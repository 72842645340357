import React, { memo } from 'react';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';

import WpGrid from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid';

import useFirmAdminGridColumns from './firmAdminGridColumns';

import { useAdministratorsForGrid } from 'pages/accounting-dashboard/client-directory/components/page-administrators/useAdministratorsForGrid';
import { selectFirmClientId, selectFirmPartnerId } from 'redux/slice/accountingFirmSlice';
import { useAppSelector } from 'redux/hooks/hook';

import { ReactComponent as EmptyStateIcon } from 'assets/images/empty_state.svg';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';

const FirmAdminGrid: React.VFC = () => {
  const { t } = useTranslation(['dashboardUserAccounts', 'common']);

  const clientId = useAppSelector(selectFirmClientId);
  const partnerId = useAppSelector(selectFirmPartnerId);

  const { adminList, gridEvents } = useAdministratorsForGrid({
    clientId: clientId ?? 0,
    partnerId: partnerId ?? 0,
  });

  const columns = useFirmAdminGridColumns();

  const emptyPlaceholder = (
    <>
      <EmptyStateIcon />
      <WpTypography variant="h3">{t('administratorsTable.noResult.title')}</WpTypography>
      <WpTypography>{t('administratorsTable.noResult.subtitle')}</WpTypography>
    </>
  );

  return (
    <Card>
      <WpGrid columns={columns} rows={adminList} events={gridEvents} emptyPlaceholder={emptyPlaceholder} />
    </Card>
  );
};

export default memo(FirmAdminGrid);
