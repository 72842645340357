import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  GetEmployerTaxesRequest,
  GetEmployerTaxesResponse,
  ICompanyTaxProperty,
  ICompanyTaxInfo,
  ICompanyTaxRemittance,
} from 'services/tax-settings-access/taxSettingsAccess.contracts';
import { GetEmployerTaxes } from 'services/tax-settings-access/taxSettingsAccess.service';

interface IGetInitialState {
  companyTaxPropertyList: Array<ICompanyTaxProperty>;
  companyTaxInfoList: Array<ICompanyTaxInfo>;
  companyTaxRemittanceList: Array<ICompanyTaxRemittance>;
  isChanged: boolean;
  updatedValues: { [key: string]: boolean };
}

const initialState: IGetInitialState = {
  companyTaxPropertyList: [],
  companyTaxInfoList: [],
  companyTaxRemittanceList: [],
  isChanged: false,
  updatedValues: {},
};

export const getEmployerTaxes = createAsyncThunk(
  'employerTaxes/getemployertaxes',
  async (request: GetEmployerTaxesRequest) => {
    const response: GetEmployerTaxesResponse = await GetEmployerTaxes(request);
    return response;
  }
);

export const getEmployerTaxesSlice = createSlice({
  name: 'getEmployerTaxes',
  initialState,
  reducers: {
    updateEmpTaxes: (state, action: PayloadAction<IGetInitialState>) => {
      state.companyTaxPropertyList = action.payload.companyTaxPropertyList;
      state.companyTaxInfoList = action.payload.companyTaxInfoList;
    },
    setEmployerTaxesFormState: (state, action) => {
      state.isChanged = action.payload;
    },
    resetEmpTaxes: (state) => {
      state.companyTaxInfoList = [];
      state.companyTaxPropertyList = [];
      state.companyTaxRemittanceList = [];
      state.updatedValues = {};
      state.isChanged = false;
    },
    updateEmpTaxesState: (state, action) => {
      state.updatedValues[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployerTaxes.fulfilled, (state, action) => {
      state.companyTaxPropertyList = action.payload.companyTaxPropertyList;
      state.companyTaxInfoList = action.payload.companyTaxInfoList;
      state.companyTaxRemittanceList = action.payload.companyTaxRemittanceList;
    });
  },
});

export const { updateEmpTaxes, resetEmpTaxes, setEmployerTaxesFormState, updateEmpTaxesState } =
  getEmployerTaxesSlice.actions;
export const getEmpTaxesReducer = getEmployerTaxesSlice.reducer;
export const getEmployerTaxesList = (state: RootState) => state.getEmpTaxesReducer;
export const getEmployerTaxesListIsChanged = (state: RootState) => state.getEmpTaxesReducer?.isChanged;
export const getEmployerTaxesListUpdatedValues = (state: RootState) => state.getEmpTaxesReducer?.updatedValues;
export const selectCompanyTaxPropertyList = (state: RootState) => state.getEmpTaxesReducer?.companyTaxPropertyList;
export const selectCompanyTaxInfoList = (state: RootState) => state.getEmpTaxesReducer?.companyTaxInfoList;
export const selectCompanyTaxRemittanceList = (state: RootState) => state.getEmpTaxesReducer?.companyTaxRemittanceList;
