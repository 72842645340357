import { Post } from 'services/httpClient';
import {
  IGetSigningAuthorityListRequest,
  IGetSigningAuthorityListResponse,
  IPersistRequest,
  IPersistResponse,
  IGetBankAccountInitialStateRequest,
  IGetBankAccountInitialStateResponse,
  IGetBankAccountVerificationRequest,
  IGetBankAccountVerificationResponse,
  IGetBankAuthorizationAgreementRequest,
  IGetBankAuthorizationAgreementResponse,
  IPersistBankAccountVerificationResponse,
  IPersistBankAccountVerificationRequest,
  ICreateBankAccountAgreementResponse,
  ICreateBankAccountAgreementRequest,
  IGeneratePlaidLinkTokenRequest,
  IGeneratePlaidLinkTokenResponse,
  IConnectToPlaidRequest,
  IConnectToPlaidResponse,
} from './companyBankAccess.contracts';

const servicedomain = '/financecompanybankaccess/';
const plaidServiceDomain = '/financeplaidaccess/';
const PersistBankUrl = servicedomain + 'persist';
const GetSigningAuthorityListUrl = servicedomain + 'getSigningAuthorityList';
const GetBankAccountVerificationUrl = servicedomain + 'getBankAccountVerification';
const GetBankAccountInitialStateUrl = servicedomain + 'getBankAccountInitialState';
const PersistBankAccountVerificationUrl = servicedomain + 'persistBankAccountVerification';
const GetBankAuthorizationAgreementUrl = servicedomain + 'getBankAuthorizationAgreement';
const CreateBankAccountAgreementUrl = servicedomain + 'createBankAccountAgreement';
const PlaidGenerateTokenUrl = plaidServiceDomain + 'getLinkToken';
const ConnectToPlaidUrl = plaidServiceDomain + 'connectToPlaid';

export const PersistBank = (request: IPersistRequest): Promise<IPersistResponse> => {
  return Post(PersistBankUrl, request);
};

export const GetSigningAuthorityList = (
  request: IGetSigningAuthorityListRequest
): Promise<IGetSigningAuthorityListResponse> => {
  return Post(GetSigningAuthorityListUrl, request);
};

export const GetBankAccountVerification = (
  request: IGetBankAccountVerificationRequest
): Promise<IGetBankAccountVerificationResponse> => {
  return Post(GetBankAccountVerificationUrl, request);
};

export const GetBankAccountInitialState = (
  request: IGetBankAccountInitialStateRequest
): Promise<IGetBankAccountInitialStateResponse> => {
  return Post(GetBankAccountInitialStateUrl, request);
};

export const PersistBankAccountVerification = (
  request: IPersistBankAccountVerificationRequest
): Promise<IPersistBankAccountVerificationResponse> => {
  return Post(PersistBankAccountVerificationUrl, request);
};

export const GetBankAuthorizationAgreement = (
  request: IGetBankAuthorizationAgreementRequest
): Promise<IGetBankAuthorizationAgreementResponse> => {
  return Post(GetBankAuthorizationAgreementUrl, request);
};

export const CreateBankAuthorizationAgreement = (
  request: ICreateBankAccountAgreementRequest
): Promise<ICreateBankAccountAgreementResponse> => {
  return Post(CreateBankAccountAgreementUrl, request);
};

export const PlaidGenerateToken = (
  request: IGeneratePlaidLinkTokenRequest
): Promise<IGeneratePlaidLinkTokenResponse> => {
  return Post(PlaidGenerateTokenUrl, request);
};

export const ConnectToPlaid = (request: IConnectToPlaidRequest): Promise<IConnectToPlaidResponse> => {
  return Post(ConnectToPlaidUrl, request);
};
