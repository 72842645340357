import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

export type IFilterAnnualReport = { payGroupId?: number; year?: number };
export type IAnnualReportPaygroup = { name: string; id: number; payGroupStatusType: number };
export type IFilterAnnualReportMap = {
  paygroup: { [id: number]: IAnnualReportPaygroup };
  year: { [id: number]: boolean };
};

export interface IDropdownItem {
  [key: string]: string | number;
}
interface IAnnualReportsInit {
  filters: IFilterAnnualReport;
  defaultFilters: IFilterAnnualReport;
  filterMaps: IFilterAnnualReportMap;
  filterDataSet: { paygroupMenu: IDropdownItem[]; yearMenu: IDropdownItem[] };
}

const initialState: IAnnualReportsInit = {
  filters: {},
  defaultFilters: {},
  filterDataSet: { paygroupMenu: [], yearMenu: [] },
  filterMaps: {
    paygroup: {},
    year: {},
  },
};

export const annualReportSlice = createSlice({
  name: 'annualReports',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<IAnnualReportsInit['filters']>) => {
      state.filters = action.payload;
    },
    updateDefaultFilters: (state, action: PayloadAction<IAnnualReportsInit['filters']>) => {
      state.defaultFilters = action.payload;
    },
    updateFilterMap: (state, action: PayloadAction<IAnnualReportsInit['filterMaps']>) => {
      state.filterMaps = action.payload;
    },
    updateToInitialState: () => {
      return initialState;
    },
    updateInitialValues: (
      state,
      action: PayloadAction<{
        filterMap: IAnnualReportsInit['filterMaps'];
        filterDataSet: IAnnualReportsInit['filterDataSet'];
      }>
    ) => {
      state.filterMaps = action.payload.filterMap;
      state.filterDataSet = action.payload.filterDataSet;
    },
  },
});

export const { updateFilters, updateFilterMap, updateDefaultFilters, updateInitialValues, updateToInitialState } =
  annualReportSlice.actions;
export const annualReportsReducer = annualReportSlice.reducer;
const selectAnnualReportStates = (state: RootState) => state.annualReportsReducer;
// Memoized selector using `createSelector`, without transforming output
export const selectAnnualReportFilters = createSelector(
  selectAnnualReportStates,
  (state) => state.filters // Returning the filters directly without transformation
);
export const selectDefaultFilters = createSelector(selectAnnualReportStates, (state) => state.defaultFilters);
export const selectAnnualReportFiltersMap = createSelector(
  selectAnnualReportStates,
  (state) => state.filterMaps // Returning the normalized filters
);
export const selectFilterDropdownOptions = createSelector(
  selectAnnualReportStates,
  (state) => state.filterDataSet // Returning the normalized filters
);
