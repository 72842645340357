import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store/store';

import billingAccess, {
  ClientStatusTypeEnum,
  CloseAccountCardInterface,
  CompanyInterface,
  CompanyOverviewContainerInterface,
  DiscountCardInterface,
  OverviewCriteriaInterface,
  SubscriptionPlanInterface,
} from 'services/finance/models/access/billingAccess';

import {
  IGetPayGroupListRequest,
  IPayGroupSummary,
  PayGroupStatusTypeEnum,
} from 'services/paygroup-access/paygroupAccess.contracts';

import { GetPayGroupList } from 'services/paygroup-access/paygroupAccess.service';

import partnerAccess, {
  ClientCompanyInterface,
  GetClientCompanyRequestInterface,
} from 'services/appCore/models/access/partnerAccess';
import companyAccess, { GetCompanyRequestInterface } from 'services/finance/models/access/companyAccess';
import { CompanyStatusTypeEnum, SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';
import companyBankAccessService, { BankAccountInterface } from 'shared/services/finance/companyBankAccess';
import financeLinkAccess, { UserInterface } from 'services/finance/models/access/companyLinkAccess';

export const getClientCompany = createAsyncThunk(
  'clientDirectory/getClientCompany',
  (request: Partial<GetClientCompanyRequestInterface> & { partnerId: number; clientId: number }) => {
    const req: GetClientCompanyRequestInterface = {
      email: '',
      filterByEmail: false,
      filterByName: false,
      name: '',
      ...request,
    };
    return partnerAccess.getClientCompany(req);
  }
);

export const getCompanyPayGroups = createAsyncThunk(
  'clientDirectory/getCompanyGayGroups',
  (request: Partial<IGetPayGroupListRequest>) => {
    const req: IGetPayGroupListRequest = {
      companyId: 0,
      getByCompanyId: false,
      getByIdList: false,
      getByPayGroupStatusType: false,
      idList: [],
      payGroupStatusType: PayGroupStatusTypeEnum.NotDefined,
      skip: 0,
      //TODO: ask backend team to resolve this as we don't have pagination here
      take: 100,
      ...request,
    };
    return GetPayGroupList(req);
  }
);

export const getCompanyOverview = createAsyncThunk(
  'clientDirectory/overview/getCompanyOverview',
  billingAccess.getCompanyOverview
);
export const getCompanyInvitation = createAsyncThunk(
  'clientDirectory/overview/getCompanyInvitation',
  (request: { id: number }) => financeLinkAccess.getCompanyInvitation(request)
);

export const getOverview = createAsyncThunk(
  'addNewClient/getOverview',
  (request: Partial<OverviewCriteriaInterface> & { companyId: number; companyCode: string }) =>
    billingAccess.getOverview({
      overviewCriteria: {
        getDiscountCard: true,
        getPartnerHandlingCard: false,
        getPlanCard: false,
        getPartnerChangeCard: false,
        getDisconnectInfo: false,
        getExtendedPlan: false,
        getChameleonCards: false,
        getReferralCard: false,
        getBankAccountCard: false,
        getBillingInfoCard: false,
        isPartner: false,
        ...request,
      },
    })
);

export const getCompany = createAsyncThunk(
  'clientDirectory/getCompany',
  (request: Partial<GetCompanyRequestInterface>) => {
    const req: GetCompanyRequestInterface = {
      getById: true,
      getByCompanyCode: false,
      getByEmail: false,
      getByName: false,
      companyCode: '',
      companyName: '',
      email: '',
      id: 0,
      includeEmployeeCounts: false,
      getByOwnerEmail: false,
      ownerEmail: '',
      includeBlacklistCompanyPersonList: false,
      ...request,
    };
    return companyAccess.getCompany(req);
  }
);

export const getPlanList = createAsyncThunk('clientDirectory/getPlanList', billingAccess.getSubscriptionPlanList);

export const getBankAccountInitialState = createAsyncThunk(
  'clientDirectory/getBankAccountInitialState',
  companyBankAccessService.getBankAccountInitialState
);

interface ClientDirectoryState {
  companyList: Array<CompanyInterface>;
  currentCompany: ClientCompanyInterface | null;
  companyOverviewContainer: CompanyOverviewContainerInterface | null;
  payGroupSummaryList: Array<IPayGroupSummary>;

  // getOverview info
  discountCard: DiscountCardInterface | null;
  closeAccountCard: CloseAccountCardInterface | null;
  defaultClientPaysFees: boolean | null;
  subscriptionPlanList: Array<SubscriptionPlanInterface>;
  clientOnboardingStatus: ClientStatusTypeEnum | null;
  clientCompanyStatus: CompanyStatusTypeEnum | null;
  clientBankAccount: BankAccountInterface | null;
  currentClientSubscriptionPlan: SubscriptionPlanInterface | null;
  clientSecurityRoleType: SecurityRoleTypeEnum | null;
  initialAdminList: Array<UserInterface>;
  showUpgradeClientsReminder: boolean;
}

const initialState: ClientDirectoryState = {
  companyList: [],
  currentCompany: null,
  companyOverviewContainer: null,
  payGroupSummaryList: [],
  discountCard: null,
  closeAccountCard: null,
  defaultClientPaysFees: null,
  subscriptionPlanList: [],
  clientOnboardingStatus: null,
  clientCompanyStatus: null,
  clientBankAccount: null,
  currentClientSubscriptionPlan: null,
  clientSecurityRoleType: null,
  initialAdminList: [],
  showUpgradeClientsReminder: false,
};

const clientDirectorySlice = createSlice({
  name: 'clientDirectory',
  initialState,
  reducers: {
    updateCurrentPlan(state, action: PayloadAction<SubscriptionPlanInterface | null>) {
      state.currentClientSubscriptionPlan = action.payload;
    },
    updateInitialAdminList(state, action: PayloadAction<Array<UserInterface>>) {
      state.initialAdminList = action.payload;
    },
    updateShowUpgradeClientsReminder(state, action: PayloadAction<boolean>) {
      state.showUpgradeClientsReminder = action.payload;
    },
    resetAllData(state) {
      state.companyList = [];
      state.currentCompany = null;
      state.companyOverviewContainer = null;
      state.payGroupSummaryList = [];
      state.discountCard = null;
      state.closeAccountCard = null;
      state.defaultClientPaysFees = null;
      state.subscriptionPlanList = [];
      state.clientOnboardingStatus = null;
      state.clientCompanyStatus = null;
      state.clientBankAccount = null;
      state.currentClientSubscriptionPlan = null;
      state.clientSecurityRoleType = null;
      state.initialAdminList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyInvitation.fulfilled, (state, action) => {
        state.currentCompany = action.payload.companyInvitation as any;
        state.clientOnboardingStatus = ClientStatusTypeEnum.inviteSent;
        state.clientSecurityRoleType = action.payload.companyInvitation.securityRoleType;
      })
      .addCase(getClientCompany.fulfilled, (state, action) => {
        state.currentCompany = action.payload.clientCompany;
      })
      .addCase(getCompanyOverview.fulfilled, (state, action) => {
        state.companyOverviewContainer = action.payload.companyOverviewContainer;
        state.clientOnboardingStatus = action.payload.companyOverviewContainer?.companyCard?.clientStatusType ?? null;
        state.clientSecurityRoleType =
          action.payload.companyOverviewContainer?.permissionsCard?.securityRoleTypeId ?? null;
      })
      .addCase(getCompanyPayGroups.fulfilled, (state, action) => {
        state.payGroupSummaryList = action.payload.payGroupSummaryList ?? [];
      })
      .addCase(getOverview.fulfilled, (state, action) => {
        state.discountCard = action.payload.overviewContainer?.discountCard ?? null;
        state.closeAccountCard = action.payload.overviewContainer?.closeAccountCard ?? null;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.defaultClientPaysFees = action.payload.company?.defaultToClientsPayCharges ?? null;
        state.clientCompanyStatus = action.payload.company?.companyStatusType;
      })
      .addCase(getPlanList.fulfilled, (state, action) => {
        state.subscriptionPlanList = action.payload.subscriptionPlanList ?? [];
      })
      .addCase(getBankAccountInitialState.fulfilled, (state, action) => {
        state.clientBankAccount = action.payload.bankAccount ?? null;
      });
  },
});

export const { updateCurrentPlan, updateInitialAdminList, resetAllData, updateShowUpgradeClientsReminder } =
  clientDirectorySlice.actions;

export const clientDirectoryReducer = clientDirectorySlice.reducer;

const selectClientDirectory = (state: RootState) => state.clientDirectoryReducer;

export const selectCurrentCompany = createSelector(selectClientDirectory, ({ currentCompany }) => currentCompany);

export const selectCurrentCompanyStatus = createSelector(
  selectClientDirectory,
  ({ clientOnboardingStatus }) => clientOnboardingStatus
);

export const selectClientName = createSelector(
  selectClientDirectory,
  ({ currentCompany }) => currentCompany?.companyName
);

export const selectClientCompanyId = createSelector(
  selectClientDirectory,
  ({ currentCompany }) => currentCompany?.id ?? 0
);

export const selectClientSecurityRoleType = createSelector(
  selectClientDirectory,
  ({ clientSecurityRoleType }) => clientSecurityRoleType ?? SecurityRoleTypeEnum.notDefined
);

export const selectClientCountry = createSelector(
  selectClientDirectory,
  ({ currentCompany }) => currentCompany?.country
);
export const selectPayGroupSummaryList = createSelector(
  selectClientDirectory,
  ({ payGroupSummaryList }) => payGroupSummaryList
);

export const selectClientOnboardingStatus = createSelector(
  selectClientDirectory,
  ({ clientOnboardingStatus }) => clientOnboardingStatus
);

export const selectClientCompanyStatus = createSelector(
  selectClientDirectory,
  ({ clientCompanyStatus }) => clientCompanyStatus
);

//BillingAccess

export const selectCompanyOverviewContainer = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer
);

export const selectCompanyCard = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.companyCard
);

export const selectDetailCompanyCard = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.companyDetailCard
);

export const selectTaxDetailCard = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.taxDetailCard
);

export const selectCompanyPlanCard = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.companyPlanCard
);

export const selectPermissionsCard = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.permissionsCard
);

export const selectEmployeeTaxCard = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.employeeTaxCard
);

export const selectClientHasTwoStepApproval = createSelector(
  selectClientDirectory,
  ({ companyOverviewContainer }) => companyOverviewContainer?.companyCard?.hasTwoStepApprovalOn ?? false
);

export const showMigrationUpgradeClientReminder = createSelector(
  selectClientDirectory,
  ({ showUpgradeClientsReminder }) => showUpgradeClientsReminder
);
