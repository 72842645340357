import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IServiceValidationResults } from '@wagepoint/ui-workbook';
import { RootState } from 'redux/store/store';
import {
  IServiceTaxMatrix,
  IPayPeriodTax,
  ITaxInfo,
} from 'services/payroll-payperiodaction-access/payPeriodActionAccess.contracts';

export interface IUpdatedPayPeriodTax extends IPayPeriodTax {
  normalized: { [key: number]: ITaxInfo };
}
export interface ITaxes {
  matrix: IServiceTaxMatrix;
  payPeriodTaxList: Array<IUpdatedPayPeriodTax>;
  validationResults: IServiceValidationResults;
  hasError?: boolean;
}

export interface IsetVariableEnumTaxes {
  payload: {
    key: 'matrix' | 'payPeriodTaxList' | 'validationResults';
    value: any;
  };
}

const resetMatrix = { matrixName: 'taxmatrix', columnList: [], rowList: [], rowCount: 0, multiHeader: [] };
const initialState: ITaxes = {
  matrix: resetMatrix,
  payPeriodTaxList: [],
  validationResults: {
    validationResultList: [],
  },
  hasError: false,
};

export const normalizeTaxArray = (taxData: IPayPeriodTax[]) => {
  return (
    taxData?.map((elem) => {
      return {
        ...elem,
        normalized: elem.taxInfoList.reduce((acc, item) => {
          acc[item.taxType] = { ...item };
          return acc;
        }, {} as { [key: number]: ITaxInfo }),
      };
    }) || []
  );
};

export const payrollTaxesSlice = createSlice({
  name: 'payrollTaxesReducer',
  initialState,
  reducers: {
    setPayrollTaxesState: (state, action: IsetVariableEnumTaxes) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setPayrollTaxesState } = payrollTaxesSlice.actions;
export const payrollTaxesReducer = payrollTaxesSlice.reducer;
export const payrollTaxesReducerStates = (state: RootState) => state.payrollTaxesReducer;
export const selectPayPeriodTaxList = createSelector(
  payrollTaxesReducerStates,
  ({ payPeriodTaxList }) => payPeriodTaxList
);
