import { createSlice, original } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IAddPoEStepEnum,
  IPoEStepListStatusEnum,
} from 'services/add-employment-province-access/addPoEAccess.contracts';
import { TaxTreatmentItemsEnum } from 'services/person-tax-access/personTaxAccess.contracts';

const addPoEList = [
  {
    id: 1,
    page: IAddPoEStepEnum.basicInformation,
    name: 'BasicInformation',
    addPoEStepStatusType: 0,
    og_status: 0,
    status: -1,
  },
  {
    id: 2,
    page: IAddPoEStepEnum.jobDetails,
    name: 'JobDetails',
    addPoEStepStatusType: 0,
    og_status: 0,
    status: 0,
  },
  {
    id: 3,
    page: IAddPoEStepEnum.taxInformation,
    name: 'TaxInformation',
    addPoEStepStatusType: 0,
    og_status: 0,
    status: 0,
  },
  {
    id: 4,
    page: IAddPoEStepEnum.finalReview,
    name: 'FinalReview',
    addPoEStepStatusType: 0,
    og_status: 0,
    status: 0,
  },
];

interface IPoESlice {
  toggleSideBar: boolean;
  stepsLoading: boolean;
  addPoEList: any[];
  activeStepIndex: null | number;
  activeStepId: number;
  initialStepId: number | null;
  isUpdate: boolean;
}

const initialState: IPoESlice = {
  toggleSideBar: false,
  addPoEList,
  activeStepIndex: null,
  activeStepId: 1,
  stepsLoading: false,
  initialStepId: 1,
  isUpdate: false,
};

const takeUserToNextStep = (list: any[] | undefined, lastIndex: number): Array<any> => {
  if (list?.length)
    return list.map((item, index) => {
      if (index === lastIndex) return { ...item, status: IPoEStepListStatusEnum.Active };
      if (item.status === IPoEStepListStatusEnum.Active) return { ...item, status: IPoEStepListStatusEnum.Completed };
      return { ...item };
    });
  return [];
};

export const poeSideBarSlice = createSlice({
  name: 'poeSideBarSlice',
  initialState,
  reducers: {
    toggleSideBar: (state, action: { payload: { value: boolean } }) => {
      state.toggleSideBar = action.payload.value;
    },
    resetPoESideBar: (state) => {
      state.addPoEList = [...addPoEList];
      state.activeStepId = 1;
      state.activeStepIndex = null;
      state.isUpdate = false;
    },
    proceedAddPoEStep: (state, action: { payload: { currentIndex?: number; goBack?: boolean } }) => {
      const updatedIndex =
        action.payload.currentIndex !== undefined
          ? action.payload.currentIndex
          : (state.activeStepIndex || 0) + (action.payload.goBack ? -1 : 1);
      state.addPoEList = takeUserToNextStep(original(state.addPoEList), updatedIndex);
      state.activeStepId = state.addPoEList?.[updatedIndex]?.id || 1;
      state.activeStepIndex = updatedIndex;
    },
    updatePoEListWithTax: (state, action) => {
      const isIndigenousStatus = action.payload.taxDetail?.taxSchemeType?.id !== TaxTreatmentItemsEnum.IndigenousStatus;
      if (isIndigenousStatus) {
        state.addPoEList = [...addPoEList];
      } else {
        state.addPoEList = addPoEList.filter((item) => item.page !== IAddPoEStepEnum.taxInformation); // removing tax page
      }
    },
    setInitialAddPoEStep: (state, action: { payload: { step: IAddPoEStepEnum } }) => {
      const value = action.payload.step;
      state.initialStepId = value;
    },
    setIsUpdatePoE: (state) => {
      state.isUpdate = true;
    },
  },
});

export const {
  toggleSideBar,
  proceedAddPoEStep,
  setInitialAddPoEStep,
  resetPoESideBar,
  setIsUpdatePoE,
  updatePoEListWithTax,
} = poeSideBarSlice.actions;
export const poeSideBarReducer = poeSideBarSlice.reducer;
export const getCommonPoEStates = (state: RootState) => state.poeSideBarReducer;
export const selectCommonPoeStatesIsUpdate = (state: RootState) => state.poeSideBarReducer?.isUpdate;
export const getToggleState = (state: RootState) => state.poeSideBarReducer.toggleSideBar;
export const getAddPoEList = (state: RootState) => state.poeSideBarReducer.addPoEList;
export const getActiveTab = (state: RootState) => state.poeSideBarReducer.activeStepId;
