import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export function usePrompt(message: any, when: boolean) {
  const history = useHistory();
  const unblock = useRef<any>(null);

  useEffect(() => {
    if (when) {
      unblock.current = history.block(message);
    } else {
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, history, message]);
}
