import { createAsyncThunk, createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IEmployeeFieldType,
  IEmployeePayrollData,
  IGetPayrollRequest,
  IGetPayrollResponse,
  IStateConfig,
  TaxesEnum,
} from 'services/priorpayroll-access/priorpayrollAccess.contracts';
import { getPriorPayroll } from 'services/priorpayroll-access/priorpayrollAccess.service';

export interface IPPRowItem {
  id: number;
  name: string;
  fieldTypeLineItemList: any[];
}
export interface IPriorPayrollTable {
  [key: number]: { [key: number]: { [key: number]: { id: number; name: string; fieldTypeLineItemList: any[] } } };
}
export enum yesNoNumberEnum {
  no = 0,
  yes,
}

export enum PPIDBTypes {
  Tax = 'tax',
  Wages = 'wages',
  Benefits = 'benefits',
  Deductions = 'deductions',
}
export interface IPriorTableKeys {
  jobId: number;
  codeId: number;
  stateId?: number;
  idbType: number;
}

export interface IDependedCell {
  cells?: { [key: number]: ICell };
  value?: IValue;
}
interface ICell {
  [key: number]: { isWcbOptional?: boolean };
}
interface IValue {
  [key: number]: number;
}
export interface IPriorTableSum {
  [key: string]: { employeeAmount: number; employerAmount: number };
}

export type IPriorList = IGetPayrollResponse['employeePayrollDataList'];

interface IEnableCustomize {
  [key: number]: boolean;
}

interface IPriorBools {
  isFetching: boolean;
  enableCustomize: null | IEnableCustomize;
  showCustomize: boolean;
  localCustomize: null | boolean;
  hasWCBAssigned: boolean;
}

export enum CustomValidationEnum {
  NotDefined = 0,
  NoCodeAdded,
  InvalidNetPay,
}

export interface IpriorNonBool {
  selectedCountry: null | number;
  tableState: IPriorPayrollTable | null;
  countryList: { id: number; name: string }[] | null;
  tableSum: IPriorTableSum;
  priorList: IPriorList;
  og_priorList: IPriorList;
  trowserStatusObj: { [key: string]: boolean };
  wagesBenefits: [];
  jobIds: number[];
  fieldOptions: { [key: string]: any[] };
  enablePP: number;
  nonRemovableStates: number[];
  formInvalid: {
    tax: { [key: string]: boolean };
    wages: { [key: string]: boolean };
    deductions: { [key: string]: boolean };
    benefits: { [key: string]: boolean };
    customValidation: number;
  };
  autoCalculatedStatTax: { [key: number]: { [key: number]: number } };
  statewiseCustomizeJob: boolean | IStateConfig[];
  dependedCells: IDependedCell;
}
export interface IpriorPayroll extends IPriorBools, IpriorNonBool {}
interface IUpdatePP extends IEmployeePayrollData {
  idbType: number;
}
interface Icontrols {
  controls: { [key: number]: unknown };
}
export const formPPInitValidationKeys = {
  tax: {},
  wages: {},
  benefits: {},
  deductions: {},
  customValidation: CustomValidationEnum.NotDefined,
};

const dereference = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};
const initialState: IpriorPayroll = {
  selectedCountry: null,
  tableState: null,
  countryList: null,
  tableSum: {},
  isFetching: false,
  showCustomize: false,
  enableCustomize: null,
  localCustomize: null,
  dependedCells: { cells: {} },
  enablePP: yesNoNumberEnum.no,
  priorList: [],
  og_priorList: [],
  trowserStatusObj: {},
  wagesBenefits: [],
  jobIds: [],
  nonRemovableStates: [],
  fieldOptions: {},
  formInvalid: dereference(formPPInitValidationKeys),
  statewiseCustomizeJob: [],
  autoCalculatedStatTax: {},
  hasWCBAssigned: false,
};

export const fetchPriorPayrollList = createAsyncThunk(
  'onboard/priorPayroll/getter',
  async (request: IGetPayrollRequest) => {
    const response: IGetPayrollResponse = await getPriorPayroll({
      ...request,
    });
    return response;
  }
);

const addNewRow = (ogList: IPriorList, value: IUpdatePP) => {
  const updatedList = ogList.map((item) => {
    if (item?.job?.id && item.job.id === value.job.id && item.state.id === value.state.id) {
      const list = item.sectionTypeList.map((el) => {
        if (el.id === value.idbType) {
          const currentSectionList = value.sectionTypeList.filter((item) => item.id === el.id)?.[0];
          return {
            ...el,
            fieldTypeLineItemList: [
              ...el.fieldTypeLineItemList,
              ...(currentSectionList?.fieldTypeLineItemList.map((item) => {
                let obj: Icontrols = { controls: {} }; // we are keeping a collection of amount / hours based ID sets so as for furhter calcuation logics
                const employeeFieldTypeList = item.employeeFieldTypeList.map((elmEmp) => {
                  // since we have a nexted structure we are calculating the empoyeeFieldList Id
                  obj = assignFieldId(elmEmp, obj); // this checks if the sub fields are hour based / amount based.
                  // Done to handle dynamic scnerios
                  return { ...elmEmp };
                });
                return { ...item, ...obj, employeeFieldTypeList };
              }) || []),
            ],
          };
        }
        return { ...el };
      });
      return { ...item, sectionTypeList: list };
    }
    return { ...item };
  });
  return updatedList;
};

const assignFieldId = (empObj: IEmployeeFieldType, objectId: any) => {
  objectId.controls[empObj.id] = { ...empObj };
  if (empObj.employeeAmount !== undefined) {
    objectId.amountId = empObj.id;
    objectId.employeeAmountType = empObj.amountValueType;
  }
  if (empObj.employerAmount !== undefined) {
    objectId.amountId = empObj.id;
    objectId.employerAmountType = empObj.amountValueType;
  }
  if (empObj.hours !== undefined) {
    objectId.hoursId = empObj.id;
  }
  return { ...objectId };
};

const removeCodeFromActualList = (params: IPriorTableKeys, list: IPriorList) => {
  const { stateId, jobId, codeId, idbType } = params;
  const newList = list.map((item) => {
    if (item.job.id === Number(jobId) && item.state.id === stateId) {
      return {
        ...item,
        sectionTypeList: item.sectionTypeList.map((sectionEl) => {
          if (sectionEl.id === idbType) {
            const filteredList = sectionEl.fieldTypeLineItemList.filter((fieldItem: any) => {
              return fieldItem.amountId ? fieldItem.amountId !== codeId : fieldItem.hoursId !== codeId;
            });
            return {
              ...sectionEl,
              fieldTypeLineItemList: filteredList,
            };
          }
          return { ...sectionEl };
        }),
      };
    }
    return { ...item };
  });
  return newList;
};

const addJobObjectForTax = (el: any) => {
  if (!el.job) return { job: { id: PPIDBTypes.Tax } };
  return {};
};

/** this function is implemented since, when we add a new state we need to persist the existing data user entered */
const simplifyOldPPData = (oldData: any) => {
  return oldData?.reduce((acc: any, item: any) => {
    return { ...acc, [`${item.state.id}_${item.job?.id || PPIDBTypes.Tax}`]: item };
  }, {});
};
const formatPriorPayrollData = (data: IPriorList, oldData?: any) => {
  const simplified = simplifyOldPPData(oldData);
  const dependedCells: IDependedCell = { cells: {} };
  const updatedDataList = data?.map((element) => {
    let el: IEmployeePayrollData = JSON.parse(JSON.stringify(element));
    const currentId = `${el.state.id}_${el.job?.id || PPIDBTypes.Tax}`;
    if (simplified?.[currentId]) el = simplified[currentId]; // this enables the user to persist the existing data without loosing it
    if (dependedCells?.cells) dependedCells.cells[el.state.id] = {};
    return {
      ...addJobObjectForTax(el),
      ...el,
      sectionTypeList: el.sectionTypeList.map((item) => {
        return {
          ...item,
          fieldTypeLineItemList: item.fieldTypeLineItemList.map((elm) => {
            let obj: Icontrols = { controls: {} }; // we are keeping a collection of amount / hours based ID sets so as for furhter calcuation logics
            const employeeFieldTypeList = elm.employeeFieldTypeList.map((elmEmp) => {
              if (!el.job?.id || (el.job?.id as unknown) === PPIDBTypes.Tax) {
                /* why do we need this?
                 * usecase 1: if user didn't fill any fields and click done. Then all the fields with field.isWcbOptional = false should light up
                 * usecase 2: if any field for example: wcb is marked as required then it should always light up even if other fields are filled.
                 */
                // saving all the dependent tax fields, to perform valiation
                if (dependedCells?.cells?.[el.state.id]) dependedCells.cells[el.state.id][elmEmp.id] = { ...elmEmp }; // we save these dependent field cell information statewise
              }
              obj = assignFieldId(elmEmp, obj);
              return { ...elmEmp, defaultAdded: true };
            });
            return {
              ...elm,
              ...obj,
              employeeFieldTypeList,
            };
          }),
        };
      }),
    };
  });
  return { updatedDataList, dependedCells };
};

const getEnablePPStatewise = (data: IEmployeePayrollData[]) => {
  return data.reduce((acc, item) => {
    acc[item.state.id as number] = item.includeAllJobs;
    return acc;
  }, {} as IEnableCustomize);
};

const priorPayrollSlice = createSlice({
  name: 'priorPayrollSlice',
  initialState,
  reducers: {
    updatePriorPayrollState: (state, action: PayloadAction<{ key: keyof IpriorNonBool; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    addWagesBenefitsNames: (state, action: PayloadAction<{ key: keyof IpriorNonBool; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    getJobIdList: (state, action: PayloadAction<{ key: keyof IpriorNonBool; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    bulkPPtableValidationUpdate: (
      state,
      action: PayloadAction<{
        type: PPIDBTypes;
        codeId?: number;
        stateId: number;
        jobId: number | string;
        value: boolean;
        specialData?: { [key: number]: { isOptional: boolean; value: number } };
      }>
    ) => {
      const { stateId, jobId, codeId, specialData } = action.payload;
      const dependedCellsList = Object.keys(state.dependedCells?.cells?.[stateId] || {});
      const fieldName = `${stateId}_${jobId}-${codeId}`;
      if (
        action.payload.codeId &&
        state.dependedCells?.cells?.[stateId]?.[action.payload.codeId] &&
        action.payload.type === PPIDBTypes.Tax
      ) {
        dependedCellsList.forEach((item) => {
          const name = `${stateId}_${jobId}-${item}`;
          state.formInvalid['tax'][name] = action.payload.value;
        });
        if (specialData && codeId && !specialData[codeId] && !specialData[TaxesEnum.Wcb]?.isOptional) {
          // this is a special condiation check for wcb rate as we don't want to remove highlight for wcb if tis marked as requred.
          const fieldName = `${stateId}_${jobId}-${TaxesEnum.Wcb}`;
          state.formInvalid['tax'][fieldName] = action.payload.value || !!specialData[TaxesEnum.Wcb]?.value;
        }
      } else {
        if (action.payload.type === PPIDBTypes.Wages && action.payload.value) {
          const wageInvalidObj = Object.keys(state.formInvalid[action.payload.type]).reduce((acc, item) => {
            acc[item] = action.payload.value;
            return acc;
          }, {} as any);
          state.formInvalid[action.payload.type] = wageInvalidObj;
        } else state.formInvalid[action.payload.type][fieldName] = action.payload.value;
      }
    },
    updatePriorList: (state, action: PayloadAction<IPriorTableKeys>) => {
      // state.tableState = handleDeleteCode(action.payload, original(state.tableState));
      state.priorList = removeCodeFromActualList(action.payload, original(state.priorList) || []);
    },
    updatePriorPayrollOptions: (state, action: PayloadAction<{ value: { [key: string]: any[] } }>) => {
      state.fieldOptions = { ...original(state.fieldOptions), ...action.payload.value };
    },
    updatePriorPayrollBools: (state, action: PayloadAction<{ key: keyof IPriorBools; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    updatePPTrowserState: (state, action: PayloadAction<{ value: { [key: string]: boolean } }>) => {
      state.trowserStatusObj = { ...original(state.trowserStatusObj), ...action.payload.value };
    },
    updateTableState: (state, action: PayloadAction<{ key: keyof IpriorPayroll; value: any; isReset?: boolean }>) => {
      state.tableSum = { ...original(state.tableSum), ...action.payload.value };
      if (action.payload.isReset) state.selectedCountry = null;
    },
    removeTableState: (state, action: PayloadAction<{ stateList: number[] }>) => {
      if (state.tableSum) {
        action.payload.stateList.forEach((item) => {
          delete state.tableSum?.[item];
        });
      }
    },
    updateCountryAndTable: (
      state,
      action: PayloadAction<{ list: IpriorPayroll['tableState']; countryList: IpriorPayroll['countryList'] }>
    ) => {
      state.tableState = action.payload.list;
      state.countryList = action.payload.countryList;
    },
    updatePriorPayrollList: (state, action: PayloadAction<{ value: IUpdatePP }>) => {
      state.priorList = addNewRow(original(state.priorList) || [], action.payload.value as any) as any;
    },
    resetPriorPayroll: (state) => {
      state.tableSum = {};
      state.priorList = [];
      state.tableState = null;
      state.selectedCountry = null;
      state.formInvalid = dereference(formPPInitValidationKeys);
    },

    updateNewPPData: (state, action: PayloadAction<{ value: IPriorList }>) => {
      const { updatedDataList, dependedCells } = formatPriorPayrollData(action.payload.value);
      state.priorList = updatedDataList;
      state.dependedCells = dependedCells;
    },
    setAutoCalTaxStateWise: (state, action: PayloadAction<{ stateId: number; value: { [key: number]: number } }>) => {
      state.autoCalculatedStatTax[action.payload.stateId] = action.payload.value;
    },
    setAutoCalculatedTax: (state, action: PayloadAction<{ value: number; key: number; stateId: number }>) => {
      if (!original(state.autoCalculatedStatTax)?.[action.payload.stateId]) {
        state.autoCalculatedStatTax[action.payload.stateId] = {};
      }
      state.autoCalculatedStatTax[action.payload.stateId][action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPriorPayrollList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPriorPayrollList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.nonRemovableStates = action.payload.employmentStateList;
      state.enablePP = action.payload.havePaidThisYear ? yesNoNumberEnum.yes : yesNoNumberEnum.no;
      if (state.enableCustomize === null) {
        state.enableCustomize = getEnablePPStatewise(action.payload.employeePayrollDataList);
      }
      state.showCustomize = action.payload.multipleJobs;
      const { updatedDataList, dependedCells } = formatPriorPayrollData(
        action.payload.employeePayrollDataList,
        action.meta.arg.dontIncludePrev === undefined ? state.priorList || [] : []
      );
      state.dependedCells = dependedCells;
      state.statewiseCustomizeJob = !action.meta.arg.useStateJobConfig // this flag will be true for employee & false for contractors
        ? action.payload.includeAllJobs // case contractors
        : action.payload.stateJobConfigList; // case employee
      state.priorList = updatedDataList;
      if (action.meta.arg.isInit) state.og_priorList = updatedDataList;
    });
    builder.addCase(fetchPriorPayrollList.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const {
  updatePriorPayrollState,
  addWagesBenefitsNames,
  getJobIdList,
  updatePriorList,
  updateCountryAndTable,
  updateTableState,
  updatePriorPayrollBools,
  updatePPTrowserState,
  updatePriorPayrollList,
  updatePriorPayrollOptions,
  resetPriorPayroll,
  updateNewPPData,
  removeTableState,
  bulkPPtableValidationUpdate,
  setAutoCalculatedTax,
  setAutoCalTaxStateWise,
} = priorPayrollSlice.actions;
export const priorPayrollReducer = priorPayrollSlice.reducer;
export const priorPayrollReducerStates = (state: RootState) => state.priorPayrollReducer;

export const priorPayrollCountrySelector = (state: RootState) => state.priorPayrollReducer.selectedCountry;
export const priorTableSumSelector = (state: RootState) => state.priorPayrollReducer.tableSum;
export const priorPayrollCountryList = (state: RootState) => state.priorPayrollReducer.countryList;
export const priorPayrollTableStates = (state: RootState) => state.priorPayrollReducer.tableState;
export const priorPayrollListSelector = (state: RootState) => state.priorPayrollReducer.priorList;
export const isFetchingPriorPayroll = (state: RootState) => state.priorPayrollReducer.isFetching;
export const trowserStatusSelector = (state: RootState) => state.priorPayrollReducer.trowserStatusObj;
export const wagesBenefitsSelector = (state: RootState) => state.priorPayrollReducer.wagesBenefits;
export const jobIdsSelector = (state: RootState) => state.priorPayrollReducer.jobIds;
export const addIdbOptionSelector = (state: RootState) => state.priorPayrollReducer.fieldOptions;
export const isPPEnabledSelector = (state: RootState) => state.priorPayrollReducer.enablePP;
export const checkIfFormValidSelector = (state: RootState) => state.priorPayrollReducer.formInvalid;
export const showCustomizeOptionSelector = (state: RootState) => state.priorPayrollReducer.showCustomize;
export const nonRemovableStateSelector = (state: RootState) => state.priorPayrollReducer.nonRemovableStates;
export const statewiseCustomizeJobSelector = (state: RootState) => state.priorPayrollReducer.statewiseCustomizeJob;
export const dependedCellSelector = (state: RootState) => state.priorPayrollReducer.dependedCells;
export const getAutoTaxCaluated = (state: RootState) => state.priorPayrollReducer.autoCalculatedStatTax;
