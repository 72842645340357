import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PersistAutoSyncPreference } from 'services/integrations-access/integrationsAccess.service';

interface IAllStatuses {
  loading: boolean;
  fulfilled: boolean;
  whoseDataIsLoading?: string;
  error: boolean;
  errorText?: string | unknown;
}

const initialState: IAllStatuses = {
  loading: false,
  fulfilled: false,
  whoseDataIsLoading: '',
  error: false,
  errorText: '',
};

export const persistAutoSyncPreference = createAsyncThunk(
  '/addons/persistAutoSyncPreference',
  PersistAutoSyncPreference
);

export const persistAutoSyncPreferenceSlice = createSlice({
  name: 'persist-auto-sync-preference',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(persistAutoSyncPreference.fulfilled, (state) => {
      state.fulfilled = true;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(persistAutoSyncPreference.pending, (state, action) => {
      state.loading = true;
      state.fulfilled = false;
      state.whoseDataIsLoading = action.payload;
    });
    builder.addCase(persistAutoSyncPreference.rejected, (state, action) => {
      state.error = true;
      state.fulfilled = false;
      state.loading = false;
      state.errorText = action.payload;
    });
  },
});

export const persistAutoSyncPreferenceReducer = persistAutoSyncPreferenceSlice.reducer;
