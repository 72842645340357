import { IWpFilterWidgetDropdownType } from '@wagepoint/ui-toolkit/components/wp-filter-widget/wpFilterWidget.interface';
import {
  PayrollCalendarEventSummaryInterface,
  PayrollCalendarEventTypeEnum,
  ReportSummaryInterface,
} from 'services/payroll/models/access/partnerAccess';

export enum DashboardFilterOptionEnum {
  ClientName = 1,
  PayGroup,
  EventType,
  Admin,
}

// PAYROLL CALENDAR INTERFACES

export enum CalendarViewEnum {
  DayView,
  WeekView,
  MonthView,
}

export type TEventTooltipPlacement = 'left' | 'right';
export interface IMonthlyCalendarEvent {
  date: string;
  title: string;
  eventType: PayrollCalendarEventTypeEnum;
  payrollsCount?: number;
  eventName?: string;
}

export interface IDetailedEvent extends PayrollCalendarEventSummaryInterface {
  title: string;
}

export interface IGetPayrollCalendarParams {
  partnerId: number;
  options: IWpFilterWidgetDropdownType[];
  displayingDate: Date;
  calendarView: CalendarViewEnum;
  isExport: boolean;
}

export interface IGetPayrollCalendarResponse {
  calendarEventList: PayrollCalendarEventSummaryInterface[];
  monthlyCalendarEventList: IMonthlyCalendarEvent[];
  isNoEvents: boolean;
}

export interface IGetReportsListResponse {
  reportSummaryList: ReportSummaryInterface[];
  filterViewedReportsApplied: boolean;
}
