import React from 'react';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  onKeepCustomization: (hasCustom: boolean) => void;
  onPersistSecurityRole: () => void;
  closeCustomization: () => void;
}

export const CustomizationsDialogues: React.FC<IProps> = ({
  isOpen,
  onKeepCustomization,
  onPersistSecurityRole,
  closeCustomization,
}) => {
  const { t } = useTranslation(['common', 'userAccounts']);

  return (
    <WpDialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      onClose={closeCustomization}
      title={<> {t('userAccounts:permissionSettings.permissionsTitle')}</>}
      content={
        <>
          <WpTypography>{t('userAccounts:permissionSettings.permissionsContent')}</WpTypography>
        </>
      }
      actions={
        <>
          <WpButton onClick={onKeepCustomization} color="primary" variant="outlined">
            {t('userAccounts:permissionSettings.permissionsNegative')}
          </WpButton>
          <WpButton onClick={onPersistSecurityRole} color="primary" variant="contained">
            {t('userAccounts:permissionSettings.permissionsPositive')}
          </WpButton>
        </>
      }
    />
  );
};
