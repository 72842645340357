import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IExportPayrollQboRequest } from 'services/integrations-access/integrationsAccess.contracts';
import { ExportPayrollQbo } from 'services/integrations-access/integrationsAccess.service';

interface IAllStatuses {
  fulfilledQbo: boolean;
  isValidatingQbo: boolean;
  errorQbo: string | unknown;
}

const initialState: IAllStatuses = {
  fulfilledQbo: false,
  isValidatingQbo: false,
  errorQbo: '',
};

export const exportPayrollQboAction = createAsyncThunk(
  '/addons/exportPayrollQbo',
  async (request: IExportPayrollQboRequest) => {
    const response = await ExportPayrollQbo(request);
    return response;
  }
);

export const exportPayrollSlice = createSlice({
  name: 'export-payroll',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exportPayrollQboAction.fulfilled, (state) => {
        state.fulfilledQbo = true;
      })
      .addCase(exportPayrollQboAction.pending, (state) => {
        state.fulfilledQbo = false;
      })
      .addCase(exportPayrollQboAction.rejected, (state, action) => {
        state.fulfilledQbo = false;
        state.errorQbo = action.payload;
      });
  },
});

export const exportPayrollReducer = exportPayrollSlice.reducer;
