import { makeStyles } from '@material-ui/core/styles';

export const AssignedPeopleGroupStyles = makeStyles((theme) => ({
  imageWrap: {
    display: 'inline-block',
    width: 36,
    height: 36,
    borderRadius: '50%',
    verticalAlign: 'middle',
    textAlign: 'center',
    background: theme.palette.text.disabled,
  },
  wrapperGrid: {
    paddingTop: theme.spacing(1),
  },
  wrapCheckBoxImg: {
    '& .MuiGrid-item': {
      paddingRight: 0,
      paddingBottom: 0,
    },
  },
  cards: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  statements: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingBottom: '10px',
  },
  filterSection: {
    padding: '0',
    '& .MuiPaper-root.MuiMenu-paper': {
      left: '272px !important',
    },
    '& .MuiButton-outlined': {
      border: `1px solid ${theme.palette.primarycolor.primaryLightBrown}`,
      margin: '0px',
    },
    textAlign: 'start',
    display: 'flex',
  },
  serachBoxStyles: {
    marginRight: theme.spacing(1),
    textAlign: 'left',
    height: '32px',
    '& .wp-search-box .wpsb-wrapper': {
      width: '100%',
    },
    '& .wpsb-wrapper': {
      display: 'flex',
    },
    '& .wp-search-box .wpsb-input': {
      width: 'calc(100% - 40px)',
    },
  },
  gridStyles: {
    width: '100%',
    padding: '0',
    // border: `0.1rem solid ${theme.palette.primarycolor.primaryLightBrown}`,
    '& td .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiTableHead-root': {
      height: '60px',
    },
    '& .wp-grid-wrapper .wp-grid .MuiTableRow-head .MuiTableCell-head': {
      color: theme.palette.primarycolor.primaryBrown,
    },
    '& .wp-grid-wrapper .wp-grid .MuiTableBody-root .MuiTableCell-body': {
      verticalAlign: 'middle',
      padding: '0 !important',
    },

    '& .MuiTableRow-root': {
      verticalAlign: 'bottom',
      // borderBottom: `0.1rem solid ${theme.palette.primarycolor.primaryLightBrown}`,
      '& td:nth-child(2)': {
        paddingLeft: '0px !important',
      },
      '& th:nth-child(2)': {
        paddingLeft: '0px !important',
      },
      '& td:nth-child(-n+4)': {
        paddingRight: '0px',
      },
    },
    '& .MuiTableCell-body': {
      verticalAlign: 'middle !important',
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      paddingTop: '19px !important',
    },
  },
  editLink: {
    ...theme.typography.body2,
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  statementContainer: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    marginTop: 10,
  },
  actionLink: {
    color: theme.palette.common.lightbrown, // need to check
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    marginLeft: '7px',
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    marginTop: '-4px',
    verticalAlign: 'top',
    width: '12px !important', //This class is for fontawsome icon
    height: '19px !important',
    cursor: 'pointer',
  },
  assignButton: {
    textAlign: 'right',
    padding: '0',
  },
  headerContainer: {
    padding: theme.spacing(2),
  },
  profileIcon: {
    display: 'none',
    height: '35px',
    borderRadius: '50%',
    verticalAlign: 'middle',
    clipPath: 'circle(44% )',
    '&:hover ': {
      background: 'none',
    },
  },
  dropdown: {
    // width: '348px',
  },
  minusIcon: {
    color: theme.palette.primarycolor.primaryBrown,
  },
  menuItem: {
    pointerEvents: 'none',
  },
  displayContent: {
    display: 'flex',
  },
  statementnote: {},
}));
