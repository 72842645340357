import React, { useEffect, useState } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { IWpBreadCrumb } from '@wagepoint/ui-toolkit/components/wp-breadcrumbs/wpBreadCrumbs';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import { selectEditStateIsEdit, selectedRoleList } from 'redux/slice/userAccountsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { useTranslation } from 'react-i18next';
import RoleTabsLink from './rolesTabsLinks';
import RoleTabsView from './rolesTabsView';
import { IGetAdministratorListRequest } from 'services/security-access/securityAccess.contracts';
import { GetAdminList } from 'services/security-access/securityAccess.service';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { setEditState } from 'redux/slice/userAccountsSlice';
import { getApiValidationErrors } from 'util/utility';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { selectCompanyId } from 'redux/slice/configSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      margin: `${theme.spacing(1)}px 0`,
    },
    noDescription: {
      display: 'none',
    },
    secondaryFooterContainer: {
      paddingLeft: theme.spacing(3),
    },
    primaryFooterContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    footerContainer: {
      borderTop: `1px solid ${theme.palette.primarycolor.primaryLightBrown}`,
    },
    title: {
      paddingLeft: '0px !important',
    },
  })
);

export enum UserAccountsTypeEnum {
  NotDefined = 0,
  Roles = 1,
  Administrator = 2,
  People = 3,
  AccountingFirm = 4,
}

const RolesContainer: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation(['userAccounts']);
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [route, setRoute] = React.useState<any>(location.state);
  const [userListLength, setUserListLength] = useState<number>(0);
  const isEdit = useAppSelector(selectEditStateIsEdit);
  const { feature } = useFeatureFlag();
  const isEmployeePortalPermissionsChanges = feature(FEATURE_FLAG_KEYS.EmployeePortalPermissionsChanges);
  const companyId = useAppSelector(selectCompanyId);

  useEffect(() => {
    setRoute((prev: any) => prev);
    if (location.state === undefined) {
      history.replace({
        pathname: location.pathname,
        state: route,
      });
    }
  }, [location]);

  useEffect(() => {
    dispatch(
      selectedRoleList({
        roles: route,
      })
    );
  }, [route]);

  useEffect(() => {
    return () => {
      dispatch(
        selectedRoleList({
          roles: {},
        })
      );
    };
  }, []);

  useEffect(() => {
    if (route?.securityRoleType === SecurityRoleTypeEnum.AccountOwner) getAdminList();
  }, [userListLength]);

  // TODO: check this and remove if not needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const codeBreadCrumbs: Array<IWpBreadCrumb> = [
    {
      label: t('rolesContainer.userAccounts'),
      href: `${path}`,
      onClick: () => {
        history.push('.');
        // TODO: remove eslint-disable and move this before history.push.
        /* eslint-disable no-restricted-syntax */
        dispatch(
          setEditState({
            isEdit: false,
          })
        );
        // eslint-enable no-restricted-syntax
      },
    },
    {
      label: `${t('rolesContainer.view')} ${route?.name}`,
      onClick: () => {
        dispatch(
          setEditState({
            isEdit: false,
          })
        );
      },
    },
    ...(isEdit && !window.location.pathname.includes('/assigned-people')
      ? [
          {
            label: `${t('permissionSettings.edit')} ${route?.name}`,
          },
        ]
      : []),
  ];

  const getLastItem = () => {
    return (
      history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1) === 'assign' ||
      window.location.pathname.includes('/edit') ||
      window.location.pathname.includes('/view')
    );
  };

  const getAdminList = async () => {
    const request: IGetAdministratorListRequest = {
      companyId,
      includeAccountingAdmin: true,
    };
    const response: any = await GetAdminList(request);
    const { hasError } = getApiValidationErrors(response);
    if (!hasError) {
      setUserListLength(response.userList.length);
    } else {
      setUserListLength(0);
    }
  };

  /*
   Transfer Ownership - tab should be displayed only if more than one user is setup in the company
  */
  return (
    <Box component="div">
      {!getLastItem() && (
        <>
          <WpTypography variant={isEmployeePortalPermissionsChanges ? 'h1' : 'h2'} className={classes.title}>
            {route?.name}
          </WpTypography>
          <WpTypography className={route?.description ? classes.description : classes.noDescription}>
            {route?.description}
          </WpTypography>
          {route?.securityRoleType === SecurityRoleTypeEnum.AccountOwner && userListLength === 1 ? (
            ''
          ) : (
            <Box mb={2}>
              <RoleTabsLink url={url} roleType={route} />
            </Box>
          )}
        </>
      )}

      <RoleTabsView path={path}></RoleTabsView>
    </Box>
  );
};

export default RolesContainer;
