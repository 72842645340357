import React, { useState } from 'react';
import AcceptPartnerPaysModal from './acceptPartnerPaysModal';
import DeclineModal from './declineModal';
import WpToolTip, { WpToolTipEnum } from '@wagepoint/ui-toolkit/components/wp-tooltip/wpToolTip';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId, selectSubscriptionPeriod, updatePartnerCompanyId } from 'redux/slice/configSlice';
import { selectCompanyInvitationList, setFirmView } from 'redux/slice/accountingFirmSlice';
import { CompanySummaryInterface, PartnerStatusTypeEnum } from 'services/finance/models/access/companyAccess';
import { selectedRoleForAccountingFirm } from 'redux/slice/userAccountsSlice';
import { useLoaders } from 'redux/hooks/useLoaders';
import { useSnackbar } from 'redux/hooks/useSnackbar';
import { getApiValidationErrors } from 'util/utility';
import { ManageViewOfAccountingFirm } from '../../../index';
import { useQueryClient } from '@tanstack/react-query';
import { accountingFirmListQueryKeys } from 'pages/settings/components/accounting-bookkeeping-firm/hooks/useAccountingFirmListQuery';
import { AcceptButton } from 'pages/settings/components/accounting-bookkeeping-firm/firm-invitations/components/common/acceptButton';
import { useGetFinanceCompanyLink } from 'shared-lib/commonHooks';
import { CardStyles } from '../cardsStyles';

export const enum DialoguesEnum {
  NotDefined = 0,
  Revoke = 1,
  Roles = 2,
  Decline = 3,
  Accept = 4,
}

interface IProps {
  item: CompanySummaryInterface;
}

const AcceptOrDeclineContainer: React.FC<IProps> = ({ item }) => {
  const { t } = useTranslation(['userAccounts']);
  const [dialogId, setDialogId] = useState<DialoguesEnum>(DialoguesEnum.NotDefined);
  const dispatch = useAppDispatch();
  const subscriptionPeriod = useAppSelector(selectSubscriptionPeriod);
  const companyId = useAppSelector(selectCompanyId);
  const companyInvitationList = useAppSelector(selectCompanyInvitationList);
  const queryClient = useQueryClient();
  const { showLoader, hideLoader } = useLoaders();
  const { error } = useSnackbar();
  const switchedCompanyLinkService = useGetFinanceCompanyLink();
  const classes = CardStyles();

  const maintainInvitation = async (isAccept: boolean) => {
    dispatch(
      selectedRoleForAccountingFirm({
        assignedRole: item.securityRoleTypeId,
      })
    );

    showLoader();
    const response = await switchedCompanyLinkService.maintainClientPartnerInvitation({
      invitationId: item?.invitationId || 0,
      accept: isAccept,
      decline: !isAccept,
      partnerPaysFees: item.isPartnerHandling,
      clientCompanyId: companyId,
      partnerCompanyId: item.partnerId ?? 0,
      partnerSecurityRoleType: item.securityRoleTypeId,
    });
    hideLoader();
    setDialogId(DialoguesEnum.NotDefined);

    const { hasError, message } = getApiValidationErrors(response);
    if (!hasError) {
      dispatch(
        setFirmView({
          firmView: ManageViewOfAccountingFirm.FirmPermission,
          isEdit: true,
          companyId,
          name: item.name,
        })
      );
      await queryClient.resetQueries({
        queryKey: accountingFirmListQueryKeys.getPartnerCompanyListQuery(companyId).key,
      });
      dispatch(updatePartnerCompanyId(isAccept ? item.partnerId : null));
    } else {
      error(message);
    }
  };

  const handleAcceptClick = () => {
    if (item.isPartnerHandling && !subscriptionPeriod?.isTrial) setDialogId(DialoguesEnum.Accept);
    else maintainInvitation(true);
  };

  const hasPartnerAlready = companyInvitationList?.some(
    ({ partnerStatusType }) => partnerStatusType === PartnerStatusTypeEnum.accepted
  );

  return (
    <>
      <div className={classes.buttonGroup}>
        <WpButton noGutter onClick={() => setDialogId(DialoguesEnum.Decline)}>
          {t('firmInviteAccepted.declineText')}
        </WpButton>

        {hasPartnerAlready ? (
          <WpToolTip title={t('common.hasPartnersTooltip')} tooltype={WpToolTipEnum.custom}>
            <span>
              <AcceptButton disabled />
            </span>
          </WpToolTip>
        ) : (
          <AcceptButton onClick={handleAcceptClick} />
        )}
      </div>
      <DeclineModal
        isOpen={dialogId === DialoguesEnum.Decline}
        onCancel={() => setDialogId(DialoguesEnum.NotDefined)}
        onDecline={() => maintainInvitation(false)}
      />
      <AcceptPartnerPaysModal
        isOpen={dialogId === DialoguesEnum.Accept}
        firmName={item.name}
        onAccept={() => maintainInvitation(true)}
        onCancel={() => setDialogId(DialoguesEnum.NotDefined)}
      />
    </>
  );
};

export default AcceptOrDeclineContainer;
