import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AccountingManagerService, {
  GetAccountListRequestInterface,
  GetAccountListResponseInterface,
  IntegrationTypeEnum,
} from 'services/integrations-access/accountingManagerAccess';
import { IQboAccountList } from 'services/integrations-access/integrationsAccess.contracts';

interface IAllStatuses {
  qboAccountList: Array<IQboAccountList>;
  isQboValidating: boolean;
  qboFulfilled: boolean;
  errorQbo?: string | unknown;
}

const initialState: IAllStatuses = {
  qboAccountList: [],
  isQboValidating: false,
  qboFulfilled: false,
};

export const getQboAccountListAction = createAsyncThunk(
  '/addons/GetQboAccountList',
  async (request: Omit<GetAccountListRequestInterface, 'integrationType'>) => {
    const response: GetAccountListResponseInterface = await AccountingManagerService.getAccountList({
      ...request,
      integrationType: IntegrationTypeEnum.qbo,
    });
    return response;
  }
);

export const getIntegrationsAccountListSlice = createSlice({
  name: 'get-integrations-account-list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQboAccountListAction.pending, (state) => {
        state.isQboValidating = true;
        state.qboFulfilled = false;
      })
      .addCase(getQboAccountListAction.fulfilled, (state, action) => {
        state.qboAccountList = action.payload.accountList;
        state.isQboValidating = false;
        state.errorQbo = '';
        state.qboFulfilled = true;
      })
      .addCase(getQboAccountListAction.rejected, (state, action) => {
        state.isQboValidating = false;
        state.errorQbo = action.payload;
        state.qboFulfilled = false;
      });
  },
});

export const getIntegrationsAccountListReducer = getIntegrationsAccountListSlice.reducer;
