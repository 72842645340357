import React from 'react';

import Grid from '@material-ui/core/Grid';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import { useTranslation } from 'react-i18next';

const StatementsContainer: React.FC = () => {
  const { t } = useTranslation(['userAccounts']);

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <WpStatements variant="highpriority" message={t('firmPermissionsContainer.admin')} />
        </Grid>
      </Grid>
    </>
  );
};

export default StatementsContainer;
