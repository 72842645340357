import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IAssociatedCompany,
  IAssociatedCompanyListRequest,
  IAssociatedCompanyListResponse,
} from 'services/company-access/companyAccess.contracts';
import { GetAssociatedCompanyList } from 'services/company-access/companyAccess.service';

export interface ICompanies {
  associatedCompanies: Array<IAssociatedCompany>;
  validationResults: any;
}

const initialState: ICompanies = {
  associatedCompanies: [],
  validationResults: {},
};

export const getAssociatedCompaniesList = createAsyncThunk(
  'associatedCompanies/getAssociatedCompaniesList',
  async (request: IAssociatedCompanyListRequest) => {
    const response: IAssociatedCompanyListResponse = await GetAssociatedCompanyList(request);
    return response;
  }
);

export const associatedCompaniesSlice = createSlice({
  name: 'associatedCompanies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssociatedCompaniesList.fulfilled, (state, action) => {
      state.associatedCompanies = action.payload.associatedCompanyList;
      state.validationResults = action.payload.validationResults;
    });
  },
});

export const associatedCompaniesReducer = associatedCompaniesSlice.reducer;
export const selectAssociatedCompanies = (state: RootState) => state.associatedCompaniesReducer;
