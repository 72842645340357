import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntegrationIdEnum } from 'pages/addons/hooks/common/useIntegrations';
import { RootState } from 'redux/store/store';
import AccountingManagerService, {
  DisconnectRequestInterface,
  IntegrationTypeEnum,
} from 'services/integrations-access/accountingManagerAccess';

interface IAllStatuses {
  fulfilledXero: boolean;
  fulfilledQbo: boolean;
  isXeroValidating: boolean;
  isQboValidating: boolean;
  showDisconnectBanner: Record<IntegrationIdEnum, boolean>;
  errorXero?: string | unknown;
  errorQbo?: string | unknown;
}

const initialState: IAllStatuses = {
  fulfilledXero: false,
  fulfilledQbo: false,
  isXeroValidating: false,
  isQboValidating: false,
  showDisconnectBanner: {
    qbo: false,
    xero: false,
    time: false,
    people: false,
  },
};

export const disconnectFromXeroAction = createAsyncThunk(
  '/addons/disconnectFromXero',
  async (request: Omit<DisconnectRequestInterface, 'integrationType'>) => {
    return AccountingManagerService.disconnect({ ...request, integrationType: IntegrationTypeEnum.xero });
  }
);

export const disconnectFromQboAction = createAsyncThunk(
  '/addons/disconnectFromQbo',
  async (request: Omit<DisconnectRequestInterface, 'integrationType'>) => {
    return AccountingManagerService.disconnect({ ...request, integrationType: IntegrationTypeEnum.qbo });
  }
);

export const disconnectAddonsSlice = createSlice({
  name: 'addons_disconnect',
  initialState,
  reducers: {
    setShowDisconnectBanner: (
      state,
      action: PayloadAction<{ key: keyof IAllStatuses['showDisconnectBanner']; isShow: boolean }>
    ) => {
      state.showDisconnectBanner[action.payload.key] = action.payload.isShow;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(disconnectFromXeroAction.pending, (state) => {
        state.isXeroValidating = true;
      })
      .addCase(disconnectFromXeroAction.fulfilled, (state) => {
        state.fulfilledXero = true;
        state.isXeroValidating = false;
      })
      .addCase(disconnectFromXeroAction.rejected, (state, action) => {
        state.fulfilledXero = false;
        state.errorXero = action.payload;
        state.isXeroValidating = false;
      });
    builder
      .addCase(disconnectFromQboAction.pending, (state) => {
        state.isQboValidating = true;
      })
      .addCase(disconnectFromQboAction.fulfilled, (state) => {
        state.fulfilledQbo = true;
        state.isQboValidating = false;
      })
      .addCase(disconnectFromQboAction.rejected, (state, action) => {
        state.fulfilledQbo = false;
        state.errorXero = action.payload;
        state.isQboValidating = false;
      });
  },
});

export const { setShowDisconnectBanner } = disconnectAddonsSlice.actions;

export const disconnectIntegrationReducer = disconnectAddonsSlice.reducer;
export const disconnectBanner = (state: RootState) => state.disconnectIntegrationReducer.showDisconnectBanner;
export const selectXeroDisconnectBanner = (state: RootState) =>
  state.disconnectIntegrationReducer.showDisconnectBanner.xero;
export const selectDisconnectQboBanner = (state: RootState) =>
  state.disconnectIntegrationReducer.showDisconnectBanner.qbo;
