import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface IStatesInit {
  stateList?: any;
}

const initialState: IStatesInit = {
  stateList: [],
};

export const stateListSlice = createSlice({
  name: 'stateList',
  initialState,
  reducers: {
    setStateList: (state, action: PayloadAction<IStatesInit>) => {
      state.stateList = action.payload.stateList;
    },
  },
});

export const { setStateList } = stateListSlice.actions;
export const stateListReducer = stateListSlice.reducer;
export const selectStateList = (state: RootState) => state.stateListReducer;
