import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IhealthRelated,
  IPayStub,
  ITotalPayrollPeopleList,
  IWagesField,
  IPayrollPeopleCount,
} from 'services/payroll-payperiod-access/payPeriodAccess.contracts';

export enum IdataReviewDetail {
  IncomeFieldList = 'incomeFieldList',
  SourceDeductionFieldList = 'sourceDeductionFieldList',
  DeductionFieldList = 'deductionFieldList',
  BenefitFieldList = 'benefitFieldList',
  TotalPayrollPeopleList = 'totalPayrollPeopleList',
  WorkersCompensationEmployeeList = 'workersCompensationEmployeeList',
  WorkersCompensationHeaderList = 'workersCompensationHeaderList',
  HsfSumTax = 'hsfSumTax',
  EhtFieldSumTax = 'ehtFieldSumTax',
  PayStubList = 'payStubList',
  employeePayStubList = 'employeePayStubList',
  PayrollPeopleCount = 'payrollPeopleCount',
}
export enum IPaymentType {
  DirectDeposit = 'Direct deposit',
  Cheque = 'Cheque',
}
interface ICal {
  ddSum: number;
  chequeSum: number;
}
interface IFundDebit {
  amount: number;
  fundName: string;
  isRemit: boolean;
}
interface IDetailState {
  [IdataReviewDetail.IncomeFieldList]: IWagesField[];
  [IdataReviewDetail.SourceDeductionFieldList]: IWagesField[];
  [IdataReviewDetail.DeductionFieldList]: IWagesField[];
  [IdataReviewDetail.BenefitFieldList]: IWagesField[];
  [IdataReviewDetail.TotalPayrollPeopleList]: ITotalPayrollPeopleList[];
  [IdataReviewDetail.WorkersCompensationEmployeeList]: [];
  [IdataReviewDetail.WorkersCompensationHeaderList]: [];
  [IdataReviewDetail.HsfSumTax]: IhealthRelated[];
  [IdataReviewDetail.EhtFieldSumTax]: IhealthRelated[];
  [IdataReviewDetail.PayStubList]: IPayStub[];
  [IdataReviewDetail.employeePayStubList]: IPayStub[];
  [IdataReviewDetail.PayrollPeopleCount]: null | IPayrollPeopleCount;
}
export interface IDetailCalc {
  [IdataReviewDetail.IncomeFieldList]: ICal;
  [IdataReviewDetail.SourceDeductionFieldList]: ICal;
  [IdataReviewDetail.DeductionFieldList]: ICal;
  [IdataReviewDetail.BenefitFieldList]: ICal;
  [IdataReviewDetail.TotalPayrollPeopleList]: ICal;
  [IdataReviewDetail.WorkersCompensationEmployeeList]: ICal;
  [IdataReviewDetail.WorkersCompensationHeaderList]: ICal;
  [IdataReviewDetail.HsfSumTax]: ICal;
  [IdataReviewDetail.EhtFieldSumTax]: ICal;
  [IdataReviewDetail.PayStubList]: ICal;
  totalChequeSum: number;
  totalDDSum: number;
}
export enum TwoStepModalTypeEnum {
  SubmitReview = 1,
  Approve,
  PayrollDeletedBeforeApproval,
  PayrollUndoneBeforeApproval,
  Reject,
  Other,
}

export enum ViewChangelogTableTypeEnum {
  Amount = 0,
  Hour,
}
export interface IReviewEmployeeJobItem {
  [key: number]: { job?: string; dept?: string };
}
export interface IReviewApproveEmployeeJob {
  [key: number]: IReviewEmployeeJobItem;
}
interface IReviewApprove {
  employeePaymentType: null | { [key: number]: any };
  ddEmployeeList: null | { [key: number]: boolean };
  paymentBreakUp: IDetailCalc;
  data: IDetailState;
  fundDebited: IFundDebit[];
  changedEmployeePT: { [key: number]: boolean } | null;
  twoStepApproval: {
    currentModal?: null | TwoStepModalTypeEnum;
    viewChangelogTableType?: ViewChangelogTableTypeEnum;
    reviewAndApprove?: {
      isDirectDepositOn: boolean;
      isRemitTaxOn: boolean;
    };
    isRejectCompleted?: boolean;
  };
  currentEhtStateId: number | null;
  employeeJobDetails: { [key: number]: { job?: string; dept?: string }[] };
}
const sumObject = { ddSum: 0, chequeSum: 0 };

const initialState: IReviewApprove = {
  employeePaymentType: null,
  ddEmployeeList: null,
  changedEmployeePT: null,
  currentEhtStateId: null,
  paymentBreakUp: {
    [IdataReviewDetail.IncomeFieldList]: { ...sumObject },
    [IdataReviewDetail.SourceDeductionFieldList]: { ...sumObject },
    [IdataReviewDetail.DeductionFieldList]: { ...sumObject },
    [IdataReviewDetail.BenefitFieldList]: { ...sumObject },
    [IdataReviewDetail.TotalPayrollPeopleList]: { ...sumObject },
    [IdataReviewDetail.WorkersCompensationEmployeeList]: { ...sumObject },
    [IdataReviewDetail.WorkersCompensationHeaderList]: { ...sumObject },
    [IdataReviewDetail.HsfSumTax]: { ...sumObject },
    [IdataReviewDetail.EhtFieldSumTax]: { ...sumObject },
    [IdataReviewDetail.PayStubList]: { ...sumObject },
    totalChequeSum: 0,
    totalDDSum: 0,
  },
  fundDebited: [],
  employeeJobDetails: {},
  data: {
    [IdataReviewDetail.IncomeFieldList]: [],
    [IdataReviewDetail.SourceDeductionFieldList]: [],
    [IdataReviewDetail.DeductionFieldList]: [],
    [IdataReviewDetail.BenefitFieldList]: [],
    [IdataReviewDetail.TotalPayrollPeopleList]: [],
    [IdataReviewDetail.WorkersCompensationEmployeeList]: [],
    [IdataReviewDetail.WorkersCompensationHeaderList]: [],
    [IdataReviewDetail.HsfSumTax]: [],
    [IdataReviewDetail.EhtFieldSumTax]: [],
    [IdataReviewDetail.PayStubList]: [],
    [IdataReviewDetail.employeePayStubList]: [],
    [IdataReviewDetail.PayrollPeopleCount]: null,
  },
  twoStepApproval: {
    currentModal: null,
    viewChangelogTableType: ViewChangelogTableTypeEnum.Amount,
    reviewAndApprove: {
      isDirectDepositOn: false,
      isRemitTaxOn: false,
    },
    isRejectCompleted: false,
  },
};
interface IReviewSet {
  key: keyof IReviewApprove;
  value: any;
}

export const reviewApproveSlice = createSlice({
  name: 'reviewApprove',
  initialState,
  reducers: {
    setReviewApproveSlice: (state, action: { payload: IReviewSet }) => {
      state[action.payload.key] = action.payload.value;
    },
    setTwoStepStates: (state, action: { payload: IReviewApprove['twoStepApproval'] }) => {
      state.twoStepApproval = { ...state.twoStepApproval, ...action.payload };
    },
    resetReviewApproveStates: (state) => {
      state.changedEmployeePT = null;
    },
    resetTwoStepApproval: (state) => {
      state.twoStepApproval = initialState.twoStepApproval;
    },
    resetToInitial: () => initialState,
    setEmployeePaymentTypes: (state, action: { payload: { key: 'employeePaymentType'; value: any } }) => {
      state.employeePaymentType = { ...action.payload.value };
      if (action.payload.value) {
        const stateCopy = { ...action.payload.value };
        const employeeList: { [key: string]: boolean } = {};
        Object.keys(stateCopy).forEach((item: any) => {
          if (stateCopy[item] === IPaymentType.DirectDeposit) {
            employeeList[item] = true;
          } else employeeList[item] = false;
        });
        state.ddEmployeeList = { ...employeeList };
      }
    },
    setTwoStepModal: (state, action: { payload: { [modalType: string]: TwoStepModalTypeEnum | null } }) => {
      state.twoStepApproval.currentModal = action.payload.modalType;
    },
    setRejectedComplete: (state, action: { payload: boolean }) => {
      state.twoStepApproval.isRejectCompleted = action.payload;
    },
  },
});

export const {
  setReviewApproveSlice,
  setTwoStepStates,
  setEmployeePaymentTypes,
  resetReviewApproveStates,
  setTwoStepModal,
  setRejectedComplete,
  resetTwoStepApproval,
  resetToInitial,
} = reviewApproveSlice.actions;
export const reviewApproveReducer = reviewApproveSlice.reducer;
export const reviewApproveStates = (state: RootState) => state.reviewApproveReducer;
export const twoStepModalSelector = ({ reviewApproveReducer }: RootState) =>
  reviewApproveReducer.twoStepApproval.currentModal;
export const twoStepReviewAndApproveSelector = ({ reviewApproveReducer }: RootState) =>
  reviewApproveReducer.twoStepApproval.reviewAndApprove;
export const twoStepActiveTotalTable = ({ reviewApproveReducer }: RootState) =>
  reviewApproveReducer.twoStepApproval.viewChangelogTableType;
export const rejectSelector = ({ reviewApproveReducer }: RootState) =>
  reviewApproveReducer.twoStepApproval.isRejectCompleted;
export const reviewApproveEmployeeJobSelector = ({ reviewApproveReducer }: RootState) =>
  reviewApproveReducer.employeeJobDetails;
export const currentEhtStateIdSelector = ({ reviewApproveReducer }: RootState) =>
  reviewApproveReducer.currentEhtStateId;
export const fundDebitSelector = ({ reviewApproveReducer }: RootState) => reviewApproveReducer.fundDebited;
export const selectEmployeePaymentType = createSelector(
  reviewApproveStates,
  ({ employeePaymentType }) => employeePaymentType
);
export const selectReviewApproveStatesData = createSelector(reviewApproveStates, ({ data }) => data);
export const selectDdEmployeeList = createSelector(reviewApproveStates, ({ ddEmployeeList }) => ddEmployeeList);
export const selectPaymentBreakUp = createSelector(reviewApproveStates, ({ paymentBreakUp }) => paymentBreakUp);
export const selectTwoStepApproval = createSelector(reviewApproveStates, ({ twoStepApproval }) => twoStepApproval);
export const selectChangedEmployeePT = createSelector(
  reviewApproveStates,
  ({ changedEmployeePT }) => changedEmployeePT
);
