import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  DepartmentStatusTypeEnum,
  IGetDepartmentSummaryListRequest,
  IGetDepartmentSummaryListResponse,
  IGetJobSummaryListRequest,
  IGetJobSummaryListResponse,
  IManageDepartmentStatusRequest,
  IManageDepartmentStatusResponse,
  IManageJobStatusRequest,
  IManageJobStatusResponse,
  IPersistCompensationRateRequest,
  IPersistCompensationRateResponse,
  IPersistJobLocationRequest,
  IPersistJobLocationResponse,
  IPersistPeopleJobAssignmentRequest,
  IPersistPeopleJobAssignmentResponse,
  JobDefinitionStatusTypeEnum,
} from 'services/job-access/jobAccess.contracts';
import {
  GetDepartmentSummaryList,
  GetJobSummaryList,
  ManageDepartmentStatus,
  ManageJobStatus,
  PersistCompensationRate,
  PersistJobLocation,
  PersistPeopleJobAssignment,
} from 'services/job-access/jobAccess.service';

/* START --------- Onboarding */
import {
  IGetPersonJobListRequest,
  IGetPersonJobListResponse,
  IManagePersonStatusRequest,
  IManagePersonStatusResponse,
  IDisableEmployeeListRequest,
  IDisableEmployeeListResponse,
} from 'services/person-access/personAccess.contracts';
import {
  ManagePersonStatus,
  GetPersonJobListDashboard,
  DisableEmployeeList,
} from 'services/person-access/personAccess.service';
import {
  GetPersonSummaryList,
  GetSalaryHistoryList,
} from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.service';
import {
  IGetEmployeeFieldSummaryListRequest,
  IGetEmployeeFieldSummaryListResponse,
  IValidateEmployeeFieldAssignmentRequest,
  IValidateEmployeeFieldAssignmentResponse,
  IGetFieldListRequest,
  IGetFieldListResponse,
} from 'services/employee-field-access/employeeFieldAccess.contracts';
import {
  GetEmployeeFieldSummaryList,
  ValidateEmployeeFieldAssignment,
  GetFieldList,
} from 'services/employee-field-access/employeeFieldAccess.service';
/* END ----------- Onboarding */

import { PeopleType } from '../peopleSlice';
import {
  IGetPersonVacationBalanceRequest,
  IGetPersonVacationBalanceResponse,
  IPersistPersonVacationBalanceRequest,
  IPersistPersonVacationBalanceResponse,
} from 'services/payroll-holidaypay-access/holidayPayAccess.contracts';
import {
  GetPersonVacationBalance,
  PersistPersonVacationBalance,
} from 'services/payroll-holidaypay-access/holidayPayAccess.service';
import {
  IChangePersonStatusRequest,
  IChangePersonStatusResponse,
  IGetEmployeeStatusListRequest,
  IGetEmployeeStatusListResponse,
  IGetOpenPayPeriodSummaryListRequest,
  IGetOpenPayPeriodSummaryListResponse,
} from 'services/priorpayroll-access/priorpayrollAccess.contracts';
import {
  ChangePersonStatus,
  GetEmployeeStatusList,
  GetOpenPayPeriodSummaryList,
} from 'services/priorpayroll-access/priorpayrollAccess.service';
import {
  IGetPersonSummaryListRequest,
  IGetPersonSummaryListResponse,
  IGetSalaryHistoryListRequest,
  IGetSalaryHistoryListResponse,
} from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.contracts';
import { IValidationResults } from 'services/shared/validationResults.contracts';
import { AxiosRequestConfig } from 'axios';
/* Calling Services START */
export const getPersonSummeryList = createAsyncThunk(
  'people/dashboard/getPersonSummaryList',
  async ({ request, config }: { request: IGetPersonSummaryListRequest; config?: AxiosRequestConfig }) => {
    const response: IGetPersonSummaryListResponse = await GetPersonSummaryList(request, config);
    return { response, request };
  }
);
export const getJobSummeryList = createAsyncThunk(
  'people/dashboard/getJobSummaryList',
  async (request: IGetJobSummaryListRequest) => {
    const response: IGetJobSummaryListResponse = await GetJobSummaryList(request);
    return response;
  }
);
export const getDepartmentSummeryList = createAsyncThunk(
  'people/dashboard/getDepartmentSummaryList',
  async (request: IGetDepartmentSummaryListRequest) => {
    const response: IGetDepartmentSummaryListResponse = await GetDepartmentSummaryList(request);
    return response;
  }
);
export const manageJobStatus = createAsyncThunk(
  'people/dashboard/manageJobStatus',
  async (request: IManageJobStatusRequest) => {
    const response: IManageJobStatusResponse = await ManageJobStatus(request);
    return response;
  }
);
interface IManagePersonStatusReq extends IManagePersonStatusRequest {
  successmsg?: any[] | string;
}
export const managePersonStatus = createAsyncThunk(
  'people/dashboard/managePersonStatus',
  async (request: IManagePersonStatusReq) => {
    const { successmsg, ...otherRequest } = request;
    const response: IManagePersonStatusResponse = await ManagePersonStatus(otherRequest);
    return response;
  }
);
export const manageDepartmentStatus = createAsyncThunk(
  'people/dashboard/manageDepartmentStatus',
  async (request: IManageDepartmentStatusRequest) => {
    const response: IManageDepartmentStatusResponse = await ManageDepartmentStatus(request);
    return response;
  }
);
export const getFieldListDashboard = createAsyncThunk(
  'people/dashboard/getFieldList',
  async (request: IGetFieldListRequest) => {
    const response: IGetFieldListResponse = await GetFieldList({
      ...request,
    });
    return response;
  }
);
export const validateEmployeeFieldAssignment = createAsyncThunk(
  'people/dashboard/validateEmployeeFieldAssignment',
  async (request: IValidateEmployeeFieldAssignmentRequest) => {
    const response: IValidateEmployeeFieldAssignmentResponse = await ValidateEmployeeFieldAssignment({
      ...request,
    });
    return response;
  }
);
export const getPersonJobListDashbaord = createAsyncThunk(
  'people/dashboard/getPersonJobList',
  async (request: Partial<IGetPersonJobListRequest>) => {
    const response: IGetPersonJobListResponse = await GetPersonJobListDashboard({
      ...request,
    });
    return response;
  }
);
export const getEmployeeFieldSummaryListDashboard = createAsyncThunk(
  'people/dashboard/getEmployeeFieldSummaryList',
  async (request: IGetEmployeeFieldSummaryListRequest) => {
    const response: IGetEmployeeFieldSummaryListResponse = await GetEmployeeFieldSummaryList({
      ...request,
    });
    return response;
  }
);
export const getSalaryHistoryList = createAsyncThunk(
  'people/dashboard/getSalaryHistoryList',
  async (request: IGetSalaryHistoryListRequest) => {
    const response: IGetSalaryHistoryListResponse = await GetSalaryHistoryList({
      ...request,
    });
    return response;
  }
);
export const persistPersonVacationBalance = createAsyncThunk(
  'people/dashboard/persistPersonVacationBalance',
  async (request: IPersistPersonVacationBalanceRequest) => {
    const response: IPersistPersonVacationBalanceResponse = await PersistPersonVacationBalance({
      ...request,
    });
    return response;
  }
);
export const getPersonVacationBalance = createAsyncThunk(
  'people/dashboard/getPersonVacationBalance',
  async (request: IGetPersonVacationBalanceRequest) => {
    const response: IGetPersonVacationBalanceResponse = await GetPersonVacationBalance({
      ...request,
    });
    return response;
  }
);
/* Calling Services END */
/* Job Services START */
export const persistPeopleJobAssignment = createAsyncThunk(
  'people/dashboard/persistPeopleJobAssignment',
  async (request: IPersistPeopleJobAssignmentRequest) => {
    const { successmsg, ...otherrequest } = request;
    const response: IPersistPeopleJobAssignmentResponse = await PersistPeopleJobAssignment({
      ...otherrequest,
    });
    return response;
  }
);
export const persistCompensationRate = createAsyncThunk(
  'people/dashboard/persistcompensationrate',
  async (request: IPersistCompensationRateRequest) => {
    const { isEdit, ...otherRequest } = request;
    const response: IPersistCompensationRateResponse = await PersistCompensationRate({
      ...otherRequest,
    });
    return response;
  }
);
export const persistJobLocation = createAsyncThunk(
  'people/dashboard/persistjoblocation',
  async (request: IPersistJobLocationRequest) => {
    const response: IPersistJobLocationResponse = await PersistJobLocation({
      ...request,
    });
    return response;
  }
);
export const getOpenPayPeriodSummaryList = createAsyncThunk(
  'people/dashboard/getopenpayperiodsummarylist',
  async (request: IGetOpenPayPeriodSummaryListRequest) => {
    const response: IGetOpenPayPeriodSummaryListResponse = await GetOpenPayPeriodSummaryList({
      ...request,
    });
    return response;
  }
);
interface IChangePersonStatusReq extends IChangePersonStatusRequest {
  validationMsg?: { empname: string; empstatus: string }[];
  returnDateValidMsg?: any[];
}
export const changePersonStatus = createAsyncThunk(
  'people/dashboard/changepersonstatus',
  async (request: IChangePersonStatusReq) => {
    const { validationMsg, returnDateValidMsg, ...otherRequest } = request;
    const response: IChangePersonStatusResponse = await ChangePersonStatus({
      ...otherRequest,
    });
    return response;
  }
);
export const getEmployeeStatusList = createAsyncThunk(
  'people/dashboard/getemployeestatuslistrequest',
  async (request: IGetEmployeeStatusListRequest) => {
    const response: IGetEmployeeStatusListResponse = await GetEmployeeStatusList({
      ...request,
    });
    return response;
  }
);

export const disableEmployeeList = createAsyncThunk(
  'people/dashboard/disableEmployeeList',
  async (request: IDisableEmployeeListRequest) => {
    const response: IDisableEmployeeListResponse = await DisableEmployeeList(request);
    return response;
  }
);

/* Job Services END */
const handleValidatorState = (
  state: PeopleType,
  validationResults: IValidationResults | any,
  successValidMessage: string | any[]
) => {
  if (validationResults?.validationResultList?.length === 0) {
    state.validationResults = validationResults;
    state.snackBarMessage = successValidMessage;
  } else if (validationResults?.validationResultList?.length) {
    state.validationResults = validationResults;
    state.snackBarMessage =
      validationResults?.validationResultList[0]?.validationMessage ?? 'onboarding.snackbarsuccessmsg.serverissue';
  } else {
    state.snackBarMessage = 'onboarding.snackbarsuccessmsg.serverissue';
  }
};
export const peopleDashboard = (builder: ActionReducerMapBuilder<PeopleType>) => {
  builder.addCase(getPersonSummeryList.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(getPersonSummeryList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.response?.validationResults?.validationResultList?.length === 0) {
      /* Updating previous bank states here */
      state.personSummeryList =
        action.payload?.request?.skip === 0
          ? action.payload?.response?.personSummaryList
          : [...(state.personSummeryList ?? []), ...action.payload?.response?.personSummaryList];
      state.personSummeryListCount = action.payload?.response?.totalCount;
      state.canCreateUserAccount = action.payload?.response?.canCreateUserAccount;
    }
    state.loading = false;
  });
  builder.addCase(getJobSummeryList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      if (
        action.meta.arg.jobDefinitionStatusType == JobDefinitionStatusTypeEnum.active &&
        !action.payload?.jobList?.length
      ) {
        state.jobSummeryList = undefined;
      } else {
        state.jobSummeryList = action.payload?.jobList;
      }
    } else if (
      action.payload?.validationResults?.validationResultList &&
      action.payload?.validationResults?.validationResultList?.length > 0
    ) {
      if (action.meta.arg.jobDefinitionStatusType == JobDefinitionStatusTypeEnum.active) {
        state.jobSummeryList = undefined;
      } else {
        state.jobSummeryList = [];
      }
    }
    state.loading = false;
  });
  builder.addCase(getDepartmentSummeryList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      if (
        action.meta.arg.departmentStatusType == DepartmentStatusTypeEnum.active &&
        !action.payload?.departmentList?.length
      ) {
        state.departmentSummeryList = undefined;
      } else {
        state.departmentSummeryList = action.payload?.departmentList;
      }
    } else if (
      action?.payload?.validationResults?.validationResultList &&
      action?.payload?.validationResults?.validationResultList.length > 0
    ) {
      if (action.meta.arg.departmentStatusType == DepartmentStatusTypeEnum.active) {
        state.departmentSummeryList = undefined;
      } else {
        state.departmentSummeryList = action.payload?.departmentList;
      }
    }
    state.loading = false;
  });
  builder.addCase(manageJobStatus.fulfilled, (state: PeopleType, action) => {
    const isEnable = action.meta.arg.jobDefinitionStatusType == JobDefinitionStatusTypeEnum.active;
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.manageDisabledTrue = true;
    }
    handleValidatorState(
      state,
      action?.payload?.validationResults ?? {},
      isEnable ? 'dashboard.jobtitle.snackbar.enabled' : 'dashboard.jobtitle.snackbar.disabled'
    );
  });
  builder.addCase(manageDepartmentStatus.fulfilled, (state: PeopleType, action) => {
    const isEnable = action.meta.arg.departmentStatusType == DepartmentStatusTypeEnum.active;
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.manageDisabledTrue = true;
    }
    handleValidatorState(
      state,
      action?.payload?.validationResults ?? {},
      isEnable ? 'dashboard.department.snackbar.enabled' : 'dashboard.department.snackbar.disabled'
    );
  });
  builder.addCase(managePersonStatus.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.manageDisabledTrue = true;
    }
  });
  builder.addCase(disableEmployeeList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.manageDisabledTrue = true;
    }
  });
  /* Employee Field List or IDB STARt */
  builder.addCase(getFieldListDashboard.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.fieldListDashboard = [...(action.payload?.fieldSummaryList ?? [])];
    }
  });
  builder.addCase(getPersonJobListDashbaord.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.personLightList = [...(action.payload?.personLiteList ?? [])];
    }
  });
  builder.addCase(getEmployeeFieldSummaryListDashboard.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.personFieldSummeryListDashboard = []; /* On every success reset list */
      action.payload?.employeeList.map((employee: any) => {
        state.personFieldSummeryListDashboard = [
          ...(state.personFieldSummeryListDashboard ?? []),
          ...(employee.employeeFieldSummaryList ?? []),
        ];
      });
      state.personActiveFieldList = undefined;
    }
  });
  builder.addCase(validateEmployeeFieldAssignment.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.personActiveFieldList = action?.payload ?? {};
      state.personFieldSummeryListDashboard = undefined;
    }
  });
  /* Employee Field List or IDB EMD */
  builder.addCase(getSalaryHistoryList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.salaryHistoryList = action.payload?.salaryHistoryList;
    }
  });
  builder.addCase(persistPersonVacationBalance.fulfilled, (state: PeopleType, action) => {
    handleValidatorState(
      state,
      action?.payload?.validationResults,
      'dashboard.components.profile.vacationpersistsuccess'
    ); //TODO: need to check why not accepting validaitonResult/Record<any, any>
  });
  builder.addCase(getPersonVacationBalance.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.vacationBalance = action.payload?.vacationBalance;
    } else {
      state.vacationBalance = undefined;
    }
  });
  builder.addCase(persistPeopleJobAssignment.fulfilled, (state: PeopleType, action) => {
    handleValidatorState(state, action?.payload?.validationResults ?? {}, action.meta.arg?.successmsg ?? '');
  });
  builder.addCase(persistCompensationRate.fulfilled, (state: PeopleType, action) => {
    handleValidatorState(
      state,
      action?.payload?.validationResults ?? {},
      !!action.meta.arg?.isEdit
        ? ['dashboard.components.profile.compensationupdated', { selpeople: action.meta.arg?.personIdList?.length }]
        : 'dashboard.components.profile.compensation'
    );
  });
  builder.addCase(persistJobLocation.fulfilled, (state: PeopleType, action) => {
    handleValidatorState(state, action?.payload?.validationResults ?? {}, [
      'dashboard.components.profile.location',
      { selpeople: action.meta.arg?.personIdList?.length },
    ]);
  });
  builder.addCase(getOpenPayPeriodSummaryList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.payPeriodSummaryList = action.payload?.payPeriodSummaryList;
    }
  });
  builder.addCase(changePersonStatus.fulfilled, (state: PeopleType, action) => {
    const ifProceedStatusChange = action.meta.arg?.proceedStatusChange;
    let isPayPeriodSummery = false;
    state.changePersonResponseState = action.payload ?? {}; /* sometimes even error has value available to be shown */
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      if (action.payload?.payPeriodSummary?.startDate) {
        /* TODO: need to test if payPeriodSummary is used to check isPayPeriodSummery */
        isPayPeriodSummery = true;
      }
    }
    if (ifProceedStatusChange) {
      const returnDateValidMsg = action.meta.arg?.returnDateValidMsg;
      handleValidatorState(
        state,
        action?.payload?.validationResults ?? {},
        returnDateValidMsg
          ? [...returnDateValidMsg]
          : [
              isPayPeriodSummery
                ? 'dashboard.changestatus.snackbarOnly.msgPayPeriod'
                : 'dashboard.changestatus.snackbarOnly.msg',
              ...(action.meta.arg?.validationMsg ?? []),
              isPayPeriodSummery
                ? 'dashboard.changestatus.snackbarOnly.msgPayPeriod'
                : 'dashboard.changestatus.snackbarOnly.msg',
            ]
      );
    }
  });
  builder.addCase(getEmployeeStatusList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.empStatusListArr = action.payload?.employeeStateTypeList ?? [];

      const key = `${action.meta.arg.employeeStateType}:${action.meta.arg.personType}`;

      if (!state.empStatusListByParams) {
        state.empStatusListByParams = {};
      }

      state.empStatusListByParams[key] = action.payload?.employeeStateTypeList ?? [];
    }
  });
};
