import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { FormattedReportFilterInterface } from 'services/payroll-report-access/payrollReport.contracts';

interface IPayrollReportsInit {
  filterList?: FormattedReportFilterInterface;
  reportResponseData?: any;
  selectedFilterList?: any;
  date?: any;
  toggleDataMasked?: boolean;
  hasPayPeriod?: boolean;
  fromPdf?: boolean;
  headQuarterId?: number;
}

const initialState: IPayrollReportsInit = {
  filterList: undefined,
  reportResponseData: {},
  selectedFilterList: {},
  date: 0,
  toggleDataMasked: false,
  hasPayPeriod: false,
  fromPdf: false,
  headQuarterId: 0,
};

export const payrollReportsSlice = createSlice({
  name: 'payrollReports',
  initialState,
  reducers: {
    updateFilterList: (state, action: PayloadAction<IPayrollReportsInit>) => {
      state.filterList = action.payload.filterList;
    },
    updateReportsData: (state, action: PayloadAction<IPayrollReportsInit>) => {
      state.reportResponseData = action.payload.reportResponseData;
      state.selectedFilterList = action.payload.selectedFilterList;
    },
    updateSelectedDateFilter: (state, action: PayloadAction<IPayrollReportsInit>) => {
      state.date = action.payload.date;
    },
    updateToggleData: (state, action: PayloadAction<IPayrollReportsInit>) => {
      state.toggleDataMasked = action.payload.toggleDataMasked;
    },
    updateHasPayPeriod: (state, action: PayloadAction<IPayrollReportsInit>) => {
      state.hasPayPeriod = action.payload.hasPayPeriod;
    },
    updateFromPdf: (state, action: PayloadAction<IPayrollReportsInit>) => {
      state.fromPdf = action.payload.fromPdf;
    },
    updateHeadQuarterId: (state, action: PayloadAction<any>) => {
      state.headQuarterId = action.payload.headQuarterId;
    },
  },
});

export const {
  updateFilterList,
  updateReportsData,
  updateSelectedDateFilter,
  updateToggleData,
  updateHasPayPeriod,
  updateFromPdf,
  updateHeadQuarterId,
} = payrollReportsSlice.actions;
export const payrollReportsReducer = payrollReportsSlice.reducer;
export const getFilterList = (state: RootState) => state.payrollReportsReducer;
export const getReportResponseData = (state: RootState) => state.payrollReportsReducer;
export const getSelectedDateFilter = (state: RootState) => state.payrollReportsReducer;
export const getToggleData = (state: RootState) => state.payrollReportsReducer;
export const getPayPeriod = (state: RootState) => state.payrollReportsReducer;
export const getPdfView = (state: RootState) => state.payrollReportsReducer;
export const getHeadquarterId = (state: RootState) => state.payrollReportsReducer;
export const getSelectedDate = (state: RootState) => state.payrollReportsReducer?.date;
export const selectFilterList = (state: RootState) => state.payrollReportsReducer?.filterList;
export const selectReportResponseData = (state: RootState) => state.payrollReportsReducer?.reportResponseData;
export const selectSelectedFilterList = (state: RootState) => state.payrollReportsReducer?.selectedFilterList;
export const selectToggleDataMasked = (state: RootState) => state.payrollReportsReducer?.toggleDataMasked;
export const selectFromPdf = (state: RootState) => state.payrollReportsReducer?.fromPdf;
export const selectHasPayPeriod = (state: RootState) => state.payrollReportsReducer?.hasPayPeriod;
