import React from 'react';
import PermissionSettings from '../permissions/index';
import { useAppSelector, useAppDispatch } from 'redux/hooks/hook';
import { selectEditStateIsEdit, setEditState } from 'redux/slice/userAccountsSlice';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

const RolesPermission: React.FC = () => {
  const isEdit = useAppSelector(selectEditStateIsEdit);
  const { feature } = useFeatureFlag();
  const isEmployeePortalPermissionsChanges = feature(FEATURE_FLAG_KEYS.EmployeePortalPermissionsChanges);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const closeBreadCrumbs = () => {
    if (isEdit) {
      dispatch(
        setEditState({
          isEdit: false,
        })
      );
    } else {
      history.push(isEmployeePortalPermissionsChanges ? '/rolesAndPermissions' : '.');
    }
  };

  return (
    <>
      <PermissionSettings handleEdit={isEdit} handleClose={closeBreadCrumbs} />
    </>
  );
};

export default RolesPermission;
