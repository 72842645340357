import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { StateEnum } from 'services/company-field-access/companyFieldAccess.contracts';
import {
  GetInitialStateRequest,
  GetInitialStateResponse,
  IEmployerTax,
  ILocation,
  IOngoingPayroll,
  ITaxSettingYTDGrossRenum,
  SetupStatusTypeEnum,
} from 'services/tax-settings-access/taxSettingsAccess.contracts';
import { GetInitialState } from 'services/tax-settings-access/taxSettingsAccess.service';

interface IGetInitialState {
  sourceDeductionSetupStatus: SetupStatusTypeEnum;
  employerTaxSetupStatus: SetupStatusTypeEnum;
  rqAccountMissing: boolean;
  companyLocationList: Array<ILocation>;
  employerTaxList: Array<IEmployerTax>;
  activeTab: number;
  allowUpdates: boolean;
  allowSourceDeductionUpdates: boolean;
  hasAnyPayrollRun: boolean;
  hasQuebecProvince: boolean;
  ongoingPayroll: IOngoingPayroll;
  taxPackage: ITaxSettingYTDGrossRenum;
}

interface ISetTaxVar {
  value: number;
}

const initialState: IGetInitialState = {
  sourceDeductionSetupStatus: 0,
  employerTaxSetupStatus: 0,
  rqAccountMissing: false,
  companyLocationList: [],
  employerTaxList: [],
  activeTab: 0,
  allowUpdates: false,
  allowSourceDeductionUpdates: false,
  hasAnyPayrollRun: false,
  hasQuebecProvince: false,
  ongoingPayroll: {
    fromDate: '',
    toDate: '',
    payDate: '',
  },
  taxPackage: {
    ehtYtdEarningsBc: 0,
    ehtYtdEarningsOn: 0,
    hapsetYtdEarnings: 0,
    heLevyYtdEarnings: 0,
    hsfYtdEarnings: 0,
  },
};

export const getInitialState = createAsyncThunk('overview/getinitialstate', async (request: GetInitialStateRequest) => {
  const response: GetInitialStateResponse = await GetInitialState(request);
  return response;
});

export const overviewSlice = createSlice({
  name: 'taxsettingsoverview',
  initialState,
  reducers: {
    overviewState: (state, action: PayloadAction<IGetInitialState>) => {
      state.sourceDeductionSetupStatus = action.payload.sourceDeductionSetupStatus;
      state.employerTaxSetupStatus = action.payload.employerTaxSetupStatus;
      state.rqAccountMissing = action.payload.rqAccountMissing;
      state.companyLocationList = action.payload.companyLocationList;
      state.employerTaxList = action.payload.employerTaxList;
      state.allowUpdates = action.payload.allowUpdates;
      state.allowSourceDeductionUpdates = action.payload.allowSourceDeductionUpdates;
      state.hasAnyPayrollRun = action.payload.hasAnyPayrollRun;
      state.ongoingPayroll = action.payload.ongoingPayroll;
    },
    setTaxVariables: (state, action: PayloadAction<ISetTaxVar>) => {
      state.activeTab = action.payload.value;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getInitialState.fulfilled, (state, action) => {
      state.sourceDeductionSetupStatus = action.payload.sourceDeductionSetupStatus;
      state.employerTaxSetupStatus = action.payload.employerTaxSetupStatus;
      state.rqAccountMissing = action.payload.rqAccountMissing;
      state.companyLocationList = action.payload.companyLocationList;
      state.employerTaxList = action.payload.employerTaxList;
      state.allowUpdates = action.payload.allowUpdates;
      state.allowSourceDeductionUpdates = action.payload.allowSourceDeductionUpdates;
      state.hasAnyPayrollRun = action.payload.hasAnyPayrollRun;
      state.ongoingPayroll = action.payload.ongoingPayroll;
      state.hasQuebecProvince = action.payload.companyLocationList.some(
        (location) => location.stateId === StateEnum.Quebec
      );
      state.taxPackage = action.payload.taxPackage;
    });
  },
});

export const { overviewState, setTaxVariables } = overviewSlice.actions;
export const overviewReducer = overviewSlice.reducer;
export const getOverview = (state: RootState) => state.overviewReducer;
export const getOverviewSourceDeductionSetupStatus = (state: RootState) =>
  state.overviewReducer?.sourceDeductionSetupStatus;
export const getOverviewActiveTab = (state: RootState) => state.overviewReducer?.activeTab;
export const getOverviewEmployerTaxSetupStatus = (state: RootState) => state.overviewReducer?.employerTaxSetupStatus;
export const selectOverviewcompanyLocationList = (state: RootState) => state.overviewReducer?.companyLocationList;
export const selectOverviewEmployerTaxList = (state: RootState) => state.overviewReducer?.employerTaxList;
export const selectOverviewHasAnyPayrollRun = (state: RootState) => state.overviewReducer?.hasAnyPayrollRun;
export const selectOverviewHasQuebecProvince = (state: RootState) => state.overviewReducer?.hasQuebecProvince;
export const selectOverviewAllowUpdates = (state: RootState) => state.overviewReducer?.allowUpdates;
export const selectOverviewTaxPackageEhtYtdEarningsBc = (state: RootState) =>
  state.overviewReducer?.taxPackage?.ehtYtdEarningsBc;
export const selectOverviewOngoingPayroll = (state: RootState) => state.overviewReducer?.ongoingPayroll;
export const selectOverviewTaxPackageHeLevyYtdEarnings = (state: RootState) =>
  state.overviewReducer?.taxPackage?.heLevyYtdEarnings;
export const selectOverviewTaxPackageHapsetYtdEarnings = (state: RootState) =>
  state.overviewReducer?.taxPackage?.hapsetYtdEarnings;
export const selectOverviewTaxPackageEhtYtdEarningsOn = (state: RootState) =>
  state.overviewReducer?.taxPackage?.ehtYtdEarningsOn;
export const selectOverviewAllowSourceDeductionUpdates = (state: RootState) =>
  state.overviewReducer?.allowSourceDeductionUpdates;
