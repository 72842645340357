import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IGetEmployeeRelevantStateListRequest,
  IGetEmployeeRelevantStateListResponse,
  IEmployeeRelevantState,
} from 'services/add-employment-province-access/addPoEAccess.contracts';
import { getEmployeeStateList } from 'services/add-employment-province-access/addPoEAccess.services';

interface IPoEState {
  employeeRelevantStateList: Array<IEmployeeRelevantState>;
  isUpdate: boolean;
}

const initialState: IPoEState = {
  employeeRelevantStateList: [],
  isUpdate: false,
};

export const getEmployeeRelevantStateList = createAsyncThunk(
  'poe/getEmployeeRelevantStateList',
  async (request: IGetEmployeeRelevantStateListRequest) => {
    const response: IGetEmployeeRelevantStateListResponse = await getEmployeeStateList(request);
    return response;
  }
);

export const poeSlice = createSlice({
  name: 'poe',
  initialState,
  reducers: {
    reset: (state) => {
      state.employeeRelevantStateList = [];
    },
    setUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeRelevantStateList.fulfilled, (state, action) => {
      state.employeeRelevantStateList = action.payload.employeeRelevantStateList;
    });
  },
});

export const { reset, setUpdate } = poeSlice.actions;
export const poeReducer = poeSlice.reducer;
export const selectPoE = (state: RootState) => state.poeReducer;
export const selectPoeEmployeeRelevantStateList = (state: RootState) => state.poeReducer?.employeeRelevantStateList;
