import React from 'react';
import Box from '@material-ui/core/Box';
import FirmAdminGrid from './firmAdminGrid';
import WpBreadCrumbs from '@wagepoint/ui-toolkit/components/wp-breadcrumbs/wpBreadCrumbs';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import { IWpBreadCrumb } from '@wagepoint/ui-toolkit/components/wp-breadcrumbs/wpBreadCrumbs';
import { ManageViewOfAccountingFirm } from '../index';
import { selectFirmName, setFirmView } from 'redux/slice/accountingFirmSlice';
import { useAppSelector, useAppDispatch } from 'redux/hooks/hook';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GlobalStyles } from 'styles/globalStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      paddingBottom: theme.spacing(1),
    },
    cardContainer: {
      padding: theme.spacing(3),
      borderRadius: 10,
      border: `1px solid ${theme.palette.primarycolor.primaryLightBrown}`,
    },
  })
);

const FirmAdminContainer: React.FC = () => {
  const classes = useStyles();
  const globalClass = GlobalStyles();
  const { t } = useTranslation(['userAccounts']);
  const name = useAppSelector(selectFirmName);
  const dispatch = useAppDispatch();

  const codeBreadCrumbs: Array<IWpBreadCrumb> = [
    {
      label: name,
      onClick: () => {
        dispatch(
          setFirmView({
            firmView: ManageViewOfAccountingFirm.FirmInviteSent,
            isEdit: false,
          })
        );
      },
    },
    {
      label: t('firmAdminContainer.title'),
    },
  ];
  return (
    <>
      <Box className={globalClass.breadcrumbContainer}>
        <WpBreadCrumbs list={codeBreadCrumbs} />
      </Box>

      <WpTypography variant="h1" className={classes.title}>
        {t('firmAdminContainer.title')}
      </WpTypography>

      <FirmAdminGrid />
    </>
  );
};

export default FirmAdminContainer;
