import { Post } from 'services/httpClient';
import { IValidationResult } from 'services/person-bank-access/personBankAccess.contracts';
import { IGetConfigurationRequest, IGetConfigurationResponse } from './uiConfigAccess.contracts';

const servicedomain = '/appcoreuiconfigaccess/';
const GetConfigurationUrl = servicedomain + 'getconfiguration';
const keepAliveUrl = servicedomain + 'keepAlive';

export interface Icompany {
  name: string;
}

export const GetConfiguration = (request: IGetConfigurationRequest): Promise<IGetConfigurationResponse> => {
  return Post(GetConfigurationUrl, request);
};

export const KeepConnectionAlive = (): Promise<IValidationResult> => {
  return Post(keepAliveUrl, {});
};
