import { createSlice, PayloadAction, original } from '@reduxjs/toolkit';
import { CardTypesEnum } from 'pages/people-refactor/peopleDefinition';
import { RootState } from 'redux/store/store';
import { IQuestion } from 'services/onboard-question-access/onboardQuestion.contracts';

export interface IGuidedOnBoardingBoolean {
  isFetchingBoardingTypes: boolean;
  isFetchingQuestions: boolean;
  isFetchingMoreQuestions: boolean;
  isBoardingTypeSelected: boolean;
}
export interface IGuidedOnboardingNonBoolean {
  preferenceDataPersisted: {
    employeeStatusTypeIdList: number | null;
    onboardingTypeId: number | null;
    employementTypeIdList: number | null;
    havePaidThisYear: number | null;
    isStaffOnboardingList: any[];
    isStaffSelfOnboardingEnabled: number | null;
  };
  listOfOnboardings: { questionOptionList: any[] };
  selectedBoardingType: number | null;
  guidedOnBoardingQuestionList: IQuestion[];
  addedIds: { [key: number]: boolean };
  alreadySelected: { [key: number]: { questions: IQuestion[]; formState: any } };
}

export interface IGuidedOnboarding extends IGuidedOnboardingNonBoolean, IGuidedOnBoardingBoolean {}

export type IupdateGuidedOnboardingStateNonBoolean = PayloadAction<{
  key: keyof IGuidedOnboardingNonBoolean;
  value: any;
}>;
export type IupdateGuidedOnboardingStateBoolean = PayloadAction<{
  key: keyof IGuidedOnBoardingBoolean;
  value: any;
}>;

const initialState: IGuidedOnboarding = {
  preferenceDataPersisted: {
    employeeStatusTypeIdList: null,
    onboardingTypeId: CardTypesEnum.guidedOnboard,
    employementTypeIdList: null,
    havePaidThisYear: null,
    isStaffOnboardingList: [],
    isStaffSelfOnboardingEnabled: null,
  },
  listOfOnboardings: { questionOptionList: [] },
  isFetchingBoardingTypes: false,
  selectedBoardingType: null,
  guidedOnBoardingQuestionList: [],
  isFetchingQuestions: false,
  isFetchingMoreQuestions: false,
  isBoardingTypeSelected: true,
  addedIds: {},
  alreadySelected: {},
};

const guidedOnboardingSlice = createSlice({
  name: 'peopleguidedOnboarding',
  initialState,
  reducers: {
    updateGuidedOnboardingState: (state, action: IupdateGuidedOnboardingStateNonBoolean) => {
      state[action.payload.key] = action.payload.value;
    },
    updateAlreadySelected: (
      state,
      action: PayloadAction<{ value: IGuidedOnboardingNonBoolean['alreadySelected'] }>
    ) => {
      state.alreadySelected = { ...original(state.alreadySelected), ...action.payload.value };
    },
    updateBooleanGuidedOnboardingState: (state, action: IupdateGuidedOnboardingStateBoolean) => {
      state[action.payload.key] = action.payload.value;
    },
    handleFetchBoardingList: (state, action: any) => {
      state.isFetchingBoardingTypes = false;
      state.listOfOnboardings = action.payload.value;
    },
    resetGuidedOnboardingStates: (state) => {
      state.addedIds = {};
      if (state.selectedBoardingType !== null) delete state.alreadySelected[state.selectedBoardingType];
      state.selectedBoardingType = null;
    },
    addOnboardingSteps: (state, action: any) => {
      state.guidedOnBoardingQuestionList = action.payload.value;
    },
  },
});

export const {
  updateGuidedOnboardingState,
  updateBooleanGuidedOnboardingState,
  handleFetchBoardingList,
  updateAlreadySelected,
  resetGuidedOnboardingStates,
  addOnboardingSteps,
} = guidedOnboardingSlice.actions;
export const guidedOnboardingReducer = guidedOnboardingSlice.reducer;
export const guidedOnboardingSelector = (state: RootState) => state.guidedOnboardingReducer;
export const boardingTypeListSelector = (state: RootState) => ({
  listOfOnboardings: state.guidedOnboardingReducer.listOfOnboardings,
  isLoading: state.guidedOnboardingReducer.isFetchingBoardingTypes,
});
export const selectedBoardingTypeSelector = (state: RootState) => ({
  onboardingType: state.guidedOnboardingReducer.selectedBoardingType,
  hasError: !state.guidedOnboardingReducer.isBoardingTypeSelected,
});
export const selectedOnBoardingTypeSelector = (state: RootState) => state.guidedOnboardingReducer.selectedBoardingType;
export const selectHasError = (state: RootState) => !state.guidedOnboardingReducer.isBoardingTypeSelected;
export const guidedOnBoardingQuestionSelector = (state: RootState) => ({
  isLoading: state.guidedOnboardingReducer.isFetchingQuestions,
  guidedOnBoardingQuestionList: state.guidedOnboardingReducer.guidedOnBoardingQuestionList,
});
export const selectGuidedOnBoardingQuestionList = (state: RootState) =>
  state.guidedOnboardingReducer.guidedOnBoardingQuestionList;
export const selectAlreadyConfig = (state: RootState) => state.guidedOnboardingReducer.alreadySelected;
export const selectedGuidedOnboardingConfig = (state: RootState) =>
  state.guidedOnboardingReducer.preferenceDataPersisted;
