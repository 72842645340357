import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'redux/hooks/useSnackbar';
import { useLoaders } from 'redux/hooks/useLoaders';
import { getApiValidationErrors, getErrorMessage } from 'util/utility';

export const useQueryFnWithLoader = (isShowLoading = true, isShowAlert = true) => {
  const { t } = useTranslation('common');
  const { showLoader, hideLoader } = useLoaders();
  const { error } = useSnackbar();

  return async <P, R>(request: (params: P) => Promise<R>, params: P) => {
    try {
      if (isShowLoading) {
        showLoader();
      }
      const response = await request(params);
      const { hasError, message } = getApiValidationErrors(response);
      if (hasError) {
        throw new Error(message || t('error'));
      }
      return response;
    } catch (e) {
      const errorMessage = getErrorMessage(e) || t('error');
      if (isShowAlert) {
        error(errorMessage);
      }
      throw e;
    } finally {
      if (isShowLoading) {
        hideLoader();
      }
    }
  };
};
