import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { TFunction } from 'react-i18next';

const roleList = (t: TFunction) => {
  return [
    { id: SecurityRoleTypeEnum.Administrator, name: t('userAccounts:transferOwnership.payrollAdmin') },
    { id: SecurityRoleTypeEnum.Manager, name: t('userAccounts:transferOwnership.manager') },
    { id: SecurityRoleTypeEnum.AccountSignatory, name: t('userAccounts:transferOwnership.authorized') },
    { id: SecurityRoleTypeEnum.ReportsManager, name: t('userAccounts:transferOwnership.reportsAdmin') },
  ];
};

const displayRoleList = (t: TFunction) => {
  return [
    { id: SecurityRoleTypeEnum.AccountOwner, name: t('userAccounts:transferOwnership.accountOwner') },
    { id: SecurityRoleTypeEnum.AccountSignatory, name: t('userAccounts:transferOwnership.authorized') },
    { id: SecurityRoleTypeEnum.Administrator, name: t('userAccounts:transferOwnership.payrollAdmin') },
    { id: SecurityRoleTypeEnum.Manager, name: t('userAccounts:transferOwnership.manager') },
    { id: SecurityRoleTypeEnum.ReportsManager, name: t('userAccounts:transferOwnership.reportsAdmin') },
    { id: SecurityRoleTypeEnum.AdministratorApprover, name: t('userAccounts:transferOwnership.adminApprover') },
    {
      id: SecurityRoleTypeEnum.AdministratorSubmitter,
      name: t('userAccounts:transferOwnership.adminSubmitter'),
    },
    { id: SecurityRoleTypeEnum.AccountAdministrator, name: t('userAccounts:transferOwnership.accountAdmin') },
    { id: SecurityRoleTypeEnum.Employee, name: t('userAccounts:transferOwnership.employee') },
    { id: SecurityRoleTypeEnum.Contractor, name: t('userAccounts:transferOwnership.contractor') },
  ];
};

export { roleList, displayRoleList };
