import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IServiceMatrix } from '@wagepoint/ui-workbook';
import { RootState } from 'redux/store/store';
import { IfieldListData } from 'services/finance-field-access/financeFieldAccess.contracts';
import { IGridColumnData } from 'services/payroll-payperiod-access/payPeriodAccess.contracts';
import { ITableViewKeys } from './hoursIncomeSlice';
import { SpecialValue } from './commonPayrollSlice';

interface ISelectedItem {
  [key: string]: { status: boolean; baseFieldId: number; id: number; inMatrix?: boolean; isNonRemovable?: boolean };
}
export interface ICustomCodeExplictField {
  [key: number]: { [desig: string]: boolean };
}
export interface ISelectedOption {
  incomes: ISelectedItem;
  deductions: ISelectedItem;
  benefits: ISelectedItem;
  additionalIncomes: ISelectedItem;
}
export interface IGridFieldsList {
  incomes: IfieldListData[];
  deductions: IfieldListData[];
  benefits: IfieldListData[];
  additionalIncomes: IfieldListData[];
}
export interface IGridVewReduxState {
  staticColumnInfo: ITableViewKeys | null;
  tableColumnConfig: IGridColumnData;
  tableData: any[];
  tableDataCopy: any[];
  valueHashState: { [key: string]: string };
  fieldIdMapping: { [key: string]: any };
  activeColumnsList: { [key: string]: boolean };
  showAddColumn: boolean;
  showHideColumn: boolean;
  showRecurring: { recurring: string[]; exceed: string[]; limitReached: string[] };
  addedFieldList: ISelectedOption;
  selectedOptions: ISelectedOption;
  fieldList: IGridFieldsList;
  gridMatrixLetterMapping: { [key: string]: any };
  selectedNewFields: {
    added: { [key: number]: boolean };
    tiggeredClearAll: boolean;
    nonRemovableItems: { [key: number]: boolean };
  };
  currentMatrix: IServiceMatrix | undefined;
  customCodeData: {
    [SpecialValue.RegularPay]: null | { [key: string]: boolean };
    [SpecialValue.OtPay]: null | { [key: string]: boolean };
    [SpecialValue.HolidayPay]: null | { [key: string]: boolean };
    [SpecialValue.VacationPay]: null | { [key: string]: boolean };
    [SpecialValue.StatPay]: null | { [key: string]: boolean };
    [SpecialValue.WorkedOnStatPay]: null | { [key: string]: boolean };
    [SpecialValue.SickPay]: null | { [key: string]: boolean };
  };
  customCodeExplicitFields: ICustomCodeExplictField;
}
export const columnConfig: IGridColumnData = {
  grossPay: null,
  netPay: null,
  totalHours: null,
  name: null,
  locationName: null,
  departmentName: null,
  type: null,
  rowKey: null,
  employeeId: null,
  jobName: null,
  incomes: null,
  deductions: null,
  benefits: null,
  isIncluded: null,
  otherIncomes: null,
  regularRate: null,
  salary: null,
};
interface ISetGridVariable {
  payload: {
    key?: keyof IGridVewReduxState;
    value: any;
  };
}

const initialState: IGridVewReduxState = {
  staticColumnInfo: null,
  currentMatrix: undefined,
  tableColumnConfig: { ...columnConfig },
  tableData: [],
  tableDataCopy: [],
  valueHashState: {},
  activeColumnsList: {},
  fieldIdMapping: {},
  showAddColumn: false,
  selectedNewFields: { added: {}, tiggeredClearAll: false, nonRemovableItems: {} },
  showHideColumn: false,
  customCodeExplicitFields: {},
  customCodeData: {
    [SpecialValue.RegularPay]: null,
    [SpecialValue.OtPay]: null,
    [SpecialValue.HolidayPay]: null,
    [SpecialValue.VacationPay]: null,
    [SpecialValue.StatPay]: null,
    [SpecialValue.WorkedOnStatPay]: null,
    [SpecialValue.SickPay]: null,
  },
  fieldList: {
    incomes: [],
    deductions: [],
    benefits: [],
    additionalIncomes: [],
  },
  selectedOptions: {
    incomes: {},
    deductions: {},
    benefits: {},
    additionalIncomes: {},
  },
  addedFieldList: {
    incomes: {},
    deductions: {},
    benefits: {},
    additionalIncomes: {},
  },
  showRecurring: { recurring: [], exceed: [], limitReached: [] },
  gridMatrixLetterMapping: {},
};

export const payrollGridReducer = createSlice({
  name: 'payrollGridReducer',
  initialState,
  reducers: {
    setGridViewRedux: (state, action: ISetGridVariable) => {
      if (action.payload.key === 'tableData') {
        state.tableDataCopy = action.payload.value;
      }
      if (action.payload.key) state[action.payload.key] = action.payload.value;
    },
    setValidationStates: (
      state,
      action: { payload: { key: keyof IGridVewReduxState['showRecurring']; value: string[] } }
    ) => {
      state.showRecurring[action.payload.key] = action.payload.value;
    },
    setMultipleGridViewRedux: (state, action: ISetGridVariable) => {
      return { ...state, ...action.payload.value };
    },
    resetGridView: (state, action?: { payload: boolean }) => {
      state.addedFieldList = {
        incomes: {},
        deductions: {},
        benefits: {},
        additionalIncomes: {},
      };
      state.tableColumnConfig = { ...columnConfig };
      state.activeColumnsList = {};
      state.fieldList = action?.payload
        ? {
            incomes: [],
            deductions: [],
            benefits: [],
            additionalIncomes: [],
          }
        : state.fieldList;
    },
  },
});

export const { setGridViewRedux, setMultipleGridViewRedux, setValidationStates, resetGridView } =
  payrollGridReducer.actions;
export const payrollGridViewReducer = payrollGridReducer.reducer;
export const payrollGridViewStates = (state: RootState) => state.payrollGridViewReducer;

export const payrollGridTableStates = (state: RootState) => {
  const { activeColumnsList, customCodeData, customCodeExplicitFields } = state.payrollGridViewReducer;
  return { activeColumnsList, customCodeData, customCodeExplicitFields };
};

export const selectTableColumnConfig = createSelector(
  payrollGridViewStates,
  ({ tableColumnConfig }) => tableColumnConfig
);
export const selectTableData = createSelector(payrollGridViewStates, ({ tableData }) => tableData);
export const selectTableDataCopy = createSelector(payrollGridViewStates, ({ tableDataCopy }) => tableDataCopy);
export const selectShowAddColumn = createSelector(payrollGridViewStates, ({ showAddColumn }) => showAddColumn);
export const selectShowHideColumn = createSelector(payrollGridViewStates, ({ showHideColumn }) => showHideColumn);
export const selectGridMatrixLetterMapping = createSelector(
  payrollGridViewStates,
  ({ gridMatrixLetterMapping }) => gridMatrixLetterMapping
);
export const selectShowRecurring = createSelector(payrollGridViewStates, ({ showRecurring }) => showRecurring);
export const selectCurrentMatrix = createSelector(payrollGridViewStates, ({ currentMatrix }) => currentMatrix);
export const selectFieldIdMapping = createSelector(payrollGridViewStates, ({ fieldIdMapping }) => fieldIdMapping);
export const selectActiveColumnsList = createSelector(
  payrollGridViewStates,
  ({ activeColumnsList }) => activeColumnsList
);
export const selectFieldList = createSelector(payrollGridViewStates, ({ fieldList }) => fieldList);
export const selectCustomCodeExplicitFields = createSelector(
  payrollGridViewStates,
  ({ customCodeExplicitFields }) => customCodeExplicitFields
);
export const selectSelectedNewFields = createSelector(
  payrollGridViewStates,
  ({ selectedNewFields }) => selectedNewFields
);
export const selectCustomCodeData = createSelector(payrollGridViewStates, ({ customCodeData }) => customCodeData);
export const selectSelectedOptions = createSelector(payrollGridViewStates, ({ selectedOptions }) => selectedOptions);

export const payrollMainComponentStates = (state: RootState) => {
  const {
    tableColumnConfig,
    tableData,
    tableDataCopy,
    showAddColumn,
    showHideColumn,
    gridMatrixLetterMapping,
    showRecurring,
    currentMatrix,
    fieldIdMapping,
    activeColumnsList,
    selectedNewFields,
    customCodeData,
  } = state.payrollGridViewReducer;
  return {
    tableColumnConfig,
    tableData,
    tableDataCopy,
    showAddColumn,
    showHideColumn,
    gridMatrixLetterMapping,
    showRecurring,
    currentMatrix,
    fieldIdMapping,
    activeColumnsList,
    selectedNewFields,
    customCodeData,
  };
};
