import { TFunction } from 'react-i18next';
import * as yup from 'yup';
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = (t: TFunction) => {
  return yup.object().shape({
    email: yup
      .string()
      .required(t('assignRole.emailValidation'))
      .matches(emailRegExp, t('assignRole.email'))
      .nullable(),

    firstName: yup.lazy((value) => {
      if (value !== undefined) {
        return yup.string().required(t('assignRole.firstNameValidation')).nullable();
      }
      return yup.mixed().notRequired();
    }),
    lastName: yup.lazy((value) => {
      if (value !== undefined) {
        return yup.string().required(t('assignRole.lastNameValidation')).nullable();
      }
      return yup.mixed().notRequired();
    }),
    confirmEmail: yup.lazy((value) => {
      if (value !== undefined) {
        return yup
          .string()
          .email(t('assignRole.email'))
          .required(t('assignRole.emailNoMatch'))
          .matches(emailRegExp, t('assignRole.email'))
          .oneOf([yup.ref('email'), null], t('assignRole.invalidEmail'))
          .nullable();
      }
      return yup.mixed().notRequired();
    }),

    roleTypeRadioCheck: yup.string().min(1, t('assignRole.required')).required(t('assignRole.required')),
  });
};

export default validationSchema;
