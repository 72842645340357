import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ChangePassword } from 'services/security-access/securityAccess.service';
import { IChangePasswordRequest, IChangePasswordResponse } from 'services/security-access/securityAccess.contracts';

interface IAllStatuses {
  fulfilled: boolean;
  isValidating: boolean;
  error?: string | unknown;
}

const initialState: IAllStatuses = {
  fulfilled: false,
  isValidating: false,
  error: '',
};

export const changePassword = createAsyncThunk(
  '/securityAccess/changePassword',
  async (request: IChangePasswordRequest) => {
    const response: IChangePasswordResponse = await ChangePassword(request);
    return response;
  }
);

export const changePasswordSlice = createSlice({
  name: 'securityAccess/changePassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.isValidating = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isValidating = false;
      state.fulfilled = Boolean(action.payload);
      state.error = action.payload.validationResults.validationResultList.length !== 0;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.fulfilled = false;
      state.isValidating = false;
      state.error = action.payload;
    });
  },
});

export const changePasswordReducer = changePasswordSlice.reducer;
