import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks/hook';
import { selectRolesListRoles } from 'redux/slice/userAccountsSlice';
import { selectCompanyId } from 'redux/slice/configSlice';
import { GetDepartmentList } from 'services/company-access/companyAccess.service';
import {
  GetDepartmentListRequest,
  GetDepartmentListResponse,
  IDepartment,
} from 'services/company-access/companyAccess.contracts';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { useViewPermissionByPartPath } from 'util/customHooks/useViewPermission';
import WpZeroState from '@wagepoint/ui-toolkit/components/wp-zeroState/wpZeroState';
import { AddAdmin as AddAdminIllustration } from '@wagepoint/ui-toolkit/assets/illustrations/addAdmin';
import { GlobalStyles } from 'styles/globalStyles';
interface Props {
  isLoading: boolean;
  emptyCardDetails: {
    title: string;
    description?: string;
    buttonContent?: string;
    pathname?: string;
  };
}

const ZerostateCards: React.FC<Props> = (props) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const roles = useAppSelector(selectRolesListRoles);
  const companyId = useAppSelector(selectCompanyId);
  const { isViewOnlyPermission } = useViewPermissionByPartPath();
  const { isLoading } = props;
  const globalStyles = GlobalStyles();

  const [departmentList, setDepartmentList] = React.useState<IDepartment[]>([]);

  useEffect(() => {
    if (roles.securityRoleType === SecurityRoleTypeEnum.Manager) getDepartmentList();
  }, [roles]);

  const getDepartmentList = async () => {
    const request: GetDepartmentListRequest = {
      companyId,
    };
    const response: GetDepartmentListResponse = await GetDepartmentList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      setDepartmentList(response.departmentList);
    } else {
      setDepartmentList([]);
    }
  };

  const handleButtonClick = () => {
    history.push({
      pathname: `${path}${props.emptyCardDetails.pathname}`,
    });
  };

  return (
    <>
      <WpZeroState
        title={props.emptyCardDetails.title}
        description={props.emptyCardDetails.description}
        illustration={
          <div className={globalStyles.maxImgSmall}>
            <AddAdminIllustration />
          </div>
        }
        button={
          props.emptyCardDetails.buttonContent && isLoading
            ? {
                label: props.emptyCardDetails.buttonContent,
                disabled:
                  (departmentList.length === 0 && roles.securityRoleType === SecurityRoleTypeEnum.Manager) ||
                  isViewOnlyPermission
                    ? true
                    : false,
                fn: handleButtonClick,
              }
            : undefined
        }
      />
    </>
  );
};

export default ZerostateCards;
