import { Post } from 'services/httpClient';
import {
  IPersistNewEmployeeRelevantStateRequest,
  IPersistNewEmployeeRelevantStateResponse,
  IPersistNewRelevantStateVacationSetupRequest,
  IPersistNewRelevantStateVacationSetupResponse,
  IPersistNewRelevantStateTaxDetailsRequest,
  IPersistNewRelevantStateTaxDetailsResponse,
  IValidateCompanyFirstPayrollRequest,
  IValidateCompanyFirstPayrollResponse,
  IGetEmployeeRelevantStateRequest,
  IGetEmployeeRelevantStateResponse,
  IGetProvinceVacationRequest,
  IGetProvinceVacationResponse,
  IGetTaxDetailRequest,
  IGetTaxDetailResponse,
  IRemoveRelevantStateRequest,
  IRemoveRelevantStateResponse,
  IGetEmployeeRelevantStateListRequest,
  IGetEmployeeRelevantStateListResponse,
  ICompletePoEProcessRequest,
  ICompletePoEProcessResponse,
  ICanUpdateProvinceOfEmploymentRequest,
  ICanUpdateProvinceOfEmploymentResponse,
} from './addPoEAccess.contracts';

const PersistNewEmployeeRelevantStateRequestUrl = '/appcorepersonaccess/PersistNewEmployeeRelevantState';
const GetEmployeeRelevantStateRequestUrl = '/appcorepersonaccess/GetEmployeeRelevantState';
const PersistNewRelevantStateVacationSetupRequestUrl = '/appcorejobaccess/PersistNewRelevantStateVacationSetup';
const GetProvinceVacationRequestUrl = '/appcorejobaccess/GetProvinceVacation';
const PersistNewRelevantStateTaxDetailsRequestUrl = '/financepersontaxaccess/PersistNewRelevantStateTaxDetails';
const GetTaxDetailRequestUrl = '/financepersontaxaccess/GetTaxDetail';
const RemoveRelevantStateRequestUrl = '/financepersontaxaccess/RemoveRelevantState';
const ValidateCompanyFirstPayrollRequestUrl = '/payrollpaygroupaccess/ValidateCompanyFirstPayroll';
const GetEmployeeRelevantStateListRequestUrl = '/payrollPersonOnboardAccess/GetEmployeeRelevantStateList';
const CompletePoERequestUrl = 'payrollPersonOnboardAccess/CompletePoEProcess';
const CanUpdateProvinceOfEmploymentUrl = 'payrollPersonOnboardAccess/CanUpdateProvinceOfEmployment';

export const persistNewEmployeeRelevantState = (
  request: IPersistNewEmployeeRelevantStateRequest
): Promise<IPersistNewEmployeeRelevantStateResponse> => {
  return Post(PersistNewEmployeeRelevantStateRequestUrl, request);
};

export const persistNewRelevantStateVacationSetup = (
  request: IPersistNewRelevantStateVacationSetupRequest
): Promise<IPersistNewRelevantStateVacationSetupResponse> => {
  return Post(PersistNewRelevantStateVacationSetupRequestUrl, request);
};

export const persistNewRelevantStateTaxDetails = (
  request: IPersistNewRelevantStateTaxDetailsRequest
): Promise<IPersistNewRelevantStateTaxDetailsResponse> => {
  return Post(PersistNewRelevantStateTaxDetailsRequestUrl, request);
};

export const validateCompanyFirstPayroll = (
  request: IValidateCompanyFirstPayrollRequest
): Promise<IValidateCompanyFirstPayrollResponse> => {
  return Post(ValidateCompanyFirstPayrollRequestUrl, request);
};

export const getEmployeeRelevantState = (
  request: IGetEmployeeRelevantStateRequest
): Promise<IGetEmployeeRelevantStateResponse> => {
  return Post(GetEmployeeRelevantStateRequestUrl, request);
};

export const getProvinceVacation = (request: IGetProvinceVacationRequest): Promise<IGetProvinceVacationResponse> => {
  return Post(GetProvinceVacationRequestUrl, request);
};

export const getTaxDetail = (request: IGetTaxDetailRequest): Promise<IGetTaxDetailResponse> => {
  return Post(GetTaxDetailRequestUrl, request);
};

export const removeRelevantState = (request: IRemoveRelevantStateRequest): Promise<IRemoveRelevantStateResponse> => {
  return Post(RemoveRelevantStateRequestUrl, request);
};

export const getEmployeeStateList = (
  request: IGetEmployeeRelevantStateListRequest
): Promise<IGetEmployeeRelevantStateListResponse> => {
  return Post(GetEmployeeRelevantStateListRequestUrl, request);
};

export const completePoEProcess = (request: ICompletePoEProcessRequest): Promise<ICompletePoEProcessResponse> => {
  return Post(CompletePoERequestUrl, request);
};

export const canUpdateProvinceOfEmployment = (
  request: ICanUpdateProvinceOfEmploymentRequest
): Promise<ICanUpdateProvinceOfEmploymentResponse> => {
  return Post(CanUpdateProvinceOfEmploymentUrl, request);
};
