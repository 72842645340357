import {
  IFlattenPermissionCategory,
  IFlattenPermissionSubCategory,
  IFlattenPermissionWithNoAccess,
} from './interface/flattenPermissionsInterface';
import { PermissionAccessTypeEnum } from './enum/flattenPermissionsEnum';

/* 
1. This function adds the new "NoAccess" permission object to the end of the subcategory's permission list
2. isSelected is also handled from ui side.

Sample Input:
[{..., permissionList:[{...read, isSelected:true}, {...write,isSelected:true}]}]

Sample Output
[{..., permissionList:[{...read,isSelected:false}, {...write,isSelected:true}, {...noaccess,isSelected:false}]}]
 */

export const updatedOriginalPermissionsWithNoAccess = (originalJson: any, addNoAccessPermission = true) => {
  // make a copy of the original security role object
  const updatedSecurityRole = [...originalJson];

  if (!updatedSecurityRole) return;

  // Loop through each permission category in the securityRole object
  updatedSecurityRole.forEach((permissionCategory: IFlattenPermissionCategory) => {
    // Loop through each permission subcategory in the permission category
    permissionCategory.permissionSubCategoryList.forEach((permissionSubcategory: IFlattenPermissionSubCategory) => {
      // Find the selected object with isReadWrite=true and isRead=false and isSubmit=false and isApprove=false
      const selectedPermissionReadWrite = permissionSubcategory.permissionList.find(
        (permission: IFlattenPermissionWithNoAccess) =>
          permission.isSelected &&
          permission.isReadWrite &&
          !permission.isRead &&
          !permission.isSubmit &&
          !permission.isApprove
      );

      // Find the selected object with isReadWrite=false and isRead=false and isSubmit=true and isApprove=false
      const selectedPermissionSubmit = permissionSubcategory.permissionList.find(
        (permission: IFlattenPermissionWithNoAccess) =>
          permission.isSelected &&
          !permission.isReadWrite &&
          !permission.isRead &&
          permission.isSubmit &&
          !permission.isApprove
      );

      // If such objects exist, update isSelected property of all other objects in the array to false
      if (selectedPermissionReadWrite || selectedPermissionSubmit) {
        permissionSubcategory.permissionList.forEach((permission: IFlattenPermissionWithNoAccess) => {
          if (permission !== selectedPermissionReadWrite && permission !== selectedPermissionSubmit) {
            permission.isSelected = false;
          }
        });
      }

      // Loop through each permission in the permission subcategory to set the permissionType based on isReadWrite and isRead
      permissionSubcategory.permissionList.forEach((permission: IFlattenPermissionWithNoAccess) => {
        if (permission.isReadWrite || permission.isSubmit) {
          permission.permissionType = PermissionAccessTypeEnum.Write;
        } else if (permission.isRead || permission.isApprove) {
          permission.permissionType = PermissionAccessTypeEnum.Read;
        } else {
          permission.permissionType = PermissionAccessTypeEnum.Noaccess;
        }
      });

      // Only add the noAccessPermissionObject if addNoAccessPermission prop is true and there isn't already a NoAccess permission in the permissionList array
      if (
        addNoAccessPermission &&
        permissionSubcategory.permissionList &&
        permissionSubcategory.permissionList?.length
      ) {
        const noAccessPermissionExists = permissionSubcategory.permissionList.some(
          (permission: IFlattenPermissionWithNoAccess) => permission.name === `${permissionSubcategory.name}/NoAccess`
        );
        if (addNoAccessPermission && !noAccessPermissionExists) {
          const noAccessPermissionObject: IFlattenPermissionWithNoAccess = {
            id: Number(`${permissionSubcategory.permissionList[0]?.id}${permissionSubcategory.permissionList[1]?.id}`),
            name: `${permissionSubcategory.name}/NoAccess`,
            isRead: false,
            isReadWrite: false,
            isSelected: !(
              permissionSubcategory.permissionList[0]?.isSelected || permissionSubcategory.permissionList[1]?.isSelected
            ),
            isSubmit: false,
            isApprove: false,
            permissionType: PermissionAccessTypeEnum.Noaccess, // Set permissionType to 3 for the new permission object
          };
          permissionSubcategory.permissionList.push(noAccessPermissionObject);
        }
      }
    });
  });

  return updatedSecurityRole;
};
