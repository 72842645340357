import { RootState } from 'redux/store/store';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  IGetPersonJobListRequest,
  IGetPersonJobListResponse,
  IPersonJobDetail,
  IVacation,
} from 'services/job-access/jobAccess.contracts';
import { GetPersonJobList } from 'services/job-access/jobAccess.service';

import {
  IGetTaxDetailRequest,
  IGetTaxDetailResponse,
  ITaxDetail,
} from 'services/person-tax-access/personTaxAccess.contracts';
import { getTaxDetail as getTaxDetailService } from 'services/person-tax-access/personTaxAccess.service';

import {
  IEmployeeInfo,
  IGetPersonRequest,
  IGetPersonResponse,
  IPerson,
} from 'services/person-access/personAccess.contracts';
import { getPerson as getPersonService } from 'services/person-access/personAccess.service';

import {
  IBankAccount,
  IGetPersonBankAccountListRequest,
  IGetPersonBankAccountListResponse,
  PaymentMethodTypeEnum,
} from 'services/person-bank-access/personBankAccess.contracts';
import { getBankPersonList as getBankPersonListService } from 'services/person-bank-access/personBankAccess.service';

import {
  FieldTypeEnum,
  IEmployeeFieldSummary,
  IGetEmployeeFieldSummaryListRequest,
  IGetEmployeeFieldSummaryListResponse,
} from 'services/employee-field-access/employeeFieldAccess.contracts';
import { GetEmployeeFieldSummaryList } from 'services/employee-field-access/employeeFieldAccess.service';
import {
  IEmployeePayrollData,
  IGetPayrollRequest,
  IGetPayrollResponse,
} from 'services/priorpayroll-access/priorpayrollAccess.contracts';
import { getPriorPayroll as getPriorPayrollService } from 'services/priorpayroll-access/priorpayrollAccess.service';

interface IJobIDB {
  id?: number;
  name?: string;
  incomes?: Array<IEmployeeFieldSummary>;
  deductions?: Array<IEmployeeFieldSummary>;
  benefits?: Array<IEmployeeFieldSummary>;
}
export interface IFinalReview {
  basicInfo: {
    employeeInfo: Partial<IEmployeeInfo>;
    person: Partial<IPerson>;
    bankAccountList: Array<IBankAccount>;
    paymentMethodType: PaymentMethodTypeEnum;
  };
  jobDetails: {
    personJobDetailList: Array<IPersonJobDetail>;
    vacation: Partial<IVacation>;
  };
  taxInfo: {
    taxDetail: Partial<ITaxDetail>;
  };
  IDB: {
    jobsIDB: Array<IJobIDB>;
  };
  priorPayroll: {
    employeePayrollDataList: Array<IEmployeePayrollData>;
  };
}

const initialState: IFinalReview = {
  basicInfo: {
    person: {},
    employeeInfo: {},
    bankAccountList: [],
    paymentMethodType: PaymentMethodTypeEnum.NotDefined,
  },
  jobDetails: {
    personJobDetailList: [],
    vacation: {},
  },
  taxInfo: {
    taxDetail: {},
  },
  IDB: {
    jobsIDB: [],
  },
  priorPayroll: {
    employeePayrollDataList: [],
  },
};

export const getPerson = createAsyncThunk(
  'people/onboarding/finalReview/getPerson',
  async (request: IGetPersonRequest) => {
    const response: IGetPersonResponse = await getPersonService(request);
    return response;
  }
);

export const getBankPersonList = createAsyncThunk(
  'people/onboarding/finalReview/getBankPersonList',
  async (request: IGetPersonBankAccountListRequest) => {
    const response: IGetPersonBankAccountListResponse = await getBankPersonListService(request);
    return response;
  }
);

export const getPersonJobList = createAsyncThunk(
  'people/onboarding/finalReview/getPersonJobList',
  async (request: IGetPersonJobListRequest) => {
    const response: IGetPersonJobListResponse = await GetPersonJobList(request);
    return response;
  }
);

export const getTaxDetail = createAsyncThunk(
  'people/onboarding/finalReview/getTaxDetail',
  async (request: IGetTaxDetailRequest) => {
    const response: IGetTaxDetailResponse = await getTaxDetailService(request);
    return response;
  }
);

export const getEmployeeFieldSummaryList = createAsyncThunk(
  'people/onboarding/finalReview/getEmployeeFieldSummaryList',
  async (request: IGetEmployeeFieldSummaryListRequest) => {
    const response: IGetEmployeeFieldSummaryListResponse = await GetEmployeeFieldSummaryList({
      ...request,
    });
    return response;
  }
);

export const getPriorPayroll = createAsyncThunk(
  'people/onboarding/finalReview/getPriorPayrollList',
  async (request: IGetPayrollRequest) => {
    const response: IGetPayrollResponse = await getPriorPayrollService(request);
    return response;
  }
);

const peopleFinalReviewSlice = createSlice({
  name: 'peoplebasicinfo',
  initialState,
  reducers: {
    resetFinalReview: (state) => {
      state.basicInfo = initialState.basicInfo;
      state.jobDetails = initialState.jobDetails;
      state.taxInfo = initialState.taxInfo;
      state.IDB = initialState.IDB;
      state.priorPayroll = initialState.priorPayroll;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPerson.fulfilled, (state: IFinalReview, action) => {
      state.basicInfo.employeeInfo = action.payload.employeeInfo;
      state.basicInfo.person = action.payload.person;
    });
    builder.addCase(getBankPersonList.fulfilled, (state: IFinalReview, action) => {
      state.basicInfo.bankAccountList = action.payload.bankAccountList;
      state.basicInfo.paymentMethodType = action.payload.paymentMethodType;
    });
    builder.addCase(getPersonJobList.fulfilled, (state: IFinalReview, action) => {
      state.jobDetails.personJobDetailList = action.payload.personJobDetailList || [];
      state.jobDetails.vacation = action.payload.vacation;
    });
    builder.addCase(getTaxDetail.fulfilled, (state: IFinalReview, action) => {
      state.taxInfo.taxDetail = action.payload.taxDetail;
    });
    builder.addCase(getEmployeeFieldSummaryList.fulfilled, (state: IFinalReview, action) => {
      const jobsIDB = [...state.jobDetails.personJobDetailList].map((job) => {
        const filtredByJob = action.payload.employeeFieldSummaryList.filter((IDB) => IDB.jobId === job.id);
        const jobIDB = {
          id: job.id,
          name: job.job?.name,
          incomes: filtredByJob.filter(
            (IDB) => IDB.fieldType === FieldTypeEnum.income || IDB.fieldType === FieldTypeEnum.additionalIncome
          ),
          deductions: filtredByJob.filter((IDB) => IDB.fieldType === FieldTypeEnum.deduction),
          benefits: filtredByJob.filter((IDB) => IDB.fieldType === FieldTypeEnum.benefit),
        };
        return jobIDB;
      });
      state.IDB.jobsIDB = jobsIDB;
    });
    builder.addCase(getPriorPayroll.fulfilled, (state: IFinalReview, action) => {
      state.priorPayroll.employeePayrollDataList = action.payload.employeePayrollDataList;
    });
  },
});

export const { resetFinalReview } = peopleFinalReviewSlice.actions;
export const peopleFinalReviewReducer = peopleFinalReviewSlice.reducer;
export const getFinalReviewBasicInfo = (state: RootState) => state.peopleFinalReviewReducer.basicInfo;
export const selectFinalReviewBasicInfoPerson = createSelector(getFinalReviewBasicInfo, ({ person }) => person);
export const selectFinalReviewBasicEmployeeInfo = createSelector(
  getFinalReviewBasicInfo,
  ({ employeeInfo }) => employeeInfo
);
export const selectFinalReviewBasicBankAccountList = createSelector(
  getFinalReviewBasicInfo,
  ({ bankAccountList }) => bankAccountList
);
export const selectFinalReviewBasicPaymentMethodType = createSelector(
  getFinalReviewBasicInfo,
  ({ paymentMethodType }) => paymentMethodType
);
export const getFinalReviewJobDetails = (state: RootState) => state.peopleFinalReviewReducer.jobDetails;
export const selectPersonJobDetailList = createSelector(
  getFinalReviewJobDetails,
  ({ personJobDetailList }) => personJobDetailList
);
export const getFinalReviewTaxInfo = (state: RootState) => state.peopleFinalReviewReducer.taxInfo;
export const getFinalReviewIDB = (state: RootState) => state.peopleFinalReviewReducer.IDB;
export const selectJobsIDB = createSelector(getFinalReviewIDB, ({ jobsIDB }) => jobsIDB);
export const getFinalReviewPriorPayroll = (state: RootState) => state.peopleFinalReviewReducer.priorPayroll;
export const selectEmployeePayrollDataList = createSelector(
  getFinalReviewPriorPayroll,
  ({ employeePayrollDataList }) => employeePayrollDataList
);
export const selectTaxDetail = createSelector(getFinalReviewTaxInfo, ({ taxDetail }) => taxDetail);
