import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useQueryFnWithLoader } from 'util/customHooks/useQueryFnWithLoader';

import companyAccess, { GetPartnerCompanyListResponseInterface } from 'services/finance/models/access/companyAccess';

import { useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { createQueryKeys } from 'util/queryKeyFactory';

export const accountingFirmListQueryKeys = createQueryKeys(['settings', 'accounting-bookkeeping-firm'], {
  getPartnerCompanyListQuery: (companyId?: number) => ({
    key: [companyId],
  }),
});

export const useGetPartnerCompanyListQuery = <TData = GetPartnerCompanyListResponseInterface['companySummaryList']>(
  options?: UseQueryOptions<GetPartnerCompanyListResponseInterface['companySummaryList'], unknown, TData>
) => {
  const selectedCompanyId = useAppSelector(selectCompanyId);

  const withLoadingAndError = useQueryFnWithLoader();

  return useQuery({
    queryKey: accountingFirmListQueryKeys.getPartnerCompanyListQuery(selectedCompanyId).key,
    queryFn: async () => {
      try {
        const { companySummaryList } = await withLoadingAndError(companyAccess.getPartnerCompanyList, {
          clientId: selectedCompanyId,
          includeLinkedCompanies: false,
        });
        return companySummaryList;
      } catch (e) {
        throw e;
      }
    },
    enabled: !!selectedCompanyId,
    retry: false,
    ...options,
  });
};
