export enum SecurityRoleTypeEnum {
  NotDefined = 0,
  AccountOwner = 1,
  AccountSignatory = 2,
  Manager = 3,
  Administrator = 4,
  ReportsManager = 5,
  AdministratorSubmitter = 6,
  AdministratorApprover = 7,
  AccountAdministrator = 8,
  Employee = 9,
  Contractor = 10,
}
