import { IValidationResults } from 'services/shared/validationResults.contracts';

export enum TaxGroupTypeEnum {
  NotDefined = 0,
  Federal,
  Provincial,
}

export interface IEmployeeTaxList {
  id: number;
  documentId: string;
  claimDescription: string;
  taxGroupType: number;
  stateId: number;
  documentName: string;
  isExempt: boolean;
  companyFieldId: number;
}
export interface IAdditionalTax {
  isTaxIncrement: boolean;
  amount?: number;
  stateId?: number;
  companyFieldId?: number;
  taxGroupType?: TaxGroupTypeEnum;
  name?: string;
}
export interface ITaxDetail {
  provinceOfEmployment?: number;
  taxSchemeType?: ITaxSchemeType;
  taxClaimCodeList?: Array<ITaxClaimCode> | null;
  additionalTaxList?: Array<IAdditionalTax> | null;
}

export interface IGetTaxExemptionCodeListRequest {
  stateId?: number;
  taxGroupType?: TaxGroupTypeEnum; // make required after old code removal
}

export interface IGetTaxExemptionCodeListResponse {
  taxClaimCodeList: Array<ITaxClaimCode>;
  validationResults: IValidationResults;
}
export interface ITaxClaimCode {
  id: number;
  claimDesciprion?: string;
  companyFieldId?: number;
  stateId?: number;
  taxGroupType?: TaxGroupTypeEnum;
  minimumClaim?: number;
  maximumClaim?: number;
}
export interface IGetInitialStateRequest {
  employmentStateId?: number; // remove when anoop code is removed
  companyId?: number; // remove when anoop code is removed
  personId?: number;
  getByStateId?: boolean;
}
export interface IGetInitialStateResponse {
  taxSchemeTypeList: Array<ITaxSchemeType>;
  validationResults: IValidationResults;
}
export interface ITaxSchemeType {
  id?: number;
  name?: string;
  description?: string;
  taxTypeSettingList?: Array<ITaxTypeSetting>;
}
export interface ITaxTypeSetting {
  id?: number;
  name?: string;
  isApplicable?: boolean;
  isDisabled?: boolean;
  companyFieldId?: number;
  taxGroupType?: TaxGroupTypeEnum;
}

export interface IGetTaxDetailRequest {
  personId: number;
  companyId?: number;
  getByStateId?: boolean;
  stateId?: number;
  includeTurnedOffTaxes?: boolean;
}

export interface IGetTaxDetailResponse {
  taxDetail: ITaxDetail;
  validationResults: IValidationResults;
}

export interface IEmployeeTaxList {
  id: number;
  documentId: string;
  claimDescription: string;
  taxGroupType: number;
  stateId: number;
  documentName: string;
  isExempt: boolean;
  companyFieldId: number;
}

export interface IGetEmployeeTaxDetailRequest {
  companyId: number;
  employeeId: number;
}
export interface IGetEmployeeTaxDetailResponse {
  canEdit: boolean;
  employeeTaxList: IEmployeeTaxList[];
}

export interface IPersistSettingsRequest {
  personId: number;
  taxDetail: ITaxDetail;
}

export interface IGetEmployeeTaxDetailRequest {
  companyId: number;
  employeeId: number;
}
export interface IGetEmployeeTaxDetailResponse {
  canEdit: boolean;
  employeeTaxList: IEmployeeTaxList[];
}

export interface IPersistSettingsResponse {
  validationResults: IValidationResults;
}

export enum TaxSettingsIdEnum {
  Eipremium = 21,
  FederalIncomeTax = 22,
  CppEmpr = 93,
  Cpp = 94,
  EiEmpr = 115,
  Cpp2Empr = 208,
  Cpp2 = 209,
  Ei = 116,
  Provincialincometax = 112,
}

export interface ITaxTypeSettingUI {
  id?: number;
  name?: string;
  isApplicable?: boolean;
  isDisabled?: boolean;
  companyFieldId?: number;
  taxGroupType?: TaxGroupTypeEnum;
  showWarning: boolean;
  isHeader: boolean;
  isStatement: boolean;
  isProvincial: boolean;
}

export enum TaxSettingItemsEnum {
  StandardFederal = 1,
  StandardCpp,
  StandardEi,
  StandardSEi,
  StandardProvisionalIT,
}

export enum TaxTreatmentItemsEnum {
  Standard = 1,
  IndigenousStatus,
  Clergy,
  SoleProprietor,
}
