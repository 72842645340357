import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { selectIsValidCompany } from 'redux/slice/accountingFirmSlice';
import { useAppSelector } from 'redux/hooks/hook';
import { ZeroStateContainerStyles } from './containers/zeroStateContainerStyles';
import Roles from '../radio-group/rolesRadioGroup';
import FormContainer from './containers/formContainer';
import TypographyContainer from './containers/typographyContainer';
import ButtonContainer from './containers/buttonContainer';
import ValidateEmailContainer from './containers/validateEmailContainer';
import { useViewPermission } from 'util/customHooks/useViewPermission';
import { useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validationSchema from 'pages/settings/components/accounting-bookkeeping-firm/common/company-email-layout/schema/validationSchema';
import validationSchemaById from './schema/validationSchemaIDForm';
import { useTranslation } from 'react-i18next';
import { ManageViewOfAccountingFirm } from '../..';

export enum InviteEnum {
  CompanyId = 1,
  Email = 2,
}

export enum ValidEnum {
  NotDefined = 0,
  Valid = 1,
  InValid = 2,
  ValidEmail = 3,
  InValidEmail = 4,
}

interface IProps {
  setView?: (value: number, permission?: number) => void;
  from?: ManageViewOfAccountingFirm;
  needValidation?: boolean;
}

const FORM_ID = 'inviteFirm';

const CommonContainer: React.VFC<IProps> = ({ needValidation, from }) => {
  const classes = ZeroStateContainerStyles();
  const isValidCompany = useAppSelector(selectIsValidCompany);
  const { path } = useRouteMatch();
  const { t } = useTranslation(['userAccounts']);

  const [inviteFrom, setInviteFrom] = useState(InviteEnum.CompanyId);

  const handleTypographyClick = (type?: any) => {
    setInviteFrom(type);
  };

  const methods = useForm({
    resolver: inviteFrom == InviteEnum.Email ? yupResolver(validationSchema(t)) : yupResolver(validationSchemaById(t)),
    mode: 'onSubmit',
    reValidateMode: inviteFrom == InviteEnum.Email ? 'onChange' : 'onSubmit',
  });

  const {
    formState: { isSubmitted },
  } = methods;

  return (
    <>
      <FormProvider {...methods}>
        <form id={FORM_ID} className={classes.formGridContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormContainer inviteFrom={inviteFrom} viewOnly={useViewPermission(path)?.isViewOnlyPermission} />
              <TypographyContainer
                inviteFrom={inviteFrom}
                handleTypographyClick={handleTypographyClick}
                viewOnly={useViewPermission(path)?.isViewOnlyPermission}
                submitted={isSubmitted}
              />
            </Grid>
            {isValidCompany === ValidEnum.InValidEmail && <ValidateEmailContainer />}
            <Grid item xs={12}>
              <Roles onValidation={needValidation} />
            </Grid>
            <ButtonContainer formId={FORM_ID} from={from} />
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default CommonContainer;
