import { useAppSelector } from 'redux/hooks/hook';
import { selectCurrentCompanyStatus } from 'redux/slice/accountingDashboardSlices/clientDirectorySlice';
import { ClientStatusTypeEnum } from 'services/finance/models/access/billingAccess';

export const useHasClientInfoAccess = () => {
  const clientOnboardingStatus = useAppSelector(selectCurrentCompanyStatus);

  return ![
    ClientStatusTypeEnum.disconnected,
    ClientStatusTypeEnum.inviteSent,
    ClientStatusTypeEnum.closed,
    ClientStatusTypeEnum.closedWithReportsAccess,
    ClientStatusTypeEnum.clientRequestReceived,
  ].includes(clientOnboardingStatus as ClientStatusTypeEnum);
};
