import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IPersistNewEmployeeRelevantStateRequest } from 'services/add-employment-province-access/addPoEAccess.contracts';

interface IPoEBasicInfo {
  province: any;
  payPeriod: any;
  ifAddress: boolean;
  homeAddress: {
    address1: string;
    address2: string;
    city: string;
    provinceResident: any;
    postal: string;
  };
  prevProvince: string | number;
  basicInfoRequest: IPersistNewEmployeeRelevantStateRequest | null;
}

const initialState: IPoEBasicInfo = {
  province: null,
  payPeriod: null,
  ifAddress: false,
  homeAddress: {
    address1: '',
    address2: '',
    city: '',
    provinceResident: null,
    postal: '',
  },
  prevProvince: '',
  basicInfoRequest: null,
};

export const poeBasicInfoSlice = createSlice({
  name: 'poeBasicInfoSlice',
  initialState,
  reducers: {
    updateBasicInfoAction: (state, action: PayloadAction<{ key: keyof IPoEBasicInfo; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    resetBasicInfoAction: (state) => {
      state.province = null;
      state.payPeriod = null;
      state.homeAddress = initialState.homeAddress;
      state.ifAddress = false;
      state.prevProvince = '';
      state.basicInfoRequest = null;
    },
  },
});

export const { updateBasicInfoAction, resetBasicInfoAction } = poeBasicInfoSlice.actions;
export const poeBasicInfoReducer = poeBasicInfoSlice.reducer;
export const getPoEBasicInfoStates = (state: RootState) => state.poeBasicInfoReducer;
export const selectPoeBasicInfoIfAddress = (state: RootState) => state.poeBasicInfoReducer?.ifAddress;
export const selectPoeBasicInfoPayPeriod = (state: RootState) => state.poeBasicInfoReducer?.payPeriod;
export const selectPoeBasicInfoHomeAddress = (state: RootState) => state.poeBasicInfoReducer?.homeAddress;
export const selectPoeBasicInfoProvince = (state: RootState) => state.poeBasicInfoReducer?.province;
export const selectPoeBasicInfoPrevProvince = (state: RootState) => state.poeBasicInfoReducer?.prevProvince;
export const selectPoeBasicInfoBasicInfoRequest = (state: RootState) => state.poeBasicInfoReducer?.basicInfoRequest;
