import { makeStyles } from '@material-ui/core/styles';

export const ZeroStateContainerStyles = makeStyles((theme) => ({
  formContainer: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingTop: 24,
    '& .MuiFormControlLabel-root': {
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      marginLeft: '-20px',
    },
    '& .MuiIconButton-root': {
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      padding: '4px !important',
    },
  },
  formGridContainer: {
    width: '100%',
  },
  roleTypo: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingBottom: 4,
  },
  formGroup: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingLeft: 10,
  },
  inviteButton: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingTop: '24px',
    paddingLeft: '0px !important',
  },
  button: {
    marginLeft: 0,
  },
  infoIcon: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingLeft: 8,
    color: theme.palette.primarycolor.primaryBrown,
    alignSelf: 'center',
  },
  infoContainer: {
    display: 'flex',
  },
  fromCardContainer: {
    display: 'block',
  },
  disabledState: {
    pointerEvents: 'none',
  },
}));
