import { Link } from 'react-router-dom';
import { IWpTabProps, WpTabs } from '@wagepoint/ui-toolkit/components/wp-tabs/wpTabs';
import React, { useEffect } from 'react';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { useTranslation } from 'react-i18next';
import useCheckSecurityRoleType from 'pages/accounting-dashboard/commonHooks/useCheckSecurityRoleType';
import { useAppSelector } from 'redux/hooks/hook';
import { selectAccountOwnerName } from 'redux/slice/configSlice';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

const useTransferOwnerShipConditions = () => {
  const { feature } = useFeatureFlag();
  const restrictTransferofOwnship = feature(FEATURE_FLAG_KEYS.RestrictTransferOwnershitAO);
  const { isAccountOwner } = useCheckSecurityRoleType();

  return restrictTransferofOwnship ? !isAccountOwner : false;
};
const RoleTabsLink: React.FC<{ url: string; roleType: any }> = (props) => {
  const { t } = useTranslation(['userAccounts']);
  const [tabs, setTabs] = React.useState<Array<IWpTabProps>>([]);
  const restrictionAdded = useTransferOwnerShipConditions();
  const { accountOwnerName } = useAppSelector(selectAccountOwnerName);
  const setupTabs = () => {
    if (props.roleType?.securityRoleType !== SecurityRoleTypeEnum.AccountOwner) {
      setTabs([
        {
          label: t('roleTabLinks.permission'),
          key: 1,
          component: Link,
          disableRipple: true,
          to: `${props.url}/permission-settings`,
        },
        {
          label: t('roleTabLinks.assignedPeople'),
          key: 2,
          component: Link,
          disableRipple: true,
          to: `${props.url}/assigned-people`,
        },
      ]);
    } else {
      setTabs([
        {
          label: t('roleTabLinks.permission'),
          key: 1,
          component: Link,
          disableRipple: true,
          to: `${props.url}/permission-settings`,
        },
        {
          label: t('roleTabLinks.transferOwnership'),
          key: 2,
          component: Link,
          disableRipple: true,
          disabled: restrictionAdded,
          tooltipProps: restrictionAdded ? { title: t('roleTabLinks.disabled', { name: accountOwnerName }) } : null,
          to: `${props.url}/transfer-ownership`,
        },
      ]);
    }
  };

  useEffect(() => {
    setupTabs();
  }, [props]);

  const activeNav = () => {
    if (
      window.location.pathname.includes('/transfer-ownership') ||
      window.location.pathname.includes('/assigned-people')
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <>
      <WpTabs id="roleTabslinks" value={activeNav} tabs={tabs} />
    </>
  );
};

export default RoleTabsLink;
