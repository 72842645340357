import { Post } from 'services/httpClient';
import {
  IPersistPersonJobRequest,
  IPersistPersonJobResponse,
  IGetPersonJobListResponse,
  IGetPersonJobListRequest,
  IPersistJobResponse,
  IPersistJobRequest,
  IPersistDepartmentResponse,
  IPersistDepartmentRequest,
  IGetJobListRequest,
  IGetJobListResponse,
  IGetDepartmentListRequest,
  IGetDepartmentListResponse,
  IGetJobSummaryListRequest,
  IGetJobSummaryListResponse,
  IGetDepartmentSummaryListRequest,
  IGetDepartmentSummaryListResponse,
  IManageDepartmentStatusRequest,
  IManageDepartmentStatusResponse,
  IManageJobStatusRequest,
  IManageJobStatusResponse,
  IPersistPeopleJobAssignmentRequest,
  IPersistPeopleJobAssignmentResponse,
  IPersistCompensationRateRequest,
  IPersistCompensationRateResponse,
  IPersistJobLocationRequest,
  IPersistJobLocationResponse,
  ICreateDefaultPersonJobRequest,
  ICreateDefaultPersonJobResponse,
  IGetProvinceVacationRequest,
  IGetProvinceVacationResponse,
} from './jobAccess.contracts';

const servicedomain = '/appcorejobaccess/';
const persistPersonJob = `${servicedomain}persistpersonjob`;
const createdefaultpersonjob = `${servicedomain}createdefaultpersonjob`;
const getPersonJobList = `${servicedomain}getpersonjoblist`;
const persistJob = `${servicedomain}persistjob`;
const persistDepartment = `${servicedomain}persistdepartment`;
const getJob = `${servicedomain}getjoblist`;
const getDepartment = `${servicedomain}getdepartmentlist`;
const getJobSummaryList = `${servicedomain}getjobsummarylist`;
const getDepartmentSummaryList = `${servicedomain}getdepartmentsummarylist`;
const manageDepartmentStatus = `${servicedomain}managedepartmentstatus`;
const manageJobStatus = `${servicedomain}managejobstatus`;
const PersistPeopleJobAssignmentURL = `${servicedomain}persistpeoplejobassignment`;
const PersistCompensationRateURL = `${servicedomain}persistcompensationrate`;
const PersistJobLocationURL = `${servicedomain}persistjoblocation`;
const GetProvinceVacationURL = `${servicedomain}GetProvinceVacation`;

export const PersistPersonJob = (request: IPersistPersonJobRequest): Promise<IPersistPersonJobResponse> => {
  return Post(persistPersonJob, request);
};
export const CreateDefaultPersonJob = (
  request: ICreateDefaultPersonJobRequest
): Promise<ICreateDefaultPersonJobResponse> => {
  return Post(createdefaultpersonjob, request);
};
export const GetPersonJobList = (request: IGetPersonJobListRequest): Promise<IGetPersonJobListResponse> => {
  return Post(getPersonJobList, request);
};
export const PersistJob = (request: IPersistJobRequest): Promise<IPersistJobResponse> => {
  return Post(persistJob, request);
};
export const PersistDepartment = (request: IPersistDepartmentRequest): Promise<IPersistDepartmentResponse> => {
  return Post(persistDepartment, request);
};
export const GetJob = (request: IGetJobListRequest): Promise<IGetJobListResponse> => {
  return Post(getJob, request);
};
export const GetDepartment = (request: IGetDepartmentListRequest): Promise<IGetDepartmentListResponse> => {
  return Post(getDepartment, request);
};
export const GetJobSummaryList = (request: IGetJobSummaryListRequest): Promise<IGetJobSummaryListResponse> => {
  return Post(getJobSummaryList, request);
};
export const GetDepartmentSummaryList = (
  request: IGetDepartmentSummaryListRequest
): Promise<IGetDepartmentSummaryListResponse> => {
  return Post(getDepartmentSummaryList, request);
};
export const ManageDepartmentStatus = (
  request: IManageDepartmentStatusRequest
): Promise<IManageDepartmentStatusResponse> => {
  return Post(manageDepartmentStatus, request);
};
export const ManageJobStatus = (request: IManageJobStatusRequest): Promise<IManageJobStatusResponse> => {
  return Post(manageJobStatus, request);
};
export const PersistPeopleJobAssignment = (
  request: IPersistPeopleJobAssignmentRequest
): Promise<IPersistPeopleJobAssignmentResponse> => {
  return Post(PersistPeopleJobAssignmentURL, request);
};
export const PersistCompensationRate = (
  request: IPersistCompensationRateRequest
): Promise<IPersistCompensationRateResponse> => {
  return Post(PersistCompensationRateURL, request);
};
export const PersistJobLocation = (request: IPersistJobLocationRequest): Promise<IPersistJobLocationResponse> => {
  return Post(PersistJobLocationURL, request);
};
export const GetProvinceVacation = (request: IGetProvinceVacationRequest): Promise<IGetProvinceVacationResponse> => {
  return Post(GetProvinceVacationURL, request);
};
