import { Post } from 'services/httpClient';
import {
  IPersistPayrollRequest,
  IPersistPayrollResponse,
  IGetPayrollRequest,
  IGetPayrollResponse,
  IGetPayPeriodDetailRequest,
  IGetPayPeriodDetailResponse,
  IGetOpenPayPeriodSummaryListRequest,
  IGetOpenPayPeriodSummaryListResponse,
  IChangePersonStatusRequest,
  IChangePersonStatusResponse,
  IGetEmployeeStatusListRequest,
  IGetEmployeeStatusListResponse,
  IGetPriorPayrollRowResponse,
  IGetPriorPayrollRowRequest,
  IGetPriorPayrollReportRequest,
  IGetPriorPayrollReportResponse,
  IDeletePriorPayrollResponse,
  IDeletePriorPayrollRequest,
} from './priorpayrollAccess.contracts';
const servicedomain = '/payrollPriorpayrollAccess/';
const GetPriorPayroll = servicedomain + 'getpayroll';
const GetPayrollRow = servicedomain + 'GetPayrollRow';
const PersistPriorPayroll = servicedomain + 'persistPayroll';
const GetPayPeriodDetailURL = `${servicedomain}getpayperioddetail`;
const GetOpenPayPeriodSummaryListURL = `${servicedomain}getopenpayperiodsummarylist`;
const ChangePersonStatusURL = `${servicedomain}changepersonstatus`;
const GetEmployeeStatusListURL = `${servicedomain}getemployeestatuslist`;
const GetPriorPayrollReportURL = `${servicedomain}GetPriorPayrollReport`;
const DeletePriorPayrollURL = `${servicedomain}DeletePriorPayroll`;

export const getPriorPayroll = (request: IGetPayrollRequest): Promise<IGetPayrollResponse> => {
  return Post(GetPriorPayroll, request);
};
export const getPriorPayrollRow = (request: IGetPriorPayrollRowRequest): Promise<IGetPriorPayrollRowResponse> => {
  return Post(GetPayrollRow, request);
};
export const persistPriorPayroll = (request: IPersistPayrollRequest): Promise<IPersistPayrollResponse> => {
  return Post(PersistPriorPayroll, request);
};
export const GetPayPeriodDetail = (request: IGetPayPeriodDetailRequest): Promise<IGetPayPeriodDetailResponse> => {
  return Post(GetPayPeriodDetailURL, request);
};
export const GetOpenPayPeriodSummaryList = (
  request: IGetOpenPayPeriodSummaryListRequest
): Promise<IGetOpenPayPeriodSummaryListResponse> => {
  return Post(GetOpenPayPeriodSummaryListURL, request);
};
export const GetEmployeeStatusList = (
  request: IGetEmployeeStatusListRequest
): Promise<IGetEmployeeStatusListResponse> => {
  return Post(GetEmployeeStatusListURL, request);
};
export const ChangePersonStatus = (request: IChangePersonStatusRequest): Promise<IChangePersonStatusResponse> => {
  return Post(ChangePersonStatusURL, request);
};
export const GetPriorPayrollReport = (
  request: IGetPriorPayrollReportRequest
): Promise<IGetPriorPayrollReportResponse> => {
  return Post(GetPriorPayrollReportURL, request);
};
export const DeletePriorPayroll = (request: IDeletePriorPayrollRequest): Promise<IDeletePriorPayrollResponse> => {
  return Post(DeletePriorPayrollURL, request);
};
