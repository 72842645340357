import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

import {
  HoursTypeEnum,
  IDepartment,
  IGetDepartmentListRequest,
  IGetDepartmentListResponse,
  IGetJobListRequest,
  IGetJobListResponse,
  IGetPersonJobListRequest,
  IGetPersonJobListResponse,
  IJob,
  IPersistDepartmentRequest,
  IPersistDepartmentResponse,
  IPersistJobRequest,
  IPersistJobResponse,
  IPersonJobDetail,
  IVacation,
  JobPayTypeEnum,
} from 'services/job-access/jobAccess.contracts';
import {
  GetDepartment,
  GetJob,
  GetPersonJobList,
  PersistDepartment,
  PersistJob,
} from 'services/job-access/jobAccess.service';

import {
  IStateWage,
  IGetStateWageRequest,
  IGetStateWageResponse,
} from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.contracts';
import { GetStateWage } from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.service';
import holidayPayAccess, {
  GetPersonVacationBalanceRequestInterface,
  JobVacationBalanceInterface,
} from 'services/payroll/models/access/holidayPayAccess';

import {
  IGetPayPeriodDetailRequest,
  IGetPayPeriodDetailResponse,
  IPayPeriodDetail,
} from 'services/priorpayroll-access/priorpayrollAccess.contracts';
import { GetPayPeriodDetail } from 'services/priorpayroll-access/priorpayrollAccess.service';

import {
  GetWcbRateListRequest,
  GetWcbRateListResponse,
  IWcbRateSummary,
} from 'services/workers-compensation-access/workersCompensationAccess.contracts';
import { GetWcbRateList } from 'services/workers-compensation-access/workersCompensationAccess.service';

interface IVocationWithLoading extends Partial<IVacation> {
  loading: boolean;
}

interface IWcbRateSummaryExpanded extends IWcbRateSummary {
  name: string;
}
export interface IJobDetails {
  personJobDetailList: Array<IPersonJobDetail>;
  vacation: IVocationWithLoading;
  isMidCycleHire: boolean;
  payPeriodDetail: IPayPeriodDetail | null;
  departmentList: Array<IDepartment>;
  jobList: Array<IJob>;
  personJobList: Array<IJob>;
  wcbList: Array<IWcbRateSummaryExpanded>;
  stateWage: Partial<IStateWage>;
  isFormPopulated: boolean;
  jobVacationBalanceList: Array<JobVacationBalanceInterface>;
  didUserChangedCompensation: boolean;
  originalValues: { hireDate: string; jobData: IPersonJobDetail[] } | null;
  compensationDataComparer: { [key: string]: { prev: string | number; newVal: string | number } };
  selectedValues: { job: { [key: number]: number } };
  persistedValues: { [key: number]: { [key: string]: unknown } }; // this will be mapping of job-index to job-details
  autoRunDetails: {
    payType: JobPayTypeEnum;
    hoursType: HoursTypeEnum;
    isAutoRun: number;
  };
}

const initialState: IJobDetails = {
  personJobDetailList: [],
  vacation: {
    loading: false,
  },
  personJobList: [],
  isMidCycleHire: false,
  payPeriodDetail: null,
  departmentList: [],
  jobList: [],
  wcbList: [],
  didUserChangedCompensation: false,
  stateWage: {},
  compensationDataComparer: {},
  isFormPopulated: false,
  jobVacationBalanceList: [],
  originalValues: null,
  selectedValues: { job: {} },
  persistedValues: {}, // this variable is used to check if user actually changed the payType : for deleting prior payroll data if changed.
  autoRunDetails: {
    payType: JobPayTypeEnum.notDefined,
    hoursType: HoursTypeEnum.notDefined,
    isAutoRun: 0,
  },
};

export const getPersonJobList = createAsyncThunk(
  'people/onboarding/jobDetails/getPersonJobList',
  async (request: IGetPersonJobListRequest) => {
    const response: IGetPersonJobListResponse = await GetPersonJobList(request);
    return response;
  }
);

export const getPayPeriodDetail = createAsyncThunk(
  'people/onboarding/jobDetails/getPayPeriodDetail',
  async (request: IGetPayPeriodDetailRequest) => {
    const response: IGetPayPeriodDetailResponse = await GetPayPeriodDetail(request);
    return response;
  }
);

export const getDepartment = createAsyncThunk(
  'people/onboarding/jobDetails/getDepartment',
  async (request: IGetDepartmentListRequest) => {
    const response: IGetDepartmentListResponse = await GetDepartment(request);
    return response;
  }
);

export const getJob = createAsyncThunk('people/onboarding/jobDetails/getJob', async (request: IGetJobListRequest) => {
  const response: IGetJobListResponse = await GetJob(request);
  return response;
});

export const saveDepartment = createAsyncThunk(
  'people/onboarding/jobDetails/saveDepartment',
  async (request: IPersistDepartmentRequest) => {
    const response: IPersistDepartmentResponse = await PersistDepartment(request);
    return response;
  }
);

export const saveJob = createAsyncThunk('people/onboarding/jobDetails/saveJob', async (request: IPersistJobRequest) => {
  const response: IPersistJobResponse = await PersistJob(request);
  return response;
});

export const getWcbRateList = createAsyncThunk(
  'people/onboarding/jobDetails/getWcbList',
  async (request: GetWcbRateListRequest) => {
    const response: GetWcbRateListResponse = await GetWcbRateList(request);
    return response;
  }
);

export const getStateWage = createAsyncThunk(
  'people/onboarding/jobDetails/getStateWage',
  async (request: IGetStateWageRequest) => {
    const response: IGetStateWageResponse = await GetStateWage(request);
    return response;
  }
);

export const getPersonVacationBalance = createAsyncThunk(
  'people/onboarding/jobDetails/getPersonVacationBalance',
  async (request: GetPersonVacationBalanceRequestInterface) => {
    const response = await holidayPayAccess.getPersonVacationBalance(request);
    return response;
  }
);

const peopleJobDetailsSlice = createSlice({
  name: 'peoplebasicinfo',
  initialState,
  reducers: {
    resetJobDetails: (state) => {
      state.departmentList = initialState.departmentList;
      state.isMidCycleHire = initialState.isMidCycleHire;
      state.jobList = initialState.jobList;
      state.payPeriodDetail = initialState.payPeriodDetail;
      state.personJobDetailList = initialState.personJobDetailList;
      state.stateWage = initialState.stateWage;
      state.vacation = initialState.vacation;
      state.wcbList = initialState.wcbList;
      state.didUserChangedCompensation = initialState.didUserChangedCompensation;
      state.selectedValues = initialState.selectedValues;
    },
    resetJob: (state) => {
      state.selectedValues = initialState.selectedValues;
    },
    madeUpdateInCompensation: (state, action) => {
      state.didUserChangedCompensation = action.payload.value;
    },
    savedJobConfig: (state, action) => {
      state.persistedValues = action.payload.value;
    },
    updatedSelectedData: (state, action) => {
      state.selectedValues = { job: { ...state.selectedValues.job, ...action.payload.value } };
    },
    setOriginalData: (state, action) => {
      state.originalValues = action.payload.value;
    },
    updateIsFormPopulated: (state, action) => {
      state.isFormPopulated = action.payload;
    },
    updateCompensationComparer: (state, action) => {
      state.compensationDataComparer[action.payload.key] = action.payload.value;
    },
    updateAutoRunDetails: (
      state,
      action: PayloadAction<Partial<Record<'payType' | 'hoursType' | 'isAutoRun', number>>>
    ) => {
      state.autoRunDetails = { ...state.autoRunDetails, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersonJobList.pending, (state: IJobDetails) => {
      state.vacation.loading = true;
    });
    builder.addCase(getPersonJobList.fulfilled, (state: IJobDetails, action) => {
      state.personJobDetailList = action.payload.personJobDetailList || [];
      state.personJobList = action.payload.personJobDetailList.map((item) => ({
        name: item.job?.name || '',
        value: item.job?.id || 0,
      }));
      state.vacation = {
        loading: false,
        ...action.payload.vacation,
      };
    });
    builder.addCase(getPayPeriodDetail.fulfilled, (state: IJobDetails, action) => {
      state.payPeriodDetail = action.payload.payPeriodDetail;
      state.isMidCycleHire = action.payload.isMidCycleHire;
    });
    builder.addCase(getDepartment.fulfilled, (state: IJobDetails, action) => {
      state.departmentList = action.payload.departmentList;
    });
    builder.addCase(getJob.fulfilled, (state: IJobDetails, action) => {
      state.jobList = action.payload.jobList;
    });
    builder.addCase(getStateWage.fulfilled, (state: IJobDetails, action) => {
      state.stateWage = action.payload.stateWage;
    });
    builder.addCase(getWcbRateList.fulfilled, (state: IJobDetails, action) => {
      const wcbList = action.payload.wcbRateSummaryList?.map((wcb) => {
        return {
          name: `${wcb.rate}% (${wcb.province})`,
          ...wcb,
        };
      });
      state.wcbList = wcbList;
    });
    builder.addCase(getPersonVacationBalance.fulfilled, (state: IJobDetails, action) => {
      state.jobVacationBalanceList = action.payload.jobVacationBalanceList ?? [];
    });
  },
});

export const {
  resetJobDetails,
  resetJob,
  updateIsFormPopulated,
  madeUpdateInCompensation,
  savedJobConfig,
  setOriginalData,
  updatedSelectedData,
  updateCompensationComparer,
  updateAutoRunDetails,
} = peopleJobDetailsSlice.actions;
export const peopleJobDetailsReducer = peopleJobDetailsSlice.reducer;
export const selectJobDetails = (state: RootState) => state.peopleJobDetailsReducer;
export const selectStateWage = (state: RootState) => state.peopleJobDetailsReducer?.stateWage;
export const selectWcbList = (state: RootState) => state.peopleJobDetailsReducer?.wcbList;
export const selectJobList = (state: RootState) => state.peopleJobDetailsReducer?.jobList;
export const selectDepartmentList = (state: RootState) => state.peopleJobDetailsReducer.departmentList;
export const selectIsMidCycleHire = (state: RootState) => state.peopleJobDetailsReducer?.isMidCycleHire;
export const selectJobDetailsPayPeriodDetail = (state: RootState) => state.peopleJobDetailsReducer?.payPeriodDetail;
export const selectJobDetailsVacation = (state: RootState) => state.peopleJobDetailsReducer?.vacation;
export const getPersonJobDetailList = (state: RootState) => state.peopleJobDetailsReducer.personJobDetailList;
export const selectUserCompensationUpdate = (state: RootState) =>
  state.peopleJobDetailsReducer.didUserChangedCompensation;
export const selectOriginalData = (state: RootState) => state.peopleJobDetailsReducer.originalValues;
export const selectOriginalJobValues = (state: RootState) => state.peopleJobDetailsReducer.persistedValues;
export const selectLocationValues = (state: RootState) => state.peopleJobDetailsReducer.selectedValues;
export const selectCompensationComparer = (state: RootState) => state.peopleJobDetailsReducer.compensationDataComparer;
export const selectJobVacationBalance = (state: RootState) => state.peopleJobDetailsReducer.jobVacationBalanceList;
export const selectAutoRunDetails = (state: RootState) => state.peopleJobDetailsReducer.autoRunDetails;
export const selectIsAutoRun = (state: RootState) => state.peopleJobDetailsReducer.autoRunDetails?.isAutoRun;
export const selectAutoRunDetailsPayType = (state: RootState) => state.peopleJobDetailsReducer.autoRunDetails?.payType;
export const selectAutoRunDetailsHoursType = (state: RootState) =>
  state.peopleJobDetailsReducer.autoRunDetails?.hoursType;
