import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { CalendarViewEnum } from 'pages/accounting-dashboard/dashboard/dashboard.interface';

import payrollPartnerAccessServices, { OnboardingStepInterface } from 'services/payroll/models/access/partnerAccess';
import appCorePartnerAccessServices, {
  GetUserListRequestInterface,
  GetUserListSortEnum,
} from 'services/appCore/models/access/partnerAccess';
import { IWpFilterWidgetDropdownOption } from '@wagepoint/ui-toolkit/components/wp-filter-widget/wpFilterWidget.interface';

interface IDashboardSlice {
  isOnboardingStepsLoaded: boolean;
  onboardingStepList: Array<OnboardingStepInterface>;
  payGroupFilterOption: Array<IWpFilterWidgetDropdownOption>;
  clientNameFilterOption: Array<IWpFilterWidgetDropdownOption>;
  adminFilterOption: Array<IWpFilterWidgetDropdownOption>;
  calendarView: CalendarViewEnum;
}

const initialState: IDashboardSlice = {
  isOnboardingStepsLoaded: false,
  onboardingStepList: [],
  payGroupFilterOption: [],
  clientNameFilterOption: [],
  adminFilterOption: [],
  calendarView: CalendarViewEnum.WeekView,
};

export const getInitialState = createAsyncThunk(
  'dashboard/GetInitialState',
  payrollPartnerAccessServices.getInitialState
);

export const getAdminList = createAsyncThunk(
  'dashboard/getAdminList',
  async (
    request: Partial<GetUserListRequestInterface> &
      Pick<GetUserListRequestInterface, 'companyId' | 'securityRoleTypeIdList'>
  ) => {
    const defaultRequest: GetUserListRequestInterface = {
      emailContains: '',
      filterByEmail: false,
      filterByName: false,
      includeClientCount: false,
      includeDisabled: false,
      includeEditAccess: false,
      includeRole: false,
      nameContains: '',
      securityRoleId: 0,
      sortAscending: false,
      sortColumnType: GetUserListSortEnum.name,
      skip: 0,
      take: 50,
      ...request,
    };
    return appCorePartnerAccessServices.getUserList(defaultRequest);
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateCalendarView: (state, action: PayloadAction<{ calendarView: CalendarViewEnum }>) => {
      state.calendarView = action.payload.calendarView;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitialState.pending, (state) => {
      state.isOnboardingStepsLoaded = false;
    });
    builder.addCase(getInitialState.fulfilled, (state, action) => {
      const { onboardingStepList, payGroupList, clientSummaryList } = action.payload;
      state.isOnboardingStepsLoaded = true;
      state.onboardingStepList = onboardingStepList || [];
      state.payGroupFilterOption = payGroupList?.map((item) => ({ ...item, isSelected: false })) || [];
      state.clientNameFilterOption =
        clientSummaryList?.map(({ id, legalName }) => ({
          id,
          name: legalName,
          isSelected: false,
        })) || [];
    });
    builder.addCase(getAdminList.fulfilled, (state, action) => {
      const { userList } = action.payload;
      state.adminFilterOption =
        userList?.map(({ id, firstName, lastName }) => {
          const name = [firstName, lastName].filter(Boolean).join(' ');
          return {
            id,
            name,
            isSelected: false,
          };
        }) || [];
    });
  },
});

export const { updateCalendarView } = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
export const selectDashboard = (state: RootState) => state.dashboardReducer;

export const selectOnboardingStepList = createSelector(selectDashboard, ({ onboardingStepList }) => onboardingStepList);

export const selectOnboardingStepsLoaded = createSelector(
  selectDashboard,
  ({ isOnboardingStepsLoaded }) => isOnboardingStepsLoaded
);
export const selectFilterOptionsPayGroup = createSelector(
  selectDashboard,
  ({ payGroupFilterOption }) => payGroupFilterOption
);
export const selectFilterOptionsClientName = createSelector(
  selectDashboard,
  ({ clientNameFilterOption }) => clientNameFilterOption
);
export const selectFilterOptionsAdmin = createSelector(selectDashboard, ({ adminFilterOption }) => adminFilterOption);
