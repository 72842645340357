import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IOffcycleEmployeeList,
  ISupportingDataInterface,
} from 'services/payroll-launch-access/payrollLaunchAccess.contracts';

export enum IValidatePayPeriodEnum {
  PayPeriodNotFound = 0,
  InvalidDateRange = 1,
  LateForDirectDeposit = 2,
  PassedDateForDDAndTaxes = 3,
  PayDateNotAvailable = 4,
}

export interface PayPeriodOption {
  key: string;
  value: string;
}

export interface IValidateObj {
  validationCode: null | IValidatePayPeriodEnum;
  response: any;
}
interface ISelectDate {
  validationObject?: IValidateObj;
  formVariables: {
    payDate: any;
  };
  supportingData: ISupportingDataInterface;
  errorMessage: string;
  employeeList: IOffcycleEmployeeList[];
  selectedEmployee: IOffcycleEmployeeList | IOffcycleEmployeeList[];
  payPeriodList: PayPeriodOption[];
  payPeriod: any;
  togglePayments: { directDeposit: boolean; remitTax: boolean };
}

interface ISelectDateBools {
  isDDDisabled: boolean;
  hideProcessingDate: boolean;
}

interface ISelectDateNumbers {
  excepmtionValue: number;
}

const initialState: ISelectDate & ISelectDateBools & ISelectDateNumbers = {
  validationObject: { validationCode: null, response: null },
  formVariables: { payDate: null },
  supportingData: {
    directDeposit: false,
    remitTax: false,
    payPeriodCycleType: '',
    payPeriodWindowList: [],
    targetDate: '',
    processingDate: '',
  },
  errorMessage: '',
  employeeList: [],
  selectedEmployee: [],
  payPeriod: null,
  payPeriodList: [],
  togglePayments: { directDeposit: true, remitTax: true },
  isDDDisabled: false,
  excepmtionValue: 0,
  hideProcessingDate: false,
};

interface IActionSelectDates {
  key: keyof ISelectDate;
  value: any;
}

const deReference = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const selectDatesSlice = createSlice({
  name: 'payroll/selectdates',
  initialState,
  reducers: {
    setSelectDateStates: (state, action: { payload: IActionSelectDates }) => {
      state[action.payload.key] = action.payload.value;
    },
    setSelectDateFormValues: (state, action: { payload: any }) => {
      state.formVariables = { ...state.formVariables, ...deReference(action.payload) };
    },
    setSupportingDataAction: (state, action: { payload: any }) => {
      state.supportingData = { ...state.supportingData, ...deReference(action.payload) };
    },
    setEmployeeListAction: (state, action: { payload: any }) => {
      state.employeeList = deReference(action.payload);
    },
    setTogglePayment: (state, action: { payload: any }) => {
      state.togglePayments = { ...state.togglePayments, ...action.payload };
    },
    setSelectDateBooleanStates: (state, action: { payload: { key: keyof ISelectDateBools; value: boolean } }) => {
      state[action.payload.key] = action.payload.value;
    },
    setSelectDateNumberStates: (state, action: { payload: { key: keyof ISelectDateNumbers; value: number } }) => {
      state[action.payload.key] = action.payload.value;
    },
    resetAll: () => initialState,
  },
});

export const {
  setSelectDateStates,
  setSelectDateFormValues,
  setTogglePayment,
  setSupportingDataAction,
  setSelectDateBooleanStates,
  setSelectDateNumberStates,
  setEmployeeListAction,
  resetAll,
} = selectDatesSlice.actions;
export const selectDatesReducer = selectDatesSlice.reducer;
export const selectValidationObj = ({ selectDatesReducer }: RootState) => ({ ...selectDatesReducer.validationObject });
export const selectPayrollDatesFormValue = ({ selectDatesReducer }: RootState) => ({
  ...selectDatesReducer.formVariables,
});
export const selectorPayrollDate = ({ selectDatesReducer }: RootState) => selectDatesReducer.formVariables.payDate;
export const selectorSupportingData = ({ selectDatesReducer }: RootState) => ({
  ...selectDatesReducer.supportingData,
});
export const selectorEmployeeList = ({ selectDatesReducer }: RootState) => selectDatesReducer.employeeList;
export const selectorRadioError = ({ selectDatesReducer }: RootState) => selectDatesReducer.errorMessage;
export const selectorPayPeriodList = ({ selectDatesReducer }: RootState) => selectDatesReducer.payPeriodList;
export const selectorPayPeriod = ({ selectDatesReducer }: RootState) => selectDatesReducer.payPeriod;
export const selectorTogglePayment = ({ selectDatesReducer }: RootState) => selectDatesReducer.togglePayments;
export const selectorDDDPayment = ({ selectDatesReducer }: RootState) => selectDatesReducer.isDDDisabled;
export const selectorExcepmtionValue = ({ selectDatesReducer }: RootState) => selectDatesReducer.excepmtionValue;
export const selectorHideProcessingDate = ({ selectDatesReducer }: RootState) => selectDatesReducer.hideProcessingDate;
export const selectorSelectedEmployee = ({ selectDatesReducer }: RootState) => selectDatesReducer.selectedEmployee;
