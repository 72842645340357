import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IappNotificationItem } from 'services/app-notification-access/appNotificationAccess.contracts';
import BillingAccessServices, {
  CompanyCriteriaInterface,
  CompanyInterface,
  CompanySortTypeEnum,
  CompanyStatusTypeEnum,
  GetCompanyListRequestInterface,
} from 'services/finance/models/access/billingAccess';

interface IAppNotificationSlice {
  notificationList: IappNotificationItem[];
  headerNotificationList: IappNotificationItem[];
  totalUnreadCount: number;
  skip: number;
  dates: { startDate: string | null; endDate: string | null };
  loadingState: { isLoading: boolean };
  clientList: Array<CompanyInterface>;
}
interface ISetAppNotifyVariables {
  payload: { key: keyof IAppNotificationSlice; value: any; concat?: boolean };
}
export const notificationPaginationFactor = 20;
interface IMarkAsRead {
  payload: { markAll?: boolean; list?: number[] };
}
interface INewElement {
  payload: { list: IappNotificationItem[]; totalUnreadCount: number };
}
const initialState: IAppNotificationSlice = {
  notificationList: [],
  skip: 0,
  headerNotificationList: [],
  totalUnreadCount: 0,
  dates: { startDate: null, endDate: null },
  loadingState: { isLoading: false },
  clientList: [],
};

export const getClientList = createAsyncThunk(
  'appNotification/getClientList',
  (request?: Partial<CompanyCriteriaInterface> & { partnerId: number }) => {
    const defaultRequest: GetCompanyListRequestInterface = {
      companyCriteria: {
        partnerId: 0,
        securityRoleTypeList: [],
        filterOnAdministrator: false,
        filterOnBilling: false,
        filterOnCountry: false,
        filterOnPermissionType: false,
        filterOnCompanyName: false,
        clientPays: false,
        partnerPays: false,
        administratorIdList: [],
        countryIdList: [],
        startsWithCompanyName: '',
        filterOnDisconnectedClients: false,
        includeCompanyInvitationList: false,
        includeCompanyOwner: false,
        filterOnPartner: true,
        filterOnYearList: false,
        filterOnCompanyTypeFlags: false,
        filterOnStatus: false,
        filterOnMonthList: false,
        filterOnPlan: false,
        monthList: [],
        yearList: [],
        sortDescending: false,
        companySortType: CompanySortTypeEnum.notSpecified,
        skip: 0,
        take: 100,
        companyTypeFlags: 0,
        filterOnCompanyCode: false,
        filterOnCompanyStatus: false,
        companyStatusType: CompanyStatusTypeEnum.notSpecified,
        filterOnNotClosedAndNotExpired: false,
        ...request,
      },
    };
    return BillingAccessServices.getCompanyList(defaultRequest);
  }
);

const getReadAndUnread = (list: IappNotificationItem[], readedList?: number[], markAll?: boolean) => {
  return list.map((item) => {
    if (markAll) return { ...item, isRead: true };
    if (readedList && readedList.includes(item.id)) {
      return { ...item, isRead: true };
    }
    return { ...item };
  });
};

const concatNewArray = (original: IappNotificationItem[], newList: IappNotificationItem[]) => {
  const updatedArray: IappNotificationItem[] = [];
  newList.map((item) => {
    if (!original.some((el) => el.id === item.id)) updatedArray.push(item);
    // updatedArray.push(item);
  });
  return [...updatedArray, ...original];
};
export const appNotificationSlice = createSlice({
  name: 'appNotificationReducer',
  initialState,
  reducers: {
    addNewNotificationItem: (state, action: INewElement) => {
      const { list, totalUnreadCount } = action.payload;
      if (list && list.length) {
        state.notificationList = concatNewArray(state.notificationList, list);
        state.headerNotificationList = concatNewArray(state.headerNotificationList, list);
        state.totalUnreadCount = totalUnreadCount;
      }
    },
    markAsReadAction: (state, action: IMarkAsRead) => {
      const { list, markAll } = action.payload;
      state.notificationList = getReadAndUnread(state.notificationList, list, markAll);
      state.headerNotificationList = getReadAndUnread(state.headerNotificationList, list, markAll);
      state.totalUnreadCount = state.totalUnreadCount - 1;
    },
    setNotificationAction: (state, action: ISetAppNotifyVariables) => {
      if (
        (action.payload.concat && action.payload.key === 'notificationList') ||
        action.payload.key === 'headerNotificationList'
      ) {
        if (state[action.payload.key].some((item) => item.id))
          state[action.payload.key] = concatNewArray(state[action.payload.key], action.payload.value);
      } else state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientList.fulfilled, (state, action) => {
      state.clientList = action.payload.companyList || [];
    });
  },
});

export const { setNotificationAction, markAsReadAction, addNewNotificationItem } = appNotificationSlice.actions;

export const appNotificationReducer = appNotificationSlice.reducer;
export const appNotificationReducerStates = ({ appNotificationReducer }: RootState) => appNotificationReducer;
export const appNotificationListState = ({ appNotificationReducer }: RootState) =>
  appNotificationReducer.notificationList;
export const appHeaderNotificationListState = ({ appNotificationReducer }: RootState) =>
  appNotificationReducer.headerNotificationList;
export const notificationPagination = ({ appNotificationReducer }: RootState) => appNotificationReducer.skip;
export const notificationFetchLoading = ({ appNotificationReducer }: RootState) => ({
  ...appNotificationReducer.loadingState,
});
export const notificationFilterDates = ({ appNotificationReducer }: RootState) => ({
  ...appNotificationReducer.dates,
});
export const appHeaderTotalUnreadNotificationCount = ({ appNotificationReducer }: RootState) =>
  appNotificationReducer.totalUnreadCount;
