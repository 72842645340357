import { IValidationResults } from 'services/shared/validationResults.contracts';

export interface IGetInitialStateXeroRequest {
  companyId: number;
}
export interface IGetInitialStateXeroResponse {
  authorizationUri: string;
  connectionStatus: number;
  isChartOfAccountsMapped: boolean;
  isTaxBasis: boolean;
  validationResults?: string;
}

export enum GetInitialStateEnum {
  notFound = 0,
  connected = 1,
  notConnected = 2,
}

export enum GetIntegrationTypesEnum {
  xero = 4,
  qbo = 9,
}

export enum EJournalMapType {
  NOT_DEFINED = 0,
  NOT_SPECIFIED = 1,
  WAGES_AND_EARNINGS_EXPENSES = 2,
  WAGES_AND_EARNINGS_LIABILITIES = 3,
  EMPLOYEE_BENEFIT_EXPENSES = 4,
  EMPLOYEE_BENEFIT_LIABILITIES = 5,
  PAYROLL_TAX_LIABILITIES = 6,
  PAYROLL_TAX_EXPENSES = 7,
  PAYROLL_CLEARING = 8,
  DEDUCTION_LIABILITIES = 9,
  PAYROLL_SUBSCRIPTION_FEES = 10,
  PAYROLL_BANK_ACCOUNT = 11,
}

export interface IConnectToXeroRequest {
  companyId: number;
  authorizationCode: string;
  redirectUrl: string;
}
export interface IDisconnectFromXeroRequest {
  companyId: number;
}

export interface IDisconnectFromXeroResponse {
  validationResults: IValidationResults;
}

export interface IGetXeroAccountListRequest {
  companyId: number;
}

export interface IXeroAccountList {
  id: string;
  name: string;
}
export interface IGetXeroAccountListResponse {
  xeroAccountList: Array<IXeroAccountList>;
}

interface IExternalAccountList {
  externalAccountId: string;
  externalAccountName: string;
}
export interface IGetChartOfAccountsRequest {
  companyId: number;
  integrationTypeId: number;
  externalAccountList?: Array<IExternalAccountList>;
}

export interface IAccountMapList {
  name: string;
  mapAccountId: string;
  mapAccountName: string;
  journalMapSubType?: Record<string, unknown>;
  baseFieldId?: Record<string, unknown>;
  fieldDepartmentMapId?: Record<string, unknown>;
  accountGuideId: number;
  sortOrder: number;
  isParentAccount: boolean;
}
export interface IAccountMapGroupList {
  id?: string;
  name: string;
  description: string;
  journalMapType: number;
  sortOrder: number;
  accountMapList: IAccountMapList[];
  validationId?: string;
}

export interface IDepartmentList {
  id?: number;
  name: string;
  accountMapGroupList: IAccountMapGroupList[];
}

export interface IGetChartOfAccountsResponse {
  departmentList: Array<IDepartmentList>;
  validationResults: IValidationResults;
}

export interface IPersistChartOfAccountsRequest {
  companyId: number;
  integrationTypeId: number;
  cultureType: number;
  departmentList: Array<IDepartmentList>;
}

export interface IPersistChartOfAccountsResponse {
  validationResults: IValidationResults;
}

interface IAttachmentList {
  attachmentType?: Record<string, unknown>;
  name?: string;
  attachPostingJournal?: boolean;
  attachPayrollRegister?: boolean;
  attachPayrollTaxes?: boolean;
  attachInvoice: boolean;
  reportType?: GetReportTypeEnum;
}
export interface IExportPayrollRequest {
  companyId: number;
  payGroupId: number;
  payPeriodId: number;
  invoiceName: string;
  subscriptionMonth?: number;
  subscriptionYear?: number;
  subscriptionFeeInvoiceName?: string;
  invoiceList?: IAttachmentList[];
}

export interface IExportPayrollResponse {
  validationResults: IValidationResults;
}
export interface IGetTaxRateListRequest {
  companyId: number;
}

export interface ITaxRateList {
  taxType: string;
  taxRate: number;
  id: string;
  name: string;
}
export interface IGetTaxRateListResponse {
  taxRateList: Array<ITaxRateList>;
  validationResults: IValidationResults;
}

export interface IGetAutoSyncPreferenceRequest {
  companyId: number;
  integrationTypeId: number;
}

export interface IAutoSyncAttachmentList {
  reportType: GetReportTypeEnum;
  attachPostingJournal: boolean;
  attachPayrollRegister: boolean;
  attachPayrollTaxes: boolean;
  attachInvoice: boolean;
  name?: string;
}

export interface IAutosyncPreference {
  autoSync: boolean;
  invoiceList: Array<IAutoSyncAttachmentList>;
  effectiveDate?: string;
  id: number;
}
export interface IGetAutoSyncPreferenceResponse {
  autoSyncPreference: IAutosyncPreference;
  validationResults?: IValidationResults;
}

export enum GetReportTypeEnum {
  notDefined = 0,
  journalEntry = 1,
  bill = 2,
  wagepointInvoice = 3,
  accountsPayableInvoice = 4,
  subsribtionFeeInvoice = 5,
  payrollTax = 6,
}

interface IAutoSyncPreference {
  id: number;
  autoSync: boolean;
  effectiveDate: Date;
  invoiceList: Array<IAutoSyncAttachmentList>;
}

export interface IPersistAutoSyncPreferenceRequest {
  companyId: number;
  integrationTypeId: number;
  autoSyncPreference: IAutoSyncPreference;
}

export interface IPersistAutoSyncPreferenceResponse {
  validationResults?: IValidationResults;
}

export interface IGetMissingAccountsRequest {
  companyId: number;
}

export interface IGetMissingAccountsResponse {
  accountNames: string[];
  validationResults?: IValidationResults;
}

export interface ICreateMissingAccountsRequest {
  companyId: number;
  accountNames: string[];
}

export interface ICreateMissingAccountsResponse {
  validationResults?: IValidationResults;
}

export interface IGetVendorListRequest {
  companyId: number;
}

export interface IVendorList {
  id: string;
  name: string;
}

export interface IGetVendorListResponse {
  vendorList: IVendorList[];
  validationResults?: IValidationResults;
}

export interface IPersistVendorRequest {
  companyId: number;
  vendorName: string;
}
export interface IPersistVendorResponse {
  vendor: IVendorList;
  validationResults?: IValidationResults;
}

export interface IGetMappingInitialStateRequest {
  companyId: number;
}

export interface IGetMappingInitialStateResponse {
  id: number;
  name: string;
  accountMapGroupList: IAccountMapGroupList[];
  validationResults?: IValidationResults;
}

export interface IConnectQuickBooksRequest {
  authorizationCode: string;
  companyId: number;
  connectionStatus?: number;
  cultureType: number;
  realmId: string;
  redirectUrl: string;
}

export interface IConnectQuickBooksResponse {
  validationResults?: IValidationResults;
}

export interface IGetInitialStateQboRequest {
  companyId: number;
}

export interface IGetInitialStateQboResponse {
  isVendorLinked: boolean;
  connectionStatus: number;
  isChartOfAccountsMapped: boolean;
  authorizationUri: string;
  controlledTipsInPayroll?: boolean;
  vacationPayAccrued?: boolean;
  isActive: boolean;
  accountingSyncStatus?: boolean;
  validationResults?: IValidationResults;
}

export interface IExportPayrollQboRequest {
  companyId: number;
  payGroupId: number;
  payPeriodId: number;
  invoiceName?: string;
  subscriptionMonth?: number;
  subscriptionYear?: number;
  subscriptionFeeInvoiceName?: string;
  invoiceList?: IAttachmentList[];
}
export interface IExportPayrollQboResponse {
  validationResults?: IValidationResults;
}

export interface IGetQboAccountListRequest {
  companyId: number;
}

export interface IQboAccountList {
  id: number;
  name: string;
}
export interface IGetQboAccountListResponse {
  qboAccountList: IQboAccountList[];
  validationResults?: IValidationResults;
}

export interface IProcessAutoSyncQboRequest {
  workflowInstanceKey: string;
}

export interface IWorkflowResponse {
  gracefullyExit: boolean;
  sleepSeconds: number;
  message: string;
  jsonPayLoad: string;
  respectSleepAndExecuteAgain: boolean;
  origin: string;
  raiseEventType?: number;
  customName: string;
}
export interface IProcessAutoSyncQboResponse {
  workflowResponse: IWorkflowResponse;
  validationResults?: IValidationResults;
}

export interface IGetQboMissingAccountsRequest {
  companyId: number;
}

export interface IGetQboMissingAccountsResponse {
  accountNames: string[];
  validationResults?: IValidationResults;
}

export interface ICreateQboMissingAccountsRequest {
  companyId: number;
  accountNames: string[];
}

export interface ICreateQboMissingAccountsResponse {
  validationResults?: IValidationResults;
}

export interface IDisconnectFromQboRequest {
  companyId: number;
}

export interface IDisconnectFromQboResponse {
  validationResults?: IValidationResults;
}
