import {
  IFilterItemInterface,
  IFilterTypeInterface,
} from 'services/payroll-dashboard-access/payrollDashboardAccess.contracts';
import { IFilteredTableRows, IPeWorkTableRow } from './hoursIncomeSlice';

const groupBy = (xs: any, key: string) => {
  return xs.reduce((rv: any, x: any) => {
    rv[x[key]] = [...(rv[x[key]] || []), x];
    return rv;
  }, {});
};

const groupSelectedFilters = (filterOptions: IFilterTypeInterface[]) => {
  let filters: IFilterItemInterface[] = [];
  if (filterOptions.length) {
    filterOptions.forEach((item) => {
      const filtered = item.filterItemList.filter((el) => {
        if (el.isSelected) return true;
        return false;
      });
      filters = [...filters, ...filtered];
    });
    return groupBy(filters, 'mainId');
  }
  return null;
};

export const filterWidgetAction = ({
  list,
  filterOptions,
  prevFilter,
}: {
  list: IPeWorkTableRow[];
  filterOptions: IFilterTypeInterface[];
  prevFilter: IFilterTypeInterface[];
}) => {
  const selectedFilters = groupSelectedFilters(filterOptions); // we group the filters like [mainId]: [{name:"Sample"}]
  if (selectedFilters && Object.keys(selectedFilters)?.length) {
    const updateTableList: IPeWorkTableRow[] = [];
    const employeeIdObj: any = {};
    let prevEId: any = null;
    list.forEach((item, index) => {
      if (item.employeeId.value !== prevEId) {
        prevEId = null;
      }
      if (!item.__isJobRow && prevEId === null) {
        employeeIdObj[item.employeeId.value] = index;
        prevEId = item.employeeId.value;
      }

      if (item.__isJobRow) {
        /** Steps Involved:
         * > First we loop through the main table array copy
         * > then we loop through the selected filters to accumulate the aggregate of the result.
         * > like eg: included>  employee>  department xyz.
         * > we map through the selected filters, then inside the sub filterArray we check if any matches for the current iteration.
         * > if we have a match then we stop the sub filterArray loop and save that item by updating found = true;
         */
        const found = Object.keys(selectedFilters).every((filterMain) => {
          const sameLevelFound = selectedFilters[filterMain].every((filterItem: IFilterItemInterface) => {
            const keyName = filterItem?.keyName as IFilteredTableRows;
            if (keyName && item[keyName]?.value === filterItem.value) {
              return false;
            }
            return true;
          });
          return !sameLevelFound;
        });

        if (found) {
          updateTableList.push({ ...item });
        }
      }
    });
    const finalTableList: any = [];
    const addedFields: any = {};
    updateTableList.forEach((el) => {
      if (employeeIdObj[el.employeeId.value] !== undefined && !addedFields[el.employeeId.value]) {
        finalTableList.push({ ...list[employeeIdObj[el.employeeId.value]] });
        addedFields[el.employeeId.value] = true;
      }
      finalTableList.push({ ...el });
    });
    return finalTableList;
  } else if (prevFilter?.length && JSON.stringify(prevFilter) !== JSON.stringify(filterOptions)) {
    return list;
  }
  return list;
};

export const searchByTextFilter = ({ searchText, list }: { searchText: string; list: any }) => {
  const updatedList: any = [];
  let nonJobRowIndex: any = null;
  if (searchText?.length) {
    list.forEach((item: any, index: number) => {
      Object.keys(item).every((key: any) => {
        if (!item.__isJobRow) {
          nonJobRowIndex = index;
        }
        if (item[key].value?.toString()?.toLowerCase().includes(searchText?.toLowerCase())) {
          if (item.__isJobRow) {
            if (!updatedList.some((el: any) => el.employeeId.value === item.employeeId.value)) {
              if (nonJobRowIndex !== null && list[nonJobRowIndex]) {
                updatedList.splice(updatedList.length ? updatedList.length - 1 : 0, 0, list[nonJobRowIndex]);
              }
            }
          }
          if (!updatedList.some((el: any) => el?.rowKey === item?.rowKey)) updatedList.push({ ...item });
          return false;
        }
        return true;
      });
    });
    return updatedList;
  }
  return list;
};

export const searchNameByTextFilter = ({ searchText, list }: { searchText: string; list: any }) => {
  const updatedList: any = [];
  if (searchText?.length) {
    list.forEach((item: any, index: number) => {
      if (!item.__isJobRow) {
        if (item['name'].value?.toString()?.toLowerCase().includes(searchText?.toLowerCase())) {
          updatedList.push({ ...item }, { ...list[index + 1] });
        }
      }
    });
    return updatedList;
  }
  return list;
};
