import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import BellOn from 'assets/images/bell_on.svg';
import { CardStyles } from './cardsStyles';
import { formatDate } from 'util/utility';
import { useTranslation } from 'react-i18next';
import MenuContainer from './common/menu';
import DisplayRoles from './common/displayRoles';
import { CompanySummaryInterface } from 'services/finance/models/access/companyAccess';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import WpPill from '@wagepoint/ui-toolkit/components/wp-pill/wpPill';

interface IProps {
  item: CompanySummaryInterface;
}

const PendingCard: React.VFC<IProps> = ({ item }) => {
  const classes = CardStyles();
  const { t } = useTranslation(['userAccounts']);

  return (
    <Card>
      <CardHeader
        title={
          <div className={classes.cardHeader}>
            <img src={BellOn} alt={t('firmInviteSent.default')} className={classes.defaultImage} />
            <WpTypography variant="h3">{item.name}</WpTypography>
            <WpPill label={t('firmInviteAccepted.chipLabel')} color="orange" />
          </div>
        }
        disableTypography
        action={<MenuContainer item={item} />}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <WpTypography variant="p1Bold" display="inline">
                {t('firmInviteSent.invite')}
              </WpTypography>
              <WpTypography display="inline">{formatDate(item.dateInvited)}</WpTypography>
            </div>
          </Grid>
          {item.companyCode ? (
            <Grid item xs={12}>
              <div>
                <WpTypography variant="p1Bold" display="inline">
                  {t('firmInviteSent.companyId')}
                </WpTypography>
                <WpTypography display="inline">{item.companyCode}</WpTypography>
              </div>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <div>
                  <WpTypography variant="p1Bold" display="inline">
                    {t('firmInviteSent.sentTo')}
                  </WpTypography>
                  <WpTypography display="inline">{item.ownerName}</WpTypography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <WpTypography variant="p1Bold" display="inline">
                    {t('firmInviteSent.email')}
                  </WpTypography>
                  <WpTypography display="inline">{item.email}</WpTypography>
                </div>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <DisplayRoles item={item} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PendingCard;
