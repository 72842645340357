import { useAppSelector } from 'redux/hooks/hook';
import { selectConfig } from 'redux/slice/configSlice';

import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';

const useCheckSecurityRoleType = () => {
  const {
    config: { securityRoleType },
  } = useAppSelector(selectConfig);

  return {
    isAccountOwner: securityRoleType === SecurityRoleTypeEnum.AccountOwner,
    isAccountAdministrator: securityRoleType === SecurityRoleTypeEnum.AccountAdministrator,
    isPayrollAdministrator: securityRoleType === SecurityRoleTypeEnum.Administrator,
    isReportsManager: securityRoleType === SecurityRoleTypeEnum.ReportsManager,
    isAuthorizedSignatory: securityRoleType === SecurityRoleTypeEnum.AccountSignatory,
  };
};

export default useCheckSecurityRoleType;
