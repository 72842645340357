import { getPortalLink } from 'util/utility';
import { IUserDetail } from 'services/ui-config-access/uiConfigAccess.contracts';
import { UserDetail } from 'shared/services/payroll/uiConfigAccess';

export const sucurePortalCheck = (userDetail: IUserDetail, companyId: number) => {
  if (companyId === 1) {
    window.location.replace(getPortalLink('alfred'));
  }
  if ((userDetail.isEmployee || userDetail.isContractor) && !userDetail.isAdmin) {
    window.location.replace(getPortalLink('employeeportal'));
  }
};

export const sucurePortalCheckNew = (userDetail: UserDetail, companyId: number, proceed?: () => void) => {
  if (companyId === 1) {
    window.location.replace(getPortalLink('alfred'));
  }
  if ((userDetail.isEmployee || userDetail.isContractor) && !userDetail.isAdmin) {
    window.location.replace(getPortalLink('employeeportal'));
  } else proceed?.(); // else proceed with processing menu
};
