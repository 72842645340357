import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetSecurityRole } from 'services/security-access/securityAccess.service';
import { IGetSecurityRoleRequest, IGetSecurityRoleResponse } from 'services/security-access/securityAccess.contracts';

interface IAllStatuses {
  isReady: boolean;
  isValidating: boolean;
  error: string | unknown;
}

const initialState: IAllStatuses = {
  isReady: false,
  isValidating: false,
  error: '',
};

export const getSecurityRole = createAsyncThunk(
  '/security-access/getSecurityRole',
  async (request: IGetSecurityRoleRequest) => {
    const response: IGetSecurityRoleResponse = await GetSecurityRole(request);
    return response;
  }
);

export const getSecurityRoleSlice = createSlice({
  name: 'securityAccess/getSecurityRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSecurityRole.pending, (state) => {
        state.isValidating = true;
      })
      .addCase(getSecurityRole.fulfilled, (state) => {
        state.isReady = true;
        state.isValidating = false;
      })
      .addCase(getSecurityRole.rejected, (state, action) => {
        state.isReady = false;
        state.error = action.payload;
        state.isValidating = false;
      });
  },
});

export const getSecurityRoleReducer = getSecurityRoleSlice.reducer;
