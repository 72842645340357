import React, { createContext, FC, useContext } from 'react';
import { useAuthProvider } from './useAuthProvider';
import { IAuthUserConfiguration } from 'shared/services/payroll/uiConfigAccess';

export const authContext = createContext<AuthContextState>({
  user: {},
});

export type AuthContextState = {
  user: Partial<IAuthUserConfiguration> | undefined;
};

const ProvideAuth: FC = ({ children }) => {
  const auth = useAuthProvider();
  const visible = !!auth.user?.userDetail;
  return <>{visible && <authContext.Provider value={auth as AuthContextState}>{children}</authContext.Provider>}</>;
};

export default ProvideAuth;

// Custom hook to access authenticated user configuration.
export function useAuthContext() {
  return useContext(authContext);
}
