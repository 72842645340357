import React from 'react';
import { UserAccountsTypeEnum } from '../roles/rolesContainer';

import AssignedPeopleGroup from '../assignedPeopleGroup';

const RolesAssignedPeople: React.FC = () => {
  return (
    <>
      <AssignedPeopleGroup selectedUserAccountOption={UserAccountsTypeEnum.Roles} />
    </>
  );
};

export default RolesAssignedPeople;
