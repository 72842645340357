import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IPersistChartOfAccountsRequest,
  IPersistChartOfAccountsResponse,
} from 'services/integrations-access/integrationsAccess.contracts';
import { PersistChartOfAccounts } from 'services/integrations-access/integrationsAccess.service';

interface IAllStatuses {
  loading: boolean;
  fulfilled: boolean;
  whoseDataIsLoading?: string;
  error: boolean;
  errorText?: string | unknown;
}

const initialState: IAllStatuses = {
  loading: false,
  fulfilled: false,
  whoseDataIsLoading: '',
  error: false,
  errorText: '',
};

export const persistChartOfAccounts = createAsyncThunk(
  '/addons/persistChartOfAccounts',
  async (request: IPersistChartOfAccountsRequest) => {
    const response: IPersistChartOfAccountsResponse = await PersistChartOfAccounts(request);
    return response;
  }
);

export const persistChartOfAccountsSlice = createSlice({
  name: 'persist-chart-of-accounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(persistChartOfAccounts.pending, (state, action) => {
        state.loading = true;
        state.fulfilled = false;
        state.whoseDataIsLoading = action.payload;
      })
      .addCase(persistChartOfAccounts.fulfilled, (state) => {
        state.fulfilled = true;
        state.loading = false;
        state.error = false;
      })
      .addCase(persistChartOfAccounts.rejected, (state, action) => {
        state.error = true;
        state.fulfilled = false;
        state.loading = false;
        state.errorText = action.payload;
      });
  },
});

export const persistChartOfAccountsReducer = persistChartOfAccountsSlice.reducer;
