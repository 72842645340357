import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store/store';
import {
  CompanyToggleTypeEnum,
  ICompanySettings,
  ICompanyToggle,
} from 'services/company-settings-access/companySettingsAccess.contracts';

import { IGetAutoRunStatusRequest, IGetAutoRunStatusResponse } from 'services/paygroup-access/paygroupAccess.contracts';
import { GetAutoRunStatus } from 'services/paygroup-access/paygroupAccess.service';
import companySettingsAccess from 'services/appCore/models/access/companySettingsAccess';
import securityAccess, {
  GetAdministratorListRequestInterface,
  GetAdminListSortEnum,
  UserInterface,
} from '@wagepoint/ui-shared-library/shared-system/services/appCore/models/access/securityAccess';

type TwoStepApprover = {
  id: number;
  name: string;
};

interface ICompanySettingsInitialState {
  companySettings: Partial<ICompanySettings> | null;
  currentTwoStepApprover?: TwoStepApprover;

  isAutoRunPaygroupPresent: boolean;
  isTwoStepApprovalOn: boolean;
  approvers?: Array<UserInterface>;
  isApproversLoading: boolean;

  initialStepApprovalValue?: boolean;
  hasPartnerEnforced2sa?: boolean;
  logoData: { id: number; name: string } | null;
}

const initialState: ICompanySettingsInitialState = {
  companySettings: null,
  isTwoStepApprovalOn: false,
  isAutoRunPaygroupPresent: false,
  isApproversLoading: false,
  logoData: null,
};

export const getCompanySettingsInitialstate = createAsyncThunk(
  'companySettings/getCompanySettingsInitialstate',
  companySettingsAccess.getCompanySettings
);

export const getApprovers = createAsyncThunk(
  'companySettings/getApprovers',
  (request: Partial<GetAdministratorListRequestInterface> & { companyId: number }) => {
    return securityAccess.getAdministratorList({
      filterByName: false,
      includeAccountingAdmin: false,
      includeDisabled: false,
      includeJobTitle: false,
      nameContains: '',
      includeTwoFactorStatus: false,
      sortAscending: true,
      sortColumnType: GetAdminListSortEnum.name,
      filterByPayrollPermission: true,
      ...request,
    });
  }
);

export const getAutoRunStatus = createAsyncThunk<IGetAutoRunStatusResponse, IGetAutoRunStatusRequest>(
  'companySettings/getAutoRunStatus',
  GetAutoRunStatus
);

export const companySettingsSlice = createSlice({
  name: 'companysettings',
  initialState,
  reducers: {
    updateCompanySettings: (state, action: PayloadAction<Partial<ICompanySettingsInitialState>>) => ({
      ...state,
      ...action.payload,
    }),
    clearCompanySettings: (state) => {
      state.companySettings = null;
    },
    updateLogoData: (state, action) => {
      state.logoData = action.payload;
    },
    updateOneSetting: (state, action: PayloadAction<{ toggleType: CompanyToggleTypeEnum; checked: boolean }>) => {
      if (!state.companySettings?.companyToggleList) return;

      const elementsIndex = state.companySettings.companyToggleList.findIndex(
        (toggle) => toggle.companyToggleType === action.payload.toggleType
      );
      if (elementsIndex === -1) return;

      const toggleState: ICompanyToggle = {
        ...state.companySettings?.companyToggleList[elementsIndex],
        isOn: action.payload.checked,
        updatedDate: new Date(),
      };

      if (toggleState.companyToggleType === CompanyToggleTypeEnum.twoStepApproval) {
        state.isTwoStepApprovalOn = toggleState.isOn;
      }
      state.companySettings.companyToggleList[elementsIndex] = toggleState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanySettingsInitialstate.fulfilled, (state, action) => {
        const { payload } = action;

        state.companySettings = payload.companySettings;
        state.isTwoStepApprovalOn =
          (payload.companySettings?.companyToggleList &&
            payload.companySettings?.companyToggleList.find(
              (toggle) => toggle.companyToggleType === CompanyToggleTypeEnum.twoStepApproval
            )?.isOn) ||
          false;

        state.initialStepApprovalValue = state.isTwoStepApprovalOn;
        state.hasPartnerEnforced2sa = payload.companySettings.approvalProcess.hasPartnerEnforced2sa;
        state.currentTwoStepApprover = {
          id: payload.companySettings?.approvalProcess?.priorityOneSecurityAccountId ?? 0,
          name: payload.companySettings?.approvalProcess?.priorityOneUserName?.split('.').join(' '), //For whatever reason BE sends firstName and lastName as a single string with a dot in between
        };
      })
      .addCase(getApprovers.fulfilled, (state, action) => {
        state.approvers = action.payload.userList ?? [];
        state.isApproversLoading = false;
      })
      .addCase(getApprovers.pending, (state) => {
        state.isApproversLoading = true;
      })
      .addCase(getApprovers.rejected, (state) => {
        state.isApproversLoading = false;
      })
      .addCase(getAutoRunStatus.fulfilled, (state, action) => {
        state.isAutoRunPaygroupPresent = action.payload.isAutoRunPaygroupPresent;
      });
  },
});

export const { updateCompanySettings, updateOneSetting, clearCompanySettings, updateLogoData } =
  companySettingsSlice.actions;
export const companySettingsReducer = companySettingsSlice.reducer;
export const selectCompanySettings = (state: RootState) => state.companySettingsReducer;
export const selectCompanySettingsPriorityOneSecurityAccountId = (state: RootState) =>
  state.companySettingsReducer.companySettings?.approvalProcess?.priorityOneSecurityAccountId;
export const selectCompanySettingsApprovers = (state: RootState) => state.companySettingsReducer.approvers;
export const selectCompanySettingsIsAutoRunPayGroupPresent = (state: RootState) =>
  state.companySettingsReducer.isAutoRunPaygroupPresent;
export const selectTwoStepApprovalInitial = createSelector(
  selectCompanySettings,
  (state) => state.initialStepApprovalValue
);
export const selectCompanyToggleList = (state: RootState) =>
  state.companySettingsReducer.companySettings?.companyToggleList;
export const selectHasPartnerEnforced2SA = createSelector(
  selectCompanySettings,
  (state) => state.hasPartnerEnforced2sa
);
export const selectCurrentTwoStepApprover = createSelector(
  selectCompanySettings,
  (state) => state.currentTwoStepApprover
);
export const selectLogoDetails = createSelector(
  selectCompanySettings,
  (state) => state.companySettings?.logoUploadDate
);
export const selectIsApproversLoading = createSelector(selectCompanySettings, (state) => state.isApproversLoading);
export const selectLogoData = createSelector(selectCompanySettings, (state) => state.logoData);
export const selectCompanyDetailsSettings = createSelector(selectCompanySettings, (state) => state.companySettings);
