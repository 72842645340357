import { IValidationResult, IValidationResults } from 'services/shared/validationResults.contracts';
import { IDayFormatter } from 'services/ui-config-access/uiConfigAccess.contracts';
import { RegexEnum } from 'services/shared/regexEnum.contracts';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import isEqual from 'date-fns/isEqual';
import isValid from 'date-fns/isValid';
import differenceInYears from 'date-fns/differenceInYears';
import getISODay from 'date-fns/getISODay';
import { convertStringToCurrency } from '@wagepoint/ui-toolkit/components/wp-numberField/helpers';
import addDays from 'date-fns/addDays';
import { useTranslation } from 'react-i18next';
import { CompanyStatusTypeEnum } from 'services/appCore/models/schema/Schema';
import { ApplicationTypeEnum } from 'shared/services/appCore/schema';
import { setUser as sentrySetUser } from '@sentry/react';

export const commonStaleTime = 5 * 60 * 1000;

export const appMinSearchKeyLength = 3;

export const wagepoint1Login = 'https://sso.wagepoint.com/';
export const learnMoreYEDiscrepancies =
  'https://wagepoint2help.zendesk.com/hc/en-us/articles/27224656225431#Address-discrepancies';
export const learnMoreYEEchecklist =
  'https://wagepoint2help.zendesk.com/hc/en-us/articles/27224656225431#Use-YE-Checklist';

export const SKIP = 0;
export const TAKE = 100;

const newGuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const enumToArray = (enums: any) => {
  return Object.keys(enums).map(Number).filter(Boolean);
};

const formValueToBit = (enums: any, selection: number[]) => {
  return selection.reduce((total, selected) => total + (selected ? selected : 0), 0);
};

const bitToFormValue = (enums: any, bit: number) => {
  const bits = enumToArray(enums);
  const bitsArray: any = [];
  bits.map((b: any) => {
    if ((bit & b) === b) {
      bitsArray.push(b);
    }
  });
  return bitsArray;
};

const getFileUploadUrl = (): string => {
  const BASE_URL =
    process.env.NODE_ENV == 'development'
      ? process.env.REACT_APP_PROXY_URL
      : process.env.REACT_APP_BASE_URL + '/services/';

  return BASE_URL + 'documentmanagement/docs/upload';
};

const getFileDownloadUrl = (): string => {
  const Download_URL =
    process.env.NODE_ENV == 'development'
      ? process.env.REACT_APP_PROXY_DownloadURL
      : process.env.REACT_APP_BASE_URL + '/services/documentmanagement/docs/download/';
  return Download_URL as string;
};

const getNumberOfDays = (start: Date | string, end: Date | string): number => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

const getLogoutUrl = (): string => {
  let redirectPath = process?.env?.REACT_APP_BASE_URL || '';
  redirectPath = redirectPath.concat(process?.env?.REACT_APP_LOGOUT_URL || '');
  return window.location.origin + redirectPath;
};

const groupBy = (xs: any, key: string) => {
  return xs.reduce((rv: any, x: any) => {
    rv[x[key]] = [...(rv[x[key]] || []), x];
    return rv;
  }, {});
};

const getTokenRefreshUrl = (): string => {
  let redirectPath = process?.env?.REACT_APP_BASE_URL || '';
  const qualifyingParam = process?.env?.REACT_APP_BASE_URL?.replaceAll('/', '') + '%2F';

  redirectPath = redirectPath.concat(process?.env?.REACT_APP_REFRESH_SESSION_URL as string);
  redirectPath = redirectPath.concat(qualifyingParam);
  return window.location.origin + redirectPath;
};

/** a common api response handler to handle api validation errors */
/**
 * Return the comma separated error message from api
 */

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

type ResponseType<R extends IValidationResults | MakeOptional<IValidationResults, 'validationResultList'>> = R extends {
  validationResultList?: (infer A)[];
}
  ? A extends IValidationResult
    ? { validationResultList?: MakeOptional<IValidationResult, 'severity'>[] }
    : R
  : R;

const getApiValidationErrors = <
  R extends IValidationResults | MakeOptional<IValidationResults, 'validationResultList'>
>(
  response: { validationResults?: ResponseType<R>; [key: string]: any },
  validationTag?: string
) => {
  const { validationResults } = response;
  const separationCharacter = ', ';
  let message = '';
  let hasError = false;
  let hasExactError = false;
  if (validationResults) {
    const validationArr = validationResults?.validationResultList;
    if (validationArr?.length) {
      hasError = true;
      validationArr.map((item, index) => {
        // TODO: Replace item.severity === ('Information' as any) with item.severity === ValidationSeverityEnum.Information once John
        //make changes on service side
        const isInformationSeverety = item.severity === ('Information' as any);
        if (isInformationSeverety) hasError = false;
        message = `${message}${item.validationMessage}${index < validationArr.length - 1 ? separationCharacter : ''}`;
        hasExactError = item.validationTag === validationTag;
      });
    }
  } else {
    hasError = true;
    message = JSON.parse(JSON.stringify(response))?.message;
  }
  return { hasError, message, hasExactError };
};

const upperCaseArray = (input: string) => {
  return input.replace(/[_\.,]/, ' ').replace(/(?!^)([A-Z]|\d+)/g, ' $1');
};

const giveStartAndEndOfDay = ({
  type,
  date,
  toISOFormat,
}: {
  type: IDayFormatter;
  date?: string | Date;
  toISOFormat?: boolean;
}) => {
  let formatedDate: any = '';
  switch (type) {
    case IDayFormatter.StartofDay:
      formatedDate = new Date(date || new Date()).setUTCHours(0, 0, 0, 0);
      break;
    case IDayFormatter.EndofDay:
      formatedDate = new Date(date || new Date()).setUTCHours(23, 59, 59, 999);
      break;
    default:
      break;
  }
  if (toISOFormat) return formatedDate.toISOString();
  return formatedDate;
};

const compareDates = ({
  startDate,
  endDate,
  checkDate,
}: {
  startDate: string;
  endDate: string;
  checkDate?: string | null;
}) => {
  const fDate = Date.parse(startDate.split('+')[0]);
  const lDate = Date.parse(endDate.split('+')[0]);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const cDate = checkDate ? Date.parse(checkDate.split('+')[0]) : Date.parse(currentDate.toDateString());
  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
};

const areDatesEqualIgnoringTime = (date1: Date, date2: Date) => {
  const startOfDayDate1 = startOfDay(date1);
  const startOfDayDate2 = startOfDay(date2);
  return isEqual(startOfDayDate1, startOfDayDate2);
};

/**
 *The function returns the given array with the total for the given fieldNames
 * @param1 array: the array which contains the fields to calculate total
 * @param2 totalFieldList: the keys for which we need to calculate total.
 * for the given array: [{income: 20} , {income: 20}] and totalFIeldList: [income]
 * @returns {income: 40}
 */
export const calculateTotalForGrid = ({ array, totalFieldList }: { array: any[]; totalFieldList: string[] }) => {
  const initObj: any = {};
  totalFieldList.forEach((el) => {
    initObj[el] = 0;
  });
  const result = array.reduce(
    (acc: any, item: any) => {
      totalFieldList.forEach((el) => {
        acc[el] = parseFloat(((item[el] || 0) + acc[el]).toFixed(2));
      });
      return acc;
    },
    { ...initObj }
  );
  return result;
};

const getApplicationType = () => {
  const isPartnerEmployerPortal = localStorage.getItem('isPartnerEmployerPortal');
  if (isPartnerEmployerPortal) return ApplicationTypeEnum.accountingDashboard;
  const employeePortalIndex = window.location.pathname.indexOf('/ui/employeeportal/');
  if (employeePortalIndex > 0) return ApplicationTypeEnum.employeePortal;

  const alfredIndex = window.location.pathname.indexOf('/ui/alfred/');
  if (alfredIndex > 0) return ApplicationTypeEnum.alfred;

  return ApplicationTypeEnum.wagepoint;
};

export const isAlfredApplication = () => {
  const applicationType = getApplicationType();
  return applicationType === ApplicationTypeEnum.wagepoint;
};

const getPortalLink = (portalName: string) => {
  const { origin } = window.location;
  return `${origin}${process.env.REACT_APP_BASE_URL}/ui/${portalName}`;
};

function isArrContains<T>(arr1: Array<T>, arr2: Array<T>, key: keyof T): boolean {
  return arr2.every((secondElem) => arr1.find((firstElem) => firstElem[key] === secondElem[key]));
}

const getValueByPath = (objectValues: any, path: string): any =>
  path.split('.').reduce((accumulator, currentValue) => accumulator?.[currentValue], objectValues);

const chunk = <T>(array: T[], size = 1): T[][] => {
  const arrayChunks = [];
  for (let i = 0; i < array.length; i += size) {
    const arrayChunk = array.slice(i, i + size);
    arrayChunks.push(arrayChunk);
  }
  return arrayChunks;
};

const formatDate = (date: string | Date, formatTemplate = 'MMMM dd, yyyy') => {
  if (date) return format(new Date(date), formatTemplate);
};

const addDaysToDate = (date: string | Date, delta: number) => {
  const nextDate = new Date(date);
  return addDays(nextDate, delta);
};

const sanitizeDate = (input: string | Date | undefined) => {
  if (!input) {
    return undefined;
  }

  const maybeDate = new Date(input);

  if (!isNaN(maybeDate.getDate())) {
    return maybeDate.toDateString();
  }

  return undefined;
};

const getRegex = (value: RegexEnum): RegExp => {
  switch (value) {
    case RegexEnum.Phone:
      return /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    case RegexEnum.InternationalPhone:
      return /^[0-9+]+$/;
    case RegexEnum.Email:
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    case RegexEnum.AlphaFrench:
      return /^[a-zA-Z\sàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\/-]*$/;
    case RegexEnum.CAPostalCode:
      return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    case RegexEnum.USPostalCode:
      return /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    case RegexEnum.AlphaEnglish:
      return /^[aA-zZ\s]+$/;
    case RegexEnum.Address:
      return /^[a-zA-Z0-9\s,.'-]{3,}$/;
    case RegexEnum.AlphaNumEnglish:
      return /^[a-zA-Z0-9\s]*$/;
    case RegexEnum.AlphaNumFrench:
      return /^[a-zA-Z0-9\sàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\/-]*$/;
    case RegexEnum.AlphaRegex:
      return /^[a-zA-Z\s,.'()#&-;”%*+\\/<>$=?:~|`^_[\]{}]*$/;
    case RegexEnum.NameRegex:
      return /^[a-zA-Z\s,.'()#&-;”%*+\\/<>$=?:~|`^_[\]{}][^0-9!@]*$/;
    case RegexEnum.NumRegex:
      return /^[0-9.]+$/;
    case RegexEnum.StandardAlphaRegex:
      return /^[^0-9]+$/;
    case RegexEnum.LegalCompanyNameRegex:
      return /^[a-zA-Z0-9' ]+$/;
    case RegexEnum.CraNumberRegex:
      return /(\d{9})-?([A-za-z]{2})-?(\d{4})/;
    case RegexEnum.FeiNumberRegex:
      return /^\d{2}-\d{7}$/;
    case RegexEnum.DecimalNumberRegex:
      return /^\d{1,3}(?:\.\d{0,2})?$/;
    case RegexEnum.SinRegex:
      return /^\d{3}-\d{3}-\d{3}$/;
    default:
      return new RegExp('');
  }
};

const getFebruaryEndDate = (year: number) => endOfMonth(new Date(year, 1, 1)).getDate();

const parseMaskValue = (string: any, maskValue?: string, maskChar?: string) => {
  if (!string) {
    return string;
  }
  if (!maskValue || !maskChar) {
    return string.replace(/-/g, '');
  } else if (string.includes('-')) {
    return string;
  } else {
    const result = maskValue.split('');
    let j = 0;
    for (let i = 0; i < maskValue.length; i++) {
      if (maskValue[i] !== '-') {
        result[i] = String(string).charAt(j);
        j++;
      }
    }
    return result.join('');
  }
};

const getUnmaskedValue = (value?: string) => {
  return value?.replace(/[⎵-]/g, '');
};
const removeTimeZoneFromISOString = (date: string): string => {
  return date.replace(/((\+|\-)[0-9]{2}\:?([0-9]{2})?$)|(Z)$/g, '');
};

const isValidDate = (date: any): boolean => {
  return isValid(new Date(date));
};

function formatToISONonUTCStartOfDay<T>(date: T): Exclude<T, Date> | string {
  const formatString = "yyyy-MM-dd'T'HH:mm:ss.SSS";
  if (date instanceof Date) {
    return format(startOfDay(date), formatString);
  } else if (typeof date === 'string') {
    return format(startOfDay(new Date(removeTimeZoneFromISOString(date))), formatString);
  }

  return date as Exclude<T, Date>;
}

export const formatWithISO = (date: string | Date, formatString: string) => {
  return format(new Date(formatToISONonUTCStartOfDay(date)), formatString);
};

const precisionSum = (a: number | null | undefined, b: number | null | undefined): number => {
  if (a === undefined || a === null || b === undefined || b === null) return 0;
  const maxAmountNumberAfterPoint = Math.max(
    (a ?? 0).toString()?.split('.')?.[1]?.length || 0,
    (b ?? 0).toString()?.split('.')?.[1]?.length || 0
  );
  const coefficient = Math.pow(10, maxAmountNumberAfterPoint);

  // this has a catch in js: 4741.48 *100 giving 474147.99999999994
  // this will need a solution & it opened https://dev.azure.com/wagepoint/Wagepoint/_workitems/edit/52729/
  return (a * coefficient + b * coefficient) / coefficient;
};

const decimalToTime = (time: any) => {
  if (!time) return '00:00';
  const hours = Number(time);
  const hr = Math.floor(Math.abs(hours));
  const min = Math.floor((Math.abs(hours) * 60) % 60);
  return (hr < 10 ? '0' : '') + hr + ':' + (min < 10 ? '0' : '') + min;
};

const timeConversion = (time: string) => {
  const splits = time.split(/[.:]/);
  const hr = parseInt(splits[0], 10);
  const min = splits[1] ? parseInt(splits[1], 10) : 0;
  return parseFloat((hr + min / 60).toFixed(2));
};

const timeToDecimal = (time: string) => {
  const result = timeConversion(time);
  if (checkTimeValue(result)) return result;
  else return result + 0.01;
};

const checkTimeValue = (num: number) => {
  const time = decimalToTime(num);
  const result = timeConversion(time);
  return num === result;
};

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

/**
 *
 * @param phoneNumberString unformated phone number string Example: 1234567890
 * @returns Formated Phone number string Example: (123) 456-7890
 */
const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); //Does not works with the regex taken from our regex generator function getRegex(1)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const removeHyphenOrSpace = (value: string) => {
  return value.replace(/-|\s/g, '');
};

export const upCaseWords = (input: string) => upperCaseArray(input).split(' ').filter(Boolean);

export const capitalize = (input: string) => (input[0] || '').toUpperCase() + input.slice(1);

export const appendDollarAndLimitToTwoDecimal = (item: number | undefined) => {
  if (typeof item === 'number')
    return `$${Number(item)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  else if (typeof item === 'string') return item;
};

export const negativeCurrencyFormatter = (value: number, language?: any, hideDollar?: boolean) => {
  const hasNegative = value?.toString().includes('-');
  if (hasNegative) value = Math.abs(value); // quick fix to handle negative numbers
  return `${hasNegative ? '-' : ''}${hideDollar ? '' : '$'}${convertStringToCurrency({
    language,
    value: value?.toFixed(2) || 0,
  })}`;
};

export const useDisplayCurrencyFormat = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const renderField = (value: number, hideDollar?: boolean) => {
    return negativeCurrencyFormatter(value, language, hideDollar);
  };
  return { renderField };
};

export function replaceNullableToUndefined<T extends Record<string, any>>(obj: T) {
  return Object.entries(obj).reduce<T>((acc, curr) => {
    const key = curr[0] as keyof T;
    acc[key] = curr[1] === '' || curr[1] === null ? undefined : curr[1];
    return acc;
  }, {} as T);
}
const localizeTZ = (date: any) => {
  return startOfDay(new Date(removeTimeZoneFromISOString(date)));
};

// Luhn algorithm. Used for checking SIN, Credit Number, etc.
function isValidLuhn(number: string): boolean {
  if (!number) return false;
  let length = number.length;
  let bit = 1;
  let sum = 0;

  while (length) {
    const value = parseInt(number.charAt(--length), 10);
    bit ^= 1;
    sum += bit ? [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][value] : value;
  }

  return sum % 10 === 0;
}

/*
function to get selected filters
*/

const getSelectedFilters = (filterArr: any[], selectedFilters: any = {}, currentId: any = '') => {
  for (let i = 0; i < filterArr.length; i++) {
    if (filterArr[i].filterItemList) {
      currentId = filterArr[i].id;
      getSelectedFilters(filterArr[i].filterItemList, selectedFilters, currentId);
    } else if (filterArr[i].isSelected) {
      selectedFilters[currentId] = selectedFilters[currentId] ? selectedFilters[currentId] : [];
      selectedFilters[currentId].push(filterArr[i].id);
    }
  }
  return selectedFilters;
};

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const entries = binaryString.split('\n'); // Assuming entries are separated by newline character
  const rows: string[] = [];

  for (const entry of entries) {
    // Assuming each entry is a comma-separated list of values
    const values = entry.split(',');
    const row = values.join(','); // Rejoin the values to form a CSV row
    rows.push(row);
  }

  const csvString = rows.join('\n'); // Join rows with newline character to form CSV content
  const bytes = new Uint8Array(csvString.length);

  for (let i = 0; i < csvString.length; i++) {
    const ascii = csvString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

const exportToCSV = (dataBase64: string, fileName: string) => {
  const bytes = base64ToArrayBuffer(dataBase64);
  const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
};

const isDeepEqual = (x: any, y: any) => {
  if (x === y) {
    return true;
  }
  if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (Object.keys(x).length != Object.keys(y).length) return false;

    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!isDeepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  }
  return false;
};

const formatNumberAddHyphen = (phoneNumber: string) => phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

const addComas = (arr: Array<string>) => arr.reduce((acc, elem, index) => (index === 0 ? elem : `${acc}, ${elem}`), '');

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatNumberToMoney = (num: number) => {
  return usdFormatter.format(num);
};

const hideAccountNumber = (accountNumber?: string) => {
  if (!accountNumber) return '';
  const hidedPart = Array(accountNumber.length - 2)
    .fill('*')
    .join('');
  return accountNumber.slice(0, 2) + hidedPart;
};

const isDateGreater = (d1: Date | string, d2: Date | string, days: number) => {
  d1 = new Date(d1);
  return +new Date(d2) > d1.setDate(d1.getDate() + (days || 0));
};

const displayFormatForName = ({ firstName, lastName }: { firstName?: string; lastName?: string }) => {
  return `${firstName || ''} ${lastName || ''}`;
};

const getAgeFromDate = (date: Date) => {
  const birthDate = new Date(date);
  return differenceInYears(new Date(), birthDate);
};

const isAgeLessThan18 = (date: Date) => {
  const ageLimit = 18;
  return getAgeFromDate(date) < ageLimit;
};

const isAgeMorethan70 = (date: Date) => {
  const ageMin = 70;
  return getAgeFromDate(date) > ageMin;
};

const removePostalChar = (str: string) => {
  return str?.replace(/(⎵|\s)/g, '') || '';
};

const sanitizeHTML = (htmlString: string | undefined) => {
  if (!htmlString) return '';
  // List of allowed HTML tags
  // Other tags can be added in future, please add the tags that to not posses security risk
  const allowedTags = ['i'];

  // Create a new element
  const tempElement = document.createElement('span');
  tempElement.innerHTML = htmlString;

  // Remove disallowed tags and attributes
  const disallowedTags = Array.from(tempElement.querySelectorAll('*')).filter(
    (element) => !allowedTags.includes(element.tagName.toLowerCase())
  );
  disallowedTags.forEach((element) => {
    if (element?.parentNode) element?.parentNode.removeChild(element);
  });

  // Return the sanitized HTML string
  return tempElement.innerHTML;
};

const santizeDiacriticMarks = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

type DayOfTheWeek = 'Mon' | 'Tue' | 'Wed' | 'Thur' | 'Fri' | 'Sat' | 'Sun';
const getDayInPast = (dayOfWeek: DayOfTheWeek, fromDate = new Date()) => {
  const dayOfWeekMap: Record<DayOfTheWeek, number> = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // follow the getISODay format (7 for Sunday, 1 for Monday)
  const fromISODay = getISODay(fromDate);

  // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek
  const targetISODay = dayOfWeekMap[dayOfWeek];

  // targetISODay >= fromISODay means we need to trace back to last week
  // e.g. target is Wed(3), from is Tue(2)
  // hence, need to -7 the account for the offset of a week
  const offsetDays = targetISODay >= fromISODay ? -7 + (targetISODay - fromISODay) : targetISODay - fromISODay;

  return addDays(fromDate, offsetDays);
};

const makeOrdinal = (n: number | string, locale: string) => {
  const num = Number(n) % 10;
  switch (locale) {
    case 'en-us':
    case 'en-ca': {
      switch (num) {
        case 1:
          return `${n}st`;
        case 2:
          return `${n}nd`;
        case 3:
          return `${n}rd`;
        default:
          return `${n}th`;
      }
    }
    case 'fr-fr': {
      switch (num) {
        case 1:
          return `${n}er`;
        default:
          return `${n}ème`;
      }
    }
    default: {
      throw new Error(`Unknown locale: ${locale}`);
    }
  }
};

const getOrdinalNum = (number: number) => {
  let selector;

  if (!number) return '';

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

const hyphenToCamelCase = (inputString: string) => {
  return inputString.replace(/-([a-z])/g, (match: string, group1: string) => {
    return group1.toUpperCase();
  });
};

const checkIsUserAccessRestricted = (companyStatusType?: CompanyStatusTypeEnum) => {
  switch (companyStatusType) {
    case CompanyStatusTypeEnum.closed:
    case CompanyStatusTypeEnum.expired:
    case CompanyStatusTypeEnum.inActive:
    case CompanyStatusTypeEnum.banned:
      return true;
    default:
      return false;
  }
};

/*
Below function is to validate CRA number:
Rules to validate:
<!--- Step1  ADD: every second digit, starting with the first number at the left and excluding the final digit, to get a total.--->
<!--- Step2:  MULTIPLY: every second digit, starting with the second digit, by 2. --->
<!--- Step3 : If the result of any of the calculations in STEP 2 is less than or equal to 9, add it to the total amount calculated in STEP 1. --->
<!--- Step4:  If the result of any of the calculations in STEP 2 is more than 9, total both digits, then add the amounts to the number above.--->
<!--- Step5:  SUBTRACT: the last digit of the amount in STEP 4 from 10. NOTE: If last digit of the amount in STEP 4 is 0, then the check digit is 0. --->
<!--- Final check ... if step5 value equal the last digit (on the right side) of the account number, then it is valid account --->
*/

const isValidCRAnumber = (craNumber: string) => {
  const validRefCodes = ['RT', 'RZ', 'RP', 'RC'];
  craNumber = craNumber.split('-').join('');
  const businessNumber = craNumber.substring(0, 9);
  const programCode = craNumber.substring(9, 11);
  const isInvalidProgramCode = validRefCodes.indexOf(programCode) < 0;
  const refrenceNumber = craNumber.substring(11, 15);
  if (
    parseInt(businessNumber[0]) === 0 ||
    parseInt(businessNumber) === 0 ||
    parseInt(refrenceNumber) === 0 ||
    isInvalidProgramCode
  )
    return false;
  let initialResult = 0;
  for (let i = 0; i < 8; i++) {
    const digit = parseInt(businessNumber[i]);
    if (i % 2 === 0) {
      initialResult += digit;
    } else {
      const digitMultByTwo = digit * 2;
      if (digitMultByTwo <= 9) {
        initialResult += digitMultByTwo;
      } else {
        initialResult += Math.floor(digitMultByTwo / 10) + (digitMultByTwo % 10);
      }
    }
  }
  const lastDigit = initialResult % 10;
  if (lastDigit === 0) {
    return lastDigit === parseInt(businessNumber[8]);
  }
  return 10 - lastDigit === parseInt(businessNumber[8]);
};

const validateOldRQNumber = (rqNumber: string) => {
  const checkNumber = rqNumber.substring(0, 8);
  let sum = 0;

  for (let i = 0; i < checkNumber.length; i++) {
    const weight = (i % 2) + 1;
    const char = checkNumber[i];
    let temp = parseInt(char) * weight;
    if (temp > 9) temp -= 9;
    sum += temp;
  }

  let checkDigit = sum % 10;
  if (checkDigit > 9) checkDigit -= 10;

  const lastDigit = parseInt(rqNumber[8]);
  if (10 - checkDigit === lastDigit) return true;

  return false;
};

const isValidRQnumber = (rqNumber: string, isNineDigitValidation: boolean) => {
  if (isNineDigitValidation) {
    return validateOldRQNumber(rqNumber);
  }
  const rqNumberParts = rqNumber.split('-');
  const refrenceNumber = rqNumberParts[2];
  if (parseInt(refrenceNumber) === 0) {
    return false;
  }
  const initialNumber = parseInt(rqNumber.substring(0, 2));

  if (!(initialNumber === 10 || initialNumber === 12 || (initialNumber >= 40 && initialNumber <= 59))) {
    return false;
  }

  const checkNumber = rqNumber.substring(0, 9);
  let sum = 0;

  for (let i = 0; i < checkNumber.length; i++) {
    const weight = (i % 6) + 2;
    const char = checkNumber[checkNumber.length - 1 - i];
    sum += parseInt(char) * weight;
  }

  let checkDigit = 11 - (sum % 11);
  if (checkDigit > 9) checkDigit -= 10;

  const lastDigit = parseInt(rqNumber[9]);
  if (checkDigit === lastDigit) return true;

  return false;
};

function deReference<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

const removeSpaces = (originalString: string) => {
  if (originalString) return originalString.replace(/\s/g, '');
  return originalString;
};

const getPhoneValueLength = (codeLength: number) => {
  const min = 4;
  const max = 13;
  return { minLength: min + codeLength, maxLength: max + codeLength };
};

const buildMask = ({ value, limit }: { value: string; limit: number }) => {
  const valueLength = value.length;
  if (valueLength <= limit) return value;
  return '*'.repeat(valueLength - limit) + value.slice(-limit);
};

// TODO: move into shared library
const performSortArrObj = <T>(list: T[], sortKey: keyof T): T[] => {
  return list.sort((iterOne, iterTwo) => {
    const valueOne = String(iterOne[sortKey]);
    const valueTwo = String(iterTwo[sortKey]);
    return valueOne.localeCompare(valueTwo);
  });
};

const getReportFilterQuery = ({ path, filterObj }: { path: string; filterObj: Record<string, any> }) => {
  /*
  Just appending the value object to url.
  This function will delete all key values with all falsy values
  */
  const removeEmpty = (obj: Record<string, any>) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
      if ((!obj[key] && obj[key] !== 0) || (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)) {
        delete obj[key];
      }
    });
    return obj;
  };

  //appending object as query params
  return `${path}?${Object.entries(removeEmpty(filterObj))
    .map((k) => k.join('='))
    .join('&')}`;
};

const generateFullName = ({ fn, ln }: { fn: string; ln: string }) => {
  return `${fn ?? ''} ${ln ?? ''}`;
};

const clearSentryUserContext = () => {
  sentrySetUser(null);
};

export {
  generateFullName,
  newGuid,
  formValueToBit,
  bitToFormValue,
  buildMask,
  enumToArray,
  getErrorMessage,
  getFebruaryEndDate,
  getFileUploadUrl,
  getFileDownloadUrl,
  getNumberOfDays,
  getLogoutUrl,
  groupBy,
  getTokenRefreshUrl,
  getApiValidationErrors,
  compareDates,
  upperCaseArray,
  getApplicationType,
  getPortalLink,
  isArrContains,
  getValueByPath,
  giveStartAndEndOfDay,
  chunk,
  formatDate,
  addDaysToDate,
  sanitizeDate,
  getRegex,
  parseMaskValue,
  getUnmaskedValue,
  removeTimeZoneFromISOString,
  isValidDate,
  formatToISONonUTCStartOfDay,
  precisionSum,
  decimalToTime,
  timeToDecimal,
  formatPhoneNumber,
  localizeTZ,
  isValidLuhn,
  getSelectedFilters,
  base64ToArrayBuffer,
  exportToCSV,
  isDeepEqual,
  formatNumberAddHyphen,
  addComas,
  formatNumberToMoney,
  hideAccountNumber,
  isDateGreater,
  displayFormatForName,
  getAgeFromDate,
  isAgeLessThan18,
  isAgeMorethan70,
  areDatesEqualIgnoringTime,
  removePostalChar,
  removeSpaces,
  sanitizeHTML,
  santizeDiacriticMarks,
  getDayInPast,
  makeOrdinal,
  getOrdinalNum,
  hyphenToCamelCase,
  checkIsUserAccessRestricted,
  isValidCRAnumber,
  deReference,
  getPhoneValueLength,
  isValidRQnumber,
  performSortArrObj,
  getReportFilterQuery,
  clearSentryUserContext,
};
