import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'redux/hooks/hook';

import { noValueStr } from 'pages/accounting-dashboard/client-directory/constants';
import { Maybe } from 'pages/accounting-dashboard/client-directory/interfaces';

interface BillingTranslationParams {
  isBillingSetup: boolean;
}

export const useBillingTranslation = (
  clientPays?: Maybe<boolean>,
  { isBillingSetup }: BillingTranslationParams = { isBillingSetup: true }
) => {
  const { t: tBillingSetup } = useTranslation('clientDirectory', { keyPrefix: 'billingSetup' });
  const { t: tMainInfo } = useTranslation('clientDirectory', { keyPrefix: 'overview.mainInfo' });

  const company = useAppSelector(({ configReducer }) => configReducer.selectedCompany.name);

  if (typeof clientPays !== 'boolean') {
    return noValueStr;
  }
  if (isBillingSetup) {
    return clientPays ? tBillingSetup('client.heading') : tBillingSetup('partner.heading', { company });
  }
  return clientPays ? tMainInfo('clientPays') : tMainInfo('partnerPays');
};
