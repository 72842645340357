import { useEffect } from 'react';

import { getApiValidationErrors } from '@wagepoint/ui-shared-library/shared-system/functions/helpers';

import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import {
  getCompanySettingsInitialstate,
  selectCurrentTwoStepApprover,
  selectTwoStepApprovalInitial,
} from 'redux/slice/companySettingsSlice';
import { selectCompanyId } from 'redux/slice/configSlice';
import { useLoaders } from 'redux/hooks/useLoaders';
import { useSnackbar } from 'redux/hooks/useSnackbar';

export const useTwoStepEnabled = () => {
  const dispatch = useAppDispatch();

  const { showLoader, hideLoader } = useLoaders();
  const snackbar = useSnackbar();

  const companyId = useAppSelector(selectCompanyId);
  const isTwoStepApprovalEnabled = useAppSelector(selectTwoStepApprovalInitial);
  const twoStepApprover = useAppSelector(selectCurrentTwoStepApprover);

  useEffect(() => {
    async function fetchData() {
      try {
        showLoader();
        const response = await dispatch(getCompanySettingsInitialstate({ companyId })).unwrap();
        const { message, hasError } = getApiValidationErrors(response);
        if (hasError) {
          throw new Error(message);
        }
      } catch (e: any) {
        snackbar.error(e?.message);
      } finally {
        hideLoader();
      }
    }
    fetchData();
  }, []);

  return { isTwoStepApprovalEnabled, twoStepApproverName: twoStepApprover?.name ?? '' };
};
