import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IValidationResults } from 'services/shared/validationResults.contracts';
import {
  GetAdminPreferencesRequest,
  GetFirmPreferencesRequest,
} from 'services/person-settings-access/personSettingsAccess.service';
import {
  IGeneralPreferences,
  ISecurityPreferences,
  IEmail,
  IGetAdminPreferencesRequest,
  IGetFirmPreferencesRequest,
} from 'services/person-settings-access/personSettingsAccess.contracts';
import { RootState } from 'redux/store/store';
import { buildSelector } from 'util/storage/buildSelector';
interface IPersonPreferences {
  isValidating: boolean;
  isReady: boolean;
  error: string | unknown;
  primaryEmail: string;
  generalPreferences: IGeneralPreferences | null;
  securityPreferences: ISecurityPreferences | null;
  personEmailList: Array<IEmail> | null;
  validationResults?: IValidationResults;
  isVerified: boolean;
  isSecondaryVerified: boolean;
  secondaryEmail: string;
  hasFetched: null | boolean;
}

const initialState: IPersonPreferences = {
  isValidating: false,
  primaryEmail: '',
  isReady: false,
  error: '',
  generalPreferences: null,
  securityPreferences: null,
  personEmailList: null,
  isVerified: false,
  isSecondaryVerified: false,
  secondaryEmail: '',
  hasFetched: null,
};

export const getAdminPreferences = createAsyncThunk(
  'preferences/getAdminPreferences',
  async (request: IGetAdminPreferencesRequest) => {
    const response = await GetAdminPreferencesRequest(request);
    return response;
  }
);

export const getFirmPreferences = createAsyncThunk(
  'preferences/getFirmPreferences',
  async (request: IGetFirmPreferencesRequest) => {
    const response = await GetFirmPreferencesRequest(request);
    return response;
  }
);

export const getPersonPreferencesSlice = createSlice({
  name: 'preferencesSlice/getPersonPreferences',
  initialState,
  reducers: {
    updatePreferences: (state, action: PayloadAction<IPersonPreferences>) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminPreferences.pending, (state) => {
        state.isValidating = true;
        state.hasFetched = false;
      })
      .addCase(getAdminPreferences.fulfilled, (state, action) => {
        state.generalPreferences = action.payload.generalPreferences || state.generalPreferences;
        state.securityPreferences = action.payload.securityPreferences || state.securityPreferences;
        state.isValidating = false;
        state.hasFetched = true;
        state.error = '';
        state.isReady = true;
        if (action.payload.securityPreferences) {
          const emailList = action.payload.securityPreferences.emailList;
          state.personEmailList = emailList;
          state.isVerified = emailList && emailList.length > 0 ? emailList[0].isVerified : false;
          state.primaryEmail = emailList && emailList.length > 0 ? emailList[0].emailAddress : '';
          state.secondaryEmail = emailList && emailList.length > 1 ? emailList[1].emailAddress : '';
          state.isSecondaryVerified = emailList && emailList.length > 1 ? emailList[1].isVerified : false;
        }

        state.validationResults = action.payload.validationResults;
      })
      .addCase(getAdminPreferences.rejected, (state, action) => {
        state.error = action.payload;
        state.isValidating = false;
        state.hasFetched = true;
      })
      .addCase(getFirmPreferences.pending, (state) => {
        state.isValidating = true;
        state.hasFetched = false;
      })
      .addCase(getFirmPreferences.fulfilled, (state, action) => {
        state.generalPreferences = action.payload.generalPreferences || state.generalPreferences;
        state.securityPreferences = action.payload.securityPreferences || state.securityPreferences;
        state.isValidating = false;
        state.hasFetched = true;
        state.error = '';
        state.isReady = true;
        if (action.payload.securityPreferences) {
          const emailList = action.payload.securityPreferences.emailList;
          state.personEmailList = emailList;
          state.isVerified = emailList && emailList.length > 0 ? emailList[0].isVerified : false;
          state.primaryEmail = emailList && emailList.length > 0 ? emailList[0].emailAddress : '';
          state.secondaryEmail = emailList && emailList.length > 1 ? emailList[1].emailAddress : '';
          state.isSecondaryVerified = emailList && emailList.length > 1 ? emailList[1].isVerified : false;
        }

        state.validationResults = action.payload.validationResults;
      })
      .addCase(getFirmPreferences.rejected, (state, action) => {
        state.error = action.payload;
        state.isValidating = false;
        state.hasFetched = true;
      });
  },
});
export const { updatePreferences } = getPersonPreferencesSlice.actions;
export const getPersonPreferencesReducer = getPersonPreferencesSlice.reducer;
export const [useSelectIsVerified, selectIsVerified] = buildSelector(
  (state) => state.getPersonPreferencesReducer.isVerified
);
export const [useSelectIsFetched, selectIsFetched] = buildSelector(
  (state: RootState) => state.getPersonPreferencesReducer.hasFetched
);
