import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  BankAccountInterface,
  GetAuthorizedSignatoryResponseInterface,
} from 'shared/services/finance/companyBankAccess';

interface IMigrationSlice {
  showMigrationCompletePopup: boolean;
  signatoryInfo: GetAuthorizedSignatoryResponseInterface['signingAuthority'] | null;
  signatoryInfoFetched: boolean;
  bankData: BankAccountInterface | null;
  loadingStates: { isBankLoading: boolean; isSignatoryLoading: boolean };
}

const initialState: IMigrationSlice = {
  showMigrationCompletePopup: false,
  signatoryInfo: null,
  signatoryInfoFetched: false,
  loadingStates: {
    isBankLoading: false,
    isSignatoryLoading: false,
  },
  bankData: null,
};

export const migrationSlice = createSlice({
  name: 'migration',
  initialState,
  reducers: {
    setMigrationSlicePopupState: (state, action: PayloadAction<{ showMigrationCompletePopup: boolean }>) => {
      state.showMigrationCompletePopup = action.payload.showMigrationCompletePopup;
    },
    setSignatoryData: (state, action: PayloadAction<GetAuthorizedSignatoryResponseInterface['signingAuthority']>) => {
      state.signatoryInfo = action.payload;
    },
    fetchStateSignatory: (state, action: PayloadAction<boolean>) => {
      state.signatoryInfoFetched = action.payload;
    },
    setBankData: (state, action: PayloadAction<BankAccountInterface>) => {
      state.bankData = action.payload;
    },
    setLoadingStates: (
      state,
      action: PayloadAction<{
        value: { isSignatoryLoading: boolean; isBankLoading: boolean };
      }>
    ) => {
      state.loadingStates = { ...action.payload.value };
    },
  },
});
export const { setMigrationSlicePopupState, setSignatoryData, fetchStateSignatory, setBankData, setLoadingStates } =
  migrationSlice.actions;
export const migrationSliceReducer = migrationSlice.reducer;
export const selectMigrationReducer = (state: RootState) => state.migrationSliceReducer;
export const getMigrationCompletedPopupState = createSelector(
  selectMigrationReducer,
  ({ showMigrationCompletePopup }) => showMigrationCompletePopup
);
export const selectSignatoryInfo = createSelector(selectMigrationReducer, ({ signatoryInfo }) => signatoryInfo);
export const selectSignatoryInfoLoading = createSelector(
  selectMigrationReducer,
  ({ signatoryInfoFetched }) => signatoryInfoFetched
);
export const selectLoadingStates = createSelector(selectMigrationReducer, ({ loadingStates }) => loadingStates);
