import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { routes } from 'pages/accounting-dashboard/routes';
import useCheckSecurityRoleType from 'pages/accounting-dashboard/commonHooks/useCheckSecurityRoleType';
import { ClientDirectoryRoutes } from 'pages/accounting-dashboard/client-directory/routes';

import { useClientId } from './useClientId';
import { useHasClientInfoAccess } from 'pages/accounting-dashboard/client-directory/hooks/useHasClientInfoAccess';
import { IWpTabProps } from '@wagepoint/ui-toolkit/components/wp-tabs/wpTabs';

interface ITabPage {
  page: string;
  label: string;
}

export const useTabs = () => {
  const { t } = useTranslation('clientDirectory');
  const { pathname } = useLocation();

  const { isAccountOwner, isAccountAdministrator } = useCheckSecurityRoleType();

  const clientId = useClientId();
  const hasClientInfoAccess = useHasClientInfoAccess();

  const tabLabels: Array<ITabPage> = [
    {
      page: ClientDirectoryRoutes.Overview,
      label: t('overviewLabel'),
    },
    {
      page: ClientDirectoryRoutes.Administrators,
      label: t('administratorsLabel'),
    },
    {
      page: ClientDirectoryRoutes.Notifications,
      label: t('notificationsLabel'),
    },
  ];
  const isShowTabs = tabLabels.some(({ page }) => pathname.includes(page));

  const getActiveTab = (locationPathname: string): number => {
    const activeTab = tabs.findIndex(({ key }) => locationPathname.includes(String(key)));
    return activeTab === -1 ? 0 : activeTab;
  };

  const getDisabledTabs = (page: string) => {
    if (page !== ClientDirectoryRoutes.Overview) {
      return !hasClientInfoAccess;
    }
    return false;
  };

  const tabs: Array<IWpTabProps> = tabLabels
    .filter(({ page }) => {
      if (!isAccountOwner && !isAccountAdministrator) {
        return page !== ClientDirectoryRoutes.Administrators;
      }
      return true;
    })
    .map(({ page, label }) => ({
      label,
      component: Link,
      disableRipple: true,
      key: page,
      disabled: getDisabledTabs(page),
      to: `${routes.ClientDirectory}/${page}/${clientId ?? ''}`,
    }));

  return {
    isShowTabs,
    tabs,
    activeTab: getActiveTab(pathname),
  };
};
