import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IValidationResults } from 'services/shared/validationResults.contracts';
import {
  IPersistPersonProfileRequest,
  IPersistPersonProfileResponse,
} from 'services/person-settings-access/personSettingsAccess.contracts';
import { PersistPersonProfile } from 'services/person-settings-access/personSettingsAccess.service';

interface IPersonProfile {
  isReady: boolean;
  isValidating: boolean;
  error: string | unknown;
  validationResults?: IValidationResults;
}

const initialState: IPersonProfile = {
  isReady: false,
  isValidating: false,
  error: '',
};

export const persistPersonProfile = createAsyncThunk(
  'preferences/persistPersonProfile',
  async (request: IPersistPersonProfileRequest) => {
    const response: IPersistPersonProfileResponse = await PersistPersonProfile(request);
    return response;
  }
);

export const personProfileSlice = createSlice({
  name: 'preferences/personProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(persistPersonProfile.pending, (state) => {
        state.isValidating = true;
        state.isReady = false;
      })
      .addCase(persistPersonProfile.fulfilled, (state, action) => {
        state.isValidating = false;
        state.isReady = true;
        state.error = '';
        state.validationResults = action.payload.validationResults;
      })
      .addCase(persistPersonProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.isValidating = false;
      });
  },
});

export const {} = personProfileSlice.actions;
export const personProfileReducer = personProfileSlice.reducer;
export const select2FA = (state: RootState) => state.personProfileReducer;
