import { Post } from 'services/httpClient';
import {
  IGetInitialStateXeroRequest,
  IGetInitialStateXeroResponse,
  IDisconnectFromXeroRequest,
  IDisconnectFromXeroResponse,
  IConnectToXeroRequest,
  IGetXeroAccountListRequest,
  IGetXeroAccountListResponse,
  IGetChartOfAccountsRequest,
  IGetChartOfAccountsResponse,
  IPersistChartOfAccountsRequest,
  IPersistChartOfAccountsResponse,
  IExportPayrollRequest,
  IExportPayrollResponse,
  IGetTaxRateListRequest,
  IGetAutoSyncPreferenceRequest,
  IGetAutoSyncPreferenceResponse,
  IPersistAutoSyncPreferenceRequest,
  IPersistAutoSyncPreferenceResponse,
  IGetTaxRateListResponse,
  IGetMissingAccountsRequest,
  IGetMissingAccountsResponse,
  ICreateMissingAccountsRequest,
  ICreateMissingAccountsResponse,
  IGetVendorListRequest,
  IGetVendorListResponse,
  IPersistVendorRequest,
  IPersistVendorResponse,
  IGetMappingInitialStateRequest,
  IGetMappingInitialStateResponse,
  IConnectQuickBooksRequest,
  IConnectQuickBooksResponse,
  IGetInitialStateQboRequest,
  IGetInitialStateQboResponse,
  IExportPayrollQboRequest,
  IExportPayrollQboResponse,
  IGetQboAccountListRequest,
  IGetQboAccountListResponse,
  IProcessAutoSyncQboRequest,
  IProcessAutoSyncQboResponse,
  IGetQboMissingAccountsRequest,
  IGetQboMissingAccountsResponse,
  ICreateQboMissingAccountsRequest,
  ICreateQboMissingAccountsResponse,
  IDisconnectFromQboRequest,
  IDisconnectFromQboResponse,
} from './integrationsAccess.contracts';

const urlXero = (endpoint: string) => `/payrollxeroaccess/${endpoint}`;
const urlFunctions = (endpoint: string) => `/payrollfunctions/${endpoint}`;
const urlQbo = (endpoint: string) => `/payrollquickbooksaccess/${endpoint}`;

export const GetInitialStateXero = (request: IGetInitialStateXeroRequest): Promise<IGetInitialStateXeroResponse> => {
  return Post(urlXero('GetInitialState'), request);
};

export const ConnectToXero = (request: IConnectToXeroRequest) => {
  return Post(urlXero('ConnectToXero'), request);
};

export const DisconnectFromXero = (request: IDisconnectFromXeroRequest): Promise<IDisconnectFromXeroResponse> => {
  return Post(urlXero('DisconnectFromXero'), request);
};

export const GetXeroAccount = (request: IGetXeroAccountListRequest): Promise<IGetXeroAccountListResponse> => {
  return Post(urlXero('GetXeroAccountList'), request);
};

export const GetChartOfAccounts = (request: IGetChartOfAccountsRequest): Promise<IGetChartOfAccountsResponse> => {
  return Post(urlFunctions('GetChartOfAccounts'), request);
};

export const PersistChartOfAccounts = (
  request: IPersistChartOfAccountsRequest
): Promise<IPersistChartOfAccountsResponse> => {
  return Post(urlFunctions('PersistChartOfAccounts'), request);
};

export const ExportPayrollXero = (request: IExportPayrollRequest): Promise<IExportPayrollResponse> => {
  return Post(urlXero('ExportPayroll'), request);
};

export const GetTaxRateList = (request: IGetTaxRateListRequest): Promise<IGetTaxRateListResponse> => {
  return Post(urlXero('GetTaxRateList'), request);
};

export const GetAutoSyncPreference = (
  request: IGetAutoSyncPreferenceRequest
): Promise<IGetAutoSyncPreferenceResponse> => {
  return Post(urlFunctions('GetAutoSyncPreference'), request);
};

export const PersistAutoSyncPreference = (
  request: IPersistAutoSyncPreferenceRequest
): Promise<IPersistAutoSyncPreferenceResponse> => {
  return Post(urlFunctions('PersistAutoSyncPreference'), request);
};

export const GetMissingAccounts = (request: IGetMissingAccountsRequest): Promise<IGetMissingAccountsResponse> => {
  return Post(urlXero('GetMissingAccounts'), request);
};

export const CreateMissingAccounts = (
  request: ICreateMissingAccountsRequest
): Promise<ICreateMissingAccountsResponse> => {
  return Post(urlXero('CreateMissingAccounts'), request);
};

export const GetVendorList = (request: IGetVendorListRequest): Promise<IGetVendorListResponse> => {
  return Post(urlQbo('GetVendorList'), request);
};

export const PersistVendor = (request: IPersistVendorRequest): Promise<IPersistVendorResponse> => {
  return Post(urlQbo('PersistVendor'), request);
};

export const GetMappingInitialState = (
  request: IGetMappingInitialStateRequest
): Promise<IGetMappingInitialStateResponse> => {
  return Post(urlQbo('GetMappingInitialState'), request);
};

export const ConnectQuickBooks = (request: IConnectQuickBooksRequest): Promise<IConnectQuickBooksResponse> => {
  return Post(urlQbo('connectquickbooks'), request);
};

export const GetInitialStateQbo = (request: IGetInitialStateQboRequest): Promise<IGetInitialStateQboResponse> => {
  return Post(urlQbo('GetInitialState'), request);
};

export const ExportPayrollQbo = (request: IExportPayrollQboRequest): Promise<IExportPayrollQboResponse> => {
  return Post(urlQbo('ExportPayroll'), request);
};

export const GetQboAccountList = (request: IGetQboAccountListRequest): Promise<IGetQboAccountListResponse> => {
  return Post(urlQbo('GetQboAccountList'), request);
};

export const GetMissingAccountsQbo = (
  request: IGetQboMissingAccountsRequest
): Promise<IGetQboMissingAccountsResponse> => {
  return Post(urlQbo('GetMissingAccounts'), request);
};

export const CreateMissingAccountsQbo = (
  request: ICreateQboMissingAccountsRequest
): Promise<ICreateQboMissingAccountsResponse> => {
  return Post(urlQbo('CreateMissingAccounts'), request);
};

export const ProcessAutoSyncQbo = (request: IProcessAutoSyncQboRequest): Promise<IProcessAutoSyncQboResponse> => {
  return Post(urlQbo('ProcessAutoSync'), request);
};

export const DisconnectFromQbo = (request: IDisconnectFromQboRequest): Promise<IDisconnectFromQboResponse> => {
  return Post(urlQbo('Disconnect'), request);
};
