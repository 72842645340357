import React, { FC } from 'react';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { useFeatureFlag } from '@wagepoint/ui-shared-library/shared-system/functions/launch-darkly';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

interface IProps {
  firmName?: string;
  fieldToWatch: string;
  isTheOnlySubmitter?: boolean;
}

const TwoStepStatement: FC<IProps> = ({ firmName, fieldToWatch, isTheOnlySubmitter }) => {
  const { t } = useTranslation(['userAccounts']);
  const watchedValue = useWatch({ name: fieldToWatch });
  const { feature } = useFeatureFlag();
  const isTwoStepApprovalForParticularClientFeature = feature(FEATURE_FLAG_KEYS.ShowTwoStepApprovalForParticularClient);

  const showStatement =
    watchedValue == SecurityRoleTypeEnum.ReportsManager &&
    isTheOnlySubmitter &&
    isTwoStepApprovalForParticularClientFeature;

  return showStatement ? (
    <WpStatements variant="highpriority" message={t('common.2saStatement', { partnerName: firmName })} />
  ) : (
    <></>
  );
};

export default TwoStepStatement;
