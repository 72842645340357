import { SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';
import { TFunction } from 'react-i18next';

const roleListEditing = (t: TFunction) => {
  return [
    { id: SecurityRoleTypeEnum.administrator, name: t('dashboardUserAccounts:editView.payrollAdministrator') },
    { id: SecurityRoleTypeEnum.reportsManager, name: t('dashboardUserAccounts:editView.reportsAdministrator') },
  ];
};
const roleListEditingAdmin = (t: TFunction) => {
  return [
    { id: SecurityRoleTypeEnum.administrator, name: t('dashboardUserAccounts:editView.payrollAdministrator') },
    { id: SecurityRoleTypeEnum.reportsManager, name: t('dashboardUserAccounts:editView.reportsAdministrator') },
    { id: SecurityRoleTypeEnum.accountAdministrator, name: t('dashboardUserAccounts:rolesList.accountAdministrator') },
  ];
};
const roleOwnerAdminList = (t: TFunction) => {
  return [
    { id: SecurityRoleTypeEnum.administrator, name: t('dashboardUserAccounts:rolesList.payrollAdministrator') },
    { id: SecurityRoleTypeEnum.reportsManager, name: t('dashboardUserAccounts:rolesList.reportsAdministrator') },
    { id: SecurityRoleTypeEnum.accountSignatory, name: t('dashboardUserAccounts:rolesList.authorizedSignatory') },
    { id: SecurityRoleTypeEnum.accountAdministrator, name: t('dashboardUserAccounts:rolesList.accountAdministrator') },
    { id: SecurityRoleTypeEnum.accountOwner, name: t('dashboardUserAccounts:rolesList.accountOwner') },
  ];
};

const headersPermissionTable = (t: TFunction) => {
  return [
    t('userAccounts:permissionSettings.viewAndEdit'),
    t('userAccounts:permissionSettings.viewOnly'),
    t('userAccounts:permissionSettings.noAccess'),
  ];
};

const tableStatuses = {
  FULLFIELDTABLEDATA: 'resolvedtabledata',
  REJECTEDTABLEDATA: 'rejectedtabledata',
  FULLFIELDRESETTABLE: 'resettable',
  LOADING: 'loading',
  SAVEDTABLEDATA: 'savedtable',
  FULLFIELDRESET: 'resettable',
  RESETING: 'reseting',
};

const updatedStatuses = {
  NEEDUPDATE: 'updateone',
  NOTDEFINED: '',
};

const displayRole = (t: TFunction): { [x in SecurityRoleTypeEnum]?: string } => ({
  [SecurityRoleTypeEnum.accountOwner]: t('transferOwnership.accountOwner'),
  [SecurityRoleTypeEnum.accountSignatory]: t('transferOwnership.authorized'),
  [SecurityRoleTypeEnum.administrator]: t('transferOwnership.payrollAdmin'),
  [SecurityRoleTypeEnum.manager]: t('transferOwnership.manager'),
  [SecurityRoleTypeEnum.reportsManager]: t('transferOwnership.reportsAdmin'),
  [SecurityRoleTypeEnum.administratorApprover]: t('transferOwnership.adminApprover'),
  [SecurityRoleTypeEnum.administratorSubmitter]: t('transferOwnership.adminSubmitter'),
  [SecurityRoleTypeEnum.accountAdministrator]: t('transferOwnership.accountAdmin'),
  [SecurityRoleTypeEnum.employee]: t('transferOwnership.employee'),
  [SecurityRoleTypeEnum.contractor]: t('transferOwnership.contractor'),
});

export {
  headersPermissionTable,
  roleListEditing,
  roleOwnerAdminList,
  tableStatuses,
  displayRole,
  updatedStatuses,
  roleListEditingAdmin,
};
