import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IRunPayrollData } from 'services/payrolladjustment-access/payrolladjustmentAccess.contracts';

interface INegativeInit {
  userInfo: IRunPayrollData;
  adjustedInfo: any;
  adjustedTaxInfo: any;
}
const initialState: INegativeInit = {
  userInfo: {
    employee: null,
    employmentType: null,
    paydate: null,
    paygroup: null,
    prevRate: 0,
    prevRateDate: null,
    newRateDate: null,
    newRate: 0,
  },
  adjustedInfo: {
    benefits: [],
    earnings: [],
    deduction: [],
  },
  adjustedTaxInfo: {},
};

export const negativeAdjustmentSlice = createSlice({
  name: 'negativeAdjustments',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IRunPayrollData>) => {
      state.userInfo = action.payload;
    },
    setAdjustedInfo: (state, action: PayloadAction<any>) => {
      state.adjustedInfo = action.payload;
    },
    setAdjustedTaxInfo: (state, action: PayloadAction<any>) => {
      state.adjustedTaxInfo = action.payload;
    },
  },
});

export const { setUserInfo, setAdjustedInfo, setAdjustedTaxInfo } = negativeAdjustmentSlice.actions;
export const negativeAdjustmentReducer = negativeAdjustmentSlice.reducer;
export const negativeAdjustmentStates = (state: RootState) => state.negativeAdjustmentReducer;
export const selectUserInfo = (state: RootState) => state.negativeAdjustmentReducer?.userInfo;
export const selectAdjustedInfo = (state: RootState) => state.negativeAdjustmentReducer?.adjustedInfo;
