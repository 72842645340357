/**
 * Why do we need this?
 * Its to keep all the related shared services inside a file so that it easier to maintain for any changes in UI.shared-libs.
 * Relatively simple to change name / path if we decide to modify paths in shared library.
 */
import CompanyBankAccessService from '@wagepoint/ui-shared-library/shared-system/services/finance/models/access/companyBankAccess';

export * from '@wagepoint/ui-shared-library/shared-system/services/finance/models/access/companyBankAccess';

export default CompanyBankAccessService;
