import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IVendorList,
  IPersistVendorRequest,
  IPersistVendorResponse,
} from 'services/integrations-access/integrationsAccess.contracts';
import { PersistVendor } from 'services/integrations-access/integrationsAccess.service';

interface IAllStatuses {
  qboVendorList: IVendorList[];
  isQboValidating: boolean;
  qboFulfilled: boolean;
  errorQbo?: string | unknown;
}

const initialState: IAllStatuses = {
  qboVendorList: [],
  isQboValidating: false,
  qboFulfilled: false,
};

export const persistQboVendorListAction = createAsyncThunk(
  '/addons/PersistQboVendorList',
  async (request: IPersistVendorRequest) => {
    const response: IPersistVendorResponse = await PersistVendor(request);
    return response;
  }
);

export const persistIntegrationsVendorListSlice = createSlice({
  name: 'persist-integrations-vendor-list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(persistQboVendorListAction.pending, (state) => {
        state.isQboValidating = true;
        state.qboFulfilled = false;
      })
      .addCase(persistQboVendorListAction.fulfilled, (state) => {
        state.isQboValidating = false;
        state.errorQbo = '';
        state.qboFulfilled = true;
      })
      .addCase(persistQboVendorListAction.rejected, (state, action) => {
        state.isQboValidating = false;
        state.errorQbo = action.payload;
        state.qboFulfilled = false;
      });
  },
});

export const persistIntegrationsVendorListReducer = persistIntegrationsVendorListSlice.reducer;
