import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  ITaxDetail,
  IGetTaxDetailRequest,
  IGetTaxDetailResponse,
  ITaxClaimCode,
  TaxGroupTypeEnum,
} from 'services/person-tax-access/personTaxAccess.contracts';
import { getTaxDetail } from 'services/person-tax-access/personTaxAccess.service';

interface IAdditionalTaxType {
  provincial: boolean | null;
  federal: boolean | null;
}

type ITaxClaimItem = {
  [key: number]: ITaxClaimCode;
};
export interface ITaxSlice extends ITaxDetail {
  simplifiedTaxSettings: null | { [key: number]: boolean };
  isAdditionalTaxToggled: {
    federal: boolean;
    provincial: boolean;
  };
  autoPopulateData: {
    federal: { codeId: number; taxType: boolean; taxAmount: number } | null;
    provincial: { codeId: number; stateId: number; taxType: boolean; taxAmount: number } | null;
    schemeId: null | number;
  };
  claimsCodeSimplified: { federal: ITaxClaimItem; provincial: ITaxClaimItem };
  taxSettingsConfig: { [key: number]: any };
  additionalTaxesEdited: { federal: boolean | null; provincial: boolean | null };
  updatedFieldNames: Array<string>;
  tempadditionalTaxAmount: { federal: number | null; provincial: number | null };
}
const initialState: ITaxSlice = {
  taxSchemeType: {
    id: 0,
    description: '',
  },
  additionalTaxList: null,
  taxClaimCodeList: null,
  simplifiedTaxSettings: null,
  isAdditionalTaxToggled: {
    federal: false,
    provincial: false,
  },
  autoPopulateData: {
    federal: null,
    provincial: null,
    schemeId: null,
  },
  taxSettingsConfig: {},
  claimsCodeSimplified: { federal: {}, provincial: {} },
  additionalTaxesEdited: { federal: null, provincial: null },
  updatedFieldNames: [],
  tempadditionalTaxAmount: { federal: null, provincial: null },
};

export const getTaxInfo = createAsyncThunk(
  'people/onboarding/taxInfo/getTaxDetail',
  async (request: IGetTaxDetailRequest) => {
    const response: IGetTaxDetailResponse = await getTaxDetail(request);
    return response.taxDetail;
  }
);

interface ITaxDetailAction extends ITaxDetail {
  hasProvinceChanged?: boolean;
}
const formatDataToAutopopulate = (data: ITaxDetailAction) => {
  const updatedObj: any = {
    federal: {},
    provincial: {},
  };
  data?.taxClaimCodeList?.forEach((item) => {
    if (item.taxGroupType === TaxGroupTypeEnum.Provincial)
      updatedObj.provincial = {
        stateId: item.stateId,
        codeId: data.hasProvinceChanged ? '' : item.id,
      };
    else updatedObj.federal = { codeId: item.id };
  });
  data?.additionalTaxList?.forEach((item) => {
    if (item.stateId)
      updatedObj.provincial = {
        ...updatedObj.provincial,
        taxAmount: item.amount,
        taxType: item.isTaxIncrement,
      };
    else updatedObj.federal = { ...updatedObj.federal, taxAmount: item.amount, taxType: item.isTaxIncrement };
  });
  return { ...updatedObj, schemeId: data.taxSchemeType?.id };
};

const formatDataForTempAdditionalTaxes = (data: ITaxDetailAction) => {
  const tempTaxAmount: any = {
    federal: {},
    provincial: {},
  };

  data?.additionalTaxList?.forEach((item) => {
    if (item.stateId) tempTaxAmount.provincial = item.amount;
    else tempTaxAmount.federal = item.amount;
  });

  return { ...tempTaxAmount };
};

const peopleTaxInfoSlice = createSlice({
  name: 'peopletaxinfo',
  initialState,
  reducers: {
    autopopulateAction: (state, action: PayloadAction<{ value: ITaxDetailAction }>) => {
      state.autoPopulateData = formatDataToAutopopulate(action.payload.value);
      state.tempadditionalTaxAmount = formatDataForTempAdditionalTaxes(action.payload.value);
    },
    resetTaxSlice: () => initialState,
    resetToggles: (state) => {
      state.isAdditionalTaxToggled = initialState.isAdditionalTaxToggled;
    },
    resetAll: (state) => {
      state.autoPopulateData = initialState.autoPopulateData;
      state.updatedFieldNames = [];
    },
    updateTaxInfoAction: (state, action: PayloadAction<{ key: keyof ITaxSlice; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    toggleAdditionTaxAction: (state, action: PayloadAction<{ key: 'federal' | 'provincial'; value: boolean }>) => {
      state.isAdditionalTaxToggled[action.payload.key] = action.payload.value;
    },
    updateClaimConfig: (state, action: PayloadAction<{ key: 'federal' | 'provincial'; value: ITaxClaimItem }>) => {
      state.claimsCodeSimplified[action.payload.key] = action.payload.value;
    },
    updateEditableAdditionalTaxes: (
      state,
      action: PayloadAction<{ key: 'federal' | 'provincial'; value: boolean | null }>
    ) => {
      const { key, value } = action.payload;
      state.additionalTaxesEdited[key] = value;
    },
    updateEditableAllAdditionalTaxes: (state, action: PayloadAction<IAdditionalTaxType>) => {
      state.additionalTaxesEdited = action.payload;
    },
    addUpdatedField: (state, action: PayloadAction<string>) => {
      if (!state.updatedFieldNames.includes(action.payload)) {
        state.updatedFieldNames.push(action.payload);
      }
    },
    removeUpdatedField: (state, action: PayloadAction<string>) => {
      state.updatedFieldNames = state.updatedFieldNames.filter((name) => name !== action.payload);
    },

    updateAdditionalTaxeAmount: (
      state,
      action: PayloadAction<{ key: 'federal' | 'provincial'; value: number | null }>
    ) => {
      const { key, value } = action.payload;
      state.tempadditionalTaxAmount[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTaxInfo.rejected, (state: ITaxDetail) => {
      state.taxSchemeType = initialState.taxSchemeType;
      state.additionalTaxList = initialState.additionalTaxList;
      state.taxClaimCodeList = initialState.taxClaimCodeList;
    });
    builder.addCase(getTaxInfo.fulfilled, (state: ITaxDetail, action) => {
      if (!action.payload) return;
      state.taxSchemeType = action.payload.taxSchemeType;
      state.additionalTaxList = action.payload.additionalTaxList;
      state.taxClaimCodeList = action.payload.taxClaimCodeList;
    });
  },
});

export const {
  updateTaxInfoAction,
  resetTaxSlice,
  toggleAdditionTaxAction,
  autopopulateAction,
  resetToggles,
  updateClaimConfig,
  resetAll,
  updateEditableAdditionalTaxes,
  updateEditableAllAdditionalTaxes,
  addUpdatedField,
  removeUpdatedField,
  updateAdditionalTaxeAmount,
} = peopleTaxInfoSlice.actions;
export const peopleTaxInfoReducer = peopleTaxInfoSlice.reducer;
export const getAdditionTaxToggleStates = (state: RootState) => state.peopleTaxInfoReducer.isAdditionalTaxToggled;
export const getDataToAutopopulate = (state: RootState) => state.peopleTaxInfoReducer.autoPopulateData;
export const getDataToAutopopulateSchemeId = (state: RootState) =>
  state.peopleTaxInfoReducer.autoPopulateData?.schemeId;
export const getDataToAutopopulateProvincial = (state: RootState) =>
  state.peopleTaxInfoReducer.autoPopulateData?.provincial;
export const getDataToAutopopulateFederal = (state: RootState) => state.peopleTaxInfoReducer.autoPopulateData?.federal;
export const getSimplifiedClaimCodes = (state: RootState) => state.peopleTaxInfoReducer.claimsCodeSimplified;
export const getSimplifiedTaxSettings = (state: RootState) => state.peopleTaxInfoReducer.taxSettingsConfig;
export const getAdditionalTaxesEdited = (state: RootState) => state.peopleTaxInfoReducer.additionalTaxesEdited;
export const getUpdatedFieldNames = (state: RootState) => state.peopleTaxInfoReducer.updatedFieldNames;
export const getAdditionalTaxAmount = (state: RootState) => state.peopleTaxInfoReducer.tempadditionalTaxAmount;
