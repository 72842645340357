import { Post } from 'services/httpClient';
import {
  IGetInitialStateRequest,
  IGetInitialStateResponse,
  IGetTaxExemptionCodeListRequest,
  IGetTaxExemptionCodeListResponse,
  IPersistSettingsRequest,
  IPersistSettingsResponse,
  IGetTaxDetailRequest,
  IGetTaxDetailResponse,
  IGetEmployeeTaxDetailResponse,
  IGetEmployeeTaxDetailRequest,
} from './personTaxAccess.contracts';
const servicedomain = '/financepersontaxaccess/';
const GetTaxExemptionCodeListURL = servicedomain + 'getTaxExemptionCodeList';
const GetInitialStateURL = servicedomain + 'GetInitialState';
const GetTaxDetailURL = servicedomain + 'GetTaxDetail';
const PersistSettingsURL = servicedomain + 'PersistSettings';
const getEmployeeTaxDetailURL = servicedomain + 'GetEmployeTaxDetail';

export const getTaxExemptionCodeList = (
  request: IGetTaxExemptionCodeListRequest
): Promise<IGetTaxExemptionCodeListResponse> => {
  return Post(GetTaxExemptionCodeListURL, request);
};
export const getInitialStatePersonTax = (request: IGetInitialStateRequest): Promise<IGetInitialStateResponse> => {
  return Post(GetInitialStateURL, request);
};
export const getTaxDetail = (request: IGetTaxDetailRequest): Promise<IGetTaxDetailResponse> => {
  return Post(GetTaxDetailURL, request);
};
export const persistSettings = (request: IPersistSettingsRequest): Promise<IPersistSettingsResponse> => {
  return Post(PersistSettingsURL, request);
};
export const getEmployeeTaxDetail = (request: IGetEmployeeTaxDetailRequest): Promise<IGetEmployeeTaxDetailResponse> => {
  return Post(getEmployeeTaxDetailURL, request);
};
