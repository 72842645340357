import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

export interface ICompany {
  companyId: number;
  name: string;
  logoId: number;
}
export interface IParentCompany {
  parentCompany: Partial<ICompany>;
  hasParentCompany: boolean;
}

const initialState: IParentCompany = {
  parentCompany: {},
  hasParentCompany: false,
};

export const parentCompanySlice = createSlice({
  name: 'parentCompany',
  initialState,
  reducers: {
    updateParentCompany: (state, action: PayloadAction<Partial<ICompany>>) => {
      if (Object.keys(action.payload).length > 0) {
        state.hasParentCompany = true;
      } else {
        state.hasParentCompany = false;
      }
      state.parentCompany = action.payload;
    },
  },
});

export const { updateParentCompany } = parentCompanySlice.actions;
export const parentCompanyReducer = parentCompanySlice.reducer;
export const selectParentCompany = (state: RootState) => state.parentCompanyReducer;
export const selectParentCompanyDetails = (state: RootState) => state.parentCompanyReducer?.parentCompany;
export const selectHasParentCompany = (state: RootState) => state.parentCompanyReducer?.hasParentCompany;
