import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IValidationResults } from 'services/shared/validationResults.contracts';
import { persistPerson } from 'services/person-access/personAccess.service';
import { IPersistPersonRequest, IPersistPersonResponse } from 'services/person-access/personAccess.contracts';

interface IGetPerson {
  validationResults?: IValidationResults;
}

const initialState: IGetPerson = {};

export const PersistPerson = createAsyncThunk('preferences/persistPerson', async (request: IPersistPersonRequest) => {
  const response: IPersistPersonResponse = await persistPerson(request);
  return response;
});

export const persistPersonSlice = createSlice({
  name: 'preferencesSlice/persistPerson',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PersistPerson.fulfilled, (state, action) => {
      state.validationResults = action.payload.validationResults;
    });
  },
});

export const {} = persistPersonSlice.actions;
export const persistPersonReducer = persistPersonSlice.reducer;
export const select2FA = (state: RootState) => state.persistPersonReducer;
