import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { IValidationResults } from 'services/shared/validationResults.contracts';
import { CultureTypeEnum } from 'services/ui-config-access/uiConfigAccess.contracts';
import { ApplicationTypeEnum } from '../appCore/models/schema/Schema';

export interface IGetUserListRequest {
  companyId?: number;
  securityRoleId?: number;
  includeDisabled?: boolean;
  includeEmployee?: boolean;
  includeNonEmployee?: boolean;
  take: number;
  skip: number;
  filterByName?: boolean;
  filterByPersonType?: boolean;
  securityRoleType?: SecurityRoleTypeEnum;
  includeRole?: boolean;
  includeEditAccess?: boolean;
  nameContains?: string;
  includeContractor?: boolean;
  sortAscending?: boolean;
  sortColumnType?: number;
  includeEmployeeAdmin?: boolean;
}

export interface IGetUserListResponse {
  userList: Array<IUser>;
  validationResults: IValidationResults;
  totalCount: number;
}

export interface IState {
  id: number;
  name: string;
}
export interface IUser {
  id: number;
  avatar: string;
  email: string;
  lastLogin: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  isEmployee: boolean;
  departmentList: Array<IDepartment>;
  isDisabled: boolean;
  isEditable: boolean;
  personType: PersonTypeEnum;
  hasMultipleAssociation: boolean;
  securityRoleTypeList: Array<SecurityRoleTypeEnum>;
  phoneNumber: string;
  languageType: CultureTypeEnum;
  companyId: number;
  personId: number;
  twoFactorEnabled: boolean;
  countryType: string;
  passwordAssignmentDate: string;
  stateList: Array<IState>;
  profileDocumentId: number;
  isFirstTimeLogin?: boolean;
  hasChangedPassword?: boolean;
  isPayrollApprover?: boolean;
}

export interface IDepartment {
  id: number;
  name: string;
}

export interface IMaintainPersonSecurityRoleRequest {
  userId: number;
  securityRoleId?: number;
  securityRoleType?: number;
  assign?: boolean;
  unassign?: boolean;
  stateIdList?: any;
}
export interface IMaintainPersonSecurityRoleResponse {
  validationResults: IValidationResults;
}

export interface IPersistUserRequest {
  personId: number;
  emailAddress?: string;
  setDisable?: boolean;
  firstName?: string;
  lastName?: string;
  securityRoleType: number;
  password?: number;
  stateIdList?: any;
  departmentIdList?: any;
  inviteToEmployeePortal?: boolean;
}

export interface IPersistUserResponse {
  userId: number;
}

export enum PersonTypeEnum {
  NotDefined = 0,
  Employee = 1,
  Contractor = 2,
  Other = 3,
}

export enum GetUserEnum {
  UserNotFound = 0,
  ExactlyOneSwitchTrue = 1,
  KeyIsEmpty = 2,
  TenantNotFound = 3,
}

export enum ValidateEmailEnum {
  NotDefined = 0,
  UserAssociatedWithBookkeeper = 1,
  UserAndBookkeeperFirmConnected = 2,
  UserAlreadyAssociated = 3,
  EmailAlreadyExists = 4,
}

export enum GetUserListSortEnum {
  Name = 0,
  EmploymentType = 1,
  Email = 2,
  LastLoggedIn = 3,
  Department = 4,
}

export enum GetAdminListSortEnum {
  Name = 0,
  Role = 1,
}

export enum SortDirectionEnum {
  Descending = 1,
  Ascending = 2,
}

export interface IGetUserRequest {
  userId: number;
  includeSecurityRoleList?: boolean;
  includePermission?: boolean;
  applicationType?: ApplicationTypeEnum;
}

export interface IPermissionList {
  id: number;
  name: string;
  isRead: boolean;
  isReadWrite: boolean;
  isSelected: boolean;
  isSubmit: boolean;
  isApprove: boolean;
}
export interface IPermissionSubCategoryList {
  id: number;
  name: string;
  description?: string;
  permissionList: Array<IPermissionList>;
  parentSubCategoryId?: number;
  hasChildren?: boolean;
  child?: Array<IPermissionSubCategoryList>;
}

export enum PermissionCategory {
  Setup = 4,
  Payroll = 7,
  AccountingFirmPortal = 9,
  Reports = 10,
  CompanySettings = 13,
  Permission = 97 | 98,
  AlfredSettings = 146,
}

export interface IPermissionCategoryList {
  id: number;
  name: string;
  permissionSubCategoryList: Array<IPermissionSubCategoryList>;
}
export interface ISecurityRoleList {
  id: number;
  name: string;
  description: string;
  userCount: number;
  securityRoleType: number;
  hasCustomization: boolean;
  permissionCategoryList: Array<IPermissionCategoryList>;
}

export interface IGetUserResponse {
  user: IUser;
  securityRoleList: Array<ISecurityRole>;
  validationResults: IValidationResults;
}

export interface IGetSecurityRoleListRequest {
  includeUserCount: boolean;
  companyId?: number;
}

export interface IGetSecurityRoleListResponse {
  securityRoleList: Array<ISecurityRole>;
  validationResults: IValidationResults;
}

export interface IGetSecurityRoleRequest {
  companyId?: number;
  securityRoleId?: number;
  includeUserList: boolean;
  includePermissionList?: boolean;
  securityRoleType?: SecurityRoleTypeEnum;
  isPartnerRole?: boolean;
}
export interface IGetSecurityRoleResponse {
  isDepartmentAvailable: boolean;
  validationResults: IValidationResults;
  securityRole: ISecurityRole;
}

export interface IPersistSecurityRoleRequest {
  securityRoleId: number;
}

export interface IPersistSecurityRoleResponse {
  validationResults: IValidationResults;
}

export interface IAddPersonToManagerRequest {
  userId: number;
  departmentIdList: Array<number>;
  stateIdList?: Array<number>;
}

export interface IAddPersonToManagerResponse {
  validationResults: IValidationResults;
}

export interface IPersistUserPermissionRequest {
  userId: number;
  securityRoleId: number;
}

export interface IPersistUserPermissionResponse {
  validationResults: IValidationResults;
}

export interface IResetToDefaultPermissionRequest {
  securityRoleId: number;
  securityRoleType: number;
}
export interface IResetToDefaultPermissionResponse {
  validationResults: IValidationResults;
}

export interface IGetSecurityAccountListRequest {
  skip: number;
  take: number;
}

export interface IGetSecurityAccountListResponse {
  validationResults: IValidationResults;
}

export interface IPermission {
  id: number;
  name: string;
  description?: string;
  parentSubCategoryId?: number;
  isApprove: boolean;
  isRead: boolean;
  isReadWrite: boolean;
  isSelected: boolean;
  isSubmit: boolean;
}

export interface IPermissionSubCategory {
  id: number;
  name: string;
  description?: string;
  parentSubCategoryId?: number;
  permissionList: Array<IPermission>;
}

export interface IPermissionCategory {
  id: number;
  name: string;
  description?: string;
  permissionSubCategoryList: Array<IPermissionSubCategory>;
}

export interface ISecurityRole {
  id: number;
  name: string;
  description: string;
  userCount: number;
  securityRoleType: SecurityRoleTypeEnum;
  companyId: number;
  hasCustomization: boolean;
  permissionCategoryList: Array<IPermissionCategory>;
  userList: Array<IUser>;
}

export interface IValidateEmailRequest {
  emailAddress: any;
}

export interface IValidateEmailResponse {
  isAssociatedWithOtherFirm: boolean;
  companyId?: number;
  companyName?: string;
  validationResults: IValidationResults;
  firstname?: string;
  lastname?: string;
}

export interface IMaintainUserRequest {
  userId: number;
  enable?: boolean;
  disable?: boolean;
  fromEmployee?: boolean;
  fromEmployer?: boolean;
}
export interface IMaintainUserResponse {
  validationResults: IValidationResults;
}

export interface ICreateEmployeeProfileRequest {
  userId: number;
}

export interface ICreateEmployeeProfileResponse {
  validationResults: IValidationResults;
}

export interface IAdmin {
  id: number;
  securityRoleTypeList: [SecurityRoleTypeEnum];
  firstName: string;
  lastName: string;
  email: string;
}

export interface IGetAdministratorListRequest {
  includeAccountingAdmin: boolean;
  companyId?: number;
  filterByName?: boolean;
  nameContains?: string;
  includeDisabled?: boolean;
  sortAscending?: boolean;
  sortColumnType?: number;
  includeJobTitle?: boolean;
}

export interface IGetAdministratorListResponse {
  validationResults: IValidationResults;
  userList: Array<IUser>;
}
export interface IGetSignatoryListRequest {
  includeAccountingAdmin: boolean;
  companyId?: number;
  filterByName?: boolean;
  nameContains?: string;
  includeDisabled?: boolean;
  sortAscending?: boolean;
  sortColumnType?: number;
  includeJobTitle?: boolean;
}

export interface IGetSignatoryListResponse {
  validationResults: IValidationResults;
  userList: Array<IUser>;
}

export interface ITransferOwnershipRequest {
  userId: number;
  newOwnerUserId: number;
  disable: boolean;
  securityRoleType?: any;
  departmentIdList?: any;
  stateIdList?: any;
}

export interface ITransferOwnershipResponse {
  validationResults: IValidationResults;
}

export interface IRevokeAccessRequest {
  companyId?: number;
}

export interface IRevokeAccessResponse {
  validationResults: IValidationResults;
}

export interface IConfigureRoleRequest {
  companyId?: number;
  securityRoleType?: number;
}
export interface IConfigureRoleResponse {
  validationResults?: any;
}

export interface IStateList {
  id: number;
  name: string;
}

export interface IPersonSummaryList {
  userId: number;
  email: string;
  lastLogin: string;
  firstName: string;
  lastName: string;
  personType: number;
  phoneNumber: string;
  companyId: number;
  personId: number;
  countryType: string;
  stateList: Array<IStateList>;
  fullName?: string;
  isInvitedToEmployeePortal?: boolean;
}

export interface IGetEmployeeListResponse {
  personSummaryList: Array<IPersonSummaryList>;
  validationResults?: any;
}

export interface IGetEmployeeListRequest {
  companyId?: number;
  filterByName?: boolean;
  nameContains?: string;
}

export interface IGetCloseCompanyAccountRequest {
  companyId?: number;
  companyStatusType: number;
}

export interface IGetCloseCompanyAccountResponse {
  validationResults?: any;
}
export interface IChangeEmailRequest {
  oldEmail: string;
  newEmail: string;
  updateSecondaryEmail: boolean;
}

export interface IChangeEmailResponse {
  validationResults: IValidationResults;
}

export interface IChangePasswordRequest {
  username: string;
  newPassword: string;
  existingPassword: string;
}

export interface IChangePasswordResponse {
  validationResults: IValidationResults;
}
export interface IMaintain2FARequest {
  enable: boolean;
  phoneNumber?: string;
  userId: number;
}

export interface IMaintain2FAResponse {
  codeExpirationMinutes: number;
  validationResults: IValidationResults;
}

export interface IVerifySmsCodeRequest {
  phoneNumber: string;
  code: string;
  rememberDevice?: boolean;
  visitorId?: string;
}

export enum VerifySmsCodeEnum {
  EmptyPhone = 0,
  EmptyCode = 1,
  IncorrectCode = 2,
  ExpiredCode = 3,
}

export interface IVerifySmsCodeResponse {
  validationResults: IValidationResults<VerifySmsCodeEnum>;
}

export interface IStartTabs {
  key: number;
  value: string;
  isDefault?: boolean;
}
