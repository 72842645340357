import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { useQueryFnWithLoader } from 'util/customHooks/useQueryFnWithLoader';

import { useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { selectClientSecurityRoleType } from 'redux/slice/accountingDashboardSlices/clientDirectorySlice';

import partnerAccess, { GetUserListSortEnum } from 'services/appCore/models/access/partnerAccess';
import { SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';
import companyLinkAccess, {
  GetUserListSortEnum as LinkGetUserListSortEnum,
} from 'services/finance/models/access/companyLinkAccess';

import useCheckSecurityRoleType from 'pages/accounting-dashboard/commonHooks/useCheckSecurityRoleType';
import { createQueryKeys } from 'util/queryKeyFactory';

export const administratorsQueriesKeys = createQueryKeys(
  ['accounting-dashboard', 'client-directory', 'administrators'],
  {
    getAdminsForGrid: () => ({
      key: [],
      sub: {
        getAdminsForGridWithParams: (params: GetAdminsForGridParams) => ({
          key: [params],
        }),
      },
    }),
    getAdminsToAssign: (params: GetAdminsToAssignParams) => ({
      key: [params],
    }),
  }
);

const TAKE = 30;

type GetAdminsForGridParams = {
  sortAscending: boolean;
  securityRoleType: SecurityRoleTypeEnum;
  text: string;
  sortColumnType: LinkGetUserListSortEnum;
  clientId?: number;
  partnerId?: number;
};

export const useGetAdminsForGridQuery = (params: GetAdminsForGridParams) => {
  const withLoaderAndError = useQueryFnWithLoader();

  return useInfiniteQuery({
    queryKey: administratorsQueriesKeys.getAdminsForGrid().sub.getAdminsForGridWithParams(params).key,
    queryFn: async ({ pageParam }) => {
      try {
        const { userList } = await withLoaderAndError(companyLinkAccess.getUserList, {
          clientId: params?.clientId ?? 0,
          partnerId: params?.partnerId ?? 0,
          securityRoleType: params.securityRoleType,
          filterByEmail: !!params.text,
          filterByName: !!params.text,
          emailContains: params.text,
          nameContains: params.text,
          sortAscending: params.sortAscending,
          sortColumnType: params.sortColumnType,
          includeClientCount: true,
          skip: pageParam ? pageParam * TAKE : 0,
          take: TAKE,
        });
        return userList ?? [];
      } catch (e) {
        throw e;
      }
    },
    retry: false,
    enabled: !!params?.clientId,
  });
};

type GetAdminsToAssignParams = Pick<GetAdminsForGridParams, 'securityRoleType'>;

export const useGetAdminsToAssign = () => {
  const withLoadingAndError = useQueryFnWithLoader(false);
  const companyId = useAppSelector(selectCompanyId);
  const clientSecurityRoleType = useAppSelector(selectClientSecurityRoleType);

  const { isAccountOwner, isAccountAdministrator } = useCheckSecurityRoleType();

  const getSecurityRoleTypeIdList = () => {
    if (isAccountOwner || isAccountAdministrator) {
      const isReportAdminNeeded = clientSecurityRoleType === SecurityRoleTypeEnum.reportsManager;
      const roles = [
        SecurityRoleTypeEnum.accountOwner,
        SecurityRoleTypeEnum.accountAdministrator,
        SecurityRoleTypeEnum.administrator,
      ];
      isReportAdminNeeded && roles.push(SecurityRoleTypeEnum.reportsManager);
      return roles;
    }
    return [];
  };

  return useQuery({
    queryKey: administratorsQueriesKeys.getAdminsToAssign({ securityRoleType: clientSecurityRoleType }).key,
    queryFn: async () => {
      try {
        const { userList } = await withLoadingAndError(partnerAccess.getUserList, {
          companyId,
          emailContains: '',
          filterByEmail: false,
          filterByName: false,
          includeClientCount: false,
          includeDisabled: false,
          includeEditAccess: false,
          includeRole: false,
          nameContains: '',
          securityRoleId: 0,
          securityRoleTypeIdList: getSecurityRoleTypeIdList(),
          sortAscending: false,
          sortColumnType: GetUserListSortEnum.name,
          skip: 0,
          take: 50,
        });
        return userList ?? [];
      } catch (e) {
        throw e;
      }
    },
    enabled: !!companyId && clientSecurityRoleType !== SecurityRoleTypeEnum.notDefined,
    retry: false,
  });
};
