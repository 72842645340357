import { useAppDispatch } from 'redux/hooks/hook';
import { updatePermissionConfig } from 'redux/slice/configSlice';
import { GetConfiguration } from 'services/ui-config-access/uiConfigAccess.service';
import { getApplicationType } from 'util/utility';

export const useReloadConfig = () => {
  const dispatch = useAppDispatch();
  const applicationType = getApplicationType();

  const reloadPermissions = async () => {
    const response = await GetConfiguration({
      applicationType: applicationType,
    });

    if (response && response.validationResults?.length > 0) return;

    /*
    Logic - to create an object - which contains url as the key and value as an object, with isViewOnly key and value.
    This structure will be updated in redux store to avoid mappings each time.
    */

    let menuItemsObject = {};

    const generateMenuList = (list: any) => {
      if (list && list.url) menuItemsObject = { [list?.url]: { isViewOnly: list?.isViewOnly }, ...menuItemsObject };
      list.map((submenuItems: any) => {
        menuItemsObject = {
          [submenuItems?.url]: {
            isViewOnly: submenuItems?.isViewOnly,
            isDisabled: submenuItems?.isDisabled,
            isVisible: submenuItems?.isVisible,
          },
          ...menuItemsObject,
        };
        if (submenuItems.menuItemList && submenuItems.menuItemList.length > 0)
          generateMenuList(submenuItems.menuItemList);
        if (submenuItems.subMenuList && submenuItems.subMenuList.length > 0) generateMenuList(submenuItems.subMenuList);
      });
    };

    {
      generateMenuList(response.menuList);
    }

    dispatch(updatePermissionConfig(menuItemsObject));
  };

  return { reloadPermissions };
};
