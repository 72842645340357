import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { IValidationResults } from 'services/shared/validationResults.contracts';

export enum OnboardingStatusTypeEnum {
  notDefined = 0,
  inProgress = 1,
  discoveringWagePoint = 2,
  exitedOnboarding = 3,
  completed = 4,
}

export enum CompanyOnboardingStepEnum {
  notDefined = 0,
  companyDetail = 1,
  payGroup = 2,
  people = 3,
  sourceDeductions = 4,
  bankingInformation = 5,
  accountVerification = 6,
  employerTaxes = 7,
  roe = 8,
  // TODO: Sync with backend interface once it will be done
  authorizedSignatory = 9,
}

export enum CompanyOnboardingStepStatusTypeEnum {
  notDefined = 0,
  pending = 1,
  partiallyCompleted = 2,
  completed = 3,
}

export enum CompanyOnboardingOfCompanyTypeEnum {
  notDefined = 0,
  partnerIndependent = 1,
  wagepoint = 2,
  customer = 4,
  vendorBusinessEntity = 8,
  vendorIndividual = 16,
  partnerRegional = 32,
  partnerNational = 64,
}

export interface IGetInitialStateRequest {
  companyId: number;
}

export interface IAccountantInviteDetails {
  id: number;
  inviterName: string | undefined;
  requestedRoleType: SecurityRoleTypeEnum;
  partnerCompanyId?: number;
  isPartnerHandling?: boolean;
  isNewClientInvitation?: boolean;
}

export interface IGetInitialStateResponse {
  isOnboardingCompleted: boolean;
  accountantInviteDetails?: IAccountantInviteDetails;
  validationResults?: IValidationResults;
}

export interface IPersistOnboardingStatusRequest {
  companyId: number;
  onboardingStatus: OnboardingStatusTypeEnum;
}

export interface IPersistOnboardingStatusResponse {
  validationResults: IValidationResults;
}

export interface IPersistOnboardingStepRequest {
  companyId: number;
  personId?: number;
  onbooardingStep: CompanyOnboardingStepEnum;
}

export interface IPersistOnboardingStepResponse {
  validationResults: IValidationResults;
}

export interface IOnboardingStepStatusDetails {
  id: number;
  name: string;
  displayOrder: number;
  isVisible: boolean;
  companyOnboardingStepStatusType: CompanyOnboardingStepStatusTypeEnum;
}

export interface IGetOnboardingStepStatusListRequest {
  companyId: number;
  getOnlyVisibleSteps: boolean;
  stepIdList: Array<number>;
}

export interface IGetOnboardingStepStatusListResponse {
  onboardingStepStatusDetailsList: IOnboardingStepStatusDetails[];
  validationResults: IValidationResults;
}

export interface ICompleteOnboardingRequest {
  companyId: number;
}

export interface ICompleteOnboardingResponse {
  validationResults: IValidationResults;
}

export interface IPersistOnboardingStepStatusRequest {
  isSkipped: boolean;
  onbooardingStep: CompanyOnboardingStepEnum;
  companyId: number;
  companyOnboardingStepStatusType: CompanyOnboardingStepStatusTypeEnum;
}

export interface IPersistOnboardingStepStatusResponse {
  validationResults: IValidationResults;
}
