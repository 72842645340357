import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IPersistNewRelevantStateVacationSetupRequest,
  IVacation,
} from 'services/add-employment-province-access/addPoEAccess.contracts';
import { VacationCalculationTypeEnum } from 'services/appCore/models/schema/Schema';
import {
  IGetPersonJobListRequest,
  IGetPersonJobListResponse,
  IPersonJobDetail,
} from 'services/job-access/jobAccess.contracts';
import { GetPersonJobList } from 'services/job-access/jobAccess.service';

interface IPOEJobDetails {
  id?: number;
  jobName: string;
  workLocationId: string;
  workLocationStateId: string;
  workLocationStateName?: string;
  workersCompensationPercent?: string;
  workersCompensationId?: string;
  vacationCalculationType: VacationCalculationTypeEnum;
  vacationTitle?: string;
  payPercent?: string;
  yearToDateBalance?: string;
}

interface IPoEJobDetailsSlice {
  vacationCalType: any;
  location: any;
  payPercentValue: number;
  vacation?: IVacation;
  personJobDetailList: Array<IPersonJobDetail>;
  jobDetailsRequest: IPersistNewRelevantStateVacationSetupRequest | null;
  jobDetails: Array<IPOEJobDetails>;
}

const initialState: IPoEJobDetailsSlice = {
  vacationCalType: null,
  location: null,
  payPercentValue: 0,
  vacation: {},
  personJobDetailList: [],
  jobDetailsRequest: null,
  jobDetails: [],
};

export const getPersonJobList = createAsyncThunk(
  'onboard/myinfo/getPersonJobList',
  async (request: IGetPersonJobListRequest) => {
    // TODO: check this and remove if not needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { buildIDBBlank = false, ...baseRequest } = request; /* Just seperating buildIDBBlank from the request */
    const response: IGetPersonJobListResponse = await GetPersonJobList(baseRequest);
    return response;
  }
);

export const poeJobDetailsSlice = createSlice({
  name: 'poeJobDetailsSlice',
  initialState,
  reducers: {
    updatePOEDetailsAction: (state, action: PayloadAction<{ key: keyof IPoEJobDetailsSlice; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    updateJobDetails: (state, action: PayloadAction<Array<IPOEJobDetails>>) => {
      state.jobDetails = action.payload;
    },
    resetJobDetailsAction: (state) => {
      state.vacationCalType = null;
      state.location = null;
      state.payPercentValue = 0;
      state.jobDetailsRequest = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersonJobList.fulfilled, (state: IPoEJobDetailsSlice, action) => {
      state.personJobDetailList = action.payload.personJobDetailList || [];
      state.vacation = {
        ...action.payload.vacation,
      };
    });
  },
});

export const { updatePOEDetailsAction, updateJobDetails, resetJobDetailsAction } = poeJobDetailsSlice.actions;
export const poeJobDetailsReducer = poeJobDetailsSlice.reducer;
export const getPoEJobDetailsStates = (state: RootState) => state.poeJobDetailsReducer;
export const selectPoeJobDetails = (state: RootState) => state.poeJobDetailsReducer?.jobDetails;
export const selectJobDetailsRequest = (state: RootState) => state.poeJobDetailsReducer?.jobDetailsRequest;
