export enum RegexEnum {
  NotDefined = 0,
  Phone = 1,
  InternationalPhone = 18,
  Email = 2,
  AlphaFrench = 3,
  CAPostalCode = 4,
  USPostalCode = 5,
  AlphaEnglish = 6,
  Address = 7,
  AlphaNumEnglish = 8,
  AlphaNumFrench = 9,
  AlphaRegex = 10,
  NumRegex = 11,
  StandardAlphaRegex = 12,
  LegalCompanyNameRegex = 13,
  CraNumberRegex = 14,
  FeiNumberRegex = 15,
  NameRegex = 16,
  DecimalNumberRegex = 17,
  SinRegex = 9,
}

export const CommonRegexOperations = {
  hyphen_spaces: /-|\s/g,
  alphaNumeric: /[a-z\d]+$/i,
  numberics: /\d+/g,
};

export const CompanyRegex = {
  Company_Address: /^(?!.*[—’]).*$/,
};
