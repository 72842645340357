import { makeStyles } from '@material-ui/core/styles';

export const PermissionSettingsStyles = makeStyles((theme) => ({
  container: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingBottom: '50px',
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    border: `1px solid ${theme.palette.common.lightbrown}`,
    borderRadius: 6,
  },
  depTypoContainer: {
    display: 'flex',
  },
  gridContainer: {
    '& .MuiGrid-container': {
      height: '100%',
    },
    '& .svg-inline--fa': {
      marginLeft: theme.spacing(1),
    },
    '& tr td:empty': {
      visibility: 'hidden',
      height: 0,
      borderBottom: 'none !important',
    },
    '​& td:not(:empty) ~ td:empty': {
      visibility: 'visible',
      height: '100%',
      borderBottom: 'none !important',
    },
    '& .MuiGrid-item': {
      paddingRight: '0px !important',
      paddingBottom: '0px !important',
      borderRight: `0.1rem solid ${theme.palette.common.lightbrown}`,
      position: 'relative',
    },
    '& .MuiRadio-root:not(.Mui-checked)': {
      color: theme.palette.common.lightbrown,
    },
    '& .MuiGrid-item:nth-child(3)': {
      borderRight: 'none',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .wpg-child-row-with-toogler ': {
      width: '100%',
      height: '100%',
    },
    '& .MuiTableContainer-root': {
      minHeight: '0px !important',
      overflow: 'hidden !important',
      maxHeight: 'inherit !important',
    },
    '& .MuiTableRow-root': {
      borderBottom: `0.1rem solid ${theme.palette.common.lightbrown}`,
      verticalAlign: 'bottom',
    },
    '& tr:last-child': {
      borderBottom: 'none',
    },
    '& td': {
      borderRight: `0.1rem solid ${theme.palette.common.lightbrown}`,
      height: 'inherit',
    },
    '& td:last-child': {
      borderRight: 'none',
      /* Required in future - NA */
      // '& .wpg-child-row-with-toogler': {
      //   position: 'absolute',
      //   right: '0',
      //   top: '0',
      // },
    },
    '& tr td:first-child': {
      border: 'none !important',
      position: 'relative',
    },
    '& tr:last-child svg': {
      border: 'none',
      top: '0px !important',
    },
    '& th .MuiGrid-container': {
      bottom: 0,
      height: '100%',
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.highlight,
    },
    '& .MuiTableHead-root': {
      height: '55px',
      // TODO: use theme.palette
      // eslint-disable-next-line no-restricted-syntax
      backgroundColor: 'rgba(223, 216, 216, 0.04)',
      borderTop: 'none !important',
    },
    '& .MuiTableCell-root .MuiTableCell-body .wpg-child-row-toggler': {
      display: 'none',
      padding: '0 !important',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(3)': {
      position: 'relative',
      width: '100%',
    },
    '& .MuiTable-stickyHeader': {
      borderCollapse: 'collapse',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '0px !important',
      height: '58px',
      marginLeft: '0px !important',
    },
    '& .MuiList-padding': {
      padding: '0 !important',
    },
    '& .MuiListItem-gutters': {
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      paddingLeft: '11px !important',
    },
    '& .MuiTableBody-root .MuiTableCell-body': {
      padding: '0 !important',
      verticalAlign: 'middle !important',
    },
    '& .wp-grid .wpg-child-row-with-toogler.iconstart': {
      direction: 'inherit !important',
    },
    '& .wp-grid .MuiTableBody-root .MuiTableCell-body:nth-child(2)': {
      width: '55%',
      position: 'relative',
    },

    '& .wp-grid .MuiTableBody-root .MuiTableCell-body': {
      position: 'relative',
    },
    '& .MuiFormGroup-root': {
      height: '100%',
    },
    '& .MuiTableCell-stickyHeader': {
      zIndex: 0,
    },
  },
  nestedGrid: {
    '& td:last-child': {
      '& .wpg-child-row-with-toogler': {
        position: 'inherit',
      },
    },
    '& .MuiTableHead-root': {
      visibility: 'collapse',
    },
    '& .MuiTableContainer-root': {
      minHeight: '0px !important',
    },
    '& .MuiTable-root': {
      borderCollapse: 'inherit',
    },
    '& ul': {
      display: 'list-item',
      listStyleType: 'disc',
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      marginLeft: '20px',
    },
    '& .MuiTableCell-body:nth-child(2)': {
      width: '54.9% !important',
    },
  },
  secondaryFooterContainer: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingLeft: '24px',
  },
  primaryFooterContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },

  editIcon: {
    textAlign: 'end',
    cursor: 'pointer',
  },
  resetIcon: {
    textAlign: 'end',
    width: '115px',
  },
  resetdefaultIconButton: {
    // TODO: replace value with theme.spacing
    // eslint-disable-next-line no-restricted-syntax
    paddingTop: '16px !important',
  },
  editIconButton: {
    height: '100%',
    paddingRight: '0px !important',
    cursor: 'pointer',
  },
  headerContainer: {
    '& .MuiGrid-item': {
      //   paddingRight: '0px !important',
      padding: `0 0 ${theme.spacing(1)}px 0`,
    },
    '& .MuiButton-label': {
      position: 'absolute',
      bottom: '8px',
      display: 'contents',
    },
  },
  icon: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  department: {
    color: theme.palette.primary.main,
  },
  boxContainer: {
    height: '100%',
    display: 'inline-flex',
  },
  assign: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  assignTypo: {
    paddingRight: theme.spacing(1),
  },
  p6: {
    ...theme.tag.p3,
    paddingLeft: '0px !important',
  },
  b2: {
    ...theme.typography.body2,
  },
}));
