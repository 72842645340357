import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
export enum SnackBarTypeEnum {
  error = 1,
  warning = 2,
  success = 3,
  informational = 4,
}
interface ISnackbarInit {
  openSnack?: boolean;
  snackType?: any;
  message: string;
}

const initialState: ISnackbarInit = {
  openSnack: false,
  snackType: SnackBarTypeEnum.informational,
  message: 'Test',
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    success: (state, action: PayloadAction<ISnackbarInit>) => {
      if (action.payload.message) {
        state.openSnack = true;
        state.snackType = SnackBarTypeEnum.success;
        state.message = action.payload.message;
      }
    },
    error: (state, action: PayloadAction<ISnackbarInit>) => {
      if (action.payload.message) {
        state.openSnack = true;
        state.snackType = SnackBarTypeEnum.error;
        state.message = action.payload.message;
      }
    },
    warning: (state, action: PayloadAction<ISnackbarInit>) => {
      if (action.payload.message) {
        state.openSnack = true;
        state.snackType = SnackBarTypeEnum.warning;
        state.message = action.payload.message;
      }
    },
    informational: (state, action: PayloadAction<ISnackbarInit>) => {
      if (action.payload.message) {
        state.openSnack = true;
        state.snackType = SnackBarTypeEnum.informational;
        state.message = action.payload.message;
      }
    },
    clear: (state) => {
      state.openSnack = false;
    },
  },
});

export const { success, clear, error, informational, warning } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
export const setSnackBarOpenSnack = (state: RootState) => state.snackbarReducer.openSnack;
export const setSnackBarSnackType = (state: RootState) => state.snackbarReducer.snackType;
export const setSnackBarMessage = (state: RootState) => state.snackbarReducer.message;
