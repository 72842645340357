import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
interface IUserAccountsInit {
  roles?: any;
  isEdit?: boolean;
  assignedRole?: number;
  isFromTransfer?: boolean;
  adminIsViewDisabled?: boolean;
}

const initialState: IUserAccountsInit = {
  roles: {},
  isEdit: false,
  assignedRole: SecurityRoleTypeEnum.NotDefined,
  isFromTransfer: false,
  adminIsViewDisabled: false,
};

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    selectedRoleList: (state, action: PayloadAction<IUserAccountsInit>) => {
      state.roles = action.payload.roles;
    },
    setEditState: (state, action: PayloadAction<IUserAccountsInit>) => {
      state.isEdit = action.payload.isEdit;
    },
    setTransferState: (state, action: PayloadAction<IUserAccountsInit>) => {
      state.isFromTransfer = action.payload.isFromTransfer;
    },
    selectedRoleForAccountingFirm: (state, action: PayloadAction<IUserAccountsInit>) => {
      state.assignedRole = action.payload.assignedRole;
    },
    setIsViewDisabled: (state, action: { payload: { value: boolean } }) => {
      state.adminIsViewDisabled = action.payload.value;
    },
  },
});

export const { selectedRoleList, setEditState, setIsViewDisabled, selectedRoleForAccountingFirm, setTransferState } =
  roleSlice.actions;
export const rolesReducer = roleSlice.reducer;
export const selectRolesList = (state: RootState) => state.rolesReducer;
export const selectRolesListRoles = (state: RootState) => state.rolesReducer?.roles;
export const getEditState = (state: RootState) => state.rolesReducer;
export const selectEditStateIsEdit = (state: RootState) => state.rolesReducer?.isEdit;
export const getSelectedRoleForAccounting = (state: RootState) => state.rolesReducer;
export const selectAccountingAssignedRole = (state: RootState) => state.rolesReducer?.assignedRole;
export const getTransferState = (state: RootState) => state.rolesReducer;
export const selectIsFromTransfer = (state: RootState) => state.rolesReducer?.isFromTransfer;

export const selectedIsViewDisabled = createSelector(selectRolesList, ({ adminIsViewDisabled }) => adminIsViewDisabled);
