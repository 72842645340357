import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import WpTypography from '@wagepoint/ui-toolkit/components/wp-typography';
import default_avatar from 'assets/images/default_avatar.svg';
import { CardStyles } from './cardsStyles';
import { useTranslation } from 'react-i18next';
import MenuContainer from '../components/common/menu';
import DisplayRoles from './common/displayRoles';
import { ManageViewOfAccountingFirm } from '../../index';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { setFirmView } from 'redux/slice/accountingFirmSlice';
import { selectedRoleForAccountingFirm } from 'redux/slice/userAccountsSlice';
import companyAccess, { CompanySummaryInterface } from 'services/finance/models/access/companyAccess';
import { formatDate } from 'util/utility';
import { useLoaders } from 'redux/hooks/useLoaders';

interface IProps {
  item: CompanySummaryInterface;
}

const AcceptedCard: React.FC<IProps> = ({ item }) => {
  const classes = CardStyles();
  const { t } = useTranslation(['userAccounts']);
  const companyId = useAppSelector(selectCompanyId);
  const dispatch = useAppDispatch();
  const { showLoader, hideLoader } = useLoaders();

  const firmPermissionsView = async () => {
    showLoader();
    const { companySummary } = await companyAccess.getPartnerCompany({
      partnerId: item.partnerId ?? 0,
      clientId: companyId,
    });
    hideLoader();

    dispatch(
      selectedRoleForAccountingFirm({
        assignedRole: companySummary.securityRoleTypeId,
      })
    );

    dispatch(
      setFirmView({
        firmView: ManageViewOfAccountingFirm.FirmPermission,
        isEdit: true,
        companyId: companySummary.partnerId ?? companySummary.invitationId,
        name: companySummary.name,
        from: ManageViewOfAccountingFirm.FirmInviteSent,
      })
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <div className={classes.cardHeader}>
            <img src={default_avatar} alt={t('firmInviteSent.default')} className={classes.defaultImage} />
            <WpTypography variant="h3">{item.name}</WpTypography>
          </div>
        }
        action={<MenuContainer item={item} from viewFirm={firmPermissionsView} />}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <WpTypography variant="p1Bold" display="inline">
                {t('firmInviteAccepted.owner')}
              </WpTypography>
              <WpTypography display="inline">{item.ownerName}</WpTypography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <WpTypography variant="p1Bold" display="inline">
                {t('firmInviteAccepted.accepted')}
              </WpTypography>
              <WpTypography display="inline">{formatDate(item.dateAccepted)}</WpTypography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <WpTypography variant="p1Bold" display="inline">
                {t('firmInviteAccepted.type')}
              </WpTypography>
              <WpTypography display="inline"> {t('firmInviteAccepted.accountingFirm')}</WpTypography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <DisplayRoles item={item} />
          </Grid>
          <Grid item xs={12}>
            <div>
              <WpTypography variant="p1Bold" display="inline">
                {t('firmInviteAccepted.peopleCount')}
              </WpTypography>
              <WpTypography display="inline"> {item.assignedAdminCount}</WpTypography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AcceptedCard;
