import { JobStatusTypeEnum } from './../payroll-person-onboard-access/payrollPersonOnboardAccess.contracts';
import { IValidationResults } from 'services/shared/validationResults.contracts';

export enum ValidationScopeTypeEnum {
  NotDefined,
  Partial,
  Complete,
}

export enum ValidationSeverityEnum {
  NotDefined = 0,
  Passed,
  Information,
  Warning,
  Error,
}

export enum JobPayTypeEnum {
  notDefined = 0,
  notSpecified,
  hourly,
  salary,
  commission,
}
export enum HoursTypeEnum {
  notDefined = 0,
  notSpecified,
  fixed,
  vairable,
}
export enum EmploymentTypeEnum {
  notDefined = 0,
  fullTime,
  partTime,
  commission,
}
export enum VacationCalculationTypeEnum {
  notDefined = 0,
  notSpecified,
  accrue,
  payOut,
  notApplicable,
}
export enum JobDefinitionStatusTypeEnum {
  notDefined = 0,
  active,
  disabled,
}
export enum DepartmentStatusTypeEnum {
  notDefined = 0,
  active,
  disabled,
}

export interface IUpcomingCompensation {
  id?: number;
  payType?: JobPayTypeEnum;
  hoursType?: HoursTypeEnum;
  employmentType?: EmploymentTypeEnum;
  hourlyPayRate?: number;
  annualSalary?: number;
  payPeriodHours?: number;
  isMidCyclePayChange?: boolean;
  midCyclePayDays?: number;
  midCycleHoursPerDay?: number;
  midCycleOldRateDays?: number;
  oldMidCycleHoursPerDay?: number;
  proratedHours?: number;
  oldProratedHours?: number;
  effectiveFromDate?: string;
}

export enum PersistJobRequestEnum {
  jobCannotBeNull = 0,
  duplicateNameFound = 1,
}

export enum PersistDepartmentRequestEnum {
  jobCannotBeNull = 0,
  duplicateNameFound = 1,
}
export interface IPersonJobDetail {
  personId?: number;
  locationId?: number;
  residenceStateId?: number;
  payType?: JobPayTypeEnum;
  hoursType?: HoursTypeEnum;
  hourlyPayRate?: number;
  annualSalary?: number;
  payPeriodHours?: number;
  lastWorkingDate?: string;
  returnDate?: string;
  hireDate?: string;
  payGroupId?: number;
  isPrimaryJob?: boolean;
  isMidCycleHire?: boolean;
  midCyclePayDays?: number;
  midCycleHoursPerDay?: number;
  proratedHours?: number;
  employmentType?: EmploymentTypeEnum;
  isPayGroupAutoRun?: boolean;
  id?: number;
  job?: IJob;
  jobId?: number;
  jobStatusType?: JobStatusTypeEnum;
  department?: IDepartment;
  getPersonJobListResponse?: IGetPersonJobListResponse;
  workersCompensation?: IWorkersCompensation;
  workFromHomeEnabled?: boolean;
  currentCompensationId?: number;
  upcomingCompensation?: IUpcomingCompensation;
  hasCompensationChanges?: boolean;
  vacation?: IVacation;
}
export interface IWorkersCompensation {
  id: number;
  rate?: number;
}
export interface IVacation {
  vacationCalculationType?: VacationCalculationTypeEnum;
  stateId?: number;
  payPercent?: number;
  id?: number;
  personId?: number;
  vacationEarned?: number;
  vacationUsed?: number;
  vacationBalance?: number;
  effectiveFromDate?: string;
  effectiveToDate?: string;
  minPayPercent?: number;
}
export interface IDepartment {
  id?: number;
  name: string;
  assignedPeopleCount?: number;
  glExpenseNumber?: string;
  departmentStatusType?: DepartmentStatusTypeEnum;
}
export interface IJob {
  id?: number;
  name: string;
  assignedPeopleCount?: number;
  jobDefinitionStatusType?: JobDefinitionStatusTypeEnum;
}
/* Manage Job and Department START */

export interface IManageJobStatusRequest {
  companyId: number;
  jobId: number;
  jobDefinitionStatusType: JobDefinitionStatusTypeEnum;
}

export interface IManageJobStatusResponse {
  validationResults: IValidationResults;
}

export interface IManageDepartmentStatusRequest {
  companyId: number;
  departmentId: number;
  departmentStatusType: DepartmentStatusTypeEnum;
}

export interface IManageDepartmentStatusResponse {
  validationResults: IValidationResults;
}
/* Manage Job and Department END */
export interface IGetPersonJobListResponse {
  personJobDetailList: Array<IPersonJobDetail>;
  vacation: IVacation;
  validationResults: IValidationResults;
}
export interface IGetPersonJobListRequest {
  personId: number;
  companyId?: number;
  IncludeVactionDetail?: boolean;
  skip: number;
  take: number;
  getByPersonIdList?: boolean;
  personIdList?: any[];
  buildIDBBlank?: boolean;
}
export interface IPersistPersonJobRequest {
  validationScopeType?: ValidationScopeTypeEnum;
  companyId: number;
  personJobDetailList: Array<IPersonJobDetail>;
  vacation?: IVacation;
}
export interface IPersistPersonJobResponse {
  personJobDetailList: Array<IPersonJobDetail>;
  validationResults: IValidationResults;
}

export interface ICreateDefaultPersonJobRequest {
  companyId: number;
  personJobDetail: IPersonJobDetail;
  vacation?: IVacation;
}
export interface ICreateDefaultPersonJobResponse {
  validationResults: IValidationResults;
}
export interface IPersistJobRequest {
  companyId: number;
  job: IJob;
  personIdList?: number[];
}
export interface IPersistJobResponse {
  id: number;
  validationResults: IValidationResults;
}
export interface IPersistPeopleJobAssignmentRequest {
  companyId: number;
  jobId: number;
  personIdList: number[];
  successmsg?: any[];
}
export interface IPersistPeopleJobAssignmentResponse {
  validationResults: IValidationResults;
}
export interface ManageDepartmentStatusRequestInterface {
  companyId: number;
  departmentId: number;
  departmentStatusType: DepartmentStatusTypeEnum;
}
export interface IDepartmentJobCommon {
  getByPersonId?: boolean;
  personId?: number;
  getByIdList?: boolean;
  idList?: number;
  getByCompanyId?: boolean;
  companyId?: number;
}
export interface IGetJobListRequest extends IDepartmentJobCommon {
  getByJobStatusType?: boolean;
  jobStatusTypeList?: JobDefinitionStatusTypeEnum[];
  skip?: number;
  take?: number;
}
export interface IGetJobListResponse {
  jobList: Array<IJob>;
  validationResults: IValidationResults;
}
export interface IGetJobSummaryListRequest {
  companyId: number;
  skip: number;
  take: number;
  searchByJobTitle: string;
  includeTotalCount: boolean;
  includePeoplePerJobCount: boolean;
  getByJobDefinitionStatusType?: boolean;
  jobDefinitionStatusType?: JobDefinitionStatusTypeEnum;
}

export interface IGetJobSummaryListResponse {
  totalCount?: number;
  jobList: Array<IJob>;
  validationResults: IValidationResults;
}

export interface IGetDepartmentSummaryListRequest {
  companyId: number;
  take: number;
  skip: number;
  searchByDepartmentName: string;
  includeTotalCount: boolean;
  includePeoplePerDepartmentCount: boolean;
  getByDepartmentStatusType?: boolean;
  departmentStatusType?: DepartmentStatusTypeEnum;
}

export interface IGetDepartmentSummaryListResponse {
  totalCount?: number;
  departmentList: Array<IDepartment>;
  validationResults: IValidationResults;
}

export interface IGetDepartmentListRequest extends IDepartmentJobCommon {
  getByDepartmentStatusType?: boolean;
  departmentStatusTypeList?: JobDefinitionStatusTypeEnum[];
}

export interface IGetDepartmentListResponse {
  departmentList: Array<IDepartment>;
  validationResults: IValidationResults;
}

export interface IPersistDepartmentRequest {
  companyId: number;
  department: IDepartment;
  assignToPrimaryJob?: boolean;
  personIdList?: number[];
}

export interface IPersistDepartmentResponse {
  id: number;
  validationResults: IValidationResults;
}
export interface IPersistCompensationRateRequest {
  personIdList: number[];
  companyId: number;
  wcbRateId: number;
  isEdit?: boolean;
}

export interface IPersistCompensationRateResponse {
  validationResults: IValidationResults;
}

export interface IPersistJobLocationRequest {
  companyId: number;
  personIdList: number[];
  locationId: number;
}

export interface IPersistJobLocationResponse {
  validationResults: IValidationResults;
}

export interface IGetProvinceVacationRequest {
  companyId: number;
  stateId: number;
  personId: number;
  getVacationPercentByStateId: boolean;
  hireDate?: string;
}

export interface IGetProvinceVacationResponse {
  validationResults: IValidationResults;
  vacation?: IVacation;
}
