import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { ConnectionStatusTypeEnum } from 'services/appCore/models/schema/Schema';
import AccountingManagerService, {
  GetInitialStateRequestInterface,
  IntegrationTypeEnum,
} from 'services/integrations-access/accountingManagerAccess';

import { GetInitialStateEnum } from 'services/integrations-access/integrationsAccess.contracts';
import timeIntegrationAccessService, {
  ConnectionInitialisationResponseInterface,
} from 'services/integrations-access/timeIntegrationAccess.service';
import hrManagerService, {
  GetInitialStateRequestInterface as GetPeopleInitialStateRequestInterface,
  GetInitialStateResponseInterface,
  IntegrationTypeEnum as IntegrationTypePeopleEnum,
} from 'services/integrations-access/hrManager.service';

import { AccountingSyncStatusTypeEnum } from 'services/payroll/schema/Schema';

interface IStatusQboInfo {
  authorizationUri: string;
  connectionStatus: number;
  isChartOfAccountsMapped: boolean;
  isVendorLinked: boolean;
  controlledTipsInPayroll?: boolean;
  vacationPayAccrued?: boolean;
  isActive: boolean;
  accountingSyncStatus: AccountingSyncStatusTypeEnum;
}

export enum IntegrationStatusEnum {
  notConnected = 'notConnected',
  setUpRequired = 'setUpRequired',
  connected = 'connected',
}

//TODO: Will be refactored in the future when  server side create one endpoint for all integrations statuses
interface IIntegrationInfo<T> {
  info: T;
  isValidating: boolean;
  fulfilled: boolean;
  status: string;
  error?: string | unknown;
  connectionStatus: number;
}

interface IAllIntegrations {
  qbo: IIntegrationInfo<IStatusQboInfo>;
  time: IIntegrationInfo<
    ConnectionInitialisationResponseInterface & { jobTabStatus?: boolean; isTokenExpired?: boolean }
  >;
  people: IIntegrationInfo<GetInitialStateResponseInterface & { jobTabStatus?: boolean; isTokenExpired?: boolean }>;
}

const initialState: IAllIntegrations = {
  qbo: {
    info: {
      authorizationUri: '',
      connectionStatus: 0,
      isChartOfAccountsMapped: false,
      isActive: false,
      isVendorLinked: false,
      accountingSyncStatus: AccountingSyncStatusTypeEnum.notDefined,
    },
    isValidating: false,
    fulfilled: false,
    status: '',
    connectionStatus: GetInitialStateEnum.notConnected,
  },
  time: {
    info: {
      connectionStatus: '',
      authorizationUri: '',
      isEmployeeMapped: '',
      validationResults: {},
      jobTabStatus: false,
    },
    isValidating: false,
    fulfilled: false,
    status: '',
    connectionStatus: GetInitialStateEnum.notConnected,
  },
  people: {
    info: {
      connectionStatus: 0,
      authorizationUri: '',
      newEmployeesAdded: false,
      syncedEmployeeCount: 0,
      syncedAllEmployees: false,
      validationResults: {},
      jobTabStatus: false,
    },
    isValidating: false,
    fulfilled: false,
    status: '',
    connectionStatus: GetInitialStateEnum.notConnected,
  },
};

export const getInitialQboAction = createAsyncThunk(
  '/addons/getIntegrationStatusQbo',
  (request: Omit<GetInitialStateRequestInterface, 'integrationType'>) => {
    return AccountingManagerService.getInitialState({
      ...request,
      integrationType: IntegrationTypeEnum.qbo,
    });
  }
);

export const getInitialTimeAction = createAsyncThunk(
  '/addons/getIntegrationStatusTime',
  timeIntegrationAccessService.connectionInitialisation
);

export const getInitialPeopleAction = createAsyncThunk(
  '/addons/getIntegrationStatusPeople',
  (request: Omit<GetPeopleInitialStateRequestInterface, 'integrationType'>) => {
    return hrManagerService.getInitialState({
      ...request,
      integrationType: IntegrationTypePeopleEnum.people,
    });
  }
);

export const getIntegrationStatusSlice = createSlice({
  name: 'get-integration-status',
  initialState,
  reducers: {
    setJobTabStatus: (state, action: { payload: boolean }) => {
      state.time.info.jobTabStatus = action.payload;
    },
    setLinkVendorStatus: (state, action: { payload: boolean }) => {
      state.qbo.info.isVendorLinked = action.payload;
    },
    setTimeTokenExpired: (state, action: { payload: boolean }) => {
      state.time.info.isTokenExpired = action.payload;
    },
    setIsEmployeeMapped: (state, action: { payload: string }) => {
      state.time.info.isEmployeeMapped = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialQboAction.pending, (state) => {
        state.qbo.isValidating = true;
        state.qbo.fulfilled = false;
      })
      .addCase(getInitialQboAction.fulfilled, (state, action) => {
        state.qbo.info = action.payload;
        state.qbo.isValidating = false;
        state.qbo.status =
          action.payload.connectionStatus === ConnectionStatusTypeEnum.connected ||
          action.payload.connectionStatus === ConnectionStatusTypeEnum.disconnected
            ? 'qbo'
            : '';
        state.qbo.connectionStatus = action.payload.connectionStatus;
        state.qbo.fulfilled = true;
      })
      .addCase(getInitialQboAction.rejected, (state, action) => {
        state.qbo.error = action.payload;
        state.qbo.fulfilled = false;
        state.qbo.isValidating = false;
      });
    builder
      .addCase(getInitialTimeAction.pending, (state) => {
        state.time.isValidating = true;
        state.time.fulfilled = false;
      })
      .addCase(getInitialTimeAction.fulfilled, (state, action) => {
        state.time.info = { ...state.time.info, ...action.payload };
        state.time.isValidating = false;
        state.time.status = action.payload.connectionStatus;
        state.time.connectionStatus =
          action.payload.connectionStatus === 'Active'
            ? GetInitialStateEnum.connected
            : GetInitialStateEnum.notConnected;
        state.time.fulfilled = true;
      })
      .addCase(getInitialTimeAction.rejected, (state, action) => {
        state.time.error = action.payload;
        state.time.fulfilled = false;
        state.time.isValidating = false;
        state.time.info = { ...state.time.info, isTokenExpired: true };
      });
    builder
      .addCase(getInitialPeopleAction.pending, (state) => {
        state.people.isValidating = true;
        state.people.fulfilled = false;
      })
      .addCase(getInitialPeopleAction.fulfilled, (state, action) => {
        state.people.info = { ...state.people.info, ...action.payload };
        state.people.isValidating = false;
        state.people.connectionStatus = action.payload.connectionStatus;
        state.people.fulfilled = true;
      })
      .addCase(getInitialPeopleAction.rejected, (state, action) => {
        state.people.error = action.payload;
        state.people.fulfilled = false;
        state.people.isValidating = false;
        state.people.info = { ...state.people.info, isTokenExpired: true };
      });
  },
});
export const { setJobTabStatus, setLinkVendorStatus, setTimeTokenExpired, setIsEmployeeMapped } =
  getIntegrationStatusSlice.actions;
export const getInitialStateReducer = getIntegrationStatusSlice.reducer;

export const selectQBO = (state: RootState) => state.getInitialStateReducer.qbo;
export const selectTime = (state: RootState) => state.getInitialStateReducer.time;
export const selectPeople = (state: RootState) => state.getInitialStateReducer.people;
export const selectTimeJobTabStatus = (state: RootState) => state.getInitialStateReducer.time.info.jobTabStatus;

export const selectPeopleConnectionStatus = (state: RootState) => state.getInitialStateReducer.people.connectionStatus;
export const selectPeopleNewEmployeesAdded = (state: RootState) =>
  state.getInitialStateReducer.people.info.newEmployeesAdded;
export const selectPeopleSyncedEmployeeCount = (state: RootState) =>
  state.getInitialStateReducer.people.info.syncedEmployeeCount;
export const selectPeopleSyncedAllEmployees = (state: RootState) =>
  state.getInitialStateReducer.people.info.syncedAllEmployees;

export const selectTimeStatus = (state: RootState) => state.getInitialStateReducer.time.status;
export const selectTimeConnectionStatus = (state: RootState) => state.getInitialStateReducer.time.connectionStatus;
export const selectTimeIsTokenExpired = (state: RootState) => state.getInitialStateReducer.time.info.isTokenExpired;
export const getIsEmployeeMapped = (state: RootState) => state.getInitialStateReducer.time.info.isEmployeeMapped;

export const selectQBOVendorLinked = createSelector(selectQBO, (qbo) => qbo.info.isVendorLinked);
export const selectQBOVendorIsChartOfAccountsMapped = createSelector(
  selectQBO,
  (qbo) => qbo.info.isChartOfAccountsMapped
);
