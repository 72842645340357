import React from 'react';
import ReactDOM from 'react-dom';
import APPS from './config/apps';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store as wpStore } from 'redux/store/store';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from 'query-client/query-client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LdCLient, AppEnvironments } from 'LDfeatureFlag';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallBack from 'applications/app/ErrorBoundaryFallBack/ErrorBoundaryFallBack';
import { InitSentry } from 'applications/app/sentry';

// Determine which entry point to import
const { path }: any = APPS.find(({ name }) => process.env.REACT_APP_BUILD_TARGET === name);

import(`${path}`).then(async ({ default: BuildTarget }) => {
  const key = LdCLient[process?.env?.REACT_APP_BASE_URL as AppEnvironments];
  const LDProvider = await asyncWithLDProvider({
    clientSideID: key,
  });

  return ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallBack}>
        <LDProvider>
          <InitSentry /> {/** Sentry errors will be only catched beyond this point */}
          <Provider store={wpStore}>
            <QueryClientProvider client={queryClient}>
              <BuildTarget />
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
          </Provider>
        </LDProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
