import { useEffect, useMemo, useReducer } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import {
  selectClientSecurityRoleType,
  updateInitialAdminList,
} from 'redux/slice/accountingDashboardSlices/clientDirectorySlice';

import { SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';
import { GetUserListSortEnum } from 'services/finance/models/access/companyLinkAccess';

import { useGetAdminsForGridQuery } from './useAdministratorsQueries';
import { IWpGridColumn, IWpGridEvents } from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid.interface';
import { WpGridColumnSortDirectionEnum } from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid.enum';

export interface IAdminsState {
  search: string;
  sort: { sortColumnType: GetUserListSortEnum; sortAscending: boolean };
}

type GetAdminsForGridConatinerParams = {
  clientId?: number;
  partnerId?: number;
};

export const useAdministratorsForGrid = (params: GetAdminsForGridConatinerParams) => {
  const clientSecurityRole = useAppSelector(selectClientSecurityRoleType);
  const dispatch = useAppDispatch();

  const initialAdminList = useAppSelector(({ clientDirectoryReducer }) => clientDirectoryReducer.initialAdminList);

  const [{ sort, search }, setState] = useReducer(
    (state: IAdminsState, newState: Partial<IAdminsState>) => ({ ...state, ...newState }),
    {
      search: '',
      sort: {
        sortColumnType: GetUserListSortEnum.name,
        sortAscending: true,
      },
    }
  );

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetAdminsForGridQuery({
    text: search,
    securityRoleType: clientSecurityRole ?? SecurityRoleTypeEnum.notDefined,
    ...sort,
    clientId: params.clientId,
    partnerId: params.partnerId,
  });
  const adminList = useMemo(() => data?.pages.flatMap((item) => item ?? []) ?? [], [data?.pages]);

  const handleSearch = (search: string) => setState({ search });

  const gridEvents: IWpGridEvents = {
    async onColumnSorting(sortedColumns: IWpGridColumn[]) {
      if (sortedColumns[0]) {
        const sortColumnType = sortedColumns[0]?.currentSortColumn as keyof typeof GetUserListSortEnum | undefined;
        let newSort = {
          sortColumnType: sortColumnType ? GetUserListSortEnum[sortColumnType] : GetUserListSortEnum.name,
          sortAscending: sortedColumns[0]?.sortDirection === WpGridColumnSortDirectionEnum.ASC,
        };
        if (sortedColumns[0].sortDirection === WpGridColumnSortDirectionEnum.NONE) {
          newSort = {
            sortColumnType: GetUserListSortEnum.name,
            sortAscending: true,
          };
        }
        setState({ sort: newSort });
      }
    },
    onReachedEnd() {
      if (!hasNextPage || isFetchingNextPage) return;
      fetchNextPage();
    },
  };

  useEffect(() => {
    if (!search) {
      dispatch(updateInitialAdminList(adminList));
    }
  }, [adminList, search]);

  return {
    handleSearch,
    isSearched: !!search,
    gridEvents,
    adminList,
    initialAdminList,
  };
};
