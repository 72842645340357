import { createSlice } from '@reduxjs/toolkit';
import { IWpTabProps } from '@wagepoint/ui-toolkit/components/wp-tabs/wpTabs';
import { RootState } from 'redux/store/store';

interface IPayPeriodItemCount {
  count?: number;
  showHoursIncomeBanner?: boolean;
  showTaxBanner?: boolean;
}
export interface IBannerPayperiodCount {
  [key: number]: IPayPeriodItemCount;
}
interface IpayrollHeaderInit {
  tabs: IWpTabProps[];
  triggerApiCall: boolean;
  banner: IBannerPayperiodCount;
}
const initialState: IpayrollHeaderInit = {
  tabs: [],
  triggerApiCall: false,
  banner: {},
};

export const payrollHeaderSlice = createSlice({
  name: 'payrollHeaderSlice',
  initialState,
  reducers: {
    setTabState: (state, action: { payload: { key: 'tabs'; value: any } }) => {
      state[action.payload.key] = action.payload.value;
    },
    setBannerState: (state, action: { payload: { value: IBannerPayperiodCount } }) => {
      state.banner = { ...state.banner, ...action.payload.value };
    },
    setBannerForEachPaygroup: (state, action: { payload: { key: number; value: IPayPeriodItemCount } }) => {
      state.banner[action.payload.key] = { ...state.banner[action.payload.key], ...action.payload.value };
    },
    triggerApiCallAction: (state) => {
      state.triggerApiCall = !state.triggerApiCall;
    },
  },
});

export const { setTabState, triggerApiCallAction, setBannerState, setBannerForEachPaygroup } =
  payrollHeaderSlice.actions;
export const payrollHeaderReducer = payrollHeaderSlice.reducer;
export const payrollHeaderStates = (state: RootState) => state.payrollHeaderReducer;
export const selectBannerStatus = (state: RootState) => state.payrollHeaderReducer.banner;
export const payrollTriggerApiCall = (state: RootState) => state.payrollHeaderReducer.triggerApiCall;
