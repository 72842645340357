import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

const useHideMenuOptions = () => {
  const { feature } = useFeatureFlag();
  const hideYtdReport = feature(FEATURE_FLAG_KEYS.HideYTDReport);

  return {
    hideYtdReport,
  };
};

export default useHideMenuOptions;
