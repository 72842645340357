import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import DisabledPayrollAdminContainer from './assignedPeopleView/statements/disabledPayrollAdmin';
import ZeroStateCards from './zeroStateCards';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import WpLink from '@wagepoint/ui-toolkit/components/wp-link/wpLink';
import WpGrid from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid';
import WpSearchBox from '@wagepoint/ui-toolkit/components/wp-search-box/wpSearchBox';
import WpFilterWidget from '@wagepoint/ui-toolkit/components/wp-filter-widget/wpFilterWidget';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import WpDropdown from '@wagepoint/ui-toolkit/components/wp-dropdown/wpDropdown';
import WpToolTip, { WpToolTipEnum } from '@wagepoint/ui-toolkit/components/wp-tooltip/wpToolTip';
import WpTypography from '@wagepoint/ui-toolkit/components/wp-typography';
import { IWpFilterWidgetDropdownType } from '@wagepoint/ui-toolkit/components/wp-filter-widget/wpFilterWidget.interface';
import { IWpGridColumn, IWpGridEvents } from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid.interface';
import { WpGridColumnSortDirectionEnum } from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid.enum';
// TODO: replace with WpIcon
// eslint-disable-next-line no-restricted-imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';
import MigrationOneManagerService from 'shared/services/payroll/migrationOneManager';
import {
  GetAdminListSortEnum,
  GetUserListSortEnum,
  IGetUserListRequest,
  IGetUserListResponse,
  IUser,
  PersonTypeEnum,
  SortDirectionEnum,
} from 'services/security-access/securityAccess.contracts';
import {
  AddPersonToManager,
  CreateEmployeeProfile,
  GetAdminList,
  GetSecurityRole,
  GetUserList,
  MaintainUser,
} from 'services/security-access/securityAccess.service';
import {
  CompanyToggleTypeEnum,
  IGetCompanySettingsInitialStateResponse,
} from 'services/company-settings-access/companySettingsAccess.contracts';
import { getCompanySettingsInitialstate } from 'redux/slice/companySettingsSlice';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { GetDepartmentList } from 'services/company-access/companyAccess.service';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import {
  selectAccountingAssignedRole,
  selectedIsViewDisabled,
  selectRolesListRoles,
  setEditState,
  setIsViewDisabled,
} from 'redux/slice/userAccountsSlice';
import { debounce } from '@wagepoint/ui-toolkit/utility/debounce';
import { UserAccountsTypeEnum } from './roles/rolesContainer';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'redux/hooks/useSnackbar';
import { useLoaders } from 'redux/hooks/useLoaders';
import { selectCompanyId, selectShowMigrationDahsboard, selectTimeZonePreference } from 'redux/slice/configSlice';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PermissionsEnum } from 'services/shared/permission.contracts';
import { selectFirmCompanyId } from 'redux/slice/accountingFirmSlice';
import { displayRoleList } from './common';
import { NotFound } from '@wagepoint/ui-toolkit/assets/illustrations/notFound';
import { useViewPermissionByPartPath } from 'util/customHooks/useViewPermission';
import { isFromAlfredSelector } from 'redux/slice/fromAlfredSlice';
import { useConvertTZ } from 'util/customHooks/useFormatDate';
import { appMinSearchKeyLength, getApiValidationErrors, getErrorMessage } from 'util/utility';
import { AssignedPeopleGroupStyles } from './assignedPeopleGroupStyles';
import {
  AssignedRolesActions,
  IAssignedRolesActionsProps,
} from './roles/assignedRolesContainer/assignedRolesAction/assignedRolesActions';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import createEmployeeProfileHandler from './roles/assignedRolesContainer/assignedRolesAction/util/createEmployeeProfile';
import { emptyPlug } from '@wagepoint/ui-shared-library/shared-system/constants/appContants';
import WpZeroState from '@wagepoint/ui-toolkit/components/wp-zeroState';

interface IProps {
  selectedUserAccountOption?: number;
  headerVisibility?: (value: boolean) => void;
}

interface IZeroStateCards {
  title: string;
  description: string;
  buttonContent: string;
  pathname?: string;
}

export enum DialogTypeEnum {
  NotDefined = 0,
  Disable = 1,
  CreateEmployeeProfile = 2,
  Enable = 3,
  EnableManager = 4,
}

export enum PermissionSettingsEnum {
  Setup = PermissionsEnum.SetupRoot,
  Payroll = PermissionsEnum.PayrollRoot,
  PayrollTools = PermissionsEnum.PayrollTools,
  Reports = PermissionsEnum.ReportsRoot,
  CompanySettings = PermissionsEnum.CompanySettingsRoot,
  PayrollSettings = PermissionsEnum.PayrollSetup,
  TaxSettings = PermissionsEnum.TaxSetup,
}

// Please use this Enum to attach additional settings.
export enum PermissionSettingsEnumExtended {
  AccountingPortal = PermissionsEnum.AccountingPortal,
  Alfred = PermissionsEnum.Alfred,
}

export enum PermissionSettingsEnumForPeople {
  BasicPermission = PermissionsEnum.EmployeePortal,
  PayrollPermission = PermissionsEnum.ScEmployeePayroll,
}

interface IGetUserList {
  isViewDisabled?: boolean;
  search?: string;
  initial?: boolean;
  page?: number;
}

const defaultTake = 25;

const AssignedPeopleGroup: React.FC<IProps> = ({ headerVisibility, selectedUserAccountOption }) => {
  const classes = AssignedPeopleGroupStyles();

  const [usersList, setUsersList] = useState<Array<IUser>>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [isDepartmentAvailable, setDepartmentAvailable] = useState<boolean>();
  const [departmentList, setDepartmentList] = useState<Array<any>>([]);
  const [departmentListForService, setDepartmentListForService] = useState<Array<number>>([]);
  const [isTwoStepApproval, setIsTwoStepApproval] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [enabledTotalCount, setEnabledTotalCount] = useState(0);
  const [disabledTotalCount, setDisabledTotalCount] = useState(0);
  const [searchResultsCount, setSearchResultsCount] = useState<number>();
  const [disabledMode, setDisabledMode] = useState(false);
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedCompanyId = useAppSelector(selectCompanyId);
  const showMigrationDashboard = useAppSelector(selectShowMigrationDahsboard);
  const assignedRole = useAppSelector(selectAccountingAssignedRole);
  const isViewDisabled = useAppSelector(selectedIsViewDisabled);
  const companyId = useAppSelector(selectFirmCompanyId);
  const timeZonePreference = useAppSelector(selectTimeZonePreference) ?? 0;

  const { t } = useTranslation(['common', 'userAccounts']);
  const { isViewOnlyPermission } = useViewPermissionByPartPath();
  const convertTZFormat1 = useConvertTZ("MMM d, yyyy 'at' h:mm a");
  const convertTZFormat2 = useConvertTZ('MMM d, yyyy - h:mm a');

  const roles = useAppSelector(selectRolesListRoles);
  const { feature } = useFeatureFlag();
  const featureDisableEdit = feature(FEATURE_FLAG_KEYS.DisableEditForUserPermissions);
  const removeMigrationDep = feature(FEATURE_FLAG_KEYS.RemoveMigrationDependencyFromResentInvite);

  const [anchorId, setAnchorId] = useState(0);
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [rowDetails, setRowDetails] = useState<any>({});
  const [menuActionId, setMenuActionId] = useState<number>(DialogTypeEnum.NotDefined);
  const [filterCheckBox, setFilterCheckBox] = useState(false);
  const [sortValue, setSortValue] = useState({ sortBy: 0, sortDesc: true });
  const [sortDetails, setSortDetails] = useState({
    col: '',
    sortDirection: 0,
  });
  const [maintainAdminCount, setAdminCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const isFromAlfred = useAppSelector(isFromAlfredSelector);
  const validationSchema = yup.object().shape({
    department: yup.array().min(1, t('userAccounts:assignRole.validation')),
  });
  const [pageStart, setPageStart] = useState(0);
  const [userListLoading, setUserListLoading] = useState(false);
  const { error, success } = useSnackbar();
  const { showLoader, hideLoader } = useLoaders();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const {
    errors,
    formState: { isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    getDepartmentList();
    if (
      selectedUserAccountOption === UserAccountsTypeEnum.Administrator ||
      selectedUserAccountOption === UserAccountsTypeEnum.AccountingFirm
    ) {
      getAdminList();
    } else {
      if (Object.keys(roles).length > 0 || selectedUserAccountOption === UserAccountsTypeEnum.People) {
        getUsersList();
      }
    }
  }, []);

  useEffect(() => {
    if (
      roles?.securityRoleType !== SecurityRoleTypeEnum.Manager ||
      selectedUserAccountOption === UserAccountsTypeEnum.Administrator ||
      selectedUserAccountOption === UserAccountsTypeEnum.People
    )
      return;
    getSecurityRoleListService();
  }, [usersList]);

  useEffect(() => {
    if (enabledTotalCount > 0 || disabledTotalCount > 0) {
      selectedUserAccountOption === UserAccountsTypeEnum.Administrator
        ? getAdminList(filterCheckBox)
        : getUsersList({ isViewDisabled: filterCheckBox });
    }
  }, [sortValue]);

  useEffect(() => {
    if (selectedUserAccountOption === UserAccountsTypeEnum.Administrator) return;
    if (enabledTotalCount === 0) getUsersList({ isViewDisabled: true, initial: true });
  }, [enabledTotalCount]);

  useEffect(() => {
    getTwoStepEnabled();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setIsViewDisabled({ value: false }));
    };
  }, []);

  const getTwoStepEnabled = async () => {
    if (!selectedCompanyId) return;

    const companySettingsPromise = await dispatch(getCompanySettingsInitialstate({ companyId: selectedCompanyId }));

    const response = companySettingsPromise.payload as IGetCompanySettingsInitialStateResponse;
    if (response.validationResults && response.validationResults.validationResultList?.length == 0) {
      response.companySettings?.companyToggleList.forEach((item) => {
        if (item.companyToggleType === CompanyToggleTypeEnum.twoStepApproval) setIsTwoStepApproval(item.isOn);
      });
    }
  };

  const getDepartmentList = async () => {
    const response = await GetDepartmentList({ companyId: selectedCompanyId });
    const { hasError } = getApiValidationErrors(response);

    if (!hasError) {
      const departmentList = response.departmentList.filter((item) => !item.hasManager);
      setDepartmentList(departmentList);
    }
  };

  const addSearch = (search?: string) => {
    const searchKey = (search && search.length > appMinSearchKeyLength) || false;
    return {
      filterByName: searchKey,
      nameContains: searchKey ? search : '',
    };
  };

  //user list called with particular security role id
  const getUsersList = async ({ isViewDisabled = false, search, initial = false, page }: IGetUserList = {}) => {
    showLoader();
    if (page) setUserListLoading(true);

    const request: IGetUserListRequest = {
      skip: page ? page + 1 : 0, // page +1: to take next iteration correctly, 0-10 , 11-21 , 22-32 , 33-43
      take: defaultTake,
      includeEmployee: true,
      includeDisabled: isViewDisabled,
      companyId: selectedCompanyId,
      ...addSearch(search),
      ...(!isViewDisabled &&
        selectedUserAccountOption !== UserAccountsTypeEnum.People && { securityRoleId: 0 || roles.id }),
      filterByPersonType: true,
      includeContractor: true,
      includeNonEmployee: selectedUserAccountOption !== UserAccountsTypeEnum.People,
      ...(isViewDisabled && {
        securityRoleType: roles?.securityRoleType || (location?.state as any)?.securityRoleType,
      }),
      ...(selectedUserAccountOption === UserAccountsTypeEnum.AccountingFirm && {
        securityRoleType: assignedRole,
      }),
      sortColumnType: sortValue.sortBy,
      sortAscending: sortValue.sortDesc,
      includeEmployeeAdmin: true,
    };
    const response: IGetUserListResponse = await GetUserList(request);
    if (page) setUserListLoading(false);
    if (response.validationResults && response.validationResults?.validationResultList.length === 0) {
      hideLoader();
      if (!initial && page === undefined) setUsersList(response.userList);
      if (page !== undefined) {
        setPageStart((prev) => prev + defaultTake + 1);
        setUsersList((prev) => [...prev, ...response.userList]);
      }
      setLoading(true);
      if (!isViewDisabled && !initial) {
        setEnabledTotalCount(response?.totalCount);
      } else {
        setDisabledTotalCount(response?.totalCount);
      }
      if (search && response.userList.length === 0) {
        setSearchResultsCount(response.userList.length);
      }
      setDisabledMode(response.userList.some((item) => item.isDisabled));
      // setDisabledItems(false);
    } else {
      hideLoader();
      setUsersList([]);
      error(
        response.validationResults?.validationResultList[0].validationMessage ||
          t('userAccounts:permissionSettings.error')
      );
    }
  };

  const getAdminList = async (isDisabledItems?: boolean, search?: string) => {
    showLoader();

    const response = await GetAdminList({
      companyId: selectedCompanyId,
      sortColumnType: sortValue.sortBy,
      sortAscending: sortValue.sortDesc,
      includeAccountingAdmin: false,
      includeDisabled: isDisabledItems ?? isViewDisabled,
      filterByName: !!search,
      nameContains: !!search ? search : '',
      ...(selectedUserAccountOption === UserAccountsTypeEnum.AccountingFirm && {
        companyId: companyId,
      }),
    });
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      hideLoader();

      setUsersList(response.userList);
      setDisabledMode(response.userList.some((item: IUser) => item.isDisabled));

      /* In case if admin List has only one admin - then we need to display a popup */
      let adminCount = 0;
      response.userList.map((item) => {
        if (
          item?.personType == PersonTypeEnum.Employee &&
          [
            SecurityRoleTypeEnum.AccountOwner,
            SecurityRoleTypeEnum.AccountSignatory,
            SecurityRoleTypeEnum.AdministratorApprover,
            SecurityRoleTypeEnum.AdministratorSubmitter,
            SecurityRoleTypeEnum.AccountAdministrator,
            SecurityRoleTypeEnum.Administrator,
          ].some((role) => item?.securityRoleTypeList?.length && item?.securityRoleTypeList.includes(role))
        ) {
          adminCount++;
        }
      });
      setAdminCount(adminCount);

      setLoading(true);
      if (!isViewDisabled) {
        setEnabledTotalCount(response.userList.length);
      } else {
        getDepartmentList();
        setDisabledTotalCount(response.userList.length);
      }
      if (search && response.userList.length === 0) {
        setSearchResultsCount(response.userList.length);
      }
      // setDisabledItems(false);
    } else {
      hideLoader();
      setUsersList([]);
    }
  };

  const getSecurityRoleListService = async () => {
    const response = await GetSecurityRole({
      includeUserList: false,
      includePermissionList: false,
      securityRoleType: SecurityRoleTypeEnum.Manager,
      companyId: selectedCompanyId,
    });
    if (response.validationResults && response.validationResults?.validationResultList.length === 0) {
      setDepartmentAvailable(response?.isDepartmentAvailable);
    } else {
      setDepartmentAvailable(false);
    }
  };

  const handleSearch = React.useCallback(
    debounce((searchString: string) => {
      const searchValue = searchString || '';
      const shouldSearch = searchValue === '' || searchValue.length > 3;
      setSearchValue(searchString);
      if (shouldSearch) {
        selectedUserAccountOption === UserAccountsTypeEnum.Administrator
          ? getAdminList(filterCheckBox, searchString)
          : getUsersList({ isViewDisabled: filterCheckBox, search: searchString });
      }
    }, 600),
    []
  );

  const handleZeroStateContent = () => {
    let emptyCard = {} as IZeroStateCards;
    switch (selectedUserAccountOption) {
      case UserAccountsTypeEnum.Roles:
        if (roles.securityRoleType === SecurityRoleTypeEnum.Manager) {
          emptyCard = {
            title:
              isDepartmentAvailable !== undefined
                ? isDepartmentAvailable
                  ? t('userAccounts:assignedPeopleGroup.otherRoles.title')
                  : t('userAccounts:assignedPeopleGroup.manager.title')
                : '',
            description:
              isDepartmentAvailable !== undefined
                ? isDepartmentAvailable
                  ? t('userAccounts:assignedPeopleGroup.otherRoles.description')
                  : t('userAccounts:assignedPeopleGroup.manager.description')
                : '',
            buttonContent: `${t('userAccounts:assignedPeopleGroup.manager.buttonContent')} ${roles.name}`,
            pathname: '/assign',
          };
        } else {
          emptyCard = {
            title: t('userAccounts:assignedPeopleGroup.otherRoles.title'),
            description: t('userAccounts:assignedPeopleGroup.otherRoles.description'),
            buttonContent: `${t('userAccounts:assignedPeopleGroup.manager.buttonContent')} ${roles.name}`,
            pathname: '/assign',
          };
        }
        break;
      case UserAccountsTypeEnum.People:
        emptyCard = {
          title: t('userAccounts:assignedPeopleGroup.people.title'),
          description: t('userAccounts:assignedPeopleGroup.people.description'),
          buttonContent: '',
        };
        break;
      default:
        break;
    }
    return emptyCard;
  };

  const returnDialogTypeEnum = (securityRoleTypeList: SecurityRoleTypeEnum[]) => {
    let returnValue;
    securityRoleTypeList.map((item) => {
      if (Object.values(SecurityRoleTypeEnum).includes(item)) {
        if (item === SecurityRoleTypeEnum.Manager) {
          returnValue = DialogTypeEnum.EnableManager;
        } else {
          returnValue = DialogTypeEnum.Enable;
        }
      }
    });
    return returnValue;
  };

  const menuOptions: IAssignedRolesActionsProps['menuOptions'] = [
    ...(!featureDisableEdit
      ? [
          {
            label: t('userAccounts:permissionSettings.edit'),
            onClick: (row: any) => handleViewClick(row),
            display: DialogTypeEnum.NotDefined,
          },
        ]
      : []),
    {
      label: t('userAccounts:assignedPeopleGroup.columns.disable'),
      onClick: (row?: any, dialog?: DialogTypeEnum, rowId?: any) => handleDialog(dialog, rowId, row),
      display: DialogTypeEnum.Disable,
    },
    {
      label: t('userAccounts:assignedPeopleGroup.columns.employeeProfile'),
      onClick: (row?: any, dialog?: DialogTypeEnum, rowId?: any) => handleDialog(dialog, rowId, row),
      display: DialogTypeEnum.CreateEmployeeProfile,
    },
  ];

  const displayMenuOptionsHandler = (row?: any) => {
    const isConditionSatisfied = createEmployeeProfileHandler(row, roles);
    return !!menuOptions?.slice(1)?.length && isConditionSatisfied;
  };

  const columns: IWpGridColumn[] = [
    {
      field: '',
      title: '',
      templateSupply: () => {
        return (
          <Grid container wrap="nowrap" alignItems="center" className={classes.wrapCheckBoxImg}>
            <Grid item>
              <span className={classes.imageWrap} />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'Name',
      title:
        selectedUserAccountOption === UserAccountsTypeEnum.Roles
          ? t('userAccounts:assignedPeopleGroup.columns.fullName')
          : t('userAccounts:assignedPeopleGroup.columns.name'),
      enableSorting: true,
      sortDirection:
        sortDetails.col == GetUserListSortEnum[GetUserListSortEnum.Name]
          ? sortDetails.sortDirection
          : WpGridColumnSortDirectionEnum.NONE,

      templateSupply: (row: any) => {
        if (row) {
          return <WpTypography>{row.firstName + ' ' + row.lastName}</WpTypography>;
        }
      },
    },
    {
      field: 'Email',
      title: t('userAccounts:assignedPeopleGroup.columns.email'),
      enableSorting: selectedUserAccountOption === UserAccountsTypeEnum.Roles,
      sortDirection:
        sortDetails.col == GetUserListSortEnum[GetUserListSortEnum.Email]
          ? sortDetails.sortDirection
          : WpGridColumnSortDirectionEnum.NONE,
      templateSupply: (row: any) => {
        if (row) {
          return <WpTypography>{row.email}</WpTypography>;
        }
      },
    },
    ...(selectedUserAccountOption === UserAccountsTypeEnum.Administrator ||
    selectedUserAccountOption === UserAccountsTypeEnum.AccountingFirm
      ? [
          {
            field: 'Role',
            title: t('userAccounts:assignedPeopleGroup.columns.role'),
            enableSorting: true,
            sortDirection:
              sortDetails.col == GetAdminListSortEnum[GetAdminListSortEnum.Role]
                ? sortDetails.sortDirection
                : WpGridColumnSortDirectionEnum.NONE,
            templateSupply: (row: any) => {
              if (row) {
                return (
                  <WpTypography>
                    {row.securityRoleTypeList.map((item: any) => {
                      return displayRoleList(t).find((x) => x.id === item)?.name;
                    })}
                  </WpTypography>
                );
              }
            },
          },
        ]
      : []),

    ...(selectedUserAccountOption !== UserAccountsTypeEnum.Administrator &&
    roles.securityRoleType !== SecurityRoleTypeEnum.Manager &&
    selectedUserAccountOption !== UserAccountsTypeEnum.AccountingFirm
      ? [
          {
            field: 'EmploymentType',
            title:
              selectedUserAccountOption === UserAccountsTypeEnum.Roles
                ? t('userAccounts:assignedPeopleGroup.columns.type')
                : t('userAccounts:assignedPeopleGroup.columns.employmentType'),
            enableSorting: selectedUserAccountOption === UserAccountsTypeEnum.People,
            sortDirection:
              sortDetails.col == GetUserListSortEnum[GetUserListSortEnum.EmploymentType]
                ? sortDetails.sortDirection
                : WpGridColumnSortDirectionEnum.NONE,
            templateSupply: (row: any) => {
              if (row) {
                return (
                  <WpTypography>
                    {Object.values(PersonTypeEnum).includes(row.personType)
                      ? row.personType == PersonTypeEnum.Other
                        ? t('userAccounts:assignedPeopleGroup.columns.nonEmp')
                        : PersonTypeEnum[row.personType]
                      : null}
                  </WpTypography>
                );
              }
            },
          },
        ]
      : []),

    ...(roles.securityRoleType === SecurityRoleTypeEnum.Manager
      ? [
          {
            field: 'Department',
            title: t('userAccounts:assignedPeopleGroup.columns.department'),
            enableSorting: true,
            sortDirection:
              sortDetails.col == GetUserListSortEnum[GetUserListSortEnum.Department]
                ? sortDetails.sortDirection
                : WpGridColumnSortDirectionEnum.NONE,
            templateSupply: (row: any) => {
              if (row && row.departmentList.length > 0) {
                return <WpTypography>{row.departmentList.map((dep: any) => dep?.name).join()}</WpTypography>;
              }
            },
          },
        ]
      : []),

    {
      field: 'LastLoggedIn',
      title: t('userAccounts:assignedPeopleGroup.columns.lastLoggedIn'),
      enableSorting: selectedUserAccountOption !== UserAccountsTypeEnum.Administrator && true,
      sortDirection:
        sortDetails.col == GetUserListSortEnum[GetUserListSortEnum.LastLoggedIn]
          ? sortDetails.sortDirection
          : WpGridColumnSortDirectionEnum.NONE,
      templateSupply: (row: any) => {
        if (row) {
          return (
            <WpTypography>
              {row.lastLogin
                ? selectedUserAccountOption !== UserAccountsTypeEnum.People
                  ? convertTZFormat1(row.lastLogin, timeZonePreference)
                  : convertTZFormat2(row.lastLogin, timeZonePreference)
                : emptyPlug}
            </WpTypography>
          );
        }
      },
    },
    ...(selectedUserAccountOption === UserAccountsTypeEnum.Administrator
      ? [
          {
            field: 'action',
            title: '',
            templateSupply: (row: any) => {
              if (row) {
                return (
                  <Box display="flex">
                    {row.isDisabled ? (
                      isViewOnlyPermission ? (
                        <WpLink
                          onClick={() => {
                            handleDialog(returnDialogTypeEnum(row.securityRoleTypeList), row.id, row);
                          }}
                          disabled
                        >
                          {t('userAccounts:assignedPeopleGroup.columns.enable')}
                        </WpLink>
                      ) : row.securityRoleTypeList.includes(SecurityRoleTypeEnum.Manager) &&
                        departmentList.length === 0 ? (
                        <WpToolTip
                          title={t('userAccounts:assignedPeopleGroup.columns.noManager')}
                          placement="bottom-start"
                          tooltype={WpToolTipEnum.custom}
                          arrow
                          PopperProps={{
                            style: { pointerEvents: 'none' },
                          }}
                        >
                          <Box display="inlineBlock">
                            <WpLink
                              onClick={() => {
                                handleDialog(returnDialogTypeEnum(row.securityRoleTypeList), row.id, row);
                              }}
                              disabled
                            >
                              {t('userAccounts:assignedPeopleGroup.columns.enable')}
                            </WpLink>
                          </Box>
                        </WpToolTip>
                      ) : (
                        <WpLink
                          onClick={() => {
                            handleDialog(returnDialogTypeEnum(row.securityRoleTypeList), row.id, row);
                          }}
                        >
                          {t('userAccounts:assignedPeopleGroup.columns.enable')}
                        </WpLink>
                      )
                    ) : row.isEditable && selectedUserAccountOption === UserAccountsTypeEnum.Administrator ? (
                      <WpLink onClick={() => handleViewClick(row)}>{t('userAccounts:rolesContainer.view')}</WpLink>
                    ) : (
                      <>
                        {row.securityRoleTypeList.map((item: any) => {
                          return Object.values(SecurityRoleTypeEnum).includes(item) ? (
                            item === SecurityRoleTypeEnum.AccountOwner ? (
                              <WpLink onClick={() => handleViewClick(row)}>
                                {t('userAccounts:rolesContainer.view')}
                              </WpLink>
                            ) : (
                              <>
                                <WpLink onClick={() => handleViewClick(row)}>
                                  {t('userAccounts:rolesContainer.view')}
                                </WpLink>
                                <div>
                                  {!isViewOnlyPermission && (
                                    <FontAwesomeIcon
                                      className={classes.actionLink}
                                      icon={faSortDown}
                                      onClick={(e) => handleClick(e, row.id)}
                                    />
                                  )}
                                </div>
                                <div>
                                  {anchorId === row.id && (
                                    <Popover
                                      id="edit-dropdwn"
                                      anchorEl={open}
                                      open
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                    >
                                      {isTwoStepApproval && row?.isPayrollApprover ? (
                                        <WpToolTip
                                          title={t('userAccounts:assignedPeopleGroup.columns.tooltip')}
                                          placement="bottom-start"
                                          tooltype={WpToolTipEnum.custom}
                                          arrow
                                          PopperProps={{
                                            style: { pointerEvents: 'none' },
                                          }}
                                        >
                                          <Box display="inlineBlock">
                                            <MenuItem
                                              onClick={() => handleDialog(DialogTypeEnum.Disable, row.id, row)}
                                              className={classes.menuItem}
                                              disabled
                                            >
                                              {t('userAccounts:assignedPeopleGroup.columns.disable')}
                                            </MenuItem>
                                          </Box>
                                        </WpToolTip>
                                      ) : (
                                        <MenuItem onClick={() => handleDialog(DialogTypeEnum.Disable, row.id, row)}>
                                          {t('userAccounts:assignedPeopleGroup.columns.disable')}
                                        </MenuItem>
                                      )}
                                      {(removeMigrationDep ? true : showMigrationDashboard) && !row.lastLogin && (
                                        <MenuItem onClick={() => handleResendInvite(row)}>
                                          {t('userAccounts:assignedPeopleGroup.columns.resendInvite')}
                                        </MenuItem>
                                      )}
                                    </Popover>
                                  )}
                                </div>
                              </>
                            )
                          ) : null;
                        })}
                      </>
                    )}
                  </Box>
                );
              }
            },
          },
        ]
      : []),
    ...(selectedUserAccountOption === UserAccountsTypeEnum.Roles
      ? [
          {
            field: 'action',
            title: '',
            templateSupply: (row: any) => {
              const firstMenuOption = menuOptions[0];
              if (row) {
                return (
                  <div className={classes.displayContent}>
                    {row.isDisabled ? (
                      isDepartmentAvailable || roles?.securityRoleType !== SecurityRoleTypeEnum.Manager ? (
                        <WpLink
                          onClick={() => handleDialog(DialogTypeEnum.Enable, row.id, row)}
                          disabled={isViewOnlyPermission}
                          isButton
                        >
                          {t('userAccounts:assignedPeopleGroup.columns.enable')}
                        </WpLink>
                      ) : (
                        <WpToolTip
                          title={t('userAccounts:assignedPeopleGroup.columns.depMax')}
                          placement="bottom-start"
                          tooltype={WpToolTipEnum.custom}
                          arrow
                          isTrackPosition={false}
                        >
                          <span>
                            <WpLink isButton disabled>
                              {t('userAccounts:assignedPeopleGroup.columns.enable')}
                            </WpLink>
                          </span>
                        </WpToolTip>
                      )
                    ) : (
                      <>
                        <WpToolTip
                          show={firstMenuOption?.display === DialogTypeEnum.Disable && row.isPayrollApprover}
                          title={t('userAccounts:assignedPeopleGroup.columns.tooltipForAdmin')}
                          as="span"
                          arrow
                        >
                          <WpLink
                            onClick={() => firstMenuOption?.onClick(row, firstMenuOption?.display, row?.id)}
                            disabled={
                              isViewOnlyPermission ||
                              (firstMenuOption?.display === DialogTypeEnum.Disable && row.isPayrollApprover)
                            }
                          >
                            {firstMenuOption?.label}
                          </WpLink>
                        </WpToolTip>
                        {displayMenuOptionsHandler(row) && (
                          <div>
                            {!isViewOnlyPermission && (
                              <FontAwesomeIcon
                                className={classes.actionLink}
                                icon={faSortDown}
                                onClick={(e) => handleClick(e, row.id)}
                              />
                            )}
                          </div>
                        )}

                        {anchorId === row.id && (
                          <AssignedRolesActions
                            anchorId={anchorId}
                            open={open}
                            handleClose={handleClose}
                            menuOptions={menuOptions}
                            row={row}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              }
            },
          },
        ]
      : []),
    ...(selectedUserAccountOption === UserAccountsTypeEnum.People
      ? [
          {
            field: 'action',
            title: '',
            templateSupply: (row: any) => {
              if (row) {
                return (
                  <div className={classes.displayContent}>
                    {row.isDisabled ? (
                      <WpLink
                        onClick={() => {
                          handleDialog(DialogTypeEnum.Enable, row.id, row), setFilterCheckBox(false);
                        }}
                        disabled={isViewOnlyPermission}
                      >
                        {t('userAccounts:assignedPeopleGroup.columns.enable')}
                      </WpLink>
                    ) : (
                      <>
                        <WpLink onClick={() => handleViewClick(row)}>{t('userAccounts:rolesContainer.view')}</WpLink>
                        <div>
                          {!isViewOnlyPermission && (
                            <FontAwesomeIcon
                              className={classes.actionLink}
                              icon={faSortDown}
                              onClick={(e) => handleClick(e, row.id)}
                            />
                          )}
                        </div>
                        <div>
                          {anchorId === row.id && (
                            <Popover
                              id="edit-dropdwn"
                              anchorEl={open}
                              open
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <MenuItem onClick={() => handleDialog(DialogTypeEnum.Disable, row.id, row)}>
                                {t('userAccounts:assignedPeopleGroup.columns.disable')}
                              </MenuItem>
                            </Popover>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              }
            },
          },
        ]
      : []),
  ];

  const handleDialog = (dialog: any, rowId?: number, row?: any) => {
    setMenuActionId(dialog);
    if (row) setRowDetails(row);
    if (rowId) setSelectedRowId(rowId);
    setOpenPopup(true);

    if (
      (selectedUserAccountOption !== UserAccountsTypeEnum.People &&
        dialog == DialogTypeEnum.Enable &&
        (row.securityRoleTypeList.length
          ? row.securityRoleTypeList[0] !== SecurityRoleTypeEnum.Manager
          : roles?.securityRoleType !== SecurityRoleTypeEnum.Manager)) ||
      (selectedUserAccountOption === UserAccountsTypeEnum.People && dialog == DialogTypeEnum.Enable)
    ) {
      disableParticularPeople(false, false, rowId);
    }
  };

  const handleResendInvite = async (row: IUser) => {
    showLoader();
    try {
      const response = await MigrationOneManagerService.executeAction({
        companyId: selectedCompanyId,
        resendAdminInvite: true,
        userId: row.id,
      });
      const { hasError, message } = getApiValidationErrors(response);
      if (hasError) {
        error(message);
        return;
      }
      success(t('userAccounts:assignedPeopleGroup.columns.inviteSuccessfullySent'));
    } catch (errors: any) {
      error(getErrorMessage(errors));
    } finally {
      handleClose();
      hideLoader();
    }
  };

  const handleDepartmentSelection = (e: any) => {
    trigger();
    setDepartmentListForService(e);
  };

  const displayWpDialog = () => {
    switch (selectedUserAccountOption) {
      case UserAccountsTypeEnum.Roles:
        if (DialogTypeEnum.Disable === menuActionId)
          return (
            <WpDialog
              maxWidth="sm"
              fullWidth
              open={openPopup}
              title={t('userAccounts:assignedPeopleGroup.columns.popupDisbaleTitle', {
                name:
                  Object.keys(rowDetails).length > 0 && rowDetails.firstName
                    ? `${rowDetails.firstName} ${rowDetails.lastName}`
                    : '',
              })}
              content={
                <>
                  {rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.Manager ? (
                    <WpTypography>
                      {t('userAccounts:assignedPeopleGroup.columns.disableAdminManager', {
                        name:
                          Object.keys(rowDetails).length > 0 && rowDetails.firstName
                            ? `${rowDetails.firstName} ${rowDetails?.lastName}`
                            : '',
                      })}
                      <Box className={classes.statementContainer}>
                        <WpStatements
                          variant="highpriority"
                          message={t('userAccounts:assignedPeopleGroup.columns.disableAdminManagerStatement')}
                        />
                      </Box>
                    </WpTypography>
                  ) : rowDetails?.personType == PersonTypeEnum.Employee &&
                    (rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.AccountOwner ||
                      rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.AccountSignatory ||
                      rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.AdministratorApprover ||
                      rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.AdministratorSubmitter ||
                      rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.AccountAdministrator ||
                      rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.Administrator) ? (
                    <>
                      <WpTypography>
                        {t('userAccounts:assignedPeopleGroup.columns.disableEmployeeAdmin', {
                          name:
                            Object.keys(rowDetails).length > 0 && rowDetails.firstName
                              ? `${rowDetails.firstName} ${rowDetails.lastName}`
                              : '',
                        })}
                      </WpTypography>
                      {enabledTotalCount == 1 && (
                        <Box mt={1}>
                          <WpStatements
                            variant="highpriority"
                            message={t('userAccounts:assignedPeopleGroup.columns.disableOnlyEmployeeAdmin')}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <WpTypography>
                        {t('userAccounts:assignedPeopleGroup.columns.disableNonEmployeeAdmin', {
                          name:
                            Object.keys(rowDetails).length > 0 && rowDetails.firstName
                              ? `${rowDetails.firstName} ${rowDetails.lastName}`
                              : '',
                        })}
                      </WpTypography>
                      {enabledTotalCount == 1 && (
                        <Box mt={1}>
                          <WpStatements
                            variant="highpriority"
                            message={t('userAccounts:assignedPeopleGroup.columns.disableOnlyEmployeeAdmin')}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
              }
              actions={
                <>
                  <WpButton onClick={handleCloseDialog} disableRipple color="primary" variant="outlined">
                    {t('common:cancel')}
                  </WpButton>
                  <WpButton disableRipple onClick={disableSelection} color="primary" variant="contained" autoFocus>
                    {t('userAccounts:assignedPeopleGroup.columns.disable')}
                  </WpButton>
                </>
              }
            />
          );
        else if (DialogTypeEnum.CreateEmployeeProfile === menuActionId)
          return (
            <WpDialog
              maxWidth="sm"
              fullWidth
              open={openPopup}
              // onClose={handleClose}
              title={t('userAccounts:assignedPeopleGroup.columns.popupEmployeeProfileTitle', {
                name:
                  Object.keys(rowDetails).length > 0 && rowDetails.firstName
                    ? `${rowDetails.firstName} ${rowDetails.lastName}`
                    : '',
              })}
              content={
                <WpTypography>{t('userAccounts:assignedPeopleGroup.columns.popupEmployeeProfileContent')}</WpTypography>
              }
              actions={
                <>
                  <WpButton onClick={handleCloseDialog} disableRipple color="primary" variant="outlined">
                    {t('common:cancel')}
                  </WpButton>
                  <WpButton disableRipple onClick={disableSelection} color="primary" variant="contained" autoFocus>
                    {t('userAccounts:assignedPeopleGroup.columns.create')}
                  </WpButton>
                </>
              }
            />
          );
        else
          return (
            roles?.securityRoleType === SecurityRoleTypeEnum.Manager && (
              <WpDialog
                maxWidth="sm"
                fullWidth
                open={openPopup}
                title={t('userAccounts:assignedPeopleGroup.columns.popupEnableTitle', {
                  name:
                    Object.keys(rowDetails).length > 0 && rowDetails.firstName
                      ? `${rowDetails.firstName} ${rowDetails.lastName}`
                      : '',
                })}
                content={
                  <>
                    <WpTypography>{t('userAccounts:assignedPeopleGroup.columns.enableManager')}</WpTypography>
                    <Grid item xs={12}>
                      <FormProvider {...methods}>
                        <form>
                          <Box component="div" className={classes.dropdown}>
                            <WpDropdown
                              id="department"
                              label={t('userAccounts:assignedPeopleGroup.columns.assignDepartment')}
                              name="department"
                              menuItems={departmentList}
                              multiple
                              hasSelectAll
                              displayKey="id"
                              displayValue="name"
                              returnValue="id"
                              className={classes.dropdown}
                              errorobj={errors}
                              required
                              callBackOnChange={handleDepartmentSelection}
                            />
                          </Box>
                        </form>
                      </FormProvider>
                    </Grid>
                  </>
                }
                actions={
                  <>
                    <WpButton onClick={handleCloseDialog} disableRipple color="primary" variant="outlined">
                      {t('common:cancel')}
                    </WpButton>
                    <WpButton disableRipple onClick={enableSelection} color="primary" variant="contained" autoFocus>
                      {t('userAccounts:assignedPeopleGroup.columns.enableButton')}
                    </WpButton>
                  </>
                }
              />
            )
          );
      case UserAccountsTypeEnum.Administrator:
        if (DialogTypeEnum.Disable === menuActionId)
          return (
            <WpDialog
              maxWidth="sm"
              fullWidth
              open={openPopup}
              // onClose={handleClose}
              title={t('userAccounts:assignedPeopleGroup.columns.popupDisbaleTitle', {
                name:
                  Object.keys(rowDetails).length > 0 && rowDetails.firstName
                    ? `${rowDetails.firstName} ${rowDetails.lastName}`
                    : '',
              })}
              content={
                <>
                  {rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.Manager ? (
                    <WpTypography>
                      {t('userAccounts:assignedPeopleGroup.columns.disableAdminManager', {
                        name:
                          Object.keys(rowDetails).length > 0 && rowDetails.firstName
                            ? `${rowDetails.firstName} ${rowDetails?.lastName}`
                            : '',
                      })}
                      <Box className={classes.statementContainer}>
                        <WpStatements
                          variant="highpriority"
                          message={t('userAccounts:assignedPeopleGroup.columns.disableAdminManagerStatement')}
                        />
                      </Box>
                    </WpTypography>
                  ) : !rowDetails.isEmployee ? (
                    <>
                      <WpTypography>
                        {t('userAccounts:assignedPeopleGroup.columns.disableNonEmployeeAdmin', {
                          name:
                            Object.keys(rowDetails).length > 0 && rowDetails.firstName
                              ? `${rowDetails.firstName} ${rowDetails.lastName}`
                              : '',
                        })}
                      </WpTypography>

                      <DisabledPayrollAdminContainer
                        maintainAdminCount={maintainAdminCount}
                        currentRole={rowDetails?.securityRoleTypeList[0]}
                      />
                    </>
                  ) : (
                    rowDetails.isEmployee && (
                      <>
                        <WpTypography>
                          {t('userAccounts:assignedPeopleGroup.columns.disableEmployeeAdmin', {
                            name:
                              Object.keys(rowDetails).length > 0 && rowDetails.firstName
                                ? `${rowDetails.firstName} ${rowDetails.lastName}`
                                : '',
                          })}
                        </WpTypography>

                        <DisabledPayrollAdminContainer
                          maintainAdminCount={maintainAdminCount}
                          currentRole={rowDetails?.securityRoleTypeList[0]}
                        />
                      </>
                    )
                  )}
                </>
              }
              actions={
                <>
                  <WpButton onClick={handleCloseDialog} disableRipple color="primary" variant="outlined">
                    {t('common:cancel')}
                  </WpButton>
                  <WpButton disableRipple onClick={disableSelection} color="primary" variant="contained" autoFocus>
                    {t('userAccounts:assignedPeopleGroup.columns.disable')}
                  </WpButton>
                </>
              }
            />
          );
        else
          return (
            Object.keys(rowDetails).length > 0 &&
            rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.Manager && (
              <WpDialog
                maxWidth="sm"
                fullWidth
                open={openPopup}
                title={t('userAccounts:assignedPeopleGroup.columns.popupEnableTitle', {
                  name:
                    Object.keys(rowDetails).length > 0 && rowDetails.firstName
                      ? `${rowDetails.firstName} ${rowDetails.lastName}`
                      : '',
                })}
                content={
                  <>
                    <WpTypography>{t('userAccounts:assignedPeopleGroup.columns.enableManager')}</WpTypography>
                    <Grid item xs={12}>
                      <FormProvider {...methods}>
                        <form>
                          <Box component="div" className={classes.dropdown}>
                            <WpDropdown
                              id="department"
                              label={t('userAccounts:assignedPeopleGroup.columns.assignDepartment')}
                              name="department"
                              menuItems={departmentList}
                              multiple
                              hasSelectAll
                              displayKey="id"
                              displayValue="name"
                              returnValue="id"
                              className={classes.dropdown}
                              errorobj={errors}
                              required
                              callBackOnChange={handleDepartmentSelection}
                            />
                          </Box>
                        </form>
                      </FormProvider>
                    </Grid>
                  </>
                }
                actions={
                  <>
                    <WpButton onClick={handleCloseDialog} disableRipple color="primary" variant="outlined">
                      {t('common:cancel')}
                    </WpButton>
                    <WpButton disableRipple onClick={enableSelection} color="primary" variant="contained" autoFocus>
                      {t('userAccounts:assignedPeopleGroup.columns.enableButton')}
                    </WpButton>
                  </>
                }
              />
            )
          );

      case UserAccountsTypeEnum.People:
        if (DialogTypeEnum.Disable === menuActionId)
          return (
            <WpDialog
              maxWidth="sm"
              fullWidth
              open={openPopup}
              title={t('userAccounts:assignedPeopleGroup.columns.peoplePopUpDisableTitle', {
                name:
                  Object.keys(rowDetails).length > 0 && rowDetails.firstName
                    ? `${rowDetails.firstName} ${rowDetails.lastName}`
                    : '',
              })}
              content={
                <WpTypography>
                  {t('userAccounts:assignedPeopleGroup.columns.popupDisableContent', {
                    name:
                      Object.keys(rowDetails).length > 0 && rowDetails.firstName
                        ? `${rowDetails.firstName} ${rowDetails?.lastName}`
                        : '',
                  })}
                </WpTypography>
              }
              actions={
                <>
                  <WpButton onClick={handleCloseDialog} disableRipple color="primary" variant="outlined">
                    {t('common:cancel')}
                  </WpButton>
                  <WpButton disableRipple onClick={disableSelection} color="primary" variant="contained" autoFocus>
                    {t('userAccounts:assignedPeopleGroup.columns.disable')}
                  </WpButton>
                </>
              }
            />
          );
    }
  };

  /*
  On view results button click - Filter widget
  */
  // TODO: check this and remove if not needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFilterUpdate = (updatedFilterList: IWpFilterWidgetDropdownType[]) => {
    setFilterCheckBox(isViewDisabled ?? false);
    {
      selectedUserAccountOption === UserAccountsTypeEnum.Administrator
        ? getAdminList()
        : getUsersList({ isViewDisabled });
    }
  };

  /*
  On selection made in checkbox - Filter widget
  */
  const onViewDisabledItem = (checked: boolean) => {
    dispatch(setIsViewDisabled({ value: checked }));
  };

  /*
   On positive action button click - dialog [in case of table action column click, if action is disable/create employee profile]
  */
  const disableSelection = () => {
    setOpenPopup(false);
    setAnchorId(0);
    if (menuActionId === DialogTypeEnum.CreateEmployeeProfile) {
      createEmployeeProfile();
    } else {
      disableParticularPeople(true);
    }
  };

  /*
  On positive action button click - dialog [in case of table action column click, if action is enable]
  */

  const enableSelection = () => {
    trigger();
    setTimeout(() => {
      if (roles?.securityRoleType === SecurityRoleTypeEnum.Manager && !isDirty) return;
      if (rowDetails?.securityRoleTypeList[0] === SecurityRoleTypeEnum.Manager && !isDirty) return;
      disableParticularPeople(false, true), setOpenPopup(false);
    }, 50);
  };

  //enable/disable particular person from user list
  const disableParticularPeople = async (isDisable: boolean, fromManager?: boolean, id?: any) => {
    if (!isDisable) {
      dispatch(setIsViewDisabled({ value: false }));
      setFilterCheckBox(false);
    }
    const response = await MaintainUser({
      userId: id || selectedRowId,
      disable: isDisable,
      enable: !isDisable,
      ...(selectedUserAccountOption !== UserAccountsTypeEnum.People && { fromEmployer: true }),
      ...(selectedUserAccountOption === UserAccountsTypeEnum.People && { fromEmployee: true }),
    });
    hideLoader();
    if (response.validationResults && response.validationResults?.validationResultList.length === 0) {
      if (isDisable) {
        success(
          selectedUserAccountOption === UserAccountsTypeEnum.People
            ? t('userAccounts:assignedPeopleGroup.roles.disablePeople')
            : t('userAccounts:assignedPeopleGroup.roles.disableRole')
        );
        getDepartmentList();
      } else {
        success(
          selectedUserAccountOption === UserAccountsTypeEnum.People
            ? t('userAccounts:assignedPeopleGroup.roles.enablePeople')
            : t('userAccounts:assignedPeopleGroup.roles.enableRole')
        );
      }

      if (fromManager && departmentListForService.length > 0) {
        addPersonToManager();
      } else {
        {
          selectedUserAccountOption === UserAccountsTypeEnum.Administrator ? getAdminList(false) : getUsersList();
        }
      }
    }
  };

  const addPersonToManager = async () => {
    showLoader();
    const response: any = await AddPersonToManager({
      userId: selectedRowId,
      departmentIdList: departmentListForService,
    });
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      hideLoader();
      if (selectedUserAccountOption == UserAccountsTypeEnum.Administrator) getAdminList(false);
      else getUsersList({});
    } else {
      error(response.validationResults?.validationResultList[0].validationMessage || t('assignRole.error'));
      hideLoader();
    }
  };

  const createEmployeeProfile = async () => {
    showLoader();
    const response = await CreateEmployeeProfile({ userId: selectedRowId });
    if (response.validationResults && response.validationResults?.validationResultList.length === 0) {
      hideLoader();
      selectedUserAccountOption === UserAccountsTypeEnum.Administrator ? getAdminList() : getUsersList();
    } else {
      hideLoader();
    }
  };

  const handleViewClick = (row: any) => {
    history.push({
      pathname: `${path}/view` + `/${row.id}`,
      state: {
        from: selectedUserAccountOption,
        details: row,
        totalCount: enabledTotalCount,
      },
    });

    // TODO: remove eslint-disable and move this before history.push.
    /* eslint-disable no-restricted-syntax */
    if (selectedUserAccountOption === UserAccountsTypeEnum.Roles) {
      dispatch(
        setEditState({
          isEdit: true,
        })
      );
    }

    headerVisibility?.(false);
    /* eslint-enable no-restricted-syntax */
  };

  const handleClick = (event: any, value: any) => {
    event.preventDefault();
    setAnchorId(value);
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorId(0);
    setOpen(null);
  };

  const handleClearFilter = () => {
    setFilterCheckBox(false);
    dispatch(setIsViewDisabled({ value: false }));
    selectedUserAccountOption === UserAccountsTypeEnum.Administrator
      ? getAdminList(false)
      : getUsersList({ isViewDisabled: false });
  };

  const onCloseWidget = () => {
    dispatch(setIsViewDisabled({ value: disabledMode }));
  };

  const handleCloseDialog = () => {
    hideLoader();
    setOpenPopup(false);
    setAnchorId(0);
    // setChangePopupContent(DialogTypeEnum.NotDefined);
  };

  const gridEvents: IWpGridEvents = {
    onColumnSorting: (sortedColumns: IWpGridColumn[]) => {
      if (!sortedColumns || sortedColumns.length == 0) return;

      sortedColumns.length > 1
        ? handleSortingCol(sortedColumns?.[0]?.currentSortColumn, sortedColumns?.[0]?.sortDirection)
        : handleSortingCol(sortedColumns?.[0]?.field, sortedColumns?.[0]?.sortDirection);
    },
    onReachedEnd: () => {
      // Needs to be removed:
      // this component needs to be refactored & all controls will be kept in a single page
      // should be using react query for pagination part. but to avoid regression cycles & fix to production ASAP.
      // forward with this quick fix logic
      if (!userListLoading && usersList?.length && enabledTotalCount > usersList.length) {
        getUsersList({ page: pageStart + defaultTake, isViewDisabled, search: searchValue });
      }
    },
  };

  const handleSortingCol = (col: any, sortDirection: any) => {
    const sortObj = { col: col, sortDirection: sortDirection };
    setSortDetails(sortObj);

    if (Object.values(GetUserListSortEnum).includes(col)) {
      const direction: boolean = sortDirection === SortDirectionEnum.Ascending;

      if (col == GetUserListSortEnum[GetUserListSortEnum.Name])
        setSortValue({ sortBy: GetUserListSortEnum.Name, sortDesc: direction });

      if (col == GetUserListSortEnum[GetUserListSortEnum.EmploymentType])
        setSortValue({ sortBy: GetUserListSortEnum.EmploymentType, sortDesc: direction });

      if (col == GetUserListSortEnum[GetUserListSortEnum.Email])
        setSortValue({ sortBy: GetUserListSortEnum.Email, sortDesc: direction });

      if (col == GetUserListSortEnum[GetUserListSortEnum.Department])
        setSortValue({ sortBy: GetUserListSortEnum.Department, sortDesc: direction });

      if (col == GetUserListSortEnum[GetUserListSortEnum.LastLoggedIn])
        setSortValue({ sortBy: GetUserListSortEnum.LastLoggedIn, sortDesc: direction });
    }

    if (Object.values(GetAdminListSortEnum).includes(col)) {
      const direction = sortDirection === SortDirectionEnum.Ascending;

      if (col == GetAdminListSortEnum[GetAdminListSortEnum.Role])
        setSortValue({ sortBy: GetAdminListSortEnum.Role, sortDesc: direction });
    }
  };

  const emptyPlaceholder = (
    <WpZeroState
      illustration={<NotFound />}
      title={t('userAccounts:common.noResult.title')}
      titleVariant="h3"
      description={t('userAccounts:common.noResult.subtitle')}
      inCard={false}
    />
  );

  const disabledCondition = () => {
    return (
      isFromAlfred ||
      (roles?.securityRoleType === SecurityRoleTypeEnum.Manager && departmentList.length === 0) ||
      (roles?.securityRoleType === SecurityRoleTypeEnum.AdministratorApprover && enabledTotalCount === 2)
    );
  };

  return (
    <>
      {searchResultsCount === 0 || enabledTotalCount > 0 || disabledTotalCount > 0 ? (
        <Grid container className={classes.wrapperGrid}>
          {isDepartmentAvailable === false && (
            <Box component="div" className={classes.statements}>
              <WpStatements variant="highpriority" message={t('userAccounts:assignedPeopleGroup.columns.noDep')} />
            </Box>
          )}
          {roles?.securityRoleType === SecurityRoleTypeEnum.AdministratorApprover && enabledTotalCount == 2 && (
            <Box component="div" className={classes.statements}>
              <WpStatements
                variant="highpriority"
                message={t('userAccounts:assignedPeopleGroup.columns.noPayrollAdmin')}
              />
            </Box>
          )}

          <Grid item xs={12}>
            <Card variant="outlined" className={classes.cards}>
              <Grid container>
                <Grid container className={clsx(classes.headerContainer)}>
                  <Grid item xs={8} className={classes.filterSection}>
                    <Box component="div" className={classes.serachBoxStyles}>
                      <WpSearchBox
                        searchString={searchValue}
                        onChange={handleSearch}
                        debounceTime={300}
                        placeholder={t('userAccounts:assignedPeopleGroup.columns.search')}
                      />
                    </Box>

                    {selectedUserAccountOption !== UserAccountsTypeEnum.AccountingFirm && (
                      <WpFilterWidget
                        onCancel={onCloseWidget}
                        options={[]}
                        onApply={(updatedFilterList) => onFilterUpdate(updatedFilterList)}
                        onViewDisabledItems={onViewDisabledItem}
                        hideDropDown
                        onClearAll={handleClearFilter}
                        onViewDisabledText={t('common:viewdisableditems')}
                        filterText={t('common:filter')}
                        showResultText={t('common:showresults')}
                        clearAllText={t('common:clearall')}
                        defaultFilterCheck={isViewDisabled}
                      />
                    )}
                  </Grid>

                  {!isViewOnlyPermission && (
                    <Grid item xs={4} className={classes.assignButton}>
                      {selectedUserAccountOption !== UserAccountsTypeEnum.People &&
                        selectedUserAccountOption !== UserAccountsTypeEnum.AccountingFirm && (
                          <WpButton
                            component={Link}
                            to={`${path}/assign`}
                            disableRipple
                            variant="contained"
                            color="primary"
                            style={{ margin: 0 }}
                            disabled={disabledCondition()}
                          >
                            {selectedUserAccountOption === UserAccountsTypeEnum.Administrator
                              ? t('userAccounts:assignedPeopleGroup.columns.addAdmin')
                              : `${t('userAccounts:assignedPeopleGroup.manager.buttonContent')} ${roles.name}`}
                          </WpButton>
                        )}
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} className={classes.gridStyles}>
                  <WpGrid
                    columns={columns}
                    rows={usersList}
                    hasInfiniteScroll
                    events={gridEvents}
                    resetSortState
                    emptyPlaceholder={emptyPlaceholder}
                  />
                </Grid>
              </Grid>

              {displayWpDialog()}
            </Card>
          </Grid>
        </Grid>
      ) : (
        <ZeroStateCards isLoading={isLoading} emptyCardDetails={handleZeroStateContent()} />
      )}
    </>
  );
};

export default AssignedPeopleGroup;
