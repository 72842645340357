import { Post } from 'services/httpClient';
import {
  IGetOnboardingPreferencesListRequest,
  IGetOnboardingPreferencesListResponse,
  IGetOnboardingStepsListRequest,
  IGetOnboardingStepsListResponse,
  IGetSelfOnboardingFieldGroupListRequest,
  IGetSelfOnboardingFieldGroupListResponse,
  IPersistCurrentOnboardingStepRequest,
  IPersistCurrentOnboardingStepResponse,
  IPersistOnboardingPreferencesRequest,
  IPersistOnboardingPreferencesResponse,
  IPersistOnboardingStepStatusRequest,
  IPersistOnboardingStepStatusResponse,
} from './onboardQuestion.contracts';

const servicedomain = '/appcoreonboardquestionaccess/';
const PersistOnboardingPreferencesUrl = servicedomain + 'PersistOnboardingPreferences';
const PersistOnboardingStepStatusUrl = servicedomain + 'PersistOnboardingStepStatus';
const GetOnboardingStepsListUrl = servicedomain + 'getonboardingstepslist';
const GetOnboardingPreferencesListtUrl = servicedomain + 'getonboardingpreferenceslist';
const GetSelfOnboardingFieldGroupListUrl = servicedomain + 'getselfonboardingfieldgrouplist';
const PersistCurrentOnboardingStepUrl = servicedomain + 'PersistOnboardingCurrentStep';

export const PersistOnboardingPreferences = (
  request: IPersistOnboardingPreferencesRequest
): Promise<IPersistOnboardingPreferencesResponse> => {
  return Post(PersistOnboardingPreferencesUrl, request);
};
export const PersistOnboardingStepStatus = (
  request: IPersistOnboardingStepStatusRequest
): Promise<IPersistOnboardingStepStatusResponse> => {
  return Post(PersistOnboardingStepStatusUrl, request);
};
export const GetOnboardingStepsList = (
  request: IGetOnboardingStepsListRequest
): Promise<IGetOnboardingStepsListResponse> => {
  return Post(GetOnboardingStepsListUrl, request);
};
export const GetOnboardingPreferencesList = (
  request: IGetOnboardingPreferencesListRequest
): Promise<IGetOnboardingPreferencesListResponse> => {
  return Post(GetOnboardingPreferencesListtUrl, request);
};
export const GetSelfOnboardingFieldGroupList = (
  request: IGetSelfOnboardingFieldGroupListRequest
): Promise<IGetSelfOnboardingFieldGroupListResponse> => {
  return Post(GetSelfOnboardingFieldGroupListUrl, request);
};
export const PersistOnboardingStep = (
  request: IPersistCurrentOnboardingStepRequest
): Promise<IPersistCurrentOnboardingStepResponse> => {
  return Post(PersistCurrentOnboardingStepUrl, request);
};
