import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IEmployeeInfo,
  IGetPersonRequest,
  IGetPersonResponse,
  IPerson,
} from 'services/person-access/personAccess.contracts';
import { getPerson } from 'services/person-access/personAccess.service';

type StateItem = {
  isReady: boolean;
  isValidating: boolean;
  person: null | IPerson;
  employeeInfo: null | IEmployeeInfo;
};

type State = {
  map: Record<number, StateItem>;
};

const initialState: State = {
  map: {},
};

export const getPersonAction = createAsyncThunk<IGetPersonResponse, IGetPersonRequest>(
  'peopleAccessPersonSlice/getPersonAction',
  (request) => getPerson(request)
);

const peopleAccessPersonSlice = createSlice({
  name: 'peopleAccessPersonSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonAction.pending, (state, action) => {
        const id = action.meta.arg.personId;

        if (state.map[id]) {
          state.map[id].isValidating = true;
        } else {
          state.map[id] = {
            isReady: false,
            isValidating: true,
            person: null,
            employeeInfo: null,
          };
        }
      })
      .addCase(getPersonAction.fulfilled, (state, action) => {
        const id = action.meta.arg.personId;
        state.map[id].isReady = true;
        state.map[id].isValidating = false;
        state.map[id].person = action.payload.person;
        state.map[id].employeeInfo = action.payload.employeeInfo;
      });
  },
});

export const peopleAccessPersonReducer = peopleAccessPersonSlice.reducer;
