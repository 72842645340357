import * as utils from 'util/utility';
import { localizeTZ } from 'util/utility';
import format from 'date-fns/format';
import { TimeZoneTypeEnum, TimeZoneTypeEnumMappedToStringValues } from 'services/shared/timeZoneTypeEnum';

export function useFormatDate(format: string) {
  return (date: string | Date) => utils.formatDate(date, format);
}

export function useFormatDateByRemovingTZ(dateFormat: string) {
  return (date: string | Date) => format(localizeTZ(date), dateFormat);
}

export function useConvertTZ(dateFormat: string) {
  return (date: string | Date, timeZonePreference: TimeZoneTypeEnum = 0) => {
    const tzString =
      TimeZoneTypeEnumMappedToStringValues[
        TimeZoneTypeEnum[timeZonePreference] as keyof typeof TimeZoneTypeEnumMappedToStringValues
      ];
    return format(new Date(new Date(date).toLocaleString('en-US', { timeZone: tzString })), dateFormat);
  };
}
