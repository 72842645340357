import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IContentLayoutProps, WpContentLayout } from '@wagepoint/ui-toolkit/components/wp-layout/wpContentLayout';

const useStyles = makeStyles({
  contentLayout: {
    height: '100%',
  },
});

const AppContentWrapper: FC<IContentLayoutProps> = ({ customClass, children, ...props }) => {
  const classes = useStyles();

  return (
    <WpContentLayout customClass={clsx(customClass, classes.contentLayout)} {...props}>
      {children}
    </WpContentLayout>
  );
};

export default React.memo(AppContentWrapper);
