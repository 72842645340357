export type FieldKey = string;
export type FieldKeyValue = string | number | Array<string | number> | null;
export interface IProps {
  cardValue: number;
  fieldValues: Record<FieldKey, FieldKeyValue>;
  fieldOnChange: (e: React.ChangeEvent, newProps: any) => void;
  errors: any;
  confirmPayShow: boolean;
  setValue?: any;
  watch?: any;
  allQuestionValues?: any;
}
export enum CardTypesEnum {
  guidedOnboard = 2,
  gridOnboard,
  importOnboard,
}
export enum onBoardingEnum {
  basicInformation = 1,
  jobDetails,
  taxInformation,
  incomeDeducationsAndBenefits,
  priorPayrolls,
  finalReview,
}
export enum updateProvinceEnum {
  basicInfo = 1,
  jobDetails,
  taxInfo,
  finalReview,
}
export enum onBoardingStringEnum {
  basicInformation = 'basicInformation',
  jobDetails = 'jobDetails',
  taxInformation = 'taxInformation',
  incomeDeducationsAndBenefits = 'incomeDeducationsAndBenefits',
  priorPayrolls = 'priorPayrolls',
  finalReview = 'finalReview',
}
export enum updateProvinceStringEnum {
  basicInformation = 'basicInformation',
  jobDetails = 'jobDetails',
  taxInformation = 'taxInformation',
  finalReview = 'finalReview',
}
export enum IDBStringEnum {
  income = 'income',
  deduction = 'deduction',
  benefit = 'benefit',
}
export enum PersonStatusTypeEnum {
  undefined = 0,
  notDefined,
  active,
  terminated,
  onleave,
}

export enum VendorTypeEnum {
  undefined = 0,
  business,
  individual,
}
export interface ITabPanelProps {
  children?: React.ReactNode;
  className: string;
  index: any;
  value: any;
}
export interface IDashboardELemProps {
  dashboardPath?: string;
  pathName?: string;
  companyId?: number;
  setShowButton?: (props: boolean) => void;
  unsetHeading?: (props: boolean) => void;
  enablePopupObj?: Record<any, boolean>;
  setEnablePopupObj?: (props: Record<any, boolean>) => void;
  setEdit?: (props: boolean) => void;
  edit?: boolean;
  isFTU?: boolean;
  renderThisComponentFor?: string;
}
export interface IAllProps {
  [k: string]: any;
}

export interface ITooltipValues {
  title: string;
  placement?: 'bottom-start';
}
export interface IOnboardSubElemProps {
  errors: Record<any, any>;
  setYupSchema?: (yupSchema: Record<any, any>) => void;
  setValue?: any;
  register?: any;
  getValues?: any /* For testing if values populated */;
  reset?: (value?: any) => void;
  countryId?: number | undefined;
  companyId?: number | undefined;
  primaryProvince?: Record<any, any>;
  provinceList?: Record<any, any>[];
  watchValue?: any;
  handleOnboarding?: (onboardStatus: onboardStatusEnum) => void;
  employeeStatus?: Record<any, any>;
  selfOnboardingArr?: any[];
  handlePersistData?: (data: IAllProps) => void;
  dataPropSaved?: IAllProps;
  isRehire?: boolean;
  hasPersonBeenPaid?: boolean;
  isValidPayroll?: boolean;
  provinceName?: string;
  nextProvince?: any;
  person?: any;
}
export enum OnboardListTypeEnum {
  employementTypeIdList = 2,
  employeeStatusTypeIdList,
  havePaidThisYear,
  isStaffOnboardingList,
  isStaffSelfOnboardingEnabled,
}
export enum OnboardFieldGroupListEnum {
  personal = 1,
  sin,
  taxinfo,
  bankDD,
}
export enum SelfOnboardingGroupEnum {
  dateOfBirth = 1,
  middleName,
  preferredName,
  phoneNumber,
  homeAddress,
  addressLine2,
  city,
  provinceOfResidence,
  postalCode,
  emergencyContactFullName,
  emergencyContactPhoneNumber,
  emergencyContactEmail,
  socialInsuranceNumber,
  bankName,
  institutionNumber,
  branchNumber,
  accountNumber,
  accountType,
  amount,
  federalTD1Code,
  provincialTD1Code,
}
export const duplicateRecordFound = 1;
export enum onBoardIsStaffSelfTypeEnum {
  selfSetupBasic = 1,
  selfSetupSocial,
  selfSetupDeposit,
  selfSetupTax,
}
export enum yesNoNumberEnum {
  no = 0,
  yes,
}
export enum taxInfoSettingsType {
  standard = 0,
  indianstatus,
  clergy,
  soleproprietor,
}
/* Sidebar control user START */
export enum onboardStatusEnum {
  notDefined = 0,
  skip,
  partiallyCompleted,
  success,
  active,
}
export interface IPeopleDashboardPopover {
  openElem: null | HTMLElement;
  isOpen: boolean;
  companyId: number;
  handleClose: () => void;
  handleOpenSlideChangeStatus: () => void;
  setOpenRehire: (isOpen: boolean) => void;
  setOpenReturnDate: (isOpen: boolean) => void;
  setSelectedRowData: (rows: Record<any, any>) => void;
  enableSinglePerson: (personId: number) => void;
  openDisablePopup: (companyFieldId: any, singlename: string) => void;
  row: any;
  isEITaxSettingToggleOn?: boolean;
  isFTU?: boolean;
}
export interface IPersonSummeryProps {
  isOpen: boolean;
  setOpen?: (openParam: boolean) => void;
  path?: any;
  sidebarType?: boolean;
  backToLink?: boolean;
  personData?: Record<any, any>;
  personId?: number;
  changeStatusProps?: {
    onRemoveSuccess: () => void;
    onStatusChangeSuccess: () => void;
  };
}
export interface IOnBoarding {
  id: number;
  name: string;
  description: string;
  status?: onboardStatusEnum;
  originalstatus?: onboardStatusEnum /* Please NOTE: always set with original data, and use this for always resetting status mainly success/skipped status */;
}
export type SidebarLayout = {
  /* Wonder why you are here, search in "peopleContainer.jsx" to track back all this function assigned, for eg: setOnBoardingListFn */
  showSidebar: (sidbarType: boolean) => void;
  sidebarVal: boolean;
  setOnBoardingList?: (menuId: number, status: onboardStatusEnum) => boolean;
  updateListFn?: (newList: any, setOriginalStatus?: boolean) => void;
  onBoardinglist?: Array<IOnBoarding>;
  changeListFn?: (newList: any) => void;
  reset?: () => void;
};
/* Sidebar control user END */
export const getEnumKey = (SomeEnum: any | unknown, enumVal: number) => {
  const filteredVal = Object.keys(SomeEnum).filter((x) => !(parseInt(x) >= 0));
  let returnKey = '';
  filteredVal.map((value) => {
    if (SomeEnum[value] == enumVal) {
      returnKey = value;
    }
  });
  return returnKey;
};

export const getEnumVal = (SomeEnum: any | unknown, key: string) => {
  return SomeEnum[key as keyof typeof SomeEnum];
};

export const MaskCharacter = (str: string, mask: string, n = 1) => {
  // Slice the string and replace with
  // mask then add remaining string
  return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
};

export const convertCammelToText = (text: string) => {
  /* This will convert all the caps as a spaced text */
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
export const convertTextToLower = (text: string) => {
  /* only converting to lower nothing else */
  const finalResult = text.toLowerCase();
  return finalResult;
};

export enum assignUpdateEnum {
  income = 'income',
  deduction = 'deduction',
  benefit = 'benefit',
  jobtitle = 'jobtitle',
  department = 'department',
  workerscompensation = 'workerscompensation',
}

export type SnackType = {
  open: boolean;
  snackType?: number;
  message: string;
};

export enum filterOptionEnum {
  employeeType = 1,
  status,
  location,
  department,
  payGroup,
}

export enum SelectListValFilterEnum {
  PersonTypeList = 1,
  EmployeeStateTypeList,
  CompanyLocationIdList,
  DepartmentIdList,
  PayGroupIdList,
}

export enum URLPathEnum {
  jobtitles = 'jobTitles',
  department = 'department',
}
