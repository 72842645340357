import startOfDay from 'date-fns/startOfDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import lastDayOfYear from 'date-fns/lastDayOfYear';

export const isWeekend = (date: any) => {
  const day = new Date(date).getDay();
  return day === 0 || day === 6;
};

const validateWeekInput = (value: any) => {
  if (value && value?.toString() !== 'Invalid Date') {
    const isWeek = isWeekend(value);
    return !isWeek;
  }
  return true;
};

const validateMinMax = (value: any, start?: any, end?: any) => {
  let isBeforeDate = false;
  let afterDate = true;
  if (value && value?.toString() !== 'Invalid Date') {
    if (start) isBeforeDate = isBefore(new Date(value), startOfDay(start));
    if (end) afterDate = isAfter(new Date(value), start);
  }
  return { afterDate, isBeforeDate };
};

export const useDateValidations = () => {
  const { t } = useTranslation(['common']);
  const { feature } = useFeatureFlag();
  const max2024 = feature(FEATURE_FLAG_KEYS.Enable2024Dates);
  const weekendSchema = (customMessage?: string) =>
    yup.string().test('is-weekend', customMessage || t('common:invalidDate'), function (value) {
      return validateWeekInput(value);
    });

  const minMaxSchema = ({
    minDate,
    maxDate,
    minErrorText,
    maxErrorText,
  }: {
    minDate?: string | Date;
    maxDate?: string | Date;
    minErrorText?: string;
    maxErrorText?: string;
  }) =>
    yup.string().test('is-minMax' || t('common:invalidDate'), function (value) {
      const { afterDate, isBeforeDate } = validateMinMax(value, minDate, maxDate);
      const maxDateyear = max2024 ? false : value && isAfter(new Date(value), lastDayOfYear(new Date()));
      if (!afterDate) return this.createError({ message: minErrorText || t('common:invalidDate'), path: this.path });
      if (isBeforeDate || maxDateyear)
        return this.createError({ message: maxErrorText || t('common:invalidDate'), path: this.path });
      return true;
    });

  return {
    weekendSchema,
    minMaxSchema,
  };
};
