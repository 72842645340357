import React, { useEffect, useState } from 'react';

import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import Grid from '@material-ui/core/Grid';

import { RadioCheckTypeEnum, WpRadioCheckNew } from '@wagepoint/ui-toolkit/components/wp-radioCheck-new';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import WpToolTip from '@wagepoint/ui-toolkit/components/wp-tooltip/wpToolTip';

import { GetUserList } from 'services/security-access/securityAccess.service';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';

import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import WpIcon from '@wagepoint/ui-toolkit/components/wp-icon';
import FormRadio from '@wagepoint/ui-toolkit/components/wp-formradio/wpFormRadio';
import infoCircleSolid from '@wagepoint/ui-toolkit/components/wp-icon/icons/info-circle-solid';

import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { selectAccountingAssignedRole, selectedRoleForAccountingFirm } from 'redux/slice/userAccountsSlice';
import { selectCompanyId } from 'redux/slice/configSlice';
import { selectCurrentRole, selectFirmView } from 'redux/slice/accountingFirmSlice';

import { ManageViewOfAccountingFirm } from '../../index';
import { RolesRadioGroupStyles } from './rolesRadioGroupStyles';
import TwoStepStatement from './twoStepStatement';
import { useFeatureFlag } from '@wagepoint/ui-shared-library/shared-system/functions/launch-darkly';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { useTwoStepEnabled } from 'pages/settings/components/useraccounts/permissionSettingsHook';

interface IProps {
  role?: number;
  firm?: string;
  fromConfigureRoles?: boolean;
  onValidation?: boolean;
  isTheOnlySubmitter?: boolean;
}

const roleButtonsGroupName = 'radio-buttons-group';
const zeroStateRolesName = 'roleTypeRadioCheck';

const RolesRadioGroupSection: React.VFC<IProps> = ({
  role,
  firm,
  fromConfigureRoles,
  onValidation,
  isTheOnlySubmitter,
}) => {
  const classes = RolesRadioGroupStyles();
  const dispatch = useAppDispatch();
  const assignedRole = useAppSelector(selectAccountingAssignedRole);
  const companyId = useAppSelector(selectCompanyId);
  const firmView = useAppSelector(selectFirmView);
  const currentRole = useAppSelector(selectCurrentRole);

  const { isTwoStepApprovalEnabled } = useTwoStepEnabled();

  const { t } = useTranslation(['userAccounts']);
  const [displayRoleStatement, setRoleStatement] = useState(false);
  const [payrollAdminCount, setPayrollAdminCount] = useState(0);
  const [showPayrollAdminStatement, setShowPayrollAdminStatement] = useState(false);

  const { errors = {} } = useFormContext() ?? 0;
  const methods = useForm();

  const { feature } = useFeatureFlag();
  const isTwoStepApprovalForParticularClientFeature = feature(FEATURE_FLAG_KEYS.ShowTwoStepApprovalForParticularClient);

  /* To check 2 step is enabled or not */
  useEffect(() => {
    getAdminCount();
  }, []);

  const configureRoles = () => {
    if (firmView === ManageViewOfAccountingFirm.ZeroState) return;

    return (
      <Grid item xs={12}>
        <WpStatements variant="highpriority" message={t('common.configureRoles')} />
      </Grid>
    );
  };

  const getAdminCount = async () => {
    const response = await GetUserList({
      securityRoleType: SecurityRoleTypeEnum.Administrator,
      skip: 0,
      take: 100,
      companyId,
    });
    setPayrollAdminCount(response?.userList?.length ?? 0);
  };

  const configureRoleDataList = [
    {
      value: SecurityRoleTypeEnum.Administrator,
      ...(fromConfigureRoles &&
        payrollAdminCount <= 1 &&
        role === SecurityRoleTypeEnum.Administrator && {
          hoverMessage: t('common.payrollAdminTooltip', {
            name: firm,
          }),
        }),

      label: <WpTypography>{t('common.payrollAdmin')}</WpTypography>,
      afterLabel: (
        <WpToolTip aria-multiline={true} title={t('common.payrollAdminHover')} arrow={true} placement="bottom-start">
          <Grid className={classes.infoIcon}>
            <WpIcon svgIcon={infoCircleSolid} />
          </Grid>
        </WpToolTip>
      ),
    },
    {
      value: SecurityRoleTypeEnum.ReportsManager,
      label: <WpTypography>{t('common.reportAdmin')}</WpTypography>,
      afterLabel: (
        <WpToolTip aria-multiline title={t('common.reportAdminHover')} arrow>
          <Grid className={classes.infoIcon}>
            <WpIcon svgIcon={infoCircleSolid} />
          </Grid>
        </WpToolTip>
      ),
    },
  ];

  const handleConfigureRoleForConnectedPartner = (selectedRole: SecurityRoleTypeEnum) => {
    if (currentRole !== selectedRole) setRoleStatement(true);
    else setRoleStatement(false);

    if (
      currentRole === SecurityRoleTypeEnum.ReportsManager &&
      selectedRole === SecurityRoleTypeEnum.Administrator &&
      isTwoStepApprovalEnabled &&
      isTwoStepApprovalForParticularClientFeature
    )
      setShowPayrollAdminStatement(true);
    else setShowPayrollAdminStatement(false);

    dispatch(
      selectedRoleForAccountingFirm({
        assignedRole: selectedRole,
      })
    );
  };

  return (
    <>
      {showPayrollAdminStatement && <WpStatements variant="warning" message={t('common.2saReportAdminSelected')} />}

      <WpTypography variant="p1Bold">{t('common.whatRole')}</WpTypography>

      {firmView === ManageViewOfAccountingFirm.ZeroState || onValidation ? (
        <Controller
          name={zeroStateRolesName}
          render={(field) => (
            <FormRadio
              name={zeroStateRolesName}
              errorobj={errors}
              list={[
                {
                  value: SecurityRoleTypeEnum.Administrator,
                  name: t('common.payrollAdmin'),
                  info: t('common.payrollAdminHover'),
                },
                {
                  value: SecurityRoleTypeEnum.ReportsManager,
                  name: t('common.reportAdmin'),
                  info: t('common.reportAdminHover'),
                },
              ]}
              value={field.value}
              onChange={(_, data) => {
                const role = Number(data);
                field.onChange(role);
                dispatch(
                  selectedRoleForAccountingFirm({
                    assignedRole: role,
                  })
                );
              }}
            />
          )}
        />
      ) : (
        <Grid container>
          <FormProvider {...methods}>
            <Grid item xs={8}>
              <WpRadioCheckNew
                elementType={RadioCheckTypeEnum.radioButton}
                dataList={configureRoleDataList}
                defaultValue={role ? role : assignedRole}
                name={roleButtonsGroupName}
                fixedToolTip
                callBackOnChange={({ target }: any) => handleConfigureRoleForConnectedPartner(Number(target.value))}
              />
            </Grid>
            {displayRoleStatement && !isTwoStepApprovalForParticularClientFeature && configureRoles()}
            <TwoStepStatement
              firmName={firm}
              fieldToWatch={roleButtonsGroupName}
              isTheOnlySubmitter={isTheOnlySubmitter}
            />
          </FormProvider>
        </Grid>
      )}
    </>
  );
};

export default RolesRadioGroupSection;
