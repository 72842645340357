import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';

interface Row {
  isEmployee: boolean;
}

interface Roles {
  securityRoleType: SecurityRoleTypeEnum;
}

function createEmployeeProfile(row: Row, roles: Roles) {
  return (
    !row.isEmployee &&
    (roles.securityRoleType === SecurityRoleTypeEnum.Administrator ||
      roles.securityRoleType === SecurityRoleTypeEnum.AccountSignatory ||
      roles.securityRoleType === SecurityRoleTypeEnum.ReportsManager)
  );
}

export default createEmployeeProfile;
