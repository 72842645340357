import { useCallback } from 'react';

import { useAppDispatch } from 'redux/hooks/hook';
import { success, error } from 'redux/slice/snackbarSlice';

export function useSnackbar() {
  const dispatch = useAppDispatch();

  const showSuccess = useCallback((message: string) => dispatch(success({ message })), [dispatch]);
  const showError = useCallback((message: string) => dispatch(error({ message })), [dispatch]);

  return {
    success: showSuccess,
    error: showError,
  };
}
