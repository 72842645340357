import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CommonContainer from '../common/company-email-layout/companyIdOrEmailLayout';
import { ManageViewOfAccountingFirm } from '../index';
import { useTranslation } from 'react-i18next';
import WpHeader from '@wagepoint/ui-toolkit/components/wp-header/wpHeader';

const ZeroState: React.FC = () => {
  const { t } = useTranslation(['userAccounts']);

  return (
    <>
      <WpHeader title={t('zeroState.title')} description={t('zeroState.subTitle')} />
      <Card>
        <CardContent>
          <CommonContainer from={ManageViewOfAccountingFirm.ZeroState} />
        </CardContent>
      </Card>
    </>
  );
};

export default ZeroState;
