import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IGetSalaryHistoryListRequest,
  IPersonSummary,
  ISalaryHistory,
} from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.contracts';
import { IEmployeeInfo, IPerson, IPersonLite } from 'services/person-access/personAccess.contracts';

import { IBankAccount } from 'services/person-bank-access/personBankAccess.contracts';

/* Payroll START */
import { IPayGroupSummary } from 'services/paygroup-access/paygroupAccess.contracts';
/* Payroll END */
/* Job Access START */
import { IDepartment, IJob, IVacation } from 'services/job-access/jobAccess.contracts';
import { IWcbRateSummary } from 'services/workers-compensation-access/workersCompensationAccess.contracts';
/* Job Access END */
/* Person Tax START */
import { ITaxClaimCode, ITaxDetail, ITaxSchemeType } from 'services/person-tax-access/personTaxAccess.contracts';
import { peopleOnboarding } from './peopleExtraSliceFn/peopleOnboarding';
import { peopleDashboard } from './peopleExtraSliceFn/peopleDashboard';
/* Person Tax END */
/* Onboarding First Time Users  START*/
import {
  EmployeeOnboardingStepTypeEnum,
  IOnboardFieldGroup,
  IOnboardingStepType,
  IPersistOnboardingPreferencesRequest,
  IQuestion,
} from 'services/onboard-question-access/onboardQuestion.contracts';
/* Onboarding First Time Users  END*/
/* Person IDB START */
import {
  ICompanyFieldSummary,
  ISalesTax,
  IValidateEmployeeFieldAssignmentResponse,
} from 'services/employee-field-access/employeeFieldAccess.contracts';
import {
  IChangePersonStatusResponse,
  IField,
  IGetPayPeriodDetailResponse,
  IPayPeriodSummary,
} from 'services/priorpayroll-access/priorpayrollAccess.contracts';
import { ILocation } from 'services/company-access/companyAccess.contracts';
import { IValidationResults } from 'services/shared/validationResults.contracts';

/* Person IDB END */
interface RehireData {
  hireDate?: any;
  startDate?: any;
  endDate?: any;
  midCycleHours?: any;
  midCycleDays?: any;
  employementType?: number;
  isMidCycleHire?: boolean;
}
export interface IPeopleState {
  personId?: number;
  person?: Partial<IPerson>;
  personSummeryList?: IPersonSummary[];
  personSummeryListCount?: number;
  canCreateUserAccount?: boolean;
  employeeInfo?: Partial<IEmployeeInfo>;
  bankAccountList?: Array<IBankAccount>;
  bankDDSaved?: boolean;
  error?: null;
  loading?: boolean;
  loadingOnBoard?: boolean;
  loadingImg?: boolean;
  nextOnboard?: boolean;
  wcbLoading?: boolean;
  previousProvinces?: number[];
  payGroupSummaryList?: IPayGroupSummary[];
  jobList?: Array<IJob>;
  jobSummeryList?: Array<IJob>;
  departmentList?: Array<IDepartment>;
  departmentSummeryList?: Array<IDepartment>;
  wcbList?: Array<IWcbRateSummary>;
  personJobList?: any[];
  personLightList?: Array<IPersonLite>;
  vacation?: IVacation;
  validationResults?: IValidationResults;
  snackBarMessage?: string | any[];
  open?: boolean;
  onBoardQuestionLists?: IQuestion[];
  taxSchemeTypeList?: ITaxSchemeType[];
  getTaxDetails?: ITaxDetail;
  taxCodeList?: {
    taxCode?: ITaxClaimCode[];
    stateTaxCode?: ITaxClaimCode[];
  };
  EmployeeFieldList?: {
    jobId: number;
    jobName?: string;
    income?: ICompanyFieldSummary[];
    deduction?: ICompanyFieldSummary[];
    benefit?: ICompanyFieldSummary[];
  }[];
  fieldListDashboard?: any[];
  EmployeeFieldSummaryList?: {
    jobId: number;
    jobName?: string;
    income?: ICompanyFieldSummary[];
    deduction?: ICompanyFieldSummary[];
    benefit?: ICompanyFieldSummary[];
  }[];
  EmployeePayrollDataList?: any[];
  workStateId?: number;
  personTypeGuidedOnboarding?: number;
  manageDisabledTrue?: boolean;
  personActiveFieldList?: Partial<IValidateEmployeeFieldAssignmentResponse>;
  personFieldSummeryListDashboard?: any[];
  onboardingPreferencesData?: IPersistOnboardingPreferencesRequest;
  onboardingSteplist?: IOnboardingStepType[];
  payPeriodDetail?: IGetPayPeriodDetailResponse;
  payGroupName?: string;
  paygroupHoursPerPay?: number;
  fieldGroupList?: IOnboardFieldGroup[];
  fieldGroupAllList?: IOnboardFieldGroup[];
  skipStep?: boolean;
  salHistoryParam?: Partial<IGetSalaryHistoryListRequest>;
  salaryHistoryList?: Partial<ISalaryHistory>[];
  vacationBalance?: number;
  filterDashboard?: any;
  payPeriodSummaryList?: IPayPeriodSummary[];
  changePersonResponseState?: Partial<IChangePersonStatusResponse>;
  empStatusListArr?: IField[];
  empStatusListByParams?: Record<string, IField[] | undefined>;
  rehireProcess?: EmployeeOnboardingStepTypeEnum | null;
  rehireData?: RehireData;
  isNoFirstTimeUser?: boolean;
  saleTaxList?: Array<ISalesTax>;
  preferenceDataPersisted?: any;
  locationLists?: ILocation[];
  workLocationList?: any[];
  hasInvited?: boolean;
}

const initialState: IPeopleState = {
  personId: 0,
  workLocationList: [],
  onboardingPreferencesData: undefined,
  person: undefined,
  personSummeryList: undefined,
  personSummeryListCount: 0,
  employeeInfo: {},
  bankAccountList: [],
  bankDDSaved: false /* Set to true, because personId if already fetched then only need to getPerson and getDD */,
  error: null,
  loading: false,
  wcbLoading: false,
  nextOnboard: false,
  loadingOnBoard: false,
  previousProvinces: [],
  payGroupSummaryList: [],
  jobList: [],
  jobSummeryList: undefined,
  departmentList: undefined,
  departmentSummeryList: undefined,
  wcbList: [],
  personJobList: undefined,
  personLightList: undefined,
  vacation: {},
  validationResults: undefined,
  snackBarMessage: '',
  open: false,
  onBoardQuestionLists: [],
  preferenceDataPersisted: undefined,
  taxSchemeTypeList: [],
  getTaxDetails: {},
  taxCodeList: { taxCode: undefined, stateTaxCode: undefined },
  EmployeeFieldList: [],
  EmployeeFieldSummaryList: [],
  EmployeePayrollDataList: [],
  workStateId: undefined,
  personTypeGuidedOnboarding: 0,
  manageDisabledTrue: false,
  fieldListDashboard: undefined,
  personActiveFieldList: undefined,
  personFieldSummeryListDashboard: undefined,
  payPeriodDetail: undefined,
  onboardingSteplist: undefined,
  payGroupName: undefined,
  paygroupHoursPerPay: undefined,
  fieldGroupList: undefined,
  fieldGroupAllList: undefined,
  skipStep: false,
  salHistoryParam: undefined,
  salaryHistoryList: undefined,
  vacationBalance: undefined,
  filterDashboard: false,
  payPeriodSummaryList: undefined,
  changePersonResponseState: undefined,
  empStatusListArr: undefined,
  rehireProcess: undefined,
  isNoFirstTimeUser: false,
  saleTaxList: undefined,
  locationLists: undefined,
  hasInvited: false,
};

/* Prior Payroll END */
const peopleSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    resetValidationMsg: (state, action: PayloadAction<boolean>) => {
      if (action?.payload) {
        state.validationResults =
          undefined; /* Emptying/resetting ValidationResults for the validation to work based on this */
        state.snackBarMessage = '';
      }
    },
    updatePersonDetails: (state, action: PayloadAction<IPeopleState>) => {
      if (action?.payload?.personId) {
        /* If "updatePersonDetails" with personId means the person ID is of already created Person, So setting bankDDSaved to true */
        state.bankDDSaved = true;
      }
      state.personId = action?.payload?.personId;
      state.person = action?.payload?.person ?? {};
    },
    updateInvitePopupStatus: (state, action: PayloadAction<{ value: boolean }>) => {
      state.hasInvited = action.payload.value;
    },
    updateManageDisabledTrue: (state, action: PayloadAction<boolean>) => {
      /* using update loading adjust loading */
      state.manageDisabledTrue = action?.payload ?? false;
    },
    updateSalaryHistoryParam: (state, action: PayloadAction<Partial<IGetSalaryHistoryListRequest> | undefined>) => {
      state.salHistoryParam = action.payload;
    },
    updateFilterDashboard: (state, action: PayloadAction<any | undefined>) => {
      state.filterDashboard = action.payload;
    },
    updateNotFirstTimeUser: (state, action: PayloadAction<boolean>) => {
      state.isNoFirstTimeUser = action.payload;
    },
    setPersonSummaryData: (state, action: PayloadAction<any>) => {
      state.personSummeryList = action.payload.personSummaryList;
      state.personSummeryListCount = action.payload.totalCount;
      state.canCreateUserAccount = action.payload.canCreateUserAccount;
    },
    resetStatusChangeValues: (state) => {
      state.empStatusListArr = undefined;
      state.changePersonResponseState = undefined;
    },
    resetAssignUpdateIDB: (state) => {
      state.fieldListDashboard = undefined;
      state.personLightList = undefined;
      state.personFieldSummeryListDashboard = undefined;
      state.personActiveFieldList = undefined;
    },
  },
  extraReducers: (builder) => {
    peopleOnboarding(builder);
    peopleDashboard(builder);
  },
});

export const {
  resetValidationMsg,
  updatePersonDetails,
  updateManageDisabledTrue,
  updateSalaryHistoryParam,
  updateFilterDashboard,
  updateNotFirstTimeUser,
  resetStatusChangeValues,
  resetAssignUpdateIDB,
  updateInvitePopupStatus,
  setPersonSummaryData,
} = peopleSlice.actions;
export const peopleReducer = peopleSlice.reducer;
export const selectPeople = (state: RootState) => state.peopleReducer;
export const selectPeopleEmpStatusListByParams = (state: RootState) => state.peopleReducer?.empStatusListByParams;
export const selectPeoplePersonJobList = (state: RootState) => state.peopleReducer?.personJobList;
export const selectPeopleVacationBalance = (state: RootState) => state.peopleReducer?.vacationBalance;
export const selectPeopleVacation = (state: RootState) => state.peopleReducer?.vacation;
export const selectGetTaxDetails = (state: RootState) => state.peopleReducer?.getTaxDetails;
export const selectSalHistoryParam = (state: RootState) => state.peopleReducer?.salHistoryParam;
export const selectSalaryHistoryList = (state: RootState) => state.peopleReducer?.salaryHistoryList;
export const selectPeoplePayGroupName = (state: RootState) => state.peopleReducer?.payGroupName;
export const selectPeoplePerson = (state: RootState) => state.peopleReducer?.person;
export const selectPeopleEmployeeInfo = (state: RootState) => state.peopleReducer?.employeeInfo;
export const selectPeoplePersonSummeryList = (state: RootState) => state.peopleReducer.personSummeryList;
export const selectDepartmentSummeryList = (state: RootState) => state.peopleReducer.departmentSummeryList;
export const selectManageDisabledTrue = (state: RootState) => state.peopleReducer.manageDisabledTrue;
export const selectJobSummeryList = (state: RootState) => state.peopleReducer.jobSummeryList;
export const selectPayPeriodDetail = (state: RootState) => state.peopleReducer?.payPeriodDetail;
export const selectRehireData = (state: RootState) => state.peopleReducer?.rehireData;
export const selectFieldListDashboard = (state: RootState) => state.peopleReducer?.fieldListDashboard;
export const selectPersonActiveFieldList = (state: RootState) => state.peopleReducer?.personActiveFieldList;
export const selectPersonFieldSummeryListDashboard = (state: RootState) =>
  state.peopleReducer?.personFieldSummeryListDashboard;
export const selectEmpStatusListArr = (state: RootState) => state.peopleReducer?.empStatusListArr;
export const selectChangePersonResponseState = (state: RootState) => state.peopleReducer?.changePersonResponseState;
export const selectJobList = (state: RootState) => state.peopleReducer?.jobList;
export const selectWcbList = (state: RootState) => state.peopleReducer?.wcbList;

export const selectPeoplePersonSummeryListCount = (state: RootState) => state.peopleReducer.personSummeryListCount;
export const selectPeopleLoading = (state: RootState) => state.peopleReducer.loading;
export const selectPeoplePersonLightList = (state: RootState) => state.peopleReducer.personLightList;
export const selectPeopleDepartmentList = (state: RootState) => state.peopleReducer.departmentList;
export const selectPeoplePayPeriodSummaryList = (state: RootState) => state.peopleReducer.payPeriodSummaryList;
export const selectPeopleIsNoFirstTimeUser = (state: RootState) => state.peopleReducer.isNoFirstTimeUser;
export const selectPeopleValidationResults = (state: RootState) => state.peopleReducer.validationResults;
export const selectPeopleSnackBarMessage = (state: RootState) => state.peopleReducer.snackBarMessage;
export const selectPeopleFilterDashboard = (state: RootState) => state.peopleReducer.filterDashboard;
export const getUserCanCreateAccount = (state: RootState) => state.peopleReducer.canCreateUserAccount;
export const selectLocationLists = (state: RootState) => state.peopleReducer.locationLists;
export const selectDepartmentList = (state: RootState) => state.peopleReducer.departmentList;
export const getInvitePopupStatus = (state: RootState) => ({
  hasInvited: state.peopleReducer.hasInvited,
});

export type PeopleType = ReturnType<typeof selectPeople>;
