import React from 'react';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { useTranslation } from 'react-i18next';
import { CompanySummaryInterface } from 'services/finance/models/access/companyAccess';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import Grid from '@material-ui/core/Grid';

interface IProps {
  item: CompanySummaryInterface;
}

type AdminRole = SecurityRoleTypeEnum.Administrator | SecurityRoleTypeEnum.ReportsManager;

const DisplayRolesContainer: React.FC<IProps> = ({ item }) => {
  const { t } = useTranslation(['userAccounts']);

  const roles = {
    [SecurityRoleTypeEnum.NotDefined]: '',
    [SecurityRoleTypeEnum.Administrator]: t('transferOwnership.payrollAdministrator'),
    [SecurityRoleTypeEnum.ReportsManager]: t('transferOwnership.reportsAdmin'),
  };

  const description = {
    [SecurityRoleTypeEnum.NotDefined]: '',
    [SecurityRoleTypeEnum.Administrator]: t('common.payrollAdminHover'),
    [SecurityRoleTypeEnum.ReportsManager]: t('common.reportAdminHover'),
  };

  return (
    <>
      <Grid item xs={12}>
        <div>
          <WpTypography variant="p1Bold" display="inline">
            {t('firmInviteSent.role')}
          </WpTypography>
          <WpTypography display="inline">{roles[item.securityRoleTypeId as AdminRole]}</WpTypography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <WpTypography>{description[item.securityRoleTypeId as AdminRole]}</WpTypography>
      </Grid>
    </>
  );
};

export default DisplayRolesContainer;
