import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IValidateEmailRequest, IValidateEmailResponse } from 'services/security-access/securityAccess.contracts';
import { ValidateEmail } from 'services/security-access/securityAccess.service';
import { IValidationResults } from 'services/shared/validationResults.contracts';

interface IValidateEmail {
  validationResults?: IValidationResults;
}

const initialState: IValidateEmail = {};

export const validateEmail = createAsyncThunk(
  'securityAccess/validateEmail',
  async (request: IValidateEmailRequest) => {
    const response: IValidateEmailResponse = await ValidateEmail(request);
    return response;
  }
);

export const validateEmailSlice = createSlice({
  name: 'securityAccess/validateEmail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validateEmail.fulfilled, (state, action) => {
      state.validationResults = action.payload.validationResults;
    });
  },
});

export const validateEmailReducer = validateEmailSlice.reducer;
