function format(value: any, format: any, lng: any) {
  if (format.startsWith('date')) {
    return formatDate(value, format, lng);
  }

  if (format.startsWith('number')) {
    return formatNumber(value, format, lng);
  }

  return value;
}

function formatDate(value: any, format: any, lng: any) {
  const options = toOptions(format, 'date');

  return options === null ? value : new Intl.DateTimeFormat(lng, options).format(value);
}

function formatNumber(value: any, format: any, lng: any) {
  const options = toOptions(format, 'number');

  return options === null ? value : new Intl.NumberFormat(lng, options).format(value);
}

function toOptions(format: any, specifier: any) {
  if (format.trim() === specifier) {
    return {};
  } else {
    try {
      return JSON.parse(toJsonString(format, specifier));
    } catch (error) {
      console.error(error);

      return null;
    }
  }
}

function toJsonString(format: any, specifier: any) {
  const inner = format
    .trim()
    .replace(specifier, '')
    .replace('(', '')
    .replace(')', '')
    .split(';')
    .map((param: any) =>
      param
        .split(':')
        .map((name: any) => `"${name.trim()}"`)
        .join(':')
    )
    .join(',');

  return '{' + inner + '}';
}

export default format;
