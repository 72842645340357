import React from 'react';
import { useCallbackPrompt } from './hooks/use-callback-prompt';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';

interface Props {
  isShow: boolean;
  onNavigateAway?: (func: any) => void;
  onStayOnPage?: () => void;
}

const CustomPrompt: React.FC<Props> = ({
  isShow,
  onNavigateAway,
  onStayOnPage = () => {
    return '';
  },
}) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isShow, onStayOnPage);
  const onLeave = () => {
    onNavigateAway?.(confirmNavigation);
  };
  return (
    <WpDialog
      open={!!showPrompt}
      unsavedChanges
      unsavedLeave={onNavigateAway ? onLeave : confirmNavigation}
      unsavedStay={cancelNavigation}
    />
  );
};

export default CustomPrompt;
