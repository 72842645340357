import { Post } from 'services/httpClient';
import {
  GetEmployeeListRequest,
  GetEmployeeListResponse,
  PersistEmployeeFieldListRequest,
  PersistEmployeeFieldListResponse,
  RemoveEmployeeFieldRequest,
  RemoveEmployeeFieldResponse,
  GetEmployeeFieldListRequest,
  GetEmployeeFieldListResponse,
  GetOpenPayPeriodSummaryRequest,
  GetOpenPayPeriodSummaryResponse,
  ChangeCompanyFieldStateRequest,
  ChangeCompanyFieldStateResponse,
  IGetEmployeeFieldSummaryListRequest,
  IGetEmployeeFieldSummaryListResponse,
  IDeleteEmployeeFieldRequest,
  IDeleteEmployeeFieldResponse,
  IGetFieldListRequest,
  IGetFieldListResponse,
  IValidateEmployeeFieldAssignmentRequest,
  IValidateEmployeeFieldAssignmentResponse,
  IGetSaleTaxListResponse,
  IGetSaleTaxListRequest,
  GetAssignedFieldListRequest,
  GetAssignedFieldListResponse,
} from './employeeFieldAccess.contracts';

const servicedomain = '/payrollemployeefieldaccess/';

const GetEmployeeListUrl = servicedomain + 'getemployeelist';
const PersistEmployeeFieldListUrl = servicedomain + 'persistemployeefieldlist';
const RemoveEmployeeFieldUrl = servicedomain + 'removeemployeefield';
const GetEmployeeFieldListUrl = servicedomain + 'getemployeefieldlist';
const GetOpenPayPeriodSummaryUrl = servicedomain + 'getopenpayperiodsummary';
const ChangeCompanyFieldStateUrl = servicedomain + 'changefieldstate';
const GetEmployeeFieldSummaryListUrl = servicedomain + 'GetEmployeeFieldSummaryList';
const ValidateEmployeeFieldAssignmentUrl = servicedomain + 'validateemployeefieldassignment';
const GetAssignedFieldListUrl = servicedomain + 'getassignedfieldlist';
const GetFieldListUrl = servicedomain + 'GetFieldList';
const DeleteEmployeeFieldUrl = servicedomain + 'DeleteEmployeeField';
const GetSaleTaxListUrl = servicedomain + 'getsaletaxlist';

export const GetEmployeeList = (request: GetEmployeeListRequest): Promise<GetEmployeeListResponse> => {
  return Post(GetEmployeeListUrl, request);
};

export const PersistEmployeeFieldList = (
  request: PersistEmployeeFieldListRequest
): Promise<PersistEmployeeFieldListResponse> => {
  return Post(PersistEmployeeFieldListUrl, request);
};

export const RemoveEmployeeField = (request: RemoveEmployeeFieldRequest): Promise<RemoveEmployeeFieldResponse> => {
  return Post(RemoveEmployeeFieldUrl, request);
};

export const GetEmployeeFieldList = (request: GetEmployeeFieldListRequest): Promise<GetEmployeeFieldListResponse> => {
  return Post(GetEmployeeFieldListUrl, request);
};

export const GetOpenPayPeriodSummary = (
  request: GetOpenPayPeriodSummaryRequest
): Promise<GetOpenPayPeriodSummaryResponse> => {
  return Post(GetOpenPayPeriodSummaryUrl, request);
};

export const ChangeCompanyFieldState = (
  request: ChangeCompanyFieldStateRequest
): Promise<ChangeCompanyFieldStateResponse> => {
  return Post(ChangeCompanyFieldStateUrl, request);
};
export const GetEmployeeFieldSummaryList = (
  request: IGetEmployeeFieldSummaryListRequest
): Promise<IGetEmployeeFieldSummaryListResponse> => {
  return Post(GetEmployeeFieldSummaryListUrl, request);
};
export const ValidateEmployeeFieldAssignment = (
  request: IValidateEmployeeFieldAssignmentRequest
): Promise<IValidateEmployeeFieldAssignmentResponse> => {
  return Post(ValidateEmployeeFieldAssignmentUrl, request);
};
export const GetAssignedFieldList = (request: GetAssignedFieldListRequest): Promise<GetAssignedFieldListResponse> => {
  return Post(GetAssignedFieldListUrl, request);
};
export const GetFieldList = (request: IGetFieldListRequest): Promise<IGetFieldListResponse> => {
  return Post(GetFieldListUrl, request);
};
export const GetSaleTaxList = (request: IGetSaleTaxListRequest): Promise<IGetSaleTaxListResponse> => {
  return Post(GetSaleTaxListUrl, request);
};
export const DeleteEmployeeField = (request: IDeleteEmployeeFieldRequest): Promise<IDeleteEmployeeFieldResponse> => {
  return Post(DeleteEmployeeFieldUrl, request);
};
