import { UpdateColumnHeaderList } from './updatePermissionsHeader';
import { IPermissionServiceCategory, IPermissionServiceSubCategory } from './interface/flattenPermissionsInterface';

/*
1. This function is to restructure, if there is parentSubCategoryId 
2. Need to add respective column names[header names for each permission list items]

Sample Input: 
 [{ "id": 16},{"id": 76,"parentsubCategoryId":16}]
Sample Output:
[{"id": 16,hasChildren:true,child:[{id:"76"}]}]

*/

export const restructurePermissionCategoryList = (permissionCategoryList: any) => {
  // make a copy of the original security role object
  const updatedPermissionCategoryList = [...permissionCategoryList];

  const updatedPermissionCategories = updatedPermissionCategoryList?.map((category: IPermissionServiceCategory) => {
    const updatedPermissionSubCategories = category.permissionSubCategoryList.reduce(
      (acc: IPermissionServiceSubCategory[], subcategory: IPermissionServiceSubCategory) => {
        if (subcategory.parentSubCategoryId !== undefined) {
          const parentIndex = acc.findIndex((item) => item.id === subcategory.parentSubCategoryId);
          const child = { ...subcategory, hasChildren: false };
          delete child.parentSubCategoryId;
          if (parentIndex >= 0) {
            if (!acc[parentIndex].children) acc[parentIndex].children = [];
            const childIndex = acc[parentIndex].children.findIndex(
              (subitem: { id: number }) => subitem.id === child.id
            );
            if (childIndex < 0) {
              acc[parentIndex].hasChildren = true;
              acc[parentIndex].children.push(child);
            }
          }
        } else {
          acc.push(subcategory);
        }
        return acc;
      },
      []
    );

    // Generate columnHeaderList based on ids
    const columnHeaderList = UpdateColumnHeaderList(category.id);

    return { ...category, permissionSubCategoryList: updatedPermissionSubCategories, columnHeaderList };
  });

  return updatedPermissionCategories;
};
