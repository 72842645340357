const APPS = [
  {
    name: 'App',
    path: './applications/app/app',
  },
  {
    name: 'Payroll',
    path: './applications/payroll/payroll',
  },
  {
    name: 'apollo',
    path: './applications/apollo/apollo',
  },
];

export default APPS;
