import { switchingFirmsReducer } from './../slice/accountingDashboardSlices/switchingFirmsSlice';
import { peopleFinalReviewReducer } from './../slice/peopleSlices/finalReviewSlice';
import { peopleJobDetailsReducer } from './../slice/peopleSlices/jobDetailsSlice';
//import { userReducer } from 'pages';
import { AnyAction, combineReducers } from 'redux';
import { companyDetailsReducer } from 'redux/slice/companyDetailsSlice';
import { stateReducer } from 'redux/slice/stateSlice';
import { configReducer } from 'redux/slice/configSlice';
import { companyBankReducer } from 'redux/slice/companyBankSlice';
import { peopleReducer } from 'redux/slice/peopleSlice';
import { peoplesCommonReducer } from 'redux/slice/peopleSlices/peopleSidebarSlice';
import { companySettingsReducer } from 'redux/slice/companySettingsSlice';
import { companyFieldReducer } from 'redux/slice/companyFieldSlice';
import { layoutReducer } from 'redux/slice/layoutSlice';
import { negativeAdjustmentReducer } from 'redux/slice/negativeAdjustmentSlice';
import { rolesReducer } from 'redux/slice/userAccountsSlice';
import { payrollHoursIncomeReducer } from 'redux/slice/payrollSlices/hoursIncomeSlice';
import { reviewApproveReducer } from 'redux/slice/payrollSlices/reviewapproveSlice';
import { payrollCommonReducer } from 'redux/slice/payrollSlices/commonPayrollSlice';
import { payrollTaxesReducer } from 'redux/slice/payrollSlices/taxesSlice';
import { payrollGridViewReducer } from 'redux/slice/payrollSlices/gridViewSlice';
import { snackbarReducer } from 'redux/slice/snackbarSlice';
import { loadingIndicatorReducer } from 'redux/slice/loadingIndicatorSlice';
import { overviewReducer } from 'redux/slice/taxSettingsSlices/overviewSlice';
import { getEmpTaxesReducer } from 'redux/slice/taxSettingsSlices/getEmpTaxesSlice';
import { persistEmpTaxesReducer } from 'redux/slice/taxSettingsSlices/persistEmpTaxesSlice';
import { notificationsReducer } from 'redux/slice/notificationsSlice';
import { appNotificationReducer } from 'redux/slice/appNotificationSlice';
import { payrollStateReducer } from 'redux/slice/companyPayrollSlice';
import { parentCompanyReducer } from 'redux/slice/parentCompanySlice';
import { recordOfEmploymentFormStateReducer } from 'redux/slice/recordOfEmploymentFormStateSlice';
import { firstTimeUserReducer } from 'redux/slice/firstTimeUserSlice';
import { accountingFirmReducer } from 'redux/slice/accountingFirmSlice';
import { getInitialStateReducer } from 'redux/slice/addonsSlices/getIntegrationStatusSlice';
import { poeReducer } from 'redux/slice/poeSlice';
import { poeSideBarReducer } from 'redux/slice/poeSlices/poeSideBarSlice';
import { poeBasicInfoReducer } from 'redux/slice/poeSlices/poeBasicInfoSlice';
import { poeJobDetailsReducer } from 'redux/slice/poeSlices/poeJobDetailsSlice';
import { poeTaxInfoReducer } from 'redux/slice/poeSlices/poeTaxInfoSlice';
import { poeCommonReducer } from 'redux/slice/poeSlices/poeCommonSlice';
import { disconnectIntegrationReducer } from 'redux/slice/addonsSlices/disconnectSlice';
import { connectIntegrationReducer } from 'redux/slice/addonsSlices/connectSlice';
import { getIntegrationsAccountListReducer } from 'redux/slice/addonsSlices/getIntegrationsAccountListSlice';
import { getChartOfAccountsReducer } from 'redux/slice/addonsSlices/getChartOfAccountsSlice';
import { addNewClientReducer } from 'redux/slice/accountingDashboardSlices/addNewClientSlice';
import { dashboardReducer } from 'redux/slice/accountingDashboardSlices/dashboardSlice';
import { stateListReducer } from 'redux/slice/taxSettingsSlices/stateListSlice';
import { persistChartOfAccountsReducer } from 'redux/slice/addonsSlices/persistChartOfAccountsSlice';
import { exportPayrollReducer } from 'redux/slice/addonsSlices/exportPayrollSlice';
import { persistAutoSyncPreferenceReducer } from 'redux/slice/addonsSlices/persistAutoSyncPreferenceSlice';
import { payrollHeaderReducer } from 'redux/slice/payrollSlices/payrollHeaderSlice';
import { getTaxRateListReducer } from 'redux/slice/addonsSlices/getTaxRateListSlice';
import { peopleBasicInfoReducer } from 'redux/slice/peopleSlices/basicInfoSlice';
import { peopleSharedReducer } from 'redux/slice/peopleSlices/peopleSharedSlice';
import { peopleAccessPersonReducer } from 'redux/slice/peopleSlices/personSlice';
import { guidedOnboardingReducer } from 'redux/slice/peopleSlices/guidedOnboardingSlice';
import { addBankReducer } from 'redux/slice/addBankSlice';
import { yearEndSubmitFormStateReducer } from 'redux/slice/yearEndSubmitFormStateSlice';
import { getUserReducer } from 'redux/slice/security-access/getUserSlice';
import { changePasswordReducer } from 'redux/slice/security-access/changePasswordSlice';
import { personalReducer } from 'redux/slice/personalSlice';
import { getPersonPreferencesReducer } from 'redux/slice/preferences/getPersonPreferencesSlice';
import { personProfileReducer } from 'redux/slice/preferences/personProfileSlice';
import { getPersonReducer } from 'redux/slice/preferences/getPersonSlice';
import { persistPersonReducer } from 'redux/slice/preferences/persistPersonSlice';
import { validateEmailReducer } from 'redux/slice/security-access/validateEmailSlice';
import { priorPayrollReducer } from 'redux/slice/peopleSlices/priorPayrollSlice';
import { peopleTaxInfoReducer } from 'redux/slice/peopleSlices/taxInfoSlice';
import { peopleIdbReducer } from 'redux/slice/peopleSlices/idbSlice';
import { payrollReportsReducer } from 'redux/slice/payrollReportsSlice';
import { getSecurityRoleReducer } from 'redux/slice/security-access/getSecurityRolesSlice';
import { documentAccessReducer } from 'redux/slice/documentAccessSlice';
import { payrollAdjustmentsReducer } from 'redux/slice/payrollSlices/adjustmentsSlice';
import { getPayGroupListReducer } from 'redux/slice/payrollSlices/payGroupSlice';
import { getIntegrationsVendorListReducer } from 'redux/slice/addonsSlices/getIntegrationsVendorListSlice';
import { persistIntegrationsVendorListReducer } from 'redux/slice/addonsSlices/persistIntegrationsVendorListSlice';
import { associatedCompaniesReducer } from 'redux/slice/associatedCompaniesSlice';
import { permissionTableReducer } from '../slice/accountingPermissionTable';
import { clientDirectoryReducer } from 'redux/slice/accountingDashboardSlices/clientDirectorySlice';
import { statusBarReducer } from 'redux/slice/statusBarSlice';
import { accountingUserAccountsReducer } from 'redux/slice/accountingUserAccount';
import { administratorTableReducer } from 'redux/slice/accountingAdministratorTable';
import { standardWeekHoursReducer } from 'redux/slice/payrollSlices/standardWeekHoursSlice';
import { selectDatesReducer } from 'redux/slice/payrollSlices/selectDateSlice';
import { fromAlfredReducer } from 'redux/slice/fromAlfredSlice';
import { payrollFrequencyReducer } from 'redux/slice/payrollSlices/payrollFrequencySlice';
import { payrollSettingsReducer } from 'redux/slice/payrollSettingSlice';
import { migrationSliceReducer } from 'redux/slice/migrationSlice';
import { chartOfAccountsSliceReducer } from 'redux/slice/addonsSlices/common/chartOfAccountsSlice';
import { xeroReducer } from 'redux/slice/addonsSlices/xero/xeroSlice';
import { accountVerificationSliceReducer } from 'redux/slice/reactivateAccountSlice';
import { annualReportsReducer } from 'redux/slice/annualReportSlice';

const reducer = combineReducers({
  administratorTableReducer,
  accountingUserAccountsReducer,
  permissionTableReducer,
  associatedCompaniesReducer,
  guidedOnboardingReducer,
  companyDetailsReducer,
  stateReducer,
  configReducer,
  layoutReducer,
  companyBankReducer,
  companyFieldReducer,
  peopleReducer,
  peopleSharedReducer,
  peoplesCommonReducer,
  peopleBasicInfoReducer,
  peopleAccessPersonReducer,
  addBankReducer,
  companySettingsReducer,
  negativeAdjustmentReducer,
  rolesReducer,
  payrollHoursIncomeReducer,
  payrollCommonReducer,
  payrollHeaderReducer,
  payrollFrequencyReducer,
  payrollTaxesReducer,
  payrollGridViewReducer,
  snackbarReducer,
  loadingIndicatorReducer,
  reviewApproveReducer,
  overviewReducer,
  getEmpTaxesReducer,
  persistEmpTaxesReducer,
  stateListReducer,
  notificationsReducer,
  appNotificationReducer,
  payrollStateReducer,
  parentCompanyReducer,
  recordOfEmploymentFormStateReducer,
  firstTimeUserReducer,
  accountingFirmReducer,
  getInitialStateReducer,
  poeReducer,
  poeSideBarReducer,
  poeBasicInfoReducer,
  poeJobDetailsReducer,
  poeTaxInfoReducer,
  poeCommonReducer,
  disconnectIntegrationReducer,
  connectIntegrationReducer,
  getIntegrationsAccountListReducer,
  getChartOfAccountsReducer,
  addNewClientReducer,
  dashboardReducer,
  persistChartOfAccountsReducer,
  exportPayrollReducer,
  persistAutoSyncPreferenceReducer,
  getTaxRateListReducer,
  peopleJobDetailsReducer,
  peopleFinalReviewReducer,
  yearEndSubmitFormStateReducer,
  getUserReducer,
  changePasswordReducer,
  personalReducer,
  getPersonPreferencesReducer,
  personProfileReducer,
  getPersonReducer,
  persistPersonReducer,
  validateEmailReducer,
  priorPayrollReducer,
  peopleTaxInfoReducer,
  peopleIdbReducer,
  payrollReportsReducer,
  getSecurityRoleReducer,
  documentAccessReducer,
  payrollAdjustmentsReducer,
  getPayGroupListReducer,
  getIntegrationsVendorListReducer,
  persistIntegrationsVendorListReducer,
  switchingFirmsReducer,
  clientDirectoryReducer,
  statusBarReducer,
  standardWeekHoursReducer,
  selectDatesReducer,
  fromAlfredReducer,
  payrollSettingsReducer,
  migrationSliceReducer,
  chartOfAccountsSliceReducer,
  xeroReducer,
  accountVerificationSliceReducer,
  annualReportsReducer,
});

type RootState = ReturnType<typeof reducer> | undefined;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'RESET_ALL_REDUCERS') {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default rootReducer;
