import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormProvider, useForm } from 'react-hook-form';

import WpDropdown from '@wagepoint/ui-toolkit/components/wp-dropdown/wpDropdown';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import { WpTextField } from '@wagepoint/ui-toolkit/components/wp-textField/wpTextField';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpAutoComplete from '@wagepoint/ui-toolkit/components/wp-autocomplete/wpAutoComplete';
import { WpRadioCheckNew } from '@wagepoint/ui-toolkit/components/wp-radioCheck-new';
import { RadioCheckTypeEnum } from '@wagepoint/ui-toolkit/components/wp-radioCheck-new';
import { debounce } from '@wagepoint/ui-toolkit/utility/debounce';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import WpBottomNav from '@wagepoint/ui-toolkit/components/wp-bottomNav/wpBottomNav';

import {
  IValidateEmailRequest,
  IValidateEmailResponse,
  IPersistUserRequest,
  ValidateEmailEnum,
  IGetEmployeeListResponse,
  IPersonSummaryList,
} from 'services/security-access/securityAccess.contracts';
import { ValidateEmail, PersistUser, GetUser, GetEmployeeList } from 'services/security-access/securityAccess.service';
import { GetLocationList, GetDepartmentList } from 'services/company-access/companyAccess.service';
import {
  GetLocationListRequest,
  GetLocationListResponse,
  ILocation,
} from 'services/company-access/companyAccess.contracts';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'redux/hooks/hook';
import { selectRolesListRoles } from 'redux/slice/userAccountsSlice';
import { selectCompanyId } from 'redux/slice/configSlice';
import { success, error } from 'redux/slice/snackbarSlice';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from 'redux/slice/loadingIndicatorSlice';
import { getRegex } from 'util/utility';
import { RegexEnum } from 'services/shared/regexEnum.contracts';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomPrompt from 'shared-components/custom-prompt/customPrompt';
import { IDepartmentUi } from '../common/interface/userAccountsInterface';
import { GlobalStyles } from 'styles/globalStyles';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

export enum RoleTypeEnum {
  NotDefined = 0,
  Employee = 1,
  NonEmployee = 2,
}

export enum RolesDialogEnum {
  NotDefined = 0,
  Invite = 1,
  Cancel = 2,
}

const AssignRole: React.FC = () => {
  const globalClass = GlobalStyles();
  const { t } = useTranslation(['userAccounts', 'common']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRolesListRoles);
  const companyId = useAppSelector(selectCompanyId);
  const { feature } = useFeatureFlag();
  const isAutofillOff = feature(FEATURE_FLAG_KEYS.enableAutofillOff);

  const [employeeList, setEmployeeList] = useState<Array<IPersonSummaryList>>([]);
  const [locationList, setLocationList] = useState<Array<ILocation>>([]);
  const [departmentList, setDepartmentList] = useState<Array<any>>([]);
  const [locationListForService, setLocationListForService] = useState<Array<any>>([]);
  const [departmentListForService, setDepartmentListForService] = useState<Array<any>>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<IPersonSummaryList>();
  const [dialogId, setDialogId] = React.useState<number>(RolesDialogEnum.NotDefined);

  const [submitData, setSubmitData] = useState<any>();
  const [roleType, setRoleType] = useState<number>(RoleTypeEnum.NotDefined);
  const [initialEmployeeList, setInitialEmployeeList] = useState<Array<IPersonSummaryList>>([]);
  const [displayPrompt, setPrompt] = useState<boolean>(false);

  const employeeRadioDataList = [
    { value: RoleTypeEnum.Employee, label: t('assignRole.employee') },
    { value: RoleTypeEnum.NonEmployee, label: t('assignRole.nonEmployee') },
  ];

  const emailRegExp = getRegex(RegexEnum.Email);

  const validationSchema = yup.object().shape({
    ...(roles?.securityRoleType !== SecurityRoleTypeEnum.Manager && {
      employeeType: yup.number().required(t('assignRole.selection')),
    }),
    ...(selectedEmployee &&
      !selectedEmployee.email && {
        emailEmployee: yup
          .string()
          .required(t('assignRole.emailValidation'))
          .matches(emailRegExp, t('assignRole.validEmail'))
          .test('emailEmployee', '', async function (value) {
            if (value && value.match(emailRegExp)) {
              const request: IValidateEmailRequest = {
                emailAddress: value,
              };

              const response: IValidateEmailResponse = await ValidateEmail(request);
              if (response.validationResults && response?.validationResults?.validationResultList.length > 0) {
                if (
                  response?.validationResults?.validationResultList[0].validationCode !=
                  ValidateEmailEnum.UserAlreadyAssociated
                ) {
                  if (
                    response?.validationResults?.validationResultList[0].validationCode ==
                    ValidateEmailEnum.EmailAlreadyExists
                  )
                    return this.createError({
                      message: t('assignRole.emailAlreadyExsist'),
                    });
                  else
                    return this.createError({
                      message: response?.validationResults?.validationResultList[0].validationMessage,
                    });
                } else
                  return this.createError({
                    message: response?.validationResults?.validationResultList[0].validationMessage,
                  });
              }
            }
            // WHEN THE VALUE IS EMPTY RETURN `true` by default
            return true;
          })
          .nullable(),
        confirmEmailEmployee: yup
          .string()
          .email(t('assignRole.email'))
          .required(t('assignRole.emailValidation'))
          .matches(emailRegExp, t('assignRole.email'))
          .oneOf([yup.ref('emailEmployee'), null], t('assignRole.invalidEmail'))
          .nullable(),
      }),
    ...(roleType == RoleTypeEnum.Employee || roles.securityRoleType === SecurityRoleTypeEnum.Manager
      ? {
          employee: yup.string().required(t('assignRole.validation')).nullable(),
          department: yup.array().min(1, t('assignRole.validation')),
        }
      : {
          firstName: yup.string().required(t('assignRole.firstNameValidation')).nullable(),
          lastName: yup.string().required(t('assignRole.lastNameValidation')).nullable(),
          email: yup
            .string()
            .nullable()
            .required(t('assignRole.emailValidation'))
            .matches(emailRegExp, t('assignRole.validEmail'))
            .test('email', '', async function (value) {
              if (value && value.match(emailRegExp)) {
                const request: IValidateEmailRequest = {
                  emailAddress: value,
                };
                const response: IValidateEmailResponse = await ValidateEmail(request);
                if (response.validationResults && response?.validationResults?.validationResultList.length > 0) {
                  if (
                    response?.validationResults?.validationResultList[0].validationCode !=
                    ValidateEmailEnum.UserAlreadyAssociated
                  )
                    return this.createError({
                      message: response?.validationResults?.validationResultList[0].validationMessage,
                    });
                  else
                    return this.createError({
                      message: response?.validationResults?.validationResultList[0].validationMessage,
                    });
                }
              }
              // WHEN THE VALUE IS EMPTY RETURN `true` by default
              return true;
            })
            .nullable(),

          confirmEmail: yup
            .string()
            .email(t('assignRole.email'))
            .required(t('assignRole.emailValidation'))
            .matches(emailRegExp, t('assignRole.email'))
            .oneOf([yup.ref('email'), null], t('assignRole.invalidEmail'))
            .nullable(),
        }),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    errors,
    setValue,
    handleSubmit,
    formState: { isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    getEmployeeList();
    getLocationList();
    getDeparmentList();
  }, []);

  useEffect(() => {
    if (selectedEmployee?.userId) getUser();
    if (selectedEmployee && selectedEmployee.stateList.length)
      setValue(
        'stateId',
        selectedEmployee.stateList.map((item) => item.name)
      );
  }, [selectedEmployee]);

  //not adding application type here, since we are just taking state from user list
  //application type is for getting correct securityRoleList and permissions
  const getUser = async () => {
    const request: any = {
      userId: selectedEmployee?.userId,
      includeSecurityRoleList: true,
      includePermission: true,
    };

    const response: any = await GetUser(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      setValue(
        'stateId',
        response?.user?.stateList.map((state: any) => state.name)
      );
    }
  };

  const getEmployeeList = async (search?: string) => {
    const request = {
      filterByName: true,
      nameContains: search,
      companyId,
    };
    const response: IGetEmployeeListResponse = await GetEmployeeList(search ? request : {});
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      const formattedList: Array<IPersonSummaryList> = response.personSummaryList.map((d) => {
        d.fullName = d.firstName + ' ' + d.lastName;
        return d;
      });
      setEmployeeList(formattedList);
      if (!search) setInitialEmployeeList(formattedList);
    } else {
      setEmployeeList([]);
    }
  };

  const getDeparmentList = async () => {
    const request: any = {
      companyId,
    };
    const response: any = await GetDepartmentList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      const departmentList = response?.departmentList.filter((item: any) => !item.hasManager);
      setDepartmentList(departmentList);
    } else {
      setDepartmentList([]);
    }
  };

  const getLocationList = async () => {
    const request: GetLocationListRequest = {
      skip: 0,
      take: 50,
      getByIdList: false,
      companyId,
    };
    const response: GetLocationListResponse = await GetLocationList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      const ids = response?.locationList.map((loc: any) => loc.stateId);
      const filtered = response?.locationList.filter(({ stateId }, index) => !ids.includes(stateId, index + 1));
      setLocationList(filtered);
    } else {
      setLocationList([]);
    }
  };

  const onEmployeeSelection = async (selected: any) => {
    if (!selected) {
      setSelectedEmployee(undefined);
    } else {
      setValue('employee', selected.fullName, {
        shouldValidate: true,
      });
      setSelectedEmployee(selected);
    }
  };

  const onSubmit = (data: any) => {
    setPrompt(true);
    setSubmitData(data);
    if (roleType === RoleTypeEnum.NonEmployee || selectedEmployee?.isInvitedToEmployeePortal) {
      persistUser(data);
    } else {
      setDialogId(RolesDialogEnum.Invite);
    }
  };

  const persistUser = async (data?: any, invite = false) => {
    dispatch(showLoading());
    const request: IPersistUserRequest = {
      personId: roleType == RoleTypeEnum.NonEmployee ? 0 : (selectedEmployee?.personId as number),
      emailAddress: data.email || data.emailEmployee,
      setDisable: false,
      firstName: data.firstName,
      lastName: data.lastName,
      securityRoleType: roles?.securityRoleType,
      stateIdList: locationListForService,
      departmentIdList: departmentListForService,
      inviteToEmployeePortal: invite,
    };
    const response = await PersistUser(request);
    if (response) {
      setDialogId(RolesDialogEnum.NotDefined);
      dispatch(
        success({
          message: t('assignRole.assignedSuccessfully'),
        })
      );
      history.push('.');
      // TODO: remove eslint-disable and move this before history.push.
      // eslint-disable-next-line no-restricted-syntax
      dispatch(hideLoading());
    } else {
      dispatch(
        error({
          message: t('assignRole.error'),
        })
      );
      history.push('.');
      // TODO: remove eslint-disable and move this before history.push.
      // eslint-disable-next-line no-restricted-syntax
      dispatch(hideLoading());
    }
  };

  const handleLocationSelection = (e: any) => {
    setLocationListForService(e);
  };

  const handleDepartmentSelection = (e: any) => {
    const filteredList = departmentList.reduce((acc: number[], dep: IDepartmentUi) => {
      if (e.includes(dep?.id)) acc.push(dep.id);
      return acc;
    }, []);
    setValue('department', filteredList);
    trigger('department');
    setDepartmentListForService(e);
  };

  // TODO: remove this comment and wrap "debounce" in "useCallback"
  // eslint-disable-next-line no-restricted-syntax
  const searchEmployeeList = debounce((name?: string) => {
    if ((name && name?.length >= 3) || name?.length == 0) {
      getEmployeeList(name);
    }
  });

  const handleCancel = () => {
    if (isDirty) {
      setDialogId(RolesDialogEnum.Cancel);
    } else {
      history.goBack();
    }
  };

  const renderDialog = () => {
    if (dialogId === RolesDialogEnum.Invite) {
      return (
        <WpDialog
          maxWidth="sm"
          fullWidth
          open
          title={t('assignRole.popupEmpTitle')}
          content={t('assignRole.popupEmpContent', {
            name:
              submitData && submitData.firstName && submitData.lastName
                ? `${submitData.firstName} ${submitData.lastName}`
                : selectedEmployee
                ? `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`
                : '',
          })}
          actions={
            <>
              <WpButton onClick={() => persistUser(submitData)} disableRipple={true} color="primary" variant="outlined">
                {t('assignRole.cancelInvitation')}
              </WpButton>
              <WpButton
                onClick={() => {
                  persistUser(submitData, true);
                }}
                color="primary"
                variant="contained"
              >
                {t('assignRole.sendInvite')}
              </WpButton>
            </>
          }
        />
      );
    } else if (dialogId === RolesDialogEnum.Cancel) {
      return (
        <>
          {/* TODO: VPS-replace-customprompt */}
          <WpDialog
            unsavedChanges
            open
            unsavedLeave={() => {
              setDialogId(RolesDialogEnum.NotDefined);
              setPrompt(true);
              history.goBack();
            }}
            unsavedStay={() => {
              setDialogId(RolesDialogEnum.NotDefined);
            }}
          />
        </>
      );
    }
  };

  return (
    <>
      <WpTypography variant="h2" className={globalClass.mb2}>{`${t('assignedPeopleGroup.manager.buttonContent')} ${
        roles.name
      }`}</WpTypography>
      <Card variant="outlined">
        <CardContent>
          <FormProvider {...methods}>
            <form>
              <Grid container spacing={2}>
                {roles.securityRoleType !== SecurityRoleTypeEnum.Manager && (
                  <Grid item xs={12}>
                    <WpTypography variant="p1Bold">{t('assignRole.who')} </WpTypography>
                    <WpRadioCheckNew
                      elementType={RadioCheckTypeEnum.radioButton}
                      dataList={
                        initialEmployeeList.length === 0
                          ? employeeRadioDataList.map((item: any) =>
                              item.value === RoleTypeEnum.Employee
                                ? { ...item, disabled: true, hoverMessage: t('assignRole.empAdmin') }
                                : { ...item }
                            )
                          : employeeRadioDataList
                      }
                      callBackOnChange={({ target }: any) => {
                        setRoleType(Number(target.value));
                        setSelectedEmployee(undefined);
                      }}
                      name="employeeType"
                      errorobj={errors}
                    />
                  </Grid>
                )}

                {(roleType === RoleTypeEnum.Employee || roles.securityRoleType === SecurityRoleTypeEnum.Manager) && (
                  <>
                    <Grid item xs={12}>
                      <WpAutoComplete
                        fullWidth={true}
                        label={t('assignRole.employeeLabel')}
                        name="employee"
                        multiple={false}
                        required={true}
                        errorobj={errors}
                        dataSource={employeeList}
                        displayKey="personId"
                        displayValue="fullName"
                        callBackOnChange={(selected: any) => {
                          onEmployeeSelection(selected);
                        }}
                        callBackOnFocus={() => employeeList.length === 0 && getEmployeeList()}
                        callBackOnInputChange={(event, value) => {
                          searchEmployeeList(value);
                        }}
                      />
                    </Grid>

                    {selectedEmployee && selectedEmployee.email && (
                      <Grid item xs={12}>
                        <WpTypography variant="p1Bold">{t('assignRole.emailSmall')}</WpTypography>
                        <WpTypography>{selectedEmployee.email}</WpTypography>
                      </Grid>
                    )}
                    {selectedEmployee && !selectedEmployee?.email && (
                      <>
                        <Grid item xs={12}>
                          <WpStatements
                            variant="critical"
                            message={t('assignRole.noEmailMessage', {
                              name: selectedEmployee
                                ? `${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`
                                : '',
                            })}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <WpTextField
                            id="emailEmployee"
                            label={t('assignRole.emailSmall')}
                            name="emailEmployee"
                            required={true}
                            errorobj={errors}
                            autofillOff={isAutofillOff}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <WpTextField
                            id="confirmEmailEmployee"
                            label={t('assignRole.retypeEmail')}
                            name="confirmEmailEmployee"
                            required={true}
                            errorobj={errors}
                          />
                        </Grid>
                      </>
                    )}

                    {locationList.length >= 2 && (
                      <Grid item xs={6}>
                        <WpDropdown
                          id="stateId"
                          label={t('assignRole.locationLabel')}
                          name="stateId"
                          menuItems={locationList}
                          multiple
                          hasSelectAll={true}
                          displayKey="stateId"
                          displayValue="state"
                          returnValue="stateId"
                          callBackOnChange={handleLocationSelection}
                        />
                      </Grid>
                    )}

                    {roles.securityRoleType === SecurityRoleTypeEnum.Manager && (
                      <Grid item xs={6}>
                        <WpDropdown
                          id="department"
                          label={t('assignedPeopleGroup.columns.assignDepartment')}
                          name="department"
                          menuItems={departmentList}
                          multiple
                          hasSelectAll={true}
                          displayKey="id"
                          displayValue="name"
                          returnValue="id"
                          errorobj={errors}
                          required={true}
                          callBackOnChange={handleDepartmentSelection}
                        />
                      </Grid>
                    )}
                  </>
                )}

                {roleType === RoleTypeEnum.NonEmployee && (
                  <>
                    <Grid item xs={12}>
                      <WpStatements variant="highpriority" message={t('assignRole.nonEmployeeTooltip')} />
                    </Grid>
                    <Grid item xs={6}>
                      <WpTextField
                        id="firstName"
                        name="firstName"
                        label={t('assignRole.firstName')}
                        required={true}
                        errorobj={errors}
                        isBankField
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <WpTextField
                        id="lastName"
                        name="lastName"
                        label={t('assignRole.lastName')}
                        required={true}
                        errorobj={errors}
                        isBankField
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <WpTextField
                        id="email"
                        label={t('assignRole.emailSmall')}
                        name="email"
                        required={true}
                        errorobj={errors}
                        autofillOff={isAutofillOff}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <WpTextField
                        id="confirmemail"
                        label={t('assignRole.retypeEmail')}
                        name="confirmEmail"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>

                    {locationList.length >= 2 && (
                      <Grid item xs={6}>
                        <WpDropdown
                          id="stateId"
                          label={t('assignRole.locationLabel')}
                          name="location"
                          menuItems={locationList}
                          multiple
                          hasSelectAll={true}
                          displayKey="stateId"
                          displayValue="state"
                          returnValue="stateId"
                          callBackOnChange={handleLocationSelection}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <WpBottomNav
                  primaryBtn={{
                    text:
                      roles.securityRoleType === SecurityRoleTypeEnum.Manager
                        ? t('common:save')
                        : roleType === RoleTypeEnum.NonEmployee
                        ? t('assignedPeopleGroup.manager.buttonContent')
                        : t('assignRole.Invite'),
                    fn: handleSubmit(onSubmit),
                  }}
                  secondaryBtn={{ text: t('common:cancel'), fn: handleCancel }}
                />
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>

      <CustomPrompt isShow={isDirty && !displayPrompt} />

      {renderDialog()}
    </>
  );
};

export default AssignRole;
