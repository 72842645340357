import React from 'react';

import Grid from '@material-ui/core/Grid';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import { WpTextField } from '@wagepoint/ui-toolkit/components/wp-textField/wpTextField';

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

const ValidateEmailContainer: React.VFC = () => {
  const { t } = useTranslation(['userAccounts']);
  const { feature } = useFeatureFlag();
  const isAutofillOff = feature(FEATURE_FLAG_KEYS.enableAutofillOff);

  const { errors } = useFormContext();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <WpTextField
          id="confirmEmail"
          label={t('common.retrypeEmail')}
          name="confirmEmail"
          required
          errorobj={errors}
          autofillOff={isAutofillOff}
        />
      </Grid>
      <Grid item xs={12}>
        <WpStatements variant="warning" message={t('common.statement')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WpTextField
          id="firstName"
          name="firstName"
          label={t('common.firstName')}
          required
          errorobj={errors}
          isBankField
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <WpTextField
          id="lastName"
          name="lastName"
          label={t('common.lastName')}
          required
          errorobj={errors}
          isBankField
        />
      </Grid>
    </>
  );
};
export default ValidateEmailContainer;
