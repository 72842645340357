import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import partnerAccessService, {
  ClientInterface,
  GetUserRequestInterface,
  SecurityRoleInterface,
} from 'services/appCore/models/access/partnerAccess';
import { SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';

import { IShortClientInterface } from 'pages/accounting-dashboard/user-accounts/administrators/components/addAdministrator/addadmin';
import { buildSelector } from 'util/storage/buildSelector';

export interface ISlice {
  selectedRoles: any;
  securityRoleList?: SecurityRoleInterface;
  securityRoleId: number;
  securityUserId: number;
  securityRoleType: SecurityRoleTypeEnum;
  securityRoleName: string;
  clientCount: number;
  selectedAssignedClientsList: Array<number> | null | [];
  selectedUnassignedClientsList: Array<number> | null | [];
  assignedClientsList: Array<ClientInterface>;
  fullName: string;
  email: string;
  adminIsViewDisabled: boolean;
  isChangeClientList: boolean;
  isClientListLoaded: boolean;
  shortClientListToAssign: Array<IShortClientInterface>;
  shortClientListToUnAssign: Array<IShortClientInterface>;
}

const initialState: ISlice = {
  selectedRoles: {},
  securityRoleId: 0,
  securityUserId: 0,
  securityRoleType: SecurityRoleTypeEnum.notDefined,
  securityRoleName: ' ',
  clientCount: 0,
  selectedAssignedClientsList: null,
  selectedUnassignedClientsList: null,
  assignedClientsList: [],
  fullName: '',
  email: '',
  adminIsViewDisabled: false,
  isChangeClientList: false,
  isClientListLoaded: false,
  shortClientListToAssign: [],
  shortClientListToUnAssign: [],
};

export const getUserSecurityData = createAsyncThunk(
  'accountingUserAccounts/getUserSecurityData',
  async (request: Partial<GetUserRequestInterface> & { partnerId: number; userId: number }) => {
    const req: GetUserRequestInterface = {
      includeSecurityRoleList: true,
      includePermission: true,
      includeClientList: true,
      includeClientAcceptedInviteOnly: false,
      ...request,
    };
    return partnerAccessService.getUser(req);
  }
);

export const accountingUserAccountsSlice = createSlice({
  name: 'accountingUserAccounts',
  initialState,
  reducers: {
    selectedRoleData: (state, action) => {
      state.selectedRoles = action.payload.selectedRoles;
    },
    setAssignedClients: (state, action) => {
      state.selectedAssignedClientsList = action.payload;
    },
    setUnassignedClients: (state, action: PayloadAction<number[]>) => {
      state.selectedUnassignedClientsList = action.payload;
    },
    setIsViewDisabled: (state, action: { payload: { value: boolean } }) => {
      state.adminIsViewDisabled = action.payload.value;
    },
    setChangeClientList: (state, action: { payload: { value: boolean } }) => {
      state.isChangeClientList = action.payload.value;
    },
    setShortClientListToAssign: (state, action: PayloadAction<IShortClientInterface[]>) => {
      state.shortClientListToAssign = action.payload;
    },
    setShortClientListToUnAssign: (state, action: PayloadAction<IShortClientInterface[]>) => {
      state.shortClientListToUnAssign = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserSecurityData.pending, (state) => {
      state.isClientListLoaded = false;
    });
    builder.addCase(getUserSecurityData.fulfilled, (state, action) => {
      state.securityRoleList = action.payload?.securityRoleList[0];
      state.securityRoleId = action.payload?.securityRoleList[0]?.id;
      state.securityRoleType = action.payload?.securityRoleList[0]?.securityRoleType;
      state.securityRoleName = action.payload?.securityRoleList[0]?.name;
      state.clientCount = action.payload.user.clientCount;
      state.securityUserId = action.payload?.user.id;
      state.assignedClientsList = action.payload.user.clientList;
      state.fullName = `${action.payload.user.firstName} ${action.payload.user.lastName}`;
      state.email = action.payload.user.email;
      state.isClientListLoaded = true;
    });
  },
});

export const {
  selectedRoleData,
  setAssignedClients,
  setIsViewDisabled,
  setUnassignedClients,
  setChangeClientList,
  setShortClientListToUnAssign,
  setShortClientListToAssign,
} = accountingUserAccountsSlice.actions;
export const accountingUserAccountsReducer = accountingUserAccountsSlice.reducer;
export const selectUserAccounts = (state: RootState) => state.accountingUserAccountsReducer;
export const selectSecurityRoleList = createSelector(selectUserAccounts, ({ securityRoleList }) => securityRoleList);
export const selectedFullName = createSelector(selectUserAccounts, ({ fullName }) => fullName);
export const selectedEmail = createSelector(selectUserAccounts, ({ email }) => email);
export const selectSecurityRoleId = createSelector(selectUserAccounts, ({ securityRoleId }) => securityRoleId);
export const selectSecurityUserId = createSelector(selectUserAccounts, ({ securityUserId }) => securityUserId);
export const selectSecurityRoleType = createSelector(selectUserAccounts, ({ securityRoleType }) => securityRoleType);
export const selectSecurityRoleName = createSelector(selectUserAccounts, ({ securityRoleName }) => securityRoleName);
export const selectSelectedRoles = createSelector(selectUserAccounts, ({ selectedRoles }) => selectedRoles);
export const selectedClientCount = createSelector(selectUserAccounts, ({ clientCount }) => clientCount);
export const selectedIsViewDisabled = createSelector(
  selectUserAccounts,
  ({ adminIsViewDisabled }) => adminIsViewDisabled
);
export const selectedAssignClientList = createSelector(
  selectUserAccounts,
  ({ assignedClientsList }) => assignedClientsList ?? []
);
export const selectedUpdatedAssignClientList = createSelector(
  selectUserAccounts,
  ({ selectedAssignedClientsList }) => selectedAssignedClientsList ?? []
);
export const selectedUnassignClientList = createSelector(
  selectUserAccounts,
  ({ selectedUnassignedClientsList }) => selectedUnassignedClientsList ?? []
);
export const selectedChangeClientList = createSelector(
  selectUserAccounts,
  ({ isChangeClientList }) => isChangeClientList
);

export const [useSelectShortClientListToAssign, selectShortClientListToAssign] = buildSelector(
  ({ accountingUserAccountsReducer }) => accountingUserAccountsReducer.shortClientListToAssign
);

export const [useSelectShortClientListToUnAssign, selectShortClientListToUnAssign] = buildSelector(
  ({ accountingUserAccountsReducer }) => accountingUserAccountsReducer.shortClientListToUnAssign
);
