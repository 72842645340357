import { IValidationResults } from 'services/shared/validationResults.contracts';

export enum UiElementTypeEnum {
  notDefined = 0,
  radioButton = 1,
  checkbox = 2,
  dropdownList = 3,
  typeAhead = 4,
  textbox = 5,
  multilineTextBox = 6,
  label = 7,
  dropDownListCheckbox = 8,
  calendar = 9,
}
export enum QuestionValueDataTypeEnum {
  string = 0,
  integer = 1,
  decimal = 2,
  boolean = 3,
}
export enum EmployeeOnboardingTypeEnum {
  notDefined = 0,
  notSpecified,
  guided,
  grid,
  import,
}

export interface IQuestionOption {
  displayText?: string;
  valueDataType: QuestionValueDataTypeEnum;
  isDefault?: boolean;
  questionIdList?: number[];
  integerValue?: number;
  booleanValue?: boolean;
  stringValue?: string;
  decimalValue?: string;
  description?: string;
}
export interface IQuestion {
  id?: number;
  title?: string;
  valueDataType?: number;
  booleanValue?: boolean;
  integerValue?: number;
  isDefault?: boolean;
  uiElementType?: UiElementTypeEnum;
  description?: string;
  displayText?: string;
  hasNestedQuestion?: boolean;
  key?: string;
  questionIdList?: number[];
  questionOptionList?: Array<IQuestionOption>;
  nameElement?: string /* Might be in future, when adding name for each field from the backend */;
}

export interface IOnboardingPreferences {
  id?: number;
  onboardingTypeId: EmployeeOnboardingTypeEnum;
  isStaffSelfOnboardingEnabled?: boolean;
  havePaidThisYear?: boolean;
  selfSetupBasic?: boolean;
  selfSetupSocial?: boolean;
  selfSetupTax?: boolean;
  selfSetupDeposit?: boolean;
  employeeStatusTypeIdList?: number[];
  employementTypeIdList?: number[];
  personId?: number;
  selfOnboardFieldGroupIdList?: number[];
}

export enum OnboardListTypeEnum {
  employementTypeIdList = 2,
  employeeStatusTypeIdList,
  havePaidThisYear,
  isStaffOnboardingList,
  isStaffSelfOnboardingEnabled,
}

export enum IStaffSelfOnboardList {
  NotDefined = 0,
  Personal,
  Social,
  Tax,
  Bank,
}
export interface IPersistOnboardingPreferencesRequest {
  companyId?: number;
  onboardingPreferences?: IOnboardingPreferences;
}
export interface IGetOnboardingPreferencesListRequest {
  companyId: number;
  onboardingType: EmployeeOnboardingTypeEnum;
  personId?: number;
  getByIdList?: boolean;
  idList?: number;
}

export interface IGetOnboardingPreferencesListResponse {
  onboardingPreferencesList: Array<IOnboardingPreferences>;
  validationResults: IValidationResults;
}

export enum OnboardingStepStatusTypeEnum /* Note: if this is updated always update > onboardStatusEnum > peopleDefinition */ {
  notDefined = 0,
  skipped /* Note: in service enum is used as Pending - skipped only for UI purpose */,
  partiallyCompleted,
  completed,
  staffOnboardPending /* Note: this is for self onbaording purpose only -  */,
  active,
}

export enum IOnboardingStepListStatusEnum {
  Active = -1, // kept for UI purpose only
  NotDefined = 0,
  Skipped,
  Incomplete,
  Completed,
  StaffOnboardPending,
}

export enum EmployeeOnboardingStepTypeEnum {
  notDefined = 0,
  basicInformation = 1,
  jobDetails = 2,
  taxInformation = 3,
  incomeDeducationsAndBenefits = 4,
  priorPayrolls = 5,
  finalReview = 6,
  paymentInformation = 7,
}
export enum UpdateProvinceStepTypeEnum {
  notDefined = 0,
  basicInformation = 1,
  jobDetails = 2,
  taxInformation = 3,
  finalReview = 4,
}
export interface IPersistOnboardingStepStatusRequest {
  companyId: number;
  personId?: number;
  onboardingType: EmployeeOnboardingTypeEnum;
  onboardingStepStatusType: OnboardingStepStatusTypeEnum;
  onboardingStepType: EmployeeOnboardingStepTypeEnum;
}

export interface IPersistOnboardingStepStatusResponse {
  validationResults: IValidationResults;
}

export interface IPersistOnboardingPreferencesResponse {
  onboardingPreferencesId: number;
  validationResults: IValidationResults;
}
export enum EmployeeStateTypeEnum {
  notDefined = 0,
  notSpecified,
  active,
  terminated,
  onLeave,
  terminatedPending,
  onLeavePending,
  disabled,
  pending,
  incomplete,
}
export enum CountryTypeEnum {
  notDefined = 0,
  notSpecified,
  us,
  canada,
}
export enum PersonTypeEnum {
  notDefined = 0,
  employee,
  contractor,
  other,
}

export interface IGetOnboardingStepsListRequest {
  onboardingType: EmployeeOnboardingTypeEnum;
  country: CountryTypeEnum;
  employeeStateType: EmployeeStateTypeEnum;
  employementType: PersonTypeEnum;
  isSelfOnBoarding?: boolean;
  havePaidThisYear?: boolean;
  companyId?: number;
  personId?: number;
}

export interface IOnboardingStepType {
  id: number;
  name: string;
  onboardingStepStatusType: OnboardingStepStatusTypeEnum;
}

export interface IGetOnboardingStepsListResponse {
  onboardingStepTypeList: IOnboardingStepType[];
  currentStepTypeId: EmployeeOnboardingTypeEnum;
  validationResults: IValidationResults;
}

export interface IGetSelfOnboardingFieldGroupListRequest {
  companyId: number;
  onboardingType: EmployeeOnboardingTypeEnum;
  includeFieldList: boolean;
  isStaffOnboardingListArr?: number[];
  personId?: number;
}
export interface IPersistCurrentOnboardingStepRequest {
  personId: number;
  currentStepTypeId: EmployeeOnboardingStepTypeEnum;
}

export interface IPersistCurrentOnboardingStepResponse {
  validationResults: IValidationResults;
}

export interface IGetSelfOnboardingFieldGroupListResponse {
  onboardFieldGroupList: Array<IOnboardFieldGroup>;
  validationResults: IValidationResults;
}

export interface IOnboardFieldGroup {
  id?: number;
  name?: string;
  onboardFieldList: Array<IOnboardFieldGroup>;
}

export enum IOnBoardingStepEnum {
  basicInformation = 1,
  jobDetails,
  taxInformation,
  incomeDeducationsAndBenefits,
  priorPayrolls,
  finalReview,
}
