import React from 'react';
import { Route } from 'react-router-dom';
import RolesPermission from './rolePermission';
import RolesAssignedPeople from './rolesAssignedPeople';
import AssignRole from './assignRole';
import TransferOwnership from './transferOwnership';
import EditDetails from '../editView';

const RoleTabsView: React.FC<{ path: string }> = (props) => {
  return (
    <div>
      <Route path={`${props.path}/permission-settings`}>
        <RolesPermission />
      </Route>
      <Route exact path={`${props.path}/assigned-people`}>
        <RolesAssignedPeople />
      </Route>
      <Route path={`${props.path}/assigned-people/assign`}>
        <AssignRole />
      </Route>
      <Route path={`${props.path}/assigned-people/view/:id`}>
        <EditDetails />
      </Route>
      <Route path={`${props.path}/transfer-ownership`}>
        <TransferOwnership />
      </Route>
    </div>
  );
};

export default RoleTabsView;
