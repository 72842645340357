import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  CompanyOnboardingStepEnum,
  CompanyOnboardingStepStatusTypeEnum,
  IGetInitialStateResponse,
  IGetOnboardingStepStatusListResponse,
  OnboardingStatusTypeEnum,
} from 'services/payroll-company-onboard-access/payrollCompanyOnboardAccess.contracts';
import {
  GetInitialState,
  GetOnboardingStepStatusList,
  CompleteOnboarding,
  PersistOnboardingStepStatus,
  PersistOnboardingStep,
  PersistOnboardingStatus,
} from 'services/payroll-company-onboard-access/payrollCompanyOnboardAccess.service';

export interface IFirstFormInitialStateFormState {
  onboard: IGetOnboardingStepStatusListResponse | null;
  initialState: IGetInitialStateResponse | null;
}

export const firstFormInitialState: IFirstFormInitialStateFormState = {
  onboard: null,
  initialState: null,
};

export const getFirstTimeUserInitialStateData = createAsyncThunk(
  'firstTimeUser/getInitialStateData',
  async (request, { getState }) => {
    const state = getState() as RootState;
    const response = await GetInitialState({ companyId: state.configReducer?.selectedCompany?.companyId ?? 0 });

    return response;
  }
);

export const getFirstTimeUserOnboardData = createAsyncThunk(
  'firstTimeUser/getFirstTimeUserOnboardData',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const response = await GetOnboardingStepStatusList({
      companyId: state.configReducer?.selectedCompany?.companyId ?? 0,
      getOnlyVisibleSteps: false,
      stepIdList: [],
    });

    return response;
  }
);

export const setPersistStepStatus = createAsyncThunk(
  'firstTimeUser/setPersistStepStatus',
  async (
    request: {
      onbooardingStep: CompanyOnboardingStepEnum;
      isSkipped: boolean;
      companyOnboardingStepStatusType?: CompanyOnboardingStepStatusTypeEnum;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    await PersistOnboardingStep({
      companyId: state.configReducer?.selectedCompany?.companyId ?? 0,
      personId: state.configReducer?.config?.personId ?? 0,
      onbooardingStep: request.onbooardingStep ?? CompanyOnboardingStepEnum.notDefined,
    });
  }
);

export const setPersistStepStatusSpecial = createAsyncThunk(
  'firstTimeUser/setPersistStepStatus',
  async (
    request: {
      onbooardingStep: CompanyOnboardingStepEnum;
      isSkipped: boolean;
      companyOnboardingStepStatusType?: CompanyOnboardingStepStatusTypeEnum;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    await PersistOnboardingStep({
      companyId: state.configReducer?.selectedCompany?.companyId ?? 0,
      personId: state.configReducer?.config?.personId ?? 0,
      onbooardingStep: request.onbooardingStep ?? CompanyOnboardingStepEnum.notDefined,
    });
    await PersistOnboardingStepStatus({
      companyId: state.configReducer?.selectedCompany?.companyId ?? 0,
      isSkipped: request.isSkipped ?? false,
      onbooardingStep: request.onbooardingStep ?? CompanyOnboardingStepEnum.notDefined,
      companyOnboardingStepStatusType:
        request.companyOnboardingStepStatusType ?? CompanyOnboardingStepStatusTypeEnum.completed,
    });
  }
);

export const setPersistOnboardingStatus = createAsyncThunk(
  'firstTimeUser/setPersistOnboardingStatus',
  async (
    request: {
      onboardingStatus: OnboardingStatusTypeEnum;
    },
    { getState }
  ) => {
    const state = getState() as RootState;

    await PersistOnboardingStatus({
      companyId: state.configReducer?.selectedCompany?.companyId ?? 0,
      onboardingStatus: request.onboardingStatus,
    });
  }
);

export const completeOnboarding = createAsyncThunk(
  'firstTimeUser/completeOnboarding',
  async (request, { getState }) => {
    const state = getState() as RootState;
    return CompleteOnboarding({
      companyId: state.configReducer?.selectedCompany?.companyId ?? 0,
    });
  }
);

export const stateSlice = createSlice({
  name: 'firstTimeUser',
  initialState: firstFormInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFirstTimeUserOnboardData.fulfilled, (state, action) => {
      state.onboard = action.payload;
    });
    builder.addCase(getFirstTimeUserInitialStateData.fulfilled, (state, action) => {
      state.initialState = action.payload;
    });
  },
});

export const firstTimeUserReducer = stateSlice.reducer;
export const selectFirstTimeUser = (state: RootState) => state.firstTimeUserReducer;
export const selectFirstTimeUserOnboard = (state: RootState) => state.firstTimeUserReducer.onboard;
export const selectFirstTimeUserInitialData = (state: RootState) => state.firstTimeUserReducer.initialState;
export const selectFirstTimeUserInitialDataAccountantInviteDetails = (state: RootState) =>
  state.firstTimeUserReducer.initialState?.accountantInviteDetails;
