import { useTranslation } from 'react-i18next';
import { SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';
import { noValueStr } from '../constants';

export const useAdministratorRoleName = () => {
  const { t: tSecurityRoles } = useTranslation('dashboardUserAccounts', { keyPrefix: 'securityRoleType' });

  const getRoleName = (securityRoleId = SecurityRoleTypeEnum.notDefined) => {
    switch (securityRoleId) {
      case SecurityRoleTypeEnum.notDefined:
        return tSecurityRoles('NotDefined');

      case SecurityRoleTypeEnum.accountOwner:
        return tSecurityRoles('AccountOwner');

      case SecurityRoleTypeEnum.accountSignatory:
        return tSecurityRoles('AccountSignatory');

      case SecurityRoleTypeEnum.administrator:
        return tSecurityRoles('Administrator');

      case SecurityRoleTypeEnum.manager:
        return tSecurityRoles('Manager');

      case SecurityRoleTypeEnum.reportsManager:
        return tSecurityRoles('ReportsManager');

      case SecurityRoleTypeEnum.administratorApprover:
        return tSecurityRoles('AdministratorApprover');

      case SecurityRoleTypeEnum.administratorSubmitter:
        return tSecurityRoles('AdministratorSubmitter');

      case SecurityRoleTypeEnum.accountAdministrator:
        return tSecurityRoles('AccountAdministrator');

      case SecurityRoleTypeEnum.employee:
        return tSecurityRoles('Employee');

      case SecurityRoleTypeEnum.contractor:
        return tSecurityRoles('Contractor');

      default:
        return noValueStr;
    }
  };

  return { getRoleName };
};
