import { Post } from 'services/httpClient';
import {
  IGetAdminPreferencesRequest,
  IGetAdminPreferencesResponse,
  IGetFirmPreferencesRequest,
  IGetFirmPreferencesResponse,
  IPersistPersonProfileRequest,
  IPersistPersonProfileResponse,
} from './personSettingsAccess.contracts';

const url = (endpoint: string) => `/appCorePersonSettingsAccess/${endpoint}`;

export const GetAdminPreferencesRequest = (
  request: IGetAdminPreferencesRequest
): Promise<IGetAdminPreferencesResponse> => {
  return Post(url('GetAdminPreferences'), request);
};

export const GetFirmPreferencesRequest = (
  request: IGetFirmPreferencesRequest
): Promise<IGetFirmPreferencesResponse> => {
  return Post(url('GetFirmPreferences'), request);
};

export const PersistPersonProfile = (request: IPersistPersonProfileRequest): Promise<IPersistPersonProfileResponse> => {
  return Post(url('PersistPersonProfile'), request);
};
