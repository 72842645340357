import React from 'react';
import Grid from '@material-ui/core/Grid';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import WpBottomNav from '@wagepoint/ui-toolkit/components/wp-bottomNav/wpBottomNav';

import { ZeroStateContainerStyles } from './zeroStateContainerStyles';
import { useTranslation } from 'react-i18next';

import { getApiValidationErrors } from 'util/utility';

import companyLinkAccess from 'services/finance/models/access/companyLinkAccess';
import { SecurityRoleTypeEnum } from 'services/appCore/models/schema/Schema';

import { ValidEnum } from '../companyIdOrEmailLayout';
import { selectFirmCompanyId, selectIsValidCompany, setFirmView } from 'redux/slice/accountingFirmSlice';
import { selectAccountingAssignedRole } from 'redux/slice/userAccountsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { useLoaders } from 'redux/hooks/useLoaders';
import { useSnackbar } from 'redux/hooks/useSnackbar';
import { selectCompanyId } from 'redux/slice/configSlice';

import { ManageViewOfAccountingFirm } from '../../../index';
import { useFormContext } from 'react-hook-form';

interface IFormContainerProps {
  formId: string;
  from?: ManageViewOfAccountingFirm;
}

const ButtonContainer: React.VFC<IFormContainerProps> = ({ formId, from }) => {
  const classes = ZeroStateContainerStyles();
  const { t } = useTranslation(['userAccounts']);
  const dispatch = useAppDispatch();
  const selectedCompanyId = useAppSelector(selectCompanyId);
  const companyId = useAppSelector(selectFirmCompanyId);
  const isValidCompany = useAppSelector(selectIsValidCompany);
  const assignedRole = useAppSelector(selectAccountingAssignedRole);
  const { hideLoader, showLoader } = useLoaders();
  const { success, error } = useSnackbar();
  const { handleSubmit } = useFormContext();

  const updateFirmView = () =>
    dispatch(
      setFirmView({
        firmView: ManageViewOfAccountingFirm.FirmPermission,
        isEdit: false,
        from: ManageViewOfAccountingFirm.ZeroState,
        companyId,
        isValidCompany: ValidEnum.NotDefined,
      })
    );

  const inviteFirmService = async () => {
    showLoader();
    const response = await companyLinkAccess.clientInvitesPartner({
      invitedByEmail: false,
      invitedByPartnerCompanyId: true,
      partnerCompanyId: companyId ?? 0,
      partnerSecurityRoleType: assignedRole ?? SecurityRoleTypeEnum.notDefined,
      clientCompanyId: selectedCompanyId,
      isInvitingNewPartner: false,
    });

    const { hasError, message } = getApiValidationErrors(response);

    if (!hasError) {
      //logic to set current view
      updateFirmView();
      success(t('common.inviteSent'));
    } else {
      error(message);
    }
    hideLoader();
  };

  const inviteUser = async (data: any) => {
    showLoader();
    const response = await companyLinkAccess.invitePartnerUser({
      email: data.email ?? '',
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      partnerSecurityRoleType: assignedRole ?? SecurityRoleTypeEnum.notDefined,
      clientCompanyId: selectedCompanyId,
    });

    const { hasError, message } = getApiValidationErrors(response);

    if (!hasError) {
      //logic to set current view
      updateFirmView();
      success(t('common.inviteSent'));
    } else {
      error(message);
    }
    hideLoader();
  };

  const buttonClickHandler = (data: any) => {
    if (isValidCompany === ValidEnum.Valid) {
      inviteFirmService();
    } else if (isValidCompany === ValidEnum.ValidEmail || isValidCompany === ValidEnum.InValidEmail) {
      inviteUser(data);
    }
  };

  return (
    <>
      {from === ManageViewOfAccountingFirm.ZeroState ? (
        <WpBottomNav
          primaryBtn={{
            text: t('common.invite'),
            fn: handleSubmit((data: any) => {
              buttonClickHandler(data);
            }),
            form: formId,
            type: 'submit',
          }}
        />
      ) : (
        <Grid item xs={12}>
          <WpButton
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit((data: any) => {
              buttonClickHandler(data);
            })}
            form={formId}
            type="submit"
            noGutter
          >
            {t('common.invite')}
          </WpButton>
        </Grid>
      )}
    </>
  );
};

export default ButtonContainer;
