import { makeStyles } from '@material-ui/core/styles';

export const RolesRadioGroupStyles = makeStyles((theme) => ({
  infoIcon: {
    paddingLeft: theme.spacing(1),
    alignSelf: 'center',
  },
  infoContainer: {
    display: 'flex',
  },
}));
