import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IEmployeeField,
  IEmployeeFieldSummary,
  FieldTypeEnum,
} from 'services/employee-field-access/employeeFieldAccess.contracts';

export enum ManageCodeEnum {
  NotDefined = 0,
  Add,
  Update,
  Delete,
}
export interface IIdbSlice {
  incomes: Array<IEmployeeField>;
  deductions: Array<IEmployeeField>;
  benefits: Array<IEmployeeField>;
  loadingState: boolean;
  manageCodes: {
    selectedRow: IEmployeeFieldSummary | null;
    mode: ManageCodeEnum;
    type: FieldTypeEnum | null;
    jobDetails: { id: number | null; name: string | null };
  };
  disableButtons: {
    deleteAction: boolean;
  };
  selectedCodeInfo: { valueType: string | null; annualLimit: number | null };
}
const initialManageCode = {
  // this is to perform the IDB management logic
  selectedRow: null,
  mode: ManageCodeEnum.NotDefined,
  type: null,
  jobDetails: { id: null, name: null },
};
const initialState: IIdbSlice = {
  incomes: [],
  deductions: [],
  benefits: [],
  loadingState: false,
  selectedCodeInfo: { valueType: null, annualLimit: null },
  manageCodes: { ...initialManageCode },
  disableButtons: {
    deleteAction: false,
  },
};

const idbSlice = createSlice({
  name: 'peopleidb',
  initialState,
  reducers: {
    updateIncomes: (state, action: PayloadAction<{ value: Array<IEmployeeField> }>) => {
      state.incomes = action.payload.value;
    },
    disableButtonsBools: (state, action: PayloadAction<{ key: 'deleteAction'; value: boolean }>) => {
      state.disableButtons[action.payload.key] = action.payload.value;
    },
    updateIDBStates: (state, action: PayloadAction<{ key: keyof IIdbSlice; value: any }>) => {
      // TODO :remove any in this case
      state[action.payload.key] = action.payload.value;
    },
    updateIDBCodes: (
      state,
      action: PayloadAction<{
        value: any;
      }>
    ) => {
      state.manageCodes = { ...state.manageCodes, ...action.payload.value };
    },
    resetIDBSelection: (state) => {
      state.manageCodes = { ...initialManageCode };
    },
    updateDeductions: (state, action: PayloadAction<{ value: Array<IEmployeeField> }>) => {
      state.deductions = action.payload.value;
    },
    updateBenefits: (state, action: PayloadAction<{ value: Array<IEmployeeField> }>) => {
      state.benefits = action.payload.value;
    },
    setIdbLoader: (state, action: PayloadAction<{ value: boolean }>) => {
      state.loadingState = action.payload.value;
    },
    reset: (state) => {
      state.benefits = initialState.benefits;
      state.incomes = initialState.incomes;
      state.deductions = initialState.deductions;
    },
  },
});

export const {
  updateDeductions,
  updateIncomes,
  resetIDBSelection,
  updateBenefits,
  reset,
  disableButtonsBools,
  updateIDBStates,
  setIdbLoader,
  updateIDBCodes,
} = idbSlice.actions;
export const peopleIdbReducer = idbSlice.reducer;
export const getPeopleIdb = (state: RootState) => state.peopleIdbReducer;
export const selectBenefits = createSelector(getPeopleIdb, ({ benefits }) => benefits);
export const selectDeductions = createSelector(getPeopleIdb, ({ deductions }) => deductions);
export const selectIncomes = createSelector(getPeopleIdb, ({ incomes }) => incomes);
export const getIdbSelectedRow = (state: RootState) => state.peopleIdbReducer.manageCodes;
export const selectSelectedRow = createSelector(getIdbSelectedRow, ({ selectedRow }) => selectedRow);
export const selectMode = createSelector(getIdbSelectedRow, ({ mode }) => mode);
export const selectType = createSelector(getIdbSelectedRow, ({ type }) => type);
export const selectJobDetailsId = createSelector(getIdbSelectedRow, ({ jobDetails }) => jobDetails?.id);
export const selectJobDetailsName = createSelector(getIdbSelectedRow, ({ jobDetails }) => jobDetails?.name);
export const getIdbSelectedRowMode = (state: RootState) => state.peopleIdbReducer.manageCodes.mode;
export const loadingStateSelector = (state: RootState) => state.peopleIdbReducer.loadingState;
export const selectDisableButtonStates = (state: RootState) => state.peopleIdbReducer.disableButtons;
export const selectDeleteAction = createSelector(selectDisableButtonStates, ({ deleteAction }) => deleteAction);
