import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPreviewSubmitProps } from 'pages/payroll/components/adjustments/page-edit/step-review/definations';
import { TaxTableTypeEnum } from 'pages/payroll/components/adjustments/table-general/enums';
import { RootState } from 'redux/store/store';
import { AdjustmentTypeEnum, TaxMethodTypeEnum } from 'services/finance/models/schema/Schema';
import AdjustmentAccessService, {
  AdjustmentListItemInterface,
  EmployeeInterface,
  GetAdjustmentDetailRequestInterface,
  GetAdjustmentDetailResponseInterface,
  GetAdjustmentListRequestInterface,
  GetAdjustmentListResponseInterface,
  GetAdjustmentViewRequestInterface,
  GetAdjustmentViewResponseInterface,
  GetCreditDetailRequestInterface,
  GetCreditDetailResponseInterface,
  GetEmployeeListRequestInterface,
  GetEmployeeListResponseInterface,
  GetEmployerTaxDetailRequestInterface,
  GetEmployerTaxDetailResponseInterface,
  GetSummaryRequestInterface,
  GetSummaryResponseInterface,
  GetWorkersCompensationDetailRequestInterface,
  GetWorkersCompensationDetailResponseInterface,
  JobInterface,
  PayInterface,
  PersistAdjustmentRequestInterface,
  PersistAdjustmentResponseInterface,
  PostAdjustmentRequestInterface,
  PostAdjustmentResponseInterface,
  ProvinceInterface,
  TaxCreditDetailInterface,
  TaxInterface,
  TaxSummaryInterface,
} from 'services/payroll/models/access/adjustmentAccess';

export interface NewDataProvinceInterface {
  id: number;
  name: string;
  code: string;
  isPrimary: boolean;
  jobList: Array<JobInterface>;
  taxList: Array<TaxInterface>;
  pay: PayInterface;
}

export interface NewDataInterface {
  id?: number;
  companyId?: number;
  employeeId?: number;
  payGroupId?: number;
  payPeriodId?: number;
  adjustmentType: AdjustmentTypeEnum;
  selectedProvinceIndex: number;
  provinceList: Array<NewDataProvinceInterface>;
  detailsReady: boolean;
  isAnyCodeAdded: boolean;
  taxDetailReady: boolean;
  taxDetailsValidating: boolean;
  detailsValidating: boolean;
  employerTaxDetail: GetEmployerTaxDetailResponseInterface;
  WCBAddItems: TaxInterface[][];
  getSummary: GetSummaryResponseInterface;
  getSummaryValidating: boolean;
  getSummaryReady: boolean;
  updatedSelectedMethod: IPreviewSubmitProps;
  isExceededGrossPay: boolean;
  isEmployeeWcbEnabled?: boolean;
  isOffCycle?: boolean;
}

export const getAdjustmentList = createAsyncThunk<
  GetAdjustmentListResponseInterface,
  GetAdjustmentListRequestInterface
>('payrollAdjustmentsSlice/getAdjustmentList', (request) => AdjustmentAccessService.getAdjustmentList(request));

export const getAdjustmentListMore = createAsyncThunk<
  GetAdjustmentListResponseInterface,
  GetAdjustmentListRequestInterface
>('payrollAdjustmentsSlice/getAdjustmentListMore', (request) => AdjustmentAccessService.getAdjustmentList(request));

export const getEmployeeList = createAsyncThunk<GetEmployeeListResponseInterface, GetEmployeeListRequestInterface>(
  'payrollAdjustmentsSlice/getEmployeeList',
  (request) => AdjustmentAccessService.getEmployeeList(request)
);

export const getAdjustmentDetail = createAsyncThunk<
  GetAdjustmentDetailResponseInterface,
  GetAdjustmentDetailRequestInterface
>('payrollAdjustmentsSlice/getAdjustmentDetail', (request) => AdjustmentAccessService.getAdjustmentDetail(request));

export const getEmployerTaxDetail = createAsyncThunk<
  GetEmployerTaxDetailResponseInterface,
  GetEmployerTaxDetailRequestInterface
>('payrollAdjustmentsSlice/getEmployerTaxDetail', (request) => AdjustmentAccessService.getEmployerTaxDetail(request));

export const persistAdjustment = createAsyncThunk<
  PersistAdjustmentResponseInterface,
  PersistAdjustmentRequestInterface
>('payrollAdjustmentsSlice/persistAdjustment', (request) => AdjustmentAccessService.persistAdjustment(request));

export const postAdjustment = createAsyncThunk<PostAdjustmentResponseInterface, PostAdjustmentRequestInterface>(
  'payrollAdjustmentsSlice/postAdjustment',
  (request) => AdjustmentAccessService.postAdjustment(request)
);

export const getAdjustmentView = createAsyncThunk<
  GetAdjustmentViewResponseInterface,
  GetAdjustmentViewRequestInterface
>('payrollAdjustmentsSlice/getAdjustmentView', (request) => AdjustmentAccessService.getAdjustmentView(request));

export const getCreditDetail = createAsyncThunk<GetCreditDetailResponseInterface, GetCreditDetailRequestInterface>(
  'payrollAdjustmentsSlice/getCreditDetail',
  (request) => AdjustmentAccessService.getCreditDetail(request)
);

export const getSummary = createAsyncThunk<GetSummaryResponseInterface, GetSummaryRequestInterface>(
  'payrollAdjustmentsSlice/getSummary',
  (request) => AdjustmentAccessService.getSummary(request)
);

export const getWorkersCompensationDetail = createAsyncThunk<
  GetWorkersCompensationDetailResponseInterface,
  GetWorkersCompensationDetailRequestInterface
>('payrollAdjustmentsSlice/getWorkersCompensationDetail', (request) =>
  AdjustmentAccessService.getWorkersCompensationDetail(request)
);

type State = {
  listAdjustments: {
    items: AdjustmentListItemInterface[];
    isReady: boolean;
    isValidating: boolean;
    canCreateAdjustment: boolean;
  };
  listEmployee: {
    items: EmployeeInterface[];
    isReady: boolean;
    isValidating: boolean;
  };
  deletedTaxTypesList: TaxTableTypeEnum[];
  newData: NewDataInterface;
  views: Record<
    number,
    {
      data: GetAdjustmentViewResponseInterface | null;
      isReady: boolean;
      isValidating: boolean;
    }
  >;
  creditViews: Record<
    number,
    {
      data: TaxCreditDetailInterface[] | null;
      newAccountNumber?: string;
      newAccountAddedDate?: string;
      isReady: boolean;
      isValidating: boolean;
    }
  >;
};

const initialState: State = {
  listAdjustments: {
    items: [],
    isReady: false,
    isValidating: false,
    canCreateAdjustment: false,
  },
  listEmployee: {
    items: [],
    isReady: false,
    isValidating: false,
  },
  deletedTaxTypesList: [],
  newData: {
    adjustmentType: AdjustmentTypeEnum.notDefined,
    provinceList: [],
    isAnyCodeAdded: false,
    detailsReady: false,
    taxDetailReady: false,
    taxDetailsValidating: false,
    detailsValidating: false,
    selectedProvinceIndex: 0,
    employerTaxDetail: {
      provinceList: [],
      validationResults: {},
    },
    isEmployeeWcbEnabled: true,
    WCBAddItems: [],
    getSummary: {
      isRemitTax: false,
      taxSummaryList: [],
      adjustmentList: [],
      validationResults: {},
    },
    getSummaryReady: false,
    getSummaryValidating: false,
    updatedSelectedMethod: {
      selectedMethod: TaxMethodTypeEnum.notDefined,
      notSpecifiedTax: [],
    },
    isExceededGrossPay: false,
  },
  views: {},
  creditViews: {},
};

export const payrollAdjustmentsSlice = createSlice({
  name: 'payrollAdjustmentsSlice',
  initialState,
  reducers: {
    setSelectedProvinceIndex: (state, action: PayloadAction<number>) => {
      state.newData.selectedProvinceIndex = action.payload;
    },
    /** Execute when we open first step of creating */
    newStart: (state, action: PayloadAction<{ companyId: number }>) => {
      state.newData = { ...initialState.newData };
      state.newData.companyId = action.payload.companyId;
    },

    initNewUpdate: (state, action: PayloadAction<Partial<Exclude<State['newData'], 'provinceList'>>>) => {
      state.newData = {
        ...state.newData,
        ...action.payload,
      };
    },

    /** Insert and keep more data */
    newUpdate: (
      state,
      action: PayloadAction<Partial<Exclude<State['newData']['provinceList'][0], 'jobList' | 'taxList'>>>
    ) => {
      state.newData.provinceList[state.newData.selectedProvinceIndex] = {
        ...state.newData.provinceList[state.newData.selectedProvinceIndex],
        ...action.payload,
      };
    },

    updateERTaxDetail: (state, action: PayloadAction<ProvinceInterface[]>) => {
      state.newData.employerTaxDetail.provinceList = action.payload;
    },

    updateERTaxes: (state, action: PayloadAction<TaxInterface[]>) => {
      state.newData.employerTaxDetail.provinceList[state.newData.selectedProvinceIndex].taxList = action.payload;
    },

    updateWCBAddItems: (state, action: PayloadAction<TaxInterface[][]>) => {
      state.newData.WCBAddItems = action.payload;
    },

    updateWCBaddEnabled: (state, action: PayloadAction<boolean>) => {
      state.newData.isEmployeeWcbEnabled = action.payload;
    },

    updateProvinces: (state, action: PayloadAction<ProvinceInterface[]>) => {
      state.newData.provinceList = action.payload;
    },

    updateGetSummaryTax: (state, action: PayloadAction<TaxSummaryInterface[]>) => {
      state.newData.getSummary.taxSummaryList = action.payload;
    },

    updateSelectedMethod: (state, action: PayloadAction<IPreviewSubmitProps>) => {
      state.newData.updatedSelectedMethod = action.payload;
    },

    updateEmployeeList: (state, action: PayloadAction<EmployeeInterface[]>) => {
      state.listEmployee.items = action.payload;
    },

    updateDeletedTax: (state, action?: PayloadAction<TaxTableTypeEnum | undefined>) => {
      state.deletedTaxTypesList = action?.payload ? [...state.deletedTaxTypesList, action.payload] : [];
    },

    updateIsAnyCodeAdded: (state, action: PayloadAction<boolean>) => {
      state.newData.isAnyCodeAdded = action.payload;
    },

    updateExceededGrossPay: (state, action: PayloadAction<boolean>) => {
      state.newData.isExceededGrossPay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdjustmentList.pending, (state) => {
        state.listAdjustments.items = [];
        state.listAdjustments.isValidating = true;
        state.listAdjustments.isReady = false;
      })
      .addCase(getAdjustmentList.fulfilled, (state, action) => {
        state.listAdjustments.isReady = true;
        state.listAdjustments.isValidating = false;
        state.listAdjustments.items = action.payload.adjustmentListItemList || [];
      })
      .addCase(getAdjustmentList.rejected, (state) => {
        state.listAdjustments.isReady = true;
        state.listAdjustments.isValidating = false;
      });

    builder
      .addCase(getAdjustmentListMore.pending, (state) => {
        state.listAdjustments.isValidating = true;
      })
      .addCase(getAdjustmentListMore.fulfilled, (state, action) => {
        state.listAdjustments.isValidating = false;
        state.listAdjustments.items = [...state.listAdjustments.items, ...action.payload.adjustmentListItemList];
      })
      .addCase(getAdjustmentListMore.rejected, (state) => {
        state.listAdjustments.isValidating = false;
      });

    builder
      .addCase(getAdjustmentDetail.pending, (state) => {
        state.newData.detailsValidating = true;
      })
      .addCase(getAdjustmentDetail.fulfilled, (state, action) => {
        state.newData.detailsReady = true;
        state.newData.detailsValidating = false;
        state.newData.isOffCycle = action.payload.adjustmentDetail?.isOffCycle;
        state.newData.provinceList =
          action.payload.provinceList?.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary)) || [];
      })
      .addCase(getAdjustmentDetail.rejected, (state) => {
        state.newData.detailsReady = true;
        state.newData.detailsValidating = false;
      });

    builder
      .addCase(getSummary.pending, (state) => {
        state.newData.getSummaryValidating = true;
        state.newData.getSummaryReady = false;
      })
      .addCase(getSummary.fulfilled, (state, action) => {
        state.newData.getSummaryReady = true;
        state.newData.getSummaryValidating = false;
        state.newData.getSummary = {
          ...action.payload,
          taxSummaryList: !action.payload.isRemitTax
            ? action.payload.taxSummaryList.map((tax) => ({
                ...tax,
                taxMethodType:
                  tax.postedAmount > tax.updatedAmount &&
                  (tax.taxMethodType === TaxMethodTypeEnum.notSpecified ||
                    tax.taxMethodType === TaxMethodTypeEnum.notDefined)
                    ? TaxMethodTypeEnum.collectDirectly
                    : tax.taxMethodType,
              }))
            : action.payload.taxSummaryList,
        };
      })
      .addCase(getSummary.rejected, (state) => {
        state.newData.getSummaryReady = true;
        state.newData.getSummaryValidating = false;
      });

    builder
      .addCase(getCreditDetail.pending, (state, action) => {
        if (state.creditViews[action.meta.arg.taxAccountId]) {
          state.creditViews[action.meta.arg.taxAccountId].isValidating = true;
        } else {
          state.creditViews[action.meta.arg.taxAccountId] = {
            data: null,
            isReady: false,
            isValidating: true,
          };
        }
      })
      .addCase(getCreditDetail.fulfilled, (state, action) => {
        state.creditViews[action.meta.arg.taxAccountId].data = action.payload.taxCreditDetailList;
        state.creditViews[action.meta.arg.taxAccountId].newAccountNumber = action.payload.newAccountNumber;
        state.creditViews[action.meta.arg.taxAccountId].newAccountAddedDate = action.payload.newAccountAddedDate;
        state.creditViews[action.meta.arg.taxAccountId].isReady = true;
        state.creditViews[action.meta.arg.taxAccountId].isValidating = false;
      })
      .addCase(getCreditDetail.rejected, (state, action) => {
        state.creditViews[action.meta.arg.taxAccountId].isValidating = false;
      });

    builder.addCase(postAdjustment.fulfilled, (state, action) => {
      state.newData.id = action.payload.adjustmentId;
    });

    builder
      .addCase(getAdjustmentView.pending, (state, action) => {
        if (state.views[action.meta.arg.id]) {
          state.views[action.meta.arg.id].isValidating = true;
        } else {
          state.views[action.meta.arg.id] = {
            data: null,
            isReady: false,
            isValidating: true,
          };
        }
      })
      .addCase(getAdjustmentView.fulfilled, (state, action) => {
        (state.views[action.meta.arg.id].data = action.payload), (state.views[action.meta.arg.id].isReady = true);
        state.views[action.meta.arg.id].isValidating = false;
      })
      .addCase(getAdjustmentView.rejected, (state, action) => {
        state.views[action.meta.arg.id].isValidating = false;
      });
  },
});

export const {
  newStart,
  newUpdate,
  updateProvinces,
  updateERTaxes,
  updateERTaxDetail,
  updateWCBAddItems,
  updateGetSummaryTax,
  updateEmployeeList,
  updateSelectedMethod,
  updateDeletedTax,
  initNewUpdate,
  setSelectedProvinceIndex,
  updateIsAnyCodeAdded,
  updateExceededGrossPay,
  updateWCBaddEnabled,
} = payrollAdjustmentsSlice.actions;
export const payrollAdjustmentsReducer = payrollAdjustmentsSlice.reducer;

// Selectors
export const payrollAdjustmentsSelector = (state: RootState) => state.payrollAdjustmentsReducer;
export const payrollAdjustmentsListSelector = (state: RootState) => state.payrollAdjustmentsReducer.listAdjustments;
export const payrollAdjustmentsListEmployeeSelector = (state: RootState) =>
  state.payrollAdjustmentsReducer.listEmployee;
export const payrollAdjustmentsNewDataSelector = (state: RootState) => state.payrollAdjustmentsReducer.newData;
export const payrollAdjustmentsViewSelector = (state: RootState) => state.payrollAdjustmentsReducer.views;
export const payrollAdjustmentsCreditViewSelector = (state: RootState) => state.payrollAdjustmentsReducer.creditViews;
export const deleteTaxTypesList = (state: RootState) => state.payrollAdjustmentsReducer.deletedTaxTypesList;
