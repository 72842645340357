import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

const initialState = {
  hasFooter: false,
  hasSidebar: true,
  hasHeader: true,
  showAdaChatLoader: false,
};

export const layoutSlice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    updateLayout: (state, action: PayloadAction<any>) => {
      state.hasFooter = action.payload;
    },
    showSidebar: (state, action: PayloadAction<boolean>) => {
      state.hasSidebar = action.payload;
    },
    showHeader: (state, action: PayloadAction<boolean>) => {
      state.hasHeader = action.payload;
    },
    toggleADAchatLoader: (state, action: PayloadAction<boolean>) => {
      state.showAdaChatLoader = action.payload;
    },
  },
});

export const { updateLayout, showSidebar, showHeader, toggleADAchatLoader } = layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
export const selectLayout = (state: RootState) => state.layoutReducer;

export const hasSidebarSelector = createSelector(selectLayout, ({ hasSidebar }) => hasSidebar);
export const hasHeaderSelector = createSelector(selectLayout, ({ hasHeader }) => hasHeader);
export const hasFooterSelector = createSelector(selectLayout, ({ hasFooter }) => hasFooter);
export const showAdaChatLoaderSelector = createSelector(selectLayout, ({ showAdaChatLoader }) => showAdaChatLoader);
