import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface IWeeklyWorkHoursInit {
  totalWeeklyHours: any;
}

const initialState: IWeeklyWorkHoursInit = {
  totalWeeklyHours: 0,
};

export const standardWeekHoursSlice = createSlice({
  name: 'standardWeekHours',
  initialState,
  reducers: {
    setStandardWorkWeekHours: (state, action: PayloadAction<IWeeklyWorkHoursInit>) => {
      state.totalWeeklyHours = action.payload.totalWeeklyHours;
    },
  },
});

export const { setStandardWorkWeekHours } = standardWeekHoursSlice.actions;
export const standardWeekHoursReducer = standardWeekHoursSlice.reducer;
export const getStandardWeekHours = (state: RootState) => state.standardWeekHoursReducer;
