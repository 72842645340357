import React, { VFC } from 'react';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import WpTypography from '@wagepoint/ui-toolkit/components/wp-typography';
import { useTranslation } from 'react-i18next';

interface IAcceptPartnerPaysModalProps {
  isOpen: boolean;
  firmName: string;
  onAccept: () => void;
  onCancel: () => void;
}

const AcceptPartnerPaysModal: VFC<IAcceptPartnerPaysModalProps> = ({ isOpen, firmName, onAccept, onCancel }) => {
  const { t } = useTranslation(['userAccounts', 'common']);
  return (
    <WpDialog
      maxWidth="xs"
      fullWidth
      open={isOpen}
      title={t('firmInviteAccepted.acceptModal.title')}
      content={
        <>
          <WpTypography>{t('firmInviteAccepted.acceptModal.description', { firmName })}</WpTypography>
          <WpTypography>{t('firmInviteAccepted.acceptModal.description2')}</WpTypography>
        </>
      }
      actions={
        <>
          <WpButton onClick={onCancel} disableRipple color="primary" variant="outlined">
            {t('common:cancel')}
          </WpButton>
          <WpButton disableRipple onClick={onAccept} color="primary" variant="contained" autoFocus>
            {t('common:accept')}
          </WpButton>
        </>
      }
    />
  );
};

export default AcceptPartnerPaysModal;
