import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { createQueryKeys } from 'util/queryKeyFactory';
import companyAccess from 'services/finance/models/access/companyAccess';
import { getApiValidationErrors } from 'util/utility';
import { BillingTypeEnum } from 'pages/accounting-dashboard/client-directory/enums';
import { useEffect } from 'react';

export const defaultBillingQueriesKeys = createQueryKeys(
  ['accounting-dashboard', 'client-directory', 'default-billing-setting'],
  {
    defaultBilling: (id: number) => ({
      key: [id],
    }),
  }
);

export const useDefaultBilling = (id?: number) => {
  const companyId = useAppSelector(selectCompanyId);
  const queryClient = useQueryClient();

  const { data: defaultClientPays } = useQuery({
    queryKey: defaultBillingQueriesKeys.defaultBilling(id ?? companyId).key,
    queryFn: async () => {
      try {
        const response = await companyAccess.getCompany({
          getById: true,
          getByCompanyCode: false,
          getByEmail: false,
          getByName: false,
          id: id ?? companyId,
          companyCode: '',
          companyName: '',
          email: '',
          includeEmployeeCounts: false,
          getByOwnerEmail: false,
          ownerEmail: '',
          includeBlacklistCompanyPersonList: false,
        });
        const { message, hasError } = getApiValidationErrors(response);
        if (hasError) {
          throw new Error(message);
        }
        return response.company;
      } catch (e) {
        throw e;
      }
    },
    retry: false,
    enabled: !!(id ?? companyId),
    select: (data) => data.defaultToClientsPayCharges,
  });

  useEffect(() => {
    return () => {
      queryClient.resetQueries({ queryKey: defaultBillingQueriesKeys.all().key });
    };
  }, []);

  if (defaultClientPays === undefined || defaultClientPays === null) {
    return BillingTypeEnum.notDefined;
  }

  return defaultClientPays ? BillingTypeEnum.clientPays : BillingTypeEnum.firmPays;
};
