const commonRoutes = {
  SecureUploadFolder: '/secureuploadfolder',
  Perferences: '/preferences',
  Subscription: '/billing',
};

export const accountingDashboardRoutes = {
  Dasboard: '/dashboard',
  ClientDirectory: '/client-directory',
  UserAccounts: '/user-accounts',
  FirmSettings: '/firm-settings',
  NotificationCenter: '/notification-center',
};

export const otherRoutes = {
  ChangeClientsPlan: '/billing/partner-plan-change/:clientId',
};

export const routes = {
  ...commonRoutes,
  ...accountingDashboardRoutes,
  ...otherRoutes,
};
