import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import partnerAccessService, {
  MaintainUserRequestInterface,
  MaintainUserResponseInterface,
} from 'services/appCore/models/access/partnerAccess';

export interface IPermissionTableSlice {
  needUpdateFlag: boolean;
  isLastItem: boolean;
}

const initialState: IPermissionTableSlice = {
  needUpdateFlag: false,
  isLastItem: false,
};

export const maintainUser = createAsyncThunk(
  'administratorTable/maintainUser',
  async (request: MaintainUserRequestInterface) => {
    const response: MaintainUserResponseInterface = await partnerAccessService.maintainUser(request);
    return response;
  }
);

export const administratorTableSlice = createSlice({
  name: 'administratorTable',
  initialState,
  reducers: {
    setNeedUpdate: (state, action: { payload: { value: boolean } }) => {
      state.needUpdateFlag = action.payload.value;
    },
    setIsLastItem: (state, action: { payload: { value: boolean } }) => {
      state.isLastItem = action.payload.value;
    },
  },
});

export const { setNeedUpdate, setIsLastItem } = administratorTableSlice.actions;
export const administratorTableReducer = administratorTableSlice.reducer;
export const selectAdministratorTable = (state: RootState) => state.administratorTableReducer;
export const selectNeedUpdateFlag = createSelector(selectAdministratorTable, ({ needUpdateFlag }) => needUpdateFlag);
export const selectIsLastItem = createSelector(selectAdministratorTable, ({ isLastItem }) => isLastItem);
