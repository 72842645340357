import { Post } from 'services/httpClient';
import {
  IAddPersonToManagerRequest,
  IAddPersonToManagerResponse,
  IGetAdministratorListRequest,
  IGetAdministratorListResponse,
  IGetSecurityAccountListRequest,
  IGetSecurityAccountListResponse,
  IGetSecurityRoleListRequest,
  IGetSecurityRoleListResponse,
  IGetSecurityRoleRequest,
  IGetSecurityRoleResponse,
  IGetUserListRequest,
  IGetUserListResponse,
  IGetUserRequest,
  IGetUserResponse,
  IMaintainPersonSecurityRoleRequest,
  IMaintainPersonSecurityRoleResponse,
  IPersistSecurityRoleRequest,
  IPersistSecurityRoleResponse,
  IPersistUserPermissionRequest,
  IPersistUserPermissionResponse,
  IPersistUserRequest,
  IPersistUserResponse,
  IResetToDefaultPermissionRequest,
  IResetToDefaultPermissionResponse,
  IValidateEmailRequest,
  IValidateEmailResponse,
  IMaintainUserRequest,
  IMaintainUserResponse,
  ICreateEmployeeProfileRequest,
  ICreateEmployeeProfileResponse,
  ITransferOwnershipRequest,
  ITransferOwnershipResponse,
  IRevokeAccessRequest,
  IRevokeAccessResponse,
  IConfigureRoleRequest,
  IConfigureRoleResponse,
  IGetEmployeeListResponse,
  IGetEmployeeListRequest,
  IGetCloseCompanyAccountRequest,
  IGetCloseCompanyAccountResponse,
  IChangeEmailRequest,
  IChangeEmailResponse,
  IChangePasswordRequest,
  IChangePasswordResponse,
  IMaintain2FARequest,
  IMaintain2FAResponse,
  IVerifySmsCodeRequest,
  IVerifySmsCodeResponse,
  IGetSignatoryListResponse,
  IGetSignatoryListRequest,
} from './securityAccess.contracts';

const servicedomain = '/appcoresecurityaccess/';
const GetUserListUrl = servicedomain + 'getUserList';
const MaintainPersonSecurityRoleUrl = servicedomain + 'maintainPersonSecurityRole';
const PersistUserUrl = servicedomain + 'persistUser';
const GetUserUrl = servicedomain + 'getUser';
const GetSecurityRoleListUrl = servicedomain + 'getSecurityRoleList';
const GetSecurityRoleUrl = servicedomain + 'getSecurityRole';
const GetSecurityAccountListUrl = servicedomain + 'getSecurityAccountList';
const AddPersonToManagerUrl = servicedomain + 'addPersonToManager';
const PersistSecurityRoleUrl = servicedomain + 'persistSecurityRole';
const PersistUserPermissionUrl = servicedomain + 'persistUserPermission';
const ResetToDefaultPermissionUrl = servicedomain + 'resetToDefaultPermission';
const ValidateEmailUrl = servicedomain + 'validateEmail';
const MaintainUserUrl = servicedomain + 'maintainUser';
const CreateEmployeeProfileUrl = servicedomain + 'createEmployeeProfile';
const GetAdministratorUrl = servicedomain + 'getAdministratorList';
const GetTransferOwnershipUrl = servicedomain + 'transferOwnership';
const RevokeAccessUrl = servicedomain + 'revokeAccess';
const ConfigureRolesUrl = servicedomain + 'configureAccess';
const GetEmployeeListUrl = servicedomain + 'getEmployeeList';
const CloseCompanyAccountUrl = servicedomain + 'closeCompanyAccount';
const ChangeEmailUrl = servicedomain + 'changeEmail';
const VerifySmsCodeUrl = servicedomain + 'verifySmsCode';
const ChangePasswordUrl = servicedomain + 'changePassword';
const MaintainTwoFactorAuthorizationUrl = servicedomain + 'maintainTwoFactorAuthorization';
const GetSignatoryListUrl = servicedomain + 'GetSignatoryList';

export const GetUserList = (request: IGetUserListRequest): Promise<IGetUserListResponse> => {
  return Post(GetUserListUrl, request);
};

export const MaintainPersonSecurityRole = (
  request: IMaintainPersonSecurityRoleRequest
): Promise<IMaintainPersonSecurityRoleResponse> => {
  return Post(MaintainPersonSecurityRoleUrl, request);
};

export const PersistUser = (request: IPersistUserRequest): Promise<IPersistUserResponse> => {
  return Post(PersistUserUrl, request);
};

export const GetUser = (request: IGetUserRequest): Promise<IGetUserResponse> => {
  return Post(GetUserUrl, request);
};

export const GetSecurityRoleList = (request: IGetSecurityRoleListRequest): Promise<IGetSecurityRoleListResponse> => {
  return Post(GetSecurityRoleListUrl, request);
};

export const GetSecurityRole = (request: IGetSecurityRoleRequest): Promise<IGetSecurityRoleResponse> => {
  return Post(GetSecurityRoleUrl, request);
};

export const GetSecurityAccountList = (
  request: IGetSecurityAccountListRequest
): Promise<IGetSecurityAccountListResponse> => {
  return Post(GetSecurityAccountListUrl, request);
};

export const AddPersonToManager = (request: IAddPersonToManagerRequest): Promise<IAddPersonToManagerResponse> => {
  return Post(AddPersonToManagerUrl, request);
};

export const PersistSecurityRole = (request: IPersistSecurityRoleRequest): Promise<IPersistSecurityRoleResponse> => {
  return Post(PersistSecurityRoleUrl, request);
};

export const PersistUserPermission = (
  request: IPersistUserPermissionRequest
): Promise<IPersistUserPermissionResponse> => {
  return Post(PersistUserPermissionUrl, request);
};

export const ResetToDefaultPermission = (
  request: IResetToDefaultPermissionRequest
): Promise<IResetToDefaultPermissionResponse> => {
  return Post(ResetToDefaultPermissionUrl, request);
};

export const ValidateEmail = (request: IValidateEmailRequest): Promise<IValidateEmailResponse> => {
  return Post(ValidateEmailUrl, request);
};

export const MaintainUser = (request: IMaintainUserRequest): Promise<IMaintainUserResponse> => {
  return Post(MaintainUserUrl, request);
};

export const CreateEmployeeProfile = (
  request: ICreateEmployeeProfileRequest
): Promise<ICreateEmployeeProfileResponse> => {
  return Post(CreateEmployeeProfileUrl, request);
};

export const GetAdminList = (request: IGetAdministratorListRequest): Promise<IGetAdministratorListResponse> => {
  return Post(GetAdministratorUrl, request);
};

export const GetSignatoryList = (request: IGetSignatoryListRequest): Promise<IGetSignatoryListResponse> => {
  return Post(GetSignatoryListUrl, request);
};

export const TransferOwnershipService = (request: ITransferOwnershipRequest): Promise<ITransferOwnershipResponse> => {
  return Post(GetTransferOwnershipUrl, request);
};

export const RevokeAccess = (request: IRevokeAccessRequest): Promise<IRevokeAccessResponse> => {
  return Post(RevokeAccessUrl, request);
};

export const ConfigureRoles = (request: IConfigureRoleRequest): Promise<IConfigureRoleResponse> => {
  return Post(ConfigureRolesUrl, request);
};

export const GetEmployeeList = (request: IGetEmployeeListRequest): Promise<IGetEmployeeListResponse> => {
  return Post(GetEmployeeListUrl, request);
};

export const CloseCompanyAccount = (
  request: IGetCloseCompanyAccountRequest
): Promise<IGetCloseCompanyAccountResponse> => {
  return Post(CloseCompanyAccountUrl, request);
};

export const ChangeEmail = (request: IChangeEmailRequest): Promise<IChangeEmailResponse> => {
  return Post(ChangeEmailUrl, request);
};

export const ChangePassword = (request: IChangePasswordRequest): Promise<IChangePasswordResponse> => {
  return Post(ChangePasswordUrl, request);
};

export const Maintain2FA = (request: IMaintain2FARequest): Promise<IMaintain2FAResponse> => {
  return Post(MaintainTwoFactorAuthorizationUrl, request);
};

export const VerifySmsCode = (request: IVerifySmsCodeRequest): Promise<IVerifySmsCodeResponse> => {
  return Post(VerifySmsCodeUrl, request);
};
