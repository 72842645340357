import { Post } from 'services/httpClient';
import {
  GetCompanyRequest,
  GetCompanyResponse,
  GetStateListRequest,
  GetStateListResponse,
  GetLocationListRequest,
  GetLocationListResponse,
  GetDepartmentListRequest,
  GetDepartmentListResponse,
  IAssociatedCompanyListRequest,
  IAssociatedCompanyListResponse,
  IPersistAssociatedCompanyRequest,
  IPersistAssociatedCompanyResponse,
} from './companyAccess.contracts';

const servicedomain = '/appcorecompanyaccess/';
const GetCompanyUrl = servicedomain + 'getcompany';
const GetStateListUrl = servicedomain + 'getstatelist';
const GetLocationListUrl = servicedomain + 'getlocationlist';
const GetDepartmentListUrl = servicedomain + 'getDepartmentList';
const PersistAssociatedCompanyUrl = servicedomain + 'persistassociatedcompany';
const AssociatedCompanyUrl = servicedomain + 'getassociatedcompanylist';

export const GetCompany = (request: GetCompanyRequest): Promise<GetCompanyResponse> => {
  return Post(GetCompanyUrl, request);
};

export const GetStateList = (request: GetStateListRequest): Promise<GetStateListResponse> => {
  return Post(GetStateListUrl, request);
};

export const GetLocationList = (request: GetLocationListRequest): Promise<GetLocationListResponse> => {
  return Post(GetLocationListUrl, request);
};

export const GetDepartmentList = (request: GetDepartmentListRequest): Promise<GetDepartmentListResponse> => {
  return Post(GetDepartmentListUrl, request);
};

export const PersistAssociatedCompany = (
  request: IPersistAssociatedCompanyRequest
): Promise<IPersistAssociatedCompanyResponse> => {
  return Post(PersistAssociatedCompanyUrl, request);
};

export const GetAssociatedCompanyList = (
  request: IAssociatedCompanyListRequest
): Promise<IAssociatedCompanyListResponse> => {
  return Post(AssociatedCompanyUrl, request);
};
