import { EmployeeStateTypeEnum, PersonTypeEnum } from 'services/person-access/personAccess.contracts';
import { IValidationResults } from 'services/shared/validationResults.contracts';
export interface IPersistPayrollRequest {
  companyId: number;
  includeAllJobs?: boolean;
  employeePayrollDataList: Array<IEmployeePayrollData>;
  validationScopeType?: number;
  havePaidThisYear?: boolean;
  stateJobConfigList?: IStateConfig[];
  employeeId?: number;
}

export interface IPersistPayrollResponse {
  validationResults: IValidationResults;
}

export interface IStateConfig {
  stateId: number;
  includeAllJobs: boolean;
  isPrimary?: boolean;
}

export interface IGetPayrollRequest {
  employeeId: number;
  companyId: number;
  fieldTypeIdList?: number[];
  havePaidThisYear?: boolean;
  includeAllJobs?: boolean;
  stateJobConfigList?: IStateConfig[];
  isInit?: boolean;
  dontIncludePrev?: boolean;
  useStateJobConfig?: boolean;
}

export interface IGetPriorPayrollRowRequest {
  stateId: number;
  jobId: number;
  employeeId: number;
  companyId: number;
  baseFieldId: number;
  amount?: number;
}
export interface IGetPriorPayrollRowResponse {
  validationResults: IValidationResults;
  employeePayrollData: IEmployeePayrollData;
}

export interface IGetPayrollResponse {
  employeePayrollDataList: Array<IEmployeePayrollData>;
  validationResults: IValidationResults;
  havePaidThisYear: boolean;
  employmentStateList: number[];
  multipleJobs: boolean;
  includeAllJobs: boolean;
  stateJobConfigList: IStateConfig[];
}

export interface IEmployeePayrollData {
  sectionTypeList: Array<ISectionType>;
  job: IField;
  includeAllJobs: boolean;
  department: IField;
  employee: IField;
  state: IField;
}

export interface ISectionType {
  id: number;
  name: string;
  amountId?: number;
  hoursId?: number;
  totalHours?: number;
  totalEmployeeAmount?: number;
  totalEmployerAmount?: number;
  fieldTypeLineItemList: Array<IFieldTypeLineItem>;
}

export interface IEmployeeFieldType {
  id: number;
  name: string;
  hours?: number;
  amountValueType?: number;
  employeeAmount?: number;
  employerAmount?: number;
  isWcbOptional?: boolean;
  fieldTypeLineItem: IFieldTypeLineItem;
}

export interface IField {
  id: number;
  name: string;
}

export interface IFieldTypeLineItem {
  name: string;
  employeeFieldTypeList: Array<IEmployeeFieldType>;
  sectionType: ISectionType;
  id: number;
}

export enum PayPeriodStatusTypeEnum {
  notDefined = 0,
  created = 1,
  waitingDataEntry = 2,
  dataEntryInProgress = 3,
  forceClosedRequested = 4,
  submitted = 5,
  submitProcessed = 6,
  waitingSubmit = 7,
  waitingApproval = 8,
  waitingSubmitOrApproval = 9,
  approved = 10,
  approvalProcessed = 11,
  undoRequested = 12,
  companyFundsWithdrawn = 13,
  employeeFundsDeposited = 14,
  finalProcessingComplete = 15,
  closed = 16,
  forceClosed = 17,
  deleteRequested = 18,
  deleted = 19,
  journalEntriesCreated = 20,
  skipRequested = 21,
  skipped = 22,
  nextPayPeriodCreated = 23,
  createNextPayPeriod = 24,
  createPayrollRegister = 25,
  createJournal = 26,
  createInvoice = 27,
  fieldAccountMaintenance = 28,
  invoiceCreated = 29,
  calculateTaxes = 30,
  workmansCompCalculated = 31,
  createBankFile = 32,
  stopDepositInitiated = 33,
  stopDepostProcessingComplete = 34,
}
export interface IPayPeriodDetail {
  id: number;
  startDate: string;
  endDate: string;
  payPeriodStatusType: PayPeriodStatusTypeEnum;
  payPeriodDays?: number;
  hoursPerPayPeriod?: number;
}
export interface IGetPayPeriodDetailRequest {
  hireDate: string;
  payGroupId?: number;
  companyId: number;
}

export interface IGetPayPeriodDetailResponse {
  isMidCycleHire: boolean;
  payPeriodDetail: IPayPeriodDetail;
  validationResults: IValidationResults;
}

export enum PayrollEntityStatusTypeEnum {
  NotDefined,
  NotStarted,
  InProgress,
  Approved,
}

export interface IPayPeriodSummary {
  payGroupId: number;
  startDate: string;
  endDate: string;
  payPeriodDays?: number;
  hoursPerPayPeriod?: number;
  hoursPerDay?: number;
  entityStatusType: PayrollEntityStatusTypeEnum;
  isAutoRun: boolean;
}
export interface IGetOpenPayPeriodSummaryListRequest {
  companyId: number;
  payGroupIdList: number[];
}

export interface IGetOpenPayPeriodSummaryListResponse {
  payPeriodSummaryList: Array<IPayPeriodSummary>;
  validationResults: IValidationResults;
}
export interface IField {
  id: number;
  name: string;
}
export interface IGetEmployeeStatusListRequest {
  personType: PersonTypeEnum;
  employeeStateType: EmployeeStateTypeEnum;
}

export interface IGetEmployeeStatusListResponse {
  employeeStateTypeList: Array<IField>;
  validationResults: IValidationResults;
}
export interface IChangePersonStatusRequest {
  companyId: number;
  personId: number;
  lastWorkingDate?: string | Date;
  targetEmployeeStateType?: EmployeeStateTypeEnum;
  getLastWorkingDate?: boolean;
  changeReturnToWorkDate?: boolean;
  returnToWorkDate?: string | Date;
  hireDate?: string | Date;
  proratedHours?: number;
  midCycleHoursPerDay?: number;
  isMidCycleTerminate?: boolean;
  midCyclePayDays?: number;
  proceedStatusChange?: boolean;
}

export enum ChangePersonStatusValidationCodeEnum {
  InvalidCompanyId = 0,
  InvalidPersonId = 1,
  NoPayPeriodFound = 2,
  LastDateGreaterThanReturnDate = 3,
  AdjustLastWorkingDate = 4,
  LastDateCannotBeNull = 5,
}

export interface IChangePersonStatusResponse {
  isRunOffCycleAvailable: boolean;
  canRunExtraPayroll: boolean;
  allowedLastWorkingDate?: string;
  allowedEmployeeStateType: EmployeeStateTypeEnum;
  paidInOtherPayroll?: boolean;
  isMidCycleHire: boolean;
  isMidCycle: boolean;
  vacationBalance?: number;
  payPeriodSummary: IPayPeriodSummary;
  validationResults: IValidationResults<ChangePersonStatusValidationCodeEnum>;
}

export enum ValidationScopeTypeEnum {
  NotDefined,
  Partial,
  Complete,
}

export interface IGetPriorPayrollReportRequest {
  employeeId: number;
  companyId: number;
}

export interface IGetPriorPayrollReportResponse {
  reportContent: string;
  validationResults: IValidationResults;
}

export interface IDeletePriorPayrollRequest {
  companyId: number;
  employeeId: number;
  stateIdList: number[];
}
export interface IDeletePriorPayrollResponse {
  validationResults: IValidationResults;
}

export enum TaxesEnum {
  Wcb = 106,
}
