import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import AccountingManagerService, {
  ConnectRequestInterface,
  IntegrationTypeEnum,
} from 'services/integrations-access/accountingManagerAccess';

interface IAllStatuses {
  isXeroValidating: boolean;
  isQboValidating: boolean;
  fulfilledXero: boolean;
  fulfilledQbo: boolean;
  errorXero?: string | unknown;
  errorQbo?: string | unknown;
  didTokenExpired: boolean;
  showXeroConnectBanner: boolean;
}

const initialState: IAllStatuses = {
  isXeroValidating: false,
  isQboValidating: false,
  fulfilledXero: false,
  fulfilledQbo: false,
  didTokenExpired: false,
  showXeroConnectBanner: false,
};

export const connectToXeroAction = createAsyncThunk(
  '/addons/connectToXero',
  async (request: Omit<ConnectRequestInterface, 'integrationType'>) => {
    return AccountingManagerService.connect({ ...request, integrationType: IntegrationTypeEnum.xero });
  }
);

export const connectToQBOAction = createAsyncThunk(
  '/addons/connectToQbo',
  async (request: Omit<ConnectRequestInterface, 'integrationType'>) => {
    const response = await AccountingManagerService.connect({ ...request, integrationType: IntegrationTypeEnum.qbo });
    return response;
  }
);

export const connectAddonsSlice = createSlice({
  name: 'addons_connect',
  initialState,
  reducers: {
    setRefreshTokenStatus: (state, action) => {
      state.didTokenExpired = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectToXeroAction.pending, (state) => {
        state.isXeroValidating = true;
      })
      .addCase(connectToXeroAction.fulfilled, (state) => {
        state.fulfilledXero = true;
        state.isXeroValidating = false;
      })
      .addCase(connectToXeroAction.rejected, (state, action) => {
        state.errorXero = action.payload;
        state.fulfilledXero = false;
        state.isXeroValidating = false;
      });
    builder
      .addCase(connectToQBOAction.pending, (state) => {
        state.isQboValidating = true;
      })
      .addCase(connectToQBOAction.fulfilled, (state) => {
        state.fulfilledQbo = true;
        state.isQboValidating = false;
      })
      .addCase(connectToQBOAction.rejected, (state, action) => {
        state.errorQbo = action.payload;
        state.fulfilledQbo = false;
        state.isQboValidating = false;
      });
  },
});

export const selectDidTOkenExpire = (state: RootState) => state.connectIntegrationReducer.didTokenExpired;
export const { setRefreshTokenStatus } = connectAddonsSlice.actions;
export const connectIntegrationReducer = connectAddonsSlice.reducer;
export const selectShowXeroConnectBanner = (state: RootState) => state.connectIntegrationReducer.showXeroConnectBanner;
