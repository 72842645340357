import { createAsyncThunk, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import billingAccess, {
  DiscountCardInterface,
  PartnerHandlingCardInterface,
  PlanCardInterface,
  SubscriptionHistoryInterface,
  SubscriptionPlanInterface,
} from 'services/finance/models/access/billingAccess';
import { CompanyLiteInterface } from 'services/finance/models/access/companyAccess';
import { RootState } from '../../store/store';

export const getPlanList = createAsyncThunk('addNewClient/getPlanList', billingAccess.getSubscriptionPlanList);

export const getPlanHistory = createAsyncThunk('addNewClient/getPlanHistory', billingAccess.getSubscriptionHistory);

export const getOverview = createAsyncThunk(
  'addNewClient/getOverview',
  ({ companyId, companyCode }: { companyCode: string; companyId: number }) =>
    billingAccess.getOverview({
      overviewCriteria: {
        companyId,
        companyCode,
        getPartnerHandlingCard: true,
        getPlanCard: true,
        getPartnerChangeCard: false,
        getDisconnectInfo: false,
        getExtendedPlan: false,
        getChameleonCards: false,
        getReferralCard: false,
        getBankAccountCard: false,
        getBillingInfoCard: false,
        getDiscountCard: true,
        isPartner: false,
      },
    })
);

interface AddNewClientSliceState {
  foundCompany: CompanyLiteInterface | null;
  subscriptionPlanList: Array<SubscriptionPlanInterface>;
  partnerHandlingCard: PartnerHandlingCardInterface | null;
  planCard: PlanCardInterface | null;
  lastSubscriptionPlan: SubscriptionHistoryInterface | null;
  discountCard: DiscountCardInterface | null;
}

const initialState: AddNewClientSliceState = {
  foundCompany: null,
  subscriptionPlanList: [],
  partnerHandlingCard: null,
  planCard: null,
  lastSubscriptionPlan: null,
  discountCard: null,
};

const addNewClient = createSlice({
  name: 'addNewClient',
  initialState,
  reducers: {
    updateFoundCompany(state, action: PayloadAction<CompanyLiteInterface | null>) {
      state.foundCompany = action.payload;
    },
    updatePartnerHandlingCard(state, action: PayloadAction<PartnerHandlingCardInterface | null>) {
      state.partnerHandlingCard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlanList.fulfilled, (state, action) => {
        state.subscriptionPlanList = action.payload.subscriptionPlanList ?? [];
      })
      .addCase(getOverview.fulfilled, (state, action) => {
        state.partnerHandlingCard = action.payload.overviewContainer?.partnerHandlingCard ?? null;
        state.planCard = action.payload.overviewContainer?.planCard ?? null;
        state.discountCard = action.payload.overviewContainer?.discountCard ?? null;
      })
      .addCase(getPlanHistory.fulfilled, (state, action) => {
        state.lastSubscriptionPlan = action.payload.subscriptionHistoryList?.[0] ?? null;
      });
  },
});

export const { updateFoundCompany, updatePartnerHandlingCard } = addNewClient.actions;
export const addNewClientReducer = addNewClient.reducer;
export const selectLayout = (state: RootState) => state.addNewClientReducer;

export const selectClientData = createSelector(selectLayout, ({ foundCompany }) => foundCompany);
