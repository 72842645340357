import { IValidationResults } from 'services/shared/validationResults.contracts';
import {
  EarningType,
  IOtherMonies,
} from '../payroll-record-of-employment-access/payrollRecordOfEmploymentAccess.contracts';
export interface PersistCompanyFieldRequest {
  referenceBaseFieldId?: number;
  field: ICompanyField;
}

export interface PersistCompanyFieldResponse {
  fieldId: number;
  validationResults: IValidationResults;
}

export interface DuplicateCompanyFieldRequest {
  fieldId: number;
  field: ICompanyField;
}

export interface DuplicateCompanyFieldResponse {
  fieldId: number;
  validationResults: IValidationResults;
}

export interface ValidateCompanyFieldRequest {
  field: ICompanyField;
}

export interface ValidateCompanyFieldResponse {
  validationResults: IValidationResults;
}

export interface GetCompanyFieldListRequest {
  companyId: number;
  fieldType: FieldTypeEnum;
  fieldStateType?: FieldStateTypeEnum;
  fieldOriginType?: FieldOriginTypeEnum;
  fieldStatusType?: FieldStatusTypeEnum;
  getTotalCount: boolean;
  getByValueCodeType?: boolean;
  insurable?: boolean;
  codeType?: string;
  getByName?: boolean;
  nameStartsWith?: string;
  getByCode?: boolean;
  codeStartsWith?: string;
  insurableOnly?: boolean;
  payableOnly?: boolean;
  taxableOnly?: boolean;
  sortBy?: number;
  sortDescending?: boolean;
  skip: number;
  take: number;
}

export interface GetCompanyFieldListResponse {
  totalCount: number;
  fieldSummaryList: Array<ICompanyFieldSummary>;
  validationResults: IValidationResults;
  hideDeductionsFlag?: boolean;
}

export interface GetCompanyFieldRequest {
  fieldId: number;
  companyId: number;
}

export interface GetCompanyFieldResponse {
  field: ICompanyField;
  validationResults: IValidationResults;
}

export interface GetAssociatedIncomeFieldListRequest {
  companyId: number;
}

export interface GetAssociatedIncomeFieldListResponse {
  associatedIncomeFieldSummaryList: Array<IAssociatedIncomeFieldSummary>;
  validationResults: IValidationResults;
}

export interface PersistOverrideFieldListRequest {
  companyId: number;
  applyForAll: boolean;
  overrideFieldList: Array<IOverrideField>;
}

export interface PersistOverrideFieldListResponse {
  validationResults: IValidationResults;
}

export interface ValidateOverrideFieldListRequest {
  companyId: number;
  overrideFieldList: Array<IOverrideField>;
}

export interface ValidateOverrideFieldListResponse {
  overrideValidationSummaryList: Array<IOverrideValidationSummary>;
  validationResults: IValidationResults;
}

export interface GetInitialStateRequest {
  countryType: CountryTypeEnum;
  countryId: number;
  companyId?: number;
  checkNbOrNlJobLocations?: boolean;
}

export interface GetInitialStateResponse {
  hasEmployees: boolean;
  hasContractors: boolean;
  hasQcEmployees: boolean;
  hasNonQcEmployees: boolean;
  hasNbOrNlJobLocations: boolean;
  provinceList: Array<IProvince>;
  formList: Array<IForm>;
  validationResults: IValidationResults;
}

export interface GetTaxDetailsRequest {
  stateId?: number;
}

export interface GetTaxDetailsResponse {
  stateCalculationOption: IStateCalculationOption;
  validationResults: IValidationResults;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetRoeMoneyCodeListRequest {}

export interface GetRoeMoneyCodeListResponse {
  roeMoneyCodeList: Array<IRoeMoneyCode>;
  validationResults: IValidationResults;
}

export interface ChangeFieldStatusRequest {
  fieldId: number;
  companyId: number;
  fieldStatusType: number;
}

export interface ChangeFieldStatusResponse {
  validationResults: IValidationResults;
}

export interface IRoeMoneyCode {
  id: number;
  code: string;
}

export interface ICompanyField {
  id?: number;
  companyId?: number;
  name?: string;
  frenchName?: string;
  glExpenseAccount?: string;
  glLiabilityAccount?: string;
  code?: string;
  description?: string;
  insurableHours?: boolean;
  insurableEarnings?: boolean;
  countryType?: number;
  fieldType?: number;
  fieldSubType?: number;
  federalTaxFlags?: number;
  isPayable?: boolean;
  baseFieldId?: number;
  notes?: string;
  codeType?: number;
  rateCoefficient?: any;
  fieldStatusType?: number;
  fieldId?: number;
  contributionEntryType?: number;
  fieldCalculationInclusions?: string;
  fieldOriginType?: FieldOriginTypeEnum;
  useMinimumWage?: boolean;
  explicitFieldTypeId?: number;
  isDefaultForTypeOfHours?: boolean;
  stateConcernList?: Array<IStateConcern>;
  formList?: Array<IForm>;
  incomeCodeNameList?: any;
  isConsumedInPayroll?: boolean;
  isEmployeeOverride?: boolean;
  includedIn17C?: boolean;
  moneyCodeId?: any;
  applyForAllFlag?: boolean;
  overrideFieldList?: any;
  selectProvince?: boolean;
  jobColumn?: boolean;
  otherMonies?: IOtherMonies | null;
  reduceTaxableIncome?: boolean;
  isCustomised?: boolean;
}

export interface IStateConcern {
  id: number;
  stateId?: number;
  coversUnspecifiedStates?: boolean;
  calculationFlags: number;
}

export interface IProvince {
  id: number;
  name: string;
}

export interface IFormBox {
  formId: number;
  formName?: string;
  formBoxId: string;
}

export interface IForm {
  id: number;
  name: string;
  displayName: string;
  countryId: number;
  formBoxValueList: Array<IFormBoxValue>;
}

export interface IFormBoxValue {
  id: number;
  name: string;
}

export interface ICompanyFieldSummary {
  id?: number;
  name?: string;
  code?: string;
  description?: string;
  baseFieldId?: number;
  codeType?: number;
  fieldSubType: number;
  fieldStatusType?: number;
  isEnabled?: boolean;
  allowDisable?: boolean;
  allowDelete?: boolean;
  associationKey?: string;
  fieldOriginType?: number;
  fieldType?: number;
  contributionEntryType?: number;
  isDefaultForTypeOfHours?: boolean;
  explicitFieldTypeId?: number;
  formList?: Array<IForm>;
  sortDirection?: boolean;
  isSelected?: boolean;
  insurable?: boolean;
  moneyCodeId?: number;
  fieldId?: number;
  earningType?: EarningType;
}

export interface IStateCalculationOption {
  id: number;
  stateId?: number;
  calculationFlags: number;
}

export interface IAssociatedIncomeFieldSummary {
  explicitFieldTypeId: number;
  incomeFieldList: Array<IIncomeField>;
}

export interface IIncomeField {
  fieldId: number;
  name: string;
  IsDefaultForTypeOfHours: boolean;
  isEmployeeOverride: boolean;
}

export interface IOverrideField {
  fieldId: number;
  explicitFieldTypeId: number;
}

export interface IOverrideValidationSummary {
  name: string;
  fieldId: number;
  explicitFieldTypeId: number;
  isCustomized: boolean;
}

export enum FieldStateTypeEnum {
  all = 0,
  enabled = 1,
  disabled = 2,
}

export enum ValidationSeverityEnum {
  NotDefined = 0,
  Passed = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
}

export enum SortByEnum {
  name = 0,
  code = 1,
  codeType = 2,
  custom = 3,
}

export enum FieldSubTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Aggregate = 2,
  Garnishment = 3,
}

export enum FieldOriginTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Original = 2,
  Duplicate = 3,
  Edit = 4,
  Custom = 5,
}

export enum formTypeEnum {
  t4 = 2,
  t4A = 3,
  rl1 = 4,
}

export enum CountryTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  US = 2,
  CA = 3,
}

export enum FieldTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Wage = 2,
  AdditionalIncome = 3,
  Deduction = 4,
  WageHours = 5,
  HourlyRate = 6,
  MicroDeposit = 7,
  Benefit = 8,
  Sales = 9,
  Tax = 10,
  AdditionalIncomeHours = 11,
}

export enum CodeTypeEnum {
  NotDefined = 0 as any,
  HoursWithCalcAmounts = 1 as any,
  AmountWithHours = 2 as any,
  AmountOnly = 3 as any,
}

export enum FieldStatusTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  DataEntryInProgress = 2,
  PendingApproval = 3,
  Active = 4,
  Rejected = 5,
  Deleted = 6,
}

export enum ContributionEntryTypeEnum {
  NotDefined = 0 as any,
  NotSpecified = 1 as any,
  Amount = 2 as any,
  Percent = 3 as any,
  AmountOrPercent = 4 as any,
}

export enum TaxFlagsEnum {
  None = 0,
  Insurable = 1,
  Pensionable = 2,
  Taxable = 4,
  Unemployment = 8,
}

export enum ActionTypeEnum {
  notDefined = 0,
  new = 1,
  customize = 2,
  edit = 3,
  duplicate = 4,
  approve = 5,
  reject = 6,
  enable = 7,
  disable = 8,
  validate = 9,
}

export enum AttributeTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  IncomeTax = 2,
  CppQpp = 3,
  Ei = 4,
  Qpop = 5,
  InsurableHours = 6,
  InsurableEarnings = 7,
  SocialSecurity = 8,
  Medicare = 9,
  Taxable = 10,
}

export enum roeIncludeFlagsEnum {
  Block19 = 11,
  Block17c = 12,
  Block15cand15b = 13,
}

export enum StateCalculationFlagsEnum {
  None = 0,
  VacationPay = 1,
  WorkmansCompensation = 2,
  StatutoryPay = 4,
  EmployerHealthTax = 8,
  HealthServicesFund = 16,
  PensionPlan = 32,
  ParentalInsurancePlan = 64,
  ProvincialIncomeTax = 128,
}

export enum ExplicitFieldTypesEnum {
  NotDefined = 0,
  NotSpecified = 1,
  GrossPay = 2,
  NetPay = 3,
  RegularHours = 4,
  RegularPay = 5,
  RegularRate = 6,
  OtHours = 7,
  OtPay = 8,
  WorkmansComp = 9,
  WorkmansCompRate = 10,
  TotalWageHours = 11,
  TotalAdditionalIncomes = 12,
  HolidayPay = 13,
  VacationPay = 14,
  StatPay = 15,
  WorkedOnStatPay = 16,
  SickPay = 17,
  DoubleOvertimePay = 18,
  TotalDeductions = 19,
  TotalBenefits = 20,
}

export enum PaginationEnum {
  Take = 100,
}

export enum StateEnum {
  Quebec = 2,
  Ontario = 3,
  BritishColumbia = 6,
  Manitoba = 5,
  NewBrunswick = 9,
  NewfoundLand = 11,
}

export enum CodeDetailsEnum {
  Name = 20,
  Description = 40,
  FormValues = 4,
  Edit = 30,
  DirectTipsCA = 51,
  VacationPay = 188,
  VacationPayTimeTaken = 55,
}

export enum AmountsPageEnum {
  AssignPeople = 'assignPeople',
  AddAmounts = 'addAmounts',
  UpdateAmounts = 'updateAmounts',
}

export enum RateCoefficientEnum {
  One = 1,
  LessThanTwo = 1.5,
  Two = 2,
}

export enum FieldEnum {
  Income = 'income',
  Deduction = 'deduction',
  Benefit = 'benefit',
}

export enum CodeEnum {
  Custom = 'custom',
  PreSeeded = 'PreSeeded',
}
