import { selectConfig } from 'redux/slice/configSlice';
import { useAppSelector } from 'redux/hooks/hook';
import { useHistory } from 'react-router-dom';
import { isFromAlfredSelector } from 'redux/slice/fromAlfredSlice';
import { CompanyMenuEnum } from 'services/appCore/models/access/uiConfigAccess';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

export const useViewPermission = (path: string) => {
  const { permissionConfig } = useAppSelector(selectConfig);
  const isFromAlfred = useAppSelector(isFromAlfredSelector);

  let isViewOnlyPermission = false;
  let isDisabled = false;
  let isVisible = false;

  if (permissionConfig) {
    if (path in permissionConfig) {
      isViewOnlyPermission = !!permissionConfig[path]?.isViewOnly || isFromAlfred;
      isDisabled = !!permissionConfig[path]?.isDisabled;
      isVisible = !!permissionConfig[path]?.isVisible;
    }
  }

  return { isViewOnlyPermission, isDisabled, isVisible };
};

export const useRouteViewPermission = () => {
  const { location } = useHistory();
  const pathName = '/employertaxes';
  return useViewPermission(location.pathname.includes('employertaxes') ? pathName : location.pathname);
};

export const useViewPermissionByPartPath = () => {
  const { permissionConfig } = useAppSelector(selectConfig);
  const { location } = useHistory();
  const isFromAlfred = useAppSelector(isFromAlfredSelector);

  const { feature } = useFeatureFlag();
  const isUpdatedLogicFeature = feature(FEATURE_FLAG_KEYS.RedirectByPermissionUpdated);

  const isPreferences = location.pathname.includes('/preferences');

  if (location.pathname === '/' || isPreferences) {
    return { isViewOnlyPermission: false, isDisabledPermission: false, isVisiblePermission: true };
  }

  const comparePaths = (path: string) => {
    const regex = /\/\b([^/]+)\b/g;

    const preparePath = (arr?: string[] | null) => {
      // we need to remove all '-' from path to compare, because in BE data (permissionConfig) we have path like '/company-details/companydetails/about'.
      // But in the app we have 'companydetails/about'.
      // In order to check if path is in location we should remove all '-' from path and make it unique
      return Array.from(new Set(arr?.map((partPath) => partPath.replaceAll('-', '')))).join('');
    };

    if (!isUpdatedLogicFeature) {
      const splittedPath = path.match(regex);
      const splittedLocation = location.pathname.match(regex);

      return splittedPath?.some((partPath) => splittedLocation?.includes(partPath));
    }

    const preparedPath = preparePath(path.match(regex));
    const preparedLocation = preparePath(location.pathname.match(regex));

    return preparedLocation.includes(preparedPath);
  };

  for (const path in permissionConfig) {
    const element = permissionConfig[path];
    const modulePath = '/' + path.split('/')[1];

    if ((location.pathname === modulePath && path === modulePath) || comparePaths(path)) {
      return {
        isViewOnlyPermission: element?.isViewOnly || isFromAlfred,
        isDisabledPermission: element?.isDisabled,
        isVisiblePermission: element?.isVisible,
      };
    }
  }

  return { isViewOnlyPermission: false, isDisabledPermission: false, isVisiblePermission: true };
};

/**
 * Hook to return boolean value for all levels of permission for a given menu item
 * @param menuItem: can be enum value for menuitems defined in src/services/appCore/models/access/uiConfigAccess.ts
 */
export const useGetPermissionsOfMenuItem = (menuItem: CompanyMenuEnum) => {
  let pathString = '';

  switch (menuItem) {
    case CompanyMenuEnum.about: {
      pathString = '/companydetails/about';
      break;
    }

    case CompanyMenuEnum.banking: {
      pathString = '/companydetails/banking';
      break;
    }

    case CompanyMenuEnum.accountVerification: {
      pathString = '/companydetails/account-verification';
      break;
    }
  }

  return { ...useViewPermission(pathString) };
};
