import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import PendingCard from './components/pendingCard';
import PendingCardApprover from './components/pendingCardApprover';
import AcceptedCard from './components/acceptedCard';
import ToBeInvited from './components/toBeInvitedCard';
import WpTypography from '@wagepoint/ui-toolkit/components/wp-typography';
import CompanyLinkAccess from 'services/finance/models/access/companyLinkAccess';
import { CompanySummaryInterface, PartnerStatusTypeEnum } from 'services/finance/models/access/companyAccess';
import { selectCompanyInvitationList } from 'redux/slice/accountingFirmSlice';
import { useAppSelector } from 'redux/hooks/hook';
import { useTranslation } from 'react-i18next';
import { GlobalStyles } from 'styles/globalStyles';

const FirmInvited: React.FC = () => {
  const { t } = useTranslation(['companySettings']);
  const globalClass = GlobalStyles();
  const companyInvitationList = useAppSelector(selectCompanyInvitationList);

  const hasAcceptedInvitation = companyInvitationList?.some(({ partnerStatusType }) =>
    [PartnerStatusTypeEnum.accepted, PartnerStatusTypeEnum.inviteSent].includes(partnerStatusType)
  );

  const hasAcceptedInvitationFromCompany = companyInvitationList?.some(
    ({ partnerStatusType }) => partnerStatusType === PartnerStatusTypeEnum.accepted
  );

  useEffect(() => {
    if (!hasAcceptedInvitationFromCompany) return;

    companyInvitationList?.forEach(({ partnerStatusType, invitationId, partnerId }) => {
      // if any card exists, then revoke it automatically
      if (partnerStatusType === PartnerStatusTypeEnum.inviteSent) {
        CompanyLinkAccess.revokePartnerAccess({
          invitationId: invitationId ?? 0,
          partnerCompanyId: partnerId ?? 0,
        });
      }
    });
  }, [hasAcceptedInvitationFromCompany]);

  const getCardComponent = (item: CompanySummaryInterface) => {
    switch (item.partnerStatusType) {
      case PartnerStatusTypeEnum.accepted:
        return <AcceptedCard item={item} />;
      case PartnerStatusTypeEnum.inviteSent:
        return <PendingCard item={item} />;
      case PartnerStatusTypeEnum.partnerRequestReceived:
        return <PendingCardApprover item={item} />;
    }
  };

  return (
    <>
      <WpTypography variant="h1" className={globalClass.mb2}>
        {t('settings.accountingFirmTab')}
      </WpTypography>

      {companyInvitationList?.map((item) => (
        <Box key={`card-${item.partnerId}`} mb={2}>
          {getCardComponent(item)}
        </Box>
      ))}

      {!hasAcceptedInvitation && <ToBeInvited />}
    </>
  );
};

export default FirmInvited;
