import { ContentTemplateTypeEnum } from 'services/notification/models/schema/Schema';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';

export interface IGetCompanySettingsInitialStateRequest {
  companyId: number;
}

export interface IPersistCompanySettingsRequest {
  affectedAutoRunPayGroups?: Array<string>;
  companySettings: ICompanySettings;
}

export interface IGetCompanySettingsInitialStateResponse {
  companySettings: ICompanySettings;
  validationResults?: any;
}

export interface IPersistCompanySettingsResponse {
  validationResults?: any;
}

export interface IPersistLogoIdRequest {
  companyId: number;
  logoId?: number;
  useLogoAsFavicon: boolean;
}

export interface IPersistLogoIdResponse {
  validationResults?: any;
}

export interface ICompanySettings {
  companyId: number;
  timeSheetFormatType: TimeSheetFormatTypeEnum;
  printStockType: PrintStockTypeEnum;
  logoId?: number;
  logoUploadDate?: string;
  useLogoAsFavicon?: boolean;
  approvalProcess: IApprovalProcess;
  companyToggleList: Array<ICompanyToggle>;
}

export interface ICompanyToggle {
  id: number;
  companyToggleType: CompanyToggleTypeEnum;
  isOn: boolean;
  isLocked?: boolean;
  createdDate: Date | string;
  updatedDate?: Date | string;
}

export interface IApprovalProcess {
  id?: number;
  priorityOneSecurityAccountId?: number;
  priorityTwoSecurityAccountId?: number;
  priorityOneUserName?: string;
  priorityTwoUserName?: string;
}

export enum TimeSheetFormatTypeEnum {
  notDefined = 0,
  notSpecified = 1,
  decimal = 2,
  time = 3,
}

export enum PrintStockTypeEnum {
  notDefined = 0,
  notSpecified = 1,
  sageLaser = 2,
  quickbooksVoucher = 3,
  quickbooksStandard = 4,
}

export enum CompanyToggleTypeEnum {
  notDefined = 0,
  jobTitles = 1,
  departments = 2,
  multipleLocations = 3,
  payByDirectDeposit = 4,
  taxRemitted = 5,
  autoSubmitTaxForms = 6,
  workmansComp = 7,
  delayPayStubs = 8,
  windowedEnvelopes = 9,
  showTaxes = 10,
  showBenefits = 11,
  showVacation = 12,
  twoStepApproval = 13,
  notifyProcessing = 14,
  notifiyCalendar = 15,
  notifyPayrollApproved = 16,
  notifyReportsAvailable = 17,
  notifyEmployeeBirthday = 18,
  notifyEmployeeAnniversary = 19,
  notifyLatePayroll = 20,
  adjustVacationHoursInRegularPay = 21,
}

export enum ValidationSeverityEnum {
  NotDefined = 0,
  Passed,
  Information,
  Warning,
  Error,
}

export interface ICompanySettingsState {
  companySettings: Partial<ICompanySettings>;
  validationResults: any;
}

export interface IGetCompanyNotificationsSettingsRequest {
  companyId: number;
}

export interface INotificationRoleList {
  securityRoleTypeId: SecurityRoleTypeEnum;
  isApplicable: boolean;
  isEnable: boolean;
  includeEmployees: boolean;
  id?: number;
}
export interface ICompanyNotificationsList {
  id: number;
  contentTemplateType: ContentTemplateTypeEnum;
  doNotify: boolean;
  notificationRoleList: Array<INotificationRoleList>;
}
export interface IGetCompanyNotificationsSettingsResponse {
  notifyLimitPayrollVariance?: number;
  companyNotificationSettingsList: Array<ICompanyNotificationsList>;
  isTwoStepApprovalOn?: boolean;
  validationResults?: any;
}

export interface IPersistCompanyNotificationsSettingsRequest {
  notifyLimitPayrollVariance: any;
  companyId: number;
  companyNotificationSettingsList: Array<ICompanyNotificationsList>;
}

export interface IPersistCompanyNotificationsSettingsResponse {
  validationResults?: any;
}
