export enum ValidationSeverityEnum {
  NotDefined = 0,
  Passed = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
}

export interface IValidationResult<TValidationCode = number> {
  severity: ValidationSeverityEnum;
  validationMessage: string;
  validationCode: TValidationCode;
  validationTag: string;
  isValid: boolean;
}

export interface IValidationResults<TValidationCode = number> {
  validationResultList: IValidationResult<TValidationCode>[];
}
