import { Post } from 'services/httpClient';
import {
  GetWcbRateListRequest,
  GetWcbRateListResponse,
  GetWcbRateRequest,
  GetWcbRateResponse,
  GetProvinceListRequest,
  GetProvinceListResponse,
  PersistWcbRateRequest,
  PersistWcbRateResponse,
  DeleteWcbRateRequest,
  DeleteWcbRateResponse,
  DisableWcbRateRequest,
  DisableWcbRateResponse,
} from './workersCompensationAccess.contracts';
const servicedomain = '/payrollWcbRateAccess/';

const GetWcbRateListUrl = servicedomain + 'getWcbRateList';
const GetWcbRateUrl = servicedomain + 'getWcbRate';
const GetProvinceListUrl = servicedomain + 'getProvinceList';
const PersistWcbRateUrl = servicedomain + 'persistWcbRate';
const DeleteWcbRateUrl = servicedomain + 'deleteWcbRate';
const DisableWcbRateUrl = servicedomain + 'disableWcbRate';

export const GetWcbRate = (request: GetWcbRateRequest): Promise<GetWcbRateResponse> => {
  return Post(GetWcbRateUrl, request);
};

export const GetWcbRateList = (request: GetWcbRateListRequest): Promise<GetWcbRateListResponse> => {
  return Post(GetWcbRateListUrl, request);
};

export const GetProvinceList = (request: GetProvinceListRequest): Promise<GetProvinceListResponse> => {
  return Post(GetProvinceListUrl, request);
};

export const PersistWcbRate = (request: PersistWcbRateRequest): Promise<PersistWcbRateResponse> => {
  return Post(PersistWcbRateUrl, request);
};

export const DeleteWcbRate = (request: DeleteWcbRateRequest): Promise<DeleteWcbRateResponse> => {
  return Post(DeleteWcbRateUrl, request);
};

export const DisableWcbRate = (request: DisableWcbRateRequest): Promise<DisableWcbRateResponse> => {
  return Post(DisableWcbRateUrl, request);
};

export const GetWcbRateListRedux = (request: GetWcbRateListRequest): Promise<GetWcbRateListResponse> => {
  return Post(GetWcbRateListUrl, request);
};
