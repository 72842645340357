import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { getFirstTimeUserInitialStateData } from 'redux/slice/firstTimeUserSlice';
import { IGetInitialStateResponse } from 'services/payroll-company-onboard-access/payrollCompanyOnboardAccess.contracts';
import { createQueryKeys } from 'util/queryKeyFactory';
import { commonStaleTime } from 'util/utility';

export const getInitialStateKeys = createQueryKeys(['get-initialState'], {
  service: (companyId: number) => ({
    key: [companyId],
  }),
});

export const useGetInitialStateHook = () => {
  const companyId = useAppSelector(selectCompanyId);
  const dispatch = useAppDispatch();

  const enableThisQueryWhen = React.useMemo(() => {
    return !!companyId;
  }, [companyId]);

  return useQuery({
    queryKey: getInitialStateKeys.service(companyId).key,
    queryFn: async () => {
      try {
        const response = await dispatch(getFirstTimeUserInitialStateData());
        return response.payload as IGetInitialStateResponse;
      } catch (error) {
        throw error;
      }
    },
    enabled: enableThisQueryWhen,
    staleTime: commonStaleTime,
  });
};
