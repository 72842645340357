import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface IpayrollFrequencyTypeInit {
  frequencyType: string;
}
const initialState: IpayrollFrequencyTypeInit = {
  frequencyType: '',
};

export const payrollFrequencySlice = createSlice({
  name: 'payrollFrequencySlice',
  initialState,
  reducers: {
    setFrequencyType: (state, action: { payload: string }) => {
      state.frequencyType = action.payload;
    },
    clearFrequencyType: (state) => {
      state.frequencyType = initialState.frequencyType;
    },
  },
});

export const { setFrequencyType, clearFrequencyType } = payrollFrequencySlice.actions;
export const payrollFrequencyReducer = payrollFrequencySlice.reducer;
export const payrollFrequencyStates = (state: RootState) => state.payrollFrequencyReducer;
