import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { CompanyViewTypeEnum } from 'services/ui-config-access/uiConfigAccess.contracts';

export interface IFromAlfredData {
  userIdFromAlfed: number;
  companyViewType: CompanyViewTypeEnum;
}

const initialState = {
  isFromAlfred: false,
  userIdFromAlfed: 0,
  companyViewType: CompanyViewTypeEnum.notDefined,
  roeEnabled: true,
};

export const fromAlfredSlice = createSlice({
  name: 'userFromAlfred',
  initialState,
  reducers: {
    updateFromAlfred: (state, action: PayloadAction<IFromAlfredData>) => {
      state.userIdFromAlfed = action.payload.userIdFromAlfed;
      state.companyViewType = action.payload.companyViewType;
      state.isFromAlfred = true;
    },
  },
});

export const { updateFromAlfred } = fromAlfredSlice.actions;
export const fromAlfredReducer = fromAlfredSlice.reducer;
export const selectFromAlfred = (state: RootState) => state.fromAlfredReducer;

export const isFromAlfredSelector = createSelector(selectFromAlfred, ({ isFromAlfred }) => isFromAlfred);
export const idfromAlfredSelector = createSelector(selectFromAlfred, ({ userIdFromAlfed }) => userIdFromAlfed);
export const companyTypeSelector = createSelector(selectFromAlfred, ({ userIdFromAlfed }) => userIdFromAlfed);
export const roeFromAlfredSelector = createSelector(selectFromAlfred, ({ roeEnabled }) => roeEnabled);
