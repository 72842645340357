import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IValidationResults } from 'services/shared/validationResults.contracts';
import { IGetPersonRequest, IGetPersonResponse, IPerson } from 'services/person-access/personAccess.contracts';
import { getPerson } from 'services/person-access/personAccess.service';

interface IGetPerson {
  person: IPerson | null;
  isValidating: boolean;
  error: string | unknown;
  validationResults?: IValidationResults;
}

const initialState: IGetPerson = {
  person: null,
  isValidating: false,
  error: '',
};

export const GetPerson = createAsyncThunk('preferences/getPerson', async (request: IGetPersonRequest) => {
  const response: IGetPersonResponse = await getPerson(request);
  return response;
});

export const getPersonSlice = createSlice({
  name: 'preferencesSlice/getPerson',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetPerson.pending, (state) => {
      state.isValidating = true;
    });
    builder.addCase(GetPerson.fulfilled, (state, action) => {
      state.person = action.payload.person;
      state.isValidating = false;
      state.error = '';
      state.validationResults = action.payload.validationResults;
    });
    builder.addCase(GetPerson.rejected, (state, action) => {
      state.error = action.payload;
      state.isValidating = false;
    });
  },
});

export const {} = getPersonSlice.actions;
export const getPersonReducer = getPersonSlice.reducer;
export const select2FA = (state: RootState) => state.getPersonReducer;
