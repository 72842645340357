import { BankAccountStatusTypeEnum } from 'shared/services/finance/schema';

export enum ValidationSeverityEnum {
  NotDefined = 0,
  Passed,
  Information,
  Warning,
  Error,
}

export enum PersistBankAccountEnum {
  invalidPersonId = 0,
  invalidBankAccountList = 1,
  invalidTransitNumber = 2,
}

export enum BankAccountTypeEnum {
  notDefined = 0,
  businessChecking,
  personalChecking,
  personalSavings,
}

export enum PersonBankAccountActionTypeEnum {
  NotDefined = 0,
  Disable = 1,
  Enable = 2,
}

export interface IValidationResult {
  validationSeverityEnum: ValidationSeverityEnum;
  validationMessage: string;
  exceptionInformation: string;
  validationCode: number;
  validationTag: string;
  isValid: boolean;
  severity: string;
}

export interface IValidationResults {
  validationResultList?: Array<IValidationResult>;
}

export interface IDepositSettings {
  priority: number;
  amount?: number;
  isPercentage?: boolean;
}

export interface IPersistPersonBankAccountRequest {
  personId: number;
  companyId?: number;
  bankAccountList: Array<IBankAccount>;
  paymentMethodType?: PaymentMethodTypeEnum;
}

export interface IPersistPersonBankAccountResponse {
  invalidBankAccountList: Array<IBankAccount>;
  validationResults: IValidationResults;
}

export interface IBankAccount {
  id?: number;
  bankId?: number;
  isOtherBank: boolean;
  bankName?: string;
  accountNumber?: string;
  transitNumber?: string;
  accountType?: BankAccountTypeEnum;
  institutionNumber?: string;
  depositSettings?: IDepositSettings;
  personId?: number;
  bankAccountStatusType?: BankAccountStatusTypeEnum;
  acronym?: string;
}

export interface IGetPersonBankAccountListRequest {
  getByPersonId: boolean;
  personId: number;
  getByIdList?: boolean;
  bankIdList?: number;
}

export interface IGetPersonBankAccountListResponse {
  paymentMethodType: PaymentMethodTypeEnum;
  canEditEmployeePortal: boolean;
  showBankingChangedBanner: boolean;
  bankAccountList: Array<IBankAccount>;
  validationResults: IValidationResults;
}

export interface IChangePersonBankRequest {
  personId: number;
  accountId?: number;
  actionType: PersonBankAccountActionTypeEnum;
  companyId?: number;
}

export interface IChangePersonBankResponse {
  validationResults: IValidationResults;
}

export enum PaymentMethodTypeEnum {
  NotDefined,
  DirectDeposit,
  Cheque,
}
