import { ReportsMenuEnum } from '@wagepoint/ui-shared-library/shared-system/services/ui-config-access/uiConfigAccess.contracts';
import { useTranslation } from 'react-i18next';
import { getReportsRoutes } from './reportRoutes';
import { useEnableAnnualPayrollLimitReport } from './hooks/safetyHooks';
import { useAuthContext } from 'shared-components/auth-provider/authProvider';
import useShowYearEndMenuInPayroll from 'pages/payroll/components/payroll-year-end-report/hooks/useShowYearEndMenuInPayroll';
import useHideMenuOptions from 'shared-components/sidebar/useHideMenuOptions';

export enum ExtraReportEnum {
  AnnualReport = 430,
}
export const usePayrollReportListItems = () => {
  const { t: treports } = useTranslation('payrollreports', { keyPrefix: 'payrollreport.reportsListing' });
  const { t: tcommon } = useTranslation('common', { keyPrefix: 'permissionMessage' });
  const reportRoutes = getReportsRoutes();
  const { enableAnnualPayrollReport } = useEnableAnnualPayrollLimitReport();
  const { user } = useAuthContext();
  const enableYearEndInsidePayroll = useShowYearEndMenuInPayroll();
  const { hideYtdReport } = useHideMenuOptions();

  return [
    {
      id: 1,
      reportHeader: treports('generatedReportsHeader'),
      cardArray: [
        {
          title: treports('payrollRegister'),
          description: treports('payrollRegisterDesc'),
          url: reportRoutes.payrollRegisterPath,
          id: ReportsMenuEnum.PayrollRegister,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('payrollTaxes'),
          description: treports('payrollTaxesDesc'),
          url: reportRoutes.payrollTaxPath,
          id: ReportsMenuEnum.PayrollTax,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('workersCompensation'),
          description: treports('workersCompensationDesc'),
          url: reportRoutes.workerCompensationPath,
          id: ReportsMenuEnum.WorkersCompensation,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('earnings'),
          description: treports('earningsDesc'),
          url: reportRoutes.earningsPath,
          id: ReportsMenuEnum.Earnings,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('benefits'),
          description: treports('benefitsDesc'),
          url: reportRoutes.benefitsPath,
          id: ReportsMenuEnum.Benefits,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('deductions'),
          description: treports('deductionsDesc'),
          url: reportRoutes.deductionsPath,
          id: ReportsMenuEnum.Deductions,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('payStubs'),
          description: treports('payStubsDesc'),
          url: reportRoutes.paystubPath,
          id: ReportsMenuEnum.PayStubs,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('yearToDate'),
          description: treports('yearToDateDesc'),
          url: reportRoutes.yearToDatePath,
          id: ReportsMenuEnum.YearToDate,
          disabledTolltip: tcommon('disabledStateOtherReports'),
          isHidden: hideYtdReport,
        },
        ...(enableAnnualPayrollReport
          ? [
              {
                title: treports('annualAmount'),
                description: treports('annualAmountDesc'),
                url: reportRoutes.annualPayrollAmountPath,
                id: ExtraReportEnum.AnnualReport,
                disabledTolltip: tcommon('part1', { accountOwnerName: user?.userDetail?.accountOwnerName }),
              },
            ]
          : []),
      ],
    },
    {
      id: 2,
      reportHeader: treports('generatedReportsHeader2'),
      cardArray: [
        {
          title: treports('depositSummary'),
          description: treports('depositSummaryDesc'),
          url: reportRoutes.depositSummaryPath,
          id: ReportsMenuEnum.DepositSummary,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('payrollInvoice'),
          description: treports('payrollInvoiceDesc'),
          url: reportRoutes.payrollInvoicePath,
          id: ReportsMenuEnum.PyarollInvoice,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
        {
          title: treports('postingJournal'),
          description: treports('postingJournalDesc'),
          url: reportRoutes.postingJournalPath,
          id: ReportsMenuEnum.PostingJournal,
          disabledTolltip: tcommon('disabledStateOtherReports'),
        },
      ],
    },
    enableYearEndInsidePayroll
      ? {
          id: 3,
          cardArray: [],
        }
      : {
          id: 3,
          reportHeader: treports('generatedReportsHeader3'),
          cardArray: [
            {
              title: treports('yearEndForms'),
              description: treports('yearEndFormsDesc'),
              url: reportRoutes.yearEndPath,
              id: ReportsMenuEnum.YearEnd,
              disabledTolltip: tcommon('disabledStateYearEndForms'),
            },
          ],
        },
  ];
};
