import { IValidationResults } from 'services/shared/validationResults.contracts';
import { IAddress } from 'services/person-access/personAccess.contracts';

export enum PayPeriodFriendlyStatusTypeEnum {
  NotDefined = 1,
  NotApproved,
  Approved,
  Finalized,
}

export interface IEmployeeRelevantState {
  id?: number;
  stateId: number;
  effectiveStartDate: string;
  effectiveToDate: string;
  name?: string;
  isCurrent?: boolean;
  startingCalendarEventId?: number;
}

export interface IVacation {
  id?: number;
  vacationCalculationType?: number;
  stateId?: number;
  payPercent?: number;
  personId?: number;
  vacationEarned?: number;
  vacationUsed?: number;
  vacationBalance?: number;
}

export interface IPersistNewEmployeeRelevantStateRequest {
  personId: number;
  companyId: number;
  employeeRelevantState: IEmployeeRelevantState;
  homeAddress?: IAddress;
}

export interface IPersistNewEmployeeRelevantStateResponse {
  validationResults: IValidationResults;
}

export interface IPersistNewRelevantStateVacationSetupRequest {
  companyId: number;
  vacation: IVacation;
}

export interface IPersistNewRelevantStateVacationSetupResponse {
  validationResults: IValidationResults;
}

export interface ITaxClaimCode {
  id: number;
  claimDesciprion?: string;
  companyFieldId?: number;
  taxGroupType?: number;
  stateId: number;
}

export interface IAdditionalTax {
  isTaxIncrement: string;
  amount: number;
  stateId: number;
  companyFieldId?: number;
  taxGroupType?: number;
}

export interface ITaxDetail {
  personId: number;
  taxClaimCodeList: Array<ITaxClaimCode>;
  additionalTaxList?: Array<IAdditionalTax>;
}

export interface IPersistNewRelevantStateTaxDetailsRequest {
  personId: number;
  effectiveStartDate: string;
  relevantStateId: number;
  taxDetail: ITaxDetail;
}

export interface IPersistNewRelevantStateTaxDetailsResponse {
  validationResults: IValidationResults;
}

export interface IValidateCompanyFirstPayrollRequest {
  companyId: number;
  payGroupId: number;
  PayPeriodFriendlystatusType: PayPeriodFriendlyStatusTypeEnum;
}

export interface IValidateCompanyFirstPayrollResponse {
  isValid: boolean;
  validationResults: IValidationResults;
}

export interface IGetEmployeeRelevantStateRequest {
  stateId: number;
  personId: number;
}

export interface IGetEmployeeRelevantStateResponse {
  homeAddress: IAddress;
  employeeRelevantState: IEmployeeRelevantState;
  validationResults: IValidationResults;
}

export interface IGetProvinceVacationRequest {
  companyId: number;
  stateId: number;
  personId: number;
}

export interface IGetProvinceVacationResponse {
  vacation: IVacation;
  validationResults: IValidationResults;
}

export interface IGetTaxDetailRequest {
  personId: number;
  getByStateId: boolean;
  stateId: number;
}

export interface IGetTaxDetailResponse {
  taxDetail: ITaxDetail;
  validationResults: IValidationResults;
}

export interface IRemoveRelevantStateRequest {
  personId: number;
  relevantStateid: number;
  companyId?: number;
}

export interface IRemoveRelevantStateResponse {
  validationResults: IValidationResults;
}

export interface IGetEmployeeRelevantStateListRequest {
  personId: number;
}

export interface IGetEmployeeRelevantStateListResponse {
  employeeRelevantStateList: Array<IEmployeeRelevantState>;
  validationResults: IValidationResults;
}

export interface IProvinceOfEmployment {
  employeeId: number;
  stateId: number;
  basicInformation: string;
  job: string;
  tax?: string;
  homeAddress: string;
}

export interface ICompletePoEProcessRequest {
  personId: number;
  stateId: number;
  provinceOfEmployment: IProvinceOfEmployment;
}

export interface ICompletePoEProcessResponse {
  validationResults: IValidationResults;
}

export enum IAddPoEStepEnum {
  basicInformation = 1,
  jobDetails,
  taxInformation,
  finalReview,
}

export enum IPoEStepListStatusEnum {
  Active = -1, // kept for UI purpose only
  NotDefined = 0,
  Skipped,
  Incomplete,
  Completed,
  StaffOnboardPending,
}

export interface ICanUpdateProvinceOfEmploymentRequest {
  personId: number;
  stateId: number;
}

export interface ICanUpdateProvinceOfEmploymentResponse {
  firstFinalizedPeriodId: number;
  canUpdateProvince: boolean;
  validationResults: IValidationResults;
}
