import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IGetCompanyNotificationsSettingsRequest,
  IGetCompanyNotificationsSettingsResponse,
} from 'services/company-settings-access/companySettingsAccess.contracts';
import { GetCompanyNotificationSettings } from 'services/company-settings-access/companySettingsAccess.service';

interface INotificationsListInit {
  notificationsList?: any;
  varience?: any;
  twoStep?: boolean;
  persisted?: boolean;
}

const initialState: INotificationsListInit = {
  notificationsList: [],
  varience: 0,
  twoStep: false,
  persisted: false,
};

export const getCompanyNotificationsList = createAsyncThunk(
  '/getcompanynotificationsettings',
  async (request: IGetCompanyNotificationsSettingsRequest) => {
    const response: IGetCompanyNotificationsSettingsResponse = await GetCompanyNotificationSettings(request);
    return response;
  }
);

export const notificactionsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateCompanyNotifications: (state, action: PayloadAction<INotificationsListInit>) => {
      state.notificationsList = action.payload.notificationsList;
      state.varience = action.payload.varience;
      state.twoStep = action.payload.twoStep;
    },
    updatePersistCompanyNotifications: (state, action: PayloadAction<INotificationsListInit>) => {
      state.persisted = action.payload.persisted;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyNotificationsList.fulfilled, (state, action) => {
      state.notificationsList = action.payload.companyNotificationSettingsList;
      state.varience = action.payload.notifyLimitPayrollVariance;
      state.twoStep = action.payload.isTwoStepApprovalOn;
    });
  },
});

export const { updateCompanyNotifications, updatePersistCompanyNotifications } = notificactionsSlice.actions;
export const notificationsReducer = notificactionsSlice.reducer;
export const getNotificationList = (state: RootState) => state.notificationsReducer;
export const selectNotificationsList = (state: RootState) => state.notificationsReducer?.notificationsList;
export const selectVarience = (state: RootState) => state.notificationsReducer?.varience;
export const selectTwoStep = (state: RootState) => state.notificationsReducer?.twoStep;
