import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MaintainPreference } from 'services/payroll-year-end-access/payrollYearEndAccess.service';
import {
  EmployeeTaxForm,
  SubmissionStatusTypeEnum,
  ViewTypeEnum,
  YearEndPreference,
  YearEndTaxForm,
} from 'services/payroll-year-end-access/payrollYearEndAccess.contracts';
import { hideLoading, showLoading } from './loadingIndicatorSlice';
import { error } from './snackbarSlice';
import { getApiValidationErrors } from 'util/utility';
import { RootState } from '../store/store';
import { StatementVariants } from 'pages/payroll-tools/components/recordOfEmployment/components/createRecordOfEmployment/steps/insurableHoursAndEarnings/insurableHoursAndEarnings';
import { BoxesDataItem } from 'pages/payroll/components/payroll-year-end-report/yearEndForms.contracts';
import {
  defaultT4AFormBannerErrors,
  defaultT4FormBannerErrors,
} from 'pages/payroll/components/payroll-year-end-report/submit-forms/constants';

type EmployeeTaxFormUpdatedData = {
  isModified?: boolean;
  updatedBy?: string;
  updatedDate?: string;
};

export type BoxNameListItem = {
  name: string;
  displayName: string;
};

export type EmployeeTableRow = {
  employeeId: number;
  employeeName: string;
  stateCode: string;
  taxFormId: number;
  index: number;
};

export type StateEmployeeTaxForm = EmployeeTaxForm & EmployeeTaxFormUpdatedData;

export type FormsListKey = 't4Forms' | 't4aForms' | 'rl1Forms';

export enum StepNameEnum {
  t4 = 1,
  t4a = 2,
  rl1 = 3,
  rl1Summary = 4,
  summary = 5,
}

export type SubmitFormsStep = {
  stepId: StepNameEnum;
  name: string;
};

export type OtherBoxName = {
  key: string;
  value: string;
  displayName: string;
};

export type LastYearEndTaxFormId = Record<FormsListKey, number | null>;

export type BoxError = {
  boxName: string;
  errorMessage: string;
  bannerPriority: StatementVariants;
};

export type TaxFormsBannerErrors = Record<FormsListKey, Array<BoxError>>;

export type RevalidateForm = Record<FormsListKey, boolean>;

export interface IYearEndSubmitFormData {
  availSteps: SubmitFormsStep[];
  boxNameList: BoxNameListItem[];
  currentStepId: StepNameEnum | null;
  currentFormIndex: number;
  gridRows: EmployeeTableRow[];
  t4Forms: StateEmployeeTaxForm[];
  t4aForms: StateEmployeeTaxForm[];
  rl1Forms: StateEmployeeTaxForm[];
  rl1Summary: EmployeeTaxFormUpdatedData | null;
  isEditMode: boolean;
  viewMode: ViewTypeEnum;
  packageYear: number | undefined;
  selectedFormIds: number[];
  isAmendmentsPackage: boolean;
  isUnsavedChangesDialog: boolean;
  isEditModeDialog: boolean;
  currentStepIndex: number;
  t4OtherBoxNamesList: OtherBoxName[];
  t4AOtherBoxNamesList: OtherBoxName[];
  rl1OtherBoxNamesList: OtherBoxName[];
  t4OtherBoxNamesListIsNotInitialized: boolean;
  t4AOtherBoxNamesListIsNotInitialized: boolean;
  rl1OtherBoxNamesListIsNotInitialized: boolean;
  defaultConfirmedAccuracy: boolean;
  defaultSubmissionType: SubmissionStatusTypeEnum;
  lastFormIdData: LastYearEndTaxFormId;
  taxFormsBannerErrors: TaxFormsBannerErrors;
  revalidateFormFlags: RevalidateForm;
}

export interface IPrintData {
  taxForm: YearEndTaxForm | null;
  printType: string | null;
  employeeId: number | null;
  summaryTitle: string | null;
  summaryData: BoxesDataItem | null;
}

export interface IYearEndSubmitFormState {
  data: IYearEndSubmitFormData;
  printData: IPrintData;
}

export const yearEndSubmitFormInitialState: IYearEndSubmitFormState = {
  data: {
    availSteps: [],
    boxNameList: [],
    currentStepId: null,
    currentFormIndex: 0,
    gridRows: [],
    t4Forms: [],
    t4aForms: [],
    rl1Forms: [],
    rl1Summary: null,
    isEditMode: false,
    viewMode: ViewTypeEnum.Single,
    packageYear: undefined,
    selectedFormIds: [],
    isAmendmentsPackage: false,
    isUnsavedChangesDialog: false,
    isEditModeDialog: false,
    currentStepIndex: 0,
    t4OtherBoxNamesList: [],
    t4AOtherBoxNamesList: [],
    rl1OtherBoxNamesList: [],
    t4OtherBoxNamesListIsNotInitialized: true,
    t4AOtherBoxNamesListIsNotInitialized: true,
    rl1OtherBoxNamesListIsNotInitialized: true,
    defaultConfirmedAccuracy: false,
    defaultSubmissionType: SubmissionStatusTypeEnum.NotDefined,
    lastFormIdData: {
      t4Forms: null,
      t4aForms: null,
      rl1Forms: null,
    },
    taxFormsBannerErrors: {
      t4Forms: defaultT4FormBannerErrors,
      t4aForms: defaultT4AFormBannerErrors,
      rl1Forms: [],
    },
    revalidateFormFlags: {
      t4Forms: false,
      t4aForms: false,
      rl1Forms: false,
    },
  },
  printData: {
    taxForm: null,
    printType: null,
    employeeId: null,
    summaryTitle: null,
    summaryData: null,
  },
};

export const updatePackagePreference = createAsyncThunk<
  YearEndPreference | undefined,
  { packageId: number; preference: YearEndPreference },
  { state: RootState }
>('yearEndSubmitFormState/updatePackagePreference', async ({ packageId, preference }, { dispatch }) => {
  dispatch(showLoading());
  const response = await MaintainPreference({ yearEndPackageId: packageId, preference });
  dispatch(hideLoading());
  const { message, hasError } = getApiValidationErrors(response);
  if (hasError) {
    dispatch(error({ message }));
    return;
  }
  return response.preference;
});

export const stateSlice = createSlice({
  name: 'yearEndSubmitFormStateSlice',
  initialState: yearEndSubmitFormInitialState,
  reducers: {
    setPersistState: (state, action: PayloadAction<Partial<IYearEndSubmitFormData>>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    updateFormsListData: (
      state,
      action: PayloadAction<{ id: number; formsListKey: FormsListKey; data: EmployeeTaxFormUpdatedData }>
    ) => {
      const { id, formsListKey, data } = action.payload;
      const formsList = state.data[formsListKey];
      const updatedItemIndex = formsList.findIndex((form) => form.yearEndTaxFormId === id);
      if (updatedItemIndex !== -1) {
        state.data[formsListKey][updatedItemIndex] = {
          ...formsList[updatedItemIndex],
          ...data,
        };
      }
    },
    updateRL1SummaryData: (state, action: PayloadAction<{ data: EmployeeTaxFormUpdatedData }>) => {
      const { data } = action.payload;
      state.data.rl1Summary = data;
    },
    resetPersistState: (state) => {
      state.data = yearEndSubmitFormInitialState.data;
    },
    setPrintData: (state, action: PayloadAction<IPrintData>) => {
      state.printData = action.payload;
    },
    setLastFormId: (state, action: PayloadAction<Partial<LastYearEndTaxFormId>>) => {
      state.data.lastFormIdData = { ...state.data.lastFormIdData, ...action.payload };
    },
    updateTaxFormsBannerErrors: (state, action: PayloadAction<Partial<TaxFormsBannerErrors>>) => {
      state.data.taxFormsBannerErrors = { ...state.data.taxFormsBannerErrors, ...action.payload };
    },
    updateRevalidateFormFlags: (state, action: PayloadAction<Partial<RevalidateForm>>) => {
      state.data.revalidateFormFlags = { ...state.data.revalidateFormFlags, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePackagePreference.fulfilled, (state, action) => {
      const yearEndViewType = action.payload?.yearEndViewType;
      state.data.viewMode = yearEndViewType ?? state.data.viewMode;
    });
  },
});

export const {
  setPersistState,
  resetPersistState,
  updateFormsListData,
  updateRL1SummaryData,
  setPrintData,
  setLastFormId,
  updateTaxFormsBannerErrors,
  updateRevalidateFormFlags,
} = stateSlice.actions;
export const yearEndSubmitFormStateReducer = stateSlice.reducer;

export const yearEndPrintData = (state: RootState) => state.yearEndSubmitFormStateReducer.printData;
