import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IEmployeeAddress,
  IPersistRoePaymentRequest,
  IPreviousReportedRoePeriod,
  IRecordOfEmploymentReason,
  IRoeEmployee,
  IRoePayGroupItem,
  IRoePayPeriodItem,
  RoeCompletedStepEnum,
  RoeError,
  RoePayPeriodTypeEnum,
  RoeRecallActionTypeEnum,
  RoeSatStatusType,
  RoeStatusType,
} from 'services/payroll-record-of-employment-access/payrollRecordOfEmploymentAccess.contracts';
import { IPerson } from 'services/person-access/personAccess.contracts';

export interface IRecordOfEmploymentFormData {
  roeId: number | null;
  employee: IPerson | null;
  reason: IRecordOfEmploymentReason | null;
  occupation: string | null;
  firstDayWorked: Date | string | null;
  lastPaidDay: Date | string | null;
  finalPayPeriodEnd: Date | string | null;
  expectedDateOfRecall: Date | string | null;
  payGroup: IRoePayGroupItem | null;
  payPeriodType: RoePayPeriodTypeEnum | null;
  payPeriodList: IRoePayPeriodItem[] | null;
  roeStatusType: RoeStatusType;
  hiddenEmployeeData: IRoeEmployee | null;
  employeeFullName: string | null;
  employeeAddressAddress1: IEmployeeAddress['address1'];
  employeeAddressAddress2: IEmployeeAddress['address2'];
  employeeAddressCity: IEmployeeAddress['city'];
  employeeAddressPostalCode: IEmployeeAddress['postalCode'];
  employeeAddressState: Pick<IEmployeeAddress, 'state' | 'stateId'> | null;
  serialNumber?: string;
  previousSerialNumber?: string;
  roePayPeriodList: IRoePayPeriodItem[] | null;
  roeRecallActionType: RoeRecallActionTypeEnum;
  allowAmendment: boolean;
  isAmendment: boolean;
  isPreviouslyRejected: boolean;
  showRoeImpactedWarning: boolean;
  totalInsurableHours: number;
  totalInsurableEarnings: number;
  dontShowMeFlags?: number | null;
  lastCompletedStep: RoeCompletedStepEnum;
  invalidROEExistsDraft: IRecordOfEmploymentInvalidROEDraft | null;
  isEiExempted: boolean;
  invalidSIN: boolean;
  previousReportedRoePeriodList: IPreviousReportedRoePeriod[] | [];
}

export interface IRecordOfEmploymentInvalidROEDraft {
  isExistsDraftROE: boolean;
  nameEmployeeWithDraft: string;
}

export interface IRecordOfEmploymentFormState {
  data: IRecordOfEmploymentFormData;
  firstEndDate: null | string | undefined;
  roeSatStatusType: RoeSatStatusType;
  roeServerErrors: { [key: string]: RoeError[] | [] };
  totalData: { [key: string]: number };
  cacheData: {
    firstStepCache: IRecordOfEmploymentFormData | null;
    secondStepCache: IRecordOfEmploymentFormData | null;
    thirdStepCache: IPersistRoePaymentRequest | null;
    fourthStepCache: any;
  };
}

export enum CacheKeysEnums {
  firstStepCache = 'firstStepCache',
  secondStepCache = 'secondStepCache',
  thirdStepCache = 'thirdStepCache',
  fourthStepCache = 'fourthStepCache',
}

export const recordOfEmploymentFormInitialState: IRecordOfEmploymentFormState = {
  data: {
    roeId: null,
    employee: null,
    reason: null,
    occupation: null,
    firstDayWorked: null,
    lastPaidDay: null,
    finalPayPeriodEnd: null,
    expectedDateOfRecall: null,
    payGroup: null,
    payPeriodType: null,
    payPeriodList: [],
    roeStatusType: RoeStatusType.NotDefined,
    hiddenEmployeeData: null,
    employeeFullName: null,
    employeeAddressAddress1: '',
    employeeAddressAddress2: '',
    employeeAddressCity: '',
    employeeAddressPostalCode: '',
    employeeAddressState: null,
    serialNumber: '',
    previousSerialNumber: '',
    roePayPeriodList: [],
    roeRecallActionType: RoeRecallActionTypeEnum.NotDefined,
    allowAmendment: false,
    isAmendment: false,
    isPreviouslyRejected: false,
    showRoeImpactedWarning: false,
    totalInsurableHours: 0,
    totalInsurableEarnings: 0,
    dontShowMeFlags: 0,
    lastCompletedStep: RoeCompletedStepEnum.NotDefined,
    invalidROEExistsDraft: null,
    isEiExempted: false,
    invalidSIN: false,
    previousReportedRoePeriodList: [],
  },
  totalData: {},
  firstEndDate: null,
  roeSatStatusType: RoeSatStatusType.NotDefined,
  roeServerErrors: {
    step1: [],
    step2: [],
    step3: [],
  },
  cacheData: {
    firstStepCache: null,
    secondStepCache: null,
    thirdStepCache: null,
    fourthStepCache: null,
  },
};

export const stateSlice = createSlice({
  name: 'recordOfEmploymentFormState',
  initialState: recordOfEmploymentFormInitialState,
  reducers: {
    setPersistState: (state, action: PayloadAction<Partial<IRecordOfEmploymentFormData>>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    resetPersistState: () => {
      return recordOfEmploymentFormInitialState;
    },
    setFirstEndDate: (state, action: PayloadAction<null | undefined | string>) => {
      state.firstEndDate = action.payload;
    },
    setRoeSatStatusType: (state, action: PayloadAction<RoeSatStatusType>) => {
      state.roeSatStatusType = action.payload;
    },
    setROEServerErrors: (state, action: PayloadAction<{ [key: string]: RoeError[] | [] }>) => {
      state.roeServerErrors = action.payload;
    },
    setCacheData: (
      state,
      action: PayloadAction<{
        data: IRecordOfEmploymentFormData | IPersistRoePaymentRequest | null;
        key: CacheKeysEnums;
      }>
    ) => {
      state.cacheData = { ...state.cacheData, [action.payload.key]: action.payload.data };
    },
    setTotalData: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.totalData = { ...state.totalData, ...action.payload };
    },
  },
});

export const {
  setPersistState,
  resetPersistState,
  setFirstEndDate,
  setRoeSatStatusType,
  setROEServerErrors,
  setCacheData,
  setTotalData,
} = stateSlice.actions;
export const recordOfEmploymentFormStateReducer = stateSlice.reducer;
export const selectRecordOfEmploymentFormState = (state: RootState) => state.recordOfEmploymentFormStateReducer.data;
export const selectRecordOfEmploymentFirstEndDate = (state: RootState) =>
  state.recordOfEmploymentFormStateReducer.firstEndDate;
export const selectRoeId = ({ recordOfEmploymentFormStateReducer }: RootState) =>
  recordOfEmploymentFormStateReducer.data.roeId;
export const roeSatStatusType = ({ recordOfEmploymentFormStateReducer }: RootState) =>
  recordOfEmploymentFormStateReducer.roeSatStatusType;
export const getROEServerErrors = ({ recordOfEmploymentFormStateReducer }: RootState) =>
  recordOfEmploymentFormStateReducer.roeServerErrors;
export const cacheData = ({ recordOfEmploymentFormStateReducer }: RootState) =>
  recordOfEmploymentFormStateReducer.cacheData;
export const totalDataSelector = ({ recordOfEmploymentFormStateReducer }: RootState) =>
  recordOfEmploymentFormStateReducer.totalData;
export const selectSecondStepCache = ({ recordOfEmploymentFormStateReducer }: RootState) =>
  recordOfEmploymentFormStateReducer.cacheData?.secondStepCache;
