import React from 'react';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';

import { useTranslation } from 'react-i18next';

interface AcceptButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

export const AcceptButton: React.FC<AcceptButtonProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation('userAccounts');

  return (
    <WpButton variant="contained" color="primary" onClick={onClick} disabled={disabled} noGutter>
      {t('firmInviteAccepted.acceptText')}
    </WpButton>
  );
};
