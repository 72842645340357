import { createBuildPath } from 'util/buildPath';

export const mainReportRoute = '/reports';

export const getReportsRoutes = () => {
  const buildPath = createBuildPath(mainReportRoute);
  return {
    yearEndPath: buildPath('/year-end')(),
    postingJournalPath: buildPath('/posting-journal')(),
    payrollRegisterPath: buildPath('/payroll-register')(),
    payrollTaxPath: buildPath('/payroll-taxes')(),
    workerCompensationPath: buildPath('/workers-compensation')(),
    earningsPath: buildPath('/earnings')(),
    benefitsPath: buildPath('/benefits')(),
    deductionsPath: buildPath('/deductions')(),
    paystubPath: buildPath('/paystubs')(),
    yearToDatePath: buildPath('/year-to-date')(),
    depositSummaryPath: buildPath('/deposit-summary')(),
    payrollInvoicePath: buildPath('/payroll-invoice')(),
    annualPayrollAmountPath: buildPath('/annual-payroll-amount')(),
  };
};
