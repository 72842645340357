import { ExplicitFieldTypesEnum } from 'services/company-field-access/companyFieldAccess.contracts';
import { IValidationResults } from 'services/shared/validationResults.contracts';

export enum ValidationScopeTypeEnum {
  NotDefined,
  Partial,
  Complete,
}

export interface GetEmployeeListRequest {
  fieldId: number;
  payGroupId: number;
  getByName?: boolean;
  nameStartsWith?: string;
  getByAssignmentStatus?: boolean;
  assignmentStatus?: AssignmentStatusEnum;
  getByDepartmentList?: boolean;
  departmentIdList?: number[];
  getByCompanyLocationList?: boolean;
  companyLocationIdList?: number[];
  getByPersonTypeList?: boolean;
  personTypeList?: PersonTypeEnum[];
  getByEmployeeStateTypeList?: boolean;
  employeeStateTypeList?: EmployeeStateTypeEnum[];
  skip: number;
  take: number;
  includeTotalCount: boolean;
  sortBy?: SortByEnum;
  sortDescending?: boolean;
  includeIncompleteEmployee?: boolean;
}

export interface GetEmployeeListResponse {
  totalCount: number;
  employeeList: Array<IEmployee>;
  fieldProvinceList: Array<IFieldProvince>;
  validationResults: IValidationResults;
}

export interface PersistEmployeeFieldListRequest {
  validationScopeType?: ValidationScopeTypeEnum;
  fieldId?: number;
  companyId?: number /* This should be mandatory field, change by John for now showing optional */;
  markOnboardingAsCompleted?: boolean;
  employeeFieldList: Array<IEmployeeField>;
}

export interface PersistEmployeeFieldListResponse {
  validationResults: IValidationResults;
}

export interface RemoveEmployeeFieldRequest {
  fieldId: number;
  employeeFieldList: Array<IEmployeeField>;
}

export interface RemoveEmployeeFieldResponse {
  validationResults: IValidationResults;
}

export interface GetEmployeeFieldListRequest {
  fieldId: number;
  payGroupId: number;
  getByName?: boolean;
  nameStartsWith?: string;
  skip: number;
  take: number;
  includeTotalCount: boolean;
  sortBy?: SortByEnum;
  sortDescending?: boolean;
  getByEmployee?: boolean;
  employeeIdList?: number[];
}

export interface GetEmployeeFieldListResponse {
  totalCount: number;
  employeeFieldList: Array<IEmployeeField>;
  validationResults: IValidationResults;
}

export interface GetOpenPayPeriodSummaryRequest {
  payGroupId: number;
}

export interface GetOpenPayPeriodSummaryResponse {
  openPayPeriodSummary: IOpenPayPeriodSummary;
  validationResults: IValidationResults;
}

export interface ChangeCompanyFieldStateRequest {
  companyId: number;
  fieldId: number;
  enableAction: boolean;
  overrideFieldId: number;
}

export interface ChangeCompanyFieldStateResponse {
  validationResults: IValidationResults;
}

export interface IOpenPayPeriodSummary {
  id: number;
  startDate: string;
  endDate: string;
  isOffCyclePay: boolean;
}

export interface IFieldProvince {
  stateId: number;
  stateName: string;
}

export enum CustomPeriodEnum {
  one = 1,
  two = 2,
  three = 4,
  four = 8,
  five = 16,
}
export enum ExplicitFieldTypeEnum {
  notDefined = 0,
  notSpecified,
  grossPay,
  netPay,
  regularHours,
  regularPay,
  regularRate,
  otHours,
  otPay,
  workmansComp,
  workmansCompRate,
  totalWageHours,
  totalAdditionalIncomes,
  holidayPay,
  vacationPay,
  statPay,
  workedOnStatPay,
  sickPay,
  doubleOvertimePay,
  totalDeductions,
  totalBenefits,
  vacationAccrued,
  contractorPay,
  unionDues,
  parentalInsurance = 24,
  charitableDonation = 25,
  totalWage = 26,
  fedLaborFundsTaxCredit = 27,
  vacationPayPaidOut = 28,
}
export interface IEmployeeField {
  id?: number;
  employeeId: number;
  hours?: number;
  amount?: number;
  accumulatedAmount?: number;
  valueType?: DataValueTypeEnum;
  frequencyType: FrequencyTypeEnum;
  selFreqValue?: any; // remove after refactor not needed
  employeeFirstName: string;
  employeeLastName: string;
  jobId: number;
  jobTitle?: string;
  annualLimit?: number;
  fieldId: number;
  customPeriodFlags?: number; // remove after refactor not needed
  customPeriodList?: CustomPeriodEnum[];
  salesTaxId?: number;
  garnishField?: IGarnishField;
  isSelected?: boolean; // remove after refactor not needed
  isAnnualLimitReached?: boolean;
  explicitFieldType?: ExplicitFieldTypeEnum;
  name?: string;
  code?: string;
  codeType: number;
  contributionEntryType?: number;
}

export interface IEmployee {
  id: number;
  firstName: string;
  lastName: string;
  jobId: number;
  jobTitle: string;
  assignmentStatus: AssignmentStatusEnum;
  relevantStateId?: number;
  relevantStateAbbreviation: string;
  provinceAlert: boolean;
  isSelected?: boolean;
}

export interface IGarnishField {
  amount?: number;
  dataValueId?: number;
  max?: number;
  beginBalance?: number;
  exemption?: number;
}

export enum SortByEnum {
  firstName = 0,
  lastName = 1,
  jobTitle = 2,
}

export enum DataValueTypeEnum {
  NotDefined = 0,
  Amount = 1,
  Percentage = 2,
}

export enum payPeriodEnum {
  None = 0,
  WeekOne = 1,
  WeekTwo = 2,
  WeekThree = 4,
  WeekFour = 8,
}

export enum FrequencyTypeEnum {
  NotDefined = 0,
  OneTime = 1,
  Everytime = 2,
  LastOfMonth = 3,
  FirstOfMonth = 4,
  CustomPeriodFlags = 5,
}

export enum ValidationSeverityEnum {
  NotDefined = 0,
  Passed,
  Information,
  Warning,
  Error,
}

export enum FieldSubTypeEnum {
  notDefined = 0,
  notSpecified = 1,
  aggregate = 2,
  garnishment = 3,
}

export enum PersonTypeEnum {
  NotDefined = 0,
  Employee = 1,
  Contractor = 2,
  Other = 3,
}

export enum EmployeeStateTypeEnum {
  notDefined = 0,
  notSpecified,
  active,
  terminated,
  onLeave,
  terminatedPending,
  onLeavePending,
  disabled,
  pending,
  incompleteProfile,
  deleted,
}

export enum AssignmentStatusEnum {
  unAssigned = 0,
  assigned = 1,
}
export enum SortSummaryListByEnum {
  code = 0,
  name,
}
export enum FieldOriginTypeEnum {
  notDefined = 0,
  original = 1,
  duplicate = 2,
  edit = 3,
  custom = 4,
}
export enum FieldTypeEnum {
  notDefined = 0,
  notSpecified = 1,
  income = 2,
  additionalIncome = 3,
  deduction = 4,
  wageHours = 5,
  hourlyRate = 6,
  microDeposit = 7,
  benefit = 8,
  sales = 9,
  tax = 10,
  additionalIncomeHours = 11,
}
export enum ContributionEntryTypeEnum {
  notDefined = 0,
  notSpecified = 1,
  amount = 2,
  percent = 3,
  amountOrPercent = 4,
}
export enum CodeTypeEnum {
  notDefined = 0,
  hoursWithCalcAmounts = 1,
  amountWithHours = 2,
  amountOnly = 3,
}
export enum FieldStatusTypeEnum {
  notDefined = 0,
  notSpecified = 1,
  dataEntryInProgress = 2,
  pendingApproval = 3,
  active = 4,
  rejected = 5,
  deleted = 6,
}
export enum CompanyFieldTypeEnum {
  notSpecified = 0,
  income = 1,
  deduction = 2,
  benefit = 3,
}

export enum EmployeeFieldEnum {
  Garnishments = 'Garnishment',
}

export enum PayPeriodDayEnum {
  First = 'first',
  Second = 'second',
  Third = 'third',
  Fourth = 'fourth',
  Weekly = 'Weekly',
}

export enum AddAmountsEnum {
  Name = 'name',
  Job = 'job',
  Amount = 'amount',
  Limit = 'limit',
  MaxLimit = 'maxLimit',
  Balance = 'balance',
  Exemption = 'exemption',
  Frequency = 'frequency',
  Hours = 'hours',
}
/* Employee Employee Field START */
export interface ICompanyFieldSummary {
  fieldId: number;
  name: string;
  code: string;
  description: string;
  baseFieldId: number;
  codeType: CodeTypeEnum;
  fieldSubType: FieldSubTypeEnum;
  fieldStatusType: FieldStatusTypeEnum;
  isEnabled: boolean;
  allowDisable: boolean;
  allowDelete: boolean;
  associationKey?: string;
  fieldOriginType: FieldOriginTypeEnum;
  fieldType: FieldTypeEnum;
  contributionEntryType: ContributionEntryTypeEnum;
  explicitFieldType: ExplicitFieldTypeEnum;
  getFieldListResponse: IGetFieldListResponse;
}
export enum GetFieldListUsageTypeEnum {
  notDefined = 0,
  assign,
  update,
}
export interface IGetFieldListRequest {
  employeeId?: number;
  companyId?: number;
  jobId?: number;
  fieldType?: FieldTypeEnum;
  getByEmployeeIdList?: boolean;
  employeeIdList?: number[];
  getByName?: boolean;
  nameStartsWith?: string;
  IncludeExistingFieldList?: boolean;
  sortBy?: number;
  sortDescending?: boolean;
  includeHoursWithCalculatedAmounts?: boolean;
  getFieldListUsageType?: GetFieldListUsageTypeEnum;
}
export interface IGetFieldListResponse {
  employeePayGroupDetailList: Array<IEmployeePayGroupDetail>;
  fieldSummaryList: Array<ICompanyFieldSummary>;
  validationResults: IValidationResults;
}

export interface IEmployeePayGroupDetail {
  employeeId: number;
  payGroupId: number;
  numberOfPeriods: number;
}
export interface IEmployeeFieldSummary {
  employeeFieldId?: number;
  fieldId: number;
  code?: string;
  name: string;
  hours: number;
  id?: number;
  amount: number;
  valueType: DataValueTypeEnum;
  frequencyType: FrequencyTypeEnum;
  jobId: number;
  jobTitle: string;
  annualLimit?: number;
  customPeriodFlags?: number;
  employeeId: number;
  customPeriodList: Array<number>;
  codeType?: CodeTypeEnum;
  fieldSubType?: FieldSubTypeEnum;
  fieldStatusType?: FieldStatusTypeEnum;
  fieldOriginType?: FieldOriginTypeEnum;
  fieldType: FieldTypeEnum;
  contributionEntryType?: ContributionEntryTypeEnum;
  baseFieldId: number;
  salesTaxId: number;
  associationKey?: string;
  explicitFieldType?: number | ExplicitFieldTypesEnum;
  contributionType?: number;
  garnishField?: IGarnishField;
  formList?: Array<IForm>;
  isAnnualLimitReached: boolean;
  annualLimtPeriodStartDate?: string;
  annualLimtPeriodEndDate?: string;
}
export interface IEmployeeFieldValidator {
  employeeId: number;
  jobId: number;
  isAssigned?: boolean;
  isApplicable?: boolean;
}
export interface IValidateEmployeeFieldAssignmentRequest {
  companyId: number;
  fieldId: number;
  employeeFieldValidatorList: Array<IEmployeeFieldValidator>;
}

export interface GetAssignedFieldListRequest {
  companyId: number;
}

export interface GetAssignedFieldListResponse {
  assignedFieldIds: Array<number>;
  validationResults: IValidationResults;
}

export interface IForm {
  id: number;
  name: string;
  countryId: number;
  formBoxValueList: Array<IFormBoxValue>;
}

export interface IFormBoxValue {
  id: number;
  name: string;
}
export interface IValidateEmployeeFieldAssignmentResponse {
  isApplicableForAllState: boolean;
  applicableStateList: string[];
  employeeFieldValidatorList: Array<IEmployeeFieldValidator>;
  validationResults: IValidationResults;
}
export interface IGetEmployeeFieldSummaryListRequest {
  companyId?: number;
  employeeId?: number;
  fieldType?: FieldTypeEnum;
  getByJobId?: boolean;
  jobId?: number;
  skip?: number;
  take?: number;
  includeTotalCount?: boolean;
  sortBy?: SortSummaryListByEnum;
  sortDescending?: boolean;
  includeFormList?: boolean;
  jobName?: string;
  getByEmployeeIdList?: boolean;
  employeeIdList?: number[];
  getByFieldId?: boolean;
  fieldId?: number;
  includedAnnualLimitReached?: boolean;
}
export interface IGetEmployeeFieldSummaryListResponse {
  totalCount: number;
  employeeFieldSummaryList: Array<IEmployeeFieldSummary>;
  validationResults: IValidationResults;
  employeeList: any;
}
export interface IDeleteEmployeeFieldRequest {
  employeeFieldId: number;
  fieldId: number;
}

export interface IDeleteEmployeeFieldResponse {
  validationResults: IValidationResults;
}
export interface ISalesTax {
  id: number;
  stateId: number;
  stateName: string;
  taxPercentage: number;
  name?: string;
}
export interface IGetSaleTaxListRequest {
  /* This is empty interface */ emptyfield?: any;
}

export interface IGetSaleTaxListResponse {
  salesTaxList: Array<ISalesTax>;
  validationResults: IValidationResults;
}
/* Employee Employee Field END */

export interface IFieldList {
  fieldId: number;
}
