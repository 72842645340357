import React from 'react';
import { WpTextField } from '@wagepoint/ui-toolkit/components/wp-textField/wpTextField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InviteEnum, ValidEnum } from '../companyIdOrEmailLayout';
import { getApiValidationErrors } from 'util/utility';
import { setCompanyId } from 'redux/slice/accountingFirmSlice';
import companyLinkAccess from 'services/finance/models/access/companyLinkAccess';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import companyAccess from 'services/finance/models/access/companyAccess';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { CompanyTypeEnum } from 'shared/services/appCore/schema';

interface IFormContainerProps {
  inviteFrom?: number;
  viewOnly?: boolean;
}

const FormContainer = ({ inviteFrom, viewOnly }: IFormContainerProps) => {
  const { t } = useTranslation(['userAccounts']);
  const selectedCompanyId = useAppSelector(selectCompanyId);
  const dispatch = useAppDispatch();
  const { feature } = useFeatureFlag();
  const isAutofillOff = feature(FEATURE_FLAG_KEYS.enableAutofillOff);

  const { errors, clearErrors } = useFormContext();

  const resetCompanyId = (resetEnum: ValidEnum) => {
    dispatch(
      setCompanyId({
        companyId: 0,
        isValidCompany: resetEnum,
        name: '',
      })
    );
  };

  const checkValid = async (value: string) => {
    const response = await companyAccess.getCompanyForInvitation({
      getByCompanyCode: true,
      companyCode: value,
      getByEmail: false,
      email: '',
      getByName: false,
      companyName: '',
      getByOwnerEmail: false,
      ownerEmail: '',
      getById: false,
      id: 0,
      getByCompanyTypeFlags: true,
      companyTypeFlags:
        CompanyTypeEnum.partnerIndependent + CompanyTypeEnum.partnerNational + CompanyTypeEnum.partnerRegional,
    });
    const { hasError } = getApiValidationErrors(response);
    if (!hasError) {
      dispatch(
        setCompanyId({
          companyId: response?.companyLite?.id,
          isValidCompany: ValidEnum.Valid,
          name: response?.companyLite?.name,
        })
      );
      return true;
    } else {
      resetCompanyId(ValidEnum.InValid);
      return false;
    }
  };

  const checkValidEmail = async (value: any) => {
    const response = await companyLinkAccess.validateEmail({
      emailAddress: value,
      clientCompanyId: selectedCompanyId,
    });

    const { hasError } = getApiValidationErrors(response);

    // if we have such user
    if (hasError) {
      dispatch(
        setCompanyId({
          isValidCompany: ValidEnum.ValidEmail,
          email: value,
        })
      );
    } else {
      dispatch(
        setCompanyId({
          isValidCompany: ValidEnum.InValidEmail,
          email: value,
        })
      );
    }
  };

  return (
    <>
      {inviteFrom == InviteEnum.CompanyId && (
        <WpTextField
          id="cId"
          label={t('common.companyId')}
          name="cId"
          required
          errorobj={errors}
          disabled={viewOnly}
          onInputChange={async (e: any) => {
            if (e.target.value && e.target.value.length > 6) {
              clearErrors();
              await checkValid(e.target.value);
            } else {
              resetCompanyId(ValidEnum.NotDefined);
              clearErrors();
            }
          }}
        />
      )}

      {inviteFrom == InviteEnum.Email && (
        <WpTextField
          id="email"
          label={t('common.email')}
          name="email"
          required
          errorobj={errors}
          disabled={viewOnly}
          autofillOff={isAutofillOff}
          onInputChange={(e: any) => {
            if (e.target.value) {
              checkValidEmail(e.target.value);
            } else {
              resetCompanyId(ValidEnum.NotDefined);
              clearErrors();
            }
          }}
        />
      )}
    </>
  );
};

export default FormContainer;
