import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface IPayrollInit {
  isValid: boolean;
}

const initialState: IPayrollInit = {
  isValid: false,
};

export const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    getPayrollState: (state, action: PayloadAction<IPayrollInit>) => {
      state.isValid = action.payload.isValid;
    },
  },
});

export const { getPayrollState } = payrollSlice.actions;
export const payrollStateReducer = payrollSlice.reducer;
export const validState = (state: RootState) => state.payrollStateReducer;
