import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

export const useMigrationSafetyHooks = () => {
  const { feature } = useFeatureFlag();
  const enablePaygroupConfirmationNew = feature(FEATURE_FLAG_KEYS.EnableNewMigrationDesignPaygroup);
  return { enablePaygroupConfirmationNew };
};

export const useMigrationUnlockFeature = () => {
  const { feature } = useFeatureFlag();
  const showUnlockFeature = feature(FEATURE_FLAG_KEYS.showUnlockFullPotential);
  return { showUnlockFeature };
};

export const useMigrationHidePAD = () => {
  const { feature } = useFeatureFlag();
  const hidePADUpload = feature(FEATURE_FLAG_KEYS.HidePADUploadFeature);
  return { hidePADUpload };
};

export const useNewLogicToFetchAS = () => {
  const { feature } = useFeatureFlag();
  const newLogicToFetchAS = feature(FEATURE_FLAG_KEYS.NewLogicToFetchAS);

  return { newLogicToFetchAS };
};
export const usePartnerUpgradeFlow = () => {
  const { feature } = useFeatureFlag();
  const enablePartnerUpgrade = feature(FEATURE_FLAG_KEYS.ReleasePartnerUpgrade);

  return { enablePartnerUpgrade };
};
export const usePartnerMigrationVerificationCondition = () => {
  const { feature } = useFeatureFlag();
  const enableCondition = feature(FEATURE_FLAG_KEYS.MigratedPartnerAccountVerificationSpecialCondition);

  return { enableCondition };
};
export const useEnableMigrationDontshowFlagAction = () => {
  const { feature } = useFeatureFlag();
  const enableCondition = feature(FEATURE_FLAG_KEYS.EnableMigrationDontshowFlag);

  return { enableCondition };
};
export const useEnableWrapperConditionForMigration = () => {
  const { feature } = useFeatureFlag();
  const enableCondition = feature(FEATURE_FLAG_KEYS.EnableWrapperConditionForMigration);

  return { enableCondition };
};
export const useEnableLeaveModalForESign = () => {
  const { feature } = useFeatureFlag();
  const enableCondition = feature(FEATURE_FLAG_KEYS.EnableLeaveModal);

  return { enableCondition };
};
export const useFixForEnablingSignatory = () => {
  const { feature } = useFeatureFlag();
  const enableCondition = feature(FEATURE_FLAG_KEYS.FixForEnablingSignatoryAfterClientMigration);

  return { enableCondition };
};

export const useAutoSavePAD = () => {
  const { feature } = useFeatureFlag();
  const enableAutoSave = feature(FEATURE_FLAG_KEYS.EnableAutoSavePAD);

  return { enableAutoSave };
};
