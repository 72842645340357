import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface XeroState {
  isTokenExpired: boolean;
  areMissingAccountsCreatedInChartOfAccounts: boolean;
  isCOAPersisted: boolean;
  isMissingAccountsCreated: boolean;
  isCOAAutoMapped: boolean;
}

const initialState: XeroState = {
  isTokenExpired: false,
  areMissingAccountsCreatedInChartOfAccounts: false,
  isCOAPersisted: false,
  isMissingAccountsCreated: false,
  isCOAAutoMapped: false,
};

const xeroSlice = createSlice({
  name: 'addons/xero',
  initialState,
  reducers: {
    setIsTokenExpired(state, action: PayloadAction<boolean>) {
      state.isTokenExpired = action.payload;
    },
    setAreMissingAccountsCreatedInChartOfAccounts(state, action: PayloadAction<boolean>) {
      state.areMissingAccountsCreatedInChartOfAccounts = action.payload;
    },
    setIsCOAPersisted(state, action: PayloadAction<boolean>) {
      state.isCOAPersisted = action.payload;
    },
    setIsMissingAccountsCreated(state, action: PayloadAction<boolean>) {
      state.isMissingAccountsCreated = action.payload;
    },
    setIsCOAAutoMapped(state, action: PayloadAction<boolean>) {
      state.isCOAAutoMapped = action.payload;
    },
  },
});

export const xeroSliceActions = xeroSlice.actions;
export const xeroReducer = xeroSlice.reducer;
export const selectIsTokenExpired = (state: RootState) => state.xeroReducer.isTokenExpired;
export const selectAreMissingAccountsCreatedInChartOfAccounts = (state: RootState) =>
  state.xeroReducer.areMissingAccountsCreatedInChartOfAccounts;
export const selectIsCOAPersisted = (state: RootState) => state.xeroReducer.isCOAPersisted;
export const selectIsMissingAccountsCreated = (state: RootState) => state.xeroReducer.isMissingAccountsCreated;
export const selectIsCOAAutoMapped = (state: RootState) => state.xeroReducer.isCOAAutoMapped;
