import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  IGetEmployeeRelevantStateListRequest,
  IGetEmployeeRelevantStateListResponse,
  IEmployeeRelevantState,
} from 'services/add-employment-province-access/addPoEAccess.contracts';
import { getEmployeeStateList } from 'services/add-employment-province-access/addPoEAccess.services';

interface IPoEState {
  employeeRelevantStateList: Array<IEmployeeRelevantState>;
  loading: boolean;
}

const initialState: IPoEState = {
  employeeRelevantStateList: [],
  loading: false,
};

export const getEmployeeRelevantStateList = createAsyncThunk(
  'poe/getEmployeeRelevantStateList',
  async (request: IGetEmployeeRelevantStateListRequest) => {
    const response: IGetEmployeeRelevantStateListResponse = await getEmployeeStateList(request);
    return response;
  }
);

export const poeCommonSlice = createSlice({
  name: 'poeCommonSlice',
  initialState,
  reducers: {
    reset: (state) => {
      state.employeeRelevantStateList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeRelevantStateList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmployeeRelevantStateList.fulfilled, (state, action) => {
      state.employeeRelevantStateList = action.payload.employeeRelevantStateList;
      state.loading = false;
    });
    builder.addCase(getEmployeeRelevantStateList.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { reset } = poeCommonSlice.actions;
export const poeCommonReducer = poeCommonSlice.reducer;
export const selectCommonPoE = (state: RootState) => state.poeCommonReducer;
export const selectEmployeeRelevantStateList = (state: RootState) => state.poeCommonReducer?.employeeRelevantStateList;
export const isPOELoading = ({ poeCommonReducer }: RootState) => poeCommonReducer.loading;
