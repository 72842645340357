import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { CompanySummaryInterface } from 'services/finance/models/access/companyAccess';
import { ValidEnum } from 'pages/settings/components/accounting-bookkeeping-firm/common/company-email-layout/companyIdOrEmailLayout';

interface IAccountingFirmInit {
  companyId?: number;
  isValidCompany?: number;
  name?: any;
  firmView?: number;
  isEdit?: boolean;
  companyInvitationList?: Array<CompanySummaryInterface>;
  email?: string;
  firstName?: string;
  lastName?: string;
  from?: number;
  clientId?: number;
  partnerId?: number;
  currentRole?: number;
}

const initialState: IAccountingFirmInit = {
  companyId: 0,
  isValidCompany: 0,
  name: '',
  firmView: 0,
  isEdit: false,
  companyInvitationList: [],
  email: '',
  firstName: '',
  lastName: '',
  from: 0,
  currentRole: 0,
};

export const accountingFirmSlice = createSlice({
  name: 'accountingFirm',
  initialState,
  reducers: {
    resetAllData(state) {
      state.companyId = 0;
      state.isValidCompany = 0;
      state.name = '';
      state.firmView = 0;
      state.isEdit = false;
      state.companyInvitationList = [];
      state.email = '';
      state.firstName = '';
      state.lastName = '';
      state.from = 0;
      state.currentRole = 0;
    },
    setCompanyId: (state, action: PayloadAction<IAccountingFirmInit>) => {
      state.companyId = action.payload.companyId;
      state.isValidCompany = action.payload.isValidCompany;
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    setFirmView: (state, action: PayloadAction<IAccountingFirmInit>) => {
      state.firmView = action.payload.firmView;
      state.isEdit = action.payload.isEdit;
      state.companyId = action.payload.companyId;
      state.name = action.payload.name;
      state.from = action.payload.from;
      state.clientId = action.payload.clientId;
      state.partnerId = action.payload.partnerId;
    },
    updateInvitationList: (state, action: PayloadAction<typeof initialState['companyInvitationList']>) => {
      state.companyInvitationList = action.payload ?? [];
    },
    updateIsValidCompany: (state, action: PayloadAction<ValidEnum>) => {
      state.isValidCompany = action.payload;
    },
    updateCurrentRole: (state, action: PayloadAction<number>) => {
      state.currentRole = action.payload;
    },
  },
});

export const {
  setCompanyId,
  setFirmView,
  updateInvitationList,
  resetAllData,
  updateIsValidCompany,
  updateCurrentRole,
} = accountingFirmSlice.actions;
export const accountingFirmReducer = accountingFirmSlice.reducer;
export const getCompanyId = (state: RootState) => state.accountingFirmReducer;
export const selectFirmCompanyId = (state: RootState) => state.accountingFirmReducer?.companyId;
export const selectIsValidCompany = (state: RootState) => state.accountingFirmReducer?.isValidCompany;
export const getFirmView = (state: RootState) => state.accountingFirmReducer;
export const selectFirmView = (state: RootState) => state.accountingFirmReducer?.firmView;
export const selectFirmIsEdit = (state: RootState) => state.accountingFirmReducer?.isEdit;
export const selectFirmClientId = (state: RootState) => state.accountingFirmReducer?.clientId;
export const selectFirmPartnerId = (state: RootState) => state.accountingFirmReducer?.partnerId;
export const selectFirmName = (state: RootState) => state.accountingFirmReducer?.name;
export const selectFirmViewFrom = (state: RootState) => state.accountingFirmReducer?.from;
export const getValues = (state: RootState) => state.accountingFirmReducer;
export const getCompanyInvitationList = (state: RootState) => state.accountingFirmReducer;
export const selectCompanyInvitationList = (state: RootState) => state.accountingFirmReducer?.companyInvitationList;
export const getCurrentRole = (state: RootState) => state.accountingFirmReducer;
export const selectCurrentRole = (state: RootState) => state.accountingFirmReducer?.currentRole;
