import { Post } from 'services/httpClient';
import {
  IGetPersonVacationBalanceRequest,
  IGetPersonVacationBalanceResponse,
  IPersistPersonVacationBalanceRequest,
  IPersistPersonVacationBalanceResponse,
} from './holidayPayAccess.contracts';

const serviceUrl = `/payrollHolidayPayAccess/`;
const PersistPersonVacationBalanceURL = `${serviceUrl}persistpersonvacationbalance`;
const GetPersonVacationBalanceURL = `${serviceUrl}getpersonvacationbalance`;

export const PersistPersonVacationBalance = (
  request: IPersistPersonVacationBalanceRequest
): Promise<IPersistPersonVacationBalanceResponse> => {
  return Post(PersistPersonVacationBalanceURL, request);
};
export const GetPersonVacationBalance = (
  request: IGetPersonVacationBalanceRequest
): Promise<IGetPersonVacationBalanceResponse> => {
  return Post(GetPersonVacationBalanceURL, request);
};
