export enum PermissionsEnum {
  Root = 1,
  SetupRoot = 4,
  PayrollRoot = 7,
  AccountingPortal = 9,
  ReportsRoot = 10,
  CompanySettingsRoot = 13,

  CompanySetupDetails = 16,
  CompanySetupDetailsRead = 17,
  CompanySetupDetailsWrite = 18,

  BankSetup = 19,
  BankSetupRead = 20,
  BankSetupWrite = 21,

  BankSign = 76,
  BankSignRead = 77,
  BankSignWrite = 78,

  PayrollSetup = 22,

  PeopleSetup = 25,
  PeopleSetupRead = 26,
  PeopleSetupWrite = 27,

  TaxSetup = 28,

  Payroll = 31,
  PayrollSubmit = 32,
  PayrollApprove = 33,

  ReportsRegister = 34,
  ReportsRegisterRead = 35,
  ReportsRegisterWrite = 36,

  ReportsWc = 37,
  ReportsWcRead = 38,
  ReportsWcWrite = 39,

  ReportsEarn = 40,
  ReportsEarnRead = 41,
  ReportsEarnWrite = 42,

  ReportBenefits = 43,
  ReportBenefitsRead = 44,
  ReportBenefitsWrite = 45,

  ReportDeductions = 46,
  ReportDeductionsRead = 47,
  ReportDeductionsWrite = 48,

  ReportsPayStubs = 49,
  ReportsPayStubsRead = 50,
  ReportsPayStubsWrite = 51,

  ReportsYtd = 52,
  ReportsYtdRead = 53,
  ReportsYtdWrite = 54,

  ReportsDeposit = 55,
  ReportsDepositRead = 56,
  ReportsDepositWrite = 57,

  ReportsInvoice = 73,
  ReportsInvoiceRead = 74,
  ReportsInvoiceWrite = 75,

  ReportsJournal = 58,
  ReportsJournalRead = 59,
  ReportsJournalWrite = 60,

  CompanySetupGeneral = 61,
  CompanySetupRead = 62,
  CompanySetupWrite = 63,

  UserAccounts = 64,
  UserAccountsRead = 65,
  UserAccountsWrite = 66,

  CloseAccount = 67,
  CloseAccountRead = 68,
  CloseAccountWrite = 69,

  AssociatedCompany = 82,
  AssociatedCompanyRead = 83,
  AssociatedCompanyWrite = 84,

  AccountFirm = 85,
  AccountFirmRead = 86,
  AccountFirmWrite = 87,

  CompanySignup = 70,
  LegacyMigration = 71,
  Processing = 72,
  CustomerServiceOrganization = 80,
  CustomerServiceTicket = 81,
  Qbo = 88,
  Xero = 89,
  Hubspot = 90,
  Zendesk = 91,
  Roe = 92,
  Yodlee = 93,
  ScRepYearEnd = 94,

  EmployeePortal = 97,
  ScEmployeePayroll = 98,
  ScAccountingClient = 99,
  ScAccountingSetting = 100,
  ScEmployeeBasicInfo = 101,
  ScEmployeeSin = 104,
  ScEmployeeTaxSetup = 107,
  ScEmployeeBankingInfo = 110,
  ScEmployeePayStubs = 113,
  ScEmployeeYearEndForms = 116,
  ScNewClient = 119,
  ScClientInvite = 122,
  ScClientDirectory = 125,
  ScClientLogin = 128,
  ScClientLoginRead = 129,
  ScAccountingGeneral = 131,
  ScAccountingBanking = 134,
  ScAccountingVerification = 137,
  ScAccountingUserAccount = 140,
  ScAccountingCloseAccount = 143,
  ScCompanyLogAccess = 147,

  Notification = 150,
  CoSetRoles = 180,
  CoSetSubscription = 183,
  PayrollGeneral = 156,
  PayrollIncome = 159,
  PayrollDeduction = 162,
  PayrollBenefit = 165,
  PayrollStatHoliday = 168,
  PayrollWorkComp = 171,

  PayrollTools = 192,
  PayrollToolsAdjustments = 193,
  PayrollToolsPaystubMemos = 196,
  PayrollToolsPayGroups = 199,
  PayrollToolsHolidayPayCalc = 202,
  PayrollToolsRoe = 205,

  TaxSourceDeduction = 174,
  TaxSourceDeductionRead = 175,
  TaxSourceDeductionWrite = 176,
  TaxEmployer = 177,
  TaxEmployerRead = 178,
  TaxEmployerWrite = 179,

  Alfred = 146,

  Roles = 180,
  RolesRead = 181,
  RolesWrite = 182,
}

//enum for tax settings
export enum TaxSettingsEnum {
  TaxSetupRead = 108,
  TaxSetupWrite = 109,
}

//enum for Payroll
export enum PayrollEnum {
  Payroll = 31,
  PayrollSubmit = 32,
  PayrollApprove = 33,
  NoAccess = 3233,
}

export enum EmployeePeopleEnum {
  OverviewWrite = 191,
  JobDetailsWrite = 115,
}
