import { createAsyncThunk, createSlice, original } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  EmployeeOnboardingStepTypeEnum,
  IGetOnboardingStepsListRequest,
  IGetOnboardingStepsListResponse,
  IOnBoardingStepEnum,
  IOnboardingStepListStatusEnum,
  IPersistOnboardingStepStatusRequest,
  IPersistOnboardingStepStatusResponse,
} from 'services/onboard-question-access/onboardQuestion.contracts';
import {
  GetOnboardingStepsList,
  PersistOnboardingStepStatus,
} from 'services/onboard-question-access/onboardQuestion.service';
import { IOnBoarding } from './people-slice-config';

interface IpeopleSidebarBoolean {
  stepsLoading: boolean;
  isSaveAndContDisabled?: boolean;
}

export interface IStepList extends Omit<IOnBoarding, 'status'> {
  og_status: IOnboardingStepListStatusEnum;
  status: IOnboardingStepListStatusEnum;
  lastCompleted?: boolean;
}
interface IpeopleSidebarNonBool {
  onboardList: IStepList[];
  activeStepIndex: null | number;
  activeStepId: number | null;
  rehireProcess: EmployeeOnboardingStepTypeEnum | null;
  initialStepId: number | null;
}
export interface IPeopleSlice extends IpeopleSidebarNonBool, IpeopleSidebarBoolean {}

const initialState: IPeopleSlice = {
  onboardList: [],
  rehireProcess: null,
  activeStepIndex: null,
  activeStepId: null,
  stepsLoading: false,
  initialStepId: null,
  isSaveAndContDisabled: false,
};

export const getStepsListForGuidedOnboarding = createAsyncThunk(
  'stepslice/onboard/getOnboardingStatusList',
  async (request: IGetOnboardingStepsListRequest) => {
    const response: IGetOnboardingStepsListResponse = await GetOnboardingStepsList(request);
    return response;
  }
);

export const persistOnboardingStepStatus = createAsyncThunk(
  'stepslice/onboard/persiststatus',
  async (request: IPersistOnboardingStepStatusRequest) => {
    const response: IPersistOnboardingStepStatusResponse = await PersistOnboardingStepStatus(request);
    return response;
  }
);

const assignCurrentStep = (data: IGetOnboardingStepsListResponse, activeIndex: number | null): IStepList[] => {
  const updatedList = data.onboardingStepTypeList.map((item, index) => {
    if (activeIndex === null && item.id === data.currentStepTypeId)
      return { ...item, og_status: item.onboardingStepStatusType, status: IOnboardingStepListStatusEnum.Active };
    else if (activeIndex !== null && index === activeIndex)
      return { ...item, og_status: item.onboardingStepStatusType, status: IOnboardingStepListStatusEnum.Active };
    return { ...item, og_status: item.onboardingStepStatusType, status: item.onboardingStepStatusType };
  });
  return updatedList as any;
};

// keeping this commented for now, once ticket is closed will do a PR removing this logic.
// const formatOnBoardStepListData = (list: any, activeIndex: number | null) => {
//   let lastCompletedIndex = null;
//   const updatedList = list.map((item: any, index: number) => {
//     if (
//       item.id === IOnBoardingStepEnum.basicInformation &&
//       item.onboardingStepStatusType === IOnboardingStepListStatusEnum.NotDefined
//     ) {
//       // to handle the initial state when you are user is newly onboarding a person. Then we set the basic info active as default
//       return {
//         ...item,
//         og_status: item.onboardingStepStatusType,
//         status: IOnboardingStepListStatusEnum.Active,
//       };
//     }
//     // when the user resumes a person then we will need to resume from the last step which will be the next step of skipped / compelted
//     if (
//       item.onboardingStepStatusType !== IOnboardingStepListStatusEnum.Active &&
//       item.onboardingStepStatusType !== IOnboardingStepListStatusEnum.NotDefined
//     ) {
//       lastCompletedIndex = index;
//     }

//     // we need to make the step active when user directly clicks a step
//     if (activeIndex === index) {
//       return {
//         ...item,
//         og_status: item.onboardingStepStatusType,
//         status: IOnboardingStepListStatusEnum.Active,
//       };
//     }

//     return {
//       ...item,
//       og_status: item.onboardingStepStatusType,
//       status: item.onboardingStepStatusType,
//     };
//   });
//   if (activeIndex === null && lastCompletedIndex !== null) {
//     updatedList[lastCompletedIndex + 1] = {
//       ...updatedList[lastCompletedIndex + 1],
//       status: IOnboardingStepListStatusEnum.Active,
//     };
//   }
//   return updatedList;
// };

const findCurrentActiveOnboard = (listArr: Array<IStepList>) => {
  const index = listArr.findIndex((list) => list.status === IOnboardingStepListStatusEnum.Active);
  return { index, id: listArr[index]?.id };
};

const takeUserToNextStep = (list: IPeopleSlice['onboardList'] | undefined, lastIndex: number): Array<IStepList> => {
  if (list?.length)
    return list.map((item, index) => {
      if (index === lastIndex) return { ...item, status: IOnboardingStepListStatusEnum.Active };
      if (item.status === IOnboardingStepListStatusEnum.Active)
        return { ...item, status: IOnboardingStepListStatusEnum.NotDefined };
      return { ...item };
    });
  return [];
};

const peopleSlice = createSlice({
  name: 'peoplecommonstates',
  initialState,
  reducers: {
    resetPeopleSideBar: (state) => {
      state.onboardList = [];
      state.activeStepId = null;
      state.activeStepIndex = null;
    },
    proceedOnBoardingStep: (state, action: { payload: { currentIndex?: number; goBack?: boolean } }) => {
      const updatedIndex =
        action.payload.currentIndex !== undefined
          ? action.payload.currentIndex
          : (state.activeStepIndex || 0) + (action.payload.goBack ? -1 : 1);
      state.onboardList = takeUserToNextStep(original(state.onboardList), updatedIndex);
      state.activeStepId = state.onboardList?.[updatedIndex]?.id || 1;
      state.activeStepIndex = updatedIndex;
    },
    setInitialOnBoardingStep: (state, action: { payload: { step: IOnBoardingStepEnum } }) => {
      const value = action.payload.step;
      state.initialStepId = value;
    },
    setSaveAndContinueState: (state, action: { payload: { disableSaveAndCont: boolean } }) => {
      state.isSaveAndContDisabled = action.payload.disableSaveAndCont;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStepsListForGuidedOnboarding.pending, (state) => {
      state.stepsLoading = true;
    });
    builder.addCase(getStepsListForGuidedOnboarding.fulfilled, (state, action) => {
      state.stepsLoading = false;

      if (state.initialStepId) {
        const initialActiveIndex = action.payload.onboardingStepTypeList.findIndex(
          (item) => item.id === state.initialStepId
        );
        state.activeStepIndex = initialActiveIndex;
        state.initialStepId = null;
      }

      // const list = formatOnBoardStepListData(action.payload.onboardingStepTypeList, state.activeStepIndex) as any;
      const list = assignCurrentStep(action.payload, state.activeStepIndex);
      if (state.activeStepId === null) {
        const { index, id } = findCurrentActiveOnboard(list) || { index: 0, id: 1 };
        state.activeStepId = id;
        state.activeStepIndex = index;
      }
      state.onboardList = list;
    });
    builder.addCase(getStepsListForGuidedOnboarding.rejected, (state) => {
      state.stepsLoading = false;
    });
  },
});

export const { proceedOnBoardingStep, setInitialOnBoardingStep, resetPeopleSideBar, setSaveAndContinueState } =
  peopleSlice.actions;
export const peoplesCommonReducer = peopleSlice.reducer;
export const getOnBoardList = (state: RootState) => state.peoplesCommonReducer.onboardList;
export const getCurrentRouteIndex = (state: RootState) => state.peoplesCommonReducer.activeStepIndex;
export const getActiveTab = (state: RootState) => state.peoplesCommonReducer.activeStepId;
export const getSaveAndContinueState = (state: RootState) => state.peoplesCommonReducer.isSaveAndContDisabled;
