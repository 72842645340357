import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

const initialState: { accountVerificationStatus: number } = {
  accountVerificationStatus: 0,
};

export const accountVerificationSlice = createSlice({
  name: 'accountVerificationStatus',
  initialState,
  reducers: {
    updateAccountVerification: (state, action: PayloadAction<typeof initialState>) => {
      state.accountVerificationStatus = action.payload.accountVerificationStatus;
    },
  },
});

export const { updateAccountVerification } = accountVerificationSlice.actions;
export const accountVerificationSliceReducer = accountVerificationSlice.reducer;
export const selectVerificationStatus = (state: RootState) =>
  state.accountVerificationSliceReducer.accountVerificationStatus;
