import { makeStyles } from '@material-ui/core/styles';

export const EditViewStyles = makeStyles((theme) => ({
  accountOwnerCardContainer: {
    display: 'flex',
  },
  cardContainer: {
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  role: {
    width: '227px',
  },
  location: {
    width: '348px',
  },
}));
