import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import companyAccessService, {
  CompanyInterface,
  GetCompanyRequestInterface,
} from 'services/appCore/models/access/companyAccess';

export interface ICompanyDetailsState {
  company: Partial<CompanyInterface>;
  associatedCompanyList: Array<CompanyInterface> | [];
  counterToTriggerSidebarMenuStateAssessment: number;
  padErrorOccured: boolean;
}

const initialState: ICompanyDetailsState = {
  company: {},
  associatedCompanyList: [],
  counterToTriggerSidebarMenuStateAssessment: 0,
  padErrorOccured: false,
};

export const getCompany = createAsyncThunk(
  'companydetails/getCompany',
  async (
    params: Partial<
      Pick<
        GetCompanyRequestInterface,
        'id' | 'companyCode' | 'companyName' | 'email' | 'isPartner' | 'includeEmployeeCounts'
      >
    >
  ) => {
    return await companyAccessService.getCompany({
      companyCode: '',
      companyName: '',
      email: '',
      getByCompanyCode: !!params.companyCode,
      getByEmail: !!params.email,
      getById: !!params.id,
      getByName: !!params.companyName,
      id: 0,
      includeEmployeeCounts: false,
      isPartner: false,
      ...params,
    });
  }
);

export const companyDetailsSlice = createSlice({
  name: 'companydetails',
  initialState,
  reducers: {
    updateCompanyDetails: (state, action: PayloadAction<ICompanyDetailsState>) => {
      state.associatedCompanyList = action.payload.associatedCompanyList;
      state.company = action.payload.company;
    },
    setPadErrorData: (state, action: PayloadAction<boolean>) => {
      state.padErrorOccured = action.payload;
    },
    assessSidebarMenuState: (state) => {
      state.counterToTriggerSidebarMenuStateAssessment += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompany.fulfilled, (state, action) => {
      // todo: didn't find "associatedCompanyList" in the response
      // state.associatedCompanyList = action.payload.associatedCompanyList;
      state.company = action.payload.company ?? {};
    });
  },
});

export const { updateCompanyDetails, assessSidebarMenuState, setPadErrorData } = companyDetailsSlice.actions;
export const companyDetailsReducer = companyDetailsSlice.reducer;
export const selectCompanyDetails = (state: RootState) => state.companyDetailsReducer;
export const selectCompany = createSelector(selectCompanyDetails, ({ company }) => company);
export const selectCompanyDetailsLocationList = (state: RootState) => state.companyDetailsReducer.company?.locationList;
export const selectAccountOwnerId = createSelector(selectCompanyDetails, (state) => state.company.accountOwnerPersonId);
export const selectPADErrorData = createSelector(selectCompanyDetails, ({ padErrorOccured }) => padErrorOccured);
