import { createSlice, createSelector, current } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IFilterTypeInterface } from 'services/payroll-dashboard-access/payrollDashboardAccess.contracts';
import { filterWidgetAction, searchNameByTextFilter } from './payrollSliceHelpers';
import { IfieldListData } from 'services/finance-field-access/financeFieldAccess.contracts';

export const cellInit = { designation: null, columnIndex: null, additional: {} };
export const cellInitDefault: any = {};
export type IFilteredTableRows = 'isIncluded' | 'type' | 'departmentName';
type OptionsFlags<Type> = {
  [Property in keyof Type]: {
    designation: string | null;
    columnIndex: number | null;
    additional?: { [key: number]: any };
  };
};
type IFieldType = {
  designation: string;
  cellInfo: any;
  value: any;
  decoder?: { TooltipId: number; HoursCellDesignation?: string; MidcycleRaiseDate?: string };
};

type IDefaultFieldType = {
  [key: number]: IFieldType;
};

export enum SortOrder {
  Desc = 1,
  Asc = -1,
}
export interface IPeWorkTableRow extends matrixProps {
  __tableEmployeeRowIndex: number;
  __tableJobRowIndex?: number;
  __tableRowIndex: number;
  __serviceMatrixRowIndex: number;
  __isJobRow: boolean;
  rateCellDesignation?: matrixProps['salaryType'];
  totalJobs: number;
}

export interface matrixProps {
  isIncluded: IFieldType;
  name: IFieldType;
  type: IFieldType;
  grossPay: IFieldType;
  employeeId: IFieldType;
  jobName?: IFieldType;
  jobId?: IFieldType;
  departmentName?: IFieldType;
  totalHours?: IFieldType;
  locationName?: IFieldType;
  regularHours?: IFieldType;
  regularRate?: IFieldType;
  overtimeHours?: IFieldType;
  defaultOtPay?: IDefaultFieldType;
  defaultRegularPay?: IDefaultFieldType;
  doubleOtPay?: IFieldType;
  otherIncomes?: IFieldType;
  deductions?: IFieldType;
  salaryType?: { hourly: string | null; yearly: string | null; jobPerContract: boolean };
  rowKey?: IFieldType;
  netPay?: IFieldType;
  salesTax?: IFieldType;
}

export interface IDeleteCodeListNormalized {
  [key: number]: IfieldListData;
}
export interface IImportHourMapping {
  [empId: number]: { [jobId: number]: any };
}
export type ITableViewKeys = OptionsFlags<matrixProps>;
interface IPayrollHoursIncomeReducer {
  matrixFilteredColumns: any;
  columnToLetterMapping: { [key: string]: any };
  fieldIdMapping: { [key: string]: any };
  newfieldIdMapping: { [key: string]: any };
  allEmployeesIncluded: boolean;
  tableRowList: Array<IPeWorkTableRow>;
  tableRowListCopy: Array<IPeWorkTableRow>;
  tableViewCellNames: ITableViewKeys;
  hashValues: { [key: string]: any };
  originalValueHash: { [key: string]: any };
  totalGrossPay: string;
  totalHours: string;
  heSearchText: any;
  rowInclusionFlag: boolean;
  filterOptions: IFilterTypeInterface[];
  stateHolidayOriginals: { hours: number; amount: number };
  isStatPayFieldFocused: boolean;
  employeeJobMapping: IImportHourMapping;
  importHours: {
    loadingImports: boolean;
    userClickedImport: boolean;
    timeEmployeeMapping: { [key: number | string]: number };
    connectionStatus: boolean;
    didMappingChanged: boolean;
    showImportHourPrompt: boolean;
  };
  fieldList: {
    [PayTypesEnum.Income]: [];
    [PayTypesEnum.AdditionalIncomes]: [];
    [PayTypesEnum.Deductions]: [];
    [PayTypesEnum.Benefits]: [];
  };
  fieldListNormalized: IDeleteCodeListNormalized;
  isCalculationInProgress: boolean;
}
export enum PayTypesEnum {
  Income = 'incomes',
  AdditionalIncomes = 'additionalIncomes',
  Benefits = 'benefits',
  Deductions = 'deductions',
}

export interface IsetVariableEnum {
  payload: {
    key:
      | 'matrixFilteredColumns'
      | 'stateHolidayOriginals'
      | 'isStatPayFieldFocused'
      | 'fieldIdMapping'
      | 'newfieldIdMapping'
      | 'employeeJobMapping'
      | 'heSearchText'
      | 'columnToLetterMapping'
      | 'allEmployeesIncluded'
      | 'tableRowList'
      | 'tableRowListCopy'
      | 'tableViewCellNames'
      | 'filterOptions'
      | 'hashValues'
      | 'originalValueHash'
      | 'isCalculationInProgress'
      | 'fieldList';
    value: any;
    isUpdated?: boolean;
  };
}
export const tableViewCellInit = {
  isIncluded: cellInit,
  name: cellInit,
  type: cellInit,
  grossPay: cellInit,
  employeeId: cellInit,
  jobName: cellInit,
  departmentName: cellInit,
  totalHours: cellInit,
  regularHours: cellInit,
  regularRate: cellInit,
  overtimeHours: cellInit,
  otherIncomes: cellInit,
  deductions: cellInit,
};

const initialState: IPayrollHoursIncomeReducer = {
  matrixFilteredColumns: {
    [PayTypesEnum.Income]: [],
    [PayTypesEnum.AdditionalIncomes]: [],
    [PayTypesEnum.Deductions]: [],
    [PayTypesEnum.Benefits]: [],
  },
  originalValueHash: {},
  fieldIdMapping: {},
  newfieldIdMapping: {},
  totalHours: '0',
  heSearchText: '',
  filterOptions: [],
  totalGrossPay: '',
  columnToLetterMapping: {},
  allEmployeesIncluded: false,
  rowInclusionFlag: false,
  tableRowList: [],
  tableRowListCopy: [],
  tableViewCellNames: tableViewCellInit,
  hashValues: {},
  employeeJobMapping: {},
  isStatPayFieldFocused: false,
  stateHolidayOriginals: { hours: 0, amount: 0 },
  importHours: {
    loadingImports: false,
    userClickedImport: false,
    timeEmployeeMapping: {},
    connectionStatus: false,
    didMappingChanged: false,
    showImportHourPrompt: false,
  },
  fieldList: {
    [PayTypesEnum.Income]: [],
    [PayTypesEnum.AdditionalIncomes]: [],
    [PayTypesEnum.Deductions]: [],
    [PayTypesEnum.Benefits]: [],
  },
  fieldListNormalized: {},
  isCalculationInProgress: false,
};

/** to group the job name into the starting positon of record */
const unGroupedArray = (arr: IPeWorkTableRow[]) => {
  let updatedArr: IPeWorkTableRow[] = [];
  let jobArr: IPeWorkTableRow[] = [];
  arr.forEach((item) => {
    if (item.__isJobRow) {
      jobArr.push({ ...item });
    } else {
      updatedArr.push({ ...item });
      updatedArr = updatedArr.concat(jobArr);
      jobArr = [];
    }
  });
  return updatedArr;
};

/**
 *
 * @param arr The input array to be sorted
 * @param sortOrder Order in which sorting should occur
 * @param exceptionKey This is a boolean which will not allow that particular instance to be sorted.
 * @returns
 */
const sortByName = (arr: IPeWorkTableRow[], sortOrder?: SortOrder, exceptionKey?: any) => {
  const isNotFirefox = navigator.userAgent.search('Firefox') === -1;
  const sortOrderBrower = isNotFirefox ? 1 : -1; // the sort for firfox is just opposite for chrome
  const sortBy = sortOrder || isNotFirefox ? SortOrder.Desc : SortOrder.Asc; // change enum to update the sortOrder
  const sorted = arr.sort((a: any, b: any) => {
    if (a[exceptionKey] && b[exceptionKey]) return 1;
    return a.name.value.toLowerCase() > b.name.value.toLowerCase()
      ? sortBy * sortOrderBrower
      : -sortBy * sortOrderBrower;
  });
  let result = sorted;
  if (sortBy >= 1) {
    result = unGroupedArray(sorted);
  }
  return result;
};

export const hoursIncomeRedux = createSlice({
  name: 'payrollHoursIncomeReducer',
  initialState,
  reducers: {
    rowInclusionChanged: (state, action: { payload: { key: 'rowInclusionFlag'; value: boolean } }) => {
      state[action.payload.key] = action.payload.value;
    },
    commonHeSetVariable: (
      state,
      action: { payload: { key: 'totalGrossPay' | 'totalHours' | 'heSearchText'; value: any } }
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    sortRowData: (state, action: any) => {
      state.tableRowListCopy = sortByName(JSON.parse(JSON.stringify(state.tableRowListCopy)), action.payload.sortOrder);
    },
    filterRowData: (state, action: any) => {
      if (current(state.tableRowList).length && current(state.filterOptions).length) {
        let updatedData =
          filterWidgetAction({
            list: current(state.tableRowList),
            filterOptions: current(state.filterOptions),
            prevFilter: action.payload.prevFilter,
          }) || [];
        updatedData = searchNameByTextFilter({
          list: JSON.parse(JSON.stringify(updatedData)),
          searchText: state.heSearchText,
        });
        state.tableRowListCopy = updatedData;
      }
    },
    payrollSetVariable: (state, action: IsetVariableEnum) => {
      if (action.payload.key === 'tableRowList') {
        const descendingSorted = sortByName(JSON.parse(JSON.stringify(action.payload.value)), undefined);
        /** when the table list is updated we don't want to sort it */
        state.tableRowListCopy = action.payload.isUpdated
          ? action.payload.value
          : JSON.parse(JSON.stringify(descendingSorted));
        state.tableRowList = action.payload.isUpdated
          ? action.payload.value
          : JSON.parse(JSON.stringify(descendingSorted));
      } else state[action.payload.key] = action.payload.value;
      if (action.payload.key === 'tableRowListCopy')
        state.tableRowListCopy = sortByName(JSON.parse(JSON.stringify(action.payload.value)));
    },
    multiplePayrollsetVariable: (state, action: { payload: IsetVariableEnum['payload'][] }) => {
      action.payload.forEach((item) => {
        state[item.key] = item.value;
      });
    },
    updateFieldList: (state, action: { payload: { key: PayTypesEnum; value: any } }) => {
      state.fieldList[action.payload.key] = action.payload.value;
    },
    updateFieldListNormalized: (state, action: { payload: { value: any } }) => {
      state.fieldListNormalized = {
        ...state.fieldListNormalized,
        ...action.payload.value,
      };
    },
    updateStatHolidayValues: (state, action) => {
      state.stateHolidayOriginals = { ...action.payload.value };
    },
    setImportHourVariables: (state, action) => {
      state.importHours = { ...state.importHours, ...action.payload };
    },
    resetImportHours: (state) => {
      state.importHours = initialState.importHours;
      state.employeeJobMapping = {};
    },
  },
});

export const {
  payrollSetVariable,
  updateFieldList,
  multiplePayrollsetVariable,
  updateFieldListNormalized,
  commonHeSetVariable,
  rowInclusionChanged,
  sortRowData,
  filterRowData,
  updateStatHolidayValues,
  setImportHourVariables,
  resetImportHours,
} = hoursIncomeRedux.actions;
export const payrollHoursIncomeReducer = hoursIncomeRedux.reducer;
export const payrollHoursIncomeStates = (state: RootState) => state.payrollHoursIncomeReducer;
export const payrollHoursIncomeStatesMatrix = (state: RootState) =>
  state.payrollHoursIncomeReducer.matrixFilteredColumns;
export const payrollHoursIncomeStatesColumn = (state: RootState) =>
  state.payrollHoursIncomeReducer.columnToLetterMapping;
export const originalHashSelector = (state: RootState) => state.payrollHoursIncomeReducer.originalValueHash;
export const getNewFieldBaseIdMappings = (state: RootState) => state.payrollHoursIncomeReducer.newfieldIdMapping;
export const getOldFieldBaseIdMappings = (state: RootState) => state.payrollHoursIncomeReducer.fieldIdMapping;
export const getOriginalStatHoliday = (state: RootState) => state.payrollHoursIncomeReducer.stateHolidayOriginals;
export const selectStatPayFieldFocused = (state: RootState) => state.payrollHoursIncomeReducer.isStatPayFieldFocused;
export const getCalcuationInProgress = (state: RootState) => state.payrollHoursIncomeReducer.isCalculationInProgress;
export const getEmployeeJobMapping = (state: RootState) => state.payrollHoursIncomeReducer.employeeJobMapping;
export const getEditedHashValues = (state: RootState) => state.payrollHoursIncomeReducer.hashValues;
export const getImportHourStates = (state: RootState) => state.payrollHoursIncomeReducer.importHours;
export const selectLoadingImports = createSelector(getImportHourStates, ({ loadingImports }) => loadingImports);
export const selectUserClickedImport = createSelector(
  getImportHourStates,
  ({ userClickedImport }) => userClickedImport
);
export const selectTimeEmployeeMapping = createSelector(
  getImportHourStates,
  ({ timeEmployeeMapping }) => timeEmployeeMapping
);

export const selectConnectionStatus = createSelector(getImportHourStates, ({ connectionStatus }) => connectionStatus);
export const selectFieldListNormalized = (state: RootState) => state.payrollHoursIncomeReducer.fieldListNormalized;
export const selectTotalGrossPay = (state: RootState) => state.payrollHoursIncomeReducer.totalGrossPay;
export const selectSearchText = (state: RootState) => state.payrollHoursIncomeReducer.heSearchText;
export const selectTableRowList = (state: RootState) => state.payrollHoursIncomeReducer.tableRowList;
export const selectTableRowListCopy = (state: RootState) => state.payrollHoursIncomeReducer.tableRowListCopy;
export const selectRowInclusionFlag = (state: RootState) => state.payrollHoursIncomeReducer.rowInclusionFlag;
export const selectFilterOptions = (state: RootState) => state.payrollHoursIncomeReducer.filterOptions;
export const selectHashValues = (state: RootState) => state.payrollHoursIncomeReducer.hashValues;
export const selectAllEmployeeIncluded = (state: RootState) => state.payrollHoursIncomeReducer.allEmployeesIncluded;
export const selectTableCellViewNames = (state: RootState) => state.payrollHoursIncomeReducer.tableViewCellNames;
export const selectDidMappingChanged = (state: RootState) =>
  state.payrollHoursIncomeReducer?.importHours?.didMappingChanged;
export const selectShowImportHourPrompt = (state: RootState) =>
  state.payrollHoursIncomeReducer?.importHours?.showImportHourPrompt;
export const makeSelectHashValueById = () =>
  createSelector([selectHashValues, (state, id) => id], (hashValues, id) => hashValues[id]);
