import React, { useEffect, useRef } from 'react';
import WpZeroState from '@wagepoint/ui-toolkit/components/wp-zeroState';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ErrorBoundaryImage } from 'assets/images/error-boundary.svg';
import { publish } from 'util/customEvents';
import { SHOW_MARKER_IO_REPORTED } from 'util/customEvents/eventContracts';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppContentWrapper from 'shared-components/AppContentWrapper';

const ErrorBoundaryFallBack = ({ error, resetErrorBoundary }: { error: any; resetErrorBoundary: any }) => {
  const location = useLocation();
  const { t } = useTranslation('common', { keyPrefix: 'errorBoundary' });

  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const reportIssueMarkerIo = () => {
    // eslint-disable-next-line no-console
    console.log(error);
    publish({ eventName: SHOW_MARKER_IO_REPORTED, data: null });
  };

  return (
    <Grid item xs={12}>
      <AppContentWrapper>
        <WpZeroState
          description={t('description')}
          illustration={<ErrorBoundaryImage />}
          title={t('title')}
          titleVariant="h2"
          button={{
            label: t('actionBtn'),
            fn: () => {
              reportIssueMarkerIo();
            },
          }}
        />
      </AppContentWrapper>
    </Grid>
  );
};

export default ErrorBoundaryFallBack;
