import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { ILocation } from 'services/company-access/companyAccess.contracts';
import {
  EmployeeOnboardingTypeEnum,
  IGetOnboardingPreferencesListRequest,
  IGetOnboardingPreferencesListResponse,
  IGetSelfOnboardingFieldGroupListRequest,
  IGetSelfOnboardingFieldGroupListResponse,
  IOnboardingPreferences,
  IStaffSelfOnboardList,
  PersonTypeEnum,
} from 'services/onboard-question-access/onboardQuestion.contracts';
import {
  GetOnboardingPreferencesList,
  GetSelfOnboardingFieldGroupList,
} from 'services/onboard-question-access/onboardQuestion.service';
import { IGetPayGroupListRequest, IGetPayGroupListResponse } from 'services/paygroup-access/paygroupAccess.contracts';
import { GetPayGroupList } from 'services/paygroup-access/paygroupAccess.service';
import {
  IGetPersonJobInitialStateRequest,
  IGetPersonJobInitialStateResponse,
  IPayrollInfo,
} from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.contracts';
import { GetPersonJobInitialState } from 'services/payroll-person-onboard-access/payrollPersonOnboardAccess.service';
import { EmployeeStateTypeEnum } from 'services/person-access/personAccess.contracts';

type ISelfOnboardObj = { [key: number]: { [key: number]: boolean } };

interface INumberFields {
  personId: null | number;
  dontShowMeFlags: number | null;
}
type IAddpeople = {
  onboardingTypeId: null | EmployeeOnboardingTypeEnum;
  employeeStatusTypeIdList: null | EmployeeStateTypeEnum[]; // whether terminated, active etc.
  employementTypeIdList: null | PersonTypeEnum[]; // whether contractor/employee
  havePaidThisYear: undefined | boolean; // whether the employee is paid for year or not : yes-1, no-0
  isStaffOnboardingList: IStaffSelfOnboardList[]; // selection made on self onboarding question
  isStaffSelfOnboardingEnabled: boolean | undefined; ///: yes-1, no-0
};
interface IRehireData {
  hireDate: string;
  onCancelRedirect?: string;
}
export interface INanFields {
  listOfStates: any[];
  locationList: any[];
  primaryProvince: ILocation | null;
  addPeopleInfo: IAddpeople;
  addPeopleInfoPersist: IAddpeople;
  isInitGetReqCalled: boolean;
  rehireData: IRehireData | null;
  isBasicInfoFilled: boolean;
  personName: { firstName: string | null; lastName: string | null };
  hasReset: boolean;
  hasInvite: boolean;
  selfOnboardObject: ISelfOnboardObj;
  selfOnboardedFieldsList: any[];
  selectedPerson: IPeopleSelectedPerson;
  isInvited: boolean | null;
  isSelfOnboardLoading: boolean;
  paygroup: { list: any[]; isLoading: boolean };
  flatListOfStates: { [key: number]: any };
  payrollInfo: Partial<IPayrollInfo>;
  editPageLoading: boolean;
  calculationProgressing: boolean;
  priorPayrollAmountLoading: { [key: number]: { [key: number]: boolean } };
  priorPayrollDisableButton: { [key: number]: { [key: number]: boolean } };
  terminatedFormData: {
    hireDate: string | null;
    lastWorkingDate: string | null;
    payGroupId?: number | null;
  };
}
export interface IPeopleSelectedPerson {
  id: number;
  firstName?: string;
  lastName?: string;
}
export interface IPeopleSharedSlice extends INumberFields, INanFields {}

const initialState: IPeopleSharedSlice = {
  listOfStates: [],
  locationList: [],
  flatListOfStates: {},
  isSelfOnboardLoading: false,
  hasReset: false,
  primaryProvince: null,
  isBasicInfoFilled: false,
  hasInvite: false,
  addPeopleInfo: {
    onboardingTypeId: null,
    employeeStatusTypeIdList: null,
    employementTypeIdList: null,
    havePaidThisYear: undefined,
    isStaffOnboardingList: [],
    isStaffSelfOnboardingEnabled: undefined,
  },
  addPeopleInfoPersist: {
    onboardingTypeId: null,
    employeeStatusTypeIdList: null,
    employementTypeIdList: null,
    havePaidThisYear: undefined,
    isStaffOnboardingList: [],
    isStaffSelfOnboardingEnabled: undefined,
  },
  personId: null,
  isInitGetReqCalled: false,
  rehireData: null,
  personName: { firstName: null, lastName: null },
  selectedPerson: { id: 0 },
  isInvited: null,
  selfOnboardObject: {},
  selfOnboardedFieldsList: [],
  editPageLoading: true,
  priorPayrollAmountLoading: {},
  priorPayrollDisableButton: {},
  paygroup: {
    list: [],
    isLoading: false,
  },
  payrollInfo: {},
  dontShowMeFlags: null,
  calculationProgressing: false,
  terminatedFormData: {
    hireDate: null,
    lastWorkingDate: null,
    payGroupId: null,
  },
};

export const getSelfBoardFieldsList = createAsyncThunk(
  'onboarding/getSelfOnboardingFieldGroups',
  async (request: IGetSelfOnboardingFieldGroupListRequest) => {
    const response: IGetSelfOnboardingFieldGroupListResponse = await GetSelfOnboardingFieldGroupList(request);
    return response;
  }
);

export const getPaygroupSummaryList = createAsyncThunk(
  'onboarding/basicinfo',
  async (request: IGetPayGroupListRequest) => {
    const response: IGetPayGroupListResponse = await GetPayGroupList({
      ...request,
    });
    return response;
  }
);

export const getOnboardingPreferencesList = createAsyncThunk(
  'onboarding/getonboardingpreferenceslist',
  async (request: IGetOnboardingPreferencesListRequest) => {
    const response: IGetOnboardingPreferencesListResponse = await GetOnboardingPreferencesList(request);
    return response;
  }
);

export const getPersonJobInitialState = createAsyncThunk(
  'people/onboarding/jobDetails/getPersonJobInitialState',
  async (request: IGetPersonJobInitialStateRequest) => {
    const response: IGetPersonJobInitialStateResponse = await GetPersonJobInitialState(request);
    return response;
  }
);

const populateSelfFieldConfig = (list: any, selfBoardParams: number[]) => {
  const object: { [key: number]: { [key: number]: boolean } } = {};
  list.forEach((mainItem: any) => {
    if (selfBoardParams.includes(mainItem.id)) {
      object[mainItem.id] = {};
      mainItem?.onboardFieldList.forEach((subItem: any) => {
        object[mainItem.id][subItem.id] = true;
      });
    }
  });
  return object;
};

const formatPreferenceData = ({
  onboardingTypeId,
  isStaffSelfOnboardingEnabled,
  havePaidThisYear,
  employeeStatusTypeIdList,
  selfOnboardFieldGroupIdList,
  employementTypeIdList,
}: IOnboardingPreferences) => {
  return {
    onboardingTypeId,
    employeeStatusTypeIdList,
    employementTypeIdList,
    havePaidThisYear,
    isStaffOnboardingList: selfOnboardFieldGroupIdList,
    isStaffSelfOnboardingEnabled,
  };
};

export const peopleSharedSlice = createSlice({
  name: 'peopleSharedData',
  initialState,
  reducers: {
    updateSharedState: (state, action: PayloadAction<{ key: keyof INanFields; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    updateInviteStatus: (state, action: PayloadAction<{ value: boolean }>) => {
      state.hasInvite = action.payload.value;
    },
    populateAddPeopleInfo: (state, action: PayloadAction<{ value: IGetOnboardingPreferencesListResponse }>) => {
      if (action.payload.value.onboardingPreferencesList[0]) {
        state.addPeopleInfo = formatPreferenceData(action.payload.value.onboardingPreferencesList[0]) as any;
      }
    },
    updateProvinceList: (state, action: any) => {
      state.listOfStates = action.payload.value;
      state.flatListOfStates = action.payload.value.reduce((acc: any, item: any) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },
    setSelectedPersonInPeopleEdit: (state, action) => {
      state.selectedPerson = action.payload.value;
    },
    updateSharedNumberFields: (state, action: PayloadAction<{ key: keyof INumberFields; value: number | null }>) => {
      state[action.payload.key] = action.payload.value;
    },
    updateDataForOnboarding: (
      state,
      action: PayloadAction<{ personId: number; isInvited: boolean; firstName: string; lastName: string }>
    ) => {
      state.personId = action.payload.personId;
      state.isInvited = action.payload.isInvited;
      state.personName = { firstName: action.payload.firstName, lastName: action.payload.lastName };
    },
    updateAddPeopleInfo: (
      state,
      action: PayloadAction<{ key: 'employeeStatusTypeIdList' | 'employementTypeIdList'; value: any }>
    ) => {
      state.addPeopleInfo[action.payload.key] = action.payload.value;
    },
    resetPeopleInfo: (state) => {
      state.addPeopleInfo = initialState.addPeopleInfo;
      state.addPeopleInfoPersist = initialState.addPeopleInfoPersist;
    },
    updateSelfOnboardingList: (state, action: PayloadAction<{ key: 'isStaffOnboardingList'; value: any }>) => {
      state.addPeopleInfo[action.payload.key] = action.payload.value;
    },
    updateRehireData: (state, action: PayloadAction<{ hireDate: string; onCancelRedirect?: string }>) => {
      state.rehireData = {
        hireDate: action.payload.hireDate,
        onCancelRedirect: action.payload.onCancelRedirect,
      };
    },
    updatePriorPayrollAmountLoadingState: (
      state,
      action: PayloadAction<{ key: number; value: boolean; stateId: number }>
    ) => {
      if (!state.priorPayrollAmountLoading[action.payload.stateId])
        state.priorPayrollAmountLoading[action.payload.stateId] = {};
      state.priorPayrollAmountLoading[action.payload.stateId][action.payload.key] = action.payload.value;
    },
    updatePriorPayrollDisableButtonState: (
      state,
      action: PayloadAction<{ key: number; value: boolean; stateId: number }>
    ) => {
      if (!state.priorPayrollDisableButton[action.payload.stateId])
        state.priorPayrollDisableButton[action.payload.stateId] = {};
      state.priorPayrollDisableButton[action.payload.stateId][action.payload.key] = action.payload.value;
    },
    resetOnboarding: (state) => {
      state.personId = null;
      state.selfOnboardObject = {};
      state.selfOnboardedFieldsList = [];
      state.rehireData = null;
      state.personName = { firstName: null, lastName: null };
      state.terminatedFormData = { hireDate: null, lastWorkingDate: null };
    },
    updateTerminateData: (
      state,
      action: PayloadAction<{ hireDate: string; lastWorkingDate: string; payGroupId?: number }>
    ) => {
      state.terminatedFormData = {
        hireDate: action.payload?.hireDate,
        lastWorkingDate: action.payload?.lastWorkingDate,
        payGroupId: action.payload?.payGroupId,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaygroupSummaryList.pending, (state) => {
      state.paygroup.isLoading = true;
    });
    builder.addCase(getPaygroupSummaryList.fulfilled, (state, action) => {
      state.paygroup.isLoading = false;
      state.paygroup.list = action.payload?.payGroupSummaryList || [];
    });
    builder.addCase(getPaygroupSummaryList.rejected, (state) => {
      state.paygroup.isLoading = false;
    });
    builder.addCase(getSelfBoardFieldsList.pending, (state) => {
      state.isSelfOnboardLoading = true;
    });
    builder.addCase(getSelfBoardFieldsList.fulfilled, (state, action) => {
      state.isSelfOnboardLoading = false;
      state.selfOnboardedFieldsList = action.payload?.onboardFieldGroupList;
      state.selfOnboardObject = populateSelfFieldConfig(
        action.payload?.onboardFieldGroupList,
        state.addPeopleInfo.isStaffOnboardingList
      );
    });
    builder.addCase(getOnboardingPreferencesList.fulfilled, (state, action) => {
      if (action.payload.onboardingPreferencesList[0]) {
        state.addPeopleInfo = formatPreferenceData(action.payload.onboardingPreferencesList[0]) as any;
      }
    });
    builder.addCase(getPersonJobInitialState.fulfilled, (state, action) => {
      const { validationResults, ...payrollInfo } = action.payload;
      state.payrollInfo = payrollInfo;
    });
  },
});

export const {
  updateSharedState,
  updateProvinceList,
  updateSharedNumberFields,
  updateAddPeopleInfo,
  resetPeopleInfo,
  updateSelfOnboardingList,
  resetOnboarding,
  updateDataForOnboarding,
  updateInviteStatus,
  populateAddPeopleInfo,
  setSelectedPersonInPeopleEdit,
  updatePriorPayrollAmountLoadingState,
  updatePriorPayrollDisableButtonState,
  updateTerminateData,
} = peopleSharedSlice.actions;

export const peopleSharedReducer = peopleSharedSlice.reducer;
export const getCalculationProgress = (state: RootState) => state.peopleSharedReducer.calculationProgressing;
export const getProvincesOfPeople = (state: RootState) => state.peopleSharedReducer.listOfStates;
export const getProvincesOfPeopleFlat = (state: RootState) => state.peopleSharedReducer.flatListOfStates;
export const getOnBoardingLocation = (state: RootState) => state.peopleSharedReducer.locationList;
export const getPrimaryProvince = (state: RootState) => state.peopleSharedReducer.primaryProvince;
export const getAddPeopleInfoSelector = (state: RootState) => state.peopleSharedReducer.addPeopleInfo;
export const selectEmployeeStatusTypeIdList = (state: RootState) =>
  state.peopleSharedReducer.addPeopleInfo?.employeeStatusTypeIdList;
export const selectIsStaffOnboardingList = (state: RootState) =>
  state.peopleSharedReducer.addPeopleInfo?.isStaffOnboardingList;
export const selectIsStaffSelfOnboardingEnabled = (state: RootState) =>
  state.peopleSharedReducer.addPeopleInfo?.isStaffSelfOnboardingEnabled;
export const getAddPeopleInfoPersitedSelector = (state: RootState) => state.peopleSharedReducer.addPeopleInfoPersist;
export const getPersonDetailSelector = (state: RootState) => ({
  personId: state.peopleSharedReducer.personId,
});
export const getPersonIdDetailSelector = (state: RootState) => state.peopleSharedReducer.personId;
export const getInvitationStatus = (state: RootState) => ({
  isInvited: state.peopleSharedReducer.isInvited,
});
export const selectedIsInvited = (state: RootState) => state.peopleSharedReducer?.isInvited;
export const getPaygroupSummarySelector = (state: RootState) => state.peopleSharedReducer.paygroup;
export const selectPaygroupSummaryListIsLoading = (state: RootState) => state.peopleSharedReducer.paygroup?.isLoading;
export const selectPaygroupSummaryList = (state: RootState) => state.peopleSharedReducer.paygroup?.list;
export const terminatedFormDataSelector = (state: RootState) => state.peopleSharedReducer.terminatedFormData;
export const selectTerminatedFormDataHireDate = (state: RootState) =>
  state.peopleSharedReducer.terminatedFormData?.hireDate;
export const selectTerminatedFormDataLastWorkingDate = (state: RootState) =>
  state.peopleSharedReducer.terminatedFormData?.lastWorkingDate;
export const selectTerminatedFormDataPayGroupId = (state: RootState) =>
  state.peopleSharedReducer.terminatedFormData?.payGroupId;
export const isFormFilledSelector = (state: RootState) => state.peopleSharedReducer.hasReset;
export const isSelfFieldsLoading = (state: RootState) => state.peopleSharedReducer.isSelfOnboardLoading;
export const selfOnboardingListSelector = (state: RootState) => state.peopleSharedReducer.selfOnboardedFieldsList;
export const selfOnboardingObjectSelector = (state: RootState) => state.peopleSharedReducer.selfOnboardObject;
export const getPersonNameSelector = (state: RootState) => state.peopleSharedReducer.personName;

export const selectFirstname = (state: RootState) => state.peopleSharedReducer.personName?.firstName;
export const selectLastname = (state: RootState) => state.peopleSharedReducer.personName?.lastName;
export const getBasicInfoFilledSelector = (state: RootState) => state.peopleSharedReducer.isBasicInfoFilled;
export const selectEmployementTypeIdList = (state: RootState) =>
  state.peopleSharedReducer.addPeopleInfo.employementTypeIdList;
export const getRehireDataSelector = (state: RootState) => state.peopleSharedReducer.rehireData;
export const getPayrollInfoSelector = (state: RootState) => state.peopleSharedReducer.payrollInfo;
export const selectJobLiteList = (state: RootState) => state.peopleSharedReducer.payrollInfo?.jobLiteList;
export const selectFirstPayPeriod = (state: RootState) => state.peopleSharedReducer.payrollInfo?.firstPayPeriod;
export const selectLastPendingApprovalPeriod = (state: RootState) =>
  state.peopleSharedReducer.payrollInfo?.lastPendingApprovalPeriod;
export const selectIsPaid = (state: RootState) => state.peopleSharedReducer.payrollInfo?.isPaid;
export const isEditPageLoadingSelector = (state: RootState) => state.peopleSharedReducer.editPageLoading;
export const selectedPersonForEditView = (state: RootState) => state.peopleSharedReducer.selectedPerson;
export const selectedPersonForEditViewId = (state: RootState) => state.peopleSharedReducer.selectedPerson?.id;
export const selectPriorPayrollAmountLoading = (state: RootState) =>
  state.peopleSharedReducer.priorPayrollAmountLoading;
export const selectPriorPayrollDisableButton = (state: RootState) =>
  state.peopleSharedReducer.priorPayrollDisableButton;
export const selectIsPayrollInProgress = (state: RootState) =>
  state.peopleSharedReducer.payrollInfo?.isPayrollInProgress;
export const selectCurrentPayPeriod = (state: RootState) => state.peopleSharedReducer.payrollInfo?.currentPayPeriod;
export const selectLastApprovedPeriod = (state: RootState) => state.peopleSharedReducer.payrollInfo?.lastApprovedPeriod;
