import { EmployeeStateTypeEnum } from 'services/person-access/personAccess.contracts';
import { IValidationResult, IValidationResults } from 'services/shared/validationResults.contracts';

export enum RoePayFrequencyTypeEnum {
  NotDefined = 0,
  Weekly,
  BiWeekly,
  SemiMonthly,
  Monthly,
}

export interface IRoeReason {
  id: number;
  reason: string;
}

export enum RoePayPeriodTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Weekly = 2,
  BiWeekly = 3,
  Monthly = 4,
  MonthlyNotStandard = 5,
  ThirteenPayPeriodPerYear = 6,
  SemiMonthly = 7,
  SemiMonthlyNonStandard = 8,
}

export interface IRecordOfEmploymentReason {
  id: number;
  reason: string;
}

export interface IRecordOfEmploymentReason {
  id: number;
  reason: string;
}

export interface IGetRoeReasonListResponse extends IValidationResponse {
  roeReasonList: Array<IRoeReason>;
}

export interface IEmployeeAddress {
  address1: string;
  address2: string;
  city: string;
  stateId?: number;
  state: string;
  postalCode: string;
}

export interface IRoePayGroupItem {
  id: number;
  name: string;
  payFrequencyType: RoePayFrequencyTypeEnum;
  payPeriodList: IRoePayPeriodItem[];
}

export interface IRoeEmployee {
  name?: string;
  occupation?: string;
  socialInsurenceNumber?: string;
  payGroupList: IRoePayGroupItem[] | [];
  reportingStart?: string;
  reportingEnd?: string;
  address?: IEmployeeAddress;
  eiExempted: boolean;
  employeeStateType: EmployeeStateTypeEnum;
  returnDate?: string;
  lastWorkingDate?: string;
  hireDate?: string;
}

export interface IRoePayPeriodItem {
  id?: number;
  periodNumber: number;
  startDate?: string | Date | null | undefined;
  endDate: string | Date | null | undefined;
  insurableEarnings: number | string | null | undefined;
  insurableHours: number | string | null | undefined;
  isOverride: boolean;
}

export enum IGetRoeEmployeeResponseValidationEnum {
  PersonIdRequired = 0,
  HomeAddressRequired = 1,
  AnotherROEExists = 2,
  EiExempted = 3,
  InvalidSIN = 4,
}

export interface IGetRoeEmployeeResponseValidationResult extends IValidationResult {
  validationCode: IGetRoeEmployeeResponseValidationEnum;
}

export interface IGetRoeEmployeeResponseValidationResults {
  validationResultList: Array<IGetRoeEmployeeResponseValidationResult>;
}

export interface IGetRoeEmployeeResponse {
  employee?: IRoeEmployee;
  previousReportedRoePeriodList: IPreviousReportedRoePeriod[] | [];
  validationResults?: IGetRoeEmployeeResponseValidationResults;
}

export interface IGetInitialStateRoePayPeriodResponse {
  totalInsurableEarnings: number;
  totalInsurableHours: number;
  roePayPeriodList: IRoePayPeriodItem[];
  validationResults?: IValidationResults;
  previousDocumentId?: number;
}

export interface IPersistRoeResponse {
  roeId: number;
  validationResults?: IValidationResults;
}

export interface IInitialStateRoe {
  id: number;
  companyId: number;
  employeeId: number;
  occupation: string;
  reportingStart: string;
  reportingEnd: string;
  roeStatusType: RoeStatusType;
  serialNumber: string;
  previousSerialNumber?: string;
  payGroupId: number;
  personName: string;
  roeReasonId: number;
  comments: string;
  documentId: number;
  roePayPeriodType: RoePayPeriodTypeEnum;
  finalPayPeriodEnd: string;
  expectedDateOfRecall: string;
  roeRecallActionType: RoeRecallActionTypeEnum;
  allowAmendment: boolean;
  employeeAddress: IEmployeeAddress;
  roeSatStatusType: RoeSatStatusType;
  lastCompletedStep: RoeCompletedStepEnum;
  isAmendment: boolean;
  previousDocumentId?: number;
  isPreviouslyRejected?: boolean;
  showRoeImpactedWarning: boolean;
  personSIN: string;
  personFirstName: string;
}

export interface IGetInitialStateRoeResponse {
  roe: IInitialStateRoe;
  validationResults?: IValidationResults;
}

export interface IGetInitialStateRoeRequest {
  roeId: number;
  companyId: number;
}

export interface IPersistRoeRequest {
  roe: {
    id?: number;
    companyId?: number;
    employeeId?: number;
    occupation: string;
    reportingStart: Date | string;
    reportingEnd: Date | string;
    payGroupId?: number;
    personName: string;
    roePayPeriodType?: RoePayPeriodTypeEnum;
    roeReasonId?: number;
    employeeAddress: IEmployeeAddress;
    serialNumber?: string;
    previousSerialNumber?: string;
    lastCompletedStep?: RoeCompletedStepEnum;
  };
  isStepCompleted: boolean;
}

export interface IRoeList {
  id: number;
  companyId: number;
  employeeId: number;
  reason?: string;
  occupation: string;
  reportingStart: string;
  reportingEnd: string;
  roeStatusType: number;
  serialNumber: string;
  previousSerialNumber?: string;
  payGroupId: number;
  personName: string;
  roeReasonId: number;
  comments?: string;
  documentId?: number;
  roePayPeriodType: RoePayPeriodTypeEnum;
  finalPayPeriodEnd?: string;
  employeeAddress?: IEmployeeAddress;
  allowAmendment: boolean;
  isAmendment: boolean;
}

export interface GetRoeListRequest {
  companyId: number;
  skip: number;
  take: number;
  roeSortType?: string | number;
  sortByDecending?: boolean;
  getByName?: boolean;
  nameStartsWith?: string | undefined;
  getByEmployeeId?: boolean;
  employeeId?: number;
  getByRoeStatus?: boolean;
  roeStatusType?: RoeStatusType;
}

export interface PersistRoeInsurableEarningsRequest {
  companyId: number;
  roeId: number;
  payPeriodEndDate: string;
  incomeCodeList: IRoeCodeItem[];
  roeOtherCodeList: IRoeOtherCode[];
  roePaymentList: IRoePaymentData[];
}

export interface IValidationArray {
  validationResultList: Array<IValidationResult>;
}

export interface IValidationResponse {
  validationResults: IValidationArray;
}

export interface GetRoeListResponse extends IValidationResponse {
  roeList: Array<IRoeList>;
}

export interface IInitialStatePaymentList {
  roeId?: number;
  companyId?: number;
}

export interface IRoePaymentData {
  rowKey: string;
  startDate?: string;
  endDate?: string;
  amount: number;
  description?: string;
  perDay: boolean;
  perWeek: boolean;
  roePaymentTypeId: number;
  vacationPayReasonTypeId: number;
  baseFieldId?: number;
  otherMonies?: IOtherMonies;
  roeOtherSpecificPaymentCode?: IPaymentCodeProps;
}

export interface IssuerData {
  issuerFirstName: string;
  issuerLastName: string;
  issuerPhoneNumber: string;
}

export interface IRoeInfo {
  isPaymentCommentsRequired: boolean;
  vacationCalculationType: number;
  expectedDateOfRecall?: string;
  previousDocumentId?: number;
}

export interface IGetInitialStateRoePaymentListResponse extends IssuerData, AdditionalInfoForm, IValidationResponse {
  roePaymentList: Array<IRoePaymentData>;
  validationResults: IValidationArray;
  roeInfo: IRoeInfo;
}

export interface AdditionalInfoForm {
  comments?: string;
}

export interface IPersistRoePaymentRequest extends IssuerData, AdditionalInfoForm, IInitialStatePaymentList {
  roePaymentList: Array<IRoePaymentData>;
  isStepCompleted: boolean;
}
export interface IProcessEmployeeChangeRequest {
  employeeId: number;
  employee?: {
    name?: string;
    hireDate?: string;
    occupation?: string;
    socialInsurenceNumber?: string;
    reportingStart?: string;
    reportingEnd?: string;
    eiExempted?: true;
    employeeStateType?: number;
    returnDate?: string;
    lastWorkingDate?: string;
    payGroupList?: { id: number }[];
    address?: {
      address1: string;
      address2: string;
      city: string;
      stateId: number;
      state?: string;
      postalCode: string;
    };
  };
  isProfileUpdate?: boolean;
  isJobDetailsUpdate?: boolean;
  isStatusUpdate?: boolean;
}

export interface AssignRoeStatusRequest {
  roeId: number;
  roeStatusType: RoeStatusType;
}

export interface IPaymentCodeProps {
  id: number | undefined;
  code: string | undefined;
  description: string | undefined;
  insurable: boolean | undefined;
}

export interface IOtherMonies extends IPaymentCodeProps {
  fullLabel?: string;
  startDateValidationActionType: number | undefined;
  endDateValidationActionType: number | undefined;
  amountValidationActionType: number | undefined;
}
export interface GetOtherMoniesResponse {
  otherMoniesList: IOtherMonies[];
  validationResults: IValidationArray;
}

export interface IRoeOtherCode {
  id: number;
  innerId: number;
  amount: number;
  insurable: boolean;
  includeInBlock17c: boolean;
  name: string;
  paymentRk: string;
  code: string;
  roeOtherCodeType: RecordOfEmploymentOtherCodeType;
}

export interface IRoeCodeItem {
  baseFieldId: number;
  name: string;
  includeInBlock17c: boolean;
  amount: number;
  hours: number | string;
  isCustomCode: boolean;
  insurable: boolean;
  paymentRk: string;
  otherMonies?: IOtherMonies | null | undefined;
  moneyCodeId?: number;
  isBenefit?: boolean;
  earningType: number;
  isNewCreated?: boolean;
}

export interface GetRoeOtherCodesResponse {
  roeOtherCodeList: IRoeOtherCode[];
  validationResults: IValidationArray;
}

export interface GetRoeInsurableEarningsRequest {
  companyId: number;
  roeId: number;
}
export interface GetRoeInsurableEarningsResponse {
  payPeriodEndDate: string;
  incomeEarning: number;
  otherEarning: number;
  otherSpecificEarning: number;
  incomeCodeList: IRoeCodeItem[];
  roeOtherCodeList: IRoeOtherCode[];
  roePaymentList: IRoePaymentData[];
  validationResults: IValidationArray;
}

export enum RoeSeverityType {
  NotDefined = 0,
  Passed,
  Information,
  Warning,
  Error,
}

export interface RoeError {
  code: string;
  associatedBlock: string;
  descriptionEnglish: string;
  descriptionFrench: string;
  roeSeverityType: RoeSeverityType;
  info?: string;
  isFixed?: boolean;
}

export interface IGetRoeErrorsRequest {
  roeId: number;
  companyId: number;
}

export interface IGetRoeErrorsResponse {
  roeErrorList: RoeError[];
  validationResults: IValidationArray;
}

export enum ResolveActionEnum {
  NotDefined = 0,
  Amend,
  Rectify,
}

export interface IGetResolveRoeRequest {
  roeId: number;
  companyId: number;
  resolveAction: ResolveActionEnum;
}

export interface IGetResolveRoeResponse {
  roeId: number;
}

export interface IPreviousReportedRoePeriod {
  reportingStartDate: string;
  reportingEndDate: string;
  finalPayPeriodEndDate: string;
  roeId: number;
  getEmployeeResponse: string;
}

export enum RoeStatusType {
  NotDefined = 0,
  NotSpecified = 1,
  Draft = 2, // not in use
  Review = 3, // not in use
  Finalized = 4, // not in use: only for BE use
  SubmissionInProgress = 5,
  Approved = 6,
  Rejected = 7,
  ManualCreated = 8,
  ManualSubmitted = 9,
  Delete = 10,
}

export enum RoeSortTypeEnum {
  NotDefined = 0,
  PersonName = 1,
  RoeStatusType = 2,
  Custom = 3,
}

export enum RoePaymentTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Vacation = 2,
  StatutoryHoliday = 3,
  Other = 4,
  OtherSpecific = 5,
}

export enum RoeVacationPayReasonTypeEnum {
  NotDefined = 0,
  IncludedWithEachPay = 1,
  NoLongerWorking = 2,
  VacationLeavePeriod = 3,
  Anniversary = 4,
}

export enum RecordOfEmploymentOtherCodeType {
  NotDefined = 0,
  ServiceCanada = 1,
  OtherMonies = 2,
}

export interface IPersistRoePayPeriodRequest {
  roeId: number;
  companyId: number;
  roePayPeriodList: IRoePayPeriodItem[];
  isStepCompleted: boolean;
}

export enum RoeSatStatusType {
  NotDefined = 0,
  NotSpecified,
  Grant,
  Revoke,
}

export interface IAssignRoeStatus {
  validationResults: IValidationArray;
}

export interface ISubmitRoe {
  validationResults: IValidationArray;
}

export enum ReasonForIssuingRoeEnum {
  K00Other = 19,
  K15OtherCanadianForces = 23,
}

export enum BlockOtherMoniesEnum {
  B11BonusOther = 7,
  O00Other = 13,
}

export enum OtherMoniesValidationActionTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Optional = 2,
  Mandatory = 3,
  MustBeBlank = 4,
}

export enum RoeRecallActionTypeEnum {
  NotDefined = 0,
  NotSpecified = 1,
  Returning = 2,
  NotReturning = 3,
  Unknown = 4,
}

export enum RoeCompletedStepEnum {
  NotDefined = 0,
  EmployeeInformation = 1,
  InsurableHoursAndEarnings = 2,
  AdditionalPayments = 3,
}

export enum InsurableBannerEnum {
  StatutoryAlert = 'Statutory Holiday',
}

export enum TypeCodeEnum {
  Income = 'Income',
  Benefit = 'Benefit',
}
export enum EarningType {
  NotDefined = 0,
  Income = 1,
  Benefit = 2,
}

export enum PaymentsTowardEnum {
  WageLossNotEI = 2,
  WageLossEI = 3,
}

export enum ReasonCodeEnum {
  E0 = 'E0',
  G0 = 'G0',
  M0 = 'M0',
}

export enum TotalHoursEnum {
  MaxTotalHours = 8905,
}
