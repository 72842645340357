import React from 'react';
import { GlobalStyles } from 'styles/globalStyles';

import { useTranslation } from 'react-i18next';

import { InviteEnum, ValidEnum } from '../companyIdOrEmailLayout';
import { selectIsValidCompany } from 'redux/slice/accountingFirmSlice';
import { useAppSelector } from 'redux/hooks/hook';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import WpLink from '@wagepoint/ui-toolkit/components/wp-link/wpLink';

interface IFormContainerProps {
  inviteFrom?: number;
  handleTypographyClick?: (type?: number) => void;
  viewOnly?: boolean;
  submitted?: boolean;
}

const TypographyContainer: React.FC<IFormContainerProps> = (props) => {
  const globalClass = GlobalStyles();
  const { t } = useTranslation(['userAccounts']);
  const isValidCompany = useAppSelector(selectIsValidCompany);

  const handleClick = (type?: number) => {
    if (props?.handleTypographyClick) props?.handleTypographyClick(type);
  };

  return (
    <div className={globalClass.mtHalf}>
      {props.inviteFrom == InviteEnum.CompanyId &&
        (isValidCompany === ValidEnum.NotDefined || (props?.submitted && isValidCompany === ValidEnum.InValid)) && (
          <>
            <WpTypography display="inline">{t('common.companyIdText')}&nbsp;</WpTypography>
            <WpLink disabled={props?.viewOnly} onClick={() => handleClick(InviteEnum.Email)}>
              {t('common.companyIdAlternative')}
            </WpLink>
          </>
        )}

      {props.inviteFrom == InviteEnum.Email && isValidCompany === ValidEnum.NotDefined && (
        <>
          <WpTypography display="inline">{t('common.emailIdText')}&nbsp;</WpTypography>
          <WpLink onClick={() => handleClick(InviteEnum.CompanyId)}>{t('common.emailIdAlternative')}</WpLink>
        </>
      )}
    </div>
  );
};

export default TypographyContainer;
