/*
Todo: refactoring will be done. At present only permission structure is refactord
*/
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import WpGrid from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid';
import { IWpGridColumn } from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid.interface';
import { WpGridTextAlignmentEnum } from '@wagepoint/ui-toolkit/components/wp-grid/wpGrid.enum';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpToolTip, { WpToolTipEnum } from '@wagepoint/ui-toolkit/components/wp-tooltip/wpToolTip';
import WpLink from '@wagepoint/ui-toolkit/components/wp-link/wpLink';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { error, success } from 'redux/slice/snackbarSlice';
import { selectAccountingAssignedRole, selectRolesListRoles, setEditState } from 'redux/slice/userAccountsSlice';

import {
  IGetSecurityRoleRequest,
  IPermissionSubCategoryList,
  PersonTypeEnum,
} from 'services/security-access/securityAccess.contracts';
import {
  AddPersonToManager,
  GetSecurityRole,
  GetUser,
  MaintainPersonSecurityRole,
  PersistSecurityRole,
  PersistUserPermission,
  ResetToDefaultPermission,
} from 'services/security-access/securityAccess.service';
import { IPersistPersonDepartmentRequest } from 'services/person-access/personAccess.contracts';
import { PersistPersonDepartment } from 'services/person-access/personAccess.service';
import { UserAccountsTypeEnum } from '../roles/rolesContainer';
import { PermissionSettingsEnum, PermissionSettingsEnumForPeople } from '../assignedPeopleGroup';
import { useTranslation } from 'react-i18next';
import { hideLoading, showLoading } from 'redux/slice/loadingIndicatorSlice';
import { useHistory } from 'react-router-dom';
import {
  EmployeePeopleEnum,
  PayrollEnum,
  PermissionsEnum,
  TaxSettingsEnum,
} from 'services/shared/permission.contracts';
import { FormProvider, useForm } from 'react-hook-form';
import { reloadConfig, selectCompanyId } from 'redux/slice/configSlice';
import { selectFirmViewFrom } from 'redux/slice/accountingFirmSlice';
import { ManageViewOfAccountingFirm } from '../../accounting-bookkeeping-firm/index';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { PermissionSettingsStyles } from './permissionStyles';
import { useTwoStepEnabled } from '../permissionSettingsHook';
import { useViewPermissionByPartPath } from 'util/customHooks/useViewPermission';
import { useReloadConfig } from 'util/customHooks/useReloadConfig';

import CustomPrompt from 'shared-components/custom-prompt/customPrompt';

import { PermissionSettingsDialogue } from './dialogue/permissionSettingsDialogue';
import { CustomizationsDialogues } from './dialogue/customizationsDialogue';

//for permissions structure
import { updatedOriginalPermissionsWithNoAccess } from '../common/flattenPermissions/updatedOriginalPermissionsWithNoAccess';
import { restructurePermissionCategoryList } from '../common/flattenPermissions/updatePermissionsListToDisplay';
import { flattenObject } from '../common/flattenPermissions/flattenEditPermissions/flattenedEditPermissions';
import { updateServicePermissionStructure } from '../common/flattenPermissions/flattenToServiceStructure/flattenServiceStructure';
import { IPermissionSubCategory } from '../common/interface/userAccountsInterface';
import WpBottomNav from '@wagepoint/ui-toolkit/components/wp-bottomNav/wpBottomNav';
import WpIcon from '@wagepoint/ui-toolkit/components/wp-icon';
import checkCircleSolid from '@wagepoint/ui-toolkit/components/wp-icon/icons/check-circle-solid';
import { getApplicationType } from 'util/utility';
import { ApplicationTypeEnum } from 'shared/services/appCore/schema';
import { IPermissionUi } from 'pages/settings/components/useraccounts/common/flattenPermissions/interface/flattenPermissionsInterface';
import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { SmallBusinessMenuEnum } from 'services/ui-config-access/uiConfigAccess.contracts';
import { ExtraReportEnum } from 'pages/payroll-reports/components/payrollReportListItemNew';
import { useEnableAnnualPayrollLimitReport } from 'pages/payroll-reports/components/hooks/safetyHooks';
interface Props {
  title?: string;
  value?: boolean;
  id?: any;
  from?: any;
  details?: any;
  cardObject?: any;
  switchHandler?: (value: boolean) => void;
  handleClose?: (from?: boolean) => void;
  handleTrigger?: any;
  handleEdit?: boolean;
  handleUnsavedNavigation?: () => void;
}

export interface IDepartmentList {
  id: number;
  name: string;
  hasManager?: boolean;
}

const PermissionSettings = React.forwardRef(function PermissionSettings(props: Props, ref: any) {
  const classes = PermissionSettingsStyles();
  const { feature } = useFeatureFlag();
  const isEmployeePortalPermissionsChanges = feature(FEATURE_FLAG_KEYS.EmployeePortalPermissionsChanges);

  const { t } = useTranslation(['common', 'userAccounts']);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isViewOnlyPermission } = useViewPermissionByPartPath();
  const applicationType = getApplicationType();

  const roles = useAppSelector(selectRolesListRoles);
  const companyId = useAppSelector(selectCompanyId);
  const assignedRole = useAppSelector(selectAccountingAssignedRole);
  const from = useAppSelector(selectFirmViewFrom);
  const { isTwoStepApprovalEnabled, twoStepApproverName } = useTwoStepEnabled();

  const { reloadPermissions } = useReloadConfig();
  const [userDetails, setUserDetails] = useState<any>({});
  const [isSave, setSave] = React.useState<boolean>(false);

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isReset, setIsReset] = React.useState<boolean>(false);
  const [isCardEdit, setCardEdit] = React.useState<boolean>(false);
  const [canceldialogue, setCanceldialogue] = React.useState(false);
  const [resetdialogue, setResetdialogue] = React.useState(false);
  const [roleIdForAccountingPermissions, setRoleIdForAccountingPermissions] = useState(0);
  const [hasCustomizations, setCustomizations] = useState(false);
  const [hasCustomizationsDialogue, setCustomizationsDialogue] = useState(false);
  const [hasCustomizationsForPeople, setCustomizationsForPeople] = useState(false);
  //state variable for updating ui structure for permissions
  const [rolePermissionData, setRolePermissionData] = useState<any>([]);
  //state variable for storing flattened structure of permissions for editing
  const [flattenedPermissions, setFlattednedPermissions] = useState<any>();
  //state variable for updating to service structure for permissions
  const [rolePermissionsServiceData, setRolePermissionsServiceData] = useState<any>([]);
  const { enableAnnualPayrollReport } = useEnableAnnualPayrollLimitReport();

  const isShowEditButton =
    !isEdit &&
    !isReset &&
    props?.value !== false &&
    !isCardEdit &&
    roles?.securityRoleType !== SecurityRoleTypeEnum.AccountOwner;

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (props?.from == UserAccountsTypeEnum.People || from === ManageViewOfAccountingFirm.FirmInviteSent) {
      setIsEdit(false);
      return;
    }
    if (props.value !== undefined) {
      setIsEdit(props.value);
      if (isReset) {
        setIsReset(false);
        setCardEdit(false);
        getUser();
      }
    }
  }, [props.value]);

  //service call
  useEffect(() => {
    if (roles && Object.keys(roles).length === 0) return;
    if (props?.id) {
      getUser();
    } else {
      getRolePermissions();
    }
  }, [roles]);

  useEffect(() => {
    if (props.from == UserAccountsTypeEnum.People) {
      getUser();
    } else if (props.from == UserAccountsTypeEnum.AccountingFirm) {
      getRolePermissions();
    }
  }, [props.from]);

  useEffect(() => {
    if (props.cardObject !== undefined && userDetails !== undefined) {
      if (props.cardObject?.securityRoleTypeList?.length) {
        //if roles are changed then it should enter the below conditions.
        if (
          JSON.stringify(props.cardObject?.securityRoleTypeList) !== JSON.stringify(userDetails?.securityRoleTypeList)
        ) {
          setCardEdit(true);
        } else {
          setCardEdit(false);
        }

        //if there is change in location, then only it should enter the case
        if (
          props.cardObject?.stateIdList &&
          userDetails?.stateList &&
          props.cardObject?.stateIdList.sort().join(',') !==
            userDetails?.stateList
              .map((item: any) => (item.stateId ? item.stateId : item.id))
              .sort()
              .join(',')
        ) {
          if (
            props.cardObject?.stateIdList.length > 0 &&
            userDetails?.stateList &&
            userDetails?.stateList.map((item: any) => item.id).length > 0
          ) {
            const value = locationArray(
              props.cardObject?.stateIdList,
              userDetails?.stateList.map((item: any) => item.id)
            );

            !value ? setCardEdit(true) : setCardEdit(false);
          } else if (userDetails?.stateList && userDetails?.stateList.length == 0) {
            if (props.cardObject?.stateIdList && props.cardObject?.stateIdList.length > 0) setCardEdit(true);
          } else if (props.cardObject?.stateIdList && props.cardObject?.stateIdList == 0) {
            if (userDetails?.stateList && userDetails?.stateList.length > 0 && props.cardObject?.stateIdList == 0)
              setCardEdit(true);
          }
        }

        //As of now - a person can be assigned to a single role only. So in the array, there will be only one value
        if (Array.isArray(props.cardObject.departmentIdList) && Array.isArray(userDetails.departmentList)) {
          const sortedCardDeptIds = props.cardObject.departmentIdList.sort().join(',');
          const sortedUserDeptIds = userDetails.departmentList
            .map((item: any) => item.id)
            .sort()
            .join(', ');

          if (sortedCardDeptIds !== sortedUserDeptIds) {
            if (userDetails.departmentList.length > 0) {
              const value = locationArray(
                props.cardObject.departmentIdList,
                userDetails.departmentList.map((item: any) => item.id)
              );

              !value ? setCardEdit(true) : setCardEdit(false);
            } else if (userDetails.departmentList.length === 0) {
              if (props.cardObject.departmentIdList.length > 0) {
                setCardEdit(true);
              } else {
                setCardEdit(false);
              }
            }
          }
        }
      }
    }
  }, [props.cardObject, userDetails]);

  useEffect(() => {
    if (props?.handleEdit && !props?.handleEdit) setIsEdit(false);
  }, [props?.handleEdit]);

  useImperativeHandle(ref, () => ({
    getCancelClick() {
      handleFooterCancelClick();
    },

    getCustomizationsForPeople(permissions: boolean) {
      setCustomizationsForPeople(permissions);
    },

    setPermissions(permissionsData: any) {
      permissionsMappingToDisplay(permissionsData);
    },

    getTransferUser() {
      getUser();
    },
  }));

  const locationArray = (a: any, b: any) => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
  };

  const getUser = async () => {
    dispatch(showLoading());

    //In order to view permission of employee - the type should be employeePortal
    const appType = props?.from === UserAccountsTypeEnum.People ? ApplicationTypeEnum.employeePortal : applicationType;

    const request: any = {
      userId: props?.details?.id,
      includeSecurityRoleList: true,
      includePermission: true,
      ...(props?.from === UserAccountsTypeEnum.People && {
        securityRoleType:
          props?.details?.personType === PersonTypeEnum.Employee
            ? SecurityRoleTypeEnum.Employee
            : SecurityRoleTypeEnum.Contractor,
      }),
      applicationType: appType,
    };

    const response: any = await GetUser(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      setUserDetails(response?.user);
      permissionsMappingToDisplay(response);

      dispatch(hideLoading());
    } else {
      dispatch(
        error({
          message:
            response.validationResults?.validationResultList[0].validationMessage ||
            t('userAccounts:permissionSettings.error'),
        })
      );
      dispatch(hideLoading());
    }
  };

  const permissionsMappingToDisplay = (response: any) => {
    setRolePermissionData([]);

    response?.securityRoleList.map((data: any) => {
      //to identify if user customizations are there for this particular role
      setCustomizationsForPeople(data.hasCustomization);
      const shouldHaveNoAccess = props?.from === UserAccountsTypeEnum.People ? false : true;

      //storing the service structure
      setRolePermissionsServiceData(
        JSON.parse(
          JSON.stringify(updatedOriginalPermissionsWithNoAccess(data?.permissionCategoryList, shouldHaveNoAccess))
        )
      );

      // Converting the service response to Ui expected format to display ui
      setRolePermissionData(
        restructurePermissionCategoryList(
          JSON.parse(
            JSON.stringify(updatedOriginalPermissionsWithNoAccess(data?.permissionCategoryList, shouldHaveNoAccess))
          )
        )
      );

      //storing the flattened structure
      setFlattednedPermissions(
        flattenObject(
          JSON.parse(
            JSON.stringify(updatedOriginalPermissionsWithNoAccess(data?.permissionCategoryList, shouldHaveNoAccess))
          )
        )
      );
    });

    setUserDetails(response?.user);
  };

  const resetToDefaultPermissionService = async () => {
    dispatch(showLoading());
    let request;
    if (from == UserAccountsTypeEnum.AccountingFirm) {
      request = {
        securityRoleId: roleIdForAccountingPermissions,
        securityRoleType: assignedRole,
      };
    } else {
      request = {
        securityRoleId: roles?.id,
        securityRoleType: roles?.securityRoleType,
      };
    }

    const response: any = await ResetToDefaultPermission(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      getRolePermissions();
      setIsEdit(true);
      setIsReset(false);
      dispatch(
        success({
          message: t('userAccounts:permissionSettings.resetToDefaultSnack'),
        })
      );
      dispatch(hideLoading());
    } else {
      dispatch(
        error({
          message:
            response.validationResults?.validationResultList[0].validationMessage ||
            t('userAccounts:permissionSettings.error'),
        })
      );
      dispatch(hideLoading());
    }
  };

  const resetToDefaultPermissionServiceForPeople = async () => {
    getRolePermissions(true, true);
  };

  const resetToDefaultPermission = () => {
    setResetdialogue(false);
    if ('value' in props) {
      if (from == UserAccountsTypeEnum.AccountingFirm) resetToDefaultPermissionService();
      else resetToDefaultPermissionServiceForPeople();
    } else {
      getRolePermissions(true);
    }
  };

  const cancelDialogueService = () => {
    dispatch(showLoading());
    setIsEdit(false);
    setCanceldialogue(false);
    setIsReset(false);
    setCardEdit(false);
    if (props.switchHandler) props.switchHandler(false);
    if ('value' in props) {
      if (props.handleUnsavedNavigation) props.handleUnsavedNavigation();
      else getUser();
    } else {
      getRolePermissions();
      history.push(isEmployeePortalPermissionsChanges ? '/rolesAndPermissions' : '.');
    }
    dispatch(hideLoading());
  };

  const persitRoleService = async (hasCustom?: boolean) => {
    dispatch(showLoading());

    const updatedPermissionStructure = updateServicePermissionStructure(
      rolePermissionsServiceData,
      flattenedPermissions
    );

    // on sucess we are not performing api call, instead updating the state
    setRolePermissionData(
      restructurePermissionCategoryList(updatedOriginalPermissionsWithNoAccess(updatedPermissionStructure))
    );

    let response: any;
    if ('value' in props) {
      if (from === UserAccountsTypeEnum.AccountingFirm) {
        const request: any = {
          securityRoleId: roleIdForAccountingPermissions,
          securityRole: {
            id: roleIdForAccountingPermissions,
            permissionCategoryList: updatedPermissionStructure,
          },
        };
        response = await PersistSecurityRole(request);
        await dispatch(reloadConfig(true));
      } else {
        const request: any = {
          userId: props?.details?.id,
          securityRoleType:
            props?.cardObject?.securityRoleTypeList.length > 0
              ? parseInt(props.cardObject?.securityRoleTypeList.join())
              : parseInt(userDetails?.securityRoleTypeList.join()),
          permissionCategoryList: updatedPermissionStructure,
        };
        response = await PersistUserPermission(request);

        await reloadPermissions();
      }
    } else {
      const request: any = {
        securityRoleId: roles.id,
        ...(hasCustomizationsDialogue && { keepCustomization: hasCustom ? true : false }),
        securityRole: {
          id: roles.id,
          permissionCategoryList: updatedPermissionStructure,
        },
      };
      response = await PersistSecurityRole(request);
      dispatch(reloadConfig(true));
    }

    dispatch(hideLoading());

    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      dispatch(hideLoading());
      setIsEdit(false);
      setCardEdit(false);
      setIsReset(false);
      setSave(true);

      if (props.switchHandler) {
        props.switchHandler(false);
        setIsReset(false);
        history.goBack();

        dispatch(
          success({
            message: t('userAccounts:permissionSettings.updatePermissionsForPeople', {
              person: `${props.details?.firstName} ${props.details?.lastName}`,
            }),
          })
        );
      } else {
        setCustomizationsForPeople(true);
        dispatch(
          setEditState({
            isEdit: false,
          })
        );
        dispatch(
          success({
            message: t('userAccounts:permissionSettings.updatedPermissions'),
          })
        );
      }
    } else {
      dispatch(
        error({
          message:
            response.validationResults?.validationResultList[0].validationMessage ||
            t('userAccounts:permissionSettings.error'),
        })
      );
      dispatch(hideLoading());
      if (props.switchHandler) {
        props.switchHandler(false);
        setIsReset(false);
      }
      setIsEdit(false);
      setCardEdit(false);
    }
  };

  const handleSaveClick = () => {
    if (hasCustomizations && !props?.id) {
      setCustomizationsDialogue(true);
    } else {
      dispatch(showLoading());
      persistSecurityRole();
    }
  };

  const persistSecurityRole = (hasCustom?: boolean) => {
    setCustomizationsDialogue(false);
    if (isCardEdit) {
      //if manager and no department - trigger error and return
      if (
        props?.cardObject?.securityRoleTypeList.join() == SecurityRoleTypeEnum.Manager &&
        props?.handleTrigger() === 0
      ) {
        props?.handleTrigger();
        dispatch(hideLoading());
        return;
      }
      updatePermissions();
    } else {
      persitRoleService(hasCustom);
    }
  };

  const updatePermissions = async () => {
    dispatch(showLoading());
    await updatePersonSecurityRole(parseInt(props.cardObject?.securityRoleTypeList.join()), true, false);
    await addPersonToManager();
    if (isReset) await persitRoleService();
    dispatch(hideLoading());
    if (props?.handleUnsavedNavigation) props.handleUnsavedNavigation();
  };

  const addPersonToManager = async () => {
    let response;
    if (props?.cardObject?.securityRoleTypeList.join() == SecurityRoleTypeEnum.Manager) {
      const request: any = {
        userId: props?.details?.id,
        departmentIdList: props?.cardObject?.departmentIdList,
        stateIdList: props?.cardObject?.stateIdList,
      };
      response = await AddPersonToManager(request);
    } else {
      const request: IPersistPersonDepartmentRequest = {
        departmentidlist: props?.cardObject?.departmentIdList,
        personid: props?.details?.personId,
      };
      response = await PersistPersonDepartment(request);
    }

    if (response && response.validationResults?.validationResultList?.length == 0) {
      setSave(true);
      if (!isReset) {
        setCardEdit(false);
        if (props.switchHandler) {
          props.switchHandler(false);
        }
        dispatch(
          success({
            message: t('userAccounts:permissionSettings.updatePermissionsForPeople', {
              person: `${props.details?.firstName} ${props.details?.lastName}`,
            }),
          })
        );
      }
    } else {
      setCardEdit(false);
      dispatch(
        error({
          message:
            response.validationResults?.validationResultList[0].validationMessage ||
            t('userAccounts:permissionSettings.error'),
        })
      );
    }
  };

  const updatePersonSecurityRole = async (roleType: any, assign?: boolean, unassign?: boolean) => {
    const request: any = {
      userId: props?.details?.id,
      securityRoleType: roleType,
      assign: assign || false,
      unassign: unassign || false,
      stateIdList: props?.cardObject?.stateIdList,
    };
    const response: any = await MaintainPersonSecurityRole(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      setSave(true);
      dispatch(hideLoading());
      if (!isReset) {
        setCardEdit(false);
        if (props.switchHandler) {
          props.switchHandler(false);
          history.goBack();
          //on save click on edit page - we need to update the userdetails with cardobject data.
          setUserDetails((prev: any) => ({
            ...prev,
            securityRoleTypeList: props?.cardObject?.securityRoleTypeList,
            stateList: props?.cardObject?.locationList,
          }));
        }
        dispatch(
          success({
            message: t('userAccounts:permissionSettings.updatePermissionsForPeople', {
              person: `${props.details?.firstName} ${props.details?.lastName}`,
            }),
          })
        );
      }
    } else {
      dispatch(hideLoading());
      setCardEdit(false);
      dispatch(
        error({
          message:
            response.validationResults?.validationResultList[0].validationMessage ||
            t('userAccounts:permissionSettings.error'),
        })
      );
    }
  };

  //permission service call
  const getRolePermissions = async (fromReset = false, people = false) => {
    dispatch(showLoading());
    const request: IGetSecurityRoleRequest = {
      companyId,
      ...(!fromReset && { securityRoleId: roles?.id || 0 }),
      ...(props?.from == UserAccountsTypeEnum.AccountingFirm && { securityRoleType: assignedRole }),
      includeUserList: true,
      includePermissionList: true,
      ...(fromReset && !people && { securityRoleType: roles?.securityRoleType }),
      ...(fromReset && people && { securityRoleType: roles?.securityRoleType }),
      ...(people && {
        securityRoleType:
          props?.cardObject?.securityRoleTypeList.length > 0
            ? parseInt(props.cardObject?.securityRoleTypeList.join())
            : parseInt(userDetails?.securityRoleTypeList.join()),
      }),
      ...(props.from == UserAccountsTypeEnum.AccountingFirm && { isPartnerRole: true }),
    };
    const response: any = await GetSecurityRole(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      setRolePermissionData([]);
      if (fromReset) setIsReset(true);

      setRoleIdForAccountingPermissions(response.securityRole?.id);
      //to identify if user customizations are there for this particular role
      if (response?.securityRole?.securityRoleType !== SecurityRoleTypeEnum.AccountOwner)
        setCustomizations(response?.securityRole?.hasCustomization);
      const shouldHaveNoAccess = props?.from !== UserAccountsTypeEnum.People;
      //storing the service structure
      setRolePermissionsServiceData(
        JSON.parse(
          JSON.stringify(
            updatedOriginalPermissionsWithNoAccess(response?.securityRole?.permissionCategoryList, shouldHaveNoAccess)
          )
        )
      );

      // Converting the service response to Ui expected format to display ui
      setRolePermissionData(
        restructurePermissionCategoryList(
          JSON.parse(
            JSON.stringify(
              updatedOriginalPermissionsWithNoAccess(response?.securityRole?.permissionCategoryList, shouldHaveNoAccess)
            )
          )
        )
      );

      //storing the flattened structure
      setFlattednedPermissions(
        flattenObject(
          JSON.parse(
            JSON.stringify(
              updatedOriginalPermissionsWithNoAccess(response?.securityRole?.permissionCategoryList, shouldHaveNoAccess)
            )
          )
        )
      );

      dispatch(hideLoading());
      if (fromReset)
        dispatch(
          success({
            message: t('userAccounts:permissionSettings.resetToDefaultSnack'),
          })
        );
    } else {
      props.from !== UserAccountsTypeEnum.AccountingFirm &&
        dispatch(
          error({
            message:
              response.validationResults?.validationResultList[0].validationMessage ||
              t('userAccounts:permissionSettings.error'),
          })
        );
    }
    dispatch(hideLoading());
  };

  const childContext = (row: any) => {
    return (
      <FormProvider {...methods}>
        <form>
          <Box component="div" className={classes.nestedGrid}>
            <WpGrid
              rows={row?.children}
              columns={showPermissionTable(row?.id)}
              showPagination={false}
              childContent={childContext}
              serverSidePaging
            />
          </Box>
        </form>
      </FormProvider>
    );
  };

  const handleRadioChange = (event: any, selectedRow: any) => {
    //MJ - fix added
    methods.formState.isDirty = true;
    setIsReset(true);

    const updatedPermissions = { ...flattenedPermissions };
    updatedPermissions[selectedRow] = { ...flattenedPermissions[selectedRow], permissionType: event.target.value };

    //update child permissions
    if (updatedPermissions[selectedRow].child?.length) {
      updatedPermissions[selectedRow].child.forEach((key: any) => {
        updatedPermissions[key] = {
          ...updatedPermissions[key],
          permissionType: event.target.value,
          isDisabled: {
            1: event.target.value === '3' || event.target.value === '2',
            2: event.target.value === '3',
            3: false,
          },
        };
      });
    }

    setFlattednedPermissions(updatedPermissions);
  };

  //function to display the default values in radio group
  const getDefaultValue = (row: any) => {
    if (flattenedPermissions && flattenedPermissions.hasOwnProperty(row.id))
      return flattenedPermissions[row.id].permissionType.toString();
  };

  // to display disabled values in form control label
  const getDisabledValues = (row: any, { permissionType = '' }: Partial<IPermissionUi> = {}) => {
    if (typeof flattenedPermissions === 'object' && flattenedPermissions.hasOwnProperty(row.id))
      return flattenedPermissions[row.id]?.isDisabled?.[permissionType];
  };

  const tooltipMessageHandler = () => {
    if (userDetails.personType == PersonTypeEnum.Contractor)
      return t('userAccounts:permissionSettings.contractorNoAccess');
    return t('userAccounts:permissionSettings.employeeNoAccess');
  };

  // In case of people, 3rd column of permissions will not be there.
  const gridItemSize = () => {
    if (props.from === UserAccountsTypeEnum.People) return 6;
    return 4;
  };

  const permissionOptionsHandler = (row: IPermissionSubCategory) => {
    if (row.id === PermissionsEnum.Payroll) return row.permissionList;
    return [row.permissionList[1], row.permissionList[0], ...row.permissionList.slice(2)];
  };

  //TODO: refactor this component in future, remove any type
  const renderRow = (row: any, option: IPermissionUi) => {
    if (
      (userDetails.personType == PersonTypeEnum.Contractor &&
        (option.id === TaxSettingsEnum.TaxSetupRead ||
          option.id === TaxSettingsEnum.TaxSetupWrite ||
          option.id === Number(`${TaxSettingsEnum.TaxSetupRead}${TaxSettingsEnum.TaxSetupWrite}`))) ||
      ((userDetails.personType === PersonTypeEnum.Employee || userDetails.personType === PersonTypeEnum.Contractor) &&
        (option.id === EmployeePeopleEnum.OverviewWrite || option.id === EmployeePeopleEnum.JobDetailsWrite))
    ) {
      return (
        <WpToolTip
          aria-multiline
          title={tooltipMessageHandler()}
          tooltype={WpToolTipEnum.custom}
          arrow
          placement="bottom-start"
        >
          <FormControlLabel
            value={`${option.permissionType}`}
            key={option.name}
            name={option.name}
            disabled
            control={<Radio color="primary" />}
            label=""
          />
        </WpToolTip>
      );
    }

    if (isTwoStepApprovalEnabled && [PayrollEnum.PayrollApprove].includes(option.id as PayrollEnum)) {
      return (
        <WpToolTip
          title={t('userAccounts:permissionSettings.adminRunAndSubmit', {
            approver: twoStepApproverName,
          })}
          tooltype={WpToolTipEnum.custom}
          arrow
          placement="bottom-start"
        >
          <FormControlLabel
            value={`${option.permissionType}`}
            key={option.name}
            name={option.name}
            disabled
            control={<Radio color="primary" />}
            label=""
          />
        </WpToolTip>
      );
    }

    if (!isTwoStepApprovalEnabled && [PayrollEnum.PayrollSubmit].includes(option.id as PayrollEnum)) {
      return (
        <WpToolTip
          title={<>{t('userAccounts:permissionSettings.runAndSubmitHover')}</>}
          tooltype={WpToolTipEnum.custom}
          arrow
          placement="bottom-start"
        >
          <FormControlLabel
            value={`${option.permissionType}`}
            key={option.name}
            name={option.name}
            disabled
            control={<Radio color="primary" />}
            label=""
          />
        </WpToolTip>
      );
    }

    if (
      (parseInt(props?.details?.securityRoleTypeList.join()) === SecurityRoleTypeEnum.AccountOwner ||
        roles?.securityRoleType === SecurityRoleTypeEnum.AccountOwner) &&
      (option.id === PermissionsEnum.UserAccountsRead ||
        option.id === Number(`${PermissionsEnum.UserAccountsRead}${PermissionsEnum.UserAccountsWrite}`) ||
        option.id === PermissionsEnum.RolesRead ||
        option.id === Number(`${PermissionsEnum.RolesRead}${PermissionsEnum.RolesWrite}`))
    ) {
      return (
        <FormControlLabel
          value={`${option.permissionType}`}
          key={option.name}
          name={option.name}
          disabled
          control={<Radio color="primary" />}
          label=""
        />
      );
    }

    return (
      <FormControlLabel
        value={`${option.permissionType}`}
        key={option.name}
        name={option.name}
        disabled={getDisabledValues(row, option)}
        control={<Radio color="primary" />}
        label=""
      />
    );
  };

  const showPermissionTable = (roleDetails?: any): IWpGridColumn[] => {
    return [
      {
        field: 'name',
        title: `${roleDetails.name}`,
        // width: '55%',
        templateSupply: (row) => {
          if (row) {
            return (
              <List>
                <ListItem>
                  <Box component="div">
                    <WpTypography variant={row.parentSubCategoryId ? 'body1' : 'body2'}>{row.name}</WpTypography>
                    <WpTypography className={row.parentSubCategoryId ? classes.p6 : classes.b2}>
                      {row.description}
                    </WpTypography>
                  </Box>
                </ListItem>
              </List>
            );
          }
        },
      },
      {
        field: '',
        title: '',
        align: WpGridTextAlignmentEnum.CENTER,
        headerAlign: WpGridTextAlignmentEnum.CENTER,
        headerTemplateSupply: (col: any) => {
          if (col) {
            return (
              <Grid container>
                {roleDetails &&
                  Object.keys(roleDetails).length > 0 &&
                  roleDetails?.columnHeaderList.map((headers: any) => (
                    <Grid item xs={gridItemSize()} key={headers}>
                      {headers}
                    </Grid>
                  ))}
              </Grid>
            );
          }
        },
        templateSupply: (row) => {
          if (isEdit) {
            if (row) {
              return (
                <FormControl>
                  <RadioGroup onChange={(e) => handleRadioChange(e, row.id)} value={getDefaultValue(row)}>
                    <Grid container>
                      {row?.permissionList &&
                        permissionOptionsHandler(row).map((option) => {
                          return (
                            <Grid item xs={gridItemSize()} key={option.id}>
                              {renderRow(row, option)}
                            </Grid>
                          );
                        })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              );
            }
          } else {
            return (
              <Grid container>
                {row?.permissionList &&
                  permissionOptionsHandler(row).map((option: any, index: any) => {
                    return (
                      <>
                        <Grid item xs={gridItemSize()}>
                          <div key={index} style={{ gridColumn: index + 1 }}>
                            <>
                              {option.permissionType === Number(getDefaultValue(row)) && (
                                <WpIcon svgIcon={checkCircleSolid} color="primary" />
                              )}
                            </>
                          </div>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            );
          }
        },
      },
    ];
  };

  const switchToEdit = () => {
    setIsEdit(true);

    dispatch(
      setEditState({
        isEdit: true,
      })
    );
  };

  const handleFooterCancelClick = () => {
    if ((isReset && isEdit) || isCardEdit) {
      setCanceldialogue(true);
    } else {
      if (props?.from === UserAccountsTypeEnum.People && props?.handleClose) {
        isEdit ? props.handleClose(true) : history.goBack();
      } else {
        props?.handleClose ? props.handleClose() : history.push('.');
      }
      setIsEdit(false);
    }
  };

  const getPermissionSettingsRows = (rows: Array<IPermissionSubCategoryList>, from?: number) => {
    if (from === UserAccountsTypeEnum.People && userDetails.personType === PersonTypeEnum.Contractor)
      return rows.filter((item: IPermissionSubCategoryList) => item.id !== PermissionsEnum.ScEmployeeTaxSetup);
    else return rows;
  };

  const filterOutAnnualList = (list: IPermissionSubCategoryList[]) => {
    if (!enableAnnualPayrollReport)
      return list.map((item: any) => {
        if (item.id === SmallBusinessMenuEnum.Reports)
          return { ...item, children: item.children.filter((elem: any) => elem.id !== ExtraReportEnum.AnnualReport) };
        return { ...item };
      });
    return list;
  };

  const displayGrid = (from: number) => {
    return rolePermissionData.map((roleDetails: any) => {
      const filteredListForNow = filterOutAnnualList(
        getPermissionSettingsRows(roleDetails?.permissionSubCategoryList, from)
      );
      return (
        <Box component="div" key={roleDetails?.id} className={classes.gridContainer}>
          {Object.values(
            from === UserAccountsTypeEnum.People ? PermissionSettingsEnumForPeople : PermissionSettingsEnum
          ).includes(roleDetails.id) && (
            <>
              <FormProvider {...methods}>
                <form>
                  <WpGrid
                    hasDefaultExpandedChildContext
                    rows={filteredListForNow}
                    columns={showPermissionTable(roleDetails)}
                    showPagination={false}
                    childContent={childContext}
                    serverSidePaging
                  />
                </form>
              </FormProvider>
            </>
          )}
        </Box>
      );
    });
  };

  return (
    <>
      <Box component="div" className={classes.container}>
        <Grid container className={classes.headerContainer}>
          <Grid item xs={8}>
            {/* TODO: RB - We will possibly remove the header below, pending designs*/}
            <WpTypography variant="p1Bold" className={classes.header}>
              {props?.from == UserAccountsTypeEnum.AccountingFirm
                ? 'Firm permissions'
                : props.title
                ? props.title
                : t('userAccounts:roleTabLinks.permission')}
            </WpTypography>
          </Grid>

          {isShowEditButton && (
            <Grid item xs={4} className={classes.editIcon}>
              <WpLink className={classes.editIconButton} onClick={switchToEdit} disabled={isViewOnlyPermission}>
                {t('userAccounts:permissionSettings.edit')}
              </WpLink>
            </Grid>
          )}

          {(isReset || (hasCustomizationsForPeople && isEdit)) && (
            <Grid item xs={4} className={classes.resetIcon}>
              <WpLink isButton onClick={() => setResetdialogue(true)} className={classes.resetdefaultIconButton}>
                {t('userAccounts:permissionSettings.resetToDefault')}
              </WpLink>
            </Grid>
          )}
        </Grid>

        <Box component="div" className={classes.tableContainer}>
          {displayGrid(props?.from)}
        </Box>

        <WpBottomNav
          primaryBtn={
            isEdit || isCardEdit
              ? {
                  text: t('userAccounts:permissionSettings.save'),
                  fn: handleSaveClick,
                }
              : undefined
          }
          secondaryBtn={{
            text: isEdit
              ? t('userAccounts:firmInviteAccepted.revokeCancel')
              : t('userAccounts:permissionSettings.back'),
            fn: handleFooterCancelClick,
          }}
        />

        <PermissionSettingsDialogue
          open={canceldialogue}
          onAcceptButtonClick={cancelDialogueService}
          onRejectButtonClick={() => setCanceldialogue(false)}
        />

        <CustomizationsDialogues
          isOpen={hasCustomizationsDialogue}
          onKeepCustomization={() => persistSecurityRole(true)}
          onPersistSecurityRole={() => persistSecurityRole()}
          closeCustomization={() => setCustomizationsDialogue(false)}
        />

        <WpDialog
          maxWidth="sm"
          fullWidth={true}
          open={resetdialogue}
          onClose={() => setResetdialogue(false)}
          title={<> {t('userAccounts:permissionSettings.resetToDefault')}</>}
          content={
            <>
              <WpTypography>
                {' '}
                {t('userAccounts:permissionSettings.resetDescription', {
                  role: roles && Object.keys(roles).length == 0 ? props?.details?.firstName : roles?.name,
                })}
              </WpTypography>
            </>
          }
          actions={
            <>
              <WpButton onClick={() => setResetdialogue(false)} disableRipple={true} color="primary" variant="outlined">
                {t('common:cancel')}
              </WpButton>
              <WpButton
                disableRipple={true}
                onClick={resetToDefaultPermission}
                color="primary"
                variant="contained"
                autoFocus
              >
                {t('userAccounts:permissionSettings.yesReset')}
              </WpButton>
            </>
          }
        />
      </Box>

      <CustomPrompt isShow={isDirty && !isSave && !canceldialogue} />
    </>
  );
});

export default PermissionSettings;
