import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import {
  resetAllData,
  selectFirmView,
  selectFirmViewFrom,
  setFirmView,
  updateInvitationList,
} from 'redux/slice/accountingFirmSlice';

import ZeroState from './zero-state/zeroState';
import FirmPermissionsContainer from './firm-permission/firmPermissionsContainer';
import FirmInvited from './firm-invitations/firmInvitedContainer';
import FirmAdminContainer from './firm-admin/firmAdminContainer';

import { CompanySummaryInterface } from 'services/finance/models/access/companyAccess';
import {
  accountingFirmListQueryKeys,
  useGetPartnerCompanyListQuery,
} from 'pages/settings/components/accounting-bookkeeping-firm/hooks/useAccountingFirmListQuery';
import queryClient from 'query-client/query-client';
import { selectCompanyId } from 'redux/slice/configSlice';

export const enum ManageViewOfAccountingFirm {
  NotDefined = 0,
  ZeroState = 1,
  FirmPermission = 2,
  FirmAdministrator = 3,
  FirmInviteSent = 4,
}

const AccountantBookkeepingFirm: React.FC = () => {
  const dispatch = useAppDispatch();
  const firmView = useAppSelector(selectFirmView);
  const from = useAppSelector(selectFirmViewFrom);
  const selectedCompanyId = useAppSelector(selectCompanyId);

  const { data } = useGetPartnerCompanyListQuery();

  const updateFirmView = (data?: Array<CompanySummaryInterface>) => {
    dispatch(updateInvitationList(data ?? []));
    if (!data?.length) {
      dispatch(
        setFirmView({
          firmView: ManageViewOfAccountingFirm.ZeroState,
          isEdit: false,
        })
      );
    } else {
      dispatch(
        setFirmView({
          firmView: ManageViewOfAccountingFirm.FirmInviteSent,
          isEdit: false,
        })
      );
    }
  };

  const getPartnerList = useCallback(async () => {
    if (from === ManageViewOfAccountingFirm.FirmPermission) {
      await queryClient.resetQueries({
        queryKey: accountingFirmListQueryKeys.getPartnerCompanyListQuery(selectedCompanyId).key,
      });
    }
  }, [from, queryClient]);

  useEffect(() => {
    return () => {
      dispatch(resetAllData());
    };
  }, []);

  useEffect(() => {
    updateFirmView(data);
  }, [data]);

  useEffect(() => {
    getPartnerList();
  }, [getPartnerList]);

  const displayCurrentView = () => {
    switch (firmView) {
      case ManageViewOfAccountingFirm.ZeroState:
        return <ZeroState />;
      case ManageViewOfAccountingFirm.FirmPermission:
        return <FirmPermissionsContainer />;
      case ManageViewOfAccountingFirm.FirmInviteSent:
        return <FirmInvited />;
      case ManageViewOfAccountingFirm.FirmAdministrator:
        return <FirmAdminContainer />;
    }
  };

  return <>{displayCurrentView()}</>;
};

export default AccountantBookkeepingFirm;
