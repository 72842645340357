import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IPersistNewRelevantStateTaxDetailsRequest } from 'services/add-employment-province-access/addPoEAccess.contracts';

interface ITaxSlice {
  isAdditionalTaxToggled: {
    provincial: boolean;
  };
  autoPopulateData: {
    provincial: any;
    schemeId: any;
  };
  additionalTaxesEdited: { provincial: any };
  provincialTdiCode: any;
  taxAmount: number;
  taxType: any;
  taxInfoRequest: IPersistNewRelevantStateTaxDetailsRequest | null;
}

const initialState: ITaxSlice = {
  isAdditionalTaxToggled: {
    provincial: false,
  },
  autoPopulateData: {
    provincial: null,
    schemeId: null,
  },
  additionalTaxesEdited: { provincial: null },
  provincialTdiCode: null,
  taxAmount: 0,
  taxType: '',
  taxInfoRequest: null,
};

export const poeTaxInfoSlice = createSlice({
  name: 'poeTaxInfoSlice',
  initialState,
  reducers: {
    resetAutoPopulateField: (state, action: PayloadAction<{ key: 'provincial' }>) => {
      state.autoPopulateData[action.payload.key] = null;
    },
    resetToggles: (state) => {
      state.isAdditionalTaxToggled = initialState.isAdditionalTaxToggled;
    },
    resetAll: (state) => {
      state.autoPopulateData = initialState.autoPopulateData;
      state.provincialTdiCode = null;
      state.taxAmount = 0;
      state.isAdditionalTaxToggled.provincial = false;
      state.taxInfoRequest = null;
    },
    toggleAdditionTaxAction: (state, action: PayloadAction<{ key: 'provincial'; value: boolean }>) => {
      state.isAdditionalTaxToggled[action.payload.key] = action.payload.value;
    },
    updateEditableAdditionalTaxes: (state, action: PayloadAction<{ key: 'provincial'; value: boolean | null }>) => {
      const { key, value } = action.payload;
      state.additionalTaxesEdited[key] = value;
    },
    updateTaxInfoAction: (state, action: PayloadAction<{ key: keyof ITaxSlice; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  toggleAdditionTaxAction,
  resetAutoPopulateField,
  updateEditableAdditionalTaxes,
  updateTaxInfoAction,
  resetAll,
} = poeTaxInfoSlice.actions;
export const poeTaxInfoReducer = poeTaxInfoSlice.reducer;
export const getPoETaxInfoStates = (state: RootState) => state.poeTaxInfoReducer;
export const selectProvincialTdiCode = (state: RootState) => state.poeTaxInfoReducer?.provincialTdiCode;
export const selectPoeTaxType = (state: RootState) => state.poeTaxInfoReducer?.taxType;
export const selectAdditionalTaxesEdited = (state: RootState) => state.poeTaxInfoReducer?.additionalTaxesEdited;
export const selectPoeTaxAmount = (state: RootState) => state.poeTaxInfoReducer?.taxAmount;
export const selectIsAdditionalTaxToggled = (state: RootState) => state.poeTaxInfoReducer?.isAdditionalTaxToggled;
export const selectTaxInfoRequest = (state: RootState) => state.poeTaxInfoReducer?.taxInfoRequest;
export const getAdditionTaxToggleStates = (state: RootState) => state.poeTaxInfoReducer.isAdditionalTaxToggled;
export const getDataToAutopopulate = (state: RootState) => state.poeTaxInfoReducer.autoPopulateData;
