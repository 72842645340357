import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

import {
  IGetBankAccountInitialStateResponse,
  IGetBankAccountInitialStateRequest,
  ICompanyBankState,
} from 'services/company-bank-access/companyBankAccess.contracts';
import { GetBankAccountInitialState } from 'services/company-bank-access/companyBankAccess.service';

const initialState: ICompanyBankState & { isBankAccountFetched: boolean } = {
  bankAccount: {},
  bankAccountVerification: {},
  isAdditionalInfoRequired: false,
  isBankAccountFetched: false,
};

export const getBankAccountInitialstate = createAsyncThunk(
  'companybank/getBankAccountInitialstate',
  async (request: IGetBankAccountInitialStateRequest) => {
    const response: IGetBankAccountInitialStateResponse = await GetBankAccountInitialState(request);
    return response;
  }
);

export const companyBankSlice = createSlice({
  name: 'companybank',
  initialState,
  reducers: {
    updateCompanyBank: (state, action: PayloadAction<ICompanyBankState>) => {
      state.bankAccount = action.payload.bankAccount;
      state.bankAccountVerification = action.payload.bankAccountVerification;
      state.isAdditionalInfoRequired = action.payload.isAdditionalInfoRequired;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBankAccountInitialstate.fulfilled, (state, action) => {
      state.isAdditionalInfoRequired = action.payload.isAdditionalInfoRequired;
      state.bankAccount = action.payload.bankAccount;
      state.isBankAccountFetched = true;
    });
  },
});

export const { updateCompanyBank } = companyBankSlice.actions;
export const companyBankReducer = companyBankSlice.reducer;
export const selectCompanyBank = (state: RootState) => state.companyBankReducer;
export const selectCompanyBankAccount = createSelector(selectCompanyBank, ({ bankAccount }) => bankAccount);
export const selectCompanyBankAccountId = createSelector(selectCompanyBank, ({ bankAccount }) => bankAccount?.id ?? 0);
export const selectAdditionalInfoRequired = createSelector(
  selectCompanyBank,
  ({ isAdditionalInfoRequired }) => isAdditionalInfoRequired
);

export const isBankAccountFetchedSelector = createSelector(
  selectCompanyBank,
  ({ isBankAccountFetched }) => isBankAccountFetched
);
