import React, { useState } from 'react';
import WpMenu, { IWpMenuOption } from '@wagepoint/ui-toolkit/components/wp-menu/wpMenu';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { getApiValidationErrors } from 'util/utility';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import companyLinkAccess from 'services/finance/models/access/companyLinkAccess';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { useViewPermission } from 'util/customHooks/useViewPermission';
import { useRouteMatch } from 'react-router-dom';
import { selectCompanyId, updatePartnerCompanyId } from 'redux/slice/configSlice';
import { selectFirmName, setFirmView, updateCurrentRole, updateIsValidCompany } from 'redux/slice/accountingFirmSlice';
import RolesRadioGroupSection from '../../../common/radio-group/rolesRadioGroup';
import { ManageViewOfAccountingFirm } from '../../..';
import { error, success } from 'redux/slice/snackbarSlice';
import { hideLoading, showLoading } from 'redux/slice/loadingIndicatorSlice';
import { selectAccountingAssignedRole, selectedRoleForAccountingFirm } from 'redux/slice/userAccountsSlice';
import { CompanySummaryInterface } from 'services/finance/models/access/companyAccess';
import { accountingFirmListQueryKeys } from 'pages/settings/components/accounting-bookkeeping-firm/hooks/useAccountingFirmListQuery';
import { ValidEnum } from 'pages/settings/components/accounting-bookkeeping-firm/common/company-email-layout/companyIdOrEmailLayout';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import { useFeatureFlag } from '@wagepoint/ui-shared-library/shared-system/functions/launch-darkly';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { GlobalStyles } from 'styles/globalStyles';
import partnerAccess from 'services/payroll/models/access/partnerAccess';

export const enum DialoguesEnum {
  NotDefined = 0,
  Revoke = 1,
  Roles = 2,
  Decline = 3,
  Accept = 4,
}

interface IProps {
  item: CompanySummaryInterface;
  from?: boolean;
  viewFirm?: () => void;
}

const MenuContainer: React.FC<IProps> = ({ item, from, ...props }) => {
  const { t } = useTranslation(['userAccounts', 'common']);
  const companyId = useAppSelector(selectCompanyId);
  const name = useAppSelector(selectFirmName);
  const assignedRole = useAppSelector(selectAccountingAssignedRole);
  const { path } = useRouteMatch();
  const { isViewOnlyPermission } = useViewPermission(path);
  const globalClasses = GlobalStyles();

  const { feature } = useFeatureFlag();
  const isClientPartnerDisconnectionTwoStepApprovalFeature = feature(
    FEATURE_FLAG_KEYS.ClientPartnerDisconnectionTwoStepApproval
  );
  const AlowInvitationPendingRoleChange = feature(FEATURE_FLAG_KEYS.AlowInvitationPendingRoleChange);

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const [dialogId, setDialogId] = useState(DialoguesEnum.NotDefined);

  const acceptedMenuOptions: IWpMenuOption[] = [
    {
      label: t('firmInviteAccepted.viewAdmin'),
      onClick: () => {
        dispatch(
          setFirmView({
            firmView: ManageViewOfAccountingFirm.FirmAdministrator,
            isEdit: false,
            companyId,
            name: item.name,
            clientId: item?.clientId,
            partnerId: item?.partnerId,
          })
        );
      },
    },
    {
      label: t('firmInviteAccepted.configure'),
      disabled: isViewOnlyPermission,
      onClick: () => {
        setDialogId(DialoguesEnum.Roles);
        const assignedValue = (assignedRole == 0 ? item?.securityRoleTypeId : assignedRole) ?? 0;
        dispatch(updateCurrentRole(assignedValue));
      },
    },
    {
      label: t('firmInviteSent.revokeAccess'),
      disabled: isViewOnlyPermission,
      onClick: () => {
        setDialogId(DialoguesEnum.Revoke);
      },
    },
  ];

  const pendingMenuOptions: IWpMenuOption[] = [
    {
      label: t('firmInviteAccepted.configure'),
      disabled: isViewOnlyPermission,
      onClick: () => {
        setDialogId(DialoguesEnum.Roles);
        const assignedValue = (assignedRole == 0 ? item?.securityRoleTypeId : assignedRole) ?? 0;
        dispatch(updateCurrentRole(assignedValue));
      },
    },
  ];

  const handleConfigureRoles = async () => {
    dispatch(showLoading());
    try {
      if (assignedRole === SecurityRoleTypeEnum.ReportsManager) {
        await turn2SAOff();
      }
      const response = await companyLinkAccess.modifyPartnerRole({
        securityRoleType: (assignedRole == 0 ? item?.securityRoleTypeId : assignedRole) ?? 0,
        clientId: companyId,
        partnerId: item.partnerId ?? 0,
      });
      const { hasError, message } = getApiValidationErrors(response);
      if (hasError) {
        throw new Error(message);
      }
      await queryClient.resetQueries({
        queryKey: accountingFirmListQueryKeys.getPartnerCompanyListQuery(companyId).key,
      });
      dispatch(updateCurrentRole((assignedRole == 0 ? item?.securityRoleTypeId : assignedRole) ?? 0));
    } catch (e: any) {
      dispatch(error({ message: e?.message }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleChangePendingRole = async () => {
    dispatch(showLoading());
    const response = await companyLinkAccess.clientInvitesPartner({
      invitedByEmail: false,
      invitedByPartnerCompanyId: true,
      partnerCompanyId: item.partnerId ?? 0,
      partnerSecurityRoleType: assignedRole ?? SecurityRoleTypeEnum.NotDefined,
      clientCompanyId: companyId,
      isInvitingNewPartner: false,
    });

    const { hasError, message } = getApiValidationErrors(response);

    if (!hasError) {
      dispatch(error({ message: message }));
    }
    await queryClient.resetQueries({
      queryKey: accountingFirmListQueryKeys.getPartnerCompanyListQuery(companyId).key,
    });
    dispatch(updateCurrentRole((assignedRole == 0 ? item?.securityRoleTypeId : assignedRole) ?? 0));
    dispatch(hideLoading());
  };

  const onConfigureRoles = async () => {
    from && props.viewFirm ? handleConfigureRoles() : handleChangePendingRole();
  };

  const turn2SAOff = async () => {
    if (!item.partnerIsTheOnlySubmitter) return;

    const response = await partnerAccess.maintainTwoStepApproval({
      turnOn: false,
      clientCompanyIdList: [companyId],
      partnerCompanyId: item.partnerId ?? 0,
      enableForAllClients: false,
    });

    const { hasError, message } = getApiValidationErrors(response);
    if (hasError) {
      throw new Error(message);
    }
  };

  const revokeAccessService = async () => {
    dispatch(showLoading());
    setDialogId(DialoguesEnum.NotDefined);

    try {
      await turn2SAOff();
      const response = await companyLinkAccess.revokePartnerAccess({
        invitationId: item.invitationId ?? 0,
        partnerCompanyId: item.partnerId ?? 0,
      });

      dispatch(
        selectedRoleForAccountingFirm({
          assignedRole: SecurityRoleTypeEnum.NotDefined,
        })
      );
      dispatch(updateIsValidCompany(ValidEnum.NotDefined));

      const { hasError, message } = getApiValidationErrors(response);
      if (hasError) {
        throw new Error(message);
      }

      if (from)
        dispatch(
          success({
            message: t('firmInviteSent.revokeSuccessfull', {
              name: name || item.name,
            }),
          })
        );
      await queryClient.resetQueries({
        queryKey: accountingFirmListQueryKeys.getPartnerCompanyListQuery(companyId).key,
      });
      dispatch(updatePartnerCompanyId(null));
    } catch (e: any) {
      dispatch(error({ message: e?.message }));
    } finally {
      dispatch(hideLoading());
    }
  };

  const getTitleForRevokeDialog = () => {
    return from
      ? t('firmInviteSent.revokeInviteTitle', {
          name: name || item.name,
        })
      : t('firmInviteSent.revoke');
  };

  const getContentForRevokeDialog = () => {
    return from
      ? t('firmInviteSent.revokeConfirmation', {
          name: name || item.name,
        })
      : t('firmInviteSent.confirmation');
  };

  const renderDialogues = () => {
    if (dialogId === DialoguesEnum.Revoke) {
      return (
        <WpDialog
          maxWidth="sm"
          fullWidth
          open
          title={getTitleForRevokeDialog()}
          content={
            <>
              {isClientPartnerDisconnectionTwoStepApprovalFeature && item.partnerIsTheOnlySubmitter && (
                <div className={globalClasses.mb1}>
                  <WpStatements variant="warning" message={t('firmInviteSent.revoke2SA')} />
                </div>
              )}
              {getContentForRevokeDialog()}
            </>
          }
          actions={
            <>
              <WpButton
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDialogId(DialoguesEnum.NotDefined);
                  event?.stopPropagation();
                }}
                disableRipple
                color="primary"
                variant="outlined"
              >
                {from ? t('common:cancel') : t('firmInviteSent.no')}
              </WpButton>
              <WpButton
                disableRipple
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.stopPropagation();
                  revokeAccessService();
                }}
                color="primary"
                variant="contained"
                autoFocus
              >
                {from ? t('firmInviteSent.revokeMenu') : t('firmInviteSent.revokePending')}
              </WpButton>
            </>
          }
        />
      );
    } else if (dialogId === DialoguesEnum.Roles) {
      return (
        <WpDialog
          maxWidth="sm"
          fullWidth
          open
          title={t('firmInviteAccepted.configure')}
          content={
            <RolesRadioGroupSection
              role={item?.securityRoleTypeId}
              firm={item.name}
              fromConfigureRoles={true}
              isTheOnlySubmitter={item.partnerIsTheOnlySubmitter}
            />
          }
          actions={
            <>
              <WpButton
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event?.stopPropagation();
                  setDialogId(DialoguesEnum.NotDefined);
                  dispatch(
                    selectedRoleForAccountingFirm({
                      assignedRole: item?.securityRoleTypeId,
                    })
                  );
                }}
                disableRipple
                color="primary"
                variant="outlined"
              >
                {t('common:cancel')}
              </WpButton>
              <WpButton
                disableRipple
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event?.stopPropagation();
                  onConfigureRoles();
                }}
                color="primary"
                variant="contained"
                autoFocus
              >
                {t('permissionSettings.save')}
              </WpButton>
            </>
          }
        />
      );
    }
  };

  const getInvitationPendingAction = () =>
    AlowInvitationPendingRoleChange ? (
      <WpMenu
        label={t('firmInviteSent.cancelInvite')}
        type="Button"
        variant="text"
        options={pendingMenuOptions}
        onButtonClick={() => {
          setDialogId(DialoguesEnum.Revoke);
        }}
      />
    ) : (
      <WpButton
        variant="outlined"
        noGutter
        onClick={() => {
          setDialogId(DialoguesEnum.Revoke);
        }}
      >
        {t('firmInviteSent.revoke')}
      </WpButton>
    );

  return (
    <>
      {from && props.viewFirm ? (
        <>
          <WpMenu
            label={'View permissions'}
            type="Button"
            variant="text"
            options={acceptedMenuOptions}
            onButtonClick={props.viewFirm}
          />
        </>
      ) : (
        getInvitationPendingAction()
      )}

      {renderDialogues()}
    </>
  );
};

export default MenuContainer;
