import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';
import { useAppDispatch } from 'redux/hooks/hook';
import { updateFromAlfred } from 'redux/slice/fromAlfredSlice';
import { CompanyTypeEnum } from 'services/appCore/models/schema/Schema';
import { CompanyViewTypeEnum } from 'services/ui-config-access/uiConfigAccess.contracts';

export const useFromAlfredUser = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const { feature } = useFeatureFlag();
  // This Feature Flag also used in alfred. When Archieving, need to Archieve from both the places.
  const enableCompanyNameEncode = feature(FEATURE_FLAG_KEYS.EncodeCompanyName);
  const userIdFromAlfed = Number(urlParams.get('companyId'));
  const companyViewType = Number(urlParams.get('companyType'));
  const companyName = enableCompanyNameEncode
    ? atob(urlParams.get('companyName') || '')
    : urlParams.get('companyName') || undefined;
  const companyCode = urlParams.get('companyCode') || undefined;
  const getCompanyType = () => {
    switch (companyViewType) {
      case CompanyViewTypeEnum.customer:
        return CompanyTypeEnum.customer;
      case CompanyViewTypeEnum.partner:
        return CompanyTypeEnum.partnerIndependent;
      default:
        return CompanyTypeEnum.notDefined;
    }
  };
  userIdFromAlfed && dispatch(updateFromAlfred({ userIdFromAlfed, companyViewType }));
  return { userIdFromAlfed, companyViewType, overrideCompanyType: getCompanyType(), companyName, companyCode };
};
