export enum FEATURE_FLAG_KEYS {
  AppNewContentWidthCalculation = 'release-ui-app-new-content-width-calculation',
  /** paygroup flags */
  BannerExtraPaygroupPeriods = 'banner-for-extra-period-in-paygroup',
  ShowAutoRun = 'client-app-show-auto-run',
  ShowAutoRunFeedbackBanner = 'auto-run-feedback-banner',
  AutoRunValidateDeadlinesByMoneyMovement = 'release-ui-auto-run-validate-deadlines-by-money-movement',
  CustomCodeRadioButton = 'custom-code-radio-button',
  HideQuickBooks = 'hide-quick-books',
  MobileNavigation = 'client-app-mobile-navigation',
  NewOnboardingPage = 'client-app-new-onboarding-page',
  PeopleSaveOnBackButton = 'people-save-on-back-button',
  New2FAFlowInSecurity = 'new-2fa-flow-in-security',
  FinalizedPayrollRegister = 'finalized-payroll-payroll-register',
  FtuPayGroupIsNotMandatory = 'ftu-pay-group-is-not-mandatory',
  DisableOffCycleForNewPayroll = 'disable-off-cycle-first-payroll',
  NewAuthorizedSignatoryOnboardingStep = 'NewAuthorizedSignatoryOnboardingStep',
  EnablePaygroupConfirmationPopup = 'enable-paygroup-confirmation-popup',
  PayrollLeaveModal = 'client-app-payroll-leave-modal',
  UpdatedUIRemovedPayperiodID = 'client-app-updated-paygroup-ui',
  PayrollisVerifiedFlagImpacted = 'payroll-verified-flag-impacted',
  BusinessEmail = 'company-details-business-email',
  ShowPayrollNoAccessScreen = 'ui-show-payroll-no-access-screen',
  EnableNewSchemaForCustomizeHours = 'enable-new-schema-customize-paygroup',
  PayrollSettingsAssignPeopleNewProvinceList = 'payroll-settings-assign-people-new-province-list',
  /**  ROE related flags */
  ROE = 'client-app-record-of-employment',
  DraftROE = 'client-app-draft-roe-feature',
  ROEMaxHoursValidation = 'roe-paygroup-max-hours-validation',
  RoeBlock12Messages = 'roe-finalpayperiod-warning-messages',
  ROEAddDeleteForManualSubmitted = 'roe-add-delete-manual-submitted',
  NewRoeWpTableComponent = 'release-ui-new-roe-wp-table-component',
  EnableRoeSkipBlock18ValidationWhichBasedOnBlock17c = 'release-ui-roe-skip-block-18-validation-which-based-on-block-17-c',
  /** Year to date flags */
  YearToDate = 'client-app-year-to-date',
  HideYTDReport = 'enable-hiding-ytd-report',
  /** Payroll invoice */
  PayrollInvoice = 'client-app-payroll-invoice',
  NewOffCycleLogic = 'client-app-new-offcycle-logic',
  /** Year-end flags */
  YearEnd = 'client-app-year-end',
  YearEndTestingCard = 'year-end-testing-card',
  YearEndGridView = 'year-end-grid-view',
  YearEndHideRL1 = 'year-end-hide-qc',
  YearEndCreateAmendment = 'year-end-create-amendment',
  YearEndNewCheckListEnabled = 'ui-enable-new-yearend-checklist',
  ShowYearEndMenuInPayroll = 'show-year-end-menu-in-payroll',
  /** statutory holiday */
  StatHoliday = 'client-app-statutory-holiday',
  EnableStatHolidaySorting = 'enable-stat-holiday-sorting',
  /** associated company */
  AssociatedCompany = 'client-app-associated-company-setting',
  /** subscription invoice   */
  subscription = 'client-app-subscription-settings',
  /** Billing */
  SubscriptionInvoiceTableContent = 'billing-subscription-invoice-table-content',
  EnableTempPartnerSubscriptionHub = 'enable-temp-partner-subscription-hub',
  /** import hours */
  ImportHours = 'client-app-import-hours',
  EnableNoNewHoursImportedMessage = 'enable-no-new-hour-import-hours',
  /** Add on  */
  AddOn = 'client-app-add-ons',
  /** prior payroll */
  NewPPValidationLogic = 'client-app-use-new-payroll-validation-logic',
  /** payroll module */
  EnableMultiJob = 'client-app-enable-multi-job',
  ImportHoursFromMultipleJobs = 'import-hours-from-multiple-jobs',
  EnableTwoStepApproval = 'enable-payroll-two-step-approval',
  NewRateOfPayLogic = 'client-payroll-flow-default-rate-of-pay-logic',
  NewCodeForCustomHandlerPayroll = 'client-app-custom-code-overriding-refactor',
  EnableDeleteMatrix = 'enable-delete-matrix-column-api',
  EnableEmployeeLevelCustomCode = 'kill-ui-employee-level-default-code-flow',
  EnableNewDeleteMatrixApproach = 'app-new-delete-matrix-approach',
  ShowPayrollRegisterCsvExport = 'show-csv-export-for-payroll-register',
  ShowPostingJournalCsvExport = 'show-csv-export-for-posting-journal',
  PayrollNewTables = 'payroll-new-tables',
  PayrollNewTableComponentHoursAndIncomesForm = 'release-ui-payroll-new-table-component-hours-and-incomes-form',
  ShowPostingJournalQBOExport = 'release-ui-show-export-qbo-option-for-posting-journal-report',
  ShowPayGroupPayPeriod = 'show-pay-period-on-overview-card',
  EnableRemoveDuplicateInitialStateCall = 'enable-remove-duplicate-getinitialstate-call',
  /** Client app: Disable edit for default user permission*/
  DisableEditForUserPermissions = 'client-app-disable-edit-for-default-user-permission',
  HidePartnerPay = 'client-app-accounting-dashboard-hide-partner-pay-option',
  /** Client app: accounting dashboard > client directory > overview > terms of service */
  ClientDirectoryOverviewTermsOfService = 'terms-of-service-client-directory-overview',
  RemoveFirmPreferenceCall = 'client-app-payroll-remove-firm-preference-service',
  EnableLoginOrSetupPayroll = 'client-app-accounting-dashboard-enable-login-or-setup-payroll',
  AlowInvitationPendingRoleChange = 'accounting-bookkeeping-invitation-pending-role-change',
  /** Partner app: invite client > terms of service */
  PartnerInviteClientTermsOfService = 'terms-of-service-partner-invite-client',
  AutomaticClientInvitationAcceptance = 'automatic-client-incitation-acceptance',
  UpdateClientEmail = 'partner-dashboard-update-client-email',
  /** Partner app: Enable invoces on sidebar */
  ShowInvoicesForPartnerApp = 'partner-app-show-invoices',
  /** Partner app: Hide Edit Billing setup */
  hideEditBillingSetup = 'partner-app-hide-edit-billing-setup',
  BillingSetupRadio = 'accounting-dashboard-billing-setup-radio',
  /** Partner app: Dashboard */
  ShowDashboardExportToCSV = 'dashboard-export-to-csv-link',
  ShowNudgeAction = 'accounting-dashboard-payroll-nudge-action',
  PartnerNewTables = 'partner-new-tables',
  ShowHelpArticles = 'helparticles-partnerdashboard',
  ClientTracker = 'partner-dashboard-client-tracker',
  ClientDirectoryOverviewName = 'enable-partner-dashboard-client-directory-overview-name',
  /** Billing: Show transfer funds caard for NSF */
  showTransferFundsCardForNSF = 'billing-show-transfer-funds-card-for-nsf',
  planSelectionNewGetConfigLogic = 'kill-plan-selection-new-logic-for-getconfig',
  /*Source Deductions*/
  EnableCRAModulusChecker = 'enable-cra-number-modulus-checker',
  EnableRQModlusChecker = 'enable-rq-modulus-checker',
  EnableRemitModalDescription = 'enable-remit-schedule-modal-description',
  /** Launch darkly */
  SaveAccountProperty = 'client-app-ld-account-property',
  /** Common */
  EnableSentry = 'enable-sentry',
  Crowdin = 'crowdin-implementation',
  AllowCustomerToResolveNsf = 'allow-customer-to-resolve-nsf',
  EnableMarkerIO = 'client-app-enable-marker-io',
  HidePaystubFeatures = 'client-app-hide-non-working-paystub-features',
  RestrictQuebec = 'client-app-restrict-quebec-state',
  HideMailToSupport = 'client-app-mail-to-support',
  Enable2024Dates = 'client-app-enable-next-year-dates',
  BlockNextYearPayDate = 'payroll-run-block-nextyear-paydate',
  EnableNewGetConfiguration = 'front-end-enable-payroll-getconfiguration',
  EnableHandleErrorOnSessionCheck = 'handle-error-on-session-check',
  EnableHandleErrorOnSessionCheckAndOpenApp = 'release-ui-handle-error-on-session-check-and-open-app',
  EnableMultiProvince = 'client-app-multi-province',
  EnableSignalR = 'client-app-enable-signal-r',
  EnableBillingAccessMaintainClientPartnerInvitation = 'partner-app-enable-billing-access-maintain-client-partner-invitation',
  EnableDeviceSniffingViaUserAgent = 'enable-device-sniffing-via-user-agent',
  InitialRedirectNewAppReadyCondition = 'initial-redirect-app-ready-new-condition',
  EnableClickjackingProtection = 'release-ui-enable-clickjacking-protection',
  CoBrowserUpScope = 'kill-ui-up-scope-co-browser',
  EnableAutoPlanSelection = 'AutoLoginAutoPlan',
  EnableAutoLoginForPartner = 'AutoLoginAutoPlanPartner',
  EnableSatisMeter = 'release-enable-satis-meter',
  enableWorkersCompNewTable = 'enable-workers-comp-new-table',
  enableHidingOfPlanSelection = 'enable-redirection-to-login-after-signup',
  /** Adjustments */
  AdjustmentsTool = 'adjustment-tool',
  HideAdjustmentsPayDateDropdown = 'adjustments-pay-period-dropdown-hidden',
  AdjustmentsHistory = 'adjustments-history',
  EnableAdjustmentsDebounce = 'enable-adjustments-debounce',
  RemoveTaxListStaleTime = 'remove-gettaxlist-api-staletime',
  EnableMultiplePaygroupSelection = 'enable-multiple-paygroup-adjustment-selection',
  NewLogicForAdjustment = 'calculate-net-pay-for-ytd-adjustment',
  /** Addons Flags  */
  EnableXeroIntegration = 'enable-xero-addon',
  EnableAccountBookKeeping = 'client-app-accounting-and-book-keeping',
  EnableQBOExportReports = 'enable-qbo-export-reports',
  ExportReportsOnBackendSide = 'qbo-manual-export-reports-on-be-side',
  ShowReportsTaxesInvoiceQBO = 'qbo-manual-export-auto-sync-show-taxes-invoice-reports',
  ShowAutoSyncReportsQBO = 'show-auto-sync-reports-section-in-qbo',
  QBOMakeSequentialApiCalls = 'qbo-chart-of-accounts-sequential-api-calls',
  XEROMakeSequentialApiCalls = 'xero-chart-of-accounts-sequential-api-calls',
  PreserveCOAMappingOnDisconnection = 'preserve-coa-mapping',
  QBOEnableTextItemsInMappingDropdown = 'qbo-enable-text-item-in-mapping-dropdown',
  QBOEnableAllChildrenCategoriesInMappingDropdown = 'qbo-enable-all-children-categories-in-mapping-dropdown',
  XEROEnableTextItemsInMappingDropdown = 'xero-enable-text-item-in-mapping-dropdown',
  XEROEnableAllChildrenCategoriesInMappingDropdown = 'xero-enable-all-children-categories-in-mapping-dropdown',
  XEROPostRelease = 'xero-post-release',
  XEROAutoDisconnect = 'xero-auto-disconnect',
  TimeAdditionalSupportTokenExpiredShowDisconnect = 'hide-token-expired-screen-time-additional-support',
  QBOChildMappingWagesAndBenefits = 'ui-qbo-child-mapping-wages-benefits',
  QBOLinkSupplierOfflineLoaderFix = 'enable-link-supplier-loading-fix-on-error',
  QBODBConcurrency = 'ui-qbo-db-concurrency-resolve',
  /** Autocomplete off for fields */
  enableAutofillOff = 'enable-autofill-off',
  /** 2FA */
  Mandatory2faSetup = 'mandatory-two-factor-setup-page',
  AuthenticatorAppVerification = 'authenticator-app-feature',
  MandatoryTwoFactorFingerprint = 'mandatory-two-factor-fingerprint',
  PeopleReset2FA = 'people-reset-employee-two-fa',
  TwoFactorAuthentication = 'TwoFactorAuthentication',
  PhoneNumberInput = 'multiple-country-phone-number-input',
  /** prior payroll */
  priorPayrollAutoCalculateTax = 'client-app-prior-payroll-auto-calculate-ei-cpp',
  /** Partner App: Hide modals */
  partnerAppHideModals = 'partner-app-hide-modals',
  RedirectByPermissionUpdated = 'new-redirect-by-permission-logic',
  /** Prior payroll */
  TaxRelationshipWithValidation = 'tax-relationship-with-prior-payroll-validation',
  /** log out */
  AutoLogout = 'auto-log-out',
  /** Subscription plan */
  EnableSoloPlan = 'enableSoloPlan',
  /** General settings */
  TwoStepApproval = 'two-step-approval',
  DisablePartnerPaysForExistingClient = 'disable-partner-pays-when-connecting-with-existing-client',
  DisableChangeBillingSetUpForClient = 'disable-change-billing-set-up-for-a-client',
  NewWelcomeModalWithPlanSelection = 'new-welcome-modal-with-plan-selection',
  EnableGetConfigLogoId = 'enable-get-config-logo-id',
  EnableHidingEmployerBenefits = 'enable-employer-benefit-hide-general-settings',
  /** banking */
  EnableClientBankingAndSignatorySeparation = 'enable-client-banking-and-signatory-separation',
  EnablePartnerBankingAndSignatorySeparation = 'enable-partner-banking-and-signatory-separation',
  EnableNewPadAgreementCard = 'enable-new-pad-agreement-card',
  EnablePadAgreementRemoving = 'enable-pad-agreement-removing-ability',
  /** account verification */
  EnableNewValidationRequestCard = 'enable-new-validation-request-card',
  EnableInstantVerificationForRbcCustomers = 'enable-instant-verification-for-rbc-customers',
  NewLogicForResumeAccountVerification = 'client-app-new-resume-logic-account-verification',
  NewAccountVerificationResumeBtnCondition = 'new-account-verification-resume-button-condition',
  PartnerEnableEditPermissionForAdminLevel = 'partner-enable-edit-permissions-on-admin-level',
  NewBankTransitNumberValidation = 'new-bank-transit-number-validation',
  NewAccountSetupModalForManualVerification = 'account-setup-complete-modal-manual-verification',
  RemoveBusinessNumberAndJurisdiction = 'remove-business-number-and-jurisdiction',
  HideAVRFormForClientsConnectedToPartners = 'hide-avr-form-for-clients-connected-to-partners',
  BankNameSearchOnBackend = 'bank-name-search-on-backend',
  HideAVRFormForPartner = 'hide-avr-form-for-partner',
  ShowPayrollTutorialLink = 'client-payroll-over-view-tutorial-link',
  EnableNewConditionForAccountVerificationStatement = 'release-ui-new-condition-for-account-verification-statement',
  EnableManualVerificationHarder = 'ui-manual-verification-harder',
  /** People */
  EnableEditTerminatedAndOnLeave = 'enable-edit-terminated-and-on-leave',
  MakeAccountValidationRequestOptional = 'make-account-validation-request-optional',
  ResponsivePeopleDashboard = 'responsive-people-dashboard',
  AllowAlfredPeopleDropdown = 'client-allow-alfred-people-dropdown',
  PeopleDashboardNewTable = 'release-ui-people-dashboard-new-table',
  EnableNewContentForCustomFreqSelection = 'enable-idb-customfrequency-new-content',
  EnableLWDPayDateFormatChange = 'enable-lwd-date-format-change',
  EnableBankValidations = 'enable-people-add-bank-validations',
  /*Employer Taxes*/
  ShowManitobaEmployerTaxes = 'show-manitoba-employer-taxes',
  ShowNFLEmployerTaxes = 'show-nfl-employer-taxes',
  /**IDB */
  NewRegularPayUpdateIDBModal = 'idb-regular-pay-new-modal-implementation',
  DisableIDBCustomCodeValidations = 'disable-idb-customcode-validation',
  EnableIDBDisableSaveOnNoChanges = 'enable-idb-edit-disable-on-no-changes',
  EnableStatHolidayRemove = 'stat-holiday-special-six-remove',
  /**Billing: Plan change **/
  ShowPlanChangeCta = 'billing-show-plan-change-cta',
  CustomCodeConditionRemover = 'client-controller-custom-code-condition',
  newProfileEmailUpdateFlow = 'client-enable-new-email-update-flow', // this was added to control the update email flow.
  RoundRateOfPayInPayroll = 'client-app-round-rate-of-pay',
  ShowLanguageDropdown = 'client-show-language-in-preferences',
  EnableIDBCustomCodeDeleteRefactor = 'enable-idb-disablecode-refactor',
  EnhancedPerformanceRefactor = 'refactored-version-for-performance',
  /** Reports **/
  EnablePrintToCheque = 'enable-print-to-cheque',
  EnableExportsMaxTakeForPerson = 'release-ui-reports-max-take-for-person',
  /** 2 step approval **/
  ClientPartnerDisconnectionTwoStepApproval = 'client-partner-disconnection-two-step-approval',
  EnforceTwoStepApprovalPartner = 'partner-dashboard-enforce-two-step-approval',
  /** People onboarding */
  ShowInviteModalOnFinalReviewWithOnboardDependency = 'show-invite-modal-with-onboarding-dependency-and-on-final-review-step',
  ShowInvitationModalPeopleDashboard = 'invitation-modal-in-people-dashboard',
  /** Migration **/
  KillSwitchMigration = 'Kill-Migration',
  EnableMigrationPadCustomPrompt = 'enable-migration-pad-customprompt',
  EnableMigrationLinkPermissions = 'enable-migration-subscription-link-permissions',
  /**Error Boundary**/
  EnableErrorBoundary = 'enable-error-boundary',
  SimulateErrorBoundaryError = 'simulate-error-boundary-error',
  ShowTwoStepApprovalForParticularClient = 'show-two-step-approval-for-particular-client',
  HireDateForTerminatedEmployees = 'hire-date-terminated-employees',
  reportFilterCustomMonth = 'report-filter-custom-month',
  reportFilterCustomQuarter = 'report-filter-custom-quarter',
  EnableLoginForExpiredClients = 'expired-clients-login',
  offcycleChangeStatusFlowDisabling = 'client-app-offcycle-disabling-reason',
  EmptyStateInFirmSettings = 'partner-dashboard-empty-state-in-firm-settings',
  ShowPensionableEarnings = 'prior-payroll-show-pensionable-earnings',
  ShowPartnerNotificationNewTable = 'release-ui-partner-show-new-notification-table-component',
  ShowPayrollDoubleHeader = 'release-ui-payroll-show-double-header',
  ShowPartnerInvoiceListNewTable = 'release-ui-partner-show-new-invoice-list-table-component',
  NewRefactoredHoursAndIncome = 'release-ui-refactored-hours-and-income-page',
  ShowRolesPermissionsNewTable = 'release-ui-preferences-show-new-roles-permissions-table-component',
  EmployeePortalPermissionsChanges = 'EmployeePortalPermissionsChanges',
  ReleaseShowEePortalStatusInPeople = 'release-show-ee-portal-status-in-people',
  NewTableForReviewApprovePeopleList = 'release-ui-payroll-new-table-component-review-approve-people-list',
  ShowAdjustmentsNewTable = 'release-ui-show-adjustments-table-component',
  PeopleArchiveEmployee = 'people-archive-employee',
  enablePersistSettingsOnPOEChange = 'PoEChangeForNotPaidOnboardingEmployee',
  newMinMaxDateValidationLogic = 'ui-new-date-validation-logic',
  newValidationSchemaForCreatePaygroups = 'ui-rectify-validation-for-create-paygroup',
  NewLayoutForPayrollFlow = 'new-layout-for-payroll-flow-executor',
  EnableWpTableForNotifications = 'enable-wp-table-for-notifications',
  EnableNewMarkerIOProject = 'ui-enable-new-marker-io-project',
  ReleaseZendeskChat = 'kill-ui-zendesk-chat-integration',
  EnableSubscriptionPlanUpgradeDowngrade = 'enable-subscription-plan-upgrade-and-downgrade',
  ShowPreferencesNotificationsNewTable = 'release-ui-show-preferences-notifications-table-component',
  EnableNewLogicForPaygroupLastDaySelection = 'revert-logic-for-last-day-of-month',
  EnablePayrollTaxFieldTrouser = 'release-ui-payroll-enable-tax-edit-fields',
  ShowChangePlanNewFeatureBanner = 'show-change-plan-new-feature-banner',
  EnableNewBCEHTRate = 'release-ui-new-bc-eht-rate',
  EnablePaymentTypeInPersisPerson = 'feature-add-payment-type-in-persist-person',
  EnableWpTableForIncomes = 'ui-wptable-incomes',
  ShowFirstPayrollsRunTable = 'release-ui-show-new-first-payrolls-to-run-table',
  ShowClientInvoiceListNewTable = 'release-ui-client-show-new-invoice-list-table-component',
  DisableEiExceedsLimit = 'ui-disable-ei-exceeds-annual-limit',
  EnableResetFixPadAgreement = 'enable-reactivate-flow-pad-agreement-reset-fix',
  EnableADAIntegation = 'ada-integration-app',
  EnableNewADAarchitecture = 'ui-enable-new-approach-for-ada-chat',
  /** auto admin assignment */
  RestrictPersistAPIForRehire = 'restrict-persist-api-for-rehire',
  EnableTaxValidationSubtractingYtd = 'enable-tax-validation-subtracting-ytd',
  NewSourceDeductionsFlow = 'ui-new-source-deductions-flow',
  EncodeCompanyName = 'enable-companyname-encode-on-view-client',
  EnableAutoAddTimeEmployeesFeature = 'auto_add_time_emploeyees',
  AutoAddTimeFeatureBanner = 'auto-add-time-feature-banner',
  AutoLoginWelcomeModalFix = 'enable-auto-login-welcome-modal-twice-fix',
  /** auto admin assignment */
  EnableAdminAssignment = 'PartnerDashboardAutoAssignAdmin',
  EnableUpgradeClient = 'feature-upgrade-clients-from-old-wp',
  EnableWidthRemoveFromReportsPdf = 'enable-width-remove-from-reports-pdf',
  EnableNewMigrationDesignPaygroup = 'enhancement-migration-paygroup-content-131',
  showUnlockFullPotential = 'enhancement-migration-unlock-full-potential',
  HidePADUploadFeature = 'ui-migration-hide-upload-download-pad',
  NewLogicToFetchAS = 'ui-migration-authorized-signatory-fetch-logic',
  EnableDisconnectForInvitedClients = 'enable-disconnect-for-invited-clients',
  EnablePeopleAddon = 'ui-release-show-people-addon',
  RemoveInvitationIdLogicForPartnerOverview = 'partner-remove-invitationid-logic-on-overview',
  // release 14 -hotfix 20th
  PadAgreementConditionRemover = 'ui-release-pad-agreement-condition-removal',
  KillMigrationUnwantedRedirection = 'ui-kill-migration-unwanted-redirection-user-accounts',
  ReleasePartnerUpgrade = 'ui-release-partner-upgrade',
  MigratedPartnerAccountVerificationSpecialCondition = 'ui-migration-account-verification-anomaly-for-migrated-partner',
  EnableEmployeeHoursFromTime = 'import_time_hours_diagnostic',
  EnableMigrationDontshowFlag = 'ui-migration-enable-dontshow-flag-exclusive-for-migration',
  //client-directory Migration UI updates
  EnableMigrationUpdatesForClientDirectory = 'ui-partner-client-directory-migration-updates',
  EnableEstHireDateFix = 'enable-est-hiredate-fix',
  EnableMigrationAccountingConsent = 'ui-migration-accountant-consent',
  EnableWrapperConditionForMigration = 'ui-migration-enable-wrapper-condition-for-migrated-company',
  EnableLeaveModal = 'ui-migration-leave-modal-for-esign',
  EnableMigrationFirstPayrollSelection = 'enable-migration-first-payroll-selection',
  RemoveValidationForNoWcbValue = 'ui-release-prior-payroll-disable-validation-for-wcb-value',
  EnablePayStubMemoDeadEnd = 'enable-paystub-memo-dead-end',
  EnablePayrollGridsCodeReset = 'enable-payroll-grid-codes-reset',
  FixForEnablingSignatoryAfterClientMigration = 'ui-migration-client-sign-pad-show-editable-signatory',
  EnableAnnualPayrollReport = 'annual-payroll-report',
  EnableCustomPayperiodSelectionForMigratedCompany = 'ui-populate-payperiod-dates-for-migrated-company',
  HideReportABugMenu = 'hide-report-a-bug-menu',
  EnableHeapNewSignupDate = 'heap-enable-new-company-sigup-date-key',
  EnableAutoSavePAD = 'AutoSavePad',
  RestrictTransferOwnershitAO = 'allow-transfer-owner-ao',
  RemoveMigrationDependencyFromResentInvite = 'ui-resent-invite-remove-migration-dependency',
  EnablePrintPreviewPayrollRegisterNew = 'ui-enable-new-payroll-register-print',
  EnablePrettyPrintPayrollRegisterNew = 'ui-pretty-print-payroll-register',
}
