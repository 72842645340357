import { Post } from 'services/httpClient';
import {
  IGetCompanySettingsInitialStateRequest,
  IGetCompanySettingsInitialStateResponse,
  IPersistCompanySettingsRequest,
  IPersistCompanySettingsResponse,
  IPersistLogoIdRequest,
  IPersistLogoIdResponse,
  IGetCompanyNotificationsSettingsRequest,
  IGetCompanyNotificationsSettingsResponse,
  IPersistCompanyNotificationsSettingsRequest,
  IPersistCompanyNotificationsSettingsResponse,
} from './companySettingsAccess.contracts';

const servicedomain = '/appcorecompanysettingsaccess/';
const GetCompanySettingsInitialStateUrl = servicedomain + 'getcompanysettings';
const PersistCompanySettingsUrl = servicedomain + 'persistcompanysettings';
const PersistLogoIdUrl = servicedomain + 'persistlogoid';
const GetCompanyNotificationSettingsUrl = servicedomain + 'getcompanynotificationsettings';
const PersistCompanyNotificationSettingsUrl = servicedomain + 'persistcompanynotificationsettings';

export const GetCompanySettingsInitialState = (
  request: IGetCompanySettingsInitialStateRequest
): Promise<IGetCompanySettingsInitialStateResponse> => {
  return Post(GetCompanySettingsInitialStateUrl, request);
};

export const PersistCompanySettings = (
  request: IPersistCompanySettingsRequest
): Promise<IPersistCompanySettingsResponse> => {
  return Post(PersistCompanySettingsUrl, request);
};

export const PersistLogoId = (request: IPersistLogoIdRequest): Promise<IPersistLogoIdResponse> => {
  return Post(PersistLogoIdUrl, request);
};

export const GetCompanyNotificationSettings = (
  request: IGetCompanyNotificationsSettingsRequest
): Promise<IGetCompanyNotificationsSettingsResponse> => {
  return Post(GetCompanyNotificationSettingsUrl, request);
};

export const PersistCompanyNotificationSettings = (
  request: IPersistCompanyNotificationsSettingsRequest
): Promise<IPersistCompanyNotificationsSettingsResponse> => {
  return Post(PersistCompanyNotificationSettingsUrl, request);
};
