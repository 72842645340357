import { useFeatureFlag } from 'LDfeatureFlag';
import { FEATURE_FLAG_KEYS } from 'LDfeatureFlag/constants';

import companyLinkAccess from 'services/finance/models/access/companyLinkAccess';
import billingAccess from 'services/finance/models/access/billingAccess';
import { useDateValidationsNew } from 'util/customHooks/useDateValidations';
import { useDateValidations } from 'util/customHooks/useDateValidationsDeprecated';

export const useGetFinanceCompanyLink = () => {
  const { feature } = useFeatureFlag();
  return feature(FEATURE_FLAG_KEYS.EnableBillingAccessMaintainClientPartnerInvitation)
    ? billingAccess
    : companyLinkAccess;
};

export const useNewRefactoredAppVersion = () => {
  const { feature } = useFeatureFlag();
  return feature(FEATURE_FLAG_KEYS.EnhancedPerformanceRefactor);
};

export const useNewMinMaxDateValidation = () => {
  const { feature } = useFeatureFlag();
  const newMinMaxLogic = feature(FEATURE_FLAG_KEYS.newMinMaxDateValidationLogic);
  return newMinMaxLogic ? useDateValidationsNew : useDateValidations;
};
