import { useQuery } from '@tanstack/react-query';

import { useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId, selectUserId } from 'redux/slice/configSlice';

import partnerAccess from 'services/appCore/models/access/partnerAccess';
import { createQueryKeys } from 'util/queryKeyFactory';

export const checkBelongingToAdminQueryKeys = createQueryKeys(
  ['accounting-dashboard', 'client-directory', 'belong-to-admin'],
  {
    belongToAdmin: (partnerId: number, userId: number) => ({
      key: [partnerId, userId],
    }),
  }
);

export const useCheckBelongingToAdmin = (clientId: number) => {
  const selectedCompanyId = useAppSelector(selectCompanyId);
  const userId = useAppSelector(selectUserId);

  const { data } = useQuery({
    queryKey: checkBelongingToAdminQueryKeys.belongToAdmin(selectedCompanyId, userId).key,
    queryFn: () =>
      partnerAccess.getUser({
        partnerId: selectedCompanyId,
        userId,
        includeClientList: true,
        includePermission: false,
        includeSecurityRoleList: false,
        includeClientAcceptedInviteOnly: false,
      }),
    enabled: !!userId && !!selectedCompanyId,
    retry: false,
  });

  return data?.user?.clientList.some((client) => client.id === clientId) ?? false;
};
