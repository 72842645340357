import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface IPayrollSettingInitStates {
  incomesList: any;
}

const initialState: IPayrollSettingInitStates = {
  incomesList: [],
};

export const payrollSettingSlice = createSlice({
  name: 'payrollsettingSlice',
  initialState,
  reducers: {
    setIncomeList: (state, action) => {
      state.incomesList = action.payload;
    },
  },
});

export const { setIncomeList } = payrollSettingSlice.actions;
export const payrollSettingsReducer = payrollSettingSlice.reducer;
export const selectPayrollSettingsIncomeList = (state: RootState) => state.payrollSettingsReducer.incomesList;
