import {
  IPermissionUi,
  IChildObject,
  IFlattened,
  IPermissionSubCategory,
  IPermissionCategory,
} from '../interface/flattenPermissionsInterface';

/* 
 Create a flatten object for editing permissions

 Sample Input:
 [{{id:19,},{id:76, parentSubcategoryId:19,...}}]

 Sample Output:
   "19": {
    "permissionType": 1,
    "child":[76],
    "isDisabled":{"1":false,"2":"false","3":"false"}
  },
*/

import { PermissionAccessTypeEnum } from '../enum/flattenPermissionsEnum';

export const flattenObject = (originalJson: Array<IPermissionCategory>) => {
  const flattenedObj: IFlattened = {};
  const childObj: IChildObject = {};

  originalJson.forEach((obj: IPermissionCategory) => {
    obj.permissionSubCategoryList.forEach((subCat: IPermissionSubCategory) => {
      if (subCat.permissionList) {
        subCat.permissionList.forEach((permission: IPermissionUi) => {
          if (permission.isSelected) {
            flattenedObj[subCat.id] = {
              permissionType: permission.permissionType,
              isDisabled: {
                [PermissionAccessTypeEnum.Write]: false,
                [PermissionAccessTypeEnum.Read]: false,
                [PermissionAccessTypeEnum.Noaccess]: false,
              },
            };
            if (subCat.parentSubCategoryId) {
              if (!childObj[subCat.parentSubCategoryId]) {
                childObj[subCat.parentSubCategoryId] = [];
              }
              childObj[subCat.parentSubCategoryId].push(subCat.id);
            }
          }
        });
      }
    });
  });

  Object.keys(childObj).forEach((parentId: string) => {
    if (flattenedObj[parentId]) {
      flattenedObj[parentId]['child'] = childObj[parentId];
    }
  });

  return flattenedObj;
};
