import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { GetContentRequest, GetContentResponse } from 'services/document-access/documentAccess.contracts';
import { GetDocumentContent } from 'services/document-access/documentAccess.service';
import { IValidationResults } from 'services/shared/validationResults.contracts';

interface IDocumentContent {
  isValidating: boolean;
  isReady: boolean;
  error: string | unknown;
  profilePicture: string;
  validationResults?: IValidationResults;
}

const initialState: IDocumentContent = {
  isValidating: false,
  isReady: false,
  error: '',
  profilePicture: '',
};

export const getDocumentContent = createAsyncThunk(
  'documentAccess/getDocumentContent',
  async (request: GetContentRequest) => {
    const response: GetContentResponse = await GetDocumentContent(request);
    return response;
  }
);

export const documentAccessSlice = createSlice({
  name: 'documentAccessSlice/getDocumentContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentContent.pending, (state) => {
        state.isValidating = true;
      })
      .addCase(getDocumentContent.fulfilled, (state, action) => {
        state.profilePicture = 'data:image/png;base64,' + action.payload.documentContent;
        state.validationResults = action.payload.validationResults;
        state.isValidating = false;
      })
      .addCase(getDocumentContent.rejected, (state, action) => {
        state.error = action.payload;
        state.isValidating = false;
      });
  },
});

export const documentAccessReducer = documentAccessSlice.reducer;
export const selectDocumentAccess = (state: RootState) => state.documentAccessReducer;
export const selectDocumentAccessIsValidating = (state: RootState) => state.documentAccessReducer.isValidating;
export const selectDocumentAccessProfilePicture = (state: RootState) => state.documentAccessReducer.profilePicture;
