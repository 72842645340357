import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetPayGroupListRequest,
  IGetPayGroupListResponse,
  IPayGroup,
  IPayGroupDetail,
  IPayGroupFrequency,
  IPayPeriodInterface,
  StandardHoursPerWeekValueEnum,
} from 'services/paygroup-access/paygroupAccess.contracts';
import { GetPayGroupList } from 'services/paygroup-access/paygroupAccess.service';
import { RootState } from 'redux/store/store';

type IMenuItems = {
  key: string;
  value: string;
};

export enum TotalPayperiod {
  default = 52,
}
export enum PayperiodDifference {
  weekly = 53,
  biWeekly = 27,
}

interface CreatePaygroupForm {
  frequency?: number;
  frequencyList?: IPayGroupFrequency[];
  currentFrequencyId?: number;
  selectedPayDate?: string | null;
  perPayPeriodHours?: number;
}

interface INonBoolsPaygroup {
  totalPayPeriodCount: number;
  totalPayperiods: number;
}
export interface IPayGroupSlice extends INonBoolsPaygroup {
  isValidating: boolean;
  isReady: boolean;
  error: string | unknown;
  menuItems: IMenuItems[];
  customizedHours: {
    otherValue: string | number;
    hoursPerPayperiod: string | number;
  };
  modalStates: {
    frequencyModal: boolean;
  };
  createPaygroupForm: CreatePaygroupForm;
  isFieldEdiable: boolean;
  currentPaygroup: null | IPayGroupDetail;
  payGroupData?: IPayGroup;
  payGroupCalander: IPayPeriodInterface[] | [];
  hasRegularPayrollRun?: boolean;
}

const paygroupFormInit = {
  frequency: 0,
  frequencyList: [],
  currentFrequencyId: 0,
  selectedPayDate: '',
  perPayPeriodHours: 0,
};
const initialState: IPayGroupSlice = {
  isValidating: false,
  isReady: false,
  error: '',
  menuItems: [],
  payGroupData: undefined,
  modalStates: {
    frequencyModal: false,
  },
  customizedHours: {
    otherValue: '',
    hoursPerPayperiod: StandardHoursPerWeekValueEnum.NotDefined,
  },
  isFieldEdiable: false,
  createPaygroupForm: { ...paygroupFormInit },
  totalPayPeriodCount: 0,
  totalPayperiods: TotalPayperiod.default,
  currentPaygroup: null,
  payGroupCalander: [],
  hasRegularPayrollRun: false,
};

export interface ICreatePaygroupAction {
  value: any;
  key: keyof IPayGroupSlice['createPaygroupForm'];
}
export type ICreatePayBulkgroupAction = {
  [Key in keyof CreatePaygroupForm]: any;
};

export const getPayGroupListAction = createAsyncThunk(
  'payrollpaygroup/getPayGroupListAction',
  async (request: IGetPayGroupListRequest) => {
    const response: IGetPayGroupListResponse = await GetPayGroupList(request);
    return response;
  }
);

export const getPayGroupListSlice = createSlice({
  name: 'payrollpaygroup/getPayGroupList',
  initialState,
  reducers: {
    setCustomHours: (
      state,
      action: PayloadAction<{ keyName: keyof IPayGroupSlice['customizedHours']; value: number | string }>
    ) => {
      state.customizedHours[action.payload.keyName] = action.payload.value;
    },
    setPaygroupViewStates: (state, action) => {
      state.currentPaygroup = action.payload;
    },
    setPaygroup: (state, action: PayloadAction<{ value: IPayGroup }>) => {
      state.payGroupData = action.payload.value;
    },
    setCreatePaygroupForm: (state, action: PayloadAction<ICreatePaygroupAction>) => {
      state.createPaygroupForm = { ...state.createPaygroupForm, [action.payload.key]: action.payload.value };
    },
    resetPaygroupForm: (state) => {
      state.createPaygroupForm = { ...paygroupFormInit };
      state.totalPayPeriodCount = 0;
    },
    resetPaygroupFormCount: (state) => {
      state.totalPayPeriodCount = 0;
    },
    setModalVisibility: (
      state,
      action: PayloadAction<{ key: keyof IPayGroupSlice['modalStates']; value: boolean }>
    ) => {
      state.modalStates = { ...state.modalStates, [action.payload.key]: action.payload.value };
    },
    setPaygroupState: (state, action: PayloadAction<{ key: 'isFieldEdiable'; value: boolean }>) => {
      state[action.payload.key] = action.payload.value;
    },
    setPaygroupStateObjects: (state, action: PayloadAction<{ key: keyof INonBoolsPaygroup; value: number }>) => {
      state[action.payload.key] = action.payload.value;
    },
    setBulkCreatePayForm: (state, action: PayloadAction<{ value: ICreatePayBulkgroupAction }>) => {
      state.createPaygroupForm = { ...state.createPaygroupForm, ...action.payload.value };
    },
    setPayGroupCalander: (state, action: PayloadAction<{ payPeriodList: IPayPeriodInterface[] }>) => {
      state.payGroupCalander = action.payload.payPeriodList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayGroupListAction.pending, (state) => {
        state.isValidating = true;
      })
      .addCase(getPayGroupListAction.fulfilled, (state, action) => {
        state.isValidating = false;
        state.error = '';
        state.isReady = true;
        if (action.payload.payGroupSummaryList && action.payload.payGroupSummaryList.length > 0) {
          state.menuItems = action.payload.payGroupSummaryList.map((list) => ({
            key: list.payGroupId.toString(),
            value: list.name,
          }));
          state.hasRegularPayrollRun = action.payload.payGroupSummaryList.some(
            (payGroup) => payGroup.hasRegularPayrollRun
          );
        }
      })
      .addCase(getPayGroupListAction.rejected, (state, action) => {
        state.error = action.payload;
        state.isValidating = false;
      });
  },
});
export const {
  setCustomHours,
  setPaygroupViewStates,
  setPaygroup,
  setCreatePaygroupForm,
  setModalVisibility,
  setPaygroupState,
  setBulkCreatePayForm,
  setPaygroupStateObjects,
  resetPaygroupForm,
  resetPaygroupFormCount,
  setPayGroupCalander,
} = getPayGroupListSlice.actions;

export const getPayGroupListReducer = getPayGroupListSlice.reducer;

export const selectHoursPerPayPeriod = ({ getPayGroupListReducer }: RootState) => {
  return getPayGroupListReducer.customizedHours.hoursPerPayperiod;
};
export const getCurrentPaygroupForView = ({ getPayGroupListReducer }: RootState) =>
  getPayGroupListReducer.currentPaygroup;

export const getTotalPayperiod = ({ getPayGroupListReducer }: RootState) => getPayGroupListReducer.totalPayperiods;

export const getPayGroup = ({ getPayGroupListReducer }: RootState) => getPayGroupListReducer.payGroupData;

export const selectCreateFormFrequency = ({
  getPayGroupListReducer: {
    createPaygroupForm: { frequency },
  },
}: RootState) => frequency;

export const selectCreateFormFrequencyList = ({
  getPayGroupListReducer: {
    createPaygroupForm: { frequencyList },
  },
}: RootState) => frequencyList;

export const selectCreateForm = ({ getPayGroupListReducer: { createPaygroupForm } }: RootState) => createPaygroupForm;

export const selectFrequencyModalVisibility = ({ getPayGroupListReducer: { modalStates } }: RootState) =>
  modalStates.frequencyModal;

export const selectFieldStateEdiable = ({ getPayGroupListReducer }: RootState) => getPayGroupListReducer.isFieldEdiable;
export const selectCurrentFrequencyId = ({ getPayGroupListReducer }: RootState) =>
  getPayGroupListReducer.createPaygroupForm.currentFrequencyId;
export const selectPerPayPeriodHours = ({ getPayGroupListReducer }: RootState) =>
  getPayGroupListReducer.createPaygroupForm.perPayPeriodHours;

export const selectTotalPayperiodCount = ({ getPayGroupListReducer }: RootState) =>
  getPayGroupListReducer.totalPayPeriodCount;

export const getPayPeriodCalander = ({ getPayGroupListReducer }: RootState) => getPayGroupListReducer.payGroupCalander;
export const selectHasRegularPayrollRun = ({ getPayGroupListReducer }: RootState) =>
  getPayGroupListReducer.hasRegularPayrollRun;
