import { Post } from 'services/httpClient';
import {
  GetContentRequest,
  GetContentResponse,
  DeleteDocumentListRequest,
  DeleteDocumentListResponse,
  ValidateDocumentRequest,
  ValidateDocumentResponse,
  ICroppedImageRequest,
  ICroppedImageResponse,
} from './documentAccess.contracts';
import { GetDocumentAsBlob } from '@wagepoint/ui-toolkit/components/wp-download/wpDownloadService';

const servicedomain = '/documentmanagementdocumentaccess/';
const GetDocumentContentUrl = servicedomain + 'getContent';
const DeleteDocumentListUrl = servicedomain + 'deleteDocumentList';

const ValidateDocumentUrl = '/documentmanagement/docs/validate';
const UploadDocumentUrl = '/documentmanagement/docs/upload';

export const GetDocumentContent = (request: GetContentRequest): Promise<GetContentResponse> => {
  return Post(GetDocumentContentUrl, request);
};

export const viewDocument = async (documentId: number) => {
  const response = await GetDocumentContent({
    documentId,
    includeContent: true,
    queryByDocumentId: true,
    includeDownloadUrl: true,
  });
  if (response) GetDocumentAsBlob(response.name, response.documentContent, response.mimeType || 'application/pdf');
};

export const DeleteDocumentList = (request: DeleteDocumentListRequest): Promise<DeleteDocumentListResponse> => {
  return Post(DeleteDocumentListUrl, request);
};

export const ValidateDocument = (request: ValidateDocumentRequest): Promise<ValidateDocumentResponse> => {
  return Post(ValidateDocumentUrl, request);
};

export const UploadCroppedImage = (request: ICroppedImageRequest): Promise<ICroppedImageResponse> => {
  const formData: any = new FormData();
  if (request.query) {
    formData.append('file', request.blob, request.blob.name || `${request.name}.pdf`);
    formData.append('flowChunkNumber', 1);
    formData.append('flowChunkSize', request.blob.size);
    formData.append('flowCurrentChunkSize', request.blob.size);
    formData.append('flowTotalSize', request.blob.size);
    formData.append('flowIdentifier', request.blob.size + '-' + request.blob.name || request.name);
    formData.append('flowRelativePath', request.blob.name || request.name);
    formData.append('flowTotalChunks', 1);

    const counter = Object.keys(request.query).length;
    for (let i = 0; i < counter; i++) {
      formData.append(Object.keys(request.query)[i], Object.values(request.query)[i]);
    }
  }
  return Post(UploadDocumentUrl, formData);
};
