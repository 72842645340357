import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

import WpToolTip from '@wagepoint/ui-toolkit/components/wp-tooltip/wpToolTip';

import { DialogTypeEnum } from '../../../assignedPeopleGroup';
import { useTranslation } from 'react-i18next';

/** TODO:NA add proper types */

export interface IAssignedRolesActionsProps {
  anchorId: any;
  open: any;
  handleClose: () => void;
  menuOptions: {
    display: DialogTypeEnum;
    onClick: OnMenuItemClick;
    label: string;
  }[];
  row: any;
}

type OnMenuItemClick = (row: any, dialogType: DialogTypeEnum, rowId: string) => void;

export const AssignedRolesActions: React.FC<IAssignedRolesActionsProps> = ({
  anchorId,
  open,
  handleClose,
  menuOptions,
  row,
}) => {
  const { t } = useTranslation('userAccounts');

  return (
    <div>
      <Popover
        id="edit-dropdwn"
        anchorEl={open}
        open={anchorId === row.id}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuOptions.slice(1).map((option, i) => (
          <React.Fragment key={i}>
            {option?.display === DialogTypeEnum.Disable ? (
              <WpToolTip
                title={t('userAccounts:assignedPeopleGroup.columns.tooltipForAdmin')}
                show={row.isPayrollApprover}
                arrow
                as="span"
              >
                <MenuItem
                  onClick={() => option.onClick(row, DialogTypeEnum.Disable, row.id)}
                  disabled={row.isPayrollApprover}
                >
                  {option.label}
                </MenuItem>
              </WpToolTip>
            ) : (
              <MenuItem onClick={() => option.onClick(row, DialogTypeEnum.CreateEmployeeProfile, row.id)}>
                {option.label}
              </MenuItem>
            )}
          </React.Fragment>
        ))}
      </Popover>
    </div>
  );
};
