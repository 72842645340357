import { useCallback } from 'react';

import { useAppDispatch } from 'redux/hooks/hook';
import { showLoading, hideLoading } from 'redux/slice/loadingIndicatorSlice';

export function useLoaders() {
  const dispatch = useAppDispatch();

  const showLoader = useCallback(() => dispatch(showLoading()), [dispatch]);
  const hideLoader = useCallback(() => dispatch(hideLoading()), [dispatch]);

  return {
    showLoader,
    hideLoader,
  };
}
