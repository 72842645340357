import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
// TODO: replace Typography with WpTypography from UI Toolkit.
// eslint-disable-next-line no-restricted-imports
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import { GlobalStyles } from 'styles/globalStyles';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { selectFirmIsEdit, selectFirmName, selectFirmViewFrom } from 'redux/slice/accountingFirmSlice';
import { useAppSelector } from 'redux/hooks/hook';

// TODO: replace with WpIcon
// eslint-disable-next-line no-restricted-imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons/faPencilAlt';
import { ManageViewOfAccountingFirm } from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    titleContainer: {
      display: 'flex',
    },
  })
);

interface IProps {
  updateEditValue?: () => void;
}

const TitleContainer: React.FC<IProps> = ({ updateEditValue }) => {
  const classes = useStyles();
  const globalClass = GlobalStyles();
  const { t } = useTranslation(['userAccounts']);
  const name = useAppSelector(selectFirmName);
  const isEdit = useAppSelector(selectFirmIsEdit);
  const from = useAppSelector(selectFirmViewFrom);

  const [showEditIcon, setEditIcon] = useState<boolean>(false);

  const switchToEdit = () => {
    setEditIcon(true);
    if (updateEditValue) updateEditValue();
  };

  return (
    <Box component="div" className={classes.titleContainer}>
      <Typography variant="h1" className={clsx(globalClass.padL0, classes.title)}>
        {t('firmPermissionsContainer.title', {
          name: name || t('firmPermissionsContainer.firmName1'),
        })}
      </Typography>
      {isEdit && !showEditIcon && from !== ManageViewOfAccountingFirm.FirmInviteSent && (
        <Grid item xs={4}>
          <WpButton
            // className={classes.editIconButton}
            onClick={switchToEdit}
            startIcon={
              <FontAwesomeIcon
                icon={faPencilAlt as IconProp}
                style={{
                  // TODO: replace value with theme.spacing
                  // eslint-disable-next-line no-restricted-syntax
                  marginRight: 5,
                }}
              />
            }
          >
            {t('userAccounts:permissionSettings.edit')}
          </WpButton>
        </Grid>
      )}
    </Box>
  );
};

export default TitleContainer;
