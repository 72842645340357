import React from 'react';

import { useTranslation } from 'react-i18next';

import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import Box from '@material-ui/core/Box';

import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';

import { StatementsContainerStyles } from './statementsStyles';
import { useTwoStepEnabled } from '../../permissionSettingsHook';

interface IDisabledPayrollAdminProps {
  maintainAdminCount?: number;
  currentRole?: SecurityRoleTypeEnum;
}

const payrollAdminCount = 1;

const DisabledPayrollAdminContainer: React.FC<IDisabledPayrollAdminProps> = ({ maintainAdminCount, currentRole }) => {
  const classes = StatementsContainerStyles();
  const { t } = useTranslation(['common', 'userAccounts']);

  const { isTwoStepApprovalEnabled } = useTwoStepEnabled();

  return !isTwoStepApprovalEnabled &&
    maintainAdminCount === payrollAdminCount &&
    currentRole === SecurityRoleTypeEnum.Administrator ? (
    <Box className={classes.statementContainer}>
      <WpStatements variant="highpriority" message={t('userAccounts:editView.payrollTooltip')} />
    </Box>
  ) : (
    <></>
  );
};

export default DisabledPayrollAdminContainer;
