import { AxiosRequestConfig } from 'axios';
import { Post } from 'services/httpClient';
import {
  ICompleteOnboardRequest,
  ICompleteOnboardResponse,
  IGetPersonJobInitialStateRequest,
  IGetPersonJobInitialStateResponse,
  IGetPersonSummaryListRequest,
  IGetPersonSummaryListResponse,
  IGetSalaryHistoryListRequest,
  IGetSalaryHistoryListResponse,
  IGetStateWageRequest,
  IGetStateWageResponse,
  IInvitePersonRequest,
  IInvitePersonResponse,
  IManagePersonJobStatusRequest,
  IManagePersonJobStatusResponse,
  IRehirePersonRequest,
  IRehirePersonResponse,
  IGetEmployeeTaxContributionRequestInterface,
  IGetEmployeeTaxContributionResponseInterface,
} from './payrollPersonOnboardAccess.contracts';

const url = (endPoint: string) => `payrollPersonOnboardAccess/${endPoint}`;

export const GetPersonSummaryList = (
  request: IGetPersonSummaryListRequest,
  config?: AxiosRequestConfig
): Promise<IGetPersonSummaryListResponse> => {
  return Post(url('getPersonSummaryList'), request, config);
};

export const GetStateWage = (request: IGetStateWageRequest): Promise<IGetStateWageResponse> => {
  return Post(url('GetStateWage'), request);
};

export const InvitePerson = (request: IInvitePersonRequest): Promise<IInvitePersonResponse> => {
  return Post(url('invitePerson'), request);
};

export const CompletePersonOboarding = (request: ICompleteOnboardRequest): Promise<ICompleteOnboardResponse> => {
  return Post(url('CompleteOnboard'), request);
};

export const GetPersonJobInitialState = (
  request: IGetPersonJobInitialStateRequest
): Promise<IGetPersonJobInitialStateResponse> => {
  return Post(url('GetPersonJobInitialState'), request);
};

export const ManagePersonJobStatus = (
  request: IManagePersonJobStatusRequest
): Promise<IManagePersonJobStatusResponse> => {
  return Post(url('ManagePersonJobStatus'), request);
};

export const RehirePerson = (request: IRehirePersonRequest): Promise<IRehirePersonResponse> => {
  return Post(url('RehirePerson'), request);
};

export const GetSalaryHistoryList = (request: IGetSalaryHistoryListRequest): Promise<IGetSalaryHistoryListResponse> => {
  return Post(url('getSalaryHistoryList'), request);
};

export const GetEmployeeTaxContribution = (
  request: IGetEmployeeTaxContributionRequestInterface
): Promise<IGetEmployeeTaxContributionResponseInterface> => {
  return Post('/PayrollPersonOnboardAccess/getEmployeeTaxContribution', request);
};
