import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGetTaxRateListRequest,
  ITaxRateList,
  IGetTaxRateListResponse,
  IGetMissingAccountsRequest,
  IGetMissingAccountsResponse,
  ICreateMissingAccountsRequest,
  ICreateMissingAccountsResponse,
  IGetQboMissingAccountsRequest,
  IGetQboMissingAccountsResponse,
  ICreateQboMissingAccountsRequest,
  ICreateQboMissingAccountsResponse,
} from 'services/integrations-access/integrationsAccess.contracts';
import {
  GetTaxRateList,
  GetMissingAccounts,
  CreateMissingAccounts,
  GetMissingAccountsQbo,
  CreateMissingAccountsQbo,
} from 'services/integrations-access/integrationsAccess.service';

interface IAllStatuses {
  fulfilled: boolean;
  loading: boolean;
  error?: string | unknown;
  taxRateList: Array<ITaxRateList>;
  isGetMissingAccountsValidating: boolean;
  getMissingAccountsFulfilled: boolean;
  getMissingAccountsError?: string | unknown;
  isCreateMissingAccountsValidating: boolean;
  createMissingAccountsFulfilled: boolean;
  createMissingAccountsError?: string | unknown;
  missingAccounts: string[];
}

const initialState: IAllStatuses = {
  fulfilled: false,
  taxRateList: [],
  loading: false,
  isGetMissingAccountsValidating: false,
  getMissingAccountsFulfilled: false,
  isCreateMissingAccountsValidating: false,
  createMissingAccountsFulfilled: false,
  missingAccounts: [],
};

export const getTaxRateList = createAsyncThunk('/addons/getTaxRateList', async (request: IGetTaxRateListRequest) => {
  const response: IGetTaxRateListResponse = await GetTaxRateList(request);
  return response;
});

export const getXeroMissingAccountsAction = createAsyncThunk(
  '/addons/getXeroMissingAccounts',
  async (request: IGetMissingAccountsRequest) => {
    const response: IGetMissingAccountsResponse = await GetMissingAccounts(request);
    return response;
  }
);

export const createXeroMissingAccountsAction = createAsyncThunk(
  '/addons/createXeroMissingAccounts',
  async (request: ICreateMissingAccountsRequest) => {
    const response: ICreateMissingAccountsResponse = await CreateMissingAccounts(request);
    return response;
  }
);

export const getQboMissingAccountsAction = createAsyncThunk(
  '/addons/getQboMissingAccounts',
  async (request: IGetQboMissingAccountsRequest) => {
    const response: IGetQboMissingAccountsResponse = await GetMissingAccountsQbo(request);
    return response;
  }
);

export const createQboMissingAccountsAction = createAsyncThunk(
  '/addons/createQboMissingAccounts',
  async (request: ICreateQboMissingAccountsRequest) => {
    const response: ICreateQboMissingAccountsResponse = await CreateMissingAccountsQbo(request);
    return response;
  }
);
function mutatleTaxRateList(lists: Array<ITaxRateList>) {
  return lists.map((list) => ({ ...list, id: list.taxType, name: list.taxType }));
}

export const getTaxRateListSlice = createSlice({
  name: 'addonsPayrollAccess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTaxRateList.pending, (state) => {
        state.fulfilled = false;
        state.loading = true;
      })
      .addCase(getTaxRateList.fulfilled, (state, action) => {
        state.fulfilled = true;
        state.taxRateList = mutatleTaxRateList(action.payload.taxRateList);
        state.loading = false;
      })
      .addCase(getTaxRateList.rejected, (state, action) => {
        state.fulfilled = false;
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getXeroMissingAccountsAction.pending, (state) => {
        state.getMissingAccountsFulfilled = false;
        state.isGetMissingAccountsValidating = true;
      })
      .addCase(getXeroMissingAccountsAction.fulfilled, (state, action) => {
        state.getMissingAccountsFulfilled = true;
        state.isGetMissingAccountsValidating = false;
        if (action.payload) {
          state.missingAccounts = action.payload.accountNames;
        }
      })
      .addCase(getXeroMissingAccountsAction.rejected, (state, action) => {
        state.isGetMissingAccountsValidating = false;
        state.getMissingAccountsError = action.payload;
      });

    builder
      .addCase(createXeroMissingAccountsAction.pending, (state) => {
        state.createMissingAccountsFulfilled = false;
        state.isCreateMissingAccountsValidating = true;
      })
      .addCase(createXeroMissingAccountsAction.fulfilled, (state) => {
        state.createMissingAccountsFulfilled = true;
        state.isCreateMissingAccountsValidating = false;
      })
      .addCase(createXeroMissingAccountsAction.rejected, (state, action) => {
        state.isCreateMissingAccountsValidating = false;
        state.createMissingAccountsError = action.payload;
      });

    builder
      .addCase(getQboMissingAccountsAction.pending, (state) => {
        state.getMissingAccountsFulfilled = false;
        state.isGetMissingAccountsValidating = true;
      })
      .addCase(getQboMissingAccountsAction.fulfilled, (state, action) => {
        state.getMissingAccountsFulfilled = true;
        state.isGetMissingAccountsValidating = false;
        if (action.payload) {
          state.missingAccounts = action.payload.accountNames;
        }
      })
      .addCase(getQboMissingAccountsAction.rejected, (state, action) => {
        state.isGetMissingAccountsValidating = false;
        state.getMissingAccountsError = action.payload;
      });

    builder
      .addCase(createQboMissingAccountsAction.pending, (state) => {
        state.createMissingAccountsFulfilled = false;
        state.isCreateMissingAccountsValidating = true;
      })
      .addCase(createQboMissingAccountsAction.fulfilled, (state) => {
        state.createMissingAccountsFulfilled = true;
        state.isCreateMissingAccountsValidating = false;
      })
      .addCase(createQboMissingAccountsAction.rejected, (state, action) => {
        state.isCreateMissingAccountsValidating = false;
        state.createMissingAccountsError = action.payload;
      });
  },
});

export const getTaxRateListReducer = getTaxRateListSlice.reducer;
