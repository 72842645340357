import {
  IPermissionServiceCategory,
  IPermissionServiceSubCategory,
  IPermissionService,
  IFlattened,
} from '../interface/flattenPermissionsInterface';

import { PermissionAccessTypeEnum } from '../enum/flattenPermissionsEnum';
import { PermissionsEnum } from 'services/shared/permission.contracts';

/*
Sample Input:
permissionStructure
[{..., permissionList:[{...read,isSelected:false}, {...write,isSelected:true}, {...noaccess,isSelected:false}]}]

permissionUpdate
  "19": {
    "permissionType": 1,
    "child":[76],
    "isDisabled":{"1":false,"2":"false","3":"false"}
  },

  Sample Output:
[{..., permissionList:[{...read, isSelected:true}, {...write,isSelected:false}]}]

*/

export const updateServicePermissionStructure = (
  permissionStructure: IPermissionServiceCategory,
  permissionUpdate: IFlattened
): IPermissionServiceCategory[] => {
  const newPermissionStructure = JSON.parse(JSON.stringify(permissionStructure));

  /** Alfred permissions - will break logic, we don't need that permissions in UI repo
   * Before sending the data to service, we are removing that permissions - CONFIRMED WITH SERVICE DEV [KP]
   */
  const indexToRemove = newPermissionStructure.findIndex(
    (permissionCategory: IPermissionServiceCategory) => permissionCategory.id === PermissionsEnum.Alfred
  );

  if (indexToRemove !== -1) {
    newPermissionStructure.splice(indexToRemove, 1);
  }

  return newPermissionStructure.map((permissionCategory: IPermissionServiceCategory) => {
    permissionCategory.permissionSubCategoryList.forEach((permissionSubCategory: IPermissionServiceSubCategory) => {
      permissionSubCategory.permissionList = permissionSubCategory.permissionList.filter(
        (permission: IPermissionService) => permission.permissionType !== PermissionAccessTypeEnum.Noaccess
      );

      permissionSubCategory.permissionList.forEach((permission: IPermissionService) => {
        if (permission.permissionType === Number(permissionUpdate[permissionSubCategory.id]?.permissionType)) {
          permission.isSelected = true;
        } else {
          permission.isSelected = false;
        }

        delete permission.permissionType;
      });
    });

    return permissionCategory;
  });
};
