import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
// TODO: replace Typography with WpTypography from UI Toolkit.
// eslint-disable-next-line no-restricted-imports
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { GlobalStyles } from 'styles/globalStyles';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { selectAccountingAssignedRole } from 'redux/slice/userAccountsSlice';
import { selectFirmCompanyId, selectFirmViewFrom } from 'redux/slice/accountingFirmSlice';
import { ManageViewOfAccountingFirm } from '../../index';
import clsx from 'clsx';
import companyAccess from 'services/finance/models/access/companyAccess';
import { useAdministratorRoleName } from 'pages/accounting-dashboard/client-directory/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      padding: 24,
      borderRadius: 10,
      border: `1px solid ${theme.palette.primarycolor.primaryLightBrown}`,
    },
  })
);

interface IProps {
  edit?: number;
}

const CardContainer: React.VFC<IProps> = () => {
  const classes = useStyles();
  const globalClass = GlobalStyles();
  const { t } = useTranslation(['userAccounts']);

  const companyId = useAppSelector(selectFirmCompanyId);
  const from = useAppSelector(selectFirmViewFrom);

  const { getRoleName } = useAdministratorRoleName();

  const assignedRole = useAppSelector(selectAccountingAssignedRole);
  const selectedCompanyId = useAppSelector(selectCompanyId);

  const [role, setRole] = useState('');
  const [assignedUserDetails, setAssignedUserDetails] = useState(0);

  const getAssignedUserDetails = async () => {
    if (from === ManageViewOfAccountingFirm.ZeroState) {
      setRole(getRoleName(assignedRole));
    } else {
      const response = await companyAccess.getPartnerCompany({
        clientId: selectedCompanyId,
        partnerId: companyId ?? 0,
      });
      const role = getRoleName(response?.companySummary?.securityRoleTypeId);
      setRole(role);
      setAssignedUserDetails(response?.companySummary?.assignedAdminCount);
    }
  };

  useEffect(() => {
    getAssignedUserDetails();
  }, []);

  return (
    <>
      <Typography variant="h2" className={clsx(globalClass.ptl0, globalClass.padB8)}>
        {t('firmPermissionsContainer.details')}
      </Typography>

      <Grid container>
        <Grid item xs={5}>
          <Card className={classes.cardContainer}>
            <Box className={globalClass.padB8}>
              <Typography variant="body2">{t('common.role')}</Typography>
              <Typography>{role}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">{t('firmPermissionsContainer.assigned')}</Typography>
              <Typography>{assignedUserDetails || '...'}</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CardContainer;
