import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface IChartOfAccountsState {
  isSubmitClicked: boolean;
  isMappingDropdownsValid: boolean;
}

const initialState: IChartOfAccountsState = {
  isSubmitClicked: false,
  isMappingDropdownsValid: false,
};

export const chartOfAccountsSlice = createSlice({
  name: 'addons/common/chartOfAccounts',
  initialState,
  reducers: {
    setIsSubmitClicked(state, action: PayloadAction<boolean>) {
      state.isSubmitClicked = action.payload;
    },
    setIsMappingDropdownsValid(state, action: PayloadAction<boolean>) {
      state.isMappingDropdownsValid = action.payload;
    },
  },
});

export const chartOfAccountsSliceReducer = chartOfAccountsSlice.reducer;
export const chartOfAccountsActions = chartOfAccountsSlice.actions;

export const selectIsSubmitClicked = (state: RootState) => state.chartOfAccountsSliceReducer.isSubmitClicked;
export const selectIsMappingDropdownsValid = (state: RootState) =>
  state.chartOfAccountsSliceReducer.isMappingDropdownsValid;
