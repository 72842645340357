import { Post } from 'services/httpClient';
import {
  IPersistPersonBankAccountRequest,
  IPersistPersonBankAccountResponse,
  IGetPersonBankAccountListRequest,
  IGetPersonBankAccountListResponse,
  IChangePersonBankRequest,
  IChangePersonBankResponse,
} from './personBankAccess.contracts';

const servicedomain = '/financepersonbankaccess/';
const persistPersonBank = servicedomain + 'persistPersonBankAccount';
const getPersonBankList = servicedomain + 'getPersonBankAccountList';
const changePersonBankStatusUrl = servicedomain + 'ChangePersonBankAccountStatus';
export const persistBankPerson = (
  request: IPersistPersonBankAccountRequest
): Promise<IPersistPersonBankAccountResponse> => {
  return Post(persistPersonBank, request);
};
export const getBankPersonList = (
  request: IGetPersonBankAccountListRequest
): Promise<IGetPersonBankAccountListResponse> => {
  return Post(getPersonBankList, request);
};

export const changePersonBankStatus = (request: IChangePersonBankRequest): Promise<IChangePersonBankResponse> => {
  return Post(changePersonBankStatusUrl, request);
};
