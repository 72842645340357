/**
 * Why do we need this?
 * Its to keep all the related shared services inside a file so that it easier to maintain for any changes in UI.shared-libs.
 * Relatively simple to change name / path if we decide to modify paths in shared library.
 */
import PayrollUiConfigAccess, {
  GetConfigurationRequestInterface,
  GetConfigurationResponseInterface,
  UserDetailInterface,
} from '@wagepoint/ui-shared-library/shared-system/services/payroll/models/access/uiConfigAccess';

import { IAuthUserConfig } from '@wagepoint/ui-shared-library/shared-system/services/payroll/models/access/extra.contracts';
import { ApplicationTypeEnum, CultureTypeEnum } from 'shared/services/appCore/schema';

export type GetConfigRequestInterface = GetConfigurationRequestInterface;
export type UserDetail = UserDetailInterface;
export type IAuthUserConfiguration = IAuthUserConfig;
export { PayrollUiConfigAccess, CultureTypeEnum, ApplicationTypeEnum, type GetConfigurationResponseInterface };
export { type SubscriptionPeriodInterface } from '@wagepoint/ui-shared-library/shared-system/services/payroll/models/access/uiConfigAccess';
