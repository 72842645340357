import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

/* START --------- Onboarding */
import {
  IGetPersonRequest,
  IGetPersonResponse,
  IInvitePersonRequest,
  IInvitePersonResponse,
  IManagePersonStatusRequest,
  IManagePersonStatusResponse,
  IPersistEmployementStateRequest,
  IPersistEmployementStateResponse,
  IPersistPersonRequest,
  IPersistPersonResponse,
  IPersistProfileDocumentRequest,
  IPersistProfileDocumentResponse,
} from 'services/person-access/personAccess.contracts';
import {
  getPerson as getPersonUrl,
  InvitePerson,
  ManagePersonStatus,
  persistEmploymentState,
  persistPerson,
  savePersonDoc,
} from 'services/person-access/personAccess.service';
import {
  IGetPersonBankAccountListRequest,
  IGetPersonBankAccountListResponse,
  IPersistPersonBankAccountRequest,
  IPersistPersonBankAccountResponse,
} from 'services/person-bank-access/personBankAccess.contracts';
import {
  getBankPersonList as getBankPersonListUrl,
  persistBankPerson as persistBankPersonUrl,
} from 'services/person-bank-access/personBankAccess.service';

/* Payroll START */
import {
  IGetPayGroupListRequest,
  IGetPayGroupListResponse,
  IGetPayGroupRequest,
  IGetPayGroupResponse,
} from 'services/paygroup-access/paygroupAccess.contracts';
import { GetPayGroup, GetPayGroupList as getPayGroupListURL } from 'services/paygroup-access/paygroupAccess.service';
import {
  GetWcbRateListRequest,
  GetWcbRateListResponse,
} from 'services/workers-compensation-access/workersCompensationAccess.contracts';
import { GetWcbRateList } from 'services/workers-compensation-access/workersCompensationAccess.service';
import { GetDocumentContent } from 'services/document-access/documentAccess.service';
/* Payroll END */
/* Job Access START */
import {
  ICreateDefaultPersonJobRequest,
  ICreateDefaultPersonJobResponse,
  IGetDepartmentListRequest,
  IGetDepartmentListResponse,
  IGetJobListRequest,
  IGetJobListResponse,
  IGetPersonJobListRequest,
  IGetPersonJobListResponse,
  IPersistDepartmentRequest,
  IPersistDepartmentResponse,
  IPersistJobRequest,
  IPersistJobResponse,
  IPersistPersonJobRequest,
  IPersistPersonJobResponse,
  PersistDepartmentRequestEnum,
  PersistJobRequestEnum,
} from 'services/job-access/jobAccess.contracts';
import {
  IGetPayPeriodDetailRequest,
  IGetPayPeriodDetailResponse,
  IGetPayrollRequest,
  IGetPayrollResponse,
  IPersistPayrollRequest,
  IPersistPayrollResponse,
} from 'services/priorpayroll-access/priorpayrollAccess.contracts';
/* Person IDB END */
/* Prior Payroll START */
import {
  GetPayPeriodDetail,
  getPriorPayroll,
  persistPriorPayroll,
} from 'services/priorpayroll-access/priorpayrollAccess.service';

import {
  CreateDefaultPersonJob,
  GetDepartment,
  GetJob,
  GetPersonJobList as GetPersonJobListUrl,
  PersistDepartment,
  PersistJob,
  PersistPersonJob,
} from 'services/job-access/jobAccess.service';
import { GetContentRequest, GetContentResponse } from 'services/document-access/documentAccess.contracts';
/* Job Access END */
/* Person Tax START */
import {
  getInitialStatePersonTax,
  getTaxDetail,
  getTaxExemptionCodeList,
  persistSettings,
} from 'services/person-tax-access/personTaxAccess.service';
import {
  IGetInitialStateRequest as IGetTaxInitialStateRequest,
  IGetInitialStateResponse as IGetTaxInitialStateResponse,
  IGetTaxDetailRequest,
  IGetTaxDetailResponse,
  IGetTaxExemptionCodeListRequest,
  IGetTaxExemptionCodeListResponse,
  IPersistSettingsRequest,
  IPersistSettingsResponse,
} from 'services/person-tax-access/personTaxAccess.contracts';
/* Person Tax END */
/* Onboarding First Time Users  START*/
import {
  GetOnboardingPreferencesList,
  GetOnboardingStepsList,
  GetSelfOnboardingFieldGroupList,
  PersistOnboardingPreferences,
} from 'services/onboard-question-access/onboardQuestion.service';
import {
  EmployeeOnboardingStepTypeEnum,
  EmployeeOnboardingTypeEnum,
  IGetOnboardingPreferencesListRequest,
  IGetOnboardingPreferencesListResponse,
  IGetOnboardingStepsListRequest,
  IGetOnboardingStepsListResponse,
  IGetSelfOnboardingFieldGroupListRequest,
  IGetSelfOnboardingFieldGroupListResponse,
  IPersistOnboardingPreferencesRequest,
  IPersistOnboardingPreferencesResponse,
  OnboardingStepStatusTypeEnum,
} from 'services/onboard-question-access/onboardQuestion.contracts';
/* Onboarding First Time Users  END*/
/* Person IDB START */
import {
  GetEmployeeFieldSummaryList,
  GetFieldList,
  GetSaleTaxList,
  PersistEmployeeFieldList,
} from 'services/employee-field-access/employeeFieldAccess.service';

import {
  FieldTypeEnum,
  IGetEmployeeFieldSummaryListRequest,
  IGetEmployeeFieldSummaryListResponse,
  IGetFieldListRequest,
  IGetFieldListResponse,
  IGetSaleTaxListRequest,
  IGetSaleTaxListResponse,
  PersistEmployeeFieldListRequest,
  PersistEmployeeFieldListResponse,
} from 'services/employee-field-access/employeeFieldAccess.contracts';
/* Prior Payroll END */
/* END ----------- Onboarding */
import { PeopleType } from '../peopleSlice';
import { GetLocationList } from 'services/company-access/companyAccess.service';
import { GetLocationListRequest, GetLocationListResponse } from 'services/company-access/companyAccess.contracts';
import { IValidationResults } from 'services/shared/validationResults.contracts';
/* Calling Services START */
const dummyCompanyWrongId = 1;
const savePerson = createAsyncThunk('onboard/myinfo/savePerson', async (request: IPersistPersonRequest) => {
  const response: IPersistPersonResponse = await persistPerson(request);
  return response;
});
const saveDD = createAsyncThunk('onboard/myinfo/saveDD', async (request: IPersistPersonBankAccountRequest) => {
  const response: IPersistPersonBankAccountResponse = await persistBankPersonUrl(request);
  return response;
});
const savePreviousProvince = createAsyncThunk(
  'onboard/myinfo/savePreviousProvince',
  async (request: IPersistEmployementStateRequest) => {
    const response: IPersistEmployementStateResponse = await persistEmploymentState(request);
    return response;
  }
);
const saveProfileDocument = createAsyncThunk(
  'onboard/myinfo/saveProfileDocument',
  async (request: IPersistProfileDocumentRequest) => {
    const response: IPersistProfileDocumentResponse = await savePersonDoc(request);
    return response;
  }
);
export const getProfileDocument = createAsyncThunk(
  'onboard/myinfo/getProfileDocument',
  async (request: GetContentRequest) => {
    const response: GetContentResponse = await GetDocumentContent(request);
    return response;
  }
);
/* Job Access Management START */
interface IPersistPersonJobReq extends IPersistPersonJobRequest {
  noNextOnboard?: boolean /* This is because to avoid nextOnboard, while terminated. Because to avoid double skip to next step */;
}
const savePersonJobList = createAsyncThunk(
  'onboard/myinfo/savePersonJobList',
  async (request: IPersistPersonJobReq) => {
    const { noNextOnboard, ...otherRequest } = request;
    const response: IPersistPersonJobResponse = await PersistPersonJob(otherRequest);
    return response;
  }
);
const createDefaultPersonJob = createAsyncThunk(
  'onboard/myinfo/createdefaultpersonjob',
  async (request: ICreateDefaultPersonJobRequest) => {
    const response: ICreateDefaultPersonJobResponse = await CreateDefaultPersonJob(request);
    return response;
  }
);
interface IPersistJobReq extends IPersistJobRequest {
  successmsg?: any[] | string /* Just for passing success message */;
  errormsg?: any[] | string /* Just for passing error message */;
  disableSnackbarWithCode?: PersistJobRequestEnum[];
}
export const saveJob = createAsyncThunk('onboard/myinfo/saveJob', async (request: IPersistJobReq) => {
  const { successmsg, errormsg, disableSnackbarWithCode, ...otherRequest } = request;
  const response: IPersistJobResponse = await PersistJob(otherRequest);
  return response;
});
interface IPersistDepartmentReq extends IPersistDepartmentRequest {
  successmsg?: any[] | string /* Just for passing success message */;
  errormsg?: any[] | string /* Just for passing error message */;
  disableSnackbarWithCode?: PersistDepartmentRequestEnum[];
}
export const saveDepartment = createAsyncThunk(
  'onboard/myinfo/saveDepartment',
  async (request: IPersistDepartmentReq) => {
    const { successmsg, errormsg, disableSnackbarWithCode, ...otherRequest } = request;
    const response: IPersistDepartmentResponse = await PersistDepartment(otherRequest);
    return response;
  }
);
export const getPayPeriodDetail = createAsyncThunk(
  'onboard/myinfo/GetPayPeriodDetail',
  async (request: IGetPayPeriodDetailRequest) => {
    const response: IGetPayPeriodDetailResponse = await GetPayPeriodDetail(request);
    return response;
  }
);
export const getJob = createAsyncThunk('onboard/myinfo/getJob', async (request: IGetJobListRequest) => {
  const response: IGetJobListResponse = await GetJob(request);
  return response;
});
export const getPersonJobList = createAsyncThunk(
  'onboard/myinfo/getPersonJobList',
  async (request: IGetPersonJobListRequest) => {
    // TODO: check this and remove if not needed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { buildIDBBlank = false, ...baseRequest } = request; /* Just seperating buildIDBBlank from the request */
    const response: IGetPersonJobListResponse = await GetPersonJobListUrl(baseRequest);
    return response;
  }
);
export const getDepartment = createAsyncThunk(
  'onboard/myinfo/getDepartment',
  async (request: IGetDepartmentListRequest) => {
    const response: IGetDepartmentListResponse = await GetDepartment(request);
    return response;
  }
);
/* Job Access Management START */
export const getPerson = createAsyncThunk('onboard/myinfo/getPerson', async (request: IGetPersonRequest) => {
  const response: IGetPersonResponse = await getPersonUrl(request);
  return response;
});
const getDD = createAsyncThunk('onboard/myinfo/getDD', async (request: IGetPersonBankAccountListRequest) => {
  const response: IGetPersonBankAccountListResponse = await getBankPersonListUrl(request);
  return response;
});
/* Job Access Management END */
/* Tax Management START */
const getInitPersonTax = createAsyncThunk('onboard/tax/initial', async (request: IGetTaxInitialStateRequest) => {
  const response: IGetTaxInitialStateResponse = await getInitialStatePersonTax(request);
  return response;
});
const getTaxExemptCode = createAsyncThunk(
  'onboard/tax/exemptCode',
  async (request: IGetTaxExemptionCodeListRequest) => {
    const response: IGetTaxExemptionCodeListResponse = await getTaxExemptionCodeList(request);
    return response;
  }
);
const persistSettingPersonTax = createAsyncThunk(
  'onboard/tax/persistPersonTax',
  async (request: IPersistSettingsRequest) => {
    const response: IPersistSettingsResponse = await persistSettings(request);
    return response;
  }
);
export const getTaxDet = createAsyncThunk('onboard/tax/taxdetail', async (request: IGetTaxDetailRequest) => {
  const response: IGetTaxDetailResponse = await getTaxDetail(request);
  return response;
});
/* Tax Management END */
/* Onboarding First Time User START */
const persistOnboardingPreferences = createAsyncThunk(
  'onboard/onboard/persistpreference',
  async (request: IPersistOnboardingPreferencesRequest) => {
    const response: IPersistOnboardingPreferencesResponse = await PersistOnboardingPreferences(request);
    return response;
  }
);
const getOnboardingStepsList = createAsyncThunk(
  'onboard/onboard/getOnboardingStatusList',
  async (request: IGetOnboardingStepsListRequest) => {
    const response: IGetOnboardingStepsListResponse = await GetOnboardingStepsList(request);
    return response;
  }
);
const getOnboardingPreferencesList = createAsyncThunk(
  'onboard/onboard/getonboardingpreferenceslist',
  async (request: IGetOnboardingPreferencesListRequest) => {
    const response: IGetOnboardingPreferencesListResponse = await GetOnboardingPreferencesList(request);
    return response;
  }
);
const getSelfOnboardingFieldGroupList = createAsyncThunk(
  'onboard/onboard/getSelfOnboardingFieldGroupList',
  async (request: IGetSelfOnboardingFieldGroupListRequest) => {
    const { isStaffOnboardingListArr /* No part of API request */, ...otherRequest } = request;

    const response: IGetSelfOnboardingFieldGroupListResponse = await GetSelfOnboardingFieldGroupList(otherRequest);
    return response;
  }
);
/* Onboarding First Time User END */

/* Payroll STARt */
const getPaygroup = createAsyncThunk('onboard/payroll/getPaygroup', async (request: IGetPayGroupListRequest) => {
  const response: IGetPayGroupListResponse = await getPayGroupListURL({
    ...request,
  });
  return response;
});
export const getWcbRateList = createAsyncThunk('onboard/payroll/getWcbList', async (request: GetWcbRateListRequest) => {
  const response: GetWcbRateListResponse = await GetWcbRateList({
    ...request,
  });
  return response;
});
/* Payroll END */
/* PAYGROUP START */
export const getpaygroupname = createAsyncThunk(
  'onboard/jobdetails/getpaygroupname',
  async (request: IGetPayGroupRequest) => {
    const response: IGetPayGroupResponse = await GetPayGroup({
      ...request,
    });
    return response;
  }
);
/* PAYGROUP END */

/* Person IDB START */
interface PersistEmployeeFieldListReq extends PersistEmployeeFieldListRequest {
  successmsg?: any[];
}
export const persistEmployeeFieldList = createAsyncThunk(
  'onboard/idb/persist',
  async (request: PersistEmployeeFieldListReq) => {
    const { successmsg, ...otherRequest } = request;
    const response: PersistEmployeeFieldListResponse = await PersistEmployeeFieldList({
      ...otherRequest,
    });
    return response;
  }
);
const getEmployeeFieldSummaryList = createAsyncThunk(
  'onboard/idb/getEmployeeFieldSummaryList',
  async (request: IGetEmployeeFieldSummaryListRequest) => {
    const response: IGetEmployeeFieldSummaryListResponse = await GetEmployeeFieldSummaryList({
      ...request,
    });
    return response;
  }
);

const getFieldList = createAsyncThunk('onboard/idb/getFieldList', async (request: IGetFieldListRequest) => {
  const response: IGetFieldListResponse = await GetFieldList({
    ...request,
  });
  return response;
});
const getSaleTaxList = createAsyncThunk('onboard/idb/getSaleTaxList', async (request: IGetSaleTaxListRequest) => {
  const response: IGetSaleTaxListResponse = await GetSaleTaxList({
    ...request,
  });
  return response;
});
/* Person IDB END */
/* Prior Payroll START */
const PersistPriorPayroll = createAsyncThunk(
  'onboard/priorPayroll/Persist',
  async (request: IPersistPayrollRequest) => {
    const response: IPersistPayrollResponse = await persistPriorPayroll({
      ...request,
    });
    return response;
  }
);
interface IGetPayrollRequestState extends IGetPayrollRequest {
  stateArr?: any;
  isContractor?: boolean;
}
const getPriorPayrollList = createAsyncThunk('onboard/priorPayroll/Get', async (request: IGetPayrollRequestState) => {
  const { stateArr, isContractor, ...otherRequest } = request;
  const response: IGetPayrollResponse = await getPriorPayroll({
    ...otherRequest,
  });
  return response;
});
const managePersonstatus = createAsyncThunk(
  'onboard/manageperson/persist',
  async (request: IManagePersonStatusRequest) => {
    const response: IManagePersonStatusResponse = await ManagePersonStatus({
      ...request,
    });
    return response;
  }
);
export const getLocationLists = createAsyncThunk(
  'onboard/personjobdetails/getlocation',
  async (request: GetLocationListRequest) => {
    const response: GetLocationListResponse = await GetLocationList({
      ...request,
    });
    return response;
  }
);
interface BulkAdditionInvitePersonReq extends IInvitePersonRequest {
  successMsg?: { fullname?: string; personcount?: number };
}
export const invitePerson = createAsyncThunk('onboard/invitePerson', async (request: BulkAdditionInvitePersonReq) => {
  const { successMsg, ...otherRequest } = request;
  const response: IInvitePersonResponse = await InvitePerson({
    ...otherRequest,
  });
  return response;
});
/* Calling Services END */
const handleValidatorState = (
  state: PeopleType,
  validationResults: IValidationResults,
  successValidMessage: string | any[],
  errormsg?: string | any[] /* Adding one more for custom error message */,
  disableSnackbarWithCode?: number[]
) => {
  if (validationResults?.validationResultList?.length === 0) {
    state.validationResults = validationResults;
    state.snackBarMessage = successValidMessage;
  } else if (validationResults?.validationResultList?.length) {
    state.validationResults = validationResults;
    if (!disableSnackbarWithCode?.includes(validationResults?.validationResultList[0].validationCode)) {
      if (errormsg) {
        state.snackBarMessage = errormsg
          ? errormsg
          : validationResults?.validationResultList[0]?.validationMessage ??
            'onboarding.snackbarsuccessmsg.serverissue';
      } else {
        state.snackBarMessage =
          validationResults?.validationResultList[0]?.validationMessage ?? 'onboarding.snackbarsuccessmsg.serverissue';
      }
    }
  } else {
    state.snackBarMessage = 'onboarding.snackbarsuccessmsg.serverissue';
  }
};
export const peopleOnboarding = (builder: ActionReducerMapBuilder<PeopleType>) => {
  builder.addCase(savePerson.pending, (state: PeopleType) => {
    state.bankDDSaved =
      false; /* in basic info, setting it to false for `dispatchGetFunctions(personId);` line of code */
    state.loading = true;
  });
  builder.addCase(savePerson.fulfilled, (state: PeopleType, action) => {
    if (!action.meta.arg.person?.id) {
      /* if no person id then only assign personId else dont assign */
      state.personId = action.payload.personId;
    }
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.workStateId = action.meta.arg.employeeInfo?.employementStateId;
    }
    state.loading = false;
    handleValidatorState(state, action?.payload?.validationResults, 'onboarding.snackbarsuccessmsg.personpersist');
  });
  builder.addCase(saveProfileDocument.pending, (state: PeopleType) => {
    state.loadingImg = true;
  });
  builder.addCase(saveProfileDocument.fulfilled, (state: PeopleType, action) => {
    handleValidatorState(state, action?.payload?.validationResults, 'onboarding.snackbarsuccessmsg.personpersist');
    state.loadingImg = false;
  });
  builder.addCase(getProfileDocument.pending, (state: PeopleType) => {
    state.loadingImg = true;
  });
  builder.addCase(getProfileDocument.fulfilled, (state: PeopleType) => {
    state.loadingImg = false;
  });
  builder.addCase(saveDD.pending, (state: PeopleType) => {
    state.bankDDSaved = false;
    state.loading = true;
  });
  builder.addCase(saveDD.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
    } else {
      /* state.bankDDSaved = false; */
    }
    state.loading = false;
    state.bankDDSaved = true;
  });
  builder.addCase(savePreviousProvince.pending, (state: PeopleType) => {
    state.bankDDSaved = false;
    state.loading = true;
  });
  builder.addCase(savePreviousProvince.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
    } else {
      /* state.bankDDSaved = false; */
    }
    handleValidatorState(state, action.payload?.validationResults, 'onboarding.snackbarsuccessmsg.personpersist');
    state.loading = false;
    state.bankDDSaved = true;
  });
  builder.addCase(getPayPeriodDetail.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.payPeriodDetail = action?.payload;
    } else {
      state.payPeriodDetail = undefined;
    }
  });
  builder.addCase(getJob.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.jobList = action?.payload?.jobList;
    } else {
      state.jobList = [];
    }
  });
  builder.addCase(saveJob.fulfilled, (state: PeopleType, action) => {
    const successmsg = action.meta?.arg?.successmsg;
    const errormsg = action.meta?.arg?.errormsg;
    const disableSnackbarWithCode = action.meta?.arg?.disableSnackbarWithCode;
    handleValidatorState(state, action.payload?.validationResults, successmsg ?? '', errormsg, disableSnackbarWithCode);
  });
  builder.addCase(saveDepartment.fulfilled, (state: PeopleType, action) => {
    const errormsg = action.meta?.arg?.errormsg;
    const successmsg = action.meta?.arg?.successmsg;
    const disableSnackbarWithCode = action.meta?.arg?.disableSnackbarWithCode;
    handleValidatorState(state, action.payload?.validationResults, successmsg ?? '', errormsg, disableSnackbarWithCode);
  });
  builder.addCase(getDepartment.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.departmentList = action?.payload?.departmentList;
    } else {
      state.departmentList = [];
    }
  });
  builder.addCase(getPersonJobList.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(getPersonJobList.fulfilled, (state: PeopleType, action) => {
    const buildIDBBlankSummaryList = action.meta?.arg?.buildIDBBlank ?? false;
    const blankList = {
      income: [],
      benefit: [],
      deduction: [],
    };
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.personJobList = action?.payload?.personJobDetailList;
      if (buildIDBBlankSummaryList && action?.payload?.personJobDetailList?.length) {
        /* Create Empty Summary List from personJobList with JobId which will be used later to track the index of blank EmployeeSummaryList */
        action?.payload?.personJobDetailList.map((personJobDetails) => {
          state.EmployeeFieldSummaryList = [
            ...(state.EmployeeFieldSummaryList ?? []),
            {
              jobId: personJobDetails?.id ?? 0,
              jobName: personJobDetails?.job?.name ?? undefined,
              ...blankList,
            },
          ];
        });
      }
      action?.payload?.personJobDetailList;
      state.vacation = action?.payload?.vacation;
      state.nextOnboard = false;
    }
    state.loading = false;
  });
  builder.addCase(getLocationLists.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.locationLists = action.payload?.locationList;
    }
  });
  builder.addCase(savePersonJobList.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(savePersonJobList.fulfilled, (state: PeopleType, action) => {
    const noNextOnboard = action.meta.arg.noNextOnboard;
    if (action.payload?.validationResults?.validationResultList?.length === 0 && !noNextOnboard) {
      state.nextOnboard = true;
    }
    state.loading = false;
    handleValidatorState(state, action.payload?.validationResults, 'onboarding.snackbarsuccessmsg.personjobpersist');
  });
  builder.addCase(createDefaultPersonJob.fulfilled, (state: PeopleType, action) => {
    /* TODO: Need to run and check this function */
    handleValidatorState(state, action.payload?.validationResults, 'onboarding.snackbarsuccessmsg.personjobpersist');
  });
  builder.addCase(getPerson.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(getPerson.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.person = action.payload.person;
      state.employeeInfo = action.payload.employeeInfo;
      if (!state.hasInvited) state.hasInvited = action.payload.person?.isInvited;
      if (action.payload?.employeeInfo?.employmentStateList?.length) {
        /* Updating previous bank states here */
        state.previousProvinces = action.payload.employeeInfo.employmentStateList.map((state: any) => state.stateId);
      }
    } else {
      state.person = undefined;
    }
    state.loading = false;
  });
  builder.addCase(getDD.fulfilled, (state: PeopleType, action) => {
    state.bankAccountList = action.payload.bankAccountList;
  });
  /* Payroll START */
  builder.addCase(getPaygroup.fulfilled, (state: PeopleType, action) => {
    state.payGroupSummaryList = action?.payload?.payGroupSummaryList ?? [];
  });
  builder.addCase(getWcbRateList.pending, (state: PeopleType) => {
    state.wcbLoading = true;
  });
  builder.addCase(getWcbRateList.fulfilled, (state: PeopleType, action) => {
    state.wcbLoading = false;
    state.wcbList = action?.payload?.wcbRateSummaryList ?? [];
  });
  /* Payroll END */
  /* Onboarding START */
  builder.addCase(persistOnboardingPreferences.pending, (state: PeopleType) => {
    state.loading = true;
    state.nextOnboard = false;
  });
  builder.addCase(persistOnboardingPreferences.fulfilled, (state: PeopleType, action) => {
    if (action.meta?.arg?.onboardingPreferences) {
      const { employementTypeIdList } = action.meta?.arg?.onboardingPreferences;
      if (action.payload?.validationResults?.validationResultList?.length === 0 && employementTypeIdList) {
        state.personTypeGuidedOnboarding = (employementTypeIdList.length && employementTypeIdList[0]) || 0;
      }
      handleValidatorState(
        state,
        action.payload?.validationResults,
        'onboarding.snackbarsuccessmsg.onboardingpreferences'
      );
    }
    state.loading = false;
  });
  builder.addCase(getOnboardingStepsList.fulfilled, (state: PeopleType, action) => {
    /* Todo: getting listed data here  */
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      let onboardingStepList: any[] = [];
      action.payload?.onboardingStepTypeList?.map((onboardingStep) => {
        onboardingStepList = [
          ...onboardingStepList,
          {
            ...onboardingStep,
            onboardingStepStatusType:
              onboardingStep.id !== EmployeeOnboardingStepTypeEnum.finalReview &&
              onboardingStep.onboardingStepStatusType === OnboardingStepStatusTypeEnum.staffOnboardPending
                ? OnboardingStepStatusTypeEnum.completed /* In UI converting step type pending to active, because pending only for service purpose */
                : onboardingStep.onboardingStepStatusType ?? OnboardingStepStatusTypeEnum.notDefined,
          },
        ];
      });
      state.onboardingSteplist = onboardingStepList;
    } else {
      state.onboardingSteplist = undefined;
    }
  });
  builder.addCase(getOnboardingPreferencesList.fulfilled, (state: PeopleType, action) => {
    const personId = action.meta?.arg?.personId;
    const companyId = action.meta?.arg?.companyId;
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      if (personId && action.payload?.onboardingPreferencesList.length) {
        const findPersonIndex = action.payload?.onboardingPreferencesList.findIndex(
          (onboardPreference) => onboardPreference.personId == personId
        );
        const onboardingPreferencesData: Record<any, any> = {
          companyId:
            companyId ??
            dummyCompanyWrongId /* dummyCompanyWrongId is a dummy Company Id to get same data as onboarding preference */,
          onboardingPreferences: {
            onboardingTypeId: EmployeeOnboardingTypeEnum.guided,
            selfOnboardFieldGroupIdList: action.payload?.onboardingPreferencesList[findPersonIndex]
              .selfOnboardFieldGroupIdList ?? [0],
          },
        };
        onboardingPreferencesData.onboardingPreferences = action.payload?.onboardingPreferencesList[findPersonIndex];
        state.onboardingPreferencesData = {
          ...onboardingPreferencesData,
          onboardingPreferences: {
            ...onboardingPreferencesData.onboardingPreferences,
          },
        };
      } else {
        state.onboardingPreferencesData = undefined;
      }
    } else {
      state.onboardingPreferencesData = undefined;
    }
  });
  builder.addCase(getSelfOnboardingFieldGroupList.fulfilled, (state: PeopleType, action) => {
    const isStaffOnboardingList = action.meta?.arg?.isStaffOnboardingListArr;
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      const onboardFieldList = action.payload?.onboardFieldGroupList;
      state.fieldGroupList = onboardFieldList.filter(
        (onboardField) => isStaffOnboardingList && isStaffOnboardingList.includes(onboardField?.id ?? 0)
      );
      state.fieldGroupAllList = onboardFieldList;
    } else {
      state.onboardingPreferencesData = undefined;
    }
  });
  /* Onboarding END */
  /* Tax Treatment START */
  builder.addCase(getInitPersonTax.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(getInitPersonTax.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.taxSchemeTypeList = action.payload?.taxSchemeTypeList;
    }
    state.loading = false;
  });
  builder.addCase(getTaxExemptCode.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0 && state.taxCodeList) {
      if (action.meta?.arg?.stateId) {
        state.taxCodeList.stateTaxCode = action.payload?.taxClaimCodeList;
      } else {
        state.taxCodeList.taxCode = action.payload?.taxClaimCodeList;
      }
    }
  });
  builder.addCase(persistSettingPersonTax.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(persistSettingPersonTax.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.nextOnboard = true;
    }
    state.loading = false;
    handleValidatorState(state, action.payload?.validationResults, 'onboarding.snackbarsuccessmsg.taxpersist');
  });
  builder.addCase(getTaxDet.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(getTaxDet.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.getTaxDetails = action.payload?.taxDetail;
    }
    state.loading = false;
  });
  builder.addCase(getFieldList.fulfilled, (state: PeopleType, action) => {
    const fieldType = action.meta?.arg?.fieldType;
    const jobId = action.meta?.arg?.jobId;
    if (state.EmployeeFieldList) {
      const getIndex = state.EmployeeFieldList.findIndex((employeeField) => jobId == employeeField.jobId);
      /* if jobid then insert into the jobId place else create a newone and insert it */
      const getObjectFieldType: Record<any, any> = {};
      if (action.payload?.validationResults?.validationResultList?.length === 0) {
        if (fieldType === FieldTypeEnum.income) {
          getObjectFieldType.income = action.payload?.fieldSummaryList ?? [];
        } else if (fieldType === FieldTypeEnum.deduction) {
          getObjectFieldType.deduction = action.payload?.fieldSummaryList ?? [];
        } else if (fieldType === FieldTypeEnum.benefit) {
          getObjectFieldType.benefit = action.payload?.fieldSummaryList ?? [];
        }
        if (getIndex != -1) {
          state.EmployeeFieldList[getIndex] = { ...state.EmployeeFieldList[getIndex], ...getObjectFieldType };
        } else {
          state.EmployeeFieldList = [
            ...state.EmployeeFieldList,
            {
              jobId: jobId ?? 0,
              ...getObjectFieldType,
            },
          ];
        }
        state.fieldListDashboard = [
          ...(action.payload?.fieldSummaryList ?? []),
        ]; /* Only for using in CustomSlide IDB */
      }
    }
    state.loading = false;
  });
  builder.addCase(getSaleTaxList.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.saleTaxList = action.payload?.salesTaxList;
    } else {
      state.saleTaxList = undefined;
    }
  });
  builder.addCase(getEmployeeFieldSummaryList.fulfilled, (state: PeopleType, action) => {
    const fieldType = action.meta?.arg?.fieldType;
    const jobId = action.meta?.arg?.jobId;
    const jobName = action.meta?.arg?.jobName;
    if (state.EmployeeFieldSummaryList) {
      const getIndex = state.EmployeeFieldSummaryList.findIndex((employeeField) => jobId == employeeField.jobId);
      /* if jobid then insert into the jobId place else create a newone and insert it */
      const getObjectFieldType: Record<any, any> = {};
      if (action.payload?.validationResults?.validationResultList?.length === 0) {
        if (fieldType === FieldTypeEnum.income) {
          getObjectFieldType.income = action.payload?.employeeFieldSummaryList ?? [];
        } else if (fieldType === FieldTypeEnum.deduction) {
          getObjectFieldType.deduction = action.payload?.employeeFieldSummaryList ?? [];
        } else if (fieldType === FieldTypeEnum.benefit) {
          getObjectFieldType.benefit = action.payload?.employeeFieldSummaryList ?? [];
        }
        if (getIndex != -1) {
          state.EmployeeFieldSummaryList[getIndex] = {
            ...state.EmployeeFieldSummaryList[getIndex],
            ...getObjectFieldType,
          };
        } else {
          state.EmployeeFieldSummaryList = [
            ...state.EmployeeFieldSummaryList,
            {
              jobId: jobId ?? 0,
              ...(jobName ? { jobName } : {}),
              ...getObjectFieldType,
            },
          ];
        }
      }
    }
    state.loading = false;
  });
  builder.addCase(persistEmployeeFieldList.pending, (state: PeopleType) => {
    state.loading = true;
  });
  builder.addCase(persistEmployeeFieldList.fulfilled, (state: PeopleType, action) => {
    const successmsg = action.meta?.arg?.successmsg;
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.nextOnboard = true;
    }
    state.loading = false;
    handleValidatorState(
      state,
      action.payload?.validationResults,
      successmsg ? successmsg : 'onboarding.snackbarsuccessmsg.idbpersist'
    );
  });
  builder.addCase(getpaygroupname.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.payGroupName = action.payload?.payGroup.name;
      state.paygroupHoursPerPay = action.payload?.payGroup?.payGroupSetting?.hoursPerPayPeriod;
    }
  });
  /* Tax Treatment END */
  /* Prior Payroll START */
  builder.addCase(getPriorPayrollList.fulfilled, (state: PeopleType, action) => {
    const stateArr = action.meta?.arg?.stateArr;
    const isContractor = action.meta?.arg?.isContractor;
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      let EmployeePayrollArr: any = [];
      const PayrollArrObjBase = {
        payrollDataArr: action.payload?.employeePayrollDataList ?? [],
      };
      stateArr.map((value: any) => {
        EmployeePayrollArr = [
          ...EmployeePayrollArr,
          {
            ...PayrollArrObjBase,
            stateId: typeof value == 'number' ? value : value?.key ?? 0,
          },
        ];
      });
      state.EmployeePayrollDataList = !isContractor ? [...EmployeePayrollArr] : [PayrollArrObjBase];
    }
  });
  builder.addCase(PersistPriorPayroll.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.nextOnboard = true;
    }
    state.loading = false;
    handleValidatorState(state, action.payload?.validationResults, 'onboarding.snackbarsuccessmsg.priorpayrollpersist');
  });
  /* Prior Payroll END */
  /* ManagePersonStatus START */
  builder.addCase(managePersonstatus.fulfilled, (state: PeopleType, action) => {
    if (action.payload?.validationResults?.validationResultList?.length === 0) {
      state.nextOnboard = true;
    }
    state.loading = false;
    handleValidatorState(state, action.payload?.validationResults, 'onboarding.snackbarsuccessmsg.personstatusactive');
  });
  /* ManagePersonStatus END */
  /* Invite Person START */
  builder.addCase(invitePerson.fulfilled, (state: PeopleType, action) => {
    const successmsg = action.meta?.arg?.successMsg;
    const getSuccessMsg: any = successmsg?.fullname
      ? ['onboarding.snackbarsuccessmsg.invitePersonbulksingle', { fullname: successmsg?.fullname }]
      : successmsg?.personcount
      ? ['onboarding.snackbarsuccessmsg.invitePersonbulk', { personcount: successmsg?.personcount }]
      : 'onboarding.snackbarsuccessmsg.invitePerson';
    state.hasInvited = true;
    handleValidatorState(state, action.payload?.validationResults, getSuccessMsg);
  });
  /* Invite Person END */
};
