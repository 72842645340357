import React, { VFC } from 'react';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';
import WpButton from '@wagepoint/ui-toolkit/components/wp-button/wpButton';
import { useTranslation } from 'react-i18next';

interface IDeclineModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onDecline: () => void;
}

const DeclineModal: VFC<IDeclineModalProps> = ({ isOpen, onCancel, onDecline }) => {
  const { t } = useTranslation('userAccounts');
  return (
    <WpDialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      title={t('firmInviteAccepted.declineTitle')}
      content={t('firmInviteAccepted.declineContent')}
      actions={
        <>
          <WpButton onClick={onCancel} disableRipple color="primary" variant="outlined">
            {t('firmInviteAccepted.revokeCancel')}
          </WpButton>
          <WpButton disableRipple onClick={onDecline} color="primary" variant="contained" autoFocus>
            {t('firmInviteAccepted.decline')}
          </WpButton>
        </>
      }
    />
  );
};

export default DeclineModal;
