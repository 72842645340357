import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { GetUser } from 'services/security-access/securityAccess.service';
import {
  IGetUserRequest,
  IGetUserResponse,
  IPermissionCategory,
  IPermissionSubCategoryList,
  ISecurityRole,
  IUser,
} from 'services/security-access/securityAccess.contracts';
import {
  PermissionSettingsEnumExtended,
  PermissionSettingsEnumForPeople,
} from 'pages/settings/components/useraccounts/assignedPeopleGroup';
import { PermissionsEnum } from 'services/shared/permission.contracts';
import { RootState } from 'redux/store/store';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { WritableDraft } from 'immer/dist/internal';

export interface INewPermissionSubCategoryList extends IPermissionSubCategoryList {
  isRead?: boolean;
  isApprove?: boolean;
  isReadWrite?: boolean;
  isSelected?: boolean;
  isSubmit?: boolean;
}

interface IAllStatuses {
  isReady: boolean;
  isValidating: boolean;
  error: string | unknown;
  user: IUser | null;
  phoneNumber: string;
  twoFactorEnabled: boolean;
  profileDocumentId: number;
  securityRoleList: IPermissionCategory[];
  securityEmployeeList: IPermissionCategory[];
  payrollAdminPermissionsList: IPermissionCategory[];
  isEmployee: boolean;
  roleId?: number;
  isFirstTimeLogin?: boolean;
  isPayrollApprover?: boolean;
}

const initialState: IAllStatuses = {
  isReady: false,
  isValidating: false,
  error: '',
  user: null,
  phoneNumber: '',
  twoFactorEnabled: false,
  securityRoleList: [],
  securityEmployeeList: [],
  payrollAdminPermissionsList: [],
  isEmployee: false,
  profileDocumentId: 0,
  isFirstTimeLogin: false,
  isPayrollApprover: false,
};

export const getUser = createAsyncThunk('/security-access/getUser', async (request: IGetUserRequest) => {
  const response: IGetUserResponse = await GetUser(request);
  return response;
});

function createAdministratorList(lists: ISecurityRole[]) {
  return lists[0]?.permissionCategoryList.filter((list) => {
    if (
      list.id !== PermissionSettingsEnumForPeople.BasicPermission &&
      list.id !== PermissionSettingsEnumForPeople.PayrollPermission &&
      list.id !== PermissionSettingsEnumExtended.AccountingPortal &&
      list.id !== PermissionSettingsEnumExtended.Alfred
    ) {
      return list;
    }
  });
}

function createEmployeeList(lists: ISecurityRole[]) {
  return lists[0]?.permissionCategoryList.filter((list) => {
    if (
      list.id === PermissionSettingsEnumForPeople.BasicPermission ||
      list.id === PermissionSettingsEnumForPeople.PayrollPermission
    ) {
      return list;
    }
  });
}

function createPayrollAdminList(lists: ISecurityRole[]) {
  const accountingPortalPermissions = lists[0]?.permissionCategoryList.filter((list) => {
    return list.id === PermissionsEnum.AccountingPortal;
  })?.[0]?.permissionSubCategoryList;
  const payrollAdminList = accountingPortalPermissions?.filter((list) => {
    return list.id === PermissionsEnum.ScAccountingClient || list.id === PermissionsEnum.ScAccountingSetting;
  });

  const formattedPayrollAdminList: IPermissionCategory[] = payrollAdminList?.map(({ id, name }) => {
    const permissionSubCategoryList = accountingPortalPermissions
      ?.filter((list) => list.parentSubCategoryId === id)
      .map(({ parentSubCategoryId, ...rest }) => ({ ...rest }));
    return { id, name, permissionSubCategoryList };
  });
  return formattedPayrollAdminList;
}

const setupData = (state: WritableDraft<IAllStatuses>, response: IGetUserResponse) => {
  state.isValidating = false;
  if (response) {
    state.isReady = true;
    state.error = false;
  } else {
    state.isReady = false;
    state.error = true;
  }

  if (response.securityRoleList && response.securityRoleList.length > 0) {
    state.roleId = response.securityRoleList[0].id;
    state.securityRoleList = createAdministratorList(response.securityRoleList);
    if (response.user.isEmployee) {
      state.securityEmployeeList = createEmployeeList(response.securityRoleList);
    }
    state.payrollAdminPermissionsList = createPayrollAdminList(response.securityRoleList);
  }
  state.user = response.user;
  state.phoneNumber = response.user.phoneNumber;
  state.twoFactorEnabled = response.user.twoFactorEnabled;
  state.profileDocumentId = response.user.profileDocumentId;
  state.isFirstTimeLogin = response.user.isFirstTimeLogin;
  state.isPayrollApprover = response.user.isPayrollApprover;
  return state;
};

export const getUserSlice = createSlice({
  name: 'securityAccess/getUser',
  initialState,
  reducers: {
    updategetUserSliceState: (state, action) => {
      state = setupData(state, action.payload.value);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isValidating = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state = setupData(state, action.payload);
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isReady = false;
        state.error = action.payload;
        state.isValidating = false;
      });
  },
});

export const getUserReducer = getUserSlice.reducer;

const selectUserData = (state: RootState) => state.getUserReducer;

export const selectSecurityRoleList = createSelector(selectUserData, ({ securityRoleList }) => securityRoleList);

export const selectSecurityEmployeeList = createSelector(
  selectUserData,
  ({ securityEmployeeList }) => securityEmployeeList
);

export const selectPayrollAdminPermissionsList = createSelector(
  selectUserData,
  ({ payrollAdminPermissionsList }) => payrollAdminPermissionsList
);

export const selectIsValidating = createSelector(selectUserData, ({ isValidating }) => isValidating);

export const selectUser = createSelector(selectUserData, ({ user }) => user);
export const selectUserSecurityRole = createSelector(
  selectUserData,
  // Use the first role in the list. We always get one role from the API
  ({ user }) => user?.securityRoleTypeList?.[0] ?? SecurityRoleTypeEnum.NotDefined
);
export const selectUserPhoneNumber = createSelector(selectUserData, ({ user }) => user?.phoneNumber);
export const selectProfileDocumentId = createSelector(selectUserData, ({ profileDocumentId }) => profileDocumentId);
export const firstTimeLogin = createSelector(selectUserData, ({ isFirstTimeLogin }) => isFirstTimeLogin);
export const isPayrollApprover = createSelector(selectUserData, ({ isPayrollApprover }) => isPayrollApprover);
export const { updategetUserSliceState } = getUserSlice.actions;
