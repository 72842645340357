import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

export interface IAddBankSliceBool {
  showBankInfo: boolean;
}
export interface IAddBankSliceNonBool {
  bankData: any;
}

export interface IAddBankSlice extends IAddBankSliceBool, IAddBankSliceNonBool {}

const initialState: IAddBankSlice = {
  showBankInfo: false,
  bankData: null,
};

export const addbankslice = createSlice({
  name: 'addbankSlice',
  initialState,
  reducers: {
    updateAddBankBools: (state, action: PayloadAction<{ key: keyof IAddBankSliceBool; value: boolean }>) => {
      state[action.payload.key] = action.payload.value;
    },
    updateAddBankNonBool: (state, action: PayloadAction<{ key: keyof IAddBankSliceNonBool; value: boolean }>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { updateAddBankBools, updateAddBankNonBool } = addbankslice.actions;
export const addBankReducer = addbankslice.reducer;
export const addBankStateSelector = (state: RootState) => state.addBankReducer;
export const bankInfoSelector = (state: RootState) => state.addBankReducer.bankData;
export const selectShowBankInfo = (state: RootState) => state.addBankReducer?.showBankInfo;
