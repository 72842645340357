import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { ValidEnum } from 'pages/settings/components/accounting-bookkeeping-firm/common/company-email-layout/companyIdOrEmailLayout';
import { store } from 'redux/store/store';

const validationSchemaIDForm = (t: TFunction) =>
  yup.object().shape({
    roleTypeRadioCheck: yup.string().min(1, t('common.selectRole')).required(t('common.selectRole')),
    cId: yup.lazy((value) => {
      if (value !== undefined && value.length > 6) {
        return yup
          .string()
          .test('cId', function (value) {
            const currentState = store.getState();
            const selectedValidCompany = currentState.accountingFirmReducer.isValidCompany;
            if (value && value.length > 6 && selectedValidCompany !== ValidEnum.Valid)
              return this.createError({
                message: t('common.companyIdInvalid'),
              });
            return true;
          })
          .nullable();
      }
      return yup.mixed().notRequired();
    }),
  });

export default validationSchemaIDForm;
