import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { hideLoading, showLoading } from './loadingIndicatorSlice';
import { tableStatuses } from '../../pages/accounting-dashboard/user-accounts/common';
import partnerAccessService, {
  PersistSecurityRoleRequestInterface,
  PersistSecurityRoleResponseInterface,
  ResetToDefaultPermissionRequestInterface,
  ResetToDefaultPermissionResponseInterface,
  GetSecurityRoleRequestInterface,
  GetSecurityRoleResponseInterface,
  PersistUserPermissionRequestInterface,
  MaintainPersonSecurityRoleRequestInterface,
  MaintainPersonSecurityRoleResponseInterface,
} from 'services/appCore/models/access/partnerAccess';
import companyLinkAccess, {
  PersistClientCompanyAdminListRequestInterface,
  PersistClientCompanyAdminListResponseInterface,
} from 'services/finance/models/access/companyLinkAccess';

export interface IPermissionTableSlice {
  validationResults: any;
  status: any;
  statusUpdate: string;
  securityRole: any;
  isEdit: boolean;
  isReset: boolean;
  resetDialog: boolean;
  customizationsDialog: boolean;
  unsavedDialog: boolean;
  hasCustomization: boolean;
}

const initialState: IPermissionTableSlice = {
  securityRole: [],
  validationResults: {},
  status: null,
  isEdit: false,
  isReset: false,
  resetDialog: false,
  customizationsDialog: false,
  unsavedDialog: false,
  hasCustomization: false,
  statusUpdate: '',
};

export const getPermissionData = createAsyncThunk(
  'permissionTable/getPermissionData',
  async (request: GetSecurityRoleRequestInterface) => {
    const response: GetSecurityRoleResponseInterface = await partnerAccessService.getSecurityRole(request);
    return response;
  }
);

export const resetPermissionTable = createAsyncThunk(
  'permissionTable/resetPermissionTable',
  async (request: ResetToDefaultPermissionRequestInterface) => {
    const response: ResetToDefaultPermissionResponseInterface = await partnerAccessService.resetToDefaultPermission(
      request
    );
    return response;
  }
);

export const savePermissionTable = createAsyncThunk(
  'permissionTable/savePermissionTable',
  async (request: PersistSecurityRoleRequestInterface, { dispatch }) => {
    dispatch(showLoading());
    const response: PersistSecurityRoleResponseInterface = await partnerAccessService.persistSecurityRole(request);
    dispatch(hideLoading());
    return response;
  }
);

export const maintainPersonSecurityRole = createAsyncThunk(
  'permissionTable/maintainPersonSecurityRole',
  async (request: MaintainPersonSecurityRoleRequestInterface, { dispatch }) => {
    dispatch(showLoading());
    const response: MaintainPersonSecurityRoleResponseInterface = await partnerAccessService.maintainPersonSecurityRole(
      request
    );
    dispatch(hideLoading());
    return response;
  }
);

export const savePermissionTableParticularAdmin = createAsyncThunk(
  'permissionTable/savePermissionTableParticularAdmin',
  async (request: PersistUserPermissionRequestInterface, { dispatch }) => {
    dispatch(showLoading());
    const response: PersistSecurityRoleResponseInterface = await partnerAccessService.persistUserPermission(request);
    dispatch(hideLoading());
    return response;
  }
);

export const persistClientForAdmin = createAsyncThunk(
  'permissionTable/persistClientForAdmin',
  async (request: PersistClientCompanyAdminListRequestInterface, { dispatch }) => {
    dispatch(showLoading());
    const response: PersistClientCompanyAdminListResponseInterface =
      await companyLinkAccess.persistClientCompanyAdminList(request);
    dispatch(hideLoading());
    return response;
  }
);

export const accountingPermissionSlice = createSlice({
  name: 'permissionTable',
  initialState,
  reducers: {
    setEdit: (state, action: { payload: { value: boolean } }) => {
      state.isEdit = action.payload.value;
    },
    setReset: (state, action: { payload: { value: boolean } }) => {
      state.isReset = action.payload.value;
    },
    setCustomizationDialog: (state, action: { payload: { value: boolean } }) => {
      state.customizationsDialog = action.payload.value;
    },
    setUnsaveDialog: (state, action: { payload: { value: boolean } }) => {
      state.unsavedDialog = action.payload.value;
    },
    setResetDialog: (state, action: { payload: { value: boolean } }) => {
      state.resetDialog = action.payload.value;
    },
    resetStatus: (state, action: { payload: { value: string } }) => {
      state.status = action.payload.value;
    },
    setUpdateStatus: (state, action: { payload: { value: string } }) => {
      state.statusUpdate = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPermissionData.fulfilled, (state, action) => {
      state.securityRole = action.payload.securityRole;
      state.validationResults = action.payload.validationResults;
      state.hasCustomization = action.payload.securityRole.hasCustomization ?? false;
      state.status = tableStatuses.FULLFIELDTABLEDATA;
    });
    builder.addCase(getPermissionData.pending, (state) => {
      state.status = tableStatuses.LOADING;
    });
    builder.addCase(getPermissionData.rejected, (state) => {
      state.status = tableStatuses.REJECTEDTABLEDATA;
    });

    builder.addCase(savePermissionTable.fulfilled, (state) => {
      state.status = tableStatuses.SAVEDTABLEDATA;
      state.isEdit = false;
      state.isReset = false;
      state.customizationsDialog = false;
    });
    builder.addCase(savePermissionTable.pending, (state) => {
      state.status = tableStatuses.LOADING;
    });
    builder.addCase(savePermissionTableParticularAdmin.fulfilled, (state) => {
      state.isEdit = false;
      state.isReset = false;
    });
    builder.addCase(maintainPersonSecurityRole.fulfilled, (state) => {
      state.isEdit = false;
      state.isReset = false;
    });

    builder.addCase(persistClientForAdmin.fulfilled, (state) => {
      state.isEdit = false;
      state.isReset = false;
    });

    builder.addCase(savePermissionTableParticularAdmin.pending, (state) => {
      state.status = tableStatuses.LOADING;
    });

    builder.addCase(savePermissionTable.rejected, (state) => {
      state.status = tableStatuses.REJECTEDTABLEDATA;
    });

    builder.addCase(resetPermissionTable.pending, (state) => {
      state.resetDialog = false;
      state.status = tableStatuses.RESETING;
    });
    builder.addCase(resetPermissionTable.fulfilled, (state) => {
      state.status = tableStatuses.FULLFIELDRESET;
      state.isReset = false;
    });
    builder.addCase(resetPermissionTable.rejected, (state) => {
      state.status = tableStatuses.REJECTEDTABLEDATA;
      state.resetDialog = false;
    });
  },
});

export const {
  setEdit,
  setReset,
  setCustomizationDialog,
  setUnsaveDialog,
  setResetDialog,
  resetStatus,
  setUpdateStatus,
} = accountingPermissionSlice.actions;
export const permissionTableReducer = accountingPermissionSlice.reducer;
export const selectPermissionTable = (state: RootState) => state.permissionTableReducer;
export const selectResetDialog = createSelector(selectPermissionTable, ({ resetDialog }) => resetDialog);

export const selectSecurityRole = createSelector(selectPermissionTable, ({ securityRole }) => securityRole);
export const selectStatus = createSelector(selectPermissionTable, ({ status }) => status);
export const selectIsEditState = createSelector(selectPermissionTable, ({ isEdit }) => isEdit);
export const selectIsResetState = createSelector(selectPermissionTable, ({ isReset }) => isReset);
export const selectStatusUpdate = createSelector(selectPermissionTable, ({ statusUpdate }) => statusUpdate);
export const selectCustomizationsDialog = createSelector(
  selectPermissionTable,
  ({ customizationsDialog }) => customizationsDialog
);
export const selectUnsavedDialog = createSelector(selectPermissionTable, ({ unsavedDialog }) => unsavedDialog);
export const selectHasCustomization = createSelector(selectPermissionTable, ({ hasCustomization }) => hasCustomization);
