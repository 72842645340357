import { makeStyles } from '@material-ui/core/styles';

export const CardStyles = makeStyles((theme) => ({
  defaultImage: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  cardHeader: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));
