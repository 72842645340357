import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { GetContentRequest, GetContentResponse } from 'services/document-access/documentAccess.contracts';
import { GetDocumentContent } from 'services/document-access/documentAccess.service';
import { getApiValidationErrors } from 'util/utility';

interface IPersonal {
  profileImg: string;
  loadingImg: boolean;
}

const initialState: IPersonal = {
  profileImg: '',
  loadingImg: false,
};

export const getProfileDocument = createAsyncThunk(
  'personal/getProfileDocument',
  async (request: GetContentRequest) => {
    const response: GetContentResponse = await GetDocumentContent(request);
    return response;
  }
);

export const personalSlice = createSlice({
  name: 'personalSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileDocument.pending, (state) => {
      state.loadingImg = true;
    });
    builder.addCase(getProfileDocument.fulfilled, (state, action) => {
      state.loadingImg = false;
      const { hasError } = getApiValidationErrors(action.payload);
      if (!hasError) {
        state.profileImg = 'data:image/png;base64,' + action.payload.documentContent;
      }
    });
  },
});

export const {} = personalSlice.actions;
export const personalReducer = personalSlice.reducer;
export const selectPersonal = (state: RootState) => state.personalReducer;
