import React from 'react';
import WpDialog from '@wagepoint/ui-toolkit/components/wp-dialog/wpDialog';

interface IProps {
  open: boolean;
  onAcceptButtonClick: () => void;
  onRejectButtonClick: () => void;
}

export const PermissionSettingsDialogue: React.FC<IProps> = ({ open, onAcceptButtonClick, onRejectButtonClick }) => {
  return (
    <WpDialog
      open={open}
      unsavedChanges
      unsavedLeave={() => onAcceptButtonClick()}
      unsavedStay={() => {
        onRejectButtonClick();
      }}
    />
  );
};
