import { UserAccountsPermissionsEnum, UserAccountsPermissionsForPeopleEnum } from './enum/flattenPermissionsEnum';
import i18n from 'i18n/i18n';
/*
The header for each permission column varies based on the enum
*/

export function UpdateColumnHeaderList(categoryId: number) {
  const updatedHeaderList = [];
  const i = i18n;

  switch (categoryId) {
    case UserAccountsPermissionsEnum.Setup:
    case UserAccountsPermissionsEnum.PayrollSettings:
    case UserAccountsPermissionsEnum.TaxSettings:
    case UserAccountsPermissionsEnum.PayrollTools:
      updatedHeaderList.push(
        i.t('userAccounts:permissionSettings.viewAndEdit'),
        i.t('userAccounts:permissionSettings.viewOnly'),
        i.t('userAccounts:permissionSettings.noAccess')
      );
      break;
    case UserAccountsPermissionsEnum.Payroll:
      updatedHeaderList.push(
        i.t('userAccounts:permissionSettings.runAndSubmit'),
        i.t('userAccounts:permissionSettings.runAndApprove'),
        i.t('userAccounts:permissionSettings.noAccess')
      );
      break;
    case UserAccountsPermissionsEnum.Reports:
      updatedHeaderList.push(
        i.t('userAccounts:permissionSettings.viewAndExport'),
        i.t('userAccounts:permissionSettings.viewOnly'),
        i.t('userAccounts:permissionSettings.noAccess')
      );
      break;
    case UserAccountsPermissionsEnum.CompanySettings:
      updatedHeaderList.push(
        i.t('userAccounts:permissionSettings.viewAndEdit'),
        i.t('userAccounts:permissionSettings.viewOnly'),
        i.t('userAccounts:permissionSettings.noAccess')
      );
      break;
    case UserAccountsPermissionsForPeopleEnum.BasicPermission:
      updatedHeaderList.push(
        i.t('userAccounts:permissionSettings.viewAndEdit'),
        i.t('userAccounts:permissionSettings.viewOnly')
      );
      break;
    case UserAccountsPermissionsForPeopleEnum.PayrollPermission:
      updatedHeaderList.push(
        i.t('userAccounts:permissionSettings.viewAndExport'),
        i.t('userAccounts:permissionSettings.viewOnly'),
        i.t('userAccounts:permissionSettings.noAccess')
      );
      break;
    default:
      break;
  }

  return updatedHeaderList;
}
