import { Post } from 'services/httpClient';
import {
  ICompleteOnboardingRequest,
  ICompleteOnboardingResponse,
  IGetInitialStateRequest,
  IGetInitialStateResponse,
  IGetOnboardingStepStatusListRequest,
  IGetOnboardingStepStatusListResponse,
  IPersistOnboardingStatusRequest,
  IPersistOnboardingStatusResponse,
  IPersistOnboardingStepRequest,
  IPersistOnboardingStepResponse,
  IPersistOnboardingStepStatusRequest,
  IPersistOnboardingStepStatusResponse,
} from './payrollCompanyOnboardAccess.contracts';
import { IValidationResults } from '../shared/validationResults.contracts';

const servicedomain = '/payrollcompanyonboardaccess/';
const GetInitialStateUrl = `${servicedomain}GetInitialState`;
const PersistOnboardingStatusUrl = `${servicedomain}PersistOnboardingStatus`;
const PersistOnboardingStepUrl = `${servicedomain}PersistOnboardingStep`;
const GetOnboardingStepStatusListUrl = `${servicedomain}GetOnboardingStepStatusList`;
const CompleteOnboardingUrl = `${servicedomain}CompleteOnboarding`;
const PersistOnboardingStepStatusUrl = `${servicedomain}PersistOnboardingStepStatus`;
const SendVerificationEmailUrl = '/appcorepersonsettingsaccess/SendVerificationEmail';

export const SendVerificationEmail = (request: {
  email: string;
}): Promise<{ validationResults?: IValidationResults }> => {
  return Post(SendVerificationEmailUrl, request);
};

export const GetInitialState = (request: IGetInitialStateRequest): Promise<IGetInitialStateResponse> => {
  return Post(GetInitialStateUrl, request);
};

export const PersistOnboardingStatus = (
  request: IPersistOnboardingStatusRequest
): Promise<IPersistOnboardingStatusResponse> => {
  return Post(PersistOnboardingStatusUrl, request);
};

export const PersistOnboardingStep = (
  request: IPersistOnboardingStepRequest
): Promise<IPersistOnboardingStepResponse> => {
  return Post(PersistOnboardingStepUrl, request);
};

export const GetOnboardingStepStatusList = (
  request: IGetOnboardingStepStatusListRequest
): Promise<IGetOnboardingStepStatusListResponse> => {
  return Post(GetOnboardingStepStatusListUrl, request);
};

export const CompleteOnboarding = (request: ICompleteOnboardingRequest): Promise<ICompleteOnboardingResponse> => {
  return Post(CompleteOnboardingUrl, request);
};

export const PersistOnboardingStepStatus = (
  request: IPersistOnboardingStepStatusRequest
): Promise<IPersistOnboardingStepStatusResponse> => {
  return Post(PersistOnboardingStepStatusUrl, request);
};
