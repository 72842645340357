import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';

interface ILoadingIndicatorInit {
  loading?: boolean;
  loadingCounter: number;
  loadingMessage?: string;
  showPercentage?: boolean;
}

const initialState: ILoadingIndicatorInit = {
  loading: false,
  loadingCounter: 0,
  loadingMessage: '',
  showPercentage: false,
};

export const loadingIndicatorSlice = createSlice({
  name: 'loadingIndicator',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loadingCounter += 1;
      state.loading = state.loadingCounter > 0;
      state.loadingMessage = initialState.loadingMessage;
    },
    showLoadingWithSpecificMessage: (state, action?: PayloadAction<Pick<ILoadingIndicatorInit, 'loadingMessage'>>) => {
      state.loadingCounter += 1;
      state.loading = state.loadingCounter > 0;
      state.loadingMessage = action?.payload.loadingMessage;
    },
    showLoadingWithPercentage: (state, action?: PayloadAction<Pick<ILoadingIndicatorInit, 'showPercentage'>>) => {
      state.loadingCounter += 1;
      state.loading = state.loadingCounter > 0;
      state.showPercentage = action?.payload.showPercentage;
    },
    hideLoading: (state) => {
      state.loadingCounter = state.loadingCounter <= 0 ? 0 : state.loadingCounter - 1;
      state.loading = state.loadingCounter > 0;
    },
    resetLoading: (state) => {
      state.loading = false;
      state.loadingCounter = 0;
      state.loadingMessage = '';
      state.showPercentage = false;
    },
  },
});

export const { showLoading, hideLoading, showLoadingWithSpecificMessage, showLoadingWithPercentage, resetLoading } =
  loadingIndicatorSlice.actions;
export const loadingIndicatorReducer = loadingIndicatorSlice.reducer;
export const setLoadingIndicator = (state: RootState) => state.loadingIndicatorReducer;
