import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import {
  PersistEmployerTaxesRequest,
  PersistEmployerTaxesResponse,
} from 'services/tax-settings-access/taxSettingsAccess.contracts';
import { PersistEmployerTaxes } from 'services/tax-settings-access/taxSettingsAccess.service';

interface IGetInitialState {
  validationResults: any;
}

const initialState: IGetInitialState = {
  validationResults: {},
};

export const persistEmployerTaxes = createAsyncThunk(
  'employerTaxes/persistemployertaxes',
  async (request: PersistEmployerTaxesRequest) => {
    const response: PersistEmployerTaxesResponse = await PersistEmployerTaxes(request);
    return response;
  }
);

export const persistEmployerTaxesSlice = createSlice({
  name: 'persistEmployerTaxes',
  initialState,
  reducers: {
    saveEmpTaxes: (state, action: PayloadAction<IGetInitialState>) => {
      state.validationResults = action.payload.validationResults;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(persistEmployerTaxes.fulfilled, (state, action) => {
      state.validationResults = action.payload.validationResults;
    });
  },
});

export const { saveEmpTaxes } = persistEmployerTaxesSlice.actions;
export const persistEmpTaxesReducer = persistEmployerTaxesSlice.reducer;
export const persistEmployerTaxesList = (state: RootState) => state.persistEmpTaxesReducer;
