/* i18n defaults**/

export type SupportedLanguageCode = 'en-ca' | 'fr-fr' | 'en-ud';

type SupportedLanguage = {
  code: SupportedLanguageCode;
  name: string;
};

export const defaultLanguage: SupportedLanguageCode = 'en-ca';

export const supportedLanguages: SupportedLanguage[] = [
  { code: 'en-ca', name: 'English' },
  { code: 'fr-fr', name: 'French' },
  { code: 'en-ud', name: 'English-UD' },
];
