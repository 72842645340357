import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IStateList, GetStateListRequest } from 'services/company-access/companyAccess.contracts';
import { GetStateList } from 'services/company-access/companyAccess.service';

const initialState: IStateList = {
  statesList: [],
};

export const getStateList = createAsyncThunk('company/getStateList', async (request: GetStateListRequest) => {
  const response = await GetStateList(request);
  return response;
});

export const stateSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    updateStateList: (state, action: PayloadAction<IStateList>) => {
      state.statesList = action.payload.statesList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStateList.fulfilled, (state, action) => {
      state.statesList = action.payload.stateList;
    });
  },
});

export const { updateStateList } = stateSlice.actions;
export const stateReducer = stateSlice.reducer;
export const selectStateList = (state: RootState) => state.stateReducer;
export const selectStatesList = (state: RootState) => state.stateReducer.statesList;
