import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { clearSentryUserContext, getLogoutUrl, getTokenRefreshUrl } from 'util/utility';
import { ValidationSeverityEnum } from './shared/validationResults.contracts';
import { publish } from 'util/customEvents';
import { BANNER_EVENT, LOG_SENTRY_ERROR } from 'util/customEvents/eventContracts';

declare global {
  interface Window {
    bannerActive: boolean;
  }
}

export const BASE_URL =
  process.env.NODE_ENV == 'development'
    ? process.env.REACT_APP_PROXY_URL
    : process.env.REACT_APP_BASE_URL + '/services/';

export const apiConfig: AxiosRequestConfig = {
  // timeout: 7500,
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Strict-Transport-Security': 'max-age=31536000',
    },
  },
};

const Instance: AxiosInstance = axios.create(apiConfig);
Instance.interceptors.request.use((request: AxiosRequestConfig) => {
  if (process.env.NODE_ENV == 'development') {
    request.headers = {
      authorization: `Bearer ` + localStorage.getItem('wpToken'),
    };
  }
  return request;
});

Instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    // TODO: will be removed after the logic is tested`
    if (error?.response?.status == '403') {
      console.log(error?.response, 'error response=============');
    }
    if (error?.response?.status == '403' && error?.response?.headers['x-cpi-forbiddenreason'] === 'LogoutForced') {
      clearSentryUserContext();
      window.location.href = getLogoutUrl();
    }
    return Promise.reject(error);
  }
);

export default Instance;

const tempErrorObject: unknown = {
  validationResultList: [
    {
      validationMessage: 'Oops something went wrong!',
      severity: ValidationSeverityEnum.NotDefined,
      validationCode: 0,
      validationTag: '',
      isValid: false,
    },
  ],
};
const tempExpiryObject: unknown = {
  validationResultList: [
    {
      validationMessage: 'Expired Token!!',
      severity: ValidationSeverityEnum.NotDefined,
      validationCode: 0,
      isValid: false,
    },
  ],
  expired: true,
};

const initSentryError = ({ error, request }: { error: any; request: any }) => {
  publish({ eventName: LOG_SENTRY_ERROR, data: { error, request, response: error?.response?.data } });
};

export const Post = <T, R>(url: string, request: T, config?: AxiosRequestConfig): Promise<R> => {
  return new Promise<R>((resolve) => {
    try {
      Instance.post(url, request, config)
        .then((res: AxiosResponse<R>) => {
          if (!window.bannerActive) {
            window.bannerActive = true;
            publish({ eventName: BANNER_EVENT, data: null });
          }
          resolve(res.data);
        })
        .catch((err) => {
          if (err?.response?.headers['x-cpi-forbiddenreason'] === 'LogoutForced') {
            clearSentryUserContext();
            window.location.href = getLogoutUrl();
            return;
          }
          if (err.response && ['400'].includes(err?.response?.status?.toString())) {
            resolve(err.response.data);
          } else if (err.response && err.response.status === 403) {
            initSentryError({ error: err, request });
            // new logic added to handle scenerios when the token expires
            resolve(tempExpiryObject as R);
          } else {
            initSentryError({ error: err, request });
            resolve(tempErrorObject as R);
          }
        });
    } catch (error: any) {
      initSentryError({ error: error, request });
      resolve(tempErrorObject as R);
    }
  });
};

export const Get = <R>(url: string, config?: AxiosRequestConfig): Promise<R> => {
  return new Promise<R>((resolve, reject) => {
    try {
      Instance.get(url, config)
        .then((res: AxiosResponse<R>) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.status == '301' || err.response?.status == '302' || err.response?.status == '501') {
            const refreshurl = getTokenRefreshUrl();
            window.location.href = refreshurl;
          } else if (err.response && err.response.status == '400') {
            resolve(err.response.data);
          } else {
            resolve(err);
          }
        });
    } catch (error) {
      reject('exception');
    }
  });
};
