import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { GlobalStyles } from 'styles/globalStyles';
import WpBreadCrumbs, { IWpBreadCrumb } from '@wagepoint/ui-toolkit/components/wp-breadcrumbs/wpBreadCrumbs';
import clsx from 'clsx';

import PermissionSettings from '../../useraccounts/permissions/index';
import { UserAccountsTypeEnum } from '../../useraccounts/roles/rolesContainer';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import CardContainer from './components/cardContainer';
import StatementsContainer from './components/statementContainer';
import TitleContainer from './components/titleContainer';
import { selectFirmIsEdit, selectFirmName, setFirmView } from 'redux/slice/accountingFirmSlice';
import { ManageViewOfAccountingFirm } from '..';
import { useTwoStepEnabled } from '../../useraccounts/permissionSettingsHook';
import { selectAccountingAssignedRole } from 'redux/slice/userAccountsSlice';

const PayrollAdministrator = 3;

const FirmPermissionsContainer: React.VFC = () => {
  const globalClass = GlobalStyles();
  const { t } = useTranslation(['userAccounts']);
  const name = useAppSelector(selectFirmName);
  const isEdit = useAppSelector(selectFirmIsEdit);
  const { isTwoStepApprovalEnabled } = useTwoStepEnabled();
  const assignedRole = useAppSelector(selectAccountingAssignedRole);

  const dispatch = useAppDispatch();

  const [editValue, setEditValue] = useState<boolean>(false);

  useEffect(() => {
    if (isEdit) setEditValue(isEdit);
  }, [isEdit]);

  const codeBreadCrumbs: Array<IWpBreadCrumb> = [
    {
      label: name || t('firmPermissionsContainer.firmName1'),
      onClick: () => {
        dispatch(
          setFirmView({
            firmView: ManageViewOfAccountingFirm.FirmInviteSent,
            isEdit: false,
            from: ManageViewOfAccountingFirm.FirmPermission,
          })
        );
      },
    },
    {
      label: t('firmPermissionsContainer.permissions'),
    },
  ];

  const updateEditValue = () => {
    setEditValue(true);
  };

  const handleCloseHandler = () => {
    dispatch(
      setFirmView({
        firmView: ManageViewOfAccountingFirm.FirmInviteSent,
        isEdit: false,
        from: ManageViewOfAccountingFirm.FirmPermission,
      })
    );
  };

  return (
    <>
      <Box className={clsx(globalClass.breadcrumbContainer, globalClass.padT8)}>
        <WpBreadCrumbs list={codeBreadCrumbs} className={clsx(globalClass.padT8)} />
      </Box>
      <TitleContainer updateEditValue={updateEditValue} />
      {isTwoStepApprovalEnabled && assignedRole === PayrollAdministrator && <StatementsContainer />}
      <CardContainer />
      <PermissionSettings
        from={UserAccountsTypeEnum.AccountingFirm}
        value={editValue}
        handleClose={handleCloseHandler}
      />
    </>
  );
};

export default FirmPermissionsContainer;
