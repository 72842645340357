import { getApiValidationErrors } from 'util/utility';
import { useLoaders } from 'redux/hooks/useLoaders';

interface IWrapApiCallParams<T> {
  onSuccess?: (response?: T) => void;
  onError?: (message: string) => void;
}

export const useWrapActionApi = () => {
  const { showLoader, hideLoader } = useLoaders();

  return async <P, R>(apiCall: (req: P) => Promise<R>, request: P, config?: Partial<IWrapApiCallParams<R>>) => {
    try {
      showLoader();
      const response = await apiCall(request);
      const { hasError, message } = getApiValidationErrors(response);
      if (hasError) {
        throw new Error(message);
      }
      config?.onSuccess?.(response);
    } catch (e: any) {
      config?.onError?.(e.message);
    } finally {
      hideLoader();
    }
  };
};
