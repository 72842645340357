import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IGetChartOfAccountsRequest,
  IGetChartOfAccountsResponse,
  IDepartmentList,
  IAccountMapGroupList,
} from 'services/integrations-access/integrationsAccess.contracts';
import { GetChartOfAccounts } from 'services/integrations-access/integrationsAccess.service';

interface IAllStatuses {
  hasDepartments: boolean;
  chartOfAccounts: Array<IDepartmentList>;
  generalAccounts: Array<IAccountMapGroupList>;
  departmentsAccounts: Array<IDepartmentList>;
  payrollFees: IAccountMapGroupList;
  advancedPayrolls: Array<IAccountMapGroupList>;
  fulfilled: boolean;
  loading: boolean;
  whoseDataIsLoading?: string;
  error: boolean;
  errorText?: string | unknown;
}

const initialState: IAllStatuses = {
  generalAccounts: [],
  chartOfAccounts: [],
  departmentsAccounts: [],
  hasDepartments: false,
  payrollFees: { validationId: '', name: '', description: '', journalMapType: 0, sortOrder: 0, accountMapList: [] },
  advancedPayrolls: [],
  fulfilled: false,
  loading: false,
  whoseDataIsLoading: '',
  error: false,
  errorText: '',
};

function filterGeneral(departmentList: Array<IDepartmentList>) {
  return departmentList.filter((list) => !list['name'])[0];
}

function filterDepartments(departmentList: Array<IDepartmentList>) {
  return departmentList.filter((list) => list['name']);
}

function sortAccounts(accounts: Array<IAccountMapGroupList>) {
  return accounts.sort((a, b) => a.sortOrder - b.sortOrder);
}

function sortDepartments(departments: Array<IDepartmentList>) {
  return departments.map((department) => ({
    ...department,
    accountMapGroupList: sortAccounts(department.accountMapGroupList),
  }));
}

export const getChartOfAccounts = createAsyncThunk(
  '/addons/getChartOfAccounts',
  async (request: IGetChartOfAccountsRequest) => {
    const response: IGetChartOfAccountsResponse = await GetChartOfAccounts(request);

    return response;
  }
);

export const getChartOfAccountsSlice = createSlice({
  name: 'get-chart-of-accounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChartOfAccounts.pending, (state, action) => {
        state.loading = true;
        state.fulfilled = false;
        state.whoseDataIsLoading = action.payload;
      })
      .addCase(getChartOfAccounts.fulfilled, (state, action) => {
        if (action.payload.departmentList && action.payload.departmentList.length > 0) {
          const sortedDepartments = sortAccounts(filterGeneral(action.payload.departmentList).accountMapGroupList);
          const departmentsAccounts = sortDepartments(filterDepartments(action.payload.departmentList));
          const hasDepartments = departmentsAccounts.length > 0;

          state.generalAccounts = hasDepartments ? sortedDepartments.slice(1) : [];
          state.chartOfAccounts = action.payload.departmentList;
          state.departmentsAccounts = departmentsAccounts;
          state.payrollFees = { ...sortedDepartments[0], validationId: sortedDepartments[0].accountMapList[0].name };
          state.advancedPayrolls = sortedDepartments.slice(1);
          state.hasDepartments = hasDepartments;
          state.error = false;
        } else state.error = true;
        state.loading = false;
        state.fulfilled = true;
      })
      .addCase(getChartOfAccounts.rejected, (state, action) => {
        state.error = true;
        state.fulfilled = false;
        state.loading = false;
        state.errorText = action.payload;
      });
  },
});

export const getChartOfAccountsReducer = getChartOfAccountsSlice.reducer;
