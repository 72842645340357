import { Post } from 'services/httpClient';
import {
  GetEmployerTaxesRequest,
  GetEmployerTaxesResponse,
  GetInitialStateRequest,
  GetInitialStateResponse,
  GetRemainingTaxCreditRequest,
  GetRemainingTaxCreditResponse,
  GetSourceDeductionsRequest,
  GetSourceDeductionsResponse,
  PersistEmployerTaxesRequest,
  PersistEmployerTaxesResponse,
  PersistSourceDeductionsRequest,
  PersistSourceDeductionsResponse,
  RemoveCompanyTaxSettingRequest,
  RemoveCompanyTaxSettingResponse,
} from './taxSettingsAccess.contracts';

const url = (endPoint: string) => `payrollCompanyTaxSettingsAccess/${endPoint}`;

export const GetInitialState = (request: GetInitialStateRequest): Promise<GetInitialStateResponse> => {
  return Post(url('GetInitialState'), request);
};

export const GetSourceDeductions = (request: GetSourceDeductionsRequest): Promise<GetSourceDeductionsResponse> => {
  return Post(url('GetSourceDeductions'), request);
};

export const PersistSourceDeductions = (
  request: PersistSourceDeductionsRequest
): Promise<PersistSourceDeductionsResponse> => {
  return Post(url('PersistSourceDeductions'), request);
};

export const GetEmployerTaxes = (request: GetEmployerTaxesRequest): Promise<GetEmployerTaxesResponse> => {
  return Post(url('GetEmployerTaxes'), request);
};

export const PersistEmployerTaxes = (request: PersistEmployerTaxesRequest): Promise<PersistEmployerTaxesResponse> => {
  return Post(url('PersistEmployerTaxes'), request);
};

export const RemoveCompanyTaxSetting = (
  request: RemoveCompanyTaxSettingRequest
): Promise<RemoveCompanyTaxSettingResponse> => {
  return Post(url('RemoveCompanyTaxSetting'), request);
};

export const GetRemainingTaxCredit = (
  request: GetRemainingTaxCreditRequest
): Promise<GetRemainingTaxCreditResponse> => {
  return Post(url('GetRemainingTaxCredit'), request);
};
