import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { IBank } from 'services/company-bank-access/companyBankAccess.contracts';
import {
  IPersistProfileDocumentRequest,
  IPersistProfileDocumentResponse,
  IPerson,
} from 'services/person-access/personAccess.contracts';
import { savePersonDoc } from 'services/person-access/personAccess.service';
import { IBankAccount, PaymentMethodTypeEnum } from 'services/person-bank-access/personBankAccess.contracts';
import { IStateConfig } from 'services/priorpayroll-access/priorpayrollAccess.contracts';
export interface IBasicInfo {
  employeeDetails: {
    employeeInfo: {
      employeeStateType: number | null;
      employementStateId: number | null;
      employmentStateList: any[];
      payGroupId?: number;
    } | null;
    person: null | IPerson;
  };
  actualEmploymentState: any[];
  isEditMode: boolean;
  skipLogicFields: {
    firstName: string | null;
    lastName: string | null;
    provinceEmployment: number | null;
  };
  tempPastProvinces: any[];
  terminatedFormData: {
    hireDate: null | string;
    lastWorkingDate: string | null;
    payGroupId: number | null;
  };
  removedStates: number[];
  personJobList: any[];
  loading: boolean;
  hadPersisted: boolean;
  includeAllJobs: boolean;
  employementProvincesId: IStateConfig[];
  maskedObj: { [key: string]: boolean };
  getSelfQuestionList: any[];
  bankDetails: { list: IBank[]; data: IBankAccount[]; paymentMethodType: PaymentMethodTypeEnum };
}

const initialState: IBasicInfo = {
  employeeDetails: {
    person: null,
    employeeInfo: null,
  },
  tempPastProvinces: [],
  removedStates: [],
  skipLogicFields: {
    firstName: null,
    lastName: null,
    provinceEmployment: null,
  },
  isEditMode: false,
  hadPersisted: false,
  maskedObj: {},
  personJobList: [],
  includeAllJobs: false,
  terminatedFormData: {
    payGroupId: null,
    hireDate: null,
    lastWorkingDate: null,
  },
  employementProvincesId: [], // for prior payroll to get the state before calling getPayroll
  actualEmploymentState: [],
  bankDetails: { list: [], data: [], paymentMethodType: PaymentMethodTypeEnum.NotDefined },
  loading: true,
  getSelfQuestionList: [],
};

export const saveProfileImage = createAsyncThunk(
  'onboard/myinfo/saveProfileDocument',
  async (request: IPersistProfileDocumentRequest) => {
    const response: IPersistProfileDocumentResponse = await savePersonDoc(request);
    return response;
  }
);
const peopleBasicInfoSlice = createSlice({
  name: 'peoplebasicinfo',
  initialState,
  reducers: {
    updateBasicInfoAction: (state, action: PayloadAction<{ key: keyof IBasicInfo; value: any }>) => {
      state[action.payload.key] = action.payload.value;
    },
    updateSkippableLogic: (state, action: PayloadAction<{ name: keyof IBasicInfo['skipLogicFields']; value: any }>) => {
      state.skipLogicFields[action.payload.name] = action.payload.value;
    },
    updateCustomizePP: (state, action) => {
      if (action.payload.isContractor) {
        // the contractores doesn't have employmentProvince
        state.includeAllJobs = action.payload.value;
      } else state.employementProvincesId = action.payload.value;
    },
    updateEmployementState: (state, action: PayloadAction<{ value: any }>) => {
      state.employeeDetails.employeeInfo = {
        employmentStateList: action.payload.value,
        employementStateId: state.employeeDetails.employeeInfo?.employementStateId || null,
        employeeStateType: state.employeeDetails.employeeInfo?.employeeStateType || null,
      };
    },
    updateRemovedProvince: (state, action: PayloadAction<{ value: any }>) => {
      state.removedStates = action.payload.value;
    },
    resetBasicInfo: (state) => {
      state.employeeDetails = initialState.employeeDetails;
      state.terminatedFormData = initialState.terminatedFormData;
      state.personJobList = [];
      state.bankDetails = initialState.bankDetails;
    },
  },
});

export const {
  updateBasicInfoAction,
  updateRemovedProvince,
  updateEmployementState,
  resetBasicInfo,
  updateSkippableLogic,
  updateCustomizePP,
} = peopleBasicInfoSlice.actions;
export const peopleBasicInfoReducer = peopleBasicInfoSlice.reducer;
export const getPeopleBasicInfoStates = (state: RootState) => state.peopleBasicInfoReducer;
export const getActualStateSelector = (state: RootState) =>
  state.peopleBasicInfoReducer.employeeDetails.employeeInfo?.employementStateId;
export const getEmployeeInfoSelector = (state: RootState) => state.peopleBasicInfoReducer.employeeDetails;
export const selectEmployeeInfo = (state: RootState) => state.peopleBasicInfoReducer.employeeDetails?.employeeInfo;

export const selectEmployeePerson = (state: RootState) => state.peopleBasicInfoReducer.employeeDetails?.person;
export const getPersonJobListSelector = (state: RootState) => state.peopleBasicInfoReducer.personJobList;
export const isBasicInfoLoading = (state: RootState) => state.peopleBasicInfoReducer.loading;
export const bareMinFieldsBasicInfoSelector = (state: RootState) => state.peopleBasicInfoReducer.skipLogicFields;
export const getSelfQuestionsList = (state: RootState) => state.peopleBasicInfoReducer.getSelfQuestionList;
export const getPersonBankDetails = (state: RootState) => state.peopleBasicInfoReducer.bankDetails;
export const getPersonBankDetailsData = (state: RootState) => state.peopleBasicInfoReducer.bankDetails?.data;
export const getPersonBankDetailsPaymentMethodType = (state: RootState) =>
  state.peopleBasicInfoReducer.bankDetails?.paymentMethodType;
export const maskedObjSelector = (state: RootState) => state.peopleBasicInfoReducer.maskedObj;
export const hadPersistOccuredSelector = (state: RootState) => state.peopleBasicInfoReducer.hadPersisted;
export const isEditModeSelector = (state: RootState) => state.peopleBasicInfoReducer.isEditMode;
export const removedStateSelector = (state: RootState) => state.peopleBasicInfoReducer.removedStates;
export const actualEmploymentStateSelector = (state: RootState) => state.peopleBasicInfoReducer.actualEmploymentState;
export const tempEmploymentStateSelector = (state: RootState) => state.peopleBasicInfoReducer.tempPastProvinces;
export const employementProvincesIdSelector = (state: RootState) => state.peopleBasicInfoReducer.employementProvincesId;
export const employeeIncludesAllJobSelector = (state: RootState) => state.peopleBasicInfoReducer.includeAllJobs;
