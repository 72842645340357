import { createStyles, makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';
import '@wagepoint/ui-toolkit/styles/fonts.css';

export const GlobalStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    '@global': {
      html: {
        height: '100%',
      },
      body: {
        fontFamily: theme.typography.fontFamily,
        margin: 0,
        height: '100%',
        '-webkit-font-smoothing': 'antialiased',
        color: theme.palette.text.primary,
      },
      '#root': {
        height: '100%',
        backgroundColor: theme.palette.primarycolor.lightGrey,
        '-webkit-font-smoothing': 'antialiased',
        boxSizing: 'border-box',
      },
      'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus, input:-webkit-autofill::first-line':
        {
          transition: 'background-color 9999s ease-in-out 0s',
        },
      '#ada-button-frame': {
        display: 'none',
      },
    },
    wpBody: {
      display: 'flex',
      flex: '1',
      overflow: 'hidden',
    },
    wpPortal: {
      height: '100dvh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    ptl0: {
      paddingTop: `0 !important`,
      paddingLeft: `0 !important`,
    },
    noXpadding: {
      paddingRight: `0 !important`,
      paddingLeft: `0 !important`,
    },
    noYpadding: {
      paddingTop: `0 !important`,
      paddingBottom: `0 !important`,
    },
    lastGridItem: {
      paddingRight: `0 !important`,
    },
    datalabel: {
      ...theme.tag?.p1Bold,
      color: theme.palette.primarycolor?.black,
      paddingBottom: theme.spacing(0.5),
    },
    datavalue: {
      ...theme.tag?.p1,
      color: theme.palette.primarycolor?.black,
      paddingTop: 0,
      paddingLeft: 0,
    },
    inlineblock: {
      display: 'inline-block',
    },
    displayend: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    displaybottom: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    cardAction: {
      float: 'right',
      '& .MuiButton-label': {
        ...theme.tag?.p2,
        padding: '0 !important',
        color: theme.palette.accent?.color3,
        textTransform: 'capitalize',
      },
      '& svg': {
        fontSize: '14px !important',
      },
    },
    lastSavedDate: {
      display: 'inline-block',
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      marginLeft: '17px',
    },
    margin0: {
      margin: '0 !important',
    },
    noMarginSoft: {
      margin: 0,
    },
    marginB0: {
      marginBottom: 0,
    },
    marginL0: {
      marginLeft: 0,
    },
    marginL8: {
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      marginLeft: 8,
    },
    marginT8: {
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      marginTop: 8,
    },
    validation: {
      ...theme.tag?.p3,
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      marginTop: '10px',
      color: theme.palette.error.main,
    },
    linkButton: {
      '& .MuiButton-label': {
        ...theme.tag?.p2,
        padding: '0 !important',
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
      },
      '& svg': {
        fontSize: '14px !important',
      },
    },
    linkText: {
      // ...theme.tag?.p2,
      color: theme.palette.accent.color3,
    },

    h1: {
      ...theme.typography.h1,
    },
    h2: {
      ...theme.typography.h2,
    },
    h3: {
      ...theme.typography.h3,
    },
    h4: {
      ...theme.typography.h4,
    },
    h6: {
      ...theme.typography.h6,
    },
    h7: {
      ...theme.typography.h6,
      fontWeight: 600,
    },
    p1: {
      ...theme.tag?.p1,
    },
    p1Bold: {
      ...theme.tag?.p1Bold,
    },
    body1: {
      ...theme.tag.p1,
    },
    body2: {
      ...theme.tag.p1Bold,
    },
    p2: {
      ...theme.tag?.p2,
    },
    p3: {
      ...theme.tag.p3,
    },
    padB0: {
      paddingBottom: '0 !important',
    },
    padR0: {
      paddingRight: '0 !important',
    },
    padL0: {
      paddingLeft: '0 !important',
    },
    padT0: {
      paddingTop: '0 !important',
    },
    padB8: {
      paddingBottom: `${theme.spacing(1)}px !important`,
    },
    padR8: {
      paddingRight: `${theme.spacing(1)}px !important`,
    },
    padL8: {
      paddingLeft: `${theme.spacing(1)}px !important`,
    },
    padT8: {
      paddingTop: `${theme.spacing(1)}px !important`,
    },
    breadcrumbContainer: {
      paddingTop: theme.spacing(3),
      background: theme.palette.primarycolor?.lightGrey,
    },
    noPadding: {
      paddingTop: '0px',
      paddingRight: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
    },
    noMargin: {
      marginTop: '0px',
      marginRight: '0px',
      marginBottom: '0px',
      marginLeft: '0px',
    },
    pagesContainer: {
      position: 'relative',
      height: '100%',
      width: '100%',
    },
    gridItemFix: {
      paddingRight: 0,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
    },
    py1: { paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) },
    py2: { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) },
    py3: { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
    px0: { paddingLeft: theme.spacing(0), paddingRight: theme.spacing(0) },
    px1: { paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
    px2: { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },
    px3: { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
    ptHalf: {
      paddingTop: theme.spacing(0.5),
    },
    prHalf: {
      paddingRight: theme.spacing(0.5),
    },
    pbHalf: {
      paddingBottom: theme.spacing(0.5),
    },
    plHalf: {
      paddingLeft: theme.spacing(0.5),
    },
    pxHalf: { paddingLeft: theme.spacing(0.5), paddingRight: theme.spacing(0.5) },
    pt0: {
      paddingTop: theme.spacing(0),
    },
    pr0: {
      paddingRight: theme.spacing(0),
    },
    pb0: {
      paddingBottom: theme.spacing(0),
    },
    pl0: {
      paddingLeft: theme.spacing(0),
    },
    py0: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    pt1: {
      paddingTop: theme.spacing(1),
    },
    pr1: {
      paddingRight: theme.spacing(1),
    },
    pb1: {
      paddingBottom: theme.spacing(1),
    },
    pl1: {
      paddingLeft: theme.spacing(1),
    },
    pt2: {
      paddingTop: theme.spacing(2),
    },
    pr2: {
      paddingRight: theme.spacing(2),
    },
    pb2: {
      paddingBottom: theme.spacing(2),
    },
    pl2: {
      paddingLeft: theme.spacing(2),
    },
    mt0: {
      marginTop: theme.spacing(0),
    },
    mr0: {
      marginRight: theme.spacing(0),
    },
    mb0: {
      marginBottom: theme.spacing(0),
    },
    ml0: {
      marginLeft: theme.spacing(0),
    },
    mbHalf: {
      marginBottom: theme.spacing(0.5),
    },
    mtHalf: {
      marginTop: theme.spacing(0.5),
    },
    mlHalf: {
      marginLeft: theme.spacing(0.5),
    },
    mrHalf: {
      marginRight: theme.spacing(0.5),
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mr2: {
      marginRight: theme.spacing(2),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    ml2: {
      marginLeft: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    mr3: {
      marginRight: theme.spacing(3),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    mb4: {
      marginBottom: theme.spacing(4),
    },
    ml3: {
      marginLeft: theme.spacing(3),
    },
    mt4: {
      marginTop: theme.spacing(4),
    },
    my0: { marginTop: theme.spacing(0), marginBottom: theme.spacing(0) },
    my1: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
    my2: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
    my3: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
    mx0: { marginLeft: theme.spacing(0), marginRight: theme.spacing(0) },
    mx1: { marginLeft: theme.spacing(1), marginRight: theme.spacing(1) },
    mx2: { marginLeft: theme.spacing(2), marginRight: theme.spacing(2) },
    mx3: { marginLeft: theme.spacing(3), marginRight: theme.spacing(3) },
    standardCard: {
      padding: theme.spacing(2),
    },
    p1Card: {
      padding: theme.spacing(1),
    },
    wFull: {
      width: '100%',
    },
    wHalf: {
      width: '50%',
    },
    wQuart: {
      width: '25%',
    },
    hFull: {
      height: '100%',
    },
    cardFix: {
      height: '100%',
      boxSizing: 'border-box',
    },
    cardItemsFix: {
      '& .MuiGrid-item': {
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 0,
      },
    },
    accordionFix: {
      border: `1px solid ${theme.palette.common.lightbrown}`,
      boxShadow: 'none',
    },
    defaultRadius: {
      borderTopLeftRadius: `6px !important`,
      borderTopRightRadius: `6px !important`,
      borderBottomLeftRadius: `6px !important`,
      borderBottomRightRadius: `6px !important`,
    },
    pointer: {
      cursor: 'pointer',
    },
    footerContainer: {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      textAlign: 'right',
      '& .MuiGrid-item': {
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 0,
      },
    },
    pageHeading: {
      margin: theme.spacing(1, 0),
    },
    blackText: {
      color: theme.palette.primarycolor.black,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    capitalFirst: {
      textTransform: 'lowercase',
      '&:first-letter': { textTransform: 'capitalize' },
    },
    sectionHeading: {
      // for h2s outside of a card
      marginBottom: theme.spacing(1),
    },
    cardHeading: {
      // TODO: this will be deleted, we will use CardHeader component instead of placing H3 in card
      marginBottom: theme.spacing(2),
    },
    gapHalf: {
      gap: theme.spacing(0.5),
    },
    gap1: {
      gap: theme.spacing(1),
    },
    gap2: {
      gap: theme.spacing(2),
    },
    gap3: {
      gap: theme.spacing(3),
    },
    rowGap1: {
      rowGap: theme.spacing(1),
    },
    rowGap2: {
      rowGap: theme.spacing(2),
    },
    rowGap3: {
      rowGap: theme.spacing(3),
    },
    gridItem_2col_gap3: {
      flex: `1 1 100%`,
      [theme.breakpoints.up('sm')]: {
        flex: `0 0 calc(50% - ${theme.spacing(1.5)}px)`,
      },
    },
    cardLink: {
      cursor: 'pointer',
      '& a': {
        textDecoration: 'none',
      },
      '&:hover': {
        boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
        border: `1px solid ${theme.palette.common.lightbrown}`,
      },
      '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
    textLeft: {
      textAlign: 'left',
    },
    textRight: {
      textAlign: 'right',
    },
    textCenter: {
      textAlign: 'center',
    },
    displayInline: {
      display: 'inline',
    },
    fieldLabel: {
      marginBottom: theme.spacing(0.5),
    },
    // widths for forms
    formSm: {
      maxWidth: '578px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formMd: {
      maxWidth: '776px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formLg: {
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    formContainer: {
      maxWidth: '960px',
      margin: 'auto',
    },

    gridCard: {
      height: '100%',
      width: '100%',
    },

    selfStretch: {
      alignSelf: 'stretch',
    },
    noMinWidth: {
      minWidth: 0,
    },
    cardMinWidth: {
      minWidth: '281px',
    },
    trouserWidth: {
      '& .MuiDrawer-paper': {
        overflowX: 'hidden',
      },
    },
    iconSuccess: {
      color: theme.palette.success.main,
    },
    iconDisabled: {
      color: theme.palette.primarycolor.primaryLightBrown,
    },
    iconContainer: {
      width: '20px',
      height: '20px',
      verticalAlign: 'top',
    },
    inlineIconContainer: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      verticalAlign: 'sub',
      // TODO: replace value with theme.spacing
      // eslint-disable-next-line no-restricted-syntax
      padding: '1px 0',
      marginRight: theme.spacing(0.5),
    },
    dflex: {
      display: 'flex',
    },
    dflexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    truncate: {
      display: 'inline-block',
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    prewrap: {
      whiteSpace: 'pre-wrap',
    },
    labelWithTooltip: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    textDisabled: {
      color: theme.palette.text.disabled,
    },
    scrollable: {
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '0.5em',
        height: '100%',
        background: 'none',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.highlight,
      },
      '&::-webkit-scrollbar-thumb': {
        // TODO: use theme.palette
        // eslint-disable-next-line no-restricted-syntax
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: 6,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        // TODO: use theme.palette
        // eslint-disable-next-line no-restricted-syntax
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    rounded: {
      borderRadius: '6px !important',
    },
    disabled: {
      opacity: 0.5,
    },
    cardLinkFix: {
      '& .MuiCard-root': {
        minWidth: 0,
        height: '100%',
        wordBreak: 'break-word',
      },
    },
    inputMaxShort: {
      '& .MuiFormControl-root': {
        maxWidth: 300,
        display: 'block',
      },
    },
    checklistIcon: {
      minWidth: '18px',
      height: '18px',
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.lightbrown}`,
      marginRight: theme.spacing(0.5),
    },
    // print text
    printP1: {
      fontSize: 14,
      fontWeight: 'normal',
    },
    printP1Bold: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    printH: {
      fontSize: '18px !important',
      fontWeight: 'bold',
    },
    printTypography: {
      '@media print': {
        '& .MuiTypography-root': {
          fontSize: 14,
          fontWeight: '400',
        },
        '& .MuiTableCell-body': {
          fontSize: '14px !important',
        },
      },
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    maxImgSmall: {
      '& img, svg': {
        maxHeight: '150px',
      },
    },
    maxImgMed: {
      '& img, svg': {
        maxHeight: '250px',
      },
    },
  })
);
