import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import WpDropdown from '@wagepoint/ui-toolkit/components/wp-dropdown/wpDropdown';
import WpStatements from '@wagepoint/ui-toolkit/components/wp-statements/wpStatements';
import WpLink from '@wagepoint/ui-toolkit/components/wp-link/wpLink';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';
import PermissionSettings from './permissions/index';
import { FormProvider, useForm } from 'react-hook-form';
import { getApiValidationErrors } from 'util/utility';
import { UserAccountsTypeEnum } from './roles/rolesContainer';
import {
  PersonTypeEnum,
  IGetSecurityRoleRequest,
  IGetAdministratorListRequest,
} from 'services/security-access/securityAccess.contracts';
import { GetLocationList, GetDepartmentList } from 'services/company-access/companyAccess.service';
import {
  GetLocationListRequest,
  GetLocationListResponse,
  GetDepartmentListRequest,
  GetDepartmentListResponse,
  IDepartment,
  ILocation,
} from 'services/company-access/companyAccess.contracts';
import { GetUser, GetSecurityRole, GetAdminList } from 'services/security-access/securityAccess.service';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks/hook';
import { selectCompanyId } from 'redux/slice/configSlice';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SecurityRoleTypeEnum } from 'services/shared/securityRoleTypeEnum';
import { hideLoading, showLoading } from 'redux/slice/loadingIndicatorSlice';
import { roleList, displayRoleList } from './common';
import TransferOwnership from './roles/transferOwnership';
import { GlobalStyles } from 'styles/globalStyles';
import { selectIsFromTransfer, setTransferState } from 'redux/slice/userAccountsSlice';
import { IDepartmentList } from './permissions/index';
import { useViewPermissionByPartPath } from 'util/customHooks/useViewPermission';
import { EditViewStyles } from './editViewStyles';
import { IDepartmentUi } from './common/interface/userAccountsInterface';
import clsx from 'clsx';
import { getApplicationType } from 'util/utility';
import { ApplicationTypeEnum } from 'shared/services/appCore/schema';

export enum TransferEnum {
  transfer = 'transfer',
}

interface IDepartments {
  id: number;
  name: string;
}

const EditView: React.FC = () => {
  const classes = EditViewStyles();
  const globalClasses = GlobalStyles();
  const { t } = useTranslation(['userAccounts']);
  const history = useHistory();
  const location = useLocation();
  const path = useRouteMatch();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectCompanyId);
  const isFromTransfer = useAppSelector(selectIsFromTransfer);
  const { id }: { id: string } = useParams();

  const roleListArray = roleList(t);
  const applicationType = getApplicationType();

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [role, setRole] = React.useState<any>();
  const [userDetails, setUserDetails] = React.useState<any>();
  const [locationListForService, setLocationListForService] = React.useState<any>([]);
  const [locationList, setLocationList] = React.useState<Array<ILocation>>([]);
  const [departmentList, setDepartmentList] = React.useState<Array<any>>([]);
  const [departmentListForService, setDepartmentListForService] = React.useState<any>([]);
  const [objectToBePassed, setObjectToBePaased] = React.useState({});
  const [roleListToDisplay, setRoleListToDisplay] = React.useState(roleListArray);
  const [payrolladminCount, setPayrolladminCount] = React.useState(0);
  const [userListLength, setUserListLength] = React.useState<number>(0);
  const [isPayrollAdmin, setPayrollAdmin] = React.useState(false);
  const { isViewOnlyPermission } = useViewPermissionByPartPath();

  const validationSchema = yup.object().shape({
    ...(role === SecurityRoleTypeEnum.Manager && { department: yup.array().min(1, t('assignRole.validation')) }),
  });

  const permissionRef: any = React.useRef();

  const { details, from, totalCount, url } = (location.state as any) || {};

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { errors, trigger, getValues, setValue } = methods;

  useEffect(() => {
    const isEditMode = window.location.pathname.includes('/edit');
    setIsEdit(isEditMode);
  }, [path]);

  useEffect(() => {
    getSecurityRoleService();
  }, []);

  useEffect(() => {
    if (!isFromTransfer) return;
    permissionRef?.current?.getTransferUser();
    setTimeout(() => {
      dispatch(
        setTransferState({
          isFromTransfer: false,
        })
      );
    }, 500);
  });

  const getSecurityRoleService = async () => {
    const request: IGetSecurityRoleRequest = {
      securityRoleType: SecurityRoleTypeEnum.Administrator,
      includeUserList: true,
      includePermissionList: false,
      companyId,
    };

    const response: any = await GetSecurityRole(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0)
      setPayrolladminCount(response?.securityRole?.userCount);
  };

  const switchToEdit = (value: boolean) => {
    setIsEdit(value);
  };

  const closeBreadCrumbs = (from?: boolean) => {
    if (from) setIsEdit(false);
    else history.goBack();
  };

  useEffect(() => {
    if (isEdit) {
      getLocationList();
      getDeparmentList();

      setTimeout(() => {
        getUser();
      }, 500);
    }
  }, [isEdit]);

  useEffect(() => {
    getUser();
    getAdminList();
  }, []);

  useEffect(() => {
    setObjectToBePaased({
      securityRoleTypeList: [role],
      stateIdList: userDetails?.currentLocation ? userDetails?.currentLocation.map((item: any) => item.id) : [],
      departmentIdList: userDetails?.currentDepartment
        ? userDetails?.currentDepartment.map((item: any) => item.id)
        : [],
      locationList: locationList,
    });
  }, [userDetails]);

  useEffect(() => {
    const updatedLocationList = locationList.filter((item: any) => locationListForService.includes(item.stateId));

    setObjectToBePaased({
      ...objectToBePassed,
      stateIdList: locationListForService.length > 0 ? locationListForService : [],
      locationList: updatedLocationList,
    });
  }, [locationListForService]);

  useEffect(() => {
    setObjectToBePaased({
      ...objectToBePassed,
      departmentIdList: departmentListForService.length > 0 ? departmentListForService : [],
    });
  }, [departmentListForService]);

  useEffect(() => {
    setObjectToBePaased({
      ...objectToBePassed,
      securityRoleTypeList: [role],
      locationList: userDetails?.currentLocation,
    });
  }, [role]);

  const getAdminList = async () => {
    const request: IGetAdministratorListRequest = {
      companyId,
      includeAccountingAdmin: true,
    };
    const response: any = await GetAdminList(request);
    const { hasError } = getApiValidationErrors(response);
    if (!hasError) {
      setUserListLength(response.userList.length);
    } else {
      setUserListLength(0);
    }
  };

  const getUser = async () => {
    //In order to view permission of employee - the type should be employeePortal
    const appType =
      details?.personType === PersonTypeEnum.Employee && from === UserAccountsTypeEnum.People
        ? ApplicationTypeEnum.employeePortal
        : applicationType;

    dispatch(showLoading());
    const request: any = {
      userId: details?.id || id,
      includeSecurityRoleList: true,
      includePermission: true,
      ...(from === UserAccountsTypeEnum.People && {
        securityRoleType:
          details?.personType === PersonTypeEnum.Employee
            ? SecurityRoleTypeEnum.Employee
            : SecurityRoleTypeEnum.Contractor,
      }),
      applicationType: appType,
    };

    const response: any = await GetUser(request);
    if (response.validationResults && response.validationResults?.validationResultList?.length === 0) {
      /* To update permissions - table view */
      permissionRef?.current?.setPermissions(response);

      response?.securityRoleList.map((data: any) => {
        permissionRef?.current?.getCustomizationsForPeople(data.hasCustomization);
      });

      if (response.user?.securityRoleTypeList?.length) setRole(parseInt(response.user?.securityRoleTypeList.join()));
      const userObject = {
        currentRole:
          response.user?.securityRoleTypeList && response.user?.securityRoleTypeList.length > 0
            ? parseInt(response.user?.securityRoleTypeList.join())
            : '',
        name: response.user?.firstName + ' ' + response.user?.lastName,
        currentLocation: response.user?.stateList,
        currentDepartment: response.user?.departmentList,
      };
      setUserDetails(userObject);
      setPayrollAdmin(response?.user?.isPayrollApprover);

      //if it's a non-employee,need to disable the manager item from menu items
      if (response.user?.personType !== PersonTypeEnum.Employee)
        setRoleListToDisplay(
          roleListToDisplay.map((item) => (item.id == SecurityRoleTypeEnum.Manager ? { ...item, disable: true } : item))
        );

      dispatch(hideLoading());
    }
  };

  const getLocationList = async () => {
    const request: GetLocationListRequest = {
      skip: 0,
      take: 50,
      getByIdList: false,
      companyId,
    };
    const response: GetLocationListResponse = await GetLocationList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      const ids = response?.locationList.map((loc: any) => loc.stateId);
      const filtered = response?.locationList.filter(({ stateId }, index) => !ids.includes(stateId, index + 1));
      setLocationList(filtered);
    } else {
      setLocationList([]);
    }
  };

  const clearDepartmentsOnRoleChangeHandler = () => {
    setValue('department', []),
      setDepartmentListForService([]),
      setObjectToBePaased({
        ...objectToBePassed,
        departmentIdList: [],
      });
  };

  const displayCurrentDepartment = (from?: boolean) => {
    if (from) clearDepartmentsOnRoleChangeHandler();

    if (departmentListForService?.length) {
      const filtereddata = departmentList.filter((item: IDepartmentList) =>
        departmentListForService?.includes(item?.id)
      );
      if (filtereddata?.length) return removeDuplicates(filtereddata)?.map((data: IDepartmentList) => data?.id);
      else return [];
    } else {
      return from
        ? clearDepartmentsOnRoleChangeHandler()
        : userDetails?.currentDepartment.length > 0
        ? userDetails?.currentDepartment.map((item: IDepartmentList) => item?.id)
        : [];
    }
  };

  useEffect(() => {
    if (!isEdit) return;
    getDeparmentList();
  }, [role]);

  const getDeparmentList = async () => {
    dispatch(showLoading());
    const request: GetDepartmentListRequest = {
      companyId,
    };
    const response: GetDepartmentListResponse = await GetDepartmentList(request);
    if (response.validationResults && response?.validationResults?.validationResultList.length == 0) {
      dispatch(hideLoading());
      if (getValues().role == SecurityRoleTypeEnum.Manager) {
        const list: Array<IDepartment> = response?.departmentList.filter((item: any) => !item?.hasManager);
        setDepartmentList(list.concat(userDetails?.currentDepartment));
      } else {
        if (response?.departmentList.length == 0) {
          setRoleListToDisplay(
            roleListToDisplay.map((item) =>
              item.id == SecurityRoleTypeEnum.Manager
                ? { ...item, disable: true, hoverContent: t('transferOwnership.noManager') }
                : item
            )
          );
        } else {
          const departmentList = response?.departmentList.filter((item: any) => !item.hasManager);
          if (departmentList.length == 0) {
            setRoleListToDisplay(
              roleListToDisplay.map((item) =>
                item.id == SecurityRoleTypeEnum.Manager
                  ? { ...item, disable: true, hoverContent: t('transferOwnership.exceedManager') }
                  : item
              )
            );
          }
          if (role !== SecurityRoleTypeEnum.Manager) setDepartmentList(response?.departmentList);
          else setDepartmentList(departmentList.concat(userDetails?.currentDepartment));
        }
      }
    }
  };

  const handleLocationSelection = (e: any) => {
    setLocationListForService(e);
  };

  const handleDepartmentSelection = (e: any) => {
    const filteredList = departmentList.reduce((acc: number[], dep: IDepartmentUi) => {
      if (e.includes(dep?.id)) acc.push(dep.id);
      return acc;
    }, []);
    setValue('department', filteredList);
    trigger('department');
    setDepartmentListForService(e);
  };

  const handleTrigger = () => {
    trigger();
    // TODO: fix performance issue.
    // eslint-disable-next-line no-restricted-syntax
    return methods.watch()['department'].length;
  };

  const removeDuplicates = (array: Array<IDepartments>) => {
    const uniq: any = {};
    return array.filter((obj: IDepartments) => obj && !uniq[obj.id] && (uniq[obj.id] = true));
  };

  return (
    <>
      {url && url.includes(TransferEnum.transfer) ? (
        <>
          <WpTypography variant="h1" className={globalClasses.mb2}>
            {t('editView.transfer')}
          </WpTypography>
          <TransferOwnership dontShowTabs={true} />
        </>
      ) : (
        <>
          <Grid container spacing={3} className={classes.cardContainer}>
            <Grid item xs>
              <WpTypography variant="h1" className={clsx(globalClasses.mb2, globalClasses.mt1)}>
                {isEdit
                  ? `${t('permissionSettings.edit')} ${details?.firstName + ' ' + details?.lastName}`
                  : `${t('rolesContainer.view')} ${details?.firstName + ' ' + details?.lastName}`}
              </WpTypography>
            </Grid>
            {!isEdit && from !== UserAccountsTypeEnum.People && (
              <Grid item>
                {!isViewOnlyPermission && !details?.securityRoleTypeList.includes(SecurityRoleTypeEnum.AccountOwner) && (
                  <WpLink
                    isButton
                    onClick={() => {
                      switchToEdit(true);
                      history.push({
                        pathname: `${path?.url}/edit/${id}`,
                        state: {
                          details: details,
                          from: from,
                          totalCount: totalCount,
                        },
                      });
                    }}
                  >
                    {t('permissionSettings.edit')}
                  </WpLink>
                )}
              </Grid>
            )}
          </Grid>
          <WpTypography variant="h3" className={globalClasses.mb1}>
            {t('editView.userDetails')}
          </WpTypography>

          {from !== UserAccountsTypeEnum.People ? (
            <>
              {isEdit ? (
                userDetails?.currentRole === SecurityRoleTypeEnum.AccountOwner ? (
                  <Grid container>
                    <Grid item xs>
                      <WpTypography variant="p1Bold">{t('editView.roleLabel')}</WpTypography>
                      <div className={classes.accountOwnerCardContainer}>
                        <WpTypography>{displayRoleList(t).find((x) => x.id === role)?.name}</WpTypography>

                        <WpLink
                          disabled={userListLength == 1 ? true : false}
                          isButton
                          noGutter
                          onClick={() =>
                            history.push({
                              pathname: `${path?.url}/edit/${id}/transfer/${id}`,
                              state: {
                                securityRoleType: SecurityRoleTypeEnum.AccountOwner,
                                name: t('editView.transfer'),
                                details: details,
                                from: UserAccountsTypeEnum.Administrator,
                                url: `${path?.url}/edit/${id}/transfer/${id}`,
                                dontShowTabs: true,
                              },
                            })
                          }
                        >
                          {t('editView.transfer')}
                        </WpLink>
                      </div>
                    </Grid>
                    <Grid item xs>
                      <WpTypography variant="p1Bold">{t('editView.emailLabel')}</WpTypography>
                      <WpTypography>{details?.email}</WpTypography>
                    </Grid>
                  </Grid>
                ) : (
                  <FormProvider {...methods}>
                    <form>
                      <Grid container spacing={3}>
                        <Grid item xs>
                          {role && (
                            <Box component="div" className={classes.role}>
                              <WpDropdown
                                id="key"
                                label={t('editView.roleLabel')}
                                name="role"
                                menuItems={roleListArray}
                                displayKey="id"
                                displayValue="name"
                                returnValue="id"
                                value={role}
                                disabled={!!isPayrollAdmin}
                                disabledTooltip={t('editView.revokeacess')}
                                callBackOnChange={(value) => {
                                  displayCurrentDepartment(true);
                                  setRole(value);
                                }}
                              />
                            </Box>
                          )}

                          {userDetails?.currentRole !== role && (
                            <WpStatements
                              variant="highpriority"
                              message={
                                userDetails?.currentRole === SecurityRoleTypeEnum.Administrator &&
                                payrolladminCount === 1
                                  ? t('editView.payrollTooltip')
                                  : t('editView.normalRoleChange', {
                                      name: userDetails?.name || '',
                                      role: Object.values(SecurityRoleTypeEnum).includes(role)
                                        ? roleListArray.find((x) => x.id === role)?.name
                                        : null,
                                    })
                              }
                            />
                          )}
                        </Grid>

                        <Grid item xs>
                          <WpTypography variant="p1Bold">{t('editView.emailLabel')}</WpTypography>
                          <WpTypography>{details?.email}</WpTypography>
                        </Grid>
                        <Grid item xs>
                          {locationList.length > 1 && (
                            <WpDropdown
                              id="stateId"
                              label={t('editView.locationLabel')}
                              name="location"
                              menuItems={locationList}
                              multiple
                              hasSelectAll={true}
                              displayKey="stateId"
                              displayValue="state"
                              returnValue="stateId"
                              value={
                                locationListForService.length
                                  ? locationListForService
                                  : userDetails?.currentLocation.map((item: any) => item.id)
                              }
                              callBackOnChange={handleLocationSelection}
                            />
                          )}

                          <WpDropdown
                            id="department"
                            label={t('assignedPeopleGroup.columns.assignDepartment')}
                            name="department"
                            menuItems={removeDuplicates(departmentList)}
                            multiple
                            hasSelectAll={true}
                            displayKey="id"
                            displayValue="name"
                            returnValue="id"
                            required={true}
                            errorobj={errors}
                            value={displayCurrentDepartment}
                            callBackOnChange={handleDepartmentSelection}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </FormProvider>
                )
              ) : (
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <WpTypography variant="p1Bold">{t('editView.roleLabel')}</WpTypography>
                        <WpTypography>{displayRoleList(t).find((x) => x.id === role)?.name}</WpTypography>
                      </Grid>
                      <Grid item xs>
                        <WpTypography variant="p1Bold">{t('editView.emailLabel')}</WpTypography>
                        <WpTypography>{details?.email}</WpTypography>
                      </Grid>

                      {role !== SecurityRoleTypeEnum.AccountOwner && (
                        <Grid item xs>
                          <WpTypography variant="p1Bold">Assigned to</WpTypography>
                          <WpTypography>
                            {userDetails?.currentDepartment && userDetails?.currentDepartment.length
                              ? userDetails?.currentDepartment.map((dep: any) => dep?.name).join(', ')
                              : '--'}
                          </WpTypography>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              )}

              <PermissionSettings
                title="User permissions"
                id={from}
                details={details}
                value={false}
                cardObject={isEdit ? objectToBePassed : {}}
                switchHandler={switchToEdit}
                handleClose={closeBreadCrumbs}
                handleTrigger={handleTrigger}
                handleUnsavedNavigation={() => getUser()}
                ref={permissionRef}
              />
            </>
          ) : (
            <>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <WpTypography variant="p1Bold">{t('editView.employmentType')}</WpTypography>
                      <WpTypography>
                        {Object.values(PersonTypeEnum).includes(details?.personType)
                          ? PersonTypeEnum[details.personType]
                          : null}
                      </WpTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <WpTypography variant="p1Bold">{t('editView.emailLabel')}</WpTypography>
                      <WpTypography>{details?.email}</WpTypography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <PermissionSettings
                title="User permissions"
                value={true}
                from={UserAccountsTypeEnum.People}
                details={details}
                handleClose={closeBreadCrumbs}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditView;
