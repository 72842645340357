/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import { ICompanyField } from 'services/company-field-access/companyFieldAccess.contracts';

interface ICompanyFieldState {
  companyField: ICompanyField;
}

const initialState: ICompanyFieldState = {
  companyField: {
    id: 0,
    companyId: 0,
    name: '',
    frenchName: '',
    glExpenseAccount: '',
    glLiabilityAccount: '',
    code: '',
    description: '',
    rateCoefficient: 0,
    insurableHours: false,
    insurableEarnings: false,
    countryType: 0,
    fieldType: 0,
    contributionEntryType: 0,
    federalTaxFlags: 0,
    notes: '',
    codeType: 0,
    stateConcernList: [],
    formList: [],
    incomeCodeNameList: [],
    selectProvince: false,
    jobColumn: false,
    useMinimumWage: false,
  },
};

export const companyFieldSlice = createSlice({
  name: 'companyField',
  initialState,
  reducers: {
    updateCompanyField: (state, action: PayloadAction<ICompanyField>) => {
      state.companyField = { ...state.companyField, ...action.payload };
    },
    update: (state, action: PayloadAction<ICompanyField>) => {
      state.companyField = { ...state.companyField, ...action.payload };
    },
    resetCompanyFields: (state) => {
      state.companyField = initialState.companyField;
    },
  },
});

export const { updateCompanyField, update, resetCompanyFields } = companyFieldSlice.actions;
export const companyFieldReducer = companyFieldSlice.reducer;
export const companyFieldState = (state: RootState) => state.companyFieldReducer;
export const selectCompanyField = (state: RootState) => state.companyFieldReducer?.companyField;
