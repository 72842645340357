import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { RootState } from '../store/store';

type StatusBarType = 'success' | 'warning' | 'error';

interface IStatusBarState {
  isShow: boolean;
  type?: StatusBarType;
  message?: (() => ReactNode) | ReactNode;
}

const initialState: IStatusBarState = {
  isShow: false,
  type: undefined,
  message: undefined,
};

export const statusBarSlice = createSlice({
  name: 'statusBar',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<Pick<IStatusBarState, 'type' | 'message'>>) => {
      state.isShow = true;
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
    hide: (state) => {
      state.isShow = false;
      state.type = undefined;
      state.message = undefined;
    },
  },
});

export const { show, hide } = statusBarSlice.actions;

export const statusBarReducer = statusBarSlice.reducer;

const statusBarSelector = (state: RootState) => state.statusBarReducer;

export const isShowSelector = createSelector(statusBarSelector, ({ isShow }) => isShow);

export const messageSelector = createSelector(statusBarSelector, ({ message }) => message);

export const typeSelector = createSelector(statusBarSelector, ({ type }) => type);
