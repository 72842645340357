import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store/store';
import BillingAccessServices, {
  CompanyCriteriaInterface,
  CompanyInterface,
  CompanySortTypeEnum,
  CompanyStatusTypeEnum,
  GetCompanyListRequestInterface,
} from 'services/finance/models/access/billingAccess';

interface ISwitchingFirmsSlice {
  accountingCompanyId: number;
  clientList: Array<CompanyInterface>;
  isClientListLoaded: boolean;
}

const initialState: ISwitchingFirmsSlice = {
  accountingCompanyId: 0,
  clientList: [],
  isClientListLoaded: false,
};

export const getClientList = createAsyncThunk(
  'switchingFirms/getClientList',
  (request?: Partial<CompanyCriteriaInterface> & { partnerId: number }) => {
    const defaultRequest: GetCompanyListRequestInterface = {
      companyCriteria: {
        partnerId: 0,
        securityRoleTypeList: [],
        filterOnAdministrator: false,
        filterOnBilling: false,
        filterOnCountry: false,
        filterOnPermissionType: false,
        filterOnCompanyName: false,
        clientPays: false,
        partnerPays: false,
        administratorIdList: [],
        countryIdList: [],
        startsWithCompanyName: '',
        filterOnDisconnectedClients: false,
        includeCompanyInvitationList: false,
        includeCompanyOwner: false,
        filterOnPartner: true,
        filterOnYearList: false,
        filterOnCompanyTypeFlags: false,
        filterOnStatus: false,
        filterOnMonthList: false,
        filterOnPlan: false,
        monthList: [],
        yearList: [],
        sortDescending: false,
        companySortType: CompanySortTypeEnum.notSpecified,
        skip: 0,
        take: 100,
        companyTypeFlags: 0,
        filterOnCompanyCode: false,
        filterOnCompanyStatus: false,
        companyStatusType: CompanyStatusTypeEnum.notSpecified,
        filterOnNotClosedAndNotExpired: false,
        ...request,
      },
    };
    return BillingAccessServices.getCompanyList(defaultRequest);
  }
);

export const switchingFirmsSlice = createSlice({
  name: 'switchingFirms',
  initialState,
  reducers: {
    updateAccountingCompanyId: (state, action: PayloadAction<{ accountingCompanyId: number }>) => {
      state.accountingCompanyId = action.payload.accountingCompanyId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientList.pending, (state) => {
      state.isClientListLoaded = false;
    });
    builder.addCase(getClientList.fulfilled, (state, action) => {
      state.clientList = action.payload.companyList || [];
      state.isClientListLoaded = true;
    });
  },
});

export const { updateAccountingCompanyId } = switchingFirmsSlice.actions;
export const switchingFirmsReducer = switchingFirmsSlice.reducer;
export const selectSwitchingFirms = (state: RootState) => state.switchingFirmsReducer;
export const selectClientList = createSelector(selectSwitchingFirms, ({ clientList, isClientListLoaded }) => ({
  clientList,
  isClientListLoaded,
}));
export const selectIsPartner = createSelector(selectSwitchingFirms, ({ accountingCompanyId }) => !!accountingCompanyId);
