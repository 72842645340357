import { Post } from 'services/httpClient';
import {
  CreateUserRequest,
  CreateUserResponse,
  SetDontShowMeFlagRequest,
  SetDontShowMeFlagResponse,
  IPersistPersonResponse,
  IPersistPersonRequest,
  IGetPersonResponse,
  IGetPersonRequest,
  IPersistEmployementStateResponse,
  IPersistEmployementStateRequest,
  IPersistProfileDocumentRequest,
  IPersistProfileDocumentResponse,
  IGetPersonListRequest,
  IGetPersonListResponse,
  IGetPersonSummaryListRequest,
  IGetPersonSummaryListResponse,
  IManagePersonStatusRequest,
  IManagePersonStatusResponse,
  IInvitePersonRequest,
  IInvitePersonResponse,
  IGetPersonJobListRequest,
  IGetPersonJobListResponse,
  IPersistPersonDepartmentRequest,
  IPersistPersonDepartmentResponse,
  IGetEmployeePaymentDetailRequest,
  IGetEmployeePaymentDetailResponse,
  IDeletePersonRequest,
  IDeletePersonResponse,
  IGetPersonNameRequest,
  IGetPersonNameResponse,
  IDisableEmployeeListRequest,
  IDisableEmployeeListResponse,
} from './personAccess.contracts';

const servicedomain = '/appcorepersonaccess/';
const inviteChangedServiceDomain = '/payrollPersonOnboardAccess/';
const CreateUserUrl = servicedomain + 'createUser';
const SetDontShowMeFlagUrl = servicedomain + 'setDontShowMeFlag';
const PersistPersonUrl = servicedomain + 'persistperson';
const PersistEmployementStateRequestUrl = servicedomain + 'persistemployementstate';
const GetPersonUrl = servicedomain + 'getperson';
const GetPersonSummeryListUrl = servicedomain + 'getpersonsummarylist';
const SavePersonProfileDoc = servicedomain + 'persistprofiledocument';
const GetPersonListUrl = servicedomain + 'getPersonList';
const ManagePersonStatusUrl = servicedomain + 'managepersonstatus';
const InvitePersonUrl = inviteChangedServiceDomain + 'inviteperson';
const getPersonJobList = servicedomain + 'getpersonjoblist';
const PersistPersonDepartmentURL = servicedomain + 'persistPersonDepartment';
const GetEmployeePaymentDetailURL = servicedomain + 'getEmployeePaymentDetail';
const DeletePersonURL = servicedomain + 'deletePerson';
const GetPersonNameUrl = servicedomain + 'getPersonName';
const DisableEmployeeListUrl = inviteChangedServiceDomain + 'disableemployeeList';

export interface Icompany {
  name: string;
}

export const CreateUser = (request: CreateUserRequest): Promise<CreateUserResponse> => {
  return Post(CreateUserUrl, request);
};

export const SetDontShowMeFlag = (request: SetDontShowMeFlagRequest): Promise<SetDontShowMeFlagResponse> => {
  return Post(SetDontShowMeFlagUrl, request);
};

export const persistPerson = (request: IPersistPersonRequest): Promise<IPersistPersonResponse> => {
  return Post(PersistPersonUrl, request);
};
export const persistEmploymentState = (
  request: IPersistEmployementStateRequest
): Promise<IPersistEmployementStateResponse> => {
  return Post(PersistEmployementStateRequestUrl, request);
};
export const getPerson = (request: IGetPersonRequest): Promise<IGetPersonResponse> => {
  return Post(GetPersonUrl, request);
};
export const getPersonSummeryList = (request: IGetPersonSummaryListRequest): Promise<IGetPersonSummaryListResponse> => {
  return Post(GetPersonSummeryListUrl, request);
};
export const savePersonDoc = (request: IPersistProfileDocumentRequest): Promise<IPersistProfileDocumentResponse> => {
  return Post(SavePersonProfileDoc, request);
};

export const GetPersonList = (request: IGetPersonListRequest): Promise<IGetPersonListResponse> => {
  return Post(GetPersonListUrl, request);
};
export const ManagePersonStatus = (request: IManagePersonStatusRequest): Promise<IManagePersonStatusResponse> => {
  return Post(ManagePersonStatusUrl, request);
};

export const InvitePerson = (request: IInvitePersonRequest): Promise<IInvitePersonResponse> => {
  return Post(InvitePersonUrl, request);
};

export const GetPersonJobListDashboard = (
  request: Partial<IGetPersonJobListRequest>
): Promise<IGetPersonJobListResponse> => {
  return Post(getPersonJobList, request);
};

export const PersistPersonDepartment = (
  request: IPersistPersonDepartmentRequest
): Promise<IPersistPersonDepartmentResponse> => {
  return Post(PersistPersonDepartmentURL, request);
};

export const getEmployeePaymentDetail = (
  request: IGetEmployeePaymentDetailRequest
): Promise<IGetEmployeePaymentDetailResponse> => {
  return Post(GetEmployeePaymentDetailURL, request);
};

export const DeletePerson = (request: IDeletePersonRequest): Promise<IDeletePersonResponse> => {
  return Post(DeletePersonURL, request);
};

export const GetPersonName = (request: IGetPersonNameRequest): Promise<IGetPersonNameResponse> => {
  return Post(GetPersonNameUrl, request);
};

export const DisableEmployeeList = (request: IDisableEmployeeListRequest): Promise<IDisableEmployeeListResponse> => {
  return Post(DisableEmployeeListUrl, request);
};
