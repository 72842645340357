import { MonthEnum } from '../enums';
import { useTranslation } from 'react-i18next';

export const useMonthOptions = (): Record<string, string> => {
  const { t: tMonthName } = useTranslation('common', { keyPrefix: 'monthName' });
  return {
    [MonthEnum.January]: tMonthName('january'),
    [MonthEnum.February]: tMonthName('february'),
    [MonthEnum.March]: tMonthName('march'),
    [MonthEnum.April]: tMonthName('april'),
    [MonthEnum.May]: tMonthName('may'),
    [MonthEnum.June]: tMonthName('june'),
    [MonthEnum.July]: tMonthName('july'),
    [MonthEnum.August]: tMonthName('august'),
    [MonthEnum.September]: tMonthName('september'),
    [MonthEnum.October]: tMonthName('october'),
    [MonthEnum.November]: tMonthName('november'),
    [MonthEnum.December]: tMonthName('december'),
  };
};
