export enum TimeZoneTypeEnum {
  NotDefined = 0,
  EasternStandard = 1,
  IndiaStandard = 2,
  AtlanticTime = 3,
  NiueTime = 4,
  SamoaStandard = 5,
}

export enum TimeZoneTypeEnumMappedToStringValues {
  EasternStandard = 'America/Toronto',
  IndiaStandard = 'Asia/Kolkata',
  AtlanticTime = 'America/Halifax',
  NiueTime = 'Pacific/Niue',
  SamoaStandard = 'Pacific/Samoa	',
}
