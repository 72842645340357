import { PermissionsEnum } from 'services/shared/permission.contracts';

export enum UserAccountsPermissionsEnum {
  Setup = PermissionsEnum.SetupRoot,
  Payroll = PermissionsEnum.PayrollRoot,
  PayrollTools = PermissionsEnum.PayrollTools,
  Reports = PermissionsEnum.ReportsRoot,
  CompanySettings = PermissionsEnum.CompanySettingsRoot,
  PayrollSettings = PermissionsEnum.PayrollSetup,
  TaxSettings = PermissionsEnum.TaxSetup,
}

export enum UserAccountsPermissionsForPeopleEnum {
  BasicPermission = PermissionsEnum.EmployeePortal,
  PayrollPermission = PermissionsEnum.ScEmployeePayroll,
}

export enum PermissionAccessTypeEnum {
  Write = 1,
  Read = 2,
  Noaccess = 3,
}
