import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export const administratorsGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Columns
    avatar: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      '&.no-image': {
        backgroundColor: theme.palette.disabled.default,
      },
    },
  })
);

type GetGridColumnParams = {
  companyId?: number;
  email?: string;
  firstName?: string;
  id?: number;
  lastLogin?: string;
  lastName?: string;
  personId?: number;
  profileDocumentId?: number;
  securityRoleType?: number;
  twoFactorEnabled?: boolean;
};

const useFirmAdminGridColumns = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'administratorsGrid' });
  const classes = administratorsGridStyles();

  return useMemo(
    () => [
      {
        field: 'avatarImage',
        title: '',
        templateSupply: () => {
          return <div className={clsx(classes.avatar, 'no-image')}></div>;
        },
      },
      {
        field: 'name',
        title: t('gridColumns.name'),
        enableSorting: true,
        templateSupply: (row: GetGridColumnParams) => {
          return <div>{`${row.firstName} ${row.lastName}`}</div>;
        },
      },
      {
        field: 'email',
        title: t('gridColumns.email'),
        templateSupply: (row: GetGridColumnParams) => {
          return <div>{row.email || '--'}</div>;
        },
      },

      {
        field: 'LastLoggedIn',
        title: t('gridColumns.lastLoggedIn'),
        templateSupply: (row: GetGridColumnParams) => {
          return <div>{row.lastLogin ? format(new Date(row.lastLogin), 'MMM d, yyyy - h:mm a') : '--'}</div>;
        },
      },
    ],
    [t]
  );
};

export default useFirmAdminGridColumns;
