export type Query = Record<string, string | number | undefined>;

export type Parameter = string | number;

function injectVariablesIntoUrl(url: string, parameters: (Parameter | Query)[] = []) {
  let output = url;

  for (const variable of parameters) {
    output = output.replace(/:\w+/, encodeURIComponent(String(variable)));
  }

  return output;
}

export function createBuildPath(prefix: string) {
  function buildPath<T extends (Parameter | Query)[] = []>(url: string) {
    return (parameters?: T) => injectVariablesIntoUrl(prefix + url, parameters);
  }

  return buildPath;
}
