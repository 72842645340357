import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import ICU from 'i18next-icu';
//import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultLanguage, supportedLanguages } from './config';
import format from './i18n-format';

const Resource_Path =
  process.env.NODE_ENV == 'development'
    ? '/locales/{{lng}}/{{ns}}.json'
    : process.env.REACT_APP_BASE_URL + '/ui/locales/{{lng}}/{{ns}}.json';

i18next
  .use(ICU)
  .use(initReactI18next)
  .use(Backend)
  //.use(LanguageDetector)

  .init({
    backend: {
      loadPath: Resource_Path,
    },
    supportedLngs: supportedLanguages.map((lang) => lang.code),
    nonExplicitSupportedLngs: false,
    ns: [
      'companySettings',
      'companydetails',
      'payrollsettings',
      'common',
      'validation',
      'negativeadjustment',
      'payStubMemos',
      'secureuploadfolder',
      'fileupload',
      'taxsettings',
      'workercompensation',
      'invoices',
      'closeAccounts',
      'recordOfEmployment',
      'userAccounts',
      'payrollreports',
      'header',
      'addons',
      'notifications',
      'dashboard',
      'bankDetails',
      'preferences',
      'timezones',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
      format,
    },
    fallbackLng: defaultLanguage,
    lowerCaseLng: true,
  });

export default i18next;
