import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import CommonContainer from '../../common/company-email-layout/companyIdOrEmailLayout';
import { WpTypography } from '@wagepoint/ui-toolkit/components/wp-typography/wpTypography';

const ToBeInvited: React.VFC = () => {
  const { t } = useTranslation(['userAccounts']);

  return (
    <Card variant="outlined">
      <CardHeader title={<WpTypography variant="h3">{t('firmInviteSent.inviteDialogue')}</WpTypography>} />
      <CardContent>
        <CommonContainer needValidation />
      </CardContent>
    </Card>
  );
};

export default ToBeInvited;
