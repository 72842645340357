import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IServiceMatrix } from '@wagepoint/ui-workbook';
import { RootState } from 'redux/store/store';
import {
  EmployeeOverridenFieldInterface,
  ITotalPayrollPeopleList,
} from 'services/payroll-payperiod-access/payPeriodAccess.contracts';
import { IPayPeriodWindowInterface } from 'services/payroll-launch-access/payrollLaunchAccess.contracts';
import { TimeSheetFormatTypeEnum } from 'services/company-settings-access/companySettingsAccess.contracts';
import { IApprovePayrollResponse } from 'services/payroll-payperiodaction-access/payPeriodActionAccess.contracts';

export enum SpecialValue {
  RegularPay = 'RegularPay',
  OtPay = 'OtPay',
  HolidayPay = 'HolidayPay',
  StatPay = 'StatPay',
  VacationPay = 'VacationPay',
  WorkedOnStatPay = 'WorkedOnStatPay',
  SickPay = 'SickPay',
}

export interface IDeletCode {
  [key: number]: { [key: string]: any };
}

export interface ICustomCodeConfigItem {
  designation: string;
  columnIndex: number;
}
export interface Icustom {
  [SpecialValue.RegularPay]: string[];
  [SpecialValue.OtPay]: string[];
  [SpecialValue.HolidayPay]: string[];
  [SpecialValue.VacationPay]: string[];
  [SpecialValue.StatPay]: string[];
  [SpecialValue.WorkedOnStatPay]: string[];
  [SpecialValue.SickPay]: string[];
}
export interface IcustomConfig {
  [SpecialValue.RegularPay]: ICustomCodeConfigItem[];
  [SpecialValue.OtPay]: ICustomCodeConfigItem[];
  [SpecialValue.HolidayPay]: ICustomCodeConfigItem[];
  [SpecialValue.VacationPay]: ICustomCodeConfigItem[];
  [SpecialValue.StatPay]: ICustomCodeConfigItem[];
  [SpecialValue.WorkedOnStatPay]: ICustomCodeConfigItem[];
  [SpecialValue.SickPay]: ICustomCodeConfigItem[];
}

const initCustomCodeConfig = {
  [SpecialValue.RegularPay]: [],
  [SpecialValue.OtPay]: [],
  [SpecialValue.HolidayPay]: [],
  [SpecialValue.VacationPay]: [],
  [SpecialValue.StatPay]: [],
  [SpecialValue.WorkedOnStatPay]: [],
  [SpecialValue.SickPay]: [],
};
interface IPayrollReducer {
  runningPayGroup: any;
  isNewPaygroup: boolean;
  payperiodWindow: IPayPeriodWindowInterface;
  stepIndex: number;
  showExecutor: boolean;
  isMatrixChanged: boolean;
  disableApprove: boolean;
  lastPayrollGrossPay: number;
  lastMaxPayrollGrossPay: number;
  isFirstPaygroup: boolean;
  fundDebitList: IApprovePayrollResponse['fundDebitDetailList'];
  payPeriodResponse: {
    employeeOverridenFieldList: Array<EmployeeOverridenFieldInterface>;
    matrixConfig?: { [key: number]: number };
    matrix: IServiceMatrix;
    id: number;
    isOffCycle: boolean;
    payPeriodStatusType: number;
    targetPayDate: string;
    startDate: string;
    endDate: string;
  } | null;
  prevSelectDate: {
    directDeposit: null | boolean;
    remitTax: null | boolean;
    targetDate: string | null;
    startDate: string | null;
    endDate: string | null;
    processingDate: string | null;
  };
  previousStepIndex: null | number;
  dirtyMatrix: IServiceMatrix;
  reviewEmployee: ITotalPayrollPeopleList | null;
  currentStub: { [key: string]: any } | null;
  isOffCycle: boolean;
  customCodes: { [key: string]: Icustom };
  customCodeConfig: IcustomConfig;
  timeSheetFormatType: TimeSheetFormatTypeEnum;
  vacationAccrualBalanceDate: string;
  offCyclePreData: {
    reason: number;
    personId: number;
  } | null;
  satMethodType: number;
  disableRegularPayrollRun: boolean;
  deletedIncomeCodes: IDeletCode;
  isShowLeaveModal: boolean;
}

export interface IsetVariableEnumPayroll {
  payload: {
    key:
      | 'runningPayGroup'
      | 'stepIndex'
      | 'payPeriodResponse'
      | 'lastPayrollGrossPay'
      | 'timeSheetFormatType'
      | 'lastMaxPayrollGrossPay'
      | 'customCodeConfig'
      | 'payperiodWindow'
      | 'previousStepIndex'
      | 'prevSelectDate';

    value: any;
  };
}
export enum IstepsEnum {
  SelectDate = 0,
  HoursAndIncome = 1,
  Taxes = 2,
  ReviewAndApprove = 3,
  SuccessPage = 4,
}
interface IChangePayperiodAction {
  payload: { value: any };
}
interface IChangeVariable {
  payload: {
    value: any;
    key:
      | 'disableApprove'
      | 'showExecutor'
      | 'reviewEmployee'
      | 'currentStub'
      | 'dirtyMatrix'
      | 'isOffCycle'
      | 'isMatrixChanged'
      | 'customCodes'
      | 'isFirstPaygroup'
      | 'offCyclePreData'
      | 'fundDebitList'
      | 'disableRegularPayrollRun'
      | 'isShowLeaveModal';
  };
}

const resetMatrix = { matrixName: '', columnList: [], rowList: [], multiHeader: [] };
const initialState: IPayrollReducer = {
  runningPayGroup: null,
  isNewPaygroup: true,
  payperiodWindow: {
    endDate: '',
    targetDate: '',
    startDate: '',
    payPeriodId: 0,
  },
  deletedIncomeCodes: {},
  previousStepIndex: null,
  isMatrixChanged: false,
  disableApprove: false,
  showExecutor: false,
  isFirstPaygroup: false,
  stepIndex: IstepsEnum.SelectDate,
  lastPayrollGrossPay: 0,
  lastMaxPayrollGrossPay: 0,
  payPeriodResponse: {
    id: 0,
    isOffCycle: false,
    matrix: resetMatrix,
    payPeriodStatusType: 0,
    targetPayDate: String(new Date()),
    startDate: String(new Date()),
    endDate: String(new Date()),
    employeeOverridenFieldList: [],
  },
  dirtyMatrix: resetMatrix,
  reviewEmployee: null,
  currentStub: null,
  isOffCycle: false,
  customCodes: {},
  customCodeConfig: initCustomCodeConfig,
  timeSheetFormatType: TimeSheetFormatTypeEnum.decimal,
  prevSelectDate: {
    directDeposit: null,
    remitTax: null,
    targetDate: null,
    startDate: null,
    endDate: null,
    processingDate: null,
  },
  vacationAccrualBalanceDate: '',
  offCyclePreData: null,
  fundDebitList: [],
  satMethodType: 0,
  disableRegularPayrollRun: false,
  isShowLeaveModal: false,
};

export const payrollSlice = createSlice({
  name: 'payrollCommonReducer',
  initialState,
  reducers: {
    commonPayrollSetVariable: (state, action: IsetVariableEnumPayroll) => {
      state[action.payload.key] = action.payload.value;
    },
    changecommonPayrollStates: (state, action: IChangeVariable) => {
      state[action.payload.key] = action.payload.value;
    },
    changePayPeriodWindow: (state, action: IChangePayperiodAction) => {
      state.payperiodWindow = { ...state.payperiodWindow, ...action.payload.value };
    },
    updateVacationInformation: (state, action) => {
      state.vacationAccrualBalanceDate = action.payload.value;
    },
    resetHoursIncome: (state) => {
      state.customCodes = {};
      state.previousStepIndex = null;
      state.isMatrixChanged = false;
    },
    resetMatrixAction: (state) => {
      if (state.payPeriodResponse) state.payPeriodResponse.matrix = { ...resetMatrix };
    },
    resetPayPeriod: (state) => {
      state.payperiodWindow = initialState.payperiodWindow;
      state.prevSelectDate = initialState.prevSelectDate;
    },
    setDeletedIncomeCodes: (state, action) => {
      state.deletedIncomeCodes = action.payload;
    },
    updateMatrix: (state, action: IChangePayperiodAction) => {
      if (state.payPeriodResponse)
        state.payPeriodResponse = {
          ...state.payPeriodResponse,
          matrix: JSON.parse(JSON.stringify(action.payload.value)),
        };
    },
    setSatMethodType: (state, action: { payload: number }) => {
      state.satMethodType = action.payload;
    },
    resetToInitial: () => initialState,
  },
});

export const {
  commonPayrollSetVariable,
  changePayPeriodWindow,
  updateMatrix,
  changecommonPayrollStates,
  resetMatrixAction,
  resetPayPeriod,
  resetHoursIncome,
  updateVacationInformation,
  setSatMethodType,
  setDeletedIncomeCodes,
  resetToInitial,
} = payrollSlice.actions;

export const payrollCommonReducer = payrollSlice.reducer;

export const payrollCommonReducerStates = (state: RootState) => state.payrollCommonReducer;

export const showExecutorState = createSelector(payrollCommonReducerStates, ({ showExecutor }) => showExecutor);
export const selectIsOffCycle = createSelector(payrollCommonReducerStates, ({ isOffCycle }) => isOffCycle);
export const selectIsNewPaygroup = createSelector(payrollCommonReducerStates, ({ isNewPaygroup }) => isNewPaygroup);
export const selectOffCyclePreData = createSelector(
  payrollCommonReducerStates,
  ({ offCyclePreData }) => offCyclePreData
);

export const firstPayrollSelector = createSelector(
  payrollCommonReducerStates,
  ({ isFirstPaygroup }) => isFirstPaygroup
);

export const selectMatrixChanged = createSelector(payrollCommonReducerStates, ({ isMatrixChanged }) => isMatrixChanged);
export const selectRunningPaygroup = createSelector(
  payrollCommonReducerStates,
  ({ runningPayGroup }) => runningPayGroup
);
export const selectShowPayrollExecutor = createSelector(payrollCommonReducerStates, ({ showExecutor }) => showExecutor);
export const selectStepIndex = createSelector(payrollCommonReducerStates, ({ stepIndex }) => stepIndex);
export const selectLeaveModal = createSelector(payrollCommonReducerStates, ({ isShowLeaveModal }) => isShowLeaveModal);
export const selectPayperiodResponse = createSelector(
  payrollCommonReducerStates,
  ({ payPeriodResponse }) => payPeriodResponse
);
export const selectTimeSheetFormatType = createSelector(
  payrollCommonReducerStates,
  ({ timeSheetFormatType }) => timeSheetFormatType
);
export const getPrevConfigSelector = createSelector(payrollCommonReducerStates, ({ prevSelectDate }) => prevSelectDate);
export const selectDeletedCodes = createSelector(
  payrollCommonReducerStates,
  ({ deletedIncomeCodes }) => deletedIncomeCodes
);
export const didMatrixChangeSelector = createSelector(
  payrollCommonReducerStates,
  ({ isMatrixChanged }) => isMatrixChanged
);
export const getStepIndexSelector = createSelector(payrollCommonReducerStates, ({ stepIndex }) => stepIndex);
export const getPrevStepIndexSelector = createSelector(
  payrollCommonReducerStates,
  ({ previousStepIndex }) => previousStepIndex
);
export const getVacationBalancePaydate = createSelector(
  payrollCommonReducerStates,
  ({ vacationAccrualBalanceDate }) => vacationAccrualBalanceDate
);
export const getFundDebitList = createSelector(payrollCommonReducerStates, ({ fundDebitList }) => fundDebitList);
export const getSatMethodType = createSelector(payrollCommonReducerStates, ({ satMethodType }) => satMethodType);
export const selectCustomCodeConfig = createSelector(
  payrollCommonReducerStates,
  ({ customCodeConfig }) => customCodeConfig
);
export const selectLastMaxPayrollGrossPay = createSelector(
  payrollCommonReducerStates,
  ({ lastMaxPayrollGrossPay }) => lastMaxPayrollGrossPay
);
export const selectCustomCodes = createSelector(payrollCommonReducerStates, ({ customCodes }) => customCodes);
