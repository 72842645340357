import { Get, Post } from 'services/httpClient';

const servicedomain = '/security/';
const switchTenantUrl = servicedomain + 'switch-tenant/';
const resetTenantUrl = servicedomain + 'reset';
const checksessionUrl = '/hdrauth' + servicedomain + 'session/check';

export const switchTenant = (tenantId: number): Promise<any> => {
  return Get(switchTenantUrl + tenantId);
};

export const resetTenant = (): Promise<any> => {
  return Get(resetTenantUrl);
};

export const checkSession = (): Promise<ICheckSession> => {
  return Post(checksessionUrl, null);
};

export interface ICheckSession {
  hasSession: boolean;
  baseUrl: string;
}
