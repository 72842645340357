interface ICustomEvent {
  eventName: string;
  listener: (data?: any) => void;
}

const subscribe = ({ eventName, listener }: ICustomEvent) => {
  document.addEventListener(eventName, listener);
};

const unsubscribe = ({ eventName, listener }: ICustomEvent) => {
  document.removeEventListener(eventName, listener);
};

const publish = <T>({ eventName, data }: { eventName: string; data: T }): void => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };
